import * as React from "react";
import { IBktDataTableColumnProps } from "front-end-lib";
import { QnA } from "../../../components/BktStudy/MddResDisplay/Utils";

export const generalDataTableHeaders = (
  questions: string[]
): IBktDataTableColumnProps[] =>
  questions.map((question: string) => {
    return {
      label: question,
      tableCellProps: { id: `${question}_general_header` }
    };
  });

export const getDataTableGeneral = (demographics: QnA[][], meta: string[]) =>
  demographics.map(demographic => {
    const [name, isActive] = ["Type", "isActive"].map(
      question => demographic.find(item => item.question === question)?.answer
    );

    const demographicName = isActive ? name : `*${name}`;
    const inactiveClassName = isActive ? "" : "mdd-experience--inactive-item";

    const result = demographic
      .filter(item => !meta?.includes(item.question))
      .map(item => {
        const answer = item.question === "Type" ? demographicName : item.answer;
        return {
          [item.question]: (
            <div
              className={`mdd-experience--tbl-cell ${inactiveClassName}`}
              data-testid={`${name}_${item.question}_general_testid`}
              title={answer ?? "-"}
            >
              {answer ?? "-"}
            </div>
          )
        };
      });

    return Object.assign({ key: name }, ...result);
  });
