import * as React from "react";

interface IMddPanel {
  title?: string;
  size: "half" | "full" | "onethird" | "twothirds" | "custom";
  customSize?: string;
  border?: boolean;
  content: React.ReactNode;
  className?: string;
  id?: string;
}

interface IMddPanelContainerProps {
  panels: IMddPanel[];
  className?: string;
  id?: string;
}

export const MddPanelContainer = (props: IMddPanelContainerProps) => {
  const { panels, className, id } = props;

  return (
    <div
      className={`mdd-panel-container ${className ? className : ""}`}
      id={id || "panelContainer"}
      data-testid={id || "panelContainer"}
    >
      {panels.map((panel: IMddPanel, index: number) => {
        const { border = true, id = `panel_${index}` } = panel;
        return (
          <React.Fragment key={`fragment_${index}`}>
            <div
              key={`panel_${index}`}
              className={`mdd-panel-container--panel-${panel.size}${
                border ? "" : "-no-border"
              } ${panel.className ? panel.className : ""}`}
              style={{
                flexBasis: panel.size === "custom" ? `${panel.customSize}%` : ""
              }}
              id={id}
              data-testid={id}
            >
              {panel.title && (
                <div
                  className="mdd-panel-container--panel-title"
                  id={`panel_${index}_title`}
                  data-testid={`panel_${index}_title`}
                >
                  {panel.title}
                </div>
              )}
              {panel.content}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
