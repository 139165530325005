import { BktDataTable, SagaService } from "front-end-lib";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IEditItem, eResConfig } from "../../../store/resConfig/types";
import { MddDataContainer } from "../../../components";
import {
  getConfig,
  headers,
  tableStyles
} from "../../../config/dataTable/raterExperienceSurvey/experienceIndicationsDataTableConfig";
import {
  getIndictionsState,
  handleActiveChange
} from "../../../store/resConfig/selectors";
import { MddResExperienceIndicationDialog } from "./MddResExperienceIndicationDialog";
import { DeleteDialog } from "./DeleteDialog";

interface IProps {
  fields: string[];
  items: IEditItem[] | null;
  edit: IEditItem[];
}

const MddResExperienceIndicationComponent = ({
  fields,
  items,
  edit
}: IProps) => {
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState<IEditItem>();

  useEffect(() => {
    SagaService.dispatchSaga({ type: eResConfig.GET_INDICATIONS });
    SagaService.dispatchSaga({ type: eResConfig.GET_DEMOGRAPHICS });
  }, []);

  const handleClose = (edits?: IEditItem[]) => {
    if (edits) {
      SagaService.dispatchSaga({
        type: eResConfig.EDIT_ITEMS,
        payload: { section: "indications", edits }
      });
    }

    setOpen(false);
  };

  const handleOpenDeleteDialog = (editItem: IEditItem) => {
    setOpenDeleteDialog(true);
    setDeleteItem({ ...editItem, softDeleted: true });
  };

  const handleCloseDeleteDialog = (isSave?: boolean) => {
    if (isSave) {
      SagaService.dispatchSaga({
        type: eResConfig.DELETE_ITEMS,
        payload: {
          edits: edit,
          deleteItem: deleteItem,
          items,
          section: "indications"
        }
      });
    }

    setOpenDeleteDialog(false);
  };

  const getItemName = (item: IEditItem) => {
    const hasChilds =
      !item?.childId &&
      (items?.some(i => i.childId && i.parentId === item?.parentId) ||
        edit?.some(i => i.childId && i.parentId === item?.parentId));

    const itemName = `${item?.name} Indication`;

    if (hasChilds) {
      return `${itemName} and its currently selected demographics`;
    }

    return itemName;
  };

  const handleIsActiveChange = (indication: IEditItem) => {
    SagaService.dispatchSaga({
      type: eResConfig.EDIT_ITEMS,
      payload: {
        section: "indications",
        edits: handleActiveChange(indication, edit, items!)
      }
    });
  };

  return (
    <div className="mdd-res-draft--tab-content">
      <h3>Experience (Indication)</h3>
      <p className="mdd-res-draft--tab-content--paragraph">
        Add or remove indication(s). Once published, any indication selected
        cannot be deleted from the configuration. However, the status can be
        updated, which will no longer display the fields in the RES within
        Clinical Profile.
      </p>
      <h4>Indication Fields</h4>
      <ul
        className="mdd-res-draft--tab-content--fields"
        data-testid="mdd-res-draft-static-fields"
      >
        {fields.map(field => (
          <li key={field}>{field}</li>
        ))}
      </ul>
      <MddDataContainer
        id="resExperienceIndcationContainer"
        title="Indications Included"
        classes={{
          container: "mdd-res-draft--tableContainer"
        }}
        addAction={
          items
            ? {
                display: "Add Indication(s)",
                action: () => setOpen(true)
              }
            : undefined
        }
      >
        <BktDataTable
          noRecordsMessage="Please add an Indication(s)"
          loading={!items}
          data={getConfig(items || [], handleIsActiveChange)}
          columnProps={headers(handleOpenDeleteDialog)}
          {...tableStyles}
        />
      </MddDataContainer>
      <MddResExperienceIndicationDialog open={open} onClose={handleClose} />
      <DeleteDialog
        itemName={getItemName(deleteItem!)}
        open={openDeleteDialog}
        title={"Indication"}
        onClose={handleCloseDeleteDialog}
      />
    </div>
  );
};

export const MddResExperienceIndication = connect(getIndictionsState)(
  MddResExperienceIndicationComponent
);
