import { IMasterScale } from "../../model";
import { trim } from "lodash";

export class MasterScale implements IMasterScale {
  private id: string;
  private name: string;
  private shortName: string;

  get Id() {
    return this.id;
  }
  set Id(val) {
    this.id = val;
  }
  get Name() {
    return this.name;
  }
  set Name(val) {
    this.name = val;
  }
  get ShortName() {
    return this.shortName;
  }
  set ShortName(val) {
    this.shortName = val;
  }

  constructor(id?: string, name?: string, shortName?: string) {
    this.id = id || "";
    this.name = trim(name);
    this.shortName = trim(shortName);
  }
}
