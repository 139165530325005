import * as React from "react";
import { IQuestionGroup } from "../../../model/interfaces/IPersonnelCPResExperience";
import { MddNoDataDiv } from "../../common";
import { getAnswersWithSeparator } from "./Utils";

interface IMddResPanelContent {
  data?: IQuestionGroup[];
  content?: (questionGroup: IQuestionGroup) => JSX.Element;
}

export const MddListPanelContent = ({ data, content }: IMddResPanelContent) => {
  if (!data) {
    return <MddNoDataDiv />;
  }

  return (
    <div className="mdd-experience--panel--content">
      {data.map((group: IQuestionGroup, index: number) => {
        const isLastQuestion = index === data.length - 1;
        const answer = getAnswersWithSeparator(group.answers);
        return (
          <React.Fragment key={`${group.name}_${index}`}>
            <div
              className="mdd-experience--panel--content--label"
              id="divItemLabel"
              data-testid="divItemLabel"
            >
              {group.name}
            </div>
            {content ? content(group) : answer}
            {!isLastQuestion && <hr></hr>}
          </React.Fragment>
        );
      })}
    </div>
  );
};
