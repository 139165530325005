import * as React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";

interface IMddExpansionPanelProps {
  expanded: boolean;
  id: string;
  panelClass?: string;
  expandIcon?: React.Component;
  defaultExpanded?: boolean;
  disabled?: boolean;
  onChange?: (event: object, expanded: boolean) => void;
  headerText?: string | React.ReactElement;
  children?: React.ReactNode;
}

export const MddExpansionPanel = (props: IMddExpansionPanelProps) => {
  const {
    expanded,
    id,
    panelClass = "mdd-expansion-panel--panel",
    expandIcon = <ExpandMoreIcon />,
    defaultExpanded = false,
    disabled = false,
    headerText = "",
    children = null
  } = props;

  const togglePanel = (event: object, expanded: boolean) => {
    const { onChange } = props;
    if (onChange) {
      onChange(event, expanded);
    }
  };

  return (
    <React.Fragment>
      <Accordion
        className={panelClass}
        expanded={expanded}
        id={id}
        data-testid={id}
        defaultExpanded={defaultExpanded}
        disabled={disabled}
        onChange={togglePanel}
        elevation={0}
      >
        <AccordionSummary
          className="mdd-expansion-panel--summary"
          expandIcon={expandIcon}
          aria-controls={`${id}_content`}
          id={`${id}_header`}
          data-testid={`${id}_header`}
        >
          <div
            id={`${id}_headerText`}
            data-testid={`${id}_headerText`}
            className="mdd-expansion-panel--header"
          >
            {headerText}
          </div>
        </AccordionSummary>
        <AccordionDetails
          className="mdd-expansion-panel--details"
          id={`${id}_content`}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};
