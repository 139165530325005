import * as React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import DialogActions, {
  DialogActionsProps
} from "@material-ui/core/DialogActions";

interface IMddButtonProps {
  closeButtonId?: string;
  dialogActionsId?: string;
  saveButtonId?: string;
  closeButtonProps?: ButtonProps;
  dialogActionsProps?: DialogActionsProps;
  saveButtonProps?: ButtonProps;
  saveButtonText?: string;
  closeButtonText?: string;
  hideSaveButton?: boolean;
}

export const MddDialogButtons = (props: IMddButtonProps) => {
  const {
    closeButtonId = "btnDialogClose",
    dialogActionsId = "mddDialogActions",
    saveButtonId = "btnDialogSave",
    closeButtonProps,
    saveButtonProps,
    dialogActionsProps,
    saveButtonText = "Save",
    closeButtonText = "Cancel",
    hideSaveButton = false
  } = props;

  return (
    <DialogActions id={dialogActionsId} {...dialogActionsProps}>
      <Button
        id={closeButtonId}
        data-testid={closeButtonId}
        key={closeButtonId}
        color="primary"
        variant="outlined"
        disableFocusRipple={true}
        disableRipple={true}
        {...closeButtonProps}
      >
        {closeButtonText}
      </Button>
      {!hideSaveButton && (
        <Button
          id={saveButtonId}
          data-testid={saveButtonId}
          key={saveButtonId}
          color="primary"
          variant="contained"
          disableFocusRipple={true}
          disableRipple={true}
          {...saveButtonProps}
        >
          {saveButtonText}
        </Button>
      )}
    </DialogActions>
  );
};
