import React, { useState, useCallback } from "react";
import { IEditItem, ITabData } from "../../../store/resConfig/types";
import { IIdNamePair } from "../../../model";
import { MddExpansionPanel } from "../../common/MddExpansionPanel/MddExpansionPanel";

interface IMddResViewScalesProps {
  scales: ITabData;
  id: string;
}

export const MddResViewScales = ({ scales, id }: IMddResViewScalesProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const findScale = useCallback(
    (id: string) => {
      return scales?.list?.find((item: IIdNamePair) => item.id === id)?.name;
    },
    [scales]
  );

  return (
    <MddExpansionPanel
      expanded={isExpanded}
      id="panelScales"
      data-testid="panelScales"
      panelClass="mdd-expansion-panel--panel mdd-res-config-view--panel-scales"
      headerText={<h3>Experience (Scales)</h3>}
      onChange={(event, expanded) => setIsExpanded(!isExpanded)}
    >
      <div
        id="viewRes-scales"
        data-testid="viewRes-scales"
        className="mdd-res-config-view"
      >
        <h4 className="mdd-res-config-view--panel-description">
          Scales Fields:
        </h4>
        {scales?.fields?.map((field: string) => (
          <div key={field} className="value">
            {field}
          </div>
        ))}
        <div className="mdd-res-config-view--panel-description mdd-res-config-view--panel-description-flex mdd-res-config-view--panel-description-padding">
          <h4>Scales Included:</h4>
          <div className="mdd-res-config-view--panel-description-inactive">
            *Inactive
          </div>
        </div>

        {scales?.items?.map((scale: IEditItem) => {
          const scaleName = findScale(scale.parentId);
          const value = scale.isActive ? scaleName : `*${scaleName}`;
          const className = scale.isActive ? "" : "inactive-item";
          return (
            <div key={scale.parentId} className={`value ${className}`}>
              {value}
            </div>
          );
        })}
      </div>
    </MddExpansionPanel>
  );
};
