import * as React from "react";
import {
  BktSaga,
  Paper,
  Typography,
  BktDataTable,
  SagaService,
  getKeysFromState,
  IAction,
  BktInfiniteScroll,
  IBktDataTableData
} from "front-end-lib/core";
import { pick, map, trim, isArray } from "lodash";
import { history } from "../../../utils";

import {
  personnelDataTableHeaders,
  personnelDataTableProps
} from "../../../config";
import { Person, PersonDTO, IMatch, IPerson } from "../../../model";
import { ePersonnelSaga, eUtilSaga } from "../../../sagas";

export interface IBktShowPersonnelProps {
  match: IMatch<{
    id: string;
  }>;
}

export interface IBktDataTablePersonnel extends IPerson {
  key: string;
}

export interface IBktPersonnelDataTableState {
  loading: boolean;
  // Max items to render in DataTable in one scroll
  maxItems?: number;
  personnel?: IBktDataTablePersonnel[] | undefined;
  newPersonnel?: IBktDataTablePersonnel[] | undefined;
}
export class BktShowPersonnel extends BktSaga<
  IBktShowPersonnelProps,
  IBktPersonnelDataTableState,
  {}
> {
  private filterVals = new Map();
  // Items to render in DataTable in one scroll at a time
  private readonly itemIncrementer = 5;
  private scrollMax = 10;

  // scrollPosition that is calculated based off of Div top position + offset height
  private scrollPosition = 0;
  private page = 0;
  private readonly size = 200;
  private get scrollEl() {
    return document.getElementById("personInfiniteScroll") as HTMLDivElement;
  }
  private get personDTO() {
    const personDTO = Object.assign(new PersonDTO(), { Size: this.size });
    this.filterVals.forEach((value, key) => {
      Object.assign(personDTO, { [key]: value });
    });
    return personDTO;
  }
  get searchPersonnel() {
    const searchPersonnel = {};
    this.filterVals.forEach((value, key) => {
      Object.assign(searchPersonnel, { [key]: value });
    });
    return searchPersonnel;
  }
  constructor(props: IBktShowPersonnelProps) {
    super(props);
    this.state = {
      loading: false,
      maxItems: 10,
      personnel: undefined,
      newPersonnel: undefined
    };
  }

  public componentDidMount() {
    this.configureSaga(
      new Map([
        [
          ePersonnelSaga.FETCH_PERSONNEL.toString(),
          {
            callback: this.loadPersonnel,
            validationType: ePersonnelSaga.FETCH_PERSONNEL_SUCCESS
          }
        ],
        [
          eUtilSaga.LOADING,
          { stateKey: getKeysFromState(this.state, ["loading"]) }
        ]
      ]),
      []
    );
  }

  public render() {
    const dataProps = ["FirstName", "LastName", "UserName", "Email"];
    const { loading, maxItems } = this.state;
    let { personnel } = this.state;
    personnel = personnel
      ? map(personnel, (p: IBktDataTablePersonnel) => {
          p = Object.assign(new Person(), p) as IBktDataTablePersonnel;
          return Object.assign(pick(p, dataProps) as IBktDataTablePersonnel, {
            key: p.Id
          });
        })
      : undefined;

    return (
      <div id="container" className="mdd-grid">
        <Paper id="headerContainer">
          <Typography id="headerTitle"> All Personnel</Typography>
          {/* Placeholder for next sprint.
                    <Button id="addNewPersonButton" variant="contained" size={'small'}>
                        <AddCircle id="addCircleIcon" />
                        Add New Person
                    </Button> */}
        </Paper>
        <Paper id="bodyContainer">
          <div>
            <BktDataTable
              data={personnel}
              defaultSortProp="LastName"
              loading={loading}
              infiniteScrollProps={{
                id: "personInfiniteScroll",
                maxItems,
                itemIncrementer: this.itemIncrementer,
                defaultScrollPosition: this.scrollPosition,
                onIncrement: async (component: BktInfiniteScroll) => {
                  /* 
                                        Guard to ensure that the amount of items allowed in the infinite scroll is greater than the amount of data and 
                                        that there are more pages to retrieve.
                                    */
                  this.scrollMax = component.state.maxItems;
                  if (
                    this.scrollMax >= (personnel || []).length &&
                    !((personnel || []).length < (this.page + 1) * this.size)
                  ) {
                    // Get the current scroll position prior to the component re-rendering
                    const { scrollTop, offsetHeight } = this.scrollEl;
                    this.scrollPosition = Math.round(
                      scrollTop || 0 + offsetHeight || 0
                    );
                    this.page += 1;
                    SagaService.dispatchSaga({
                      type: ePersonnelSaga.FETCH_PERSONNEL,
                      payload: {
                        dto: Object.assign(this.personDTO, { Page: this.page })
                      }
                    });
                  }
                }
              }}
              columnProps={personnelDataTableHeaders(this.handleChangeFilter)}
              rowActions={[
                {
                  isPrimary: true,
                  action: (
                    data: IBktDataTableData,
                    event: React.MouseEvent<HTMLDivElement>
                  ) => history.push(`/personnel/${data.key}`)
                }
              ]}
              {...personnelDataTableProps}
            />
          </div>
        </Paper>
      </div>
    );
  }
  public handleChangeFilter = async (event: any) => {
    const { name } = event.target;
    const value = event.target.value ? trim(event.target.value) : "";
    if (this.filterVals.has(name) && this.filterVals.get(name) === value) {
      return;
    }
    value ? this.filterVals.set(name, value) : this.filterVals.delete(name);
    Object.assign(this, { page: 0, scrollPosition: 0, scrollMax: 10 });

    if (this.filterVals.size < 1) {
      this.setState({ personnel: undefined });
      return;
    }
    SagaService.dispatchSaga({
      type: ePersonnelSaga.FETCH_PERSONNEL,
      payload: { dto: this.searchPersonnel }
    });
  };

  private loadPersonnel = (action: IAction) => {
    if (isArray(action.payload) && action.payload.length > -1) {
      this.setState({
        personnel: [
          ...((this.page || 0) > 0 ? this.state.personnel || [] : []),
          ...action.payload
        ],
        maxItems: this.scrollMax + this.itemIncrementer
      });
    }
  };
}
