import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BktGenericMenu } from "../../common";
import { IMenuList } from "../../../model";
import {
  studyMenuItemCategories,
  sponsorMenuItemCategories,
  siteMenuItemCategories,
  studySiteMenuItemCategories,
  personMenuItemCategories,
  studySitePersonnelMenuItemCategories,
  resMenuItemCategories
} from "../../../model/classes/menus";

export const withMddGenericMenu = (
  Component: any,
  menu: IMenuList,
  isEnabled: boolean,
  defaultSelectedItem:
    | studyMenuItemCategories
    | sponsorMenuItemCategories
    | siteMenuItemCategories
    | studySiteMenuItemCategories
    | personMenuItemCategories
    | studySitePersonnelMenuItemCategories
    | resMenuItemCategories
) => (props: RouteComponentProps<{}>) => {
  return (
    <BktGenericMenu
      {...props}
      menu={menu}
      enabled={isEnabled}
      selected={defaultSelectedItem}
    >
      <Component {...props} />
    </BktGenericMenu>
  );
};
