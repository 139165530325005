import { IIdNamePair } from "../../model/interfaces/iIdNamePair";

export enum eResConfig {
  GET_RES_CONFIG_LIST = "@@study/resConfig/GET_LIST",
  GET_RES_CONFIG_LIST_SUCCESS = "@@study/resConfig/GET_LIST_SUCCESS",
  GET_RES_CONFIG_LIST_WATCH = "@@study/resConfig/GET_LIST_WATCH",

  CREATE_RES_CONFIG = "@@study/resConfig/CREATE",
  CREATE_RES_CONFIG_SUCCESS = "@@study/resConfig/CREATE_SUCCESS",
  CREATE_RES_CONFIG_WATCH = "@@study/resConfig/CREATE_WATCH",

  GET_RES_CONFIG = "@@study/resConfig/GET",
  GET_RES_CONFIG_SUCCESS = "@@study/resConfig/GET_SUCCESS",
  GET_RES_CONFIG_WATCH = "@@study/resConfig/GET_WATCH",
  SAVE_RES_CONFIG = "@@study/resConfig/SAVE",
  SAVE_RES_CONFIG_WATCH = "@@study/resConfig/SAVE_WATCH",
  GET_DEMOGRAPHICS = "@@study/resConfig/GET_DEMOGRAPHICS",
  GET_DEMOGRAPHICS_SUCCESS = "@@study/resConfig/GET_DEMOGRAPHICS_SUCCESS",
  GET_DEMOGRAPHICS_WATCH = "@@study/resConfig/GET_DEMOGRAPHICS_WATCH",
  GET_INDICATIONS = "@@study/resConfig/GET_INDICATIONS",
  GET_INDICATIONS_SUCCESS = "@@study/resConfig/GET_INDICATIONS_SUCCESS",
  GET_INDICATIONS_WATCH = "@@study/resConfig/GET_INDICATIONS_WATCH",
  GET_SCALES = "@@study/resConfig/GET_SCALES",
  GET_SCALES_SUCCESS = "@@study/resConfig/GET_SCALES_SUCCESS",
  GET_SCALES_WATCH = "@@study/resConfig/GET_SCALES_WATCH",

  EDIT_ITEMS = "@@study/resConfig/EDIT_ITEMS",
  EDIT_ITEMS_WATCH = "@@study/resConfig/EDIT_ITEMS_WATCH",

  DELETE_ITEMS = "@@study/resConfig/DELETE_ITEMS",
  DELETE_ITEMS_WATCH = "@@study/resConfig/DELETE_ITEMS_WATCH",
  DELETE_RES_DRAFT = "@@study/resconfig/DELETE_RES_DRAFT",
  DELETE_RES_DRAFT_WATCH = "@@study/resconfig/DELETE_RES_DRAFT_WATCH",
  DELETE_RES_DRAFT_SUCCESS = "@@study/resconfig/DELETE_RES_DRAFT_SUCCESS",

  PUBLISH_RES_DRAFT = "@@study/resconfig/PUBLISH_RES_DRAFT",
  PUBLISH_RES_DRAFT_WATCH = "@@study/resconfig/PUBLISH_RES_DRAFT_WATCH",
  PUBLISH_RES_DRAFT_SUCCESS = "@@study/resconfig/PUBLISH_RES_DRAFT_SUCCESS",

  RESET = "@@study/resConfig/RESET",
  RESET_WATCH = "@@study/resConfig/RESET_WATCH"
}

export interface IEditItem {
  id?: string;
  parentId: string;
  childId?: string;
  isActive: boolean;
  softDeleted: boolean;
  checked?: boolean;
  nested?: boolean;
  name?: string;
  disabledOnModal?: boolean;
  disabledOnGrid?: boolean;
  deletable?: boolean;
}

export interface ITabData {
  fields: string[];

  /* full list of possible items. 'undefined' means not loaded */
  list?: IIdNamePair[];

  /* items selected */
  items: IEditItem[] | null;
  edit: IEditItem[];
}

export interface ILocalStorageState {
  version: string;
  demographics: IEditItem[];
  indications: IEditItem[];
  scales: IEditItem[];
}

export interface IState {
  version: string;
  general: ITabData;
  education: ITabData;
  demographics: ITabData;
  indications: ITabData;
  scales: ITabData;
  experience: ITabData;
}

interface IExperienceItem {
  id: string;
  itemId: string;
  isActive: boolean;
  deletable: boolean;
}

export interface IExperienceItemWithChild extends IExperienceItem {
  subItemId?: string;
}

interface ISection {
  groups: string[];
}

interface ISectionWithExperience extends ISection {
  experience: IExperienceItem[];
}

export interface IServerState {
  version: string;
  questionTypes: {
    general: ISection;
    education: ISection;
    demographics: ISectionWithExperience;
    indications: ISectionWithExperience;
    scales: ISectionWithExperience;
    experience: ISection;
  };
}

export interface IDemographicFields {
  general: string[];
  demographic: string[];
}
