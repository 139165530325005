import * as React from "react";
import { some, pick, trim, uniqBy } from "lodash";
import {
  BktDataTable,
  SagaService,
  IAction,
  IBktDataTableData
} from "front-end-lib/core";
import {
  defaultDataTableConfig,
  studySitesDataTableHeaders
} from "../../../config";
import { messages } from "../../../constants";
import {
  MddDataContainer,
  MddDialog,
  MddDialogButtons,
  withMddLookups,
  withMddStudy
} from "../../../components";
import {
  IMddStudyProps,
  ILookup,
  IStudySite,
  StudySite,
  IFilter
} from "../../../model";
import { eStudySaga } from "../../../sagas";
import { history, isLoading, filterDataForDataTable } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

interface IMddShowStudySitesProps extends IMddStudyProps {
  countries: ILookup[];
}
export interface IMddDataTableStudySite extends IStudySite, IBktDataTableData {}

export interface IMddStudySitesState {
  studySites?: IMddDataTableStudySite[];
  mode: "View" | "Delete";
}

const _MddShowStudySites = (props: IMddShowStudySitesProps) => {
  const {
    study,
    countries,
    match: {
      params: { id: routeStudyId }
    }
  } = props;
  const [state, setState] = React.useState<IMddStudySitesState>({
    studySites: undefined,
    mode: "View"
  });
  const { studySites, mode } = state;

  const initialStudySites = React.useRef<
    IMddDataTableStudySite[] | undefined
  >();
  const filterVals = React.useRef(new Map<string, IFilter>());
  const studySite = React.useRef<IStudySite>(new StudySite());
  const deletingStudySite = isLoading(
    `undefined_${eStudySaga.DELETE_STUDY_SITE}`
  );
  const tableConfig = {
    ...defaultDataTableConfig,
    ...{
      tableHeadProps: {
        ...defaultDataTableConfig.tableHeadProps,
        ...{ "data-testid": "tableHeader" }
      }
    },
    ...{
      tableBodyProps: {
        ...defaultDataTableConfig.tableBodyProps,
        ...{ "data-testid": "tableBody" }
      }
    }
  };
  const assignState = (newState: Partial<IMddStudySitesState>) =>
    setState(Object.assign({}, state, newState));
  const getFormattedStudySites = (rawSites?: IStudySite[]) => {
    return (rawSites || []).map((s: IStudySite) => {
      const dataTableSite = Object.assign(s, {
        key: s.SiteId,
        pi: `${s.PrimaryInvestigator!.FirstName} ${
          s.PrimaryInvestigator!.LastName
        }`
      });
      return Object.assign(
        pick(dataTableSite, [
          "siteName",
          "pi",
          "sponsorSiteId",
          "country",
          "key"
        ]) as IMddDataTableStudySite,
        { IsActive: dataTableSite.IsActive }
      );
    });
  };
  const handleFilterChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    exactMatch = false
  ) => {
    const key = e.target.name;
    let value = e.target.value ? trim(e.target.value) : "";
    // const filterVal: IFilter = {value, exactMatch};

    if (initialStudySites.current) {
      const filteredData = filterDataForDataTable(
        { key, value: { value, exactMatch } },
        filterVals.current,
        initialStudySites.current
      );
      if (filteredData) {
        assignState({ studySites: getFormattedStudySites(filteredData) });
      }
    }
  };
  const getDialogContent = () => (
    <div id="divTitle" key="textKey" className="displayRelative">
      <label id="lblDeleteStudySite" key="lblDeleteStudySite">
        {`Are you sure you want to remove ''${studySite.current.SiteName}'' from this study? All associated data will be deleted.`}
      </label>
    </div>
  );
  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const payload = new StudySite(study.StudyId, studySite.current.SiteId);
    SagaService.dispatchSaga({ type: eStudySaga.DELETE_STUDY_SITE, payload });
  };

  React.useEffect(() => {
    const removeFetchStudySitesSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITES,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_STUDY_SITES_SUCCESS) {
          initialStudySites.current = uniqBy<IMddDataTableStudySite>(
            action.payload,
            "SiteId"
          );
          assignState({
            studySites: getFormattedStudySites(initialStudySites.current)
          });
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SITES,
      payload: routeStudyId
    });

    const removeDeleteStudySiteSub = SagaService.subscribeToSaga(
      eStudySaga.DELETE_STUDY_SITE,
      (action: IAction) => {
        if (
          routeStudyId &&
          action.type === eStudySaga.DELETE_STUDY_SITE_SUCCESS
        ) {
          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_STUDY_SITES,
            payload: routeStudyId
          });
        }
      }
    );

    return () => {
      removeFetchStudySitesSub();
      removeDeleteStudySiteSub();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <MddDataContainer
      id="StudySites"
      breadcrumbs={[
        { display: "All Studies", route: "/study" },
        { display: study.StudyName }
      ]}
      addAction={{
        display: "Add New Association",
        action: () => history.push(`/study/${routeStudyId}/sites/new`)
      }}
      title="Study Sites"
    >
      <BktDataTable
        data={studySites}
        defaultSortProp="siteName"
        loading={isLoading(`undefined_${eStudySaga.FETCH_STUDY_SITES}`)}
        columnProps={studySitesDataTableHeaders(
          handleFilterChange,
          countries,
          filterVals.current as Map<string, IFilter>
        )}
        rowActions={[
          {
            isPrimary: true,
            action: (data: IBktDataTableData) =>
              history.push(`/study/${routeStudyId}/sites/${data.key}`)
          },
          {
            action: (data: IBktDataTableData) => {
              studySite.current = Object.assign(
                new StudySite(),
                data as IMddDataTableStudySite,
                { siteId: data.key }
              );
              assignState({ mode: "Delete" });
            },
            display: (
              <FontAwesomeIcon
                icon={faCircleXmark}
                fixedWidth
                id="deleteCircleIcon"
                data-testid="deleteCircleIcon"
                className="svgEditIconPointer"
                title="Delete"
              />
            )
          }
        ]}
        noRecordsMessage={
          filterVals.current.size > 0 && !some(studySites)
            ? messages.NO_RECORDS_FOUND
            : "No sites exist for this study"
        }
        {...tableConfig}
      />
      {mode === "Delete" && (
        <MddDialog
          submitting={deletingStudySite}
          dialogProps={{
            id: "divStudySitesDialog",
            open: true
          }}
          dialogActionProps={{ id: "divStudySitesDialogActions" }}
          dialogContentProps={{
            id: "divStudySitesDialogContent",
            className: "mdd-dialog--content-medium"
          }}
          dialogTitleProps={{
            id: "divStudySitesDialofTitle",
            title: "Remove Study Site"
          }}
          dialogContent={getDialogContent()}
          dialogActions={
            <MddDialogButtons
              saveButtonProps={{
                disabled: deletingStudySite,
                onClick: handleDelete
              }}
              saveButtonText="DELETE"
              closeButtonProps={{
                disabled: deletingStudySite,
                onClick: () => assignState({ mode: "View" })
              }}
            />
          }
        />
      )}
    </MddDataContainer>
  );
};

export const BktShowStudySites = withMddLookups(["countries"])(
  withMddStudy()(_MddShowStudySites)
);
