import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { assign, isEqual } from "lodash";
import { IApplicationState } from "../../../store";
import {
  SagaService,
  IAction,
  BktValidatedSelectChipField,
  BktSelect
} from "front-end-lib/core";
import { Chip, Divider } from "@material-ui/core";
import {
  MddForm,
  MddCheckbox,
  MddBreadcrumb,
  MddDialogButtons,
  MddStudySitePersonComments
} from "../../../components";
import {
  IStudySitePerson,
  IPersonParticipatingSite,
  ILookup,
  Role,
  IRole,
  IStudy,
  ISite,
  StudyProtocol,
  IStudyPersonnelProtocol,
  IStudyProtocolDTO,
  IStudyPersonnelRole,
  Validations,
  ISelectChangeEvent,
  IStudySite
} from "../../../model";
import { eStudySaga, eLookupSaga, eSiteSaga } from "../../../sagas";
import {
  activeStatuses,
  NO_DATA_DISPLAY_TEXT,
  PI_TEXT
} from "../../../constants";
import { rolesIncludePI, isLoading, sortObjectArray } from "../../../utils";

interface IMddEditStudySitePersonnelProps {
  match: {
    params: {
      id: string;
      siteId: string;
      personnelId: string;
    };
  };
  currStudy: IStudy;
  currSite: ISite;
}

export const MddEditStudySitePersonnelComponent = (
  props: IMddEditStudySitePersonnelProps
) => {
  const [person, setPerson] = React.useState<IStudySitePerson | undefined>(
    undefined
  );
  const [origPerson, setOrigPerson] = React.useState<
    IStudySitePerson | undefined
  >(undefined);
  const [protocols, setProtocols] = React.useState<StudyProtocol[]>([]);
  const [filteredRoles, setFilteredRoles] = React.useState<ILookup[]>([]);
  const [piGuid, setPiGuid] = React.useState("");
  const [validationError, setValidationError] = React.useState(false);
  const [studySite, setStudySite] = React.useState<IStudySite | null>(null);
  const personRef = React.useRef(person);
  personRef.current = person;

  React.useEffect(() => {
    const { id, siteId, personnelId } = props.match.params;
    const { currStudy, currSite } = props;

    const removeFetchPerson = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITE_PERSON,
      personFetchCallback
    );
    const removeFetchRoles = SagaService.subscribeToSaga(
      eLookupSaga.FETCH_ROLES,
      setRolesInState
    );
    const removeFetchProtocols = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_PROTOCOLS,
      setProtocolsInState
    );

    const removeUpdatePersonSub = SagaService.subscribeToSaga(
      eStudySaga.UPDATE_STUDY_SITE_PERSON,
      (action: IAction) => {
        if (action.type === eStudySaga.UPDATE_STUDY_SITE_PERSON_SUCCESS) {
          setOrigPerson(
            Object.assign({} as IStudySitePerson, personRef.current)
          );
        }
      }
    );

    const removeFetchStudySite = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITE,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_STUDY_SITE_SUCCESS) {
          setStudySite(action.payload);
        }
      }
    );

    // only fetch the study and site if needed (they went directly to this page without
    // first going through a previous one that already loaded it into the redux store)
    if (!currStudy || !currStudy.StudyId || currStudy.StudyId !== id) {
      SagaService.dispatchSaga({ type: eStudySaga.FETCH_STUDY, payload: id });
    }
    if (!currSite || currSite.SiteId !== siteId) {
      SagaService.dispatchSaga({ type: eSiteSaga.FETCH_SITE, payload: siteId });
    }

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON.toString(),
      payload: {
        dto: { StudyId: id, SiteId: siteId, PersonnelId: personnelId }
      }
    });

    SagaService.dispatchSaga({ type: eLookupSaga.FETCH_ROLES });
    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_PROTOCOLS,
      payload: id
    });

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SITE,
      payload: { StudyId: id, SiteId: siteId }
    });

    return () => {
      removeFetchPerson();
      removeFetchRoles();
      removeFetchProtocols();
      removeUpdatePersonSub();
      removeFetchStudySite();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // they clicked on the link for another site, we need to load that site, person
    // and study site (to get the study site protocols)

    const { id, siteId, personnelId } = props.match.params;
    const { currSite } = props;

    const removeFetchPerson = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITE_PERSON,
      personFetchCallback
    );

    // If already loading, then don't try again.  This is to correct a flaw in this logic where
    // when it's first mounted this also gets triggered when the mount effect is already loading it.
    if (!isLoading(`undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON}`)) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_SITE_PERSON.toString(),
        payload: {
          dto: { StudyId: id, SiteId: siteId, PersonnelId: personnelId }
        }
      });
    }

    // If already loading or we already have a site, then don't load again.  This loading check is to correct a flaw in this logic where
    // when it's first mounted this also gets triggered when the mount effect is already loading it.
    if (
      (!currSite || currSite.SiteId !== siteId) &&
      !isLoading(`undefined_${eSiteSaga.FETCH_SITE}`)
    ) {
      SagaService.dispatchSaga({ type: eSiteSaga.FETCH_SITE, payload: siteId });
    }

    // If already loading study site, then don't try again.  This is to correct a flaw in this logic where
    // when it's first mounted this also gets triggered when the mount effect is already loading it.
    if (!isLoading(`undefined_${eStudySaga.FETCH_STUDY_SITE}`)) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_SITE.toString(),
        payload: { StudyId: id, SiteId: siteId }
      });
    }

    return () => {
      removeFetchPerson();
    };
    // eslint-disable-next-line
  }, [props.match.params]);

  const personFetchCallback = (action: IAction) => {
    const { type, payload } = action;
    if (type === eStudySaga.FETCH_STUDY_SITE_PERSON_SUCCESS) {
      setPerson(formatPerson(payload));
      setOrigPerson(formatPerson(payload));
    }
  };

  const formatPerson = (person: any) => {
    return Object.assign(
      { ...person },
      {
        roles: person.roles.map((r: IStudyPersonnelRole) => {
          return { RoleId: r.roleId, Name: r.name };
        }),
        protocols: person.protocols.map((p: IStudyProtocolDTO) => {
          return { id: p.id, protocolName: p.protocolName };
        })
      }
    );
  };

  const setProtocolsInState = (action: IAction) => {
    const { type, payload } = action;
    if (type === eStudySaga.FETCH_STUDY_PROTOCOLS_SUCCESS) {
      setProtocols(sortObjectArray(payload, "ProtocolName"));
    }
  };

  const setRolesInState = (action: IAction) => {
    const { type, payload } = action;
    if (type === eLookupSaga.FETCH_ROLES_SUCCESS) {
      const roles = payload.map((r: IRole) => {
        const role = assign(new Role(), r);
        return {
          value: role.RoleId,
          label: role.Name
        } as ILookup;
      });
      if (roles && roles.length) {
        // Filter out the site PI role for dropdown purposes.
        // Also, save away the GUID for the PI role so we can use
        // it for comparison purposes later.
        const filteredRoles: ILookup[] = payload
          .filter((r: IRole) => {
            const role = assign(new Role(), r);
            if (role.IsSitePi) {
              setPiGuid(role.RoleId);
              return false;
            }
            return true;
          })
          .map((r: IRole) => {
            const role = assign(new Role(), r);
            return {
              value: role.RoleId,
              label: role.Name
            } as ILookup;
          });
        setFilteredRoles(filteredRoles);
      }
    }
  };

  const getRoles = () => {
    const roles =
      person && person.roles
        ? person.roles
            .map(r => r.RoleId)
            .filter(id => {
              return id !== piGuid;
            })
        : [];
    return roles;
  };

  const isProtocolActive = (protocol: StudyProtocol) => {
    return studySite &&
      studySite.Protocols &&
      studySite.Protocols.find(
        (x: IStudyPersonnelProtocol) => x.id === protocol.ProtocolId
      )
      ? true
      : false;
  };

  const participatesInOtherSites = () => {
    return (
      person &&
      person.participatingSites &&
      person.participatingSites.length > 0
    );
  };

  const formatPersonName = () => {
    return `${person ? person.firstName : ""} ${person ? person.lastName : ""}`;
  };

  const formatParticipatingSite = (site: IPersonParticipatingSite) => {
    const { id, personnelId } = props.match.params;
    return (
      <div
        className="mdd-study-site-personnel-div--link"
        key={`link_${site.siteId}`}
      >
        <Link to={`/study/${id}/sites/${site.siteId}/personnel/${personnelId}`}>
          <div className="mdd-personnel-div--indent">{site.siteName}</div>
        </Link>
        {!site.isActive ? ` (Inactive)` : ""}
      </div>
    );
  };

  const handleRoleChange = (event: React.ChangeEvent<ISelectChangeEvent>) => {
    // Cast as any is necessary because the value is actually a string array
    const roles = (event.target.value as any).map((r: string) =>
      Object.assign(new Role(), { RoleId: r })
    );

    // If they are a PI we need to put the PI role back in
    if (rolesIncludePI(person!.roles, piGuid)) {
      roles.push(Object.assign(new Role(), { RoleId: piGuid }));
    }

    const newPerson = Object.assign({ ...person, roles: roles });
    setPerson(newPerson);
  };

  const handleSaveStudySitePerson = () => {
    const { id, siteId } = props.match.params;

    const filteredPerson: IStudySitePerson = Object.assign(
      { ...person },
      person,
      {
        roles: person!.roles.filter(r => {
          return r.RoleId !== piGuid;
        })
      }
    );
    SagaService.dispatchSaga({
      type: eStudySaga.UPDATE_STUDY_SITE_PERSON,
      payload: { id, siteId, person: filteredPerson }
    });
  };

  const { id, siteId, personnelId } = props.match.params;
  const { currStudy, currSite } = props;

  const personIsPi =
    person && rolesIncludePI(person.roles, piGuid) ? true : false;

  const showChip = person && (personIsPi || !person.isActive) ? true : false;

  return (
    <React.Fragment>
      <MddBreadcrumb
        breadcrumbs={[
          { display: "All Studies", route: "/study" },
          {
            display:
              currStudy && currStudy.StudyName
                ? currStudy.StudyName
                : "Loading...",
            route: `/study/${id}`
          },
          {
            display:
              currSite && currSite.SiteName ? currSite.SiteName : "Loading...",
            route: `/study/${id}/sites/${siteId}/personnel`
          },
          { display: formatPersonName() }
        ]}
      />
      <MddForm
        keys={[
          `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON}`,
          `_${eLookupSaga.FETCH_ROLES}`,
          `undefined_${eStudySaga.FETCH_STUDY_PROTOCOLS}`,
          `undefined_${eStudySaga.UPDATE_STUDY_SITE_PERSON}`,
          `undefined_${eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT}`,
          `undefined_${eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT}`,
          `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS}`,
          `undefined_${eStudySaga.FETCH_STUDY_SITE}`
        ]}
        id="mdd-form-personnel"
      >
        <div className="mdd-form--body">
          {/* flex container for the two main side by side divs */}
          <div className="mdd-study-site-personnel-div--flex-container">
            {/* 1st of the main side by side divs */}
            <div className="mdd-study-site-personnel-div--flex-child-left">
              <div className="mdd-study-site-personnel-div--flex-child-left--column1">
                <div
                  className="mdd-study-site-personnel-div--header"
                  id="divGeneral"
                >
                  General
                </div>
                <div
                  className="mdd-study-site-personnel-div--label-link"
                  id="lblName"
                >
                  Name
                </div>
                <Link
                  to={`/personnel/${personnelId}`}
                  data-testid="name"
                  id="personName"
                >
                  {formatPersonName()}
                </Link>

                <div
                  className="mdd-study-site-personnel-div--label"
                  id="lblPhoneNumber"
                >
                  Phone Number
                </div>
                <div
                  className="mdd-study-site-personnel-div--data"
                  data-testid="phoneNumber"
                  id="phoneNumber"
                >
                  {person &&
                  person.studySitePersonnelContactInfo &&
                  person.studySitePersonnelContactInfo.phone
                    ? person.studySitePersonnelContactInfo.phone
                    : NO_DATA_DISPLAY_TEXT}
                </div>
                <div
                  className="mdd-study-site-personnel-div--label"
                  id="lblMobilePhone"
                >
                  Mobile Phone Number
                </div>
                <div
                  className="mdd-study-site-personnel-div--data"
                  data-testid="mobilePhoneNumber"
                  id="mobilePhone"
                >
                  {person &&
                  person.studySitePersonnelContactInfo &&
                  person.studySitePersonnelContactInfo.mobilePhone
                    ? person.studySitePersonnelContactInfo.mobilePhone
                    : NO_DATA_DISPLAY_TEXT}
                </div>
                <div
                  className="mdd-study-site-personnel-div--label"
                  id="lblEmailAddress"
                >
                  Email Address
                </div>
                <div
                  className="mdd-study-site-personnel-div--data"
                  data-testid="email"
                  id="email"
                >
                  {person && person.email ? person.email : NO_DATA_DISPLAY_TEXT}
                </div>
                <div className="mdd-study-site-personnel--divider">
                  <Divider />
                </div>
                {!personIsPi && (
                  <MddCheckbox
                    lblText="Active at Site"
                    chkBoxProps={{
                      id: "chkStudySitePersonActive",
                      checked: person ? person.isActive : false,
                      value: "isActive",
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setPerson(
                          Object.assign({ ...person }, person, {
                            isActive: !person!.isActive
                          })
                        );
                      }
                    }}
                  />
                )}
                <BktValidatedSelectChipField
                  id={"personRole"}
                  name={"personRole"}
                  inputLabelText={"Role"}
                  inputLabelProps={{ required: !personIsPi }}
                  data={filteredRoles}
                  selectProps={{
                    disabled: person ? !person.isActive : true,
                    variant: "outlined",
                    required: !personIsPi
                  }}
                  value={getRoles()}
                  onChange={handleRoleChange}
                  // a role is required only when not PI
                  validationForOnBlur={
                    !personIsPi ? [Validations.required] : []
                  }
                  validationForOnChange={
                    !personIsPi ? [Validations.required] : []
                  }
                  validationerror={(validationError: boolean) =>
                    setValidationError(validationError)
                  }
                />
              </div>
              <div
                className="mdd-study-site-personnel-div--flex-child-left--column2"
                data-testid="leftColumn"
              >
                <div
                  className={
                    showChip
                      ? "mdd-study-site-personnel-div--chip"
                      : "notVisible"
                  }
                  data-testid="statusChip"
                  id="divStatusChip"
                >
                  <Chip
                    classes={{
                      colorSecondary: "mdd-study-site-personnel--inactive-chip"
                    }}
                    color={person && !person.isActive ? "secondary" : "primary"}
                    id="statusChip"
                    label={
                      person && !person.isActive
                        ? "Inactive"
                        : personIsPi
                        ? PI_TEXT
                        : ""
                    }
                  />
                </div>
                <div
                  className={
                    showChip
                      ? "mdd-study-site-personnel-div--label"
                      : "mdd-study-site-personnel-div--label-no-chip"
                  }
                  id="divOtherSites"
                >
                  Participation in other Study Sites
                </div>
                {participatesInOtherSites() && (
                  <React.Fragment>
                    {person!.participatingSites.map(site => {
                      return formatParticipatingSite(site);
                    })}
                  </React.Fragment>
                )}
                {!participatesInOtherSites() && (
                  <React.Fragment>
                    <div data-testid="otherSitesNoData">
                      {NO_DATA_DISPLAY_TEXT}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>

            {/* 2nd of the main side by side divs */}
            <div
              className="mdd-study-site-personnel-div--flex-child-right"
              data-testid="rightPane"
            >
              <div
                className="mdd-study-site-personnel-div--header"
                id="divProtocols"
              >
                Study Protocol Participation
              </div>
              {protocols.map(prot => {
                return (
                  <React.Fragment key={prot.ProtocolId}>
                    <div>{prot.ProtocolName}</div>
                    <BktSelect
                      key={`key_${prot.ProtocolId}`}
                      menuProps={{}}
                      textProps={{
                        id: `bktSelectFieldProtocol_${prot.ProtocolId}`,
                        label: "",
                        value: isProtocolActive(prot),
                        className: "mdd-form--text",
                        variant: "outlined",
                        disabled: true
                      }}
                      selectData={activeStatuses}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          {/* end main flex container */}

          <div className="mdd-study-site-personnel-div--button">
            <MddDialogButtons
              saveButtonProps={{
                id: "btnSavePersonInfo",
                disabled:
                  isEqual(person, origPerson) ||
                  validationError ||
                  isLoading(`undefined_${eStudySaga.UPDATE_STUDY_SITE_PERSON}`),
                onClick: handleSaveStudySitePerson
              }}
              closeButtonProps={{
                id: "btnCloseEdit",
                className: "notVisible"
              }}
            />
          </div>
        </div>
        <MddStudySitePersonComments
          studyId={id}
          siteId={siteId}
          personnelId={personnelId}
        />
      </MddForm>
    </React.Fragment>
  );
};

export const MddEditStudySitePersonnel = connect(
  (state: IApplicationState) => ({
    currStudy: state.study.currentStudy,
    currSite: state.site.currentSite
  })
)(MddEditStudySitePersonnelComponent);
