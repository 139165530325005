import {
  mdiAccountCardDetails,
  mdiAccountMultiple,
  mdiCommentMultiple
} from "@mdi/js";
import { MenuListItem, IMenuList } from "../../../model";

export enum studySiteMenuItemCategories {
  GeneralInfo = "General",
  Personnel = "Personnel",
  Comments = "Comments"
}

export class StudySiteMenuContainer implements IMenuList {
  // This will be the interface structure used for the component to make it generic
  public root: string;
  public data: any;
  public enabled: boolean;
  public menuItems: MenuListItem[];
  public rootUrl: string;
  public constructor() {
    this.root = "All Studies";
    this.rootUrl = "/";
    this.menuItems = this.getMenu();
  }
  public getMenu() {
    const menuList = [
      new MenuListItem(
        "General Info",
        mdiAccountCardDetails,
        "/study/:id/sites/" + this.enabled ? ":siteId" : "new",
        studySiteMenuItemCategories.GeneralInfo
      ),
      new MenuListItem(
        "Personnel",
        mdiAccountMultiple,
        "/study/:id/sites/:siteId/personnel",
        studySiteMenuItemCategories.Personnel
      ),
      new MenuListItem(
        "Comments",
        mdiCommentMultiple,
        "/study/:id/sites/:siteId/comments",
        studySiteMenuItemCategories.Comments
      )
    ];
    return menuList;
  }
}
