import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { MddDialog, MddDialogButtons } from "../../../components";
import { IIdNamePair } from "../../../model";
import { IEditItem } from "../../../store/resConfig/types";
import { IApplicationState } from "../../../store";
import {
  calculateItems,
  handleCheckedChange
} from "../../../store/resConfig/selectors";
import { MddResScaleDialogContent } from "./MddResScaleDialogContent";

interface IProps {
  isOpen: boolean;
  items: IEditItem[];
  edit: IEditItem[];
  scales: IIdNamePair[];
  onClose: (edits?: IEditItem[]) => void;
}

const MddResScaleDialogComponent = ({
  isOpen,
  onClose,
  edit: editProps,
  items,
  scales
}: IProps) => {
  const [edits, setEdits] = useState<IEditItem[]>([]);
  const state = useMemo<IEditItem[]>(
    () =>
      isOpen
        ? calculateItems({
            edits,
            items,
            parents: scales,
            childs: [],
            editState: editProps
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [edits, isOpen]
  );

  useEffect(() => {
    if (isOpen) {
      setEdits([...editProps.map(m => ({ ...m }))]);
    }
  }, [editProps, isOpen]);

  const handleChange = (item: IEditItem) => {
    setEdits(handleCheckedChange(item, edits));
  };

  return (
    <MddDialog
      dialogProps={{ open: isOpen, maxWidth: "md", fullWidth: true }}
      dialogTitleProps={{
        title: "Add Scale(s)"
      }}
      dialogContentProps={{ className: "mdd-res-draft--dialog" }}
      dialogContent={
        <MddResScaleDialogContent handleChange={handleChange} items={state} />
      }
      onCloseClick={() => {
        onClose();
      }}
      dialogActions={
        <MddDialogButtons
          saveButtonText="Add Scale(s)"
          saveButtonProps={{
            onClick: () => onClose(edits),
            disabled: !state.some(i => i.checked && !i.disabledOnModal)
          }}
          closeButtonProps={{ onClick: () => onClose() }}
        />
      }
      showCloseButton
    />
  );
};

export const MddResScaleDialog = connect(
  ({ resConfig }: IApplicationState) => ({
    items: resConfig.scales.items!,
    scales: resConfig.scales.list!,
    edit: resConfig.scales.edit
  })
)(MddResScaleDialogComponent);
