import { pick, omit } from "lodash";
import { ConfigOptions } from "../config";
import { toQueryParams } from "../utils";
import { IPersonnelPayload, IPerson, Person } from "../model";
import { callApi } from "../utils";
import { eHTTPMethods } from "../constants";
import { HTTP_RESPONSE_MESSAGES } from "../config";

export abstract class PersonnelService {
  public static async getPersonnel(personPayload: IPersonnelPayload) {
    const { dto } = personPayload;
    const pageQueryParam = { Page: dto.Page, Size: dto.Size };
    return await callApi(
      eHTTPMethods.POST,
      `${ConfigOptions.personnelApiPath}${toQueryParams(pageQueryParam)}`,
      omit(dto, "size", "page"),
      HTTP_RESPONSE_MESSAGES.API_GET_METHOD_FAIL_ERROR
    );
  }

  public static async getManagedPersonnel(personPayload: IPersonnelPayload) {
    const { dto } = personPayload;
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.managedPersonnelPath}/${toQueryParams(
        pick(dto, ["Page", "Size", "FirstName", "LastName", "Email"])
      )}`
    );
  }

  public static async getPerson(personId: string): Promise<IPerson> {
    const val = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.personnelApiPath}/${personId}`
    );
    return Object.assign(new Person(), val) as IPerson;
  }

  public static async savePerson(person: IPerson): Promise<IPerson | boolean> {
    const payload = Object.assign(
      {},
      pick(person.PersonnelContactInfo, [
        "emailAddress2",
        "emailAddress3",
        "phone",
        "mobilePhone",
        "fax"
      ])
    );
    const val = await callApi(
      eHTTPMethods.PUT,
      `${ConfigOptions.personnelApiPath}/${person.Id}`,
      payload
    );
    return val;
  }
}
