import * as React from "react";
import { isEqual, pick } from "lodash";
import {
  BktDataTable,
  BktValidatedTextField,
  BktTypeaheadSelect,
  Filter,
  eFilter,
  IAction,
  IBktDataTableData,
  SagaService,
  BktValidatedSelectChipField,
  ISelectData,
  SortType,
  BktSelect
} from "front-end-lib/core";
import { Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faPenToSquare
} from "@fortawesome/free-regular-svg-icons";
import { withMddStudy } from "../../higher-order";
import { withMddLookups } from "../../higher-order/WithMddLookups/WithMddLookups";
import { MddDataContainer, MddDialog, MddDialogButtons } from "../../common";
import { MddCheckbox } from "../../../components";
import { defaultDataTableConfig } from "../../../config";
import {
  classConstants,
  MAX_TEXT_LENGTH,
  activeStatuses
} from "../../../constants";
import { QUALIFICATION_TYPE } from "../../../constants/applicationConstants";
import {
  IMddStudyProps,
  IStudyQualification,
  IStudyScale,
  StudyQualification,
  ILookupNameValue,
  Validations,
  ILearningPlan,
  IFilter,
  ISelectChangeEvent
} from "../../../model";
import { ILookup } from "../../../model/interfaces/iLookup";

import { eStudySaga } from "../../../sagas";
import {
  isLoading,
  filterDataForDataTable,
  sortStatus,
  sortObjectArray
} from "../../../utils";
import { MddStatus } from "../../common";

interface IMddShowStudyQualificationsProps extends IMddStudyProps {
  qualificationTypes: ILookup[];
}
const _MddShowStudyQualifications = (
  props: IMddShowStudyQualificationsProps
) => {
  const {
    study,
    match: {
      params: { id: studyId }
    },
    qualificationTypes
  } = props;

  const [studyQuals, setStudyQuals] = React.useState<
    IStudyQualification[] | undefined
  >(undefined);
  const [scales, setScales] = React.useState<IStudyScale[]>([]);
  const [learningPlans, setLearningPlans] = React.useState<ILearningPlan[]>([]);
  const [mode, setMode] = React.useState<"View" | "Add" | "Edit" | "Delete">(
    "View"
  );
  const [qual, setQual] = React.useState<IStudyQualification>(
    new StudyQualification()
  );
  const [origEditQual, setOrigEditQual] = React.useState<IStudyQualification>(
    new StudyQualification()
  );

  const loadingSave = isLoading(
    `undefined_${eStudySaga.SAVE_STUDY_QUALIFICATION}`
  );
  const deleteInProgress = isLoading(
    `undefined_${eStudySaga.DELETE_STUDY_QUALIFICATION}`
  );

  const loadingQualPlans =
    isLoading(`undefined_${eStudySaga.FETCH_STUDY_QUALIFICATION}`) ||
    isLoading(`undefined_${eStudySaga.FETCH_LEARNING_PLANS}`);

  const setStudyQualification = (studyQualification: IStudyQualification) => {
    setQual(Object.assign(studyQualification, { studyId }));
  };

  let initialStudyQuals = React.useRef<IStudyQualification[]>([]);
  let noRecordsMessage = React.useRef<string | undefined>(undefined);
  const filterVals = React.useRef(new Map<string, IFilter>());
  const modeRef = React.useRef(mode);
  const qualRef = React.useRef(qual);
  qualRef.current = qual;

  const handleFilterChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    exactMatch = false
  ) => {
    const value = e.target.value ? e.target.value.trim() : "";
    const key = e.target.name;
    if (initialStudyQuals.current.length) {
      const filteredStudyQuals = filterDataForDataTable(
        { key, value: { value, exactMatch } },
        filterVals.current,
        initialStudyQuals.current
      );
      setStudyQuals(filteredStudyQuals);
    } else {
      setStudyQuals(initialStudyQuals.current);
    }
  };

  const findLearningPlanById = (id: string) => {
    return learningPlans.find(lp => lp.id === id);
  };
  const handleLearningPlanChange = (
    event: React.ChangeEvent<ISelectChangeEvent>
  ) => {
    // Cast as any is necessary because the value is actually a string array
    const selectedPlans = (event.target.value as any).map((lp: string) => {
      return findLearningPlanById(lp);
    });

    setQual(
      Object.assign(new StudyQualification(), qual, {
        LearningPlans: selectedPlans
      })
    );
  };

  const getFormattedQuals = (quals: IStudyQualification[]) => {
    return (quals || []).map((q: IStudyQualification) => {
      return Object.assign(q, {
        qual: q,
        key: q.StudyQualificationId
      });
    });
  };

  React.useEffect(() => {
    const removeStudyQual = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_QUALIFICATIONS,
      (action: IAction) => {
        const { type, payload } = action;
        if (type === eStudySaga.FETCH_STUDY_QUALIFICATIONS_SUCCESS) {
          const formattedPayload = getFormattedQuals(payload);
          initialStudyQuals.current = formattedPayload;
          setStudyQuals(formattedPayload);
          if (formattedPayload && formattedPayload.length === 0) {
            noRecordsMessage.current =
              "There are no study qualifications associated with this study.";
          }
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_QUALIFICATIONS,
      payload: studyId
    });

    const removeStudyScales = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SCALES,
      (action: IAction) => {
        const { type, payload } = action;
        if (type === eStudySaga.FETCH_STUDY_SCALES_SUCCESS) {
          setScales(sortObjectArray(payload, "name"));
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SCALES,
      payload: studyId
    });

    const removeAddQual = SagaService.subscribeToSaga(
      eStudySaga.SAVE_STUDY_QUALIFICATION,
      (action: IAction) => {
        const { type } = action;
        if (type === eStudySaga.SAVE_STUDY_QUALIFICATION_SUCCESS) {
          setMode("View");
          setStudyQualification(new StudyQualification());
          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_STUDY_QUALIFICATIONS,
            payload: studyId
          });
        }
      }
    );

    const removeDeleteQual = SagaService.subscribeToSaga(
      eStudySaga.DELETE_STUDY_QUALIFICATION,
      (action: IAction) => {
        const { type } = action;
        if (type === eStudySaga.DELETE_STUDY_QUALIFICATION_SUCCESS) {
          setMode("View");
          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_STUDY_QUALIFICATIONS,
            payload: studyId
          });
        }
      }
    );

    const removeLearningPlans = SagaService.subscribeToSaga(
      eStudySaga.FETCH_LEARNING_PLANS,
      (action: IAction) => {
        const { type, payload } = action;
        if (payload) {
          if (type === eStudySaga.FETCH_LEARNING_PLANS_SUCCESS) {
            // Need to convert integer ID to string for our dropdown.  Also, sort alphabetically.
            const lps = payload.map((lp: any) => {
              return { id: lp.id.toString(), name: lp.name };
            });

            setLearningPlans(sortObjectArray(lps, "name"));
          }
        }
      }
    );

    const removeFetchStudyQualification = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_QUALIFICATION,
      (action: IAction) => {
        const { type, payload } = action;
        if (type === eStudySaga.FETCH_STUDY_QUALIFICATION_SUCCESS) {
          const lps = payload.learningPlans;
          const newQual: IStudyQualification = Object.assign(
            new StudyQualification(),
            {
              ...payload,
              StudyQualificationId: qualRef.current.StudyQualificationId,
              LearningPlans: lps.map((lp: any) => {
                return { id: lp.id.toString(), name: lp.name };
              })
            }
          );
          setStudyQualification(newQual);
          setOrigEditQual(newQual);
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_LEARNING_PLANS,
      payload: studyId
    });

    return () => {
      removeStudyQual();
      removeAddQual();
      removeStudyScales();
      removeDeleteQual();
      removeLearningPlans();
      removeFetchStudyQualification();
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (mode === "Edit" && modeRef.current !== "Edit") {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_QUALIFICATION,
        payload: qual
      });
    }
    if (mode !== modeRef.current) {
      modeRef.current = mode;
    }
  }, [qual, mode]);

  const isExperienceDisabled = (qualType: string) => {
    let isScaleSelected = false;
    if (mode === "Edit") {
      if (qualRef.current.ScaleName && qualRef.current.ScaleName.length) {
        isScaleSelected = true;
      }
    } else {
      isScaleSelected =
        qualRef.current.ScaleId !== undefined &&
        qualRef.current.ScaleId.length > 0;
    }

    if (
      (qualType && qualType !== QUALIFICATION_TYPE.TRAINING) ||
      !isScaleSelected
    ) {
      return true;
    }
    return false;
  };

  const isScaleDisabled = () => {
    if (
      mode === "Edit" ||
      scales.length === 0 ||
      loadingSave ||
      qualRef.current.QualificationType ===
        QUALIFICATION_TYPE.RATER_EXPERIENCE ||
      qualRef.current.QualificationType ===
        QUALIFICATION_TYPE.RATER_IDENTIFICATION
    ) {
      return true;
    }
    return false;
  };

  const handleQualTypeChange = (val: string) => {
    setStudyQualification(
      Object.assign(new StudyQualification(), qualRef.current, {
        QualificationType: val,
        // blank out scale ID and name if RES or RIS
        ScaleId:
          val === QUALIFICATION_TYPE.RATER_EXPERIENCE ||
          val === QUALIFICATION_TYPE.RATER_IDENTIFICATION
            ? ""
            : qualRef.current.ScaleId,
        ScaleName:
          val === QUALIFICATION_TYPE.RATER_EXPERIENCE ||
          val === QUALIFICATION_TYPE.RATER_IDENTIFICATION
            ? ""
            : qualRef.current.ScaleName,
        IsExperienceRequired: isExperienceDisabled(val)
          ? false
          : qualRef.current.IsExperienceRequired
      })
    );
    // This is a workaround, the front-end-lib sets the value of this field based on
    // the handleTextChange function which won't get called with a manual assignment
    // of the value in state above.  Until we can fix that this will clear out the field.
    if (
      val === QUALIFICATION_TYPE.RATER_EXPERIENCE ||
      val === QUALIFICATION_TYPE.RATER_IDENTIFICATION
    ) {
      const txtScale = document.getElementById(
        "txtScaleName"
      ) as HTMLInputElement;
      txtScale.value = "";
    }
  };

  const getDisplayScaleName = () => {
    return mode === "Edit"
      ? qual.ScaleName
        ? qual.ScaleName
        : ""
      : qual.ScaleId;
  };

  const getContent = () => {
    const loadingStudyScales = isLoading(
      `undefined_${eStudySaga.FETCH_STUDY_SCALES}`
    );
    const data = !learningPlans
      ? []
      : (learningPlans.map((lp: ILearningPlan) => {
          return {
            value: lp.id,
            label: lp.name
          } as ISelectData;
        }) as ISelectData[]);

    return [
      <Grid container spacing={10} key="grid">
        <Grid item xs={6} className="mdd-qualification--content--grid">
          <div
            className={
              mode === "Edit"
                ? "mdd-qualification--disabled-text mdd-qualification--content--header"
                : "mdd-qualification--content--header"
            }
          >
            Qualification Type
          </div>
          <BktSelect
            key={`key_abc`}
            menuProps={{}}
            textProps={{
              id: "qualType",
              label: "",
              value: qual.QualificationType,
              className: "mdd-qualification--content--select",
              onChange: event => handleQualTypeChange(event.target.value),
              disabled: mode === "Edit"
            }}
            selectData={qualificationTypes}
            selectMenuProps={{
              className: "mdd-qualification--content--select--div"
            }}
          />
          <div
            className={
              mode === "Edit"
                ? "mdd-qualification--disabled-text mdd-qualification--content--header-padded"
                : "mdd-qualification--content--header-padded"
            }
          >
            Scale
          </div>
          <BktTypeaheadSelect
            selectData={
              scales
                ? scales.map((sc: IStudyScale) => ({
                    value: sc.ScaleId,
                    label: sc.Name.trim()
                  }))
                : []
            }
            key="txtEditScaleName"
            textProps={{
              textProps: {
                label: "",
                className: "mdd-qualification--content--select",
                InputLabelProps: { shrink: true },
                inputProps: {
                  className: "mdd-qualification--content--input",
                  id: "txtScaleName"
                },
                placeholder: !qual.ScaleName
                  ? loadingStudyScales
                    ? "Loading Scales..."
                    : "Start typing to select a scale."
                  : "",
                disabled: isScaleDisabled(),
                value: getDisplayScaleName()
              }
            }}
            itemProps={{ name: "ScaleName" }}
            onSubmit={(e: ILookupNameValue) => {
              setStudyQualification(
                Object.assign(new StudyQualification(), qual, {
                  ScaleName: e.value ? e.label : undefined,
                  ScaleId: e.value,
                  // clear out experience required if they blanked out the scale
                  IsExperienceRequired: e.value
                    ? qual.IsExperienceRequired
                    : false
                })
              );
            }}
          />
          <MddCheckbox
            lblText="Experience Required"
            lblProps={{
              className: isExperienceDisabled(qualRef.current.QualificationType)
                ? "mdd-qualification--disabled-text"
                : ""
            }}
            chkBoxProps={{
              id: "chkStudyQualificationExperienceReq",
              checked: qual.IsExperienceRequired ? true : false,
              value: "chkExpRequired",
              disabled: isExperienceDisabled(qualRef.current.QualificationType),
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setStudyQualification(
                  Object.assign(new StudyQualification(), qual, {
                    IsExperienceRequired: !qual.IsExperienceRequired
                  })
                )
            }}
          />
          <MddCheckbox
            lblText="Qualification Active"
            lblProps={{ className: mode === "Add" ? "notVisible" : "" }}
            chkBoxProps={{
              className: mode === "Add" ? "notVisible" : "",
              id: "chkStudyQualifcationActive",
              checked: qual.IsActive,
              value: "chkActive",
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setStudyQualification(
                  Object.assign(new StudyQualification(), qual, {
                    IsActive: !qual.IsActive
                  })
                )
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          className="mdd-qualification--content--grid-with-border"
        >
          <div
            className="mdd-qualification--content--header"
            data-testid="qualNameLabel"
          >
            Qualification Name *
          </div>
          <BktValidatedTextField
            key="txtEditQualification"
            validationForOnBlur={[Validations.required]}
            validationForOnChange={[Validations.valid]}
            textFieldProps={{
              debounceInterval: 0,
              textProps: {
                id: "txtEditQualification",
                label: "",
                className: "mdd-qualification--content--select",
                InputLabelProps: { shrink: true },
                inputProps: {
                  maxLength: MAX_TEXT_LENGTH.QUALIFICATION_NAME,
                  className: "mdd-qualification--outlined-input"
                },
                placeholder: "Enter Qualification Name",
                disabled: loadingSave,
                variant: "outlined",
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setStudyQualification(
                    Object.assign(new StudyQualification(), qual, {
                      QualificationName: e.target.value
                    })
                  ),
                value: qual.QualificationName
              }
            }}
          />
          <div className="mdd-qualification--content--header-padded">
            Learning Plans
          </div>
          <BktValidatedSelectChipField
            key="fldLearningPlans"
            id={"mdd-qualification--select-multiple-chip"}
            name={"learningPlans"}
            inputLabelText={
              loadingQualPlans || data.length
                ? ""
                : "No available Learning Plans"
            }
            data={data || undefined}
            selectProps={{
              disabled: isLearningPlanDisabled(),
              className:
                "validated-selectchip-field__select mdd-qualification--content--select--div"
            }}
            value={
              !qual.LearningPlans
                ? []
                : qual.LearningPlans.map((lp: ILearningPlan) => {
                    return lp.id;
                  })
            }
            onChange={handleLearningPlanChange}
            frmControlProps={{
              required: false,
              "aria-labelledby": "learningPlans"
            }}
          />
          {qual!.LegacyLearningPlans!.length > 0 && (
            <React.Fragment>
              <div className="mdd-qualification--content--legacy-LP-info--label">
                Legacy Learning Plan
              </div>
              <div className="mdd-qualification--content--legacy-LP-info--text">
                {qual!.LegacyLearningPlans![0].name}
              </div>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    ];
  };

  const getDeleteContent = () => {
    return (
      <div id="divTitle" key="textKey" className="displayRelative">
        <label id="lblDeleteStudySite" key="lblDeleteStudySite">
          {`Are you sure you want to delete qualification '${qual.QualificationName}' from this study? All associated data will be deleted.`}
        </label>
      </div>
    );
  };

  const isSubmitDisabled = () => {
    if (!qual.QualificationName || loadingSave) {
      return true;
    }

    if (mode === "Edit") {
      return isEqual(origEditQual, qual);
    }

    return false;
  };

  const isLearningPlanDisabled = () => {
    const loadingQualPlans =
      isLoading(`undefined_${eStudySaga.FETCH_STUDY_QUALIFICATION}`) ||
      isLoading(`undefined_${eStudySaga.FETCH_LEARNING_PLANS}`);

    if (mode === "Edit" || mode === "Add") {
      if (
        loadingQualPlans ||
        !qual ||
        !learningPlans ||
        !learningPlans.length
      ) {
        return true;
      }
    }
    return false;
  };

  const onActionClick = (
    qual: IStudyQualification,
    mode: "Edit" | "Delete"
  ) => {
    setStudyQualification(qual);
    setMode(mode);
  };

  const getActions = (qual: IStudyQualification) => {
    return (
      <div className="tbl--body-row-actions">
        <span onClick={() => onActionClick(qual, "Edit")}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            fixedWidth
            fontSize={"1.1rem"}
            id="editIcon"
            data-testid="editIcon"
            title="Edit"
          />
        </span>
        <span
          onClick={() => onActionClick(qual, "Delete")}
          className={qual.CanDelete ? "" : "notVisible"}
          id={`spanDeleteIcon${qual.CanDelete ? "" : "_hidden"}`}
          data-testid={`spanDeleteIcon${qual.CanDelete ? "" : "_hidden"}`}
        >
          <FontAwesomeIcon
            icon={faCircleXmark}
            fixedWidth
            fontSize={"1.1rem"}
            id="deleteCircleIcon"
            data-testid="svgDeleteIcon"
            title="Delete"
          />
        </span>
      </div>
    );
  };

  return (
    <MddDataContainer
      id="QualificationsData"
      breadcrumbs={[
        { display: "All Studies", route: "/study" },
        { display: study.StudyName }
      ]}
      addAction={{
        display: "Add Qualification",
        action: () => {
          setStudyQualification(new StudyQualification());
          setMode("Add");
        }
      }}
      title="Qualifications"
    >
      <BktDataTable
        {...defaultDataTableConfig}
        data={
          (studyQuals
            ? studyQuals.map((sq: IStudyQualification) =>
                pick(
                  Object.assign(sq, { key: sq.StudyQualificationId, qual: sq }),
                  "key",
                  "QualificationName",
                  "ScaleName",
                  "IsActive",
                  "qual"
                )
              )
            : []) as IBktDataTableData[]
        }
        defaultSortProp="ScaleName"
        loading={isLoading(
          `undefined_${eStudySaga.FETCH_STUDY_QUALIFICATIONS}`
        )}
        noRecordsMessage={noRecordsMessage.current}
        columnProps={[
          {
            label: "Qualification",
            sortProp: { sortPropName: "QualificationName", desc: true },
            filter: new Filter(eFilter.Text, {
              debounceInterval: 0,
              textProps: {
                className: classConstants.TEXT_FILTER_CLASS,
                id: "qualFilter",
                name: "QualificationName",
                onChange: handleFilterChange
              }
            }),
            tableCellProps: { id: "qualHeader" },
            divProps: { id: "qualHeader_div" }
          },
          {
            label: "Scale",
            sortProp: { sortPropName: "ScaleName", desc: true },
            filter: new Filter(eFilter.Text, {
              debounceInterval: 0,
              textProps: {
                className: classConstants.TEXT_FILTER_CLASS,
                id: "scaleFilter",
                name: "ScaleName",
                onChange: handleFilterChange
              }
            }),
            tableCellProps: { id: "scaleHeader" },
            divProps: { id: "scaleHeader_div" }
          },
          {
            label: "Status",
            sortProp: {
              sortPropName: "IsActive",
              desc: false,
              sortType: "Custom" as SortType,
              alg: sortStatus
            },
            filter: new Filter(eFilter.Select, {
              textProps: {
                id: "qualStatusFilterText",
                name: "isActive",
                className: "tbl--filter-select",
                onChange: e => handleFilterChange(e, true),
                InputLabelProps: { shrink: filterVals.current.has("isActive") }
              },
              selectMenuProps: { MenuProps: { className: "bracket-Select" } },
              menuProps: {},
              selectData: activeStatuses,
              defaultSelect: true
            }),
            tableCellProps: { id: "qualificationStatusHeader" },
            divProps: { id: "qualificationStatus_div" },
            formatProp: {
              formatPropName: "IsActive",
              format: (val: boolean) => <MddStatus isActive={val} />
            }
          },
          {
            label: "Actions",
            tableCellProps: { id: "actions" },
            divProps: { id: "actions_div" },
            formatProp: {
              formatPropName: "qual",
              format: (qual: IStudyQualification) => getActions(qual)
            }
          }
        ]}
      />
      {(mode === "Add" || mode === "Edit") && (
        <MddDialog
          submitting={loadingSave || loadingQualPlans}
          dialogProps={{
            id: "divStudyQualDialog",
            open: true,
            maxWidth: "md",
            fullWidth: true
          }}
          dialogActionProps={{ id: "divStudyQualDialogActions" }}
          dialogContentProps={{
            id: "divStudyQualDialogContent",
            className:
              "mdd-dialog--content-100percent mdd-qualification--content"
          }}
          dialogTitleProps={{
            id: "divStudyQualDialofTitle",
            title:
              mode === "Edit"
                ? "Edit Study Qualification"
                : "Add Study Qualification"
          }}
          dialogContent={getContent()}
          dialogActions={
            <MddDialogButtons
              saveButtonProps={{
                disabled: isSubmitDisabled(),
                onClick: () =>
                  SagaService.dispatchSaga({
                    type: eStudySaga.SAVE_STUDY_QUALIFICATION,
                    payload: qual
                  })
              }}
              closeButtonProps={{
                onClick: () => {
                  setMode("View");
                  setStudyQualification(new StudyQualification());
                }
              }}
            />
          }
        />
      )}
      {mode === "Delete" && (
        <MddDialog
          submitting={deleteInProgress}
          dialogProps={{
            id: "divDeleteQualDialog",
            open: true
          }}
          dialogActionProps={{ id: "divDeleteQualDialogActions" }}
          dialogContentProps={{
            id: "divDeleteQualDialogContent"
          }}
          dialogTitleProps={{
            id: "divDeleteQualDialofTitle",
            title: "Delete Study Qualification"
          }}
          dialogContent={getDeleteContent()}
          dialogActions={
            <MddDialogButtons
              saveButtonProps={{
                disabled: deleteInProgress,
                onClick: () => {
                  SagaService.dispatchSaga({
                    type: eStudySaga.DELETE_STUDY_QUALIFICATION,
                    payload: qual
                  });
                }
              }}
              saveButtonText="DELETE"
              closeButtonProps={{
                onClick: () => {
                  setMode("View");
                  setStudyQualification(new StudyQualification());
                }
              }}
            />
          }
        />
      )}
    </MddDataContainer>
  );
};

export const MddShowStudyQualifications = withMddLookups([
  "qualificationTypes"
])(withMddStudy()(_MddShowStudyQualifications));
