import * as React from "react";
import { pick, filter, some, isEqual, find, map, split } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { AddCircle } from "@material-ui/icons";
import { Paper, Button, Typography, Tooltip } from "@material-ui/core";
import {
  SiteComment,
  Site,
  Validations,
  IMatch,
  ISiteComment,
  ISiteCommentsDTO
} from "../../../model";
import {
  getKeysFromState,
  SagaService,
  BktDataTable,
  BktValidatedTextField,
  IAction,
  BktSaga,
  ISagaSubscription
} from "front-end-lib/core";
import { eUtilSaga, eSiteSaga } from "../../../sagas";
import {
  siteCommentsDataTableProps,
  siteCommentsDatatableHeaders
} from "../../../config";
import {
  BktBreadcrumb,
  MddForm,
  MddDialog,
  MddDialogButtons
} from "../../../components";
import { cloneAssign, toDateFormat } from "../../../utils";
import { MAX_TEXT_LENGTH } from "../../../constants";

interface IBktSiteCommentsProps {
  match: IMatch<{
    id: string;
  }>;
}

export interface IBktDataTableSiteComments
  extends ISiteComment,
    ISiteCommentsDTO {
  key: string; // required in type 'IBktDataTableData'
}

export interface IBktSiteCommentsDataTableState {
  loading: boolean;
  siteComments?: IBktDataTableSiteComments[] | undefined;
  newSiteComments?: IBktDataTableSiteComments[] | undefined;
  sagaSiteComments?: any;
  site: Site;
  comment: SiteComment;
  newComment: SiteComment;
  openDialog: boolean;
  commentHasError: boolean;
  userNameFilterText: string;
  updatedDtFilterText: string;
}

export class BktShowSiteComments extends BktSaga<
  IBktSiteCommentsProps,
  IBktSiteCommentsDataTableState,
  {}
> {
  protected filterVals = new Map();
  constructor(props: IBktSiteCommentsProps) {
    super(props);
    this.state = {
      loading: false,
      siteComments: [],
      newSiteComments: [],
      site: new Site(),
      comment: new SiteComment(),
      newComment: new SiteComment(),
      openDialog: false,
      commentHasError: false,
      userNameFilterText: "",
      updatedDtFilterText: ""
    };

    this.configureSaga(
      new Map([
        [
          eSiteSaga.FETCH_SITE.toString(),
          {
            stateKey: getKeysFromState(this.state, ["site"]),
            validationType: eSiteSaga.FETCH_SITE_SUCCESS
          } as ISagaSubscription
        ],
        [
          eSiteSaga.FETCH_SITE_COMMENTS.toString(),
          {
            stateKey: getKeysFromState(this.state, [
              "siteComments",
              "newSiteComments"
            ]),
            validationType: eSiteSaga.FETCH_SITE_COMMENTS_SUCCESS
          }
        ],
        [
          eUtilSaga.LOADING,
          { stateKey: getKeysFromState(this.state, ["loading"]) }
        ],
        [
          eSiteSaga.MODIFY_SITE_COMMENT.toString(),
          { callback: this.setSiteCommentAfterSave }
        ]
      ])
    );
  }

  public componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      SagaService.dispatchSaga({ type: eSiteSaga.FETCH_SITE, payload: id });
      SagaService.dispatchSaga({
        type: eSiteSaga.FETCH_SITE_COMMENTS,
        payload: id
      });
    }
  }

  public render() {
    const { loading, siteComments, newComment, site } = this.state;
    const dataProps = ["UpdatedBy", "UpdatedDt"];
    let { newSiteComments } = this.state;

    if (newSiteComments) {
      newSiteComments = map(newSiteComments, (s: IBktDataTableSiteComments) => {
        s = Object.assign(new SiteComment(), s) as IBktDataTableSiteComments;
        return Object.assign(pick(s, dataProps) as IBktDataTableSiteComments, {
          key: s.Id,
          Comment: (
            <Tooltip
              id={s.Id + "_toolTip"}
              title={
                s.CommentText
                  ? map(split(s.CommentText, "\n"), (x: string) => <p>{x}</p>)
                  : ""
              }
              className="mdd-toolTip"
              placement="left-end"
            >
              <div>{s.CommentText}</div>
            </Tooltip>
          ),
          EditComment: (
            <FontAwesomeIcon
              icon={faPenToSquare}
              fixedWidth
              fontSize={"1.25rem"}
              id="squareEditIcon"
              data-testid="editIcon"
              title="Edit"
              style={{ cursor: "pointer" }}
            />
          )
        });
      });
    }
    return (
      <div className="mdd-form--fullheight">
        <div>
          <BktBreadcrumb
            rootText="All Sites"
            rootUrl="/site"
            currentLocation={`${site.SiteName} (${site.SiteNumber})`}
          />
        </div>
        <MddForm>
          <div id="container" className="mdd-grid--with-menu mdd-grid">
            <Paper id="headerContainer">
              <Typography id="headerTitle">Comments</Typography>
              <Button
                id="addCommentButton"
                variant="contained"
                size={"small"}
                onClick={() => this.handleDialogOpen("")}
              >
                <AddCircle id="addCircleIcon" />
                Add New Comment
              </Button>
              <MddDialog
                submitting={loading}
                dialogProps={{
                  id: "BktFormDialogAddEditComment",
                  onClose: this.handleDialogClose,
                  "aria-labelledby": "form-dialog-title",
                  open: this.state.openDialog
                }}
                dialogActionProps={{ className: "mdd-form-dialog-action" }}
                dialogContentProps={{
                  id: "form-dialog-content"
                }}
                dialogTitleProps={{
                  id: "form-dialog-title",
                  title: newComment.Id ? "Edit Comment" : "Add New Comment"
                }}
                dialogContent={this.renderDialogContentChildren()}
                dialogActions={
                  <MddDialogButtons
                    saveButtonProps={{
                      disabled: this.disableSaveButton(),
                      onClick: this.handleDialogSave
                    }}
                    closeButtonProps={{
                      onClick: () => this.handleDialogClose()
                    }}
                  />
                }
              />
            </Paper>
            <Paper id="bodyContainer">
              <div>
                <BktDataTable
                  data={newSiteComments}
                  defaultSortProp={{
                    sortPropName: "UpdatedDt",
                    sortType: "Date",
                    desc: true
                  }}
                  loading={loading}
                  columnProps={siteCommentsDatatableHeaders(
                    this.handleChangeFilter
                  ).filter(dth => ["siteComments", ""].indexOf(dth.key) < 0)}
                  noRecordsMessage={
                    some(siteComments) && !some(newSiteComments)
                      ? "No records found"
                      : "No comments exist for this site"
                  }
                  {...siteCommentsDataTableProps}
                />
              </div>
            </Paper>
          </div>
        </MddForm>
      </div>
    );
  }

  private setSiteCommentAfterSave = (action: IAction) => {
    const { newComment } = this.state;
    const { type, payload } = action;
    if (type === eSiteSaga.MODIFY_SITE_COMMENT_SUCCESS) {
      this.setState(
        payload instanceof SiteComment
          ? { comment: payload, newComment: payload, openDialog: false }
          : { comment: newComment, newComment, openDialog: true },
        () => {
          SagaService.dispatchSaga({
            type: eSiteSaga.FETCH_SITE_COMMENTS,
            payload: payload.siteId
          });
        }
      );
    }
  };

  private handleChangeFilter = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    let filterFn = (x: IBktDataTableSiteComments) => true;
    const newState = {
      userNameFilterText: this.state.userNameFilterText,
      updatedDtFilterText: this.state.updatedDtFilterText,
      newSiteComments: this.state.newSiteComments,
      siteComments: this.state.siteComments
    };

    if (newState.newSiteComments) {
      newState.newSiteComments.forEach((x: ISiteCommentsDTO) => {
        x.updatedDtFullFormat = x.updatedDt;
        x.updatedDt = toDateFormat(x.updatedDt);
      });
    }
    const { userNameFilterText, updatedDtFilterText } = this.state;
    const inputValue = event.target.value;
    const searchValue = inputValue.toLowerCase().trim();
    if (event.target.id === "updatedByFilter") {
      newState.userNameFilterText = inputValue;
      filterFn = (x: IBktDataTableSiteComments) =>
        RegExp(searchValue, "i").test(x.updatedBy) &&
        (!updatedDtFilterText ||
          RegExp(updatedDtFilterText, "i").test(x.updatedDt));
    } else if (event.target.id === "dateCreatedFilter") {
      newState.updatedDtFilterText = inputValue;
      filterFn = (x: IBktDataTableSiteComments) =>
        RegExp(searchValue, "i").test(x.updatedDt) &&
        (!userNameFilterText ||
          RegExp(userNameFilterText, "i").test(x.updatedBy));
    }
    newState.newSiteComments = filter(
      newState.siteComments,
      filterFn
    ).reverse();
    if (newState.newSiteComments) {
      newState.newSiteComments.forEach((x: ISiteCommentsDTO) => {
        x.updatedDt = x.updatedDtFullFormat;
      });
    }

    this.setState({
      updatedDtFilterText: newState.updatedDtFilterText,
      userNameFilterText: newState.userNameFilterText,
      newSiteComments: newState.newSiteComments
    });
  };

  private renderDialogContentChildren = () => {
    const { newComment } = this.state;
    return [
      <BktValidatedTextField
        key="txtCommentText"
        validationForOnBlur={[Validations.required]}
        validationForOnChange={[Validations.valid]} // Clear required validation message on backspace.
        validationerror={(validationError: boolean) =>
          this.setState({ commentHasError: validationError })
        }
        textFieldProps={{
          textProps: {
            id: "txtCommentText",
            className: "mdd-form--multiline-text",
            label: "Comment",
            placeholder: "Comment",
            inputProps: { maxLength: MAX_TEXT_LENGTH.SITE_COMMENT },
            required: true,
            type: "text",
            multiline: true,
            rowsMax: "4",
            onChange: (event: any) => {
              this.setState({
                newComment: cloneAssign(newComment, {
                  CommentText: event.target.value
                })
              });
            },
            value: newComment.CommentText || "",
            variant: "standard"
          }
        }}
      />
    ];
  };

  private handleDialogOpen = (commentId: string) => {
    const { newSiteComments, newComment } = this.state;
    if (commentId) {
      this.setState({
        newComment: cloneAssign(
          newComment,
          find(newSiteComments, (x: any) => x.id === commentId) as any
        )
      });
    } else {
      this.setState({ newComment: new SiteComment() });
    }
    this.setState({ openDialog: true });
  };

  private handleDialogClose = () => {
    this.setState({ openDialog: false });
  };

  private disableSaveButton = () => {
    const { loading, comment, newComment, commentHasError } = this.state;
    return (
      loading ||
      !newComment.CommentText ||
      commentHasError ||
      isEqual(newComment, comment)
    );
  };

  private handleDialogSave = () => {
    const { newComment } = this.state;
    const { id } = this.props.match.params;

    const payload: any = {
      siteId: id,
      CommentText: newComment.CommentText.trim()
    };

    SagaService.dispatchSaga({ type: eSiteSaga.MODIFY_SITE_COMMENT, payload });
  };
}
