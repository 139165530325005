import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";

interface IProps {
  fields: string[];
}

const MddResEducationElement = ({ fields }: IProps) => (
  <div className="mdd-res-draft--tab-content">
    <h3>Education</h3>
    <p className="mdd-res-draft--tab-content--paragraph">
      Education fields included in Clinical Profile for the Rater Experience
      Survey (note: edits not permitted).
    </p>
    <h4>Education Fields</h4>
    <ul
      className="mdd-res-draft--tab-content--fields"
      data-testid="mdd-res-draft-static-fields"
    >
      {fields.map(field => (
        <li key={field}>{field}</li>
      ))}
    </ul>
  </div>
);

export const MddResEducation = connect(
  ({ resConfig: { education } }: IApplicationState) => ({
    fields: education.fields
  })
)(MddResEducationElement);
