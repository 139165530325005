import * as React from "react";
import CommentIcon from "@material-ui/icons/Comment";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { Button, Checkbox } from "@material-ui/core";
import {
  ITrainingExperience,
  ILearningPlanStatus,
  ILearningPlanAssignment,
  ICourseStatus
} from "../../../model";
import {
  EXPERIENCE_STATUS_DISPLAY_VALUE,
  EXPERIENCE_STATUS_VALUE,
  LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE,
  TRAINING_COURSE_TYPE
} from "../../../constants";
import { toDateFormatNoOffset } from "../../../utils";
import {
  canExemptCourse,
  disableLPCheckbox,
  enableUnassign,
  isLpSelected,
  showTrainingSpinner
} from "./MddTrainingHelpers";
import { MddExpansionPanel, MddNoDataDiv } from "../../common";

export const renderQualificationHeader = (
  qual: ITrainingExperience,
  handleOverrideClick: (e: React.MouseEvent, qual: ITrainingExperience) => void
) => {
  return (
    <div className="mdd-training--qual-header">
      <div className="mdd-training--qual-header--status">
        <span
          className="mdd-training--emphasized"
          id={`lblQualName_${qual.studyQualificationId}`}
          data-testid={`lblQualName_${qual.studyQualificationId}`}
        >
          {qual.studyQualificationName}
        </span>
        <span className="mdd-training--separator">|</span>
        <span
          id={`lblQualStatus_${qual.studyQualificationId}`}
          data-testid={`lblQualStatus_${qual.studyQualificationId}`}
        >
          {qual.studyQualificationStatus
            ? qual.studyQualificationStatus.name
            : EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE}
        </span>
        {qual.studyQualificationStatusDate && (
          <span className="mdd-training--separator">|</span>
        )}
        <span
          id={`lblQualDate_${qual.studyQualificationId}`}
          data-testid={`lblQualDate_${qual.studyQualificationId}`}
        >
          {toDateFormatNoOffset(qual.studyQualificationStatusDate)}
        </span>
        {qual.comment && (
          <React.Fragment>
            <span className="mdd-training--separator">|</span>
            <span
              className="mdd-training--commentIcon"
              title={decodeURIComponent(qual.comment.commentText!)}
            >
              <CommentIcon
                fontSize={"small"}
                id={`iconComment_${qual.studyQualificationId}`}
                data-testid={`iconComment_${qual.studyQualificationId}`}
              />
            </span>
          </React.Fragment>
        )}
      </div>
      <div className="mdd-training--qual-header--override">
        <Button
          variant="contained"
          size="small"
          color="primary"
          className="mdd-training--action-button button-secondary"
          id={`btnOverride_${qual.studyQualificationId}`}
          data-testid={`btnOverride_${qual.studyQualificationId}`}
          onClick={e => handleOverrideClick(e, qual)}
        >
          Override
        </Button>
      </div>
    </div>
  );
};

export const renderLearningPlanHeader = (
  qual: ITrainingExperience,
  plan: ILearningPlanStatus,
  selectedLps: ILearningPlanAssignment[],
  handleLPClick: (
    e: React.MouseEvent,
    qual: ITrainingExperience,
    plan: ILearningPlanStatus
  ) => void,
  handleUnassignClick: (e: React.MouseEvent, lpId: number) => void
) => {
  const isUnassignEnabled = enableUnassign(plan);

  const lpIdToUse = plan.learningPlanId || plan.legacyLearningPlanId;

  return (
    <div>
      <Checkbox
        checked={isLpSelected(qual, plan, selectedLps)}
        id={`chkLP_${lpIdToUse}`}
        data-testid={`chkLP_${lpIdToUse}${
          plan.isAssignable ? "" : "_disabled"
        }`}
        disabled={disableLPCheckbox(plan) as boolean}
        className={
          disableLPCheckbox(plan)
            ? "mdd-training--panel--LP--checkbox--disabled"
            : ""
        }
        onClick={(e: React.MouseEvent) => handleLPClick(e, qual, plan)}
      />
      <span
        className="mdd-training--emphasized"
        id={`spanLPName_${lpIdToUse}`}
        data-testid={`spanLPName_${lpIdToUse}`}
      >
        {plan.learningPlanName}
      </span>
      <span className="mdd-training--separator">|</span>
      <span
        id={`spanLPStatus_${lpIdToUse}`}
        data-testid={`spanLPStatus_${lpIdToUse}`}
      >
        {plan.learningPlanStatus
          ? plan.learningPlanStatus.name
          : EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE}
      </span>
      {plan.learningPlanStatusDate && (
        <span className="mdd-training--separator">|</span>
      )}
      <span
        id={`spanLPDate_${lpIdToUse}`}
        data-testid={`spanLPDate_${lpIdToUse}`}
      >
        {toDateFormatNoOffset(plan.learningPlanStatusDate)}
      </span>
      {plan.learningPlanAssignmentStatus &&
        plan.learningPlanAssignmentStatus.value ===
          LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE.ASSIGNED && (
          <React.Fragment>
            <span className="mdd-training--separator">|</span>
            <span
              className={
                isUnassignEnabled
                  ? `mdd-training--unassign`
                  : "mdd-training--unassign-disabled"
              }
              id="spanUnassign"
              data-testid="spanUnassign"
              onClick={(e: React.MouseEvent) =>
                isUnassignEnabled
                  ? handleUnassignClick(e, plan.learningPlanId)
                  : null
              }
            >
              Unassign
            </span>
          </React.Fragment>
        )}
      {plan.learningPlanAssignmentStatus &&
        plan.learningPlanAssignmentStatus.value ===
          LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE.PENDING_ASSIGNMENT && (
          <React.Fragment>
            <span className="mdd-training--separator">|</span>
            <span
              className="mdd-training--pending"
              id="spanPendingAssignment"
              data-testid="spanPendingAssignment"
            >
              Pending Assignment
            </span>
          </React.Fragment>
        )}
    </div>
  );
};

export const renderCoursesPanel = (
  courses: ICourseStatus[],
  trainingExperience: ITrainingExperience[] | undefined,
  handleCourseClick: (course: ICourseStatus) => void,
  handleUpdateCourseStatus: (
    e: React.MouseEvent,
    course: ICourseStatus,
    status: string
  ) => void,
  handleEditClick: (course: ICourseStatus) => void
) => {
  const renderHeaderRow = () => {
    return (
      <React.Fragment>
        <div className="mdd-training--table--row-container">
          <div className="mdd-training--table--cell--header mdd-training--table--cell--indicator mdd-training--table--cell--header">
            {/* empty placeholder for indicator column so header lines up correctly */}
          </div>
          <div
            className="mdd-training--table--cell mdd-training--table--cell--course mdd-training--table--cell--header"
            id={`hdrCourse`}
            data-testid={`hdrCourse`}
          >
            Course
          </div>
          <div
            className="mdd-training--table--cell mdd-training--table--cell--status mdd-training--table--cell--header"
            id={`hdrStatus`}
            data-testid={`hdrStatus`}
          >
            Status
          </div>
          <div
            className="mdd-training--table--cell mdd-training--table--cell--date mdd-training--table--cell--header"
            id={`hdrDate`}
            data-testid={`hdrDate`}
          >
            Date
          </div>
          <div className="mdd-training--table--cell--header mdd-training--table--cell--button">
            {/* empty placeholder for button column so header lines up correctly */}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderDataRow = (
    course: ICourseStatus,
    trainingExperience: ITrainingExperience[] | undefined,
    handleCourseClick: (course: ICourseStatus) => void,
    handleUpdateCourseStatus: (
      e: React.MouseEvent,
      course: ICourseStatus,
      status: string
    ) => void,
    handleEditClick: (course: ICourseStatus) => void
  ) => {
    const courseIdToUse = course.courseId || course.studyComponentId;
    return (
      <React.Fragment key={courseIdToUse!.toString()}>
        <hr color="#e1e1e1" className="mdd-training--table--divider" />
        <div className="mdd-training--table--row-container">
          <div className="mdd-training--table--cell--indicator">
            <span
              title="Responses entered via endSight"
              className="mdd-training--table--cell--course-info-icon"
            >
              <InfoIcon
                fontSize="small"
                id="iconInfoManual"
                data-testid="iconInfoManual"
                className={
                  !course ||
                  !course.sourceEntry ||
                  course.sourceEntry.toLowerCase() !== "manual"
                    ? "notVisible"
                    : ""
                }
              />
            </span>
          </div>
          <div
            className={`mdd-training--table--cell ${
              course.courseStatus &&
              course.courseStatus.value !==
                EXPERIENCE_STATUS_VALUE.INCOMPLETE &&
              course.courseStatus.value !== EXPERIENCE_STATUS_VALUE.EXEMPT &&
              course.courseStatus.value !==
                EXPERIENCE_STATUS_VALUE.PENDING_EXEMPT &&
              course.courseStatus.value !==
                EXPERIENCE_STATUS_VALUE.PENDING_RESET &&
              course.trainingCourseType === TRAINING_COURSE_TYPE.SCORING
                ? "mdd-training--table--cell--course-link"
                : "mdd-training--table--cell--course"
            }`}
            id={`divCourseName_${courseIdToUse}`}
            data-testid={`divCourseName_${courseIdToUse}`}
            title={course.courseName}
            onClick={() => handleCourseClick(course)}
          >
            {course.courseName}
          </div>
          <div
            className="mdd-training--table--cell mdd-training--table--cell--status"
            id={`divCourseStatus_${courseIdToUse}`}
            data-testid={`divCourseStatus_${courseIdToUse}`}
            title={
              course.courseStatus
                ? course.courseStatus.name
                : EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE
            }
          >
            {course.courseStatus
              ? course.courseStatus.name
              : EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE}
            {course &&
              course.courseStatus &&
              course.courseStatus.value === EXPERIENCE_STATUS_VALUE.EXEMPT && (
                <span
                  title="This course has been exempted. If you need to revert, click the Reset button"
                  className="mdd-training--info-icon"
                >
                  <InfoIcon
                    fontSize="small"
                    id="iconInfo"
                    data-testid="iconInfo"
                  />
                </span>
              )}
          </div>
          <div
            className="mdd-training--table--cell mdd-training--table--cell--date"
            id={`divCourseDate_${courseIdToUse}`}
            data-testid={`divCourseDate_${courseIdToUse}`}
            title={toDateFormatNoOffset(course.courseStatusDate)}
          >
            {course && course.courseStatusDate
              ? toDateFormatNoOffset(course.courseStatusDate)
              : ""}
          </div>
          <div className="mdd-training--table--cell--button">
            <span>
              {(!course.courseStatus ||
                course.courseStatus.value !==
                  EXPERIENCE_STATUS_VALUE.EXEMPT) && (
                <Button
                  size="small"
                  color="primary"
                  className="mdd-training--action-button button-secondary"
                  id={`btnExempt_${courseIdToUse}`}
                  data-testid={`btnExempt_${courseIdToUse}`}
                  disabled={
                    !canExemptCourse(course, trainingExperience) as boolean
                  }
                  onClick={e =>
                    handleUpdateCourseStatus(
                      e,
                      course,
                      EXPERIENCE_STATUS_VALUE.EXEMPT
                    )
                  }
                >
                  Exempt
                </Button>
              )}
              {course.courseStatus &&
                course.courseStatus.value ===
                  EXPERIENCE_STATUS_VALUE.EXEMPT && (
                  <Button
                    size="small"
                    color="primary"
                    className="mdd-training--action-button button-secondary"
                    id={`btnReset_${courseIdToUse}`}
                    data-testid={`btnReset_${courseIdToUse}`}
                    onClick={e =>
                      handleUpdateCourseStatus(
                        e,
                        course,
                        EXPERIENCE_STATUS_VALUE.INCOMPLETE
                      )
                    }
                  >
                    Reset
                  </Button>
                )}
              <Button
                size="small"
                color="primary"
                className="mdd-training--action-button button-secondary mdd-training--action-button--smaller"
                id={`btnEdit_${courseIdToUse}`}
                data-testid={`btnEdit_${courseIdToUse}`}
                disabled={!course.isEligibleForManualDataEntry}
                onClick={() => handleEditClick(course)}
              >
                Edit
              </Button>
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div
      className="mdd-training--table--container"
      id="tblTrainingCourses"
      data-testid="tblTrainingCourses"
    >
      {renderHeaderRow()}
      {courses.map((course: ICourseStatus) => {
        return renderDataRow(
          course,
          trainingExperience,
          handleCourseClick,
          handleUpdateCourseStatus,
          handleEditClick
        );
      })}
      {!courses.length && <MddNoDataDiv loading={showTrainingSpinner()} />}
    </div>
  );
};

export const renderLearningPlanPanel = (
  qual: ITrainingExperience,
  lp: ILearningPlanStatus[],
  selectedLps: ILearningPlanAssignment[],
  trainingExperience: ITrainingExperience[] | undefined,
  handleLPClick: (
    e: React.MouseEvent,
    qual: ITrainingExperience,
    plan: ILearningPlanStatus
  ) => void,
  handleUnassignClick: (e: React.MouseEvent, lpId: number) => void,
  toggleLpPanel: (
    expanded: boolean,
    index: number,
    qual: ITrainingExperience
  ) => void,
  handleCourseClick: (course: ICourseStatus) => void,
  handleUpdateCourseStatus: (
    e: React.MouseEvent,
    course: ICourseStatus,
    status: string
  ) => void,
  handleEditClick: (course: ICourseStatus) => void
) => {
  return (
    <div className="mdd-training--panel">
      {lp
        ? lp.map((plan: ILearningPlanStatus, index: number) => {
            return (
              <MddExpansionPanel
                expanded={plan.expanded || false}
                key={plan.learningPlanId}
                id={`panelLP_${index}`}
                panelClass="mdd-training--panel--LP"
                data-testid={`panelLP_${index}`}
                headerText={renderLearningPlanHeader(
                  qual,
                  plan,
                  selectedLps,
                  handleLPClick,
                  handleUnassignClick
                )}
                onChange={(event, expanded) =>
                  toggleLpPanel(expanded, index, qual)
                }
              >
                <div>
                  {renderCoursesPanel(
                    plan.userCourseStatuses,
                    trainingExperience,
                    handleCourseClick,
                    handleUpdateCourseStatus,
                    handleEditClick
                  )}
                </div>
              </MddExpansionPanel>
            );
          })
        : null}
    </div>
  );
};

export const renderQualificationPanel = (
  qual: ITrainingExperience,
  index: number,
  selectedLps: ILearningPlanAssignment[],
  trainingExperience: ITrainingExperience[] | undefined,
  handleLPClick: (
    e: React.MouseEvent,
    qual: ITrainingExperience,
    plan: ILearningPlanStatus
  ) => void,
  handleOverrideClick: (e: React.MouseEvent, qual: ITrainingExperience) => void,
  handleUnassignClick: (e: React.MouseEvent, lpId: number) => void,
  toggleLpPanel: (
    expanded: boolean,
    index: number,
    qual: ITrainingExperience
  ) => void,
  handleCourseClick: (course: ICourseStatus) => void,
  handleUpdateCourseStatus: (
    e: React.MouseEvent,
    course: ICourseStatus,
    status: string
  ) => void,
  handleEditClick: (course: ICourseStatus) => void,
  toggleQualPanel: (expanded: boolean, index: number) => void
) => {
  return (
    <MddExpansionPanel
      expanded={qual.expanded || false}
      id={`panelTraining_${index}`}
      data-testid={`panelTraining_${index}`}
      key={qual.studyQualificationId}
      headerText={renderQualificationHeader(qual, handleOverrideClick)}
      onChange={(event, expanded) => toggleQualPanel(expanded, index)}
    >
      <div>
        {qual.userLearningPlanStatuses.length ? (
          renderLearningPlanPanel(
            qual,
            qual.userLearningPlanStatuses,
            selectedLps,
            trainingExperience,
            handleLPClick,
            handleUnassignClick,
            toggleLpPanel,
            handleCourseClick,
            handleUpdateCourseStatus,
            handleEditClick
          )
        ) : (
          <MddNoDataDiv loading={showTrainingSpinner()} />
        )}
      </div>
    </MddExpansionPanel>
  );
};
