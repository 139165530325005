import * as React from "react";
import { Link } from "react-router-dom";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { IBreadcrumb } from "../../../model";

interface IMddBreadcrumbProps {
  /* Items to be displayed in breadcrumb */
  breadcrumbs: IBreadcrumb[];
}

export const MddBreadcrumb = (props: IMddBreadcrumbProps) => {
  const breadcrumbs: React.ReactNode[] = [];
  props.breadcrumbs.forEach((bc: IBreadcrumb, i: number) => {
    const id = `BreadCrumb_${i}`;
    if (i > 0) {
      breadcrumbs.push(<ChevronRight key={`svg${id}`} />);
    }
    if (bc.route) {
      breadcrumbs.push(
        <Link
          id={`a${id}`}
          key={`a${id}`}
          data-testid={`a${id}`}
          to={bc.route}
          title={bc.display}
        >
          {bc.display}
        </Link>
      );
    } else {
      breadcrumbs.push(
        <span
          id={`lbl${id}`}
          key={`lbl${id}`}
          data-testid={`lbl${id}`}
          title={bc.display}
        >
          {bc.display}
        </span>
      );
    }
  });
  return (
    <div id="breadCrumbContainer" className="mdd-breadcrumb typography">
      {breadcrumbs}
    </div>
  );
};
