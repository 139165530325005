import { trim } from "lodash";
import { IRole } from "..";

export class Role implements IRole {
  private roleId: string;
  private name: string;
  private isSitePi: boolean;

  get RoleId() {
    return this.roleId;
  }
  set RoleId(val) {
    this.roleId = val;
  }
  get Name() {
    return this.name;
  }
  set Name(val) {
    this.name = val;
  }
  get IsSitePi() {
    return this.isSitePi;
  }
  set IsSitePi(val) {
    this.isSitePi = val;
  }

  constructor(roleId?: string, name?: string) {
    this.roleId = roleId || "";
    this.name = trim(name);
  }
}
