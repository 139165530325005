import { call, put } from "redux-saga/effects";
import { IAction } from "front-end-lib/core";
import { CommentService } from "../services";
import { initSaga } from "../utils/sagaHelper";
import { openSuccessSnackBar } from "../store";
import { overrideSaga, store } from "../utils";

export enum eCommentSaga {
  SAVE_COMMENT = "@@comment/SAVE_COMMENT",
  SAVE_COMMENT_SUCCESS = "@@comment/SAVE_COMMENT_SUCCESS",
  WATCH_SAVE_COMMENT = "@@comment/WATCH_SAVE_COMMENT"
}

initSaga(
  function* saveComment(action: IAction) {
    const comment = yield call(CommentService.saveComment, action.payload);
    yield put({
      type: eCommentSaga.SAVE_COMMENT_SUCCESS,
      payload: comment
    });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eCommentSaga.SAVE_COMMENT,
  eCommentSaga.SAVE_COMMENT_SUCCESS
);

export const overrideCommentSagas = () => {
  overrideSaga(
    function* saveComment(action: IAction) {
      if (action.payload) {
        yield put({ type: eCommentSaga.SAVE_COMMENT_SUCCESS, payload: true });
      }
    },
    eCommentSaga.SAVE_COMMENT,
    eCommentSaga.WATCH_SAVE_COMMENT
  );
};
