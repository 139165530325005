import { createTheme } from "@material-ui/core/styles";

// Theme is set based on Bracket UI Style Guide - https://confluence.bracketglobal.com/display/PPM/Light
// Default values of theme object can be fould here - https://material-ui.com/customization/default-theme/

const muiTheme = createTheme({
  // COLORS

  palette: {
    // Background color
    background: {
      default: "#fafafa"
    },

    // Common black and white colors
    common: {
      black: "#000",
      white: "#fff"
    },

    // Base colors
    grey: {
      50: "#F6F6F6", // Gray1
      100: "#F2F2F2", // Gray2
      200: "#CACACA", // Gray3
      300: "#979797", // Gray4
      400: "#646464", // Gray5
      500: "#373737" // Gray6
    },

    // Primary colors
    primary: {
      50: "#00467F", // Bracket Dark Blue
      100: "#0093D0", // Bracket Bright Blue
      200: "#ED7A23", // Bracket Orange
      300: "#0C233F", // Navy Blue
      400: "#14A5B5", // Teal
      500: "#94C83D", // Grass Green
      600: "#FCD920", // Yellow
      main: "#0093D0"
    },

    // Secondary colors
    secondary: {
      50: "#923F95", // Bright Purple
      100: "#715292", // Muted Purple
      200: "#00867D", // Greenish Teal
      300: "#054C46", // Dark Teal
      400: "#395EAB", // Muted Dark Blue
      500: "#89A8D8", // Muted Light Blue
      600: "#ED7A23", // Tangerine
      main: "#0093D0"
    }
  },

  // TYPOGRAPY
  typography: {
    fontFamily: '"Noto Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14, // Default fontsize
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      color: "#000",
      fontSize: "6rem",
      fontWeight: 400,
      letterSpacing: "-0.01562em",
      lineHeight: 1
    },
    h2: {
      color: "#000",
      fontSize: "3.75rem",
      fontWeight: 400,
      letterSpacing: "-0.00833em",
      lineHeight: 1
    },
    h3: {
      color: "#000",
      fontSize: "3rem",
      fontWeight: 400,
      letterSpacing: "0em",
      lineHeight: 1.04
    },
    h4: {
      color: "#000",
      fontSize: "2.125rem",
      fontWeight: 400,
      letterSpacing: "0.00735em",
      lineHeight: 1.17
    },
    h5: {
      color: "#000",
      fontSize: "1.5rem",
      fontWeight: 400,
      letterSpacing: "0em",
      lineHeight: 1.33
    },
    h6: {
      color: "#fff",
      fontSize: "1rem",
      fontWeight: 1
    },
    body1: {
      color: "#000",
      fontSize: "1rem",
      fontWeight: 400,
      letterSpacing: "0.00938em",
      lineHeight: 1.5
    },
    body2: {
      color: "#000",
      fontSize: "0.875rem",
      fontWeight: 400,
      letterSpacing: "0.01071em",
      lineHeight: 1.5
    }
  }
});

export default muiTheme;
