import * as React from "react";
import { Link } from "react-router-dom";
import { isEqual, isEmpty, pick, sortBy } from "lodash";
import Paper from "@material-ui/core/Paper";
import {
  SagaService,
  IAction,
  IBktDataTableData,
  BktDataTable,
  Filter,
  eFilter
} from "front-end-lib/core";
import {
  MAX_TEXT_LENGTH,
  NO_DATA_DISPLAY_TEXT,
  classConstants
} from "../../../constants";
import {
  Person,
  IPerson,
  IPersonnelContactInfo,
  Validations,
  IFilter,
  ValidationItem,
  IStudyPersonnelProtocol
} from "../../../model";
import { MddDialogButtons, MddValidatedTextField } from "../../common";
import { ePersonnelSaga } from "../../../sagas";
import {
  isLoading,
  filterDataForDataTable,
  sortObjectArray
} from "../../../utils";
import { IPersonStudyHistory } from "../../../model";

interface IMddPersonDetailsProps {
  person: IPerson;
  onCommit?: (contactInfo: IPersonnelContactInfo | undefined) => void;
}

interface IPersonStudyData extends IBktDataTableData {
  sponsorName: string;
  studyName: string;
  internalStudyName: string;
  protocols: string;
}

interface IStaticField {
  label: string;
  id: string;
  name: string;
}
interface IEditableField {
  label: string;
  id: string;
  name: string;
  maxLength: number;
  blurValidations: ValidationItem[];
  onValidationError: (error: boolean) => void;
  placeholder?: string;
}

export const MddPersonDetails = (props: IMddPersonDetailsProps) => {
  const staticFields = [
    { label: "First Name", id: "FirstName", name: "FirstName" },
    { label: "Last Name", id: "LastName", name: "LastName" },
    { label: "Email Address", id: "Email", name: "Email" },
    { label: "Rater ID", id: "RaterId", name: "RaterId" },
    { label: "Username", id: "Username", name: "UserName" }
  ] as IStaticField[];

  const editableFields = [
    {
      label: "Email Address #2",
      id: "Email2",
      name: "emailAddress2",
      maxLength: MAX_TEXT_LENGTH.EMAIL,
      blurValidations: [Validations.validEmail],
      onValidationError: (valError: boolean) => {
        setEmail2ValError(valError);
      },
      placeholder: "Enter email"
    },
    {
      label: "Email Address #3",
      id: "Email3",
      name: "emailAddress3",
      maxLength: MAX_TEXT_LENGTH.EMAIL,
      blurValidations: [Validations.validEmail],
      onValidationError: (valError: boolean) => {
        setEmail3ValError(valError);
      },
      placeholder: "Enter email"
    },
    {
      label: "Phone",
      id: "Phone",
      name: "phone",
      maxLength: MAX_TEXT_LENGTH.PHONE_NUMBER,
      // blurValidations: [Validations.phoneNumberAllowedCharacters],
      onValidationError: (valError: boolean) => {
        setPhoneValError(valError);
      },
      placeholder: "Enter phone number"
    },
    {
      label: "Mobile Phone",
      id: "MobilePhone",
      name: "mobilePhone",
      maxLength: MAX_TEXT_LENGTH.PHONE_NUMBER,
      // blurValidations: [Validations.phoneNumberAllowedCharacters],
      onValidationError: (valError: boolean) => {
        setMobilePhoneValError(valError);
      },
      placeholder: "Enter mobile phone"
    },
    {
      label: "Fax Number",
      id: "Fax",
      name: "fax",
      maxLength: MAX_TEXT_LENGTH.PHONE_NUMBER,
      // blurValidations: [Validations.phoneNumberAllowedCharacters],
      onValidationError: (valError: boolean) => {
        setFaxValError(valError);
      },
      placeholder: "Enter fax number"
    }
  ] as IEditableField[];

  const { person } = props;

  // only things editable are in the contactInfo object so we'll only keep track of that
  const [newContactInfo, setNewContactInfo] = React.useState<
    IPersonnelContactInfo | undefined
  >(undefined);
  const contactInfoRef = React.useRef(newContactInfo);
  contactInfoRef.current = newContactInfo;

  const [email2ValError, setEmail2ValError] = React.useState(false);
  const [email3ValError, setEmail3ValError] = React.useState(false);
  const [phoneValError, setPhoneValError] = React.useState(false);
  const [mobilePhoneValError, setMobilePhoneValError] = React.useState(false);
  const [faxValError, setFaxValError] = React.useState(false);
  const [origData, setOrigData] = React.useState<IPersonStudyData[]>(
    [] as IPersonStudyData[]
  );
  const [gridData, setGridData] = React.useState<IPersonStudyData[]>(
    [] as IPersonStudyData[]
  );

  const filterVals = React.useRef(new Map<string, IFilter>());

  React.useEffect(() => {
    const removeSavePerson = SagaService.subscribeToSaga(
      ePersonnelSaga.SAVE_PERSON,
      (action: IAction) => {
        if (action.type === ePersonnelSaga.SAVE_PERSON_SUCCESS) {
          if (props.onCommit) {
            props.onCommit(contactInfoRef.current);
          }
        }
      }
    );
    return () => removeSavePerson();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const { person } = props;

    if (!person.Id) {
      // nothing to do if the person isn't loaded yet (initial mount of component)
      return;
    }

    setNewContactInfo(
      Object.assign({} as IPersonnelContactInfo, person.PersonnelContactInfo)
    );

    if (person.StudyHistory && person.StudyHistory.length) {
      const formattedStudyData = formatStudyData(person.StudyHistory);
      setOrigData(formattedStudyData);
      setGridData(formattedStudyData);
    }

    // eslint-disable-next-line
  }, [props.person]);

  const createLink = (studyId: string, siteId: string) => {
    const { person } = props;
    return (
      <Link to={`/study/${studyId}/sites/${siteId}/personnel/${person.Id}`}>
        <span className="mdd-personnel-studies--link">View</span>
      </Link>
    );
  };

  const formatProtocols = (protocols: IStudyPersonnelProtocol[]) => {
    return sortObjectArray(protocols, "protocolName")
      .map((p: IStudyPersonnelProtocol) => p.protocolName)
      .join(", ");
  };

  const formatStudyData = (studyHistory: IPersonStudyHistory[]) => {
    const newStudies = studyHistory
      ? studyHistory.map((s: IPersonStudyHistory) => {
          return Object.assign(
            pick(s, [
              "sponsorName",
              "studyName",
              "internalStudyName"
            ]) as IPersonStudyData,
            {
              formattedProtocols: formatProtocols(s.protocols),
              key: `${s.sponsorName}_${s.studyId}`,
              link: createLink(s.studyId, s.site.id)
            }
          );
        })
      : [];
    return sortBy(newStudies, "sponsorName", "studyName");
  };

  const handleValueChange = (id: string, value: string | undefined) => {
    setNewContactInfo(
      Object.assign({} as IPersonnelContactInfo, newContactInfo, {
        [id]: value
      })
    );
  };

  const isValidationError = () => {
    return (
      email2ValError ||
      email3ValError ||
      phoneValError ||
      mobilePhoneValError ||
      faxValError
    );
  };

  const disableSaveButton = () => {
    return (
      isEqual(person.PersonnelContactInfo, newContactInfo) ||
      isEmpty(newContactInfo) ||
      isValidationError() ||
      isLoading(`undefined_${ePersonnelSaga.SAVE_PERSON}`)
    );
  };

  const handleSaveDetails = () => {
    const savePerson = Object.assign(
      new Person(),
      { ...person },
      {
        PersonnelContactInfo: { ...newContactInfo }
      }
    );
    SagaService.dispatchSaga({
      type: ePersonnelSaga.SAVE_PERSON,
      payload: savePerson
    });
  };

  const renderStaticField = (field: IStaticField) => {
    return (
      <React.Fragment key={`${field.id}_fragmentkey`}>
        <div
          className="mdd-personnel-div--caption-padded"
          id={`person${field.id}Label`}
          key={`${field.id}_key`}
        >
          {field.label}
        </div>
        <div
          className="mdd-personnel-div--value"
          data-testid={`${field.id}`}
          id={`person${field.id}Value`}
        >
          {person && person[field.name]
            ? person[field.name]
            : NO_DATA_DISPLAY_TEXT}
        </div>
      </React.Fragment>
    );
  };

  const renderEditableField = (field: IEditableField) => {
    return (
      <MddValidatedTextField
        id={field.id}
        key={field.id}
        validationForOnBlur={field.blurValidations}
        validationerror={(validationError: boolean) =>
          field.onValidationError(validationError)
        }
        labelProps={{
          labelText: field.label,
          className: "mdd-personnel-div--caption"
        }}
        textFieldProps={{
          textProps: {
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              handleValueChange(field.name, event.target.value),
            value:
              newContactInfo && newContactInfo[field.name]
                ? newContactInfo[field.name]
                : "",
            placeholder: field.placeholder || "",
            id: `person${field.id}Value`,
            inputProps: {
              maxLength: field.maxLength
            }
          }
        }}
      />
    );
  };

  const handleFilterChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const value = e.target.value ? e.target.value.trim() : "";
    const key = e.target.name;
    if (origData.length) {
      const filteredData = filterDataForDataTable(
        { key, value: { value } },
        filterVals.current,
        origData
      );
      setGridData(filteredData!);
    } else {
      setGridData(origData);
    }
  };

  return (
    <React.Fragment>
      <div className="mdd-personnel--container">
        {/* 1st column */}
        <div className="mdd-personnel--container--column">
          {staticFields.map(f => renderStaticField(f))}
        </div>

        {/* 2nd column */}
        <div className="mdd-personnel--container--column">
          {editableFields.map(f => renderEditableField(f))}

          <MddDialogButtons
            saveButtonProps={{
              id: "btnSavePerson",
              disabled: disableSaveButton(),
              className: "abc",
              onClick: () => handleSaveDetails()
            }}
            closeButtonProps={{
              id: "btnClosePerson",
              className: "notVisible"
            }}
          />
        </div>
      </div>

      <Paper elevation={3} className="mdd-personnel-studies--paper">
        <span className="mdd-personnel-studies--paper-header">
          <h5 className="mdd-personnel-studies--paper-header--caption">
            Study History
          </h5>
        </span>
        <div className="mdd-personnel-studies--container">
          <BktDataTable
            data={gridData}
            defaultSortProp="sponsorName"
            columnProps={[
              {
                label: "Sponsor",
                filter: new Filter(eFilter.Text, {
                  debounceInterval: 0,
                  textProps: {
                    className: classConstants.TEXT_FILTER_CLASS,
                    id: "personStudiesSponsorFilter",
                    onChange: handleFilterChange,
                    name: "sponsorName"
                  }
                }),
                tableCellProps: {
                  id: "personStudiesSponsorHeader"
                },
                divProps: { id: "personStudiesSponsorHeader_div" },
                sortProp: { sortPropName: "sponsorName", desc: true }
              },
              {
                label: "Study Name",
                filter: new Filter(eFilter.Text, {
                  debounceInterval: 0,
                  textProps: {
                    className: classConstants.TEXT_FILTER_CLASS,
                    id: "personStudiesStudyNameFilter",
                    onChange: handleFilterChange,
                    name: "studyName"
                  }
                }),
                tableCellProps: {
                  id: "personStudiesStudyNameHeader"
                },
                divProps: { id: "personStudiesStudyNameHeader_div" },
                sortProp: { sortPropName: "studyName", desc: true }
              },
              {
                label: "Internal Study Name",
                filter: new Filter(eFilter.Text, {
                  debounceInterval: 0,
                  textProps: {
                    className: classConstants.TEXT_FILTER_CLASS,
                    id: "personStudiesInternalNameFilter",
                    onChange: handleFilterChange,
                    name: "internalStudyName"
                  }
                }),
                tableCellProps: {
                  id: "personStudiesInternaleNameHeader"
                },
                divProps: { id: "personStudiesInternalNameHeader_div" },
                sortProp: { sortPropName: "internalStudyName", desc: true }
              },
              {
                label: "Protocols",
                filter: new Filter(eFilter.Text, {
                  debounceInterval: 0,
                  textProps: {
                    className: classConstants.TEXT_FILTER_CLASS,
                    id: "personStudiesProtocolsFilter",
                    onChange: handleFilterChange,
                    name: "formattedProtocols"
                  }
                }),
                tableCellProps: {
                  id: "personStudiesProtocolsHeader"
                },
                divProps: { id: "personStudyProtocolsHeader_div" },
                sortProp: { sortPropName: "formattedProtocols", desc: true }
              },
              {
                label: "Action",
                tableCellProps: {
                  id: "personStudiesLinkHeader",
                  className: classConstants.TABLE_CELL_CLASS
                },
                divProps: { id: "personStudyLinkHeader_div" }
              }
            ]}
            tableBodyProps={{ id: "personStudies_tablebody" }}
            infiniteScrollProps={{
              id: "studiesInfiniteScroll",
              maxItems: 999
            }}
            noRecordsMessage={"No matching study history available"}
          />
        </div>
      </Paper>
    </React.Fragment>
  );
};
