import React, { useEffect, useState } from "react";
import { BktDataTable, SagaService } from "front-end-lib";
import { connect } from "react-redux";
import { MddDataContainer } from "../../../components";
import {
  demographicDataTableHeaders,
  getDataTableDemographics,
  demographicTableStyles
} from "../../../config";
import { AddDemographicDialog } from "./AddDemographicDialog";
import { DeleteDialog } from "./DeleteDialog";
import {
  getDemographicsState,
  handleActiveChange
} from "../../../store/resConfig/selectors";
import {
  IEditItem,
  eResConfig,
  IDemographicFields
} from "../../../store/resConfig/types";

interface IProps {
  fields: IDemographicFields;
  edit: IEditItem[];
  items: IEditItem[] | null;
}

const MddResExperienceGeneralComponent = ({ fields, items, edit }: IProps) => {
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<IEditItem>();

  useEffect(() => {
    SagaService.dispatchSaga({ type: eResConfig.GET_DEMOGRAPHICS });
  }, []);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleOpenDeleteDialog = (parentId: string) => {
    const item = items?.find(i => i.parentId === parentId);
    setOpenDeleteDialog(true);
    setDeleteItem({
      ...item!,
      softDeleted: true
    });
  };

  const handleCloseDeleteDialog = (isSave?: boolean) => {
    if (isSave) {
      SagaService.dispatchSaga({
        type: eResConfig.DELETE_ITEMS,
        payload: {
          section: "demographics",
          edits: edit,
          items,
          deleteItem
        }
      });
    }

    setOpenDeleteDialog(false);
  };

  const handleCloseAddDialog = (edits?: IEditItem[]) => {
    if (!!edits) {
      SagaService.dispatchSaga({
        type: eResConfig.EDIT_ITEMS,
        payload: { section: "demographics", edits }
      });
    }

    setOpenAddDialog(false);
  };

  const handleIsActiveChange = (parentId: string) => {
    const item = items?.find(i => i.parentId === parentId);
    SagaService.dispatchSaga({
      type: eResConfig.EDIT_ITEMS,
      payload: {
        section: "demographics",
        edits: handleActiveChange(item!, edit, items!)
      }
    });
  };

  return (
    <div className="mdd-res-draft--tab-content">
      <h3>Experience (General)</h3>
      <p className="mdd-res-draft--tab-content--paragraph">
        General Clinical Experience information fields included in Clinical
        Profile for the Rater Experience Survey (RES). Add or remove a
        demographic to capture demographic experience. Once published, any
        demographic selected cannot be deleted from the configuration. However,
        the status can be updated, which will no longer display the fields in
        the RES within Clinical Profile.
      </p>
      <div className="mdd-res-draft--tab-content--demographic-fields">
        <div>
          <h4>General Fields</h4>
          <ul data-testid="mdd-res-draft-static-general-fields">
            {fields.general.map(field => (
              <li key={field}>{field}</li>
            ))}
          </ul>
        </div>
        <div>
          <h4>Demographic Fields</h4>
          <ul data-testid="mdd-res-draft-static-demographic-fields">
            {fields.demographic.map(field => (
              <li key={field}>{field}</li>
            ))}
          </ul>
        </div>
      </div>
      {items && (
        <MddDataContainer
          id="resExperienceGeneralContainer"
          title="Demographics Included"
          classes={{
            container: "mdd-res-draft--tableContainer"
          }}
          addAction={{
            display: "Add Demographic(s)",
            action: () => handleOpenAddDialog()
          }}
        >
          <BktDataTable
            noRecordsMessage="Please add a Demographic(s)"
            data={getDataTableDemographics(items, handleIsActiveChange)}
            columnProps={demographicDataTableHeaders(handleOpenDeleteDialog)}
            {...demographicTableStyles}
          />
        </MddDataContainer>
      )}
      <AddDemographicDialog
        isOpen={openAddDialog}
        onClose={handleCloseAddDialog}
      />
      <DeleteDialog
        itemName={`${deleteItem?.name!} Demographic`}
        open={openDeleteDialog}
        title={"Demographic"}
        onClose={handleCloseDeleteDialog}
      />
    </div>
  );
};

export const MddResExperienceGeneral = connect(getDemographicsState)(
  MddResExperienceGeneralComponent
);
