import * as React from "react";
import { connect } from "react-redux";
import { SagaService } from "front-end-lib/core";
import { Study, IMddStudyProps } from "../../../model";
import { IApplicationState, eStudyActionTypes } from "../../../store";
import { eStudySaga } from "../../../sagas";
import { store } from "../../../utils";
import { withMddBase } from "../WithMddBase/WithMddBase";

/**
 * HOC for any study components.
 * Will map the state to props for study specific store state.
 * @returns {(WrappedComponent: any) => React.Component} Returns a component with base study functionality implemented.
 */
export const withMddStudy = () => {
  return (WrappedComponent: any) => {
    return withMddBase(
      connect((state: IApplicationState) => {
        return {
          study: state.study.currentStudy
        };
      })((props: IMddStudyProps) => {
        const { id } = props.match.params;
        React.useEffect(() => {
          if (id && id !== props.study.StudyId) {
            store.dispatch({
              type: eStudyActionTypes.SET_STUDY,
              payload: new Study()
            });
            SagaService.dispatchSaga({
              type: eStudySaga.FETCH_STUDY,
              payload: id
            });
          }
          // eslint-disable-next-line
        }, []);
        return <WrappedComponent {...(props || {})} />;
      })
    );
  };
};
