import React, { useCallback } from "react";
import { connect } from "react-redux";
import { SagaService } from "front-end-lib";
import { IApplicationState } from "../../../store";
import { ITabData, eResConfig } from "../../../store/resConfig/types";
import { MddDialog, MddDialogButtons } from "../../../components/common";
import { IResVersionListItem } from "../../../model/interfaces/iResVersionListItem";
import { MddResViewMetadata } from "./MddResViewMetadata";
import { MddResViewDetails } from "./MddResViewDetails";

interface IMddResConfigViewProps {
  studyId: string;
  resMetadata: IResVersionListItem;
  open: boolean;
  onClose: () => void;
  demographics: ITabData;
  education: ITabData;
  general: ITabData;
  indications: ITabData;
  scales: ITabData;
}

const _MddResConfigView = ({
  studyId,
  resMetadata,
  open,
  onClose,
  demographics,
  education,
  general,
  indications,
  scales
}: IMddResConfigViewProps) => {
  const cleanup = useCallback(() => {
    SagaService.dispatchSaga({
      type: eResConfig.RESET,
      payload: { studyId: studyId }
    });
  }, [studyId]);

  React.useEffect(() => {
    SagaService.dispatchSaga({
      type: eResConfig.GET_RES_CONFIG,
      payload: { studyId, resId: resMetadata.id }
    });
    SagaService.dispatchSaga({
      type: eResConfig.GET_SCALES,
      payload: { studyId: studyId }
    });
    SagaService.dispatchSaga({ type: eResConfig.GET_INDICATIONS });
    SagaService.dispatchSaga({ type: eResConfig.GET_DEMOGRAPHICS });

    return cleanup;
  }, [cleanup, studyId, resMetadata]);

  return (
    <React.Fragment>
      <MddDialog
        showCloseButton={true}
        dialogProps={{
          open: open,
          maxWidth: "lg",
          fullWidth: true
        }}
        dialogContentProps={{ className: "mdd-res-config-view--dialog" }}
        dialogTitleProps={{ title: "Rater Experience Survey" }}
        dialogContent={
          <React.Fragment>
            <div className="mdd-res-config-view">
              {<MddResViewMetadata resMetadata={resMetadata} />}
            </div>
            <hr />
            <div>
              <MddResViewDetails
                demographics={demographics}
                education={education}
                general={general}
                indications={indications}
                scales={scales}
                studyId={studyId}
              />
            </div>
            <hr />
          </React.Fragment>
        }
        onCloseClick={() => onClose()}
        dialogActions={
          <MddDialogButtons
            hideSaveButton={true}
            closeButtonText="Close"
            closeButtonProps={{ onClick: () => onClose() }}
          />
        }
      />
    </React.Fragment>
  );
};

export const MddResConfigView = connect(({ resConfig }: IApplicationState) => ({
  demographics: resConfig.demographics,
  education: resConfig.education,
  general: resConfig.general,
  indications: resConfig.indications,
  scales: resConfig.scales
}))(_MddResConfigView);
