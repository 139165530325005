import { isEqual } from "lodash";
import * as React from "react";
import Button from "@material-ui/core/Button";
import {
  SagaService,
  BktValidatedSelectChipField,
  IAction
} from "front-end-lib/core";
import {
  BktBreadcrumb,
  withMddStudy,
  withMddLookups,
  MddForm
} from "../../../components";
import { isLoading } from "../../../utils";
import {
  Validations,
  ClinicalData,
  ILookup,
  IMddStudyProps
} from "../../../model";
import { eLookupSaga, eStudySaga } from "../../../sagas";

interface IBktAddEditStudyClinicalData extends IMddStudyProps {
  demographics: ILookup[];
  indications: ILookup[];
  specialties: ILookup[];
  therapeuticAreas: ILookup[];
}

const _BktAddEditStudyClinicalData = (props: IBktAddEditStudyClinicalData) => {
  // let formData = new ClinicalData();
  const [clinicalData, setClinicalData] = React.useState(new ClinicalData());
  const [newClinicalData, setNewClinicalData] = React.useState(
    new ClinicalData()
  );

  const {
    TherapeuticArea = [],
    Indications = [],
    Specialties = [],
    Demographics = []
  } = newClinicalData;
  const {
    study,
    therapeuticAreas = [],
    indications = [],
    demographics = [],
    specialties = []
  } = props;

  const clinicalDataCallback = (action: IAction) => {
    const { type, payload } = action;
    if (
      type === eStudySaga.FETCH_CLINICAL_DATA_SUCCESS ||
      type === eStudySaga.SAVE_CLINICAL_DATA_STUDY_SUCCESS
    ) {
      setClinicalData(payload);
      setNewClinicalData(payload);
    }
  };

  React.useEffect(() => {
    const removeFetchSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_CLINICAL_DATA,
      clinicalDataCallback
    );
    const removeSaveSub = SagaService.subscribeToSaga(
      eStudySaga.SAVE_CLINICAL_DATA_STUDY,
      clinicalDataCallback
    );
    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_CLINICAL_DATA.toString(),
      payload: props.match.params.id
    });
    return () => {
      removeFetchSub();
      removeSaveSub();
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: any) => {
    setNewClinicalData(
      Object.assign(new ClinicalData(), newClinicalData, {
        [event.target.name]: event.target.value
      })
    );
  };
  const disableSubmitButton = () => {
    return !(
      TherapeuticArea.length > 0 &&
      Indications.length > 0 &&
      !isEqual(clinicalData, newClinicalData) &&
      !isLoading(`undefined_${eStudySaga.SAVE_CLINICAL_DATA_STUDY}`)
    );
  };

  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!disableSubmitButton()) {
      SagaService.dispatchSaga({
        type: eStudySaga.SAVE_CLINICAL_DATA_STUDY,
        payload: newClinicalData
      });
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <BktBreadcrumb
        rootText="All Studies"
        rootUrl="/study"
        currentLocation={!!study.StudyId ? study.StudyName : "Loading Study"}
      />
      <MddForm
        keys={[
          `HOCLookup_${eLookupSaga.FETCH_DEMOGRAPHICS}`,
          `HOCLookup_${eLookupSaga.FETCH_INDICATIONS}`,
          `HOCLookup_${eLookupSaga.FETCH_SPECIALITIES}`,
          `HOCLookup_${eLookupSaga.FETCH_THERAPEUTIC_AREAS}`,
          `undefined_${eStudySaga.FETCH_CLINICAL_DATA}`,
          `undefined_${eStudySaga.SAVE_CLINICAL_DATA_STUDY}`
        ]}
      >
        <div className="mdd-form--body">
          <BktValidatedSelectChipField
            id={"therapeuticArea"}
            name={"therapeuticArea"}
            inputLabelText={"Therapeutic Areas"}
            data={therapeuticAreas}
            value={TherapeuticArea}
            onChange={handleChange}
            validationForOnBlur={[Validations.required]}
            validationForOnChange={[Validations.required]}
          />
          <BktValidatedSelectChipField
            id={"indications"}
            name={"indications"}
            inputLabelText={"Indications"}
            data={indications}
            value={Indications}
            onChange={handleChange}
            validationForOnBlur={[Validations.required]}
            validationForOnChange={[Validations.required]}
          />
          <BktValidatedSelectChipField
            id={"specialties"}
            name={"specialties"}
            inputLabelText={"Specialities"}
            data={specialties}
            value={Specialties}
            onChange={handleChange}
            inputLabelProps={{ required: false }}
          />
          <BktValidatedSelectChipField
            id={"demographics"}
            name={"demographics"}
            inputLabelText={"Demographics"}
            data={demographics}
            value={Demographics}
            onChange={handleChange}
            inputLabelProps={{ required: false }}
          />
          <div className="mdd-form__button--container">
            <Button
              id="saveButtonId"
              color="primary"
              disableFocusRipple={true}
              disableRipple={true}
              disabled={disableSubmitButton()}
              variant="contained"
              type="submit"
              onClick={handleSave}
              className="mdd-form__button"
            >
              SAVE
            </Button>
          </div>
        </div>
      </MddForm>
    </div>
  );
};

export const BktAddEditStudyClinicalData = withMddLookups([
  "demographics",
  "indications",
  "specialties",
  "therapeuticAreas"
])(withMddStudy()(_BktAddEditStudyClinicalData));
