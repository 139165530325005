import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  ResMenuContainer,
  resMenuItemCategories
} from "../../../model/classes/menus/resMenuList";
import { withMddGenericMenu } from "../../higher-order";
import { MddResDraft, RES_SECTIONS } from "./MddResDraft";
import { MddResEducation } from "./MddResEducation";
import { MddResExperienceGeneral } from "./MddResExperienceGeneral";
import { MddResExperienceIndication } from "./MddResExperienceIndication";
import { MddResGeneral } from "./MddResGeneral";
import { MddResScale } from "./MddResScale";

export const MddResDraftWrapper = (props: RouteComponentProps<any>) => {
  const { section } = props.match.params;

  let content: React.ReactNode;
  let resMenuItemCategory: resMenuItemCategories;

  switch (section) {
    case RES_SECTIONS[RES_SECTIONS.demographics]:
      content = <MddResGeneral />;
      resMenuItemCategory = resMenuItemCategories.Demographics;
      break;
    case RES_SECTIONS[RES_SECTIONS.education]:
      content = <MddResEducation />;
      resMenuItemCategory = resMenuItemCategories.Education;
      break;
    case RES_SECTIONS[RES_SECTIONS.experienceGeneral]:
      content = <MddResExperienceGeneral />;
      resMenuItemCategory = resMenuItemCategories.ExperienceGeneral;
      break;
    case RES_SECTIONS[RES_SECTIONS.experienceIndication]:
      content = <MddResExperienceIndication />;
      resMenuItemCategory = resMenuItemCategories.ExperienceIndication;
      break;
    case RES_SECTIONS[RES_SECTIONS.experienceScales]:
      content = <MddResScale />;
      resMenuItemCategory = resMenuItemCategories.ExperienceScales;
      break;
    default:
      return null;
  }

  return withMddGenericMenu(
    MddResDraft,
    new ResMenuContainer(),
    true,
    resMenuItemCategory
  )({ content, ...props } as any);
};
