import React, { useState } from "react";
import { ITabData } from "../../../store/resConfig/types";
import { MddExpansionPanel } from "../../common/MddExpansionPanel/MddExpansionPanel";
import { IndicationItems } from "./MddResViewIndicationItems";

interface IMddResViewIndicationsProps {
  indications: ITabData;
  demographics: ITabData;
}

export const MddResViewIndications = ({
  indications,
  demographics
}: IMddResViewIndicationsProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <MddExpansionPanel
      expanded={isExpanded}
      id="panelIndications"
      data-testid="panelIndications"
      panelClass="mdd-expansion-panel--panel mdd-res-config-view--panel-nonscales"
      headerText={<h3>Experience (Indication)</h3>}
      onChange={(event, expanded) => setIsExpanded(!isExpanded)}
    >
      <div
        id="viewRes-indications"
        data-testid="viewRes-indications"
        className="mdd-res-config-view"
      >
        <h4 className="mdd-res-config-view--panel-description">
          Indication Fields:
        </h4>
        {indications?.fields?.map((field: string) => (
          <div key={field} className="value">
            {field}
          </div>
        ))}
        <div className="mdd-res-config-view--panel-description mdd-res-config-view--panel-description-flex mdd-res-config-view--panel-description-padding">
          <h4>Indications Included:</h4>
          <div className="mdd-res-config-view--panel-description-inactive">
            *Inactive
          </div>
        </div>
        <IndicationItems
          indications={indications}
          demographics={demographics}
        />
      </div>
    </MddExpansionPanel>
  );
};
