import * as React from "react";
import { connect } from "react-redux";
import { Store } from "redux";
import { useOktaAuth } from "@okta/okta-react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CircularProgress } from "front-end-lib/core";
import muiTheme from "./muiArtifacts/muiTheme";
import { IApplicationState, updateUser } from "./store";
import Routes from "./routes";
import {
  BktHeader,
  BktNotification,
  useSlidingExpiration,
  withRouter
} from "./components";
import {
  // INACTIVITY_TIMEOUT,
  LOGIN_STATE,
  SH_SESSION_COOKIE_POLLING_INTERVAL
} from "./constants";
import { IUser, User } from "./model";
import { UserService } from "./services";
import { getSHCookieJson, setSHCookie } from "./utils";
interface IMainProps {
  store: Store<IApplicationState>;
  updateUser: (newUser: IUser) => any;
  user: IUser;
  keepAlive: () => Promise<number>;
}

const MainComponent = (props: IMainProps) => {
  const { updateUser, user, keepAlive } = props;
  const { authState, oktaAuth } = useOktaAuth();

  const userService = new UserService(oktaAuth);

  const [loginState, setLoginState] = React.useState(
    LOGIN_STATE.AUTHENTICATING
  );

  const loginStateRef = React.useRef(loginState);
  loginStateRef.current = loginState;

  const userRef = React.useRef(user);
  userRef.current = user;

  // For inactivity timeout - we are currently not implementing any grace period.
  // If the user is inactive for the specified time, we will log them out, no questions asked.
  useSlidingExpiration({
    gracePeriodSeconds: 0,
    keepAlive,
    cancel: () => {
      setLoginState(LOGIN_STATE.LOGGING_OUT);
      userService.signOut();
    }
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (
        loginStateRef.current === LOGIN_STATE.LOGGING_OUT ||
        loginStateRef.current === LOGIN_STATE.AUTHENTICATING
      ) {
        // If we are already logging out (because either we did or another app did)
        // then don't do anything, it's already in process
        return;
      }
      const cookie = getSHCookieJson();
      if (!cookie || cookie.user !== userRef.current.Email) {
        setLoginState(LOGIN_STATE.LOGGING_OUT);
        userService.signOut();
      }
    }, SH_SESSION_COOKIE_POLLING_INTERVAL * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const doSigninAsync = async () => {
      await userService.signInWithRedirect();
    };

    const getUser = async (token: string) => {
      const info = await userService.getUser();

      updateUser(
        new User(
          info.sub,
          info.email,
          info.firstName,
          info.lastName,
          info.currentLoginTimestamp,
          info.lastLoginTimestamp,
          info.email_verified,
          info.login_email,
          info.groups,
          token
        )
      );

      /* We know the token is a valid one at this point so let's check for the cookie
         If it's not there then we need to create it since some ID Portal flows don't do it. */
      const cookieObject = getSHCookieJson();
      if (!cookieObject) {
        // create it
        setSHCookie(info.email as string, Date.now());
      }

      setLoginState(LOGIN_STATE.AUTHENTICATED);
    };

    const token = userService.getAccessToken();

    if ((!authState || !authState.isAuthenticated) && token === undefined) {
      doSigninAsync();
    } else {
      getUser(token!);
    }
    // eslint-disable-next-line
  }, [authState, oktaAuth]); // Update if authState changes

  const setInProgress = (logoutInProgress: boolean) => {
    if (logoutInProgress) {
      setLoginState(LOGIN_STATE.LOGGING_OUT);
    } else {
      setLoginState(LOGIN_STATE.AUTHENTICATED);
    }
  };

  const renderProgress = () => {
    // NOTE: we won't be in here if authenticated
    switch (loginState) {
      case LOGIN_STATE.AUTHENTICATING:
      case LOGIN_STATE.LOGGING_OUT: {
        // authenticating (before timeout) or logging out so show the spinner
        return <CircularProgress />;
      }
      default: {
        // They were authenticated to AzureAD but are not authorized in endSight.
        return <label>You are not authorized to use this application.</label>;
      }
    }
  };

  return withRouter(
    <MuiThemeProvider theme={muiTheme}>
      <main>
        {loginState === LOGIN_STATE.AUTHENTICATED ? (
          <React.Fragment>
            <BktHeader
              inProgressCallback={setInProgress}
              userService={userService}
            />
            <Routes />
          </React.Fragment>
        ) : (
          <span id="mainLoading">{renderProgress()}</span>
        )}
      </main>
      <BktNotification />
    </MuiThemeProvider>
  );
};

export const Main = connect(
  (state: IApplicationState) => ({ user: state.user }),
  { updateUser }
)(MainComponent);
