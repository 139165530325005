import * as React from "react";

export const MddResConfigDialogContent = () => {
  return (
    <div className="mdd-res-config-dialog">
      <div className="mdd-res-config-dialog--header">
        Initial Publish (RES Version 1.0):
      </div>
      <div className="mdd-res-config--paragraph--text">
        All active Study Personnel with the role rater will automatically be
        assigned to the RES request in Clinical Profile. New Persons added to
        the study with the rater role will be assigned when added.
      </div>
      <div className="mdd-res-config--paragraph">
        <div className="mdd-res-config-dialog--header">
          Updated RES Published (RES Version 2.0 and up):
        </div>
        <div className="mdd-res-config--paragraph--text">
          Currently assigned and incomplete RES requests in Clinical Profile
          will reflect the updated version. New persons added to the study with
          the rater role will be assigned. Raters who completed the previous RES
          version will NOT be automatically assigned to the updated RES or
          receive a request to complete any new fields that were added.
        </div>
      </div>
    </div>
  );
};
