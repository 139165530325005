import { ConfigOptions } from "../../config";
import { MDDHTTPService } from "../../services";
import { IUtilState, eUtilActionTypes } from "./types";

const initialState: IUtilState = {
  loadingKeys: [],
  mddHTTPService: new MDDHTTPService(ConfigOptions.mddApiUrl),
  studyApiHTTPService: new MDDHTTPService(ConfigOptions.studyApiUrl)
};

export const utilReducer: any = (
  state: IUtilState = initialState,
  action: any
) => {
  switch (action.type) {
    case eUtilActionTypes.SET_LOADING: {
      const loadingKeys = action.payload.loading
        ? [...state.loadingKeys, action.payload.key]
        : state.loadingKeys.filter(lk => lk !== action.payload.key);
      return { ...state, ...{ loadingKeys } };
    }
    default: {
      return state;
    }
  }
};
