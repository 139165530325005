import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IBktDataTableColumnProps } from "front-end-lib";
import React from "react";

import { MddCheckbox } from "../../../components";
import { IEditItem } from "../../../store/resConfig";

export const tableStyles = {
  tableHeadProps: {
    className: "tbl--header tableHead",
    id: "resDraftTableHeader"
  },
  tableBodyProps: {
    rowProps: { className: "tbl--body-row mdd-tbl--body-row" }
  },
  tableFooterProps: {
    className: "mdd-res-draft--tableFooter",
    id: "resDraftTableFooter"
  }
};

export const headers = (
  openDeleteDialog: (item: IEditItem) => void
): IBktDataTableColumnProps[] => [
  {
    label: "Indication",
    tableCellProps: { id: "indicationHeader" }
  },
  {
    label: "Active",
    tableCellProps: { id: "activeHeader" }
  },
  {
    label: "Delete",
    tableCellProps: { id: "deleteHeader" },
    formatProp: {
      formatPropName: "item",
      format: (item: IEditItem) => (
        <div className="tbl--body-row-actions">
          <FontAwesomeIcon
            className={item.deletable ? "" : "icon-disabled"}
            data-testid={`deleteIcon_${item.parentId}${item.childId ??
              `_${item.childId}`}`}
            onClick={() => openDeleteDialog(item)}
            icon={faTimesCircle}
            fixedWidth
            id="closeIcon"
            size="xl"
          />
        </div>
      )
    }
  }
];

export const getConfig = (
  indications: IEditItem[],
  handle: (indication: IEditItem) => void
) =>
  indications.map(i => ({
    name: (
      <span style={{ paddingLeft: i.nested ? "16px" : "0" }}>{i.name}</span>
    ),
    key: i.name!,
    active: (
      <MddCheckbox
        lblText=""
        chkBoxProps={{
          id: `activeCheckbox_${i.parentId}${i.childId ?? `_${i.childId}`}`,
          checked: i.isActive,
          disabled: i.disabledOnGrid,
          className: "activeCheckbox",
          onClick: () => handle(i)
        }}
      />
    ),
    item: i
  }));
