import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { IMenuItem } from "../../model";
import { eIconTypes } from "../../constants/applicationConstants";

export class MenuListItem implements IMenuItem {
  public menuText: string;
  public icon: any;
  public linkTo: string;
  public anchor: string;
  public iconType: eIconTypes;
  public constructor(
    menuText: string,
    icon: string | IconDefinition,
    linkTo: string,
    anchor: string,
    iconType: eIconTypes = eIconTypes.MDI
  ) {
    this.menuText = menuText;
    this.icon = icon;
    this.linkTo = linkTo;
    this.anchor = anchor;
    this.iconType = iconType;
  }
}
