import { IUser } from "../interfaces";

export class User implements IUser {
  private currentLoginTimestamp: string;
  private lastLoginTimestamp: string;
  private email: string;
  private email_verified: boolean;
  private login_email: string;
  private firstName: string;
  private lastName: string;
  private sub: string;
  private groups: string[];
  private token: string;

  get CurrentLoginTimestamp() {
    return this.currentLoginTimestamp;
  }

  get LastLoginTimestamp() {
    return this.lastLoginTimestamp;
  }

  get Email() {
    return this.email;
  }

  get Email_verified() {
    return this.email_verified;
  }

  get Login_email() {
    return this.login_email;
  }

  get FirstName() {
    return this.firstName;
  }

  get LastName() {
    return this.lastName;
  }

  get Sub() {
    return this.sub;
  }

  get Groups() {
    return this.groups;
  }

  get Token() {
    return this.token;
  }

  set Token(val: string) {
    this.token = val;
  }

  constructor(
    sub: string = "",
    email: string = "",
    firstName: string = "",
    lastName: string = "",
    currentLoginTimestamp: string = "",
    lastLoginTimestamp: string = "",
    email_verified: boolean = true,
    login_email: string = "",
    groups: string[] = [],
    token: string = ""
  ) {
    this.sub = sub;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.currentLoginTimestamp = currentLoginTimestamp;
    this.lastLoginTimestamp = lastLoginTimestamp;
    this.email_verified = email_verified;
    this.login_email = login_email;
    this.groups = groups;
    this.token = token;
  }
}
