import * as React from "react";
import { truncate } from "lodash";

interface IMddExpandableMultilineProps {
  // expansion behavior enabled
  isExpandable?: boolean;
  // length to display when truncated
  truncateLength?: number;
  // how much overage is allowed so that we don't truncate with only a few chars remaining
  overageAllowed?: number;
  // pattern to use to truncate to (e.g. a space to not truncate in the middle of a word)
  separator?: string; //
  // string to use at the end when truncated (e.g. "..." which is the default)
  omissionString?: string;
  // string to use as the option to show more (defaults to "Show More")
  moreText?: string;
  // string to use as the option to show less (defaults to "Show Less")
  lessText?: string;
  // text to display
  displayText: string;
}

export const MddExpandableMultiline = (props: IMddExpandableMultilineProps) => {
  const {
    isExpandable = true,
    truncateLength = 200,
    overageAllowed = 20,
    separator = " ",
    omissionString = "...",
    moreText = "Show More",
    lessText = "Show Less",
    displayText
  } = props;

  const calcTruncLength = () => {
    // The truncateLength passed in is where the caller typically wants to truncate the string.
    // However, if they supply an overageAllowed value and the text passed in is less than truncateLength + overageAllowed then
    // don't truncate it.  This will prevent truncating something when there are only a couple of characters not shown.
    // If the string size exceeds truncateLength + overageAllowed then we still will truncate at truncateLength.
    let truncLen = truncateLength;
    const textLen = displayText.length;
    if (textLen <= truncateLength + overageAllowed) {
      truncLen = textLen;
    }
    return truncLen;
  };

  const isOversize = displayText.length > calcTruncLength();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const truncateText = isExpandable && !isExpanded;

  const showMore = isExpandable && !isExpanded && isOversize;

  const showLess = isExpandable && isExpanded;

  return (
    <div
      id="commentTextDiv"
      className="mdd-comments--comment--info-div--comment-text"
      data-testid="commentTextDiv"
    >
      {!truncateText && <span>{displayText}</span>}
      {truncateText && (
        <span id="commentText" data-testid="commentText">
          {truncate(displayText, {
            length: calcTruncLength(),
            separator: separator,
            omission: omissionString
          })}
        </span>
      )}
      {showMore && (
        <div
          id="divShowMore"
          data-testid="divShowMore"
          className="mdd-comments--show-more-less"
          onClick={() => setIsExpanded(true)}
        >
          {moreText}
        </div>
      )}
      {showLess && (
        <div
          id="divShowLess"
          data-testid="divShowLess"
          className="mdd-comments--show-more-less"
          onClick={() => setIsExpanded(false)}
        >
          {lessText}
        </div>
      )}
    </div>
  );
};
