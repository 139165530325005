import * as React from "react";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

interface IMddCheckboxProps {
  chkBoxProps?: CheckboxProps;
  lblProps?: React.HTMLProps<HTMLLabelElement>;
  lblText: string;
}

export const MddCheckbox = (props: IMddCheckboxProps) => {
  const { lblText, lblProps = {}, chkBoxProps = {} } = props;
  const id = chkBoxProps && chkBoxProps.id ? chkBoxProps.id : "mddChkBox";
  const lblId = lblProps.id || `${id}Lbl`;

  return (
    <div id={`${id}Div`} data-testid={`${id}Div`} className="mdd-chkbox">
      <Checkbox id={id} data-testid={id} {...chkBoxProps} />
      <label id={lblId} data-testid={lblId} htmlFor={id} {...lblProps}>
        {lblText}
      </label>
    </div>
  );
};
