import React, { useCallback } from "react";
import { IIdNamePair } from "../../../model";
import { IEditItem, ITabData } from "../../../store/resConfig";

interface IProps {
  indications: ITabData;
  demographics: ITabData;
}

const getIsActiveParent = (parentId: string, items: IEditItem[]) =>
  items.find(item => item.parentId === parentId && !item.childId)?.isActive;

const getClassName = (item: IEditItem) => {
  const activeClassName = item.isActive ? "" : "inactive-item";
  const indicationClassName = item.childId
    ? "childIndication"
    : "parentIndication";

  return `${activeClassName} ${indicationClassName} value`;
};

export const IndicationItems = ({ indications, demographics }: IProps) => {
  const findIndication = useCallback(
    (parentId: string, childId?: string) => {
      const indicationName = indications.list?.find(
        (item: IIdNamePair) => item.id === parentId
      )?.name;

      if (childId) {
        const demographicName = `${
          demographics.list?.find((item: any) => item.id === childId)?.name
        }`;
        return `${demographicName} ${indicationName}`;
      }

      return `${indicationName}`;
    },
    [indications, demographics]
  );

  const getName = (item: IEditItem) => {
    const name = findIndication(item.parentId, item.childId);

    const isParent = !item.childId;
    const isActiveParent = getIsActiveParent(item.parentId, indications.items!);

    const value =
      (isParent && !item.isActive) ||
      (isActiveParent && !isParent && !item.isActive)
        ? `*${name}`
        : name;
    return value;
  };

  if (!indications || !indications.items || indications.items === null) {
    return <></>;
  }

  const renderedItems: JSX.Element[] = [];
  const parents = indications.items.filter(item => !item.childId);

  parents.forEach(parent => {
    renderedItems.push(
      <div key={parent.id} className={getClassName(parent)}>
        {getName(parent)}
      </div>
    );

    const children = indications.items!.filter(
      child => child.parentId === parent.parentId && child.childId
    );

    children.forEach(child =>
      renderedItems.push(
        <div key={child.id} className={getClassName(child)}>
          {getName(child)}
        </div>
      )
    );
  });

  return <>{renderedItems}</>;
};
