import * as React from "react";
import { IQuestionGroup } from "../../../model/interfaces/IPersonnelCPResExperience";
import {
  BktDataTable,
  IBktDataTableColumnProps,
  IBktDataTableData
} from "front-end-lib";
import { MddNoDataDiv } from "../../common";
import { QnA, getQuestionsAndAnswers } from "./Utils";

interface IProps {
  data?: IQuestionGroup;
  headers: (questions: string[]) => IBktDataTableColumnProps[];
  getDataTableAnswers: (
    qnA: QnA[][],
    metaData: string[]
  ) => IBktDataTableData[];
}
export const MddDataTablePanelContent = ({
  data,
  headers,
  getDataTableAnswers
}: IProps) => {
  if (!data) {
    return <MddNoDataDiv />;
  }

  const qnA = getQuestionsAndAnswers(data);
  return (
    <BktDataTable
      tableHeadProps={{
        className: "mdd-experience--panel--content--text"
      }}
      columnProps={headers(data.questions)}
      tableFooterProps={{ className: "displayNone" }}
      data={getDataTableAnswers(qnA, data.meta)}
    />
  );
};
