import { IStudyScale } from "..";

export class StudyScale implements IStudyScale {
  private studyId: string;
  private id: string;
  private name: string;
  private shortName: string;
  private isActive: boolean;

  get StudyId() {
    return this.studyId;
  }
  set StudyId(val) {
    this.studyId = val;
  }
  get ScaleId() {
    return this.id;
  }
  set ScaleId(val) {
    this.id = val;
  }
  get Name() {
    return this.name;
  }
  set Name(val) {
    this.name = val;
  }
  get ShortName() {
    return this.shortName;
  }
  set ShortName(val) {
    this.shortName = val;
  }
  get IsActive() {
    return this.isActive;
  }
  set IsActive(val) {
    this.isActive = val;
  }

  constructor(
    studyId?: string,
    id?: string,
    name?: string,
    shortName?: string,
    isActive?: boolean
  ) {
    this.studyId = studyId || "";
    this.id = id || "";
    this.name = name || "";
    this.shortName = shortName || "";
    this.isActive = isActive || false;
  }
}
