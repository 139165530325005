import * as React from "react";
import { Switch } from "react-router-dom";
import { SecureRoute } from "@okta/okta-react";

import {
  StudyMenuContainer,
  studyMenuItemCategories,
  SponsorMenuContainer,
  sponsorMenuItemCategories,
  SiteMenuContainer,
  siteMenuItemCategories,
  StudySiteMenuContainer,
  studySiteMenuItemCategories,
  PersonMenuContainer,
  personMenuItemCategories,
  StudySitePersonnelMenuContainer,
  studySitePersonnelMenuItemCategories
} from "./model/classes/menus";

import {
  BktShowSponsors,
  BktShowStudies,
  BktShowSponsorPrograms,
  BktShowSites,
  BktAddEditSponsor,
  BktAddEditStudy,
  BktAddEditSite,
  BktAddEditStudyClinicalData,
  BktShowStudyProtocols,
  BktShowStudyScales,
  BktShowSponsorStudies,
  BktShowSiteComments,
  BktShowStudySites,
  MddAddEditStudySite,
  BktShowScales,
  BktShowStudySitePersonnel,
  BktShowPersonnel,
  withMddGenericMenu,
  MddShowStudyQualifications,
  MddEditPersonnel,
  MddAddEditStudySiteComments,
  MddEditStudySitePersonnel,
  MddStudySitePersonTraining,
  MddShowStudyScores,
  MddShowStudyCourseScores,
  //  commenting out until API work done for bulk LP assign
  // MddBulkLPAssign,
  MddEditCourseCountryScores,
  MddEditCourseMasterScores,
  MddResConfig,
  MddResDraftWrapper,
  MddResDisplayWrapper
} from "./components";

const ENABLE_MENU: boolean = true;
const DISABLE_MENU: boolean = false;
const Routes: React.SFC = () => (
  <React.Fragment>
    <Switch>
      <SecureRoute path="/" exact={true} component={BktShowStudies} />
      <SecureRoute path="/sponsor" exact={true} component={BktShowSponsors} />
      <SecureRoute
        path="/sponsor/new"
        render={withMddGenericMenu(
          BktAddEditSponsor,
          new SponsorMenuContainer(),
          false,
          sponsorMenuItemCategories.General
        )}
      />
      <SecureRoute
        path="/sponsor/:id/study"
        render={withMddGenericMenu(
          BktShowSponsorStudies,
          new SponsorMenuContainer(),
          true,
          sponsorMenuItemCategories.Studies
        )}
      />
      <SecureRoute
        path="/sponsor/:id/program"
        render={withMddGenericMenu(
          BktShowSponsorPrograms,
          new SponsorMenuContainer(),
          true,
          sponsorMenuItemCategories.Programs
        )}
      />
      <SecureRoute
        path="/sponsor/:id"
        render={withMddGenericMenu(
          BktAddEditSponsor,
          new SponsorMenuContainer(),
          true,
          sponsorMenuItemCategories.General
        )}
      />
      <SecureRoute
        path="/study/new/:sponsorId?"
        render={withMddGenericMenu(
          BktAddEditStudy,
          new StudyMenuContainer(),
          false,
          studyMenuItemCategories.General
        )}
      />
      <SecureRoute
        path="/study/:id/sites/:siteId/personnel"
        exact={true}
        render={withMddGenericMenu(
          BktShowStudySitePersonnel,
          new StudySiteMenuContainer(),
          true,
          studySiteMenuItemCategories.Personnel
        )}
      />
      <SecureRoute
        path="/study/:id/sites/:siteId/personnel/:personnelId"
        exact={true}
        render={withMddGenericMenu(
          MddEditStudySitePersonnel,
          new StudySitePersonnelMenuContainer(),
          true,
          studySitePersonnelMenuItemCategories.GeneralInfo
        )}
      />
      <SecureRoute
        path="/study/:id/sites/:siteId/personnel/:personnelId/experience"
        exact={true}
        render={withMddGenericMenu(
          MddResDisplayWrapper,
          new StudySitePersonnelMenuContainer(),
          true,
          studySitePersonnelMenuItemCategories.Experience
        )}
      />

      <SecureRoute
        path="/study/:id/sites/:siteId/personnel/:personnelId/training"
        exact={true}
        render={withMddGenericMenu(
          MddStudySitePersonTraining,
          new StudySitePersonnelMenuContainer(),
          true,
          studySitePersonnelMenuItemCategories.Training
        )}
      />
      <SecureRoute
        path="/study/:id/sites/:siteId/comments"
        render={withMddGenericMenu(
          MddAddEditStudySiteComments,
          new StudySiteMenuContainer(),
          true,
          studySiteMenuItemCategories.Comments
        )}
      />
      <SecureRoute
        path="/study/:id/clinicaldata"
        render={withMddGenericMenu(
          BktAddEditStudyClinicalData,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.ClinicalData
        )}
      />
      <SecureRoute
        path="/study/:id/scales"
        render={withMddGenericMenu(
          BktShowStudyScales,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.Scales
        )}
      />
      <SecureRoute
        path="/study/:id/protocol"
        render={withMddGenericMenu(
          BktShowStudyProtocols,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.Protocols
        )}
      />
      {/* commenting out until API work done for bulk LP assign */}
      {/* <Route
      path="/study/:id/assignLPs"
      render={withMddGenericMenu(
        MddBulkLPAssign,
        new StudyMenuContainer(),
        true,
        studyMenuItemCategories.AssignLearningPlans
      )}
    /> */}
      <SecureRoute
        path="/study/:id/sites/new"
        render={withMddGenericMenu(
          MddAddEditStudySite,
          new StudySiteMenuContainer(),
          DISABLE_MENU,
          studySiteMenuItemCategories.GeneralInfo
        )}
      />
      <SecureRoute
        path="/study/:id/sites/:siteId"
        render={withMddGenericMenu(
          MddAddEditStudySite,
          new StudySiteMenuContainer(),
          ENABLE_MENU,
          studySiteMenuItemCategories.GeneralInfo
        )}
      />
      <SecureRoute
        path="/study/:id/sites"
        render={withMddGenericMenu(
          BktShowStudySites,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.Sites
        )}
      />
      <SecureRoute
        path="/study/:id/qualifications"
        render={withMddGenericMenu(
          MddShowStudyQualifications,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.Qualifications
        )}
      />
      <SecureRoute
        exact={true}
        path="/study/:id/scores"
        render={withMddGenericMenu(
          MddShowStudyScores,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.AcceptableScores
        )}
      />
      <SecureRoute
        exact={true}
        path="/study/:id/scores/:courseId"
        render={withMddGenericMenu(
          MddShowStudyCourseScores,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.AcceptableScores
        )}
      />
      <SecureRoute
        exact={true}
        path="/study/:id/scores/:studyComponentId/legacy"
        render={withMddGenericMenu(
          MddShowStudyCourseScores,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.AcceptableScores
        )}
      />
      <SecureRoute
        exact={true}
        path="/study/:id/scores/:courseId/editscores/master"
        render={withMddGenericMenu(
          MddEditCourseMasterScores,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.AcceptableScores
        )}
      />
      <SecureRoute
        exact={true}
        path="/study/:id/scores/:studyComponentId/editscores/master/legacy"
        render={withMddGenericMenu(
          MddEditCourseMasterScores,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.AcceptableScores
        )}
      />
      <SecureRoute
        exact={true}
        path="/study/:id/scores/:courseId/editscores/country/:countryId"
        render={withMddGenericMenu(
          MddEditCourseCountryScores,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.AcceptableScores
        )}
      />
      <SecureRoute
        exact={true}
        path="/study/:id/scores/:studyComponentId/editscores/country/:countryId/legacy"
        render={withMddGenericMenu(
          MddEditCourseCountryScores,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.AcceptableScores
        )}
      />
      <SecureRoute
        path="/study/:id/resconfig/:resId/:section"
        render={props => MddResDraftWrapper(props)}
        exact={true}
      />
      <SecureRoute
        path="/study/:id/resconfig"
        render={withMddGenericMenu(
          MddResConfig,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.RESConfig
        )}
      />
      <SecureRoute
        path="/study/:id"
        render={withMddGenericMenu(
          BktAddEditStudy,
          new StudyMenuContainer(),
          true,
          studyMenuItemCategories.General
        )}
      />
      <SecureRoute path="/study" exact={true} component={BktShowStudies} />
      <SecureRoute path="/site" exact={true} component={BktShowSites} />
      <SecureRoute
        path="/site/new"
        render={withMddGenericMenu(
          BktAddEditSite,
          new SiteMenuContainer(),
          false,
          siteMenuItemCategories.General
        )}
      />
      <SecureRoute
        path="/site/:id/comment"
        render={withMddGenericMenu(
          BktShowSiteComments,
          new SiteMenuContainer(),
          true,
          siteMenuItemCategories.Comments
        )}
      />
      <SecureRoute
        path="/site/:id"
        render={withMddGenericMenu(
          BktAddEditSite,
          new SiteMenuContainer(),
          true,
          siteMenuItemCategories.General
        )}
      />
      <SecureRoute path="/scale" exact={true} component={BktShowScales} />
      <SecureRoute path="/scale/new" exact={true} component={BktShowScales} />
      <SecureRoute path="/scale/:id" exact={true} component={BktShowScales} />
      <SecureRoute
        path="/personnel"
        exact={true}
        component={BktShowPersonnel}
      />
      <SecureRoute
        path="/personnel/:id"
        exact={true}
        render={withMddGenericMenu(
          MddEditPersonnel,
          new PersonMenuContainer(),
          true,
          personMenuItemCategories.GeneralInfo
        )}
      />
    </Switch>
  </React.Fragment>
);

export default Routes;
