import * as React from "react";
import { isEmpty, isEqual, omit, pick } from "lodash";
import { eStudySaga, eLookupSaga } from "../../../sagas";
import { Button } from "@material-ui/core";
import History from "@material-ui/icons/History";
import { getDefaultValueForSelect, isLoading } from "../../../utils";
import {
  StudySite,
  StudySiteDTO,
  Validations,
  Person,
  ILookup,
  IMddStudyProps,
  IStudySite,
  IStudySitePiHistory,
  IStudyProtocol
} from "../../../model";
import {
  BktTypeaheadSelect,
  BktValidatedTextField,
  BktToolTip,
  BktSelect,
  BktDataTable,
  SagaService,
  IAction
} from "front-end-lib/core";
import { MAX_TEXT_LENGTH, PI_TEXT, activeStatuses } from "../../../constants";
import {
  BktBreadcrumb,
  BktSelectPersonnel,
  IBktDataTablePerson,
  withMddStudy,
  MddCheckbox,
  MddForm
} from "../../../components";
import { sortObjectArray, toDateFormat } from "../../../utils/functionsHelper";

const _MddAddEditStudySite = (props: IMddStudyProps) => {
  const { id, siteId } = props.match.params;
  const { study } = props;

  const [studySite, setStudySite] = React.useState<IStudySite>(
    new StudySite(id)
  );
  const [newStudySite, setNewStudySite] = React.useState<IStudySite>(
    Object.assign(new StudySite(id), {
      isActive: isEmpty(siteId),
      protocols: []
    })
  );
  const [sites, setSites] = React.useState<ILookup[]>([]);
  const sitesRef = React.useRef(sites);
  sitesRef.current = sites;
  const [sponsorSiteHasError, setSponsorSiteHasError] = React.useState(false);
  const [displayPIDialog, setDisplayPIDialog] = React.useState(false);
  const [studyProtocols, setStudyProtocols] = React.useState<IStudyProtocol[]>(
    []
  );
  const [siteName, setSiteName] = React.useState("");

  const newStudySiteRef = React.useRef(newStudySite);
  newStudySiteRef.current = newStudySite;

  React.useEffect(() => {
    const { id, siteId } = props.match.params;

    const removeFetchSitesSub = SagaService.subscribeToSaga(
      eLookupSaga.FETCH_SITES,
      (action: IAction) => {
        if (action.type === eLookupSaga.FETCH_SITES_SUCCESS) {
          const { payload: sites } = action;
          setSites(sites);
        }
      }
    );
    const removeStudySiteSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITE,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_STUDY_SITE_SUCCESS) {
          const newStudySite: IStudySite = Object.assign(
            new StudySite(),
            omit(action.payload, "primaryInvestigator"),
            {
              PrimaryInvestigator: Object.assign(
                new Person(),
                action.payload.primaryInvestigator
              )
            }
          );
          setStudySite(newStudySite);
          setNewStudySite(newStudySite);
        }
      }
    );
    const removeSaveStudySiteSub = SagaService.subscribeToSaga(
      eStudySaga.SAVE_STUDY_SITE,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_STUDY_SITE_SUCCESS) {
          const newStudySite: IStudySite = Object.assign(
            new StudySite(),
            omit(action.payload, "primaryInvestigator"),
            {
              PrimaryInvestigator: Object.assign(
                new Person(),
                action.payload.primaryInvestigator
              )
            }
          );
          setStudySite(newStudySite);
          setNewStudySite(newStudySite);
        }
      }
    );
    const removeStudyProtocolsSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_PROTOCOLS,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_STUDY_PROTOCOLS_SUCCESS) {
          const protocols = sortObjectArray(
            action.payload as IStudyProtocol[],
            "ProtocolName"
          );
          setStudyProtocols(protocols);
          if (!siteId) {
            // put all protocols into the study's protocol list to make them active for a new study site
            setNewStudySite(
              Object.assign(new StudySite(), newStudySiteRef.current, {
                Protocols: protocols.map((prot: IStudyProtocol) => {
                  return {
                    id: prot.ProtocolId,
                    protocolName: prot.ProtocolName
                  };
                })
              })
            );
          }
        }
      }
    );

    SagaService.dispatchSaga({ type: eLookupSaga.FETCH_SITES.toString() });
    if (id && siteId) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_SITE,
        payload: new StudySiteDTO(id, siteId, "", true)
      });
    }
    if (id) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_PROTOCOLS,
        payload: id
      });
    }

    return () => {
      removeFetchSitesSub();
      removeStudyProtocolsSub();
      removeStudySiteSub();
      removeSaveStudySiteSub();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // There is no guarantee as to what order the two sagas that load the study site and the sites list
    // will complete in during the mount effect so this effect is run on any change to one or the other
    // to set up the site name.
    if (sites.length) {
      const site = sites.find((x: ILookup) => x.value === newStudySite.SiteId);
      setSiteName(site ? site.label : "");
    }
  }, [sites, newStudySite]);

  const handleSiteTypeaheadChange = (selectedOption: ILookup) => {
    const { label, value } = selectedOption || {
      value: undefined,
      label: undefined
    };
    setNewStudySite(
      Object.assign(new StudySite(), newStudySite, {
        SiteId: value,
        SiteName: label
      })
    );
  };

  const getPIName = (site?: IStudySite) => {
    const currSite = site ? site : newStudySite;

    return currSite.PrimaryInvestigator
      ? `${currSite.PrimaryInvestigator.FirstName || "N/A"} ${currSite
          .PrimaryInvestigator.LastName || ""}`
      : "";
  };

  const disableSaveButton = () => {
    const { SiteId, SponsorSiteId, PrimaryInvestigator } = newStudySite;

    return (
      isLoading(
        `undefined_${eLookupSaga.FETCH_SITES}`,
        `undefined_${eStudySaga.FETCH_STUDY_SITE}`,
        `undefined_${eStudySaga.SAVE_STUDY_SITE}`,
        `undefined_${eStudySaga.FETCH_STUDY_PROTOCOLS}`
      ) ||
      !SiteId ||
      !SponsorSiteId ||
      sponsorSiteHasError ||
      !PrimaryInvestigator ||
      isEqual(newStudySite, studySite) ||
      !studyProtocols.length ||
      newStudySite.Protocols.length === 0
    );
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!disableSaveButton()) {
      SagaService.dispatchSaga({
        type: eStudySaga.SAVE_STUDY_SITE,
        payload: { newStudySite, existingStudySite: !!studySite.SiteId }
      });
    }
  };

  const renderPiHistory = () => {
    const { PrimaryInvestigatorHistory } = studySite;

    if (PrimaryInvestigatorHistory && PrimaryInvestigatorHistory.length) {
      const data = PrimaryInvestigatorHistory.map(
        (item: IStudySitePiHistory, i: number) => {
          return Object.assign(pick(item, ["name", "startDate", "endDate"]), {
            key: `item.id${i}`
          });
        }
      );

      return (
        <BktDataTable
          data={data}
          columnProps={[
            {
              label: "Principal Investigator",
              tableCellProps: {
                id: "piHistoryNameHeader"
              },
              divProps: { id: "piHistoryNameHeader_div" }
            },
            {
              label: "Start",
              tableCellProps: { id: "piHistoryStartHeader" },
              divProps: { id: "piHistoryStartHeader_div" },
              formatProp: {
                formatPropName: "startDate",
                format: (val: string) => toDateFormat(val)
              }
            },
            {
              label: "End",
              tableCellProps: {
                id: "piHistoryEndHeader"
              },
              divProps: { id: "piHistoryEndHeader_div" },
              formatProp: {
                formatPropName: "endDate",
                format: (val: string) => toDateFormat(val)
              }
            }
          ]}
          containerProps={{ id: "tableWrapper" }}
          tableProps={{
            id: "tableContainer",
            className: "mdd-study-site-tbl-history"
          }}
          tableHeadProps={{
            id: "tableHeader",
            rowProps: {
              id: "tableHeaderRow",
              className: "mdd-study-site-tbl-history--header-row"
            }
          }}
          tableBodyProps={{
            id: "tableBody",
            rowProps: {
              id: "tableBodyRow",
              className: "mdd-study-site-tbl-history--body-row"
            },
            cellProps: {
              className: "mdd-study-site-tbl-history--body-cell"
            }
          }}
        />
      );
    }

    return undefined;
  };

  const handleProtocolChange = (val: string, prot: IStudyProtocol) => {
    if (val === "true") {
      // Made a protocol active so push it onto the list.
      // However, we need to check to see if it's already in the list.  They can open
      // the dropdown and select Active even if already active - we don't
      // want to add it twice.
      if (
        newStudySiteRef.current.Protocols &&
        newStudySiteRef.current.Protocols.some(
          siteProtocol => prot.ProtocolId === siteProtocol.id
        )
      ) {
        // already there, nothing to do
        return;
      }
      // add it to the list
      const newProtocols = [...newStudySiteRef.current.Protocols];
      newProtocols.push({
        id: prot.ProtocolId,
        protocolName: prot.ProtocolName
      });

      setNewStudySite(
        // sort the protocols by name so we maintain the same original order,
        // otherwise the save button enable/disable logic may not work.
        Object.assign(new StudySite(), newStudySiteRef.current, {
          Protocols: sortObjectArray(newProtocols, "protocolName")
        })
      );
    } else {
      const newProtocols = [...newStudySiteRef.current.Protocols].filter(
        siteProtocol => {
          return siteProtocol.id !== prot.ProtocolId;
        }
      );
      setNewStudySite(
        Object.assign(new StudySite(), newStudySiteRef.current, {
          Protocols: newProtocols
        })
      );
    }
  };

  return (
    <div className="mdd-form--fullheight">
      <BktBreadcrumb
        rootText="All Studies"
        rootUrl="/study"
        currentLocation={study.StudyId ? study.StudyName : ""}
        currentNestedLocation={
          studySite.SiteId ? `${siteName} Association` : "Add New Association"
        }
        currentNestedLocationUrl={`/study/${study.StudyId}/sites`}
      />
      <MddForm
        containerClass="mdd-study-site--form-container"
        keys={[
          `_${eLookupSaga.FETCH_SITES}`,
          `undefined_${eStudySaga.FETCH_STUDY_SITE}`,
          `undefined_${eStudySaga.SAVE_STUDY_SITE}`,
          `undefined_${eStudySaga.FETCH_STUDY_PROTOCOLS}`
        ]}
      >
        <div className="mdd-form--body">
          {/* flex container for the two main side by side divs */}
          <div className="mdd-study-site-div--flex-container">
            {/* 1st of the main side by side divs */}
            <div
              className="mdd-study-site-div--flex-child-left"
              data-testid="leftPane"
            >
              {/* 1st column in the left pane */}
              <div className="mdd-study-site-div--flex-child-left--column1">
                <div className="mdd-study-site-div--header" id="divGeneral">
                  General
                </div>
                <div className="mdd-study-site-div--label" id="lblSite">
                  {`Site ${!siteId ? "*" : ""}`}
                </div>
                {siteId && (
                  <div className="mdd-study-site-div--text" id="txtSiteName">
                    <span data-testid="txtSiteName">{siteName}</span>
                  </div>
                )}
                {!siteId && (
                  <BktTypeaheadSelect
                    selectData={sites}
                    textProps={{
                      validationForOnBlur: [Validations.required],
                      textFieldProps: {
                        textProps: {
                          id: "siteDropdown",
                          placeholder: "Select a site",
                          className: "tbl--filter-select",
                          InputLabelProps: {
                            shrink: newStudySite.SiteId !== undefined
                          },
                          disabled: sites.length === 0, //sitesData.length === 0, // || !!siteId,
                          value: getDefaultValueForSelect(
                            sites,
                            newStudySite.SiteId
                          )
                        }
                      }
                    }}
                    itemProps={{ name: "siteId" }}
                    onSubmit={handleSiteTypeaheadChange}
                  />
                )}
                <div
                  className="mdd-study-site-div--sponsor-label"
                  id="lblSponsorSiteId"
                >
                  Sponsor Site ID *
                </div>
                <BktValidatedTextField
                  validationForOnBlur={[
                    Validations.required,
                    Validations.sponsorSiteAllowedCharacters
                  ]}
                  validationForOnChange={[
                    Validations.sponsorSiteAllowedCharacters
                  ]}
                  validationerror={(validationError: boolean) =>
                    setSponsorSiteHasError(validationError)
                  }
                  textFieldProps={{
                    textProps: {
                      id: "sponsorSiteId",
                      className: "mdd-personnel-div--editable-value",
                      placeholder: "Sponsor Site ID",
                      inputProps: {
                        id: "sponsorSiteIdTextField",
                        maxLength: MAX_TEXT_LENGTH.SPONSOR_SITE_ID
                      },
                      variant: "outlined",
                      required: true,
                      type: "text",
                      onChange: (event: any) =>
                        setNewStudySite(
                          Object.assign(new StudySite(), newStudySite, {
                            SponsorSiteId: event.target.value
                          })
                        ),
                      value: newStudySite.SponsorSiteId || ""
                    }
                  }}
                />
                <MddCheckbox
                  lblText="Active"
                  chkBoxProps={{
                    id: "chkStudySiteActive",
                    checked: newStudySite.IsActive,
                    value: "chkStudySiteActive",
                    disabled: !isEmpty(siteId) && !newStudySite.SiteId,
                    onChange: (e: any) =>
                      setNewStudySite(
                        Object.assign(new StudySite(), newStudySite, {
                          isActive: !newStudySite.IsActive
                        })
                      )
                  }}
                />
              </div>
              {/* 2nd column in the left pane */}
              <div
                className="mdd-study-site-div--flex-child-left--column2"
                data-testid="leftColumn"
              >
                <div
                  className="mdd-study-site-div--label--with-padding"
                  id="lblPI"
                >
                  {`${PI_TEXT} *`}
                  <BktToolTip
                    containerProps={{
                      style: {
                        visibility:
                          studySite.PrimaryInvestigatorHistory &&
                          studySite.PrimaryInvestigatorHistory.length
                            ? "visible"
                            : "hidden"
                      }
                    }}
                    tooltip={renderPiHistory()}
                    position="bottom-start"
                  >
                    <History color={"action"} />
                  </BktToolTip>
                </div>
                <div className="mdd-study-site-div--text" id="txtSitePIName">
                  <span data-testid="txtSitePIName">{getPIName()}</span>
                  {!newStudySite.PrimaryInvestigator && <span>N/A</span>}
                </div>
                <span className="mdd-study-site--search-button--padding">
                  <Button
                    className="mdd-study-site--search-button"
                    size="small"
                    id="btnDisplayPIDialog"
                    color="primary"
                    disableFocusRipple={true}
                    disableRipple={true}
                    variant="contained"
                    onClick={() => setDisplayPIDialog(true)}
                  >
                    Search
                  </Button>
                </span>
              </div>
            </div>
            {/* 2nd of the main side by side divs */}
            <div
              className="mdd-study-site-div--flex-child-right"
              data-testid="rightPane"
            >
              <div className="mdd-study-site-div--header" id="divProtocols">
                Study Protocol Participation
              </div>
              <div className="mdd-study-site--protocols--container">
                {!studyProtocols.length &&
                  !isLoading(
                    `undefined_${eStudySaga.FETCH_STUDY_PROTOCOLS}`
                  ) && (
                    <div className="mdd-study-site--protocols--none">
                      {`No protocols exist for this study, you must add a protocol in order to ${
                        siteId ? "edit" : "add"
                      } a site`}
                    </div>
                  )}
                {isLoading(`undefined_${eStudySaga.FETCH_STUDY_PROTOCOLS}`) && (
                  <div>Loading...</div>
                )}
                {studyProtocols.map(prot => {
                  return (
                    <React.Fragment key={prot.ProtocolId}>
                      <div className="mdd-study-site--protocols--label">
                        <span data-testid="protocolName">
                          {prot.ProtocolName}
                        </span>
                      </div>
                      <div id="protocolActive" data-testid="protocolActive">
                        <BktSelect
                          menuProps={{}}
                          textProps={{
                            id: "studyProtocol",
                            label: "",
                            value:
                              newStudySite && newStudySite.Protocols
                                ? newStudySite.Protocols.some(
                                    siteProtocol =>
                                      prot.ProtocolId === siteProtocol.id
                                  )
                                : false,
                            className: "mdd-study-site--select",
                            onChange: event =>
                              handleProtocolChange(event.target.value, prot)
                          }}
                          selectData={activeStatuses}
                          selectMenuProps={{
                            className: "mdd-study-site--select--div"
                          }}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
                {studyProtocols.length > 0 &&
                  !newStudySite.Protocols.length &&
                  !isLoading(`undefined_${eStudySaga.FETCH_STUDY_PROTOCOLS}`) &&
                  !isLoading(`undefined_${eStudySaga.FETCH_STUDY_SITE}`) && (
                    <div
                      className="mdd-study-site--inactive-protocols"
                      id="divInactiveProtocolWarning"
                      data-testid="divInactiveProtocolWarning"
                    >
                      At least one protocol must be active
                    </div>
                  )}
              </div>
            </div>
            {/* end flex container */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              textAlign: "right"
            }}
          >
            <div style={{ flexGrow: 1 }}></div>
            <div style={{ flexBasis: "320px" }}>
              <Button
                className="mdd-study-site--save-button"
                id="saveButtonId"
                color="primary"
                disableFocusRipple={true}
                disableRipple={true}
                variant="contained"
                onClick={handleSubmit}
                disabled={disableSaveButton()}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </MddForm>
      <BktSelectPersonnel
        displayDialog={displayPIDialog}
        person={newStudySite.PrimaryInvestigator}
        onCancel={() => setDisplayPIDialog(false)}
        onSelect={(personnel?: IBktDataTablePerson) => {
          setDisplayPIDialog(false);
          setNewStudySite(
            Object.assign(new StudySite(), newStudySite, {
              PrimaryInvestigator: personnel
            })
          );
        }}
      />
    </div>
  );
};

export const MddAddEditStudySite = withMddStudy()(_MddAddEditStudySite);
