import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { SagaService, IAction } from "front-end-lib/core";
import { eCommentSaga } from "../../../sagas";
import { isLoading } from "../../../utils";
import { MddDialogButtons } from "../../common";
import { MAX_TEXT_LENGTH } from "../../../constants";
import { sanitizeData } from "../../../utils";

interface IMddCommentAreaProps {
  maxRows?: number;
  rows?: number;
  minLength?: number;
  maxLength?: number;
  placeholderText?: string;
  initialValue?: string;
  saveButtonText?: string;
  clearButtonText?: string;
  showClearButton?: boolean;
  onSave?: (val: string) => void | undefined;
  onCancel?: () => void | undefined;
  saveUrl: string;
}

export const MddCommentArea = (props: IMddCommentAreaProps) => {
  const {
    maxRows = 4,
    rows = 4,
    minLength = 1,
    maxLength = MAX_TEXT_LENGTH.COMMENT,
    placeholderText = "Enter Comment",
    initialValue = "",
    saveButtonText = "Save",
    clearButtonText = "Clear",
    showClearButton = false,
    onSave = undefined,
    onCancel = undefined,
    saveUrl
  } = props;

  React.useEffect(() => {
    const removeSaveComment = SagaService.subscribeToSaga(
      eCommentSaga.SAVE_COMMENT,
      (action: IAction) => {
        if (action.type === eCommentSaga.SAVE_COMMENT_SUCCESS) {
          saveCommentCallback();
        }
      }
    );

    return () => removeSaveComment();
    // eslint-disable-next-line
  }, []);

  const [commentValue, setCommentValue] = React.useState(initialValue || "");

  const saveCommentCallback = () => {
    if (onSave) {
      onSave(commentValue);
    }
    setCommentValue("");
  };

  const handleSaveComment = () => {
    setCommentValue(commentValue.trim());

    SagaService.dispatchSaga({
      type: eCommentSaga.SAVE_COMMENT.toString(),
      payload: { comment: commentValue.trim(), url: saveUrl }
    });
  };

  const disableSaveButton = () =>
    commentValue.trim().length < minLength ||
    commentValue === initialValue ||
    isLoading(`undefined_${eCommentSaga.SAVE_COMMENT}`);

  return (
    <React.Fragment>
      <div>
        <TextField
          id="txtComment"
          data-testid="txtComment"
          label={placeholderText}
          multiline
          fullWidth
          inputProps={{ maxLength }}
          rows={rows}
          rowsMax={maxRows}
          value={commentValue}
          autoFocus={true}
          onChange={event => {
            setCommentValue(sanitizeData(event.target.value));
          }}
          variant="outlined"
        />
      </div>
      <MddDialogButtons
        saveButtonText={saveButtonText}
        closeButtonText={clearButtonText}
        saveButtonProps={{
          id: "btnSaveComment",
          disabled: disableSaveButton(),
          onClick: handleSaveComment
        }}
        closeButtonProps={{
          id: "btnClearComment",
          className: showClearButton ? "" : "dialog-button-hidden",
          onClick: () => {
            setCommentValue("");
            if (onCancel) {
              onCancel();
            }
          }
        }}
      />
    </React.Fragment>
  );
};
