import * as React from "react";
import { withMddStudy } from "../../../higher-order";
import { isLoading } from "../../../../utils";
import { eStudySaga } from "../../../../sagas";
import { SagaService, IAction } from "front-end-lib/core";
import {
  IMddStudyProps,
  IMatch,
  ICourseAcceptableScore
} from "../../../../model";
import { MddEditCourseScoresRender, MddProgress } from "../../../../components";

interface IMddEditCourseMasterScoresProps extends IMddStudyProps {
  match: IMatch<{
    id: string;
    sponsorId: string;
    siteId: string;
    courseId: string;
    studyComponentId: string;
  }>;
  location: {
    state?: {
      courseName: string;
      isLocked: boolean;
      countryAcceptableScoreId?: string;
    };
  };
}

const _MddEditCourseMasterScores = (props: IMddEditCourseMasterScoresProps) => {
  const { study, location } = props;
  const { id, courseId, studyComponentId } = props.match.params;

  const [courseName, setCourseName] = React.useState("");

  // Modes which determine how the screen is displayed.
  // ViewMode overrides addEditMode and displays as read-only. This is based
  // on the scores being locked.
  // AddEditMode is based on if answers have been previously provided.  If so,
  // we are in edit mode and it'll display the existing answers and allow for updates.
  // If add mode it allows for initial entry.
  const [isViewMode, setIsViewMode] = React.useState<boolean>(false);
  const [addEditMode, setAddEditMode] = React.useState<"add" | "edit">("edit");
  const [questions, setQuestions] = React.useState<ICourseAcceptableScore[]>(
    []
  );

  React.useEffect(() => {
    const processQuestions = (questions: ICourseAcceptableScore[]) => {
      // Set add/edit mode based on if questions are already answered.  We can assume
      // that if the first question has an answer they all do and we are in edit mode.
      // Note that the view mode setting will override this if set to true.
      setAddEditMode(
        questions &&
          questions.length > 0 &&
          questions[0].acceptableResponse !== null
          ? "edit"
          : "add"
      );

      setQuestions(questions);
    };

    const removeFetchMasterQuestions = SagaService.subscribeToSaga(
      eStudySaga.FETCH_COURSE_MASTER_QUESTIONS,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_SUCCESS) {
          processQuestions(action.payload);
        }
      }
    );
    const removeFetchMasterQuestionsLegacy = SagaService.subscribeToSaga(
      eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY_SUCCESS
        ) {
          processQuestions(action.payload);
        }
      }
    );

    const removeFetchScoringCourseDetails = SagaService.subscribeToSaga(
      eStudySaga.FETCH_SCORING_COURSE_DETAILS,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_SCORING_COURSE_DETAILS_SUCCESS) {
          setCourseName(action.payload.courseName);
          setIsViewMode(action.payload.masterAcceptableScore.isLocked);
        }
      }
    );
    const removeFetchScoringCourseDetailsLegacy = SagaService.subscribeToSaga(
      eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY,
      (action: IAction) => {
        if (
          action.type === eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY_SUCCESS
        ) {
          setCourseName(action.payload.courseName);
          setIsViewMode(true);
        }
      }
    );

    if (courseId) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_COURSE_MASTER_QUESTIONS,
        payload: courseId
      });
    } else {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY,
        payload: studyComponentId
      });
    }

    // If we have state passed in (they came from the main scores page) then use what we know to
    // determine what we still need to load.
    if (location.state) {
      setCourseName(location.state.courseName);
      // View mode is true for master scores if the master's are locked.
      setIsViewMode(location.state.isLocked);
    } else {
      // No state passed in, always need to fetch the scoring details.  This will just
      // value the courseName and isLocked
      if (courseId) {
        SagaService.dispatchSaga({
          type: eStudySaga.FETCH_SCORING_COURSE_DETAILS,
          payload: { studyId: id, courseId }
        });
      } else {
        SagaService.dispatchSaga({
          type: eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY,
          payload: { studyId: id, studyComponentId }
        });
      }
    }

    return () => {
      removeFetchMasterQuestions();
      removeFetchMasterQuestionsLegacy();
      removeFetchScoringCourseDetails();
      removeFetchScoringCourseDetailsLegacy();
    };
    // eslint-disable-next-line
  }, []);

  const showSpinner = () => {
    return isLoading(
      `undefined_${eStudySaga.FETCH_COURSE_MASTER_QUESTIONS}`,
      `undefined_${eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY}`,
      `undefined_${eStudySaga.SAVE_COURSE_MASTER_QUESTIONS}`,
      `undefined_${eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS}`,
      `undefined_${eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY}`,
      `undefined_${eStudySaga.SAVE_COURSE_COUNTRY_QUESTIONS}`,
      `undefined_${eStudySaga.FETCH_SCORING_COURSE_DETAILS}`,
      `undefined_${eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY}`,
      `undefined_${eStudySaga.FETCH_COURSE_MASTER_QUESTIONS}`
    );
  };

  return (
    <React.Fragment>
      <MddProgress inProgress={showSpinner()} spinnerId="svgLoading" />
      <MddEditCourseScoresRender
        studyId={study.StudyId}
        studyName={study.StudyName}
        courseId={courseId}
        studyComponentId={studyComponentId}
        courseName={courseName}
        mode={isViewMode ? "view" : addEditMode}
        isMasterScores={true}
        questions={questions}
      />
    </React.Fragment>
  );
};

export const MddEditCourseMasterScores = withMddStudy()(
  _MddEditCourseMasterScores
);
