import { Button } from "@material-ui/core";
import { FelPanelContainer, SagaService } from "front-end-lib";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { IMddStudyProps, IStudy } from "../../../model";
import { IApplicationState } from "../../../store";
import { BktBreadcrumb, withMddStudy, MddProgress } from "../../../components";
import { history, isLoading, useCallbackPrompt } from "../../../utils";
import { eResConfig } from "../../../store/resConfig/types";
import "../../../sagas/resConfigSaga/resConfigSaga";
import { MddResDraftClose } from "./MddResDraftClose";
import { getKeys } from "../../../utils/enum";

export enum RES_SECTIONS {
  "demographics" = 0,
  "education" = 1,
  "experienceGeneral" = 2,
  "experienceIndication" = 3,
  "experienceScales" = 4
}

interface IMddResDraftProps
  extends Omit<IMddStudyProps, "match">,
    RouteComponentProps<any> {
  content: React.ReactNode;
  version: string;
  hasChanges: boolean;
}

const _MddResDraft = ({
  study: { StudyName: studyName } = {} as IStudy,
  match: {
    params: { section, id, resId }
  },
  content,
  version,
  hasChanges
}: IMddResDraftProps) => {
  const cleanup = useCallback(() => {
    SagaService.dispatchSaga({
      type: eResConfig.RESET,
      payload: { studyId: id }
    });
  }, [id]);

  const when = useCallback(
    (nextLoc: any) =>
      hasChanges &&
      (!nextLoc ||
        !getKeys(RES_SECTIONS)
          .map(m => `/study/${id}/resconfig/${resId}/${m}`)
          .includes(nextLoc.pathname)),
    [hasChanges, resId, id]
  );

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    when,
    cleanup
  );

  useEffect(() => {
    SagaService.dispatchSaga({
      type: eResConfig.GET_RES_CONFIG,
      payload: { studyId: id, resId }
    });

    return cleanup;
  }, [cleanup, id, resId]);

  const handleExit = (confirmed?: boolean) => {
    if (confirmed) {
      confirmNavigation();
    } else {
      cancelNavigation();
    }
  };

  const handleClick = (isNext: boolean) => {
    const newSection = isNext
      ? RES_SECTIONS[section] + 1
      : +RES_SECTIONS[section] - 1;

    history.push(`/study/${id}/resconfig/${resId}/${RES_SECTIONS[newSection]}`);
  };

  const handleSave = () => {
    SagaService.dispatchSaga({
      type: eResConfig.SAVE_RES_CONFIG
    });
  };

  const inProgress = isLoading(
    `_${eResConfig.SAVE_RES_CONFIG}`,
    `undefined_${eResConfig.GET_RES_CONFIG}`
  );
  return (
    <React.Fragment>
      <MddProgress inProgress={inProgress} spinnerId="saveRes" />
      <BktBreadcrumb
        rootText="All Studies"
        rootUrl="/study"
        currentLocation={studyName}
        currentNestedLocationUrl={`/study/${id}`}
        currentNestedLocation="Configure Rater Experience Survey"
      />
      {version !== "0.0" && (
        <div className="mdd-res-draft">
          <div className="title">
            <h2>Configure Rater Experience Survey</h2>
            <div>Version {version}</div>
          </div>
          <FelPanelContainer
            className="mdd-res-draft-panel"
            panels={[{ size: "full", content }]}
          />
          <div className="nav-buttons">
            <div>
              {section !== RES_SECTIONS[RES_SECTIONS.demographics] && (
                <Button
                  data-testid="mdd-res-draft-button-previous"
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClick(false)}
                >
                  Previous
                </Button>
              )}
            </div>
            <div className="actions">
              <Button
                data-testid="mdd-res-draft-button-close"
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push(`/study/${id}/resconfig`);
                }}
              >
                Close
              </Button>
              <Button
                data-testid="mdd-res-draft-button-save"
                size="small"
                variant="contained"
                disabled={!hasChanges}
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
            <div>
              {section !== RES_SECTIONS[RES_SECTIONS.experienceScales] && (
                <Button
                  data-testid="mdd-res-draft-button-next"
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClick(true)}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
          <MddResDraftClose open={showPrompt} onClose={handleExit} />
        </div>
      )}
    </React.Fragment>
  );
};

export const MddResDraft = withMddStudy()(
  connect(({ resConfig }: IApplicationState) => ({
    version: resConfig.version,
    hasChanges:
      resConfig.demographics?.edit.length > 0 ||
      resConfig.indications?.edit.length > 0 ||
      resConfig.scales?.edit.length > 0
  }))(_MddResDraft)
);
