import React from "react";
import { toDateFormatNoOffset } from "../../../utils";

interface IProps {
  assignmentDate?: string | null;
  completedDate?: string | null;
}

const MddResAssignmentStatus = ({ assignmentDate, completedDate }: IProps) => {
  if (!assignmentDate && !completedDate) {
    return (
      <div className="status" id="statusDiv" data-testid="statusDiv"></div>
    );
  }

  const reassigned =
    assignmentDate &&
    completedDate &&
    new Date(assignmentDate).valueOf() > new Date(completedDate).valueOf();

  return (
    <div className="status" id="statusDiv" data-testid="statusDiv">
      {!completedDate && assignmentDate && (
        <span id="statusAssignedDiv" data-testid="statusAssignedDiv">
          Study RES Assigned to Rater: {toDateFormatNoOffset(assignmentDate)}
        </span>
      )}
      {completedDate && (
        <span id="statusUpdatedDiv" data-testid="statusUpdatedDiv">
          Study RES Last Updated by Rater: {toDateFormatNoOffset(completedDate)}
        </span>
      )}
      {reassigned && (
        <span id="statusReassignedDiv" data-testid="statusReassignedDiv">
          Study RES Reassigned to Rater: {toDateFormatNoOffset(assignmentDate!)}
        </span>
      )}
    </div>
  );
};

export default MddResAssignmentStatus;
