import { useEffect, useState, useCallback } from "react";
import { runSagaAsync } from "./sagaHelper";
import { useHistory, useLocation } from "react-router-dom";

interface ILoadProps {
  startType: string;
  successType: string;
  payload?: any;
}

export function useSaga<T>(
  props: ILoadProps
): { data: T | null; loading: boolean; reload: () => void } {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const items = await runSagaAsync<T>(props);
    setData(items);
    setLoading(false);
  };

  useEffect(() => {
    (async () => await fetchData())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, reload: fetchData };
}

export function useBlocker(
  handleBlockedNavigation: (location: any) => boolean,
  when: (nextLocation: any, currentLocation: any) => boolean,
  onUnload?: () => void
) {
  const history = useHistory();
  const location = history.location;

  useEffect(() => {
    const unblock = history.block(
      (nextLocation: any) =>
        !when(nextLocation, location) || handleBlockedNavigation(nextLocation)
    );

    return () => {
      unblock();
    };
  }, [when, handleBlockedNavigation, location, history]);

  useEffect(() => {
    const unloadHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      if (when(null, location)) {
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", unloadHandler);
    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [when, location]);

  useEffect(() => {
    if (onUnload) {
      window.addEventListener("unload", onUnload);
    }
    return () => {
      if (onUnload) {
        window.removeEventListener("unload", onUnload);
      }
    };
  }, [onUnload]);
}

export function useCallbackPrompt(
  when: (nextLocation: any, currentLocation: any) => boolean,
  onUnload?: () => void
): [boolean, () => void, () => void] {
  const history = useHistory();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    nextLocation => {
      // in if condition we are checking next location and current location are equals or not
      if (!confirmedNavigation && nextLocation.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, history, lastLocation]);

  useBlocker(handleBlockedNavigation, when, onUnload);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
