import * as React from "react";
import { connect } from "react-redux";
import { deleteSHCookie, history } from "../../../utils";
import { menuTabs } from "../../../constants";
import { ConfigOptions } from "../../../config/configOptions";
import { IUser, IUserService } from "../../../model";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Person from "@material-ui/icons/Person";
import Settings from "@material-ui/icons/Settings";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";

import headerLogo from "../../../assets/images/endsight-logo-color.png";
import { IApplicationState } from "../../../store";

export interface IHeaderProps {
  user: IUser;
  inProgressCallback: (inProgress: boolean) => void;
  userService: IUserService;
}

export const BktHeaderComponent: React.SFC<IHeaderProps> = (
  props: IHeaderProps
) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pathName, setPathName] = React.useState(history.location.pathname);
  const isMenuOpen = Boolean(anchorEl);
  const { user, userService } = props;

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const onTabClick = (path: string) => {
    setPathName(path);
    history.push(path);
  };

  const handleSignout = async () => {
    const { inProgressCallback } = props;
    setAnchorEl(null); // close the menu
    inProgressCallback(true);
    try {
      deleteSHCookie();
      userService.signOut();
    } catch (e) {
      console.log(e);
      window.location.href = ConfigOptions.oktaRedirectUri!;
    } finally {
      inProgressCallback(false);
    }
  };

  return (
    <div className="mdd-header">
      <AppBar
        id="appBarContainer"
        position="static"
        elevation={0}
        className="mdd-header--appbar"
      >
        <Toolbar style={{ minHeight: "0px" }}>
          <div
            id="appBarContentsWrapper"
            className={"mdd-header--appbar-contents"}
          >
            <div>
              <img
                id="appBarLogo"
                data-testid="appBarLogo"
                src={headerLogo}
                alt="headerLogo"
                className={"mdd-header--appbar-logo"}
              />
            </div>
            <Tabs
              variant="fullWidth"
              value={pathName}
              className="mdd-header--tabs"
              classes={{
                scroller: "mdd-header--tabs-scroller",
                indicator: "mdd-header--tabs-indicator"
              }}
            >
              {menuTabs.map(tab => {
                return (
                  <Tab
                    id={tab.id}
                    data-testid={tab.id}
                    key={tab.id}
                    classes={{ root: "mdd-header--tabs-container" }}
                    label={tab.label}
                    value={
                      (tab.default && pathName === "/") ||
                      pathName.startsWith(`/${tab.url}`)
                        ? pathName
                        : `/${tab.url}`
                    }
                    onClick={() => onTabClick(`/${tab.url}`)}
                  />
                );
              })}
            </Tabs>
            <div className={"mdd-header--appbar-icons"}>
              {user && user.FirstName ? (
                <label className="mdd-header--user">
                  Welcome, {user.FirstName}{" "}
                </label>
              ) : (
                undefined
              )}
              <IconButton id="settingsButton" data-testid="settingsButton">
                <Settings />
              </IconButton>
              <IconButton
                id="accountButton"
                data-testid="accountButton"
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
              >
                <Person />
              </IconButton>
              <Menu
                id="accountButtonMenu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                getContentAnchorEl={undefined}
                open={isMenuOpen}
                onClose={handleProfileMenuClose}
              >
                <MenuItem id="menuItemSignOut" onClick={handleSignout}>
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export const BktHeader = connect((state: IApplicationState) => ({
  user: state.user,
  httpService: state.util.mddHTTPService
}))(BktHeaderComponent);
