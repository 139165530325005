import * as React from "react";
import { DialogContent, DialogContentText } from "@material-ui/core";
import { MddDialog, MddDialogButtons } from "../../../components/common";

interface MddResConfigPublishProps {
  open: boolean;
  onClose: (isSave?: boolean) => void;
}

export const MddResConfigPublish = ({
  open,
  onClose
}: MddResConfigPublishProps) => {
  return (
    <MddDialog
      dialogProps={{
        open: open,
        maxWidth: "xs"
      }}
      dialogContentProps={{ className: "mdd-res-draft--dialog" }}
      dialogTitleProps={{ title: "Publish" }}
      dialogContent={
        <DialogContent className="mdd-res-draft--dialog-content">
          <DialogContentText>
            You have selected to publish The Rater Experience Survey (RES). By
            selecting publish, the RES will be live in Clinical Profile.
          </DialogContentText>
        </DialogContent>
      }
      onCloseClick={() => onClose()}
      dialogActions={
        <MddDialogButtons
          saveButtonText="Publish"
          saveButtonProps={{ onClick: () => onClose(true) }}
          closeButtonProps={{ onClick: () => onClose() }}
        />
      }
      showCloseButton
    />
  );
};
