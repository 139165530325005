import { Reducer } from "redux";
import { eResConfig, IState } from "./types";

const defaultState: any = { version: "0.0" };

export const reducer: Reducer<IState> = (state = defaultState, action) => {
  switch (action.type) {
    case eResConfig.GET_RES_CONFIG_SUCCESS:
      // We can't just assume this loads everything and allow it to overwrite what was in state.  For the view archived/published we
      // may have loaded the indications, demographics and scales master lists already and doing so would overwrite those.  So make sure those
      // are maintained as we update state.
      return {
        ...state,
        ...action.payload,
        demographics: {
          ...action.payload.demographics,
          list: state.demographics?.list || []
        },
        indications: {
          ...action.payload.indications,
          list: state.indications?.list || []
        },
        scales: {
          ...action.payload.scales,
          list: state.scales?.list || []
        }
      };
    case eResConfig.GET_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        demographics: { ...state.demographics, list: action.payload }
      };
    case eResConfig.GET_INDICATIONS_SUCCESS:
      return {
        ...state,
        indications: { ...state.indications, list: action.payload }
      };
    case eResConfig.GET_SCALES_SUCCESS:
      return {
        ...state,
        scales: { ...state.scales, list: action.payload }
      };
    case eResConfig.EDIT_ITEMS:
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          edit: action.payload.edits
        }
      };
    case eResConfig.RESET:
      return defaultState;
    default:
      return state;
  }
};
