import * as React from "react";
import {
  BktSaga,
  BktDataTable,
  IBktDataTableData,
  SagaService,
  getKeysFromState,
  BktValidatedTextField,
  BktTypeaheadSelect,
  IAction
} from "front-end-lib/core";
import { map, pick, trim, isEqual, isEmpty } from "lodash";
import { Paper, Button, Typography } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import { MddDialog, MddDialogButtons } from "../../../components";
import { scaleDataTableHeaders, scaleDataTableProps } from "../../../config";
import {
  Scale,
  Validations,
  ScaleDTO,
  MasterScale,
  IScale,
  IMasterScale,
  IMatch,
  ILookupNameValue
} from "../../../model";
import { eScaleSaga, eUtilSaga } from "../../../sagas";
import {
  history,
  cloneAssign,
  getDefaultValueForSelect,
  isLoading
} from "../../../utils";

interface IBktScaleProps {
  match: IMatch<{
    id: string;
  }>;
}
export interface IBktDataTableScaleData extends IScale {
  key: string;
}

export interface IBktShowScalesState {
  scales?: IBktDataTableScaleData[] | undefined;
  masterScales: IMasterScale[] | undefined;
  openDialog: boolean;
  scale: Scale;
  newScale: Scale;
  scaleNameHasError: boolean;
  scaleShortNameHasError: boolean;
  loading: boolean;
}

export class BktShowScales extends BktSaga<
  IBktScaleProps,
  IBktShowScalesState,
  {}
> {
  private filterVals = new Map();
  private mode: "Add" | "Edit" | undefined;
  private onLoading: true | false = false;
  get searchScale() {
    const searchScale = {};
    this.filterVals.forEach((value, key) => {
      Object.assign(searchScale, { [key]: value });
    });
    return searchScale;
  }
  constructor(props: IBktScaleProps) {
    super(props);
    this.state = {
      scales: undefined,
      openDialog: false,
      scale: new Scale(),
      newScale: new Scale(),
      scaleNameHasError: false,
      scaleShortNameHasError: false,
      masterScales: [],
      loading: false
    };
  }

  public componentDidMount() {
    const setScaleAfterSave = (action: IAction) => {
      const { type, payload } = action;
      const { newScale } = this.state;
      if (type === eScaleSaga.MODIFY_SCALE_SUCCESS) {
        this.setState(
          payload instanceof Scale
            ? { scale: payload, newScale: payload, openDialog: false }
            : { scale: newScale, newScale, openDialog: true },
          () => {
            if (this.mode === "Edit") {
              SagaService.dispatchSaga({
                type: eScaleSaga.FETCH_SCALES,
                payload: this.searchScale
              });
            }
          }
        );
      }
    };
    this.configureSaga(
      new Map([
        [
          eScaleSaga.FETCH_SCALES.toString(),
          {
            stateKey: getKeysFromState(this.state, ["scales"]),
            validationType: eScaleSaga.FETCH_SCALES_SUCCESS
          }
        ],
        [
          eScaleSaga.FETCH_SCALE.toString(),
          { stateKey: getKeysFromState(this.state, ["scale", "newScale"]) }
        ],
        [
          eScaleSaga.FETCH_MASTER_SCALES.toString(),
          { stateKey: getKeysFromState(this.state, ["masterScales"]) }
        ],
        [eScaleSaga.MODIFY_SCALE.toString(), { callback: setScaleAfterSave }],
        [
          eUtilSaga.LOADING,
          { stateKey: getKeysFromState(this.state, ["loading"]) }
        ]
      ]),
      []
    );
    SagaService.dispatchSaga({ type: eScaleSaga.FETCH_MASTER_SCALES });
  }

  public handleChangeFilter = async (event: any) => {
    const { name } = event.target;
    const value = event.target.value ? trim(event.target.value) : "";
    if (this.filterVals.has(name) && this.filterVals.get(name) === value) {
      return;
    }
    value ? this.filterVals.set(name, value) : this.filterVals.delete(name);

    if (this.filterVals.size < 1) {
      this.setState({ scales: undefined });
      return;
    }

    SagaService.dispatchSaga({
      type: eScaleSaga.FETCH_SCALES,
      payload: this.searchScale
    });
  };

  public render() {
    const { newScale, loading } = this.state;
    const dataProps = ["Name", "ShortName"];
    let { scales } = this.state;
    scales = scales
      ? map(scales, (s: IBktDataTableScaleData) => {
          s = Object.assign(new Scale(), s) as IBktDataTableScaleData;
          return Object.assign(pick(s, dataProps) as IBktDataTableScaleData, {
            key: s.Id,
            EditScale: (
              <FontAwesomeIcon
                icon={faPenToSquare}
                fixedWidth
                fontSize={"1.25rem"}
                id="squareEditIcon"
                data-testid="editIcon"
                title="Edit"
                onClick={() => this.handleDialogOpen(s.Id)}
              />
            )
          });
        })
      : undefined;

    return (
      <div id="container" className="mdd-grid">
        <Paper id="headerContainer">
          <Typography id="headerTitle"> All Scales</Typography>
          <Button
            id="addNewScaleButton"
            variant="contained"
            size={"small"}
            onClick={() => this.handleDialogOpen()}
          >
            <AddCircle id="addCircleIcon" />
            Add New Scale
          </Button>
          <MddDialog
            submitting={
              isLoading(`_${eScaleSaga.FETCH_MASTER_SCALES}`) ||
              isLoading(`undefined_${eScaleSaga.FETCH_SCALE}`) ||
              loading
            }
            dialogProps={{
              id: "BktFormDialogAddEditScale",
              onClose: this.handleDialogClose,
              "aria-labelledby": "form-dialog-title",
              open: this.state.openDialog
            }}
            dialogContentProps={{
              id: "form-dialog-content"
            }}
            dialogTitleProps={{
              id: "form-dialog-title",
              title: newScale.Id ? "Edit Scale" : "Add New Scale"
            }}
            dialogContent={this.renderDialogContentChildren()}
            dialogActions={
              <MddDialogButtons
                saveButtonProps={{
                  disabled: this.disableSaveButton(),
                  onClick: this.handleDialogSave
                }}
                closeButtonProps={{
                  onClick: () => this.handleDialogClose()
                }}
              />
            }
          />
        </Paper>
        <Paper id="bodyContainer">
          <div>
            <BktDataTable
              data={scales}
              defaultSortProp="Name"
              loading={isLoading(`undefined_${eScaleSaga.FETCH_SCALES}`)}
              columnProps={scaleDataTableHeaders(this.handleChangeFilter)}
              rowActions={[
                {
                  isPrimary: true,
                  action: (
                    data: IBktDataTableData,
                    event: React.MouseEvent<HTMLDivElement>
                  ) => history.push("/scale/" + data.key)
                }
              ]}
              {...scaleDataTableProps}
            />
          </div>
        </Paper>
      </div>
    );
  }

  private masterScaleId = () =>
    (Object.assign(
      new MasterScale(),
      this.state.newScale.MasterScale
    ) as IMasterScale).Id || "";

  private renderDialogContentChildren = () => {
    const { newScale, masterScales } = this.state;
    const masterScalesDropdownData = map(
      masterScales,
      (option: IMasterScale) => {
        return {
          value: option.Id,
          label: option.Name
        };
      }
    );
    return [
      <BktValidatedTextField
        key="txtScaleName"
        validationForOnBlur={[Validations.required]}
        validationForOnChange={[Validations.valid]}
        validationerror={(validationError: boolean) =>
          this.setState({ scaleNameHasError: validationError })
        }
        textFieldProps={{
          textProps: {
            variant: "standard",
            type: "text",
            required: true,
            className: "mdd-form--text",
            onChange: (event: any) =>
              this.setState({
                newScale: cloneAssign(newScale, { Name: event.target.value })
              }),
            value: newScale.Name || "",
            label: "Scale Name",
            placeholder: "Scale Name",
            id: "txtScaleName"
          }
        }}
      />,
      <BktValidatedTextField
        key="txtScaleShortName"
        validationForOnBlur={[Validations.required]}
        validationForOnChange={[Validations.valid]}
        validationerror={(validationError: boolean) =>
          this.setState({ scaleShortNameHasError: validationError })
        }
        textFieldProps={{
          textProps: {
            type: "text",
            required: true,
            className: "mdd-form--text",
            onChange: (event: any) =>
              this.setState({
                newScale: cloneAssign(newScale, {
                  ShortName: event.target.value
                })
              }),
            value: newScale.ShortName || "",
            placeholder: "Short Name",
            label: "Short Name",
            id: "txtScaleShortName"
          }
        }}
      />,
      <div key="masterScaleDiv" className="mdd-typeahead-container">
        {" "}
        {this.renderMasterScaleLabel()}{" "}
      </div>,
      <BktTypeaheadSelect
        key="bktTypeaheadSelect"
        selectData={masterScalesDropdownData}
        textProps={{
          validationForOnBlur: [Validations.required],
          textFieldProps: {
            textProps: {
              id: "masterScalesDropdown",
              className: "tbl--filter-select",
              InputLabelProps: { shrink: this.masterScaleId() !== undefined },
              disabled: masterScalesDropdownData.length === 0,
              value: getDefaultValueForSelect(
                masterScalesDropdownData,
                this.masterScaleId()
              ),
              placeholder: "Type to select scale"
            }
          }
        }}
        itemProps={{ name: "masterScaleId" }}
        onSubmit={this.handleTypeaheadChange}
      />
    ];
  };

  private handleDialogOpen = (scaleId?: string) => {
    this.onLoading = true;
    if (scaleId) {
      this.mode = "Edit";
      SagaService.dispatchSaga({
        type: eScaleSaga.FETCH_SCALE,
        payload: scaleId
      });
    } else {
      this.mode = "Add";
      this.setState({ newScale: new Scale() });
    }
    this.setState({ openDialog: true });
  };

  private handleDialogClose = () => {
    this.setState({ openDialog: false });
  };

  private renderMasterScaleLabel = (): JSX.Element | undefined => {
    let masterScaleHasError = false;
    if (this.masterScaleId()) {
      masterScaleHasError = false;
    } else if (isEmpty(this.masterScaleId) && !this.onLoading) {
      masterScaleHasError = true;
    } else {
      masterScaleHasError = false;
    }

    if (masterScaleHasError) {
      return (
        <label
          id="masterScaleLabel"
          className="mdd-form--typeahead-label-error"
          data-shrink="true"
        >
          Master Scale *
        </label>
      );
    } else {
      return (
        <label
          id="masterScaleLabel"
          className="mdd-form--typeahead-label"
          data-shrink="true"
        >
          Master Scale *
        </label>
      );
    }
  };

  private handleTypeaheadChange = (selectedOption: ILookupNameValue) => {
    this.onLoading = false;
    const { masterScales } = this.state;
    const { label, value } = selectedOption || {
      value: undefined,
      label: undefined
    };
    const masterScale = masterScales
      ? masterScales.find(x => x.Id === value)
      : undefined;

    this.setState({
      newScale: cloneAssign(this.state.newScale, {
        MasterScale: {
          Id: value,
          Name: label,
          ShortName: masterScale ? masterScale.ShortName : ""
        }
      })
    });
  };
  private disableSaveButton = () => {
    const {
      scaleNameHasError,
      scaleShortNameHasError,
      scale,
      newScale,
      loading
    } = this.state;
    return (
      loading ||
      scaleNameHasError ||
      scaleShortNameHasError ||
      !newScale.Name ||
      !newScale.ShortName ||
      !this.masterScaleId() ||
      isEqual(newScale, scale)
    );
  };

  private handleDialogSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!this.disableSaveButton()) {
      const { newScale } = this.state;
      const payload = new ScaleDTO(
        newScale.Id,
        newScale.Name,
        newScale.ShortName,
        this.masterScaleId()
      );
      SagaService.dispatchSaga({ type: eScaleSaga.MODIFY_SCALE, payload });
    }
  };
}
