import {
  IScaleExperience,
  ITrainingExperience,
  IStudyCourse,
  ICourseScoresDetails,
  ICourseThresholds,
  ICourseAcceptableScore,
  IPersonnelAtSite,
  IPersonnelTrainingScoreDetail
} from "../../model";
import { ISelectData } from "front-end-lib/core";
import { EXPERIENCE_STATUS_DISPLAY_VALUE } from "../../constants";

export const mockScaleExperienceData: IScaleExperience[] = [
  {
    userCourseStatusId: "100",
    scaleCourseId: 123,
    scaleName: "M.I.N.I.",
    experienceMonth: "",
    experienceYear: "",
    scaleUsage: "",
    status: EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE,
    overrideStatus: "",
    statusDate: null,
    comment: null
  },
  {
    userCourseStatusId: "201",
    scaleCourseId: 124,
    scaleName: "MADRS",
    experienceMonth: "1",
    experienceYear: "6",
    scaleUsage: "6-10",
    status: EXPERIENCE_STATUS_DISPLAY_VALUE.QUESTIONABLE,
    overrideStatus: "",
    statusDate: "2017-12-15T00:00:00+00:00",
    comment: { commentId: "123abad", commentText: "Test comment" }
  },
  {
    userCourseStatusId: "303",
    scaleCourseId: 125,
    scaleName: "SCID",
    experienceMonth: "9",
    experienceYear: "4",
    scaleUsage: "20+",
    status: EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW,
    overrideStatus: "",
    statusDate: "2019-06-23T00:00:00+00:00",
    comment: null
  }
];

export const mockScaleExperienceStatuses: ISelectData[] = [
  { label: "Eligible", value: "ELIGIBLE" },
  { label: "Questionable", value: "QUESTIONABLE" },
  { label: "Not Eligible", value: "NOTELIGIBLE" },
  { label: "Enriched Required", value: "ENRICHEDREQUIRED" },
  { label: "Co-Rating Required", value: "CORATINGREQUIRED" }
];

export const mockTrainingData: ITrainingExperience[] = [
  {
    userStudyQualificationStatusId: "00000000-0000-0000-0000-000000000000",
    studyQualificationId: "f072bc12-b48f-ea11-85b1-00155d0f1e6b",
    studyQualificationName: "CDR System Certification",
    studyQualificationStatus: null,
    studyQualificationStatusDate: null,
    comment: null,
    userLearningPlanStatuses: []
  },
  {
    userStudyQualificationStatusId: "0ABC0000-0000-0000-0000-000000000000",
    studyQualificationId: "0e7abc12-b48f-ea11-85b1-00155d0f1e6b",
    studyQualificationName: "CDR Scoring",
    studyQualificationStatus: { name: "Qualifiable", value: "QUALIFIABLE" },
    studyQualificationStatusDate: "2020-07-07T13:01:42.9256831+00:00",
    comment: null,
    userLearningPlanStatuses: [
      {
        userLearningPlanStatusId: "0000ZZZ0-0000-0000-0000-000000000000",
        learningPlanId: 57,
        legacyLearningPlanId: null,
        learningPlanName: "Testing Learning Plan with Scale Experience",
        learningPlanStatus: { name: "Qualifiable", value: "QUALIFIABLE" },
        learningPlanStatusDate: "2020-07-07T13:01:42.9256831+00:00",
        learningPlanAssignmentStatus: { name: "Assigned", value: "ASSIGNED" },
        isAssignable: false,
        userCourseStatuses: [
          {
            userCourseStatusId: "000GGG00-0000-0000-0000-000000000000",
            courseId: 141,
            studyComponentId: null,
            courseName: "Education Experience",
            courseStatus: { name: "Completed", value: "COMPLETED" },
            courseStatusDate: "2020-07-07T13:01:42.9256831+00:00",
            trainingCourseType: "SCORING",
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "000HHH00-0000-0000-0000-000000000000",
            courseId: 142,
            studyComponentId: null,
            courseName: "Clinical Experience",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "000LLL00-0000-0000-0000-000000000000",
            courseId: 160,
            studyComponentId: null,
            courseName: "Wildcard Questions",
            courseStatus: { name: "Exempt", value: "EXEMPT" },
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: true,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "00PPP000-0000-0000-0000-000000000000",
            courseId: 172,
            studyComponentId: null,
            courseName: "Updated Hidden Question Test",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: true,
            sourceEntry: ""
          }
        ]
      },
      {
        userLearningPlanStatusId: "00qwe000-0000-0000-0000-000000000000",
        legacyLearningPlanId: null,
        learningPlanId: 199,
        learningPlanName: "AAATesting Learning Plan2",
        learningPlanStatus: { name: "Incomplete", value: "INCOMPLETE" },
        learningPlanStatusDate: "",
        learningPlanAssignmentStatus: { name: "Assigned", value: "ASSIGNED" },
        isAssignable: true,
        userCourseStatuses: [
          {
            userCourseStatusId: "000TRR00-0000-0000-0000-000000000000",
            courseId: 241,
            studyComponentId: null,
            courseName: "Education Experience",
            courseStatus: { name: "Completed", value: "COMPLETED" },
            courseStatusDate: "2020-07-07T13:01:42.9256831+00:00",
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "000POI00-0000-0000-0000-000000000000",
            courseId: 1425,
            studyComponentId: null,
            courseName: "Clinical Experience",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "00ABC000-0000-0000-0000-000000000000",
            courseId: 1669,
            studyComponentId: null,
            courseName: "Wildcard Questions",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: true,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "00LKJ000-0000-0000-0000-000000000000",
            courseId: 173,
            studyComponentId: null,
            courseName: "Updated Hidden Question Test",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          }
        ]
      }
    ]
  },
  {
    userStudyQualificationStatusId: "ZABC0000-0000-0000-0000-000000000000",
    studyQualificationId: "0e7a9999-b48f-ea11-85b1-00155d0f1e6b",
    studyQualificationName: "CDR Scoring2",
    studyQualificationStatus: { name: "Qualifiable", value: "QUALIFIABLE" },
    studyQualificationStatusDate: "2020-07-07T13:01:42.9256831+00:00",
    comment: null,
    userLearningPlanStatuses: [
      {
        userLearningPlanStatusId: "00003210-0000-0000-0000-000000000000",
        learningPlanId: 59,
        legacyLearningPlanId: null,
        learningPlanName: "Testing Learning Plan with Scale Experience",
        learningPlanStatus: { name: "Qualifiable", value: "QUALIFIABLE" },
        learningPlanAssignmentStatus: { name: "Assigned", value: "ASSIGNED" },
        learningPlanStatusDate: "2020-07-07T13:01:42.9256831+00:00",
        isAssignable: false,
        userCourseStatuses: [
          {
            userCourseStatusId: "000GFG00-0000-0000-0000-000000000000",
            courseId: 146,
            studyComponentId: null,
            courseName: "Education Experience",
            courseStatus: { name: "Completed", value: "COMPLETED" },
            courseStatusDate: "2020-07-07T13:01:42.9256831+00:00",
            trainingCourseType: "Scoring",
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: true,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "000HHH00-0000-0000-0000-000000000000",
            courseId: 143,
            studyComponentId: null,
            courseName: "Clinical Experience",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "000LLL00-0000-0000-0000-000000000000",
            courseId: 166,
            studyComponentId: null,
            courseName: "Wildcard Questions",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "00PPP000-0000-0000-0000-000000000000",
            courseId: 192,
            studyComponentId: null,
            courseName: "Updated Hidden Question Test",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          }
        ]
      },
      {
        userLearningPlanStatusId: "0KJD000-0000-0000-0000-000000000000",
        learningPlanId: 99,
        legacyLearningPlanId: null,
        learningPlanName: "AAATesting Learning Plan2",
        learningPlanStatus: { name: "Incomplete", value: "INCOMPLETE" },
        learningPlanStatusDate: "",
        learningPlanAssignmentStatus: null,
        isAssignable: true,
        userCourseStatuses: [
          {
            userCourseStatusId: "000TRR00-0000-0000-0000-000000000000",
            courseId: 141,
            studyComponentId: null,
            courseName: "Education Experience",
            courseStatus: { name: "Completed", value: "COMPLETED" },
            courseStatusDate: "2020-07-07T13:01:42.9256831+00:00",
            trainingCourseType: "Scoring",
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "000POI00-0000-0000-0000-000000000000",
            courseId: 1427,
            studyComponentId: null,
            courseName: "Clinical Experience",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "00ABC000-0000-0000-0000-000000000000",
            courseId: 188,
            studyComponentId: null,
            courseName: "Wildcard Questions",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: true,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "00LKJ000-0000-0000-0000-000000000000",
            courseId: 174,
            studyComponentId: null,
            courseName: "Updated Hidden Question Test",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          }
        ]
      }
    ]
  },
  {
    userStudyQualificationStatusId: "ZABC0099-0000-0000-0000-000000999900",
    studyQualificationId: "0e7a9999-b48f-ea11-85b1-00155d0fzzzz",
    studyQualificationName: "Legacy CDR Scoring2",
    studyQualificationStatus: { name: "Qualifiable", value: "QUALIFIABLE" },
    studyQualificationStatusDate: "2020-09-07T13:01:42.9256831+00:00",
    comment: null,
    userLearningPlanStatuses: [
      {
        userLearningPlanStatusId: "0KJD000-0000-0000-0000-000000000000",
        learningPlanId: 0,
        legacyLearningPlanId: "1234-3333",
        learningPlanName: "Legacy AAATesting Learning Plan2",
        learningPlanStatus: { name: "Incomplete", value: "INCOMPLETE" },
        learningPlanStatusDate: "",
        learningPlanAssignmentStatus: null,
        isAssignable: false,
        userCourseStatuses: [
          {
            userCourseStatusId: "000TRR00-0999-0000-0000-000000000000",
            courseId: null,
            studyComponentId: "1233-5555-2222",
            courseName: "Education Experience",
            courseStatus: { name: "Completed", value: "COMPLETED" },
            courseStatusDate: "2020-07-07T13:01:42.9256831+00:00",
            trainingCourseType: "Scoring",
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "000POI00-0000-0000-0000-00000zzz0000",
            courseId: null,
            studyComponentId: "1233-5555-4444",
            courseName: "Clinical Experience",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "00ABC000-0000-0000-0000-000000000000",
            courseId: null,
            studyComponentId: "1233-5555-5432",
            courseName: "Wildcard Questions",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          },
          {
            userCourseStatusId: "00LKJ000-0000-0000-0000-000000000000",
            courseId: null,
            studyComponentId: "1233-5555-3383",
            courseName: "Updated Hidden Question Test",
            courseStatus: null,
            courseStatusDate: null,
            trainingCourseType: null,
            comment: null,
            canOverride: false,
            isEligibleForManualDataEntry: false,
            sourceEntry: ""
          }
        ]
      }
    ]
  }
];

export const mockStudyData = [
  {
    demographic: "Adult",
    indication: "Autism",
    internalStudyName: "internal1",
    protocol: "protocol1",
    scale: null,
    sponsorId: "1",
    sponsorName: "sponsor1",
    studyId: "1",
    studyName: "study1",
    therapeuticArea: "Neurology"
  },
  {
    demographic: "Pediatric",
    indication: "Cardiovascular",
    internalStudyName: "internal2",
    protocol: "protocol2",
    scale: null,
    sponsorId: "2",
    sponsorName: "sponsor2",
    studyId: "2",
    studyName: "study2",
    therapeuticArea: "Psychiatry"
  },
  {
    demographic: "Pediatric",
    indication: "Sleep Disorders",
    internalStudyName: "internal3",
    protocol: "protocol3",
    scale: null,
    sponsorId: "3",
    sponsorName: "sponsor3",
    studyId: "3",
    studyName: "study3",
    therapeuticArea: "Psychiatry"
  }
];

export const mockCourseData: IStudyCourse[] = [
  {
    courseName: "Clinical Experience",
    learningPlanName: "Testing Learning Plan",
    studyQualificationName: "CDR Scoring",
    courseId: 141,
    studyComponentId: null
  },
  {
    courseName: "Placebo Response Training",
    learningPlanName: "MADRS training plan",
    studyQualificationName: "MADRS Qualification",
    courseId: 142,
    studyComponentId: null
  },
  {
    courseName: "Wildcard Questions",
    learningPlanName: "Testing Learning Plan",
    studyQualificationName: "CDR Scoring",
    courseId: 160,
    studyComponentId: null
  },
  {
    courseName: "Legacy Course",
    learningPlanName: "Legacy Learning Plan",
    studyQualificationName: "Legacy Scoring",
    courseId: null,
    studyComponentId: "123"
  }
];

export const mockCourseThresholds: ICourseThresholds[] = [
  {
    passingThresholdPercentage: 85,
    remediationThresholdPercentage: 65,
    isLocked: false
  },
  {
    passingThresholdPercentage: 85,
    remediationThresholdPercentage: 65,
    isLocked: true
  },
  {
    passingThresholdPercentage: 85,
    remediationThresholdPercentage: 65,
    isLocked: true
  },
  {
    passingThresholdPercentage: 85,
    remediationThresholdPercentage: 65,
    isLocked: true
  }
];

export const mockCountryScores: ICourseScoresDetails[] = [
  {
    courseName: "Test Course",
    masterAcceptableScore: {
      masterAcceptableScoreId: null,
      isLocked: false
    },
    countryAcceptableScores: [
      {
        countryName: "Canada",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "333",
        countryId: "1"
      },
      {
        countryName: "Mexico",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "875",
        countryId: "2"
      },
      {
        countryName: "United States",
        hasCountrySpecificScoreSheet: false,
        countryAcceptableScoreId: "123",
        countryId: "3"
      }
    ]
  },
  {
    courseName: "Test Course2",
    masterAcceptableScore: {
      masterAcceptableScoreId: null,
      isLocked: false
    },
    countryAcceptableScores: [
      {
        countryName: "Canada",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "333",
        countryId: "1"
      },
      {
        countryName: "Mexico",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "875",
        countryId: "2"
      },
      {
        countryName: "United States",
        hasCountrySpecificScoreSheet: false,
        countryAcceptableScoreId: "123",
        countryId: "3"
      }
    ]
  },
  {
    courseName: "Test Course3",
    masterAcceptableScore: {
      masterAcceptableScoreId: "1",
      isLocked: false
    },
    countryAcceptableScores: [
      {
        countryName: "Canada",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "333",
        countryId: "1"
      },
      {
        countryName: "Mexico",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "875",
        countryId: "2"
      },
      {
        countryName: "United States",
        hasCountrySpecificScoreSheet: false,
        countryAcceptableScoreId: "123",
        countryId: "3"
      }
    ]
  },
  {
    courseName: "Test Course4",
    masterAcceptableScore: {
      masterAcceptableScoreId: "1",
      isLocked: true
    },
    countryAcceptableScores: [
      {
        countryName: "Canada",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "333",
        countryId: "1"
      },
      {
        countryName: "Mexico",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "875",
        countryId: "2"
      },
      {
        countryName: "United States",
        hasCountrySpecificScoreSheet: false,
        countryAcceptableScoreId: "123",
        countryId: "3"
      }
    ]
  },
  {
    courseName: "Test Course5",
    masterAcceptableScore: {
      masterAcceptableScoreId: "1",
      isLocked: false
    },
    countryAcceptableScores: [
      {
        countryName: "Canada",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "333",
        countryId: "1"
      },
      {
        countryName: "Mexico",
        hasCountrySpecificScoreSheet: true,
        countryAcceptableScoreId: "875",
        countryId: "2"
      },
      {
        countryName: "United States",
        hasCountrySpecificScoreSheet: false,
        countryAcceptableScoreId: "123",
        countryId: "3"
      }
    ]
  }
];

export const mockMasterScoresData: ICourseAcceptableScore[][] = [
  [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: null,
      answerSet: "1,2,3"
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: null,
      answerSet: "1,2,3,5"
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: null,
      answerSet: "1,2"
    }
  ],
  [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: null,
      answerSet: "0,1,2,3"
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: null,
      answerSet: "1,2,3"
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: null,
      answerSet: "5-7"
    }
  ],
  [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: null,
      answerSet: "1.1,1.2,1.3"
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: null,
      answerSet: "1,2,3,10"
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: null,
      answerSet: "1,2,3"
    }
  ],
  [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: "1-3",
      answerSet: "1,2,3"
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: "3-5,6",
      answerSet: "1,2,3,4,5,6"
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: "7",
      answerSet: "1,2,3-10"
    }
  ],
  [
    {
      courseQuestionId: "Q1",
      courseQuestionText: "Question 1",
      acceptableResponse: "1-3",
      answerSet: "1,2,3,4-6"
    },
    {
      courseQuestionId: "Q2",
      courseQuestionText: "Question 2",
      acceptableResponse: "3-5,6",
      answerSet: "1,3"
    },
    {
      courseQuestionId: "Q3",
      courseQuestionText: "Question 3",
      acceptableResponse: "7",
      answerSet: "1,2"
    }
  ],
  [
    // empty response and answer set used to test create
    {
      courseQuestionId: "Q1",
      courseQuestionText: "Question 1",
      acceptableResponse: "",
      answerSet: ""
    },
    {
      courseQuestionId: "Q2",
      courseQuestionText: "Question 2",
      acceptableResponse: "",
      answerSet: ""
    },
    {
      courseQuestionId: "Q3",
      courseQuestionText: "Question 3",
      acceptableResponse: "",
      answerSet: ""
    }
  ]
];

export const mockCountryScoresData: ICourseAcceptableScore[][] = [
  [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: null
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: null
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: null
    }
  ],
  [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: null
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: null
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: null
    }
  ],
  [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: null
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: null
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: null
    }
  ],
  [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: "1-4"
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: "3-5"
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: "8"
    }
  ],
  [
    {
      courseQuestionId: "Q1",
      courseQuestionText: "Question 1",
      acceptableResponse: "2-4"
    },
    {
      courseQuestionId: "Q2",
      courseQuestionText: "Question 2",
      acceptableResponse: "3-5"
    },
    {
      courseQuestionId: "Q3",
      courseQuestionText: "Question 3",
      acceptableResponse: "7"
    }
  ]
];

export const mockPersonnelAtSite: IPersonnelAtSite[] = [
  {
    personnelId: "b0d6a181-e99b-4071-18a5-08d819155887",
    userName: "Bob.Jones",
    type: "Internal",
    firstName: "Bob",
    lastName: "Jones",
    email: "Bob.Jones@bracketglobal.com",
    existsInCurrentStudySite: true,
    roles: []
  },
  {
    personnelId: "93c22279-43af-ea11-947d-00155d0f1e6b",
    userName: "John.Smith",
    type: "External",
    firstName: "John",
    lastName: "Smith",
    email: "john.smith@somewhere.com",
    existsInCurrentStudySite: false,
    roles: []
  },
  {
    personnelId: "93c22279-43af-ea11-947d-00155dxx1e6b",
    userName: "Jim.Tran",
    type: "External",
    firstName: "Jim",
    lastName: "Tran",
    email: "jim.tran@somewhere.com",
    existsInCurrentStudySite: false,
    roles: []
  }
];

export const mockSearchUserData = [
  {
    firstName: "Visalakshi",
    lastName: "Mandavilli",
    userName: "v.mandavilli",
    type: "External",
    email: "v.mandavilli@somewhere.com"
  },
  {
    firstName: "Sallyanne",
    lastName: "Brockway",
    userName: "Sallyanne.Brockway",
    type: "Internal",
    email: "Sallyanne.Brockway@somewhere.com"
  },
  {
    firstName: "Saleem",
    lastName: "Ismail",
    userName: "saleem.ismail",
    type: "Internal",
    email: "saleem.ismail@somewhere.com"
  },
  {
    firstName: "Salini",
    lastName: "Makineni",
    userName: "salini.makineni",
    type: "External",
    email: "salini.makineni@bracketglobal.com"
  }
];

export const mockBulkLPAssignData = [
  {
    countryName: "United States",
    sponsorSiteId: "123",
    personnelName: "John Smith",
    personnelId: "123",
    isAssigned: false,
    roles: [{ name: "Principal Investigator", roleId: "123" }]
  },
  {
    countryName: "Mexico",
    sponsorSiteId: "333",
    personnelName: "Bob Jones",
    personnelId: "333",
    isAssigned: false,
    roles: [{ name: "Rater", roleId: "333" }]
  },
  {
    countryName: "Grenada",
    sponsorSiteId: "433",
    personnelName: "Sal Miller",
    personnelId: "623",
    isAssigned: true,
    roles: [
      { name: "Rater", roleId: "333" },
      { name: "Principal Investigator", roleId: "123" }
    ]
  }
];

export const mockBulkLPs = [
  { id: 1, name: "LP #1" },
  { id: 2, name: "LP #2" },
  { id: 3, name: "LP #3" }
];

export const mockPersonnelTrainingScores: IPersonnelTrainingScoreDetail = {
  absoluteItemsDeviation: 7,
  courseName: "Test Course Name",
  courseStatus: { name: "Fail", value: "FAIL" },
  itemsDeviation: 3,
  overallPercentage: 60,
  statusDate: "2020-11-16T18:39:17+00:00",
  totalScore: 62,
  modifiedBy: "test.user",
  modifiedDate: "2018-07-13T10:39:19.953+00:00",
  qcDate: undefined,
  qcUser: undefined,
  sourceEntry: undefined,
  courseCompletionDate: undefined,
  questionAnswers: [
    {
      courseQuestionId: "1",
      courseQuestionText: "Question 1",
      acceptableResponse: "3-5",
      isResponseCorrect: true,
      userResponse: "4"
    },
    {
      courseQuestionId: "2",
      courseQuestionText: "Question 2",
      acceptableResponse: "2",
      isResponseCorrect: false,
      userResponse: "6"
    },
    {
      courseQuestionId: "3",
      courseQuestionText: "Question 3",
      acceptableResponse: "2,3,5",
      isResponseCorrect: true,
      userResponse: "3"
    },
    {
      courseQuestionId: "4",
      courseQuestionText: "Question 4",
      acceptableResponse: "8",
      isResponseCorrect: false,
      userResponse: "7"
    },
    {
      courseQuestionId: "5",
      courseQuestionText: "Question 5",
      acceptableResponse: "1-2,4",
      isResponseCorrect: true,
      userResponse: "1"
    }
  ]
};

export const mockTrainingCourseScores = {
  courseCompletionDate: "2022-08-25T00:00:00+00:00",
  modifiedDate: "2022-08-25T14:00:17.5177186-05:00",
  questionAnswers: [
    {
      courseQuestionText: "Question 1",
      acceptableResponse: "3-5",
      userResponse: "4"
    },
    {
      courseQuestionText: "Question 2",
      acceptableResponse: "2",
      userResponse: "6"
    },
    {
      courseQuestionText: "Question 3",
      acceptableResponse: "2,3,5",
      userResponse: "3"
    },
    {
      courseQuestionText: "Question 4",
      acceptableResponse: "8",
      userResponse: "7"
    },
    {
      courseQuestionText: "Question 5",
      acceptableResponse: "1-2,4",
      userResponse: "1"
    }
  ]
};
