import { ISelectData } from "front-end-lib/core";

export const classConstants = {
  TEXT_FILTER_CLASS: "tbl--filter-text",
  TABLE_CELL_CLASS: "tbl--header-cell"
};

export const apiConstants = {
  PAGINATION_PAYLOAD_LIMIT: 200
};

export const messages = {
  NO_RECORDS_FOUND: "No records found"
};

export const MAX_TEXT_LENGTH = {
  QUALIFICATION_NAME: 200,
  SITE_NAME: 255,
  ADDRESS1: 200,
  ADDRESS2: 200,
  CITY: 200,
  ZIP_CODE: 20,
  PHONE_NUMBER: 50,
  SITE_COMMENT: 2200,
  SPONSOR_NAME: 150,
  SPONSOR_SITE_ID: 20,
  PROGRAM_NAME: 100,
  STUDY_NAME: 100,
  INTERNAL_STUDY_NAME: 50,
  INTERNAL_STUDY_CODE: 50,
  PROTOCOL_NAME: 100,
  COMMENT: 4000,
  EMAIL: 320,
  THRESHOLD_VALUE: 3,
  COURSE_ACCEPTABLE_RESPONSE: 120,
  COURSE_ANSWER_SET: 120,
  COURSE_ANSWER: 12
};

export const menuTabs = [
  { id: "studyTab", label: "STUDIES", url: "study", default: true },
  { id: "sponsorTab", label: "SPONSORS", url: "sponsor", default: false },
  { id: "siteTab", label: "SITES", url: "site", default: false },
  { id: "scalesTab", label: "SCALES", url: "scale", default: false },
  { id: "personnelTab", label: "PERSONNEL", url: "personnel", default: false }
];

export enum LOGIN_STATE {
  AUTHENTICATING,
  AUTHENTICATION_TIMEOUT,
  AUTHENTICATED,
  AUTHENTICATION_ERROR,
  LOGGING_OUT
}

export const defaultApiHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  pragma: "no-cache",
  "cache-control": "no-cache"
};

export const activeStatuses: ISelectData[] = [
  { value: "true", label: "Active", key: "Active" },
  { value: "false", label: "Inactive", key: "Inactive" }
];

export const NO_DATA_DISPLAY_TEXT = "N/A"; // text to display when a data value is null or empty
export const NO_DATA_AVAILABLE_TEXT = "No Data Available";
export const NO_ANSWER_DISPLAY_TEXT = "--"; // text to display when no answer for a course question
export const PI_TEXT = "Principal Investigator";

export const QUALIFICATION_TYPE = {
  RATER_IDENTIFICATION: "RATERIDENTIFICATION",
  RATER_EXPERIENCE: "RATEREXPERIENCE",
  TRAINING: "TRAINING",
  ENRICHED_TRAINING: "ENRICHEDTRAINING",
  CORATING: "CORATING"
};

export const EXPERIENCE_TAG = {
  GENERAL: "General-Experience",
  CLINICAL: "Clinical-Experience",
  ADDITIONAL: "Additional-Questions",
  SCALE: "Scale-Experience"
};

export const EXPERIENCE_STATUS_DISPLAY_VALUE = {
  INCOMPLETE: "Incomplete",
  CLINICAL_REVIEW: "Clinical Review",
  ELIGIBLE: "Eligible",
  NOT_ELIGIBLE: "Not Eligible",
  QUESTIONABLE: "Questionable",
  ENRICHED_REQUIRED: "Enriched Required",
  CORATING_REQUIRED: "Co-Rating Required",
  EXEMPT: "Exempt",
  PENDING_EXEMPT: "Pending Exempt",
  PENDING_RESET: "Pending Reset",
  PARTIAL: "Partial"
};
export const EXPERIENCE_STATUS_VALUE = {
  INCOMPLETE: "INCOMPLETE",
  CLINICAL_REVIEW: "CLINICALREVIEW",
  ELIGIBLE: "ELIGIBLE",
  NOT_ELIGIBLE: "NOTELIGIBLE",
  QUESTIONABLE: "QUESTIONABLE",
  ENRICHED_REQUIRED: "ENRICHEDREQUIRED",
  CORATING_REQUIRED: "CORATINGREQUIRED",
  EXEMPT: "EXEMPT",
  PENDING_EXEMPT: "PENDINGEXEMPT",
  PENDING_RESET: "PENDINGRESET",
  PARTIAL: "PARTIAL"
};

export const EXPERIENCE_STATUS_MAP = [
  {
    value: EXPERIENCE_STATUS_VALUE.INCOMPLETE,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE
  },
  {
    value: EXPERIENCE_STATUS_VALUE.CLINICAL_REVIEW,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
  },
  {
    value: EXPERIENCE_STATUS_VALUE.ELIGIBLE,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.ELIGIBLE
  },
  {
    value: EXPERIENCE_STATUS_VALUE.NOT_ELIGIBLE,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.NOT_ELIGIBLE
  },
  {
    value: EXPERIENCE_STATUS_VALUE.QUESTIONABLE,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.QUESTIONABLE
  },
  {
    value: EXPERIENCE_STATUS_VALUE.ENRICHED_REQUIRED,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.ENRICHED_REQUIRED
  },
  {
    value: EXPERIENCE_STATUS_VALUE.CORATING_REQUIRED,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.CORATING_REQUIRED
  },
  {
    value: EXPERIENCE_STATUS_VALUE.EXEMPT,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.EXEMPT
  },
  {
    value: EXPERIENCE_STATUS_VALUE.PENDING_EXEMPT,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.PENDING_EXEMPT
  },
  {
    value: EXPERIENCE_STATUS_VALUE.PENDING_RESET,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.PENDING_RESET
  },
  {
    value: EXPERIENCE_STATUS_VALUE.PARTIAL,
    label: EXPERIENCE_STATUS_DISPLAY_VALUE.PARTIAL
  }
];

export const LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE = {
  ASSIGNED: "ASSIGNED",
  PENDING_ASSIGNMENT: "PENDINGASSIGNMENT"
};

export const LEARNING_PLAN_ASSIGNMENT_STATUS_NAME = {
  ASSIGNED: "Assigned",
  PENDING_ASSIGNMENT: "Pending Assignment"
};

export const QUALIFICATION_STATUS_DISPLAY_VALUE = {
  QUALIFIED: "Qualified",
  NOT_QUALIFIED: "Not Qualified",
  NOT_RATING: "Not Rating",
  INCOMPLETE: "Incomplete",
  QUESTIONABLE: "Questionable"
};

export const QUALIFICATION_STATUS_VALUE = {
  QUALIFIED: "QUALIFIED",
  NOT_QUALIFIED: "NOTQUALIFIED",
  NOT_RATING: "NOTRATING",
  INCOMPLETE: "INCOMPLETE",
  QUESTIONABLE: "QUESTIONABLE"
};

export const QUALIFICATION_STATUS_MAP = [
  {
    value: QUALIFICATION_STATUS_VALUE.QUALIFIED,
    label: QUALIFICATION_STATUS_DISPLAY_VALUE.QUALIFIED
  },
  {
    value: QUALIFICATION_STATUS_VALUE.NOT_QUALIFIED,
    label: QUALIFICATION_STATUS_DISPLAY_VALUE.NOT_QUALIFIED
  },
  {
    value: QUALIFICATION_STATUS_VALUE.NOT_RATING,
    label: QUALIFICATION_STATUS_DISPLAY_VALUE.NOT_RATING
  },
  {
    value: QUALIFICATION_STATUS_VALUE.INCOMPLETE,
    label: QUALIFICATION_STATUS_DISPLAY_VALUE.INCOMPLETE
  },
  {
    value: QUALIFICATION_STATUS_VALUE.QUESTIONABLE,
    label: QUALIFICATION_STATUS_DISPLAY_VALUE.QUESTIONABLE
  }
];

export const TRAINING_COURSE_TYPE = {
  SCORING: "SCORING",
  NON_SCORING: "NON-SCORING",
  DATA_ENTRY: "DATA-ENTRY"
};

export const REGEX_VALUES = {
  ANSWER_SET_RANGE:
    "^[0-9.]*(\\.[0-9.]{1})?(?:-[0-9.]*(\\.[0-9.]{1})?)?([0-9.]*(\\.[0-9.]{1})?(?:-[0-9.]*(\\.[0-9.]{1})?)?)*$",
  ANSWER_SET_VALUES:
    "^[0-9.]*(\\.[0-9.]{1})?(?:[0-9.]*(\\.[0-9.]{1})?)?(,[0-9.]*(\\.[0-9.]{1})?(?:[0-9.]*(\\.[0-9.]{1})?)?)*$",
  NUMBER_AND_NO_MULTIPLE_DECIMALS:
    "^[0-9]*(.[0-9]{1})?(?:-[0-9]*(.[0-9]{1})?)?(,[0-9]*(.[0-9]{1})?(?:-[0-9]*(.[0-9]{1})?)?)*$"
};

export const eHTTPCodes = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404
};

export enum eHTTPMethods {
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
  POST = "POST",
  PATCH = "PATCH"
}

export enum eApiNames {
  MDD_API = "mddHTTPService",
  STUDY_API = "studyApiHTTPService"
}

export enum eResTypes {
  DOCEBO = "DOCEBO",
  CLINICALPROFILE = "CLINICALPROFILE",
  LEGACYEXPERIENCE = "LEGACYEXPERIENCE",
  NONE = "NONE"
}

export enum eIconTypes {
  MDI,
  FONTAWESOME
}

export const SH_SESSION_COOKIE_NAME = "SH-Session";
export const SH_SESSION_COOKIE_POLLING_INTERVAL = 1; // in seconds
export const SH_SESSION_COOKIE_MAX_AGE = 34560000; // 400 days
// NOTE: this value (4 hours) is set to match up with what RaterStation currently uses but
// we need Product input to decide on what this should be
export const INACTIVITY_TIMEOUT = 240; // in minutes
