import * as React from "react";
import { connect } from "react-redux";
import { SagaService } from "front-end-lib/core";
import { Sponsor, IMddSponsorProps } from "../../../model";
import { IApplicationState, eSponsorActionTypes } from "../../../store";
import { eSponsorSaga } from "../../../sagas";
import { store } from "../../../utils";
import { withMddBase } from "../WithMddBase/WithMddBase";

/**
 * HOC for any sponsor components.
 * Will map the state to props for sponsor specific store state.
 * @returns {(WrappedComponent: any) => React.Component} Returns a component with base sponsor functionality implemented.
 */
export const withMddSponsor = () => {
  return (WrappedComponent: any) => {
    return withMddBase(
      connect((state: IApplicationState) => {
        return {
          sponsor: state.sponsor.currentSponsor
        };
      })((props: IMddSponsorProps) => {
        const { id } = props.match.params;
        React.useEffect(() => {
          if (id && id !== props.sponsor.SponsorId) {
            store.dispatch({
              type: eSponsorActionTypes.SET_SPONSOR,
              payload: new Sponsor()
            });
            SagaService.dispatchSaga({
              type: eSponsorSaga.FETCH_SPONSOR,
              payload: id
            });
          }
          // eslint-disable-next-line
        }, []);
        return <WrappedComponent {...(props || {})} />;
      })
    );
  };
};
