import React from "react";
import { IBktDataTableColumnProps, SortType } from "front-end-lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faPenToSquare,
  faArrowAltCircleUp,
  faEye
} from "@fortawesome/free-regular-svg-icons";
import { IResVersionListItem } from "../../../model/interfaces/iResVersionListItem";
import { ResStatus } from "../../../constants/resConfigStatus";
import { toDateFormatNoOffset } from "../../../utils";

interface IActionsProps {
  id: string;
  status: string;
}

const sortVersion = (a: IResVersionListItem, b: IResVersionListItem) => {
  const parseVersion = (version: string) => version.split(".").map(Number);

  const versionA = parseVersion(a.version);
  const versionB = parseVersion(b.version);

  for (let i = 0; i < versionA.length; i++) {
    if (versionA[i] !== versionB[i]) {
      return versionA[i] - versionB[i];
    }
  }

  return 0;
};

export interface IResVersionBktDataTable extends IResVersionListItem {
  key: string;
}

export const headers = (
  onEdit: (id: string) => void,
  onDelete: (id: string) => void,
  onPublish: React.Dispatch<React.SetStateAction<string | undefined>>,
  onView: (id: string) => void
): IBktDataTableColumnProps[] => [
  {
    label: "Version",
    sortProp: {
      sortPropName: "version",
      desc: false,
      sortType: "Custom" as SortType,
      alg: sortVersion
    },
    tableCellProps: {
      id: "versionHeader"
    },
    formatProp: {
      formatPropName: "version",
      format: (val: string) => val
    }
  },
  {
    label: "Last Updated By",
    sortProp: { sortPropName: "updatedBy", desc: true },
    tableCellProps: {
      id: "updatedByHeader",
      className: "tbl--header-cell"
    },
    divProps: { id: "updatedByHeader_div" }
  },
  {
    label: "Last Updated Date",
    sortProp: { sortPropName: "updatedDt", desc: true },
    tableCellProps: {
      id: "updatedDtHeader",
      className: "tbl--header-cell"
    },
    divProps: { id: "updatedDtHeader_div" }
  },
  {
    label: "Status",
    sortProp: { sortPropName: "status", desc: true },
    tableCellProps: {
      id: "statusHeader",
      className: "tbl--header-cell"
    },
    divProps: { id: "statusHeader_div" }
  },
  {
    label: "Published Date",
    sortProp: { sortPropName: "publishedDt", desc: true },
    tableCellProps: {
      id: "publishedDtHeader",
      className: "tbl--header-cell"
    },
    divProps: { id: "publishedDtHeader_div" }
  },
  {
    label: "Archived Date",
    sortProp: { sortPropName: "archivedDt", desc: true },
    tableCellProps: {
      id: "archivedDtHeader",
      className: "tbl--header-cell"
    },
    divProps: { id: "archivedDtHeader_div" }
  },
  {
    label: "Actions",
    tableCellProps: {
      id: "actionsHeader",
      className: "tbl--header-cell"
    },
    divProps: { id: "actionsHeader_div" },
    formatProp: {
      formatPropName: "actions",
      format: ({ id, status }: IActionsProps) => (
        <div className="tbl--body-row-actions">
          {status === ResStatus.Draft ? (
            <>
              <div onClick={() => onEdit(id)}>
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  fixedWidth
                  id="squareEditIcon"
                  data-testid="squareEditIcon"
                  title="Edit"
                />
              </div>
              <span onClick={() => onPublish(id)}>
                <FontAwesomeIcon
                  icon={faArrowAltCircleUp}
                  fixedWidth
                  id="publishArrowIcon"
                  data-testid="publishArrowIcon"
                  title="Publish"
                />
              </span>
              <span onClick={() => onDelete(id)}>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  fixedWidth
                  id="deleteCircleIcon"
                  data-testid="deleteCircleIcon"
                  title="Delete"
                />
              </span>
            </>
          ) : (
            <>
              <div onClick={() => onView(id)}>
                <FontAwesomeIcon
                  icon={faEye}
                  fixedWidth
                  id="viewEyeIcon"
                  data-testid="viewEyeIcon"
                  title="View"
                />
              </div>
            </>
          )}
        </div>
      )
    }
  }
];

export const getConfig = (
  items: IResVersionListItem[]
): Omit<IResVersionBktDataTable, "id">[] =>
  items.map(
    ({
      id,
      version,
      status,
      archivedDate,
      lastUpdateDate,
      lastUpdateUser,
      publishedDate
    }) => ({
      key: id,
      version,
      lastUpdateUser,
      lastUpdateDate: lastUpdateDate
        ? toDateFormatNoOffset(lastUpdateDate)
        : "-",
      status,
      publishedDate: publishedDate ? toDateFormatNoOffset(publishedDate) : "-",
      archivedDate: archivedDate ? toDateFormatNoOffset(archivedDate) : "-",
      actions: { id, status }
    })
  );
