import * as React from "react";
import { history, removeAllSpaces } from "../../../../utils";
import { Button } from "@material-ui/core";
import { eStudySaga } from "../../../../sagas";
import {
  SagaService,
  IAction,
  BktDataTable,
  IBktDataTableData
} from "front-end-lib/core";
import {
  ICourseAcceptableScore,
  IAcceptableScoresPayload,
  Validations,
  IScoreResponse
} from "../../../../model";
import {
  MddBreadcrumb,
  MddPanelContainer,
  MddValidatedTextField,
  MddDialogButtons,
  MddEditScoresDialog,
  MddNoDataDiv
} from "../../../../components";
import { MAX_TEXT_LENGTH } from "../../../../constants";

interface IMddEditCourseScoresRenderProps {
  studyId: string;
  studyName: string;
  courseId: string | null;
  studyComponentId: string | null;
  countryName?: string;
  countryId?: string;
  courseName: string;
  mode: "add" | "edit" | "view";
  isNewCountry?: boolean;
  isMasterScores: boolean;
  questions: ICourseAcceptableScore[];
}

interface IDataTableQuestion extends IBktDataTableData {
  question: string;
  acceptableResponse: IScoreResponse;
  updatedResponse: IScoreResponse;
  answerSet: IScoreResponse;
  updateButton?: ICourseAcceptableScore;
  // currentConcordance: string;  //TODO - for post-MVP
  // updatedConcordance: string;  //TODO - for post-MVP
}

export const MddEditCourseScoresRender = (
  props: IMddEditCourseScoresRenderProps
) => {
  const [isUpdateOpen, setIsUpdateOpen] = React.useState(false);
  const [
    currScore,
    setCurrScore
  ] = React.useState<ICourseAcceptableScore | null>(null);

  const [questionsTableData, setQuestionsTableData] = React.useState<
    IDataTableQuestion[]
  >([]);
  const [
    hasQuestionsValidationError,
    setHasQuestionsValidationError
  ] = React.useState(false);
  const [
    hasAnswerSetValidationError,
    setHasAnswerSetValidationError
  ] = React.useState(false);
  const [isResponseUpdated, setIsResponseUpdated] = React.useState(false);

  React.useEffect(() => {
    const removeSaveMasterScores = SagaService.subscribeToSaga(
      eStudySaga.SAVE_COURSE_MASTER_QUESTIONS,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_COURSE_MASTER_QUESTIONS_SUCCESS) {
          history.push(`/study/${studyId}/scores/${courseId}`);
        }
      }
    );

    const removeSaveCountryScores = SagaService.subscribeToSaga(
      eStudySaga.SAVE_COURSE_COUNTRY_QUESTIONS,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_COURSE_COUNTRY_QUESTIONS_SUCCESS) {
          history.push(`/study/${studyId}/scores/${courseId}`);
        }
      }
    );

    return () => {
      removeSaveMasterScores();
      removeSaveCountryScores();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // Since the parent loads the questions we need this effect to
    // format those once they are passed in.  Can't do on the mount effect
    // since at that point they haven't been loaded yet.

    if (!props.questions || !props.questions.length) {
      return;
    }

    const questions = props.questions.map(
      (question: ICourseAcceptableScore) => {
        return Object.assign({} as IDataTableQuestion, {
          question: question.courseQuestionText,
          acceptableResponse: {
            value: question.acceptableResponse
              ? question.acceptableResponse
              : "",
            questionId: question.courseQuestionId
          },
          updatedResponse: {
            value: "",
            questionId: question.courseQuestionId
          },
          answerSet: {
            value: question.answerSet ? question.answerSet : "",
            questionId: question.courseQuestionId
          },
          updateButton: question,
          // currentConcordance: "",  //TODO - for post-MVP
          // updatedConcordance: "", //TODO - for post-MVP
          key: question.courseQuestionId
        });
      }
    );

    setQuestionsTableData(questions);
  }, [props.questions]);

  const isMissingResponses = () => {
    const foundMissing = questionsTableData.find(
      (question: IDataTableQuestion) => {
        return !question.acceptableResponse.value || !question.answerSet.value;
      }
    );
    return foundMissing !== undefined;
  };

  const disableSaveButton = () => {
    const { mode } = props;

    return (
      hasQuestionsValidationError ||
      hasAnswerSetValidationError ||
      isMissingResponses() ||
      questionsTableData.length === 0 ||
      (mode === "edit" && !isResponseUpdated)
    );
  };

  const handleResponseChange = (
    currQuestions: IDataTableQuestion[],
    score: IScoreResponse,
    fieldVal: string
  ) => {
    // find the matching question id in the table data and update it
    const match = currQuestions.find((question: IDataTableQuestion) => {
      return question.key === score.questionId;
    });
    if (match) {
      const newQuestions = currQuestions.map((question: IDataTableQuestion) => {
        return Object.assign(
          {} as IDataTableQuestion,
          question.key !== match.key
            ? { ...question }
            : {
                ...question,
                [fieldVal]: {
                  value: removeAllSpaces(score.value),
                  questionId: match.key
                }
              }
        );
      });
      return newQuestions;
    }
    return { ...currQuestions };
  };

  const handleCancelClick = () => {
    const { studyId, courseId, studyComponentId } = props;

    // send them back to the course page
    if (courseId) {
      history.push(`/study/${studyId}/scores/${courseId}`);
    } else {
      history.push(`/study/${studyId}/scores/${studyComponentId}/legacy`);
    }
  };

  const getCurrentResponse = (question: IDataTableQuestion) => {
    const { mode } = props;

    let value = "";

    switch (mode) {
      case "add":
        // add will only be for master scores and they will have to have entered something for the
        // acceptable score so use that (no updated score is in play since just defining it for the first time)
        value = removeAllSpaces(question.acceptableResponse.value);
        break;
      case "edit":
        // For any given question on edit the updated response may or may not be an empty string since
        // they probably didn't update them all.  If the updated response is blank use the current one.
        const newResponse = removeAllSpaces(question.updatedResponse.value);
        value = newResponse ? newResponse : question.acceptableResponse.value;
        break;
      default:
        // Only other case would be view and there is no option to save that so
        // there is no way we should be here but since Typescript wants a default here we are.
        break;
    }
    return value;
  };

  const handleSaveClick = () => {
    const {
      studyId,
      mode,
      isMasterScores,
      courseId,
      countryId,
      isNewCountry = false
    } = props;
    const questionKey = isMasterScores
      ? "masterAcceptableScoreQuestions"
      : "countryAcceptableScoreQuestions";
    const questions = {
      studyId,
      [questionKey]: questionsTableData.map((question: IDataTableQuestion) => {
        // If it's add just use the acceptable response they just entered,
        // if it's not add (must be edit) use the newly added value if it exists,
        // but the original acceptable otherwise.
        const currentResponse = getCurrentResponse(question);
        return Object.assign(
          {},
          isMasterScores
            ? {
                courseQuestionId: question.key,
                acceptableResponse: currentResponse,
                answerSet: removeAllSpaces(question.answerSet.value)
              }
            : {
                courseQuestionId: question.key,
                acceptableResponse: currentResponse
              }
        );
      })
    };

    SagaService.dispatchSaga({
      type: isMasterScores
        ? eStudySaga.SAVE_COURSE_MASTER_QUESTIONS
        : eStudySaga.SAVE_COURSE_COUNTRY_QUESTIONS,
      payload: Object.assign({} as IAcceptableScoresPayload, {
        courseId,
        countryId: isMasterScores ? "" : countryId,
        studyId: isMasterScores ? "" : studyId,
        body: questions,
        mode,
        isNewCountry
      })
    });
  };

  const renderQuestionsPanel = () => {
    const { mode } = props;

    const showEditButton = mode === "edit";
    return (
      <React.Fragment>
        <BktDataTable
          data={questionsTableData}
          noRecordsMessage="No Available Scores"
          infiniteScrollProps={{
            id: "scoresInfiniteScroll",
            maxItems: 99999,
            containerProps: {
              className: "mdd-edit-course-scores--divScoresInfiniteScroll"
            }
          }}
          columnProps={[
            {
              label: "Question",
              tableCellProps: {
                id: "questionHeader",
                className: "tbl--header-cell"
              },
              divProps: { id: "questionHeader_div" }
            },
            {
              label: "Current Response",
              tableCellProps: {
                id: "currResponseHeader",
                className: "tbl--header-cell"
              },
              divProps: { id: "currResponseHeader_div" },
              formatProp: {
                formatPropName: "acceptableResponse",
                format: (score: IScoreResponse) =>
                  mode === "view" || mode === "edit" ? (
                    <span
                      id={`spanAcceptableResponseValue_${score.questionId}`}
                      data-testid={`spanAcceptableResponseValue_${score.questionId}`}
                    >
                      {score.value}
                    </span>
                  ) : (
                    <span
                      className="mdd-edit-course-scores--questions--textbox"
                      id={`spanAcceptableResponse_${score.questionId}`}
                      data-testid={`spanAcceptableResponse_${score.questionId}`}
                    >
                      <MddValidatedTextField
                        id={`txtAcceptableResponse_${score.questionId}`}
                        textFieldProps={{
                          debounceInterval: 0,
                          textProps: {
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setQuestionsTableData(
                                handleResponseChange(
                                  questionsTableData,
                                  {
                                    value: event.target.value,
                                    questionId: score.questionId
                                  } as IScoreResponse,
                                  "acceptableResponse"
                                )
                              ),
                            value: score.value,
                            id: `txtAcceptableResponse_${score.questionId}_value`,
                            inputProps: {
                              maxLength:
                                MAX_TEXT_LENGTH.COURSE_ACCEPTABLE_RESPONSE
                            }
                          }
                        }}
                        validationForOnBlur={[
                          Validations.required,
                          Validations.validAnswersForCourseScore
                        ]}
                        validationerror={(validationError: boolean) =>
                          setHasQuestionsValidationError(validationError)
                        }
                      />
                    </span>
                  )
              }
            },
            {
              label: "Updated Response",
              tableCellProps: {
                id: "updatedResponseHeader",
                className: "tbl--header-cell"
              },
              divProps: {
                id: "updatedResponseHeader_div"
              },
              formatProp: {
                formatPropName: "updatedResponse",
                format: (score: IScoreResponse) =>
                  mode === "view" || mode === "add" ? (
                    <span
                      id={`spanUpdatedResponse_${score.questionId}`}
                      data-testid={`spanUpdatedResponse_${score.questionId}`}
                    ></span>
                  ) : (
                    <span
                      id={`spanUpdatedResponse_${score.questionId}`}
                      data-testid={`spanUpdatedResponse_${score.questionId}`}
                    >
                      {score.value}
                    </span>
                  )
              }
            },
            {
              label: "Answer Set",
              tableCellProps: {
                id: "answerSetHeader",
                className: "tbl--header-cell"
              },
              divProps: { id: "answerSetHeader_div" },
              formatProp: {
                formatPropName: "answerSet",
                format: (answerSet: IScoreResponse) =>
                  !isMasterScores || mode === "view" || mode === "edit" ? (
                    <span
                      id={`spanAnswerSetValue_${answerSet.questionId}`}
                      data-testid={`spanAnswerSetValue_${answerSet.questionId}`}
                    >
                      {answerSet.value ? answerSet.value : ""}
                    </span>
                  ) : (
                    <span
                      className="mdd-edit-course-scores--questions--textbox"
                      id={`spanAnswerSet_${answerSet.questionId}`}
                      data-testid={`spanAnswerSet_${answerSet.questionId}`}
                    >
                      <MddValidatedTextField
                        id={`txtAnswerSet_${answerSet.questionId}`}
                        textFieldProps={{
                          debounceInterval: 0,
                          textProps: {
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setQuestionsTableData(
                                handleResponseChange(
                                  questionsTableData,
                                  {
                                    value: event.target.value,
                                    questionId: answerSet.questionId
                                  } as IScoreResponse,
                                  "answerSet"
                                )
                              ),
                            value: answerSet.value,
                            id: `txtAnswerSet_${answerSet.questionId}_value`,
                            inputProps: {
                              maxLength: MAX_TEXT_LENGTH.COURSE_ANSWER_SET
                            }
                          }
                        }}
                        validationForOnBlur={[
                          Validations.required,
                          Validations.validAnswerSet
                        ]}
                        validationerror={(validationError: boolean) =>
                          setHasAnswerSetValidationError(validationError)
                        }
                      />
                    </span>
                  )
              }
            },
            {
              label: "",
              tableCellProps: {
                id: "updateButtonHeader"
              },
              divProps: { id: "updateButtonHeader_div" },
              formatProp: {
                formatPropName: "updateButton",
                format: (score: ICourseAcceptableScore) => (
                  <span
                    className={`${
                      showEditButton
                        ? "mdd-edit-course-scores--questions--button"
                        : "displayNone"
                    }`}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      id={`btnUpdate${showEditButton ? "" : "Hidden"}_${
                        score.courseQuestionId
                      }`}
                      data-testid={`btnUpdate${
                        showEditButton ? "" : "Hidden"
                      }_${score.courseQuestionId}`}
                      onClick={() => {
                        setIsUpdateOpen(true);
                        setCurrScore(score);
                      }}
                    >
                      Update
                    </Button>
                  </span>
                )
              }
            }

            //TODO - for post-MVP
            // {
            //   label: "Current Concordance",
            //   tableCellProps: {
            //     id: "currConcordanceHeader",
            //     className: "tbl--header-cell"
            //   },
            //   divProps: { id: "currConcordanceHeader_div" }
            // },
            // {
            //   label: "Updated Concordance",
            //   tableCellProps: {
            //     id: "updatedConcordanceHeader",
            //     className: "tbl--header-cell"
            //   },
            //   divProps: { id: "updatedConcordanceHeader_div" }
            // }
          ]}
        />
        {!questionsTableData.length && (
          <MddNoDataDiv noDataText="No course scores available" />
        )}
      </React.Fragment>
    );
  };

  const handleScoreUpdate = (
    isScoreChanged: boolean,
    updatedScore: IScoreResponse,
    isAnswerSetChanged: boolean,
    updatedAnswerSet: IScoreResponse
  ) => {
    // We got an update on either the score or answer set.  One or both may have changed.
    // The booleans passed in will tell us that.

    // Sanity check. Should never happen but if neither has been updated then
    // just get out.
    if (!isScoreChanged && !isAnswerSetChanged) {
      return;
    }

    setIsResponseUpdated(true);
    const newQuestions = isScoreChanged
      ? handleResponseChange(
          questionsTableData,
          updatedScore,
          "updatedResponse"
        )
      : [...questionsTableData];
    setQuestionsTableData(
      isAnswerSetChanged
        ? handleResponseChange(newQuestions, updatedAnswerSet, "answerSet")
        : [...newQuestions]
    );
  };

  const {
    studyId,
    studyName,
    courseId,
    studyComponentId,
    courseName,
    countryName = "",
    isMasterScores,
    mode
  } = props;

  return (
    <React.Fragment>
      <MddBreadcrumb
        breadcrumbs={[
          { display: "All Studies", route: "/study" },
          {
            display: studyName,
            route: `/study/${studyId}`
          },
          {
            display: "Courses",
            route: `/study/${studyId}/scores`
          },
          {
            display: courseName || "Loading...",
            route: courseName
              ? `/study/${studyId}/scores/${
                  courseId ? courseId : studyComponentId
                }${studyComponentId ? "/legacy" : ""}`
              : ""
          },
          {
            display: isMasterScores
              ? "Master Acceptable Scores"
              : "Modify Acceptable Scores By Country"
          }
        ]}
      />
      <MddPanelContainer
        panels={[
          {
            title: isMasterScores
              ? "Master Acceptable Scores"
              : `Acceptable Scores for ${countryName}`,
            size: "full",
            content: renderQuestionsPanel(),
            className: "mdd-edit-course-scores--top-panel"
          },
          // TODO - these 2 are for post-MVP and just here (hidden) as placeholders
          {
            title: "Question Metrics",
            size: "onethird",
            content: <div></div>,
            className: "mdd-edit-course-scores--panel--hidden"
          },
          {
            title: "Preview Changes",
            size: "full",
            content: <div></div>,
            className: "mdd-edit-course-scores--panel--hidden"
          }
        ]}
      />
      <span className="mdd-edit-course-scores--buttons">
        {mode !== "view" && (
          <MddDialogButtons
            closeButtonProps={{
              size: "large",
              onClick: () => handleCancelClick()
            }}
            saveButtonProps={{
              className: "displayNone",
              size: "large",
              disabled: disableSaveButton(),
              onClick: () => handleSaveClick()
            }}
          />
        )}
        {mode === "view" && (
          <div className="mdd-edit-course-scores--close-button">
            <Button
              variant="contained"
              size="small"
              color="primary"
              id="btnOverride"
              data-testid="btnOverride"
              onClick={() => handleCancelClick()}
            >
              Close
            </Button>
          </div>
        )}
      </span>
      {isUpdateOpen && (
        <MddEditScoresDialog
          open={isUpdateOpen}
          currentScore={currScore}
          isMasterScores={isMasterScores}
          onClose={() => setIsUpdateOpen(false)}
          onUpdate={(
            isScoreChanged: boolean,
            updatedScore: IScoreResponse,
            isAnswerSetChanged: boolean,
            updatedAnswerSet: IScoreResponse
          ) =>
            handleScoreUpdate(
              isScoreChanged,
              updatedScore,
              isAnswerSetChanged,
              updatedAnswerSet
            )
          }
        />
      )}
    </React.Fragment>
  );
};
