export const auth = {
  authority:
    "https://login.microsoftonline.com/2dd5fee6-1dd2-4f6e-9832-147151cb61ee",
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  clientId: "dbc1ef52-14ab-42d2-8932-db0d14efa172",
  redirectUri: "http://localhost:3000",
  resource: "https://graph.microsoft.com/"
};

export const msalConfig = {
  auth: {
    clientId: auth.clientId,
    authority: auth.authority,
    redirectUri: auth.redirectUri
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

// create a request object for login or token request calls
// In scenarios with incremental consent, the request object can be further customized
export const tokenRequest = {
  scopes: ["api://dbc1ef52-14ab-42d2-8932-db0d14efa172/Access_to_mdd-api"]
};
