import { INotificationState, NotificationActionTypes } from "./types";

const initialState: INotificationState = {
  message: ""
};

const reducer: any = (
  state: INotificationState = initialState,
  action: any
) => {
  switch (action.type) {
    case NotificationActionTypes.OPEN_SUCCESS_SNACKBAR: {
      return {
        ...state,
        open: true,
        variant: "success",
        message: action.payload
      };
    }
    case NotificationActionTypes.OPEN_ERROR_SNACKBAR: {
      return {
        ...state,
        open: true,
        variant: "error",
        message: action.payload
      };
    }
    case NotificationActionTypes.OPEN_WARNING_SNACKBAR: {
      return {
        ...state,
        open: true,
        variant: "warning",
        message: action.payload
      };
    }
    case NotificationActionTypes.OPEN_INFO_SNACKBAR: {
      return { ...state, open: true, variant: "info", message: action.payload };
    }
    case NotificationActionTypes.CLOSE_SNACKBAR: {
      return { ...state, open: false, message: "" };
    }
    default: {
      return state;
    }
  }
};

export { reducer as notificationReducer };
