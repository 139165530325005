import { ISponsorProgram } from "../../model";

export class SponsorProgram implements ISponsorProgram {
  private sponsorId: string;
  private id: string;
  private programName: string;
  private internalProgramName: string;

  get SponsorId() {
    return this.sponsorId;
  }
  set SponsorId(val) {
    this.sponsorId = val;
  }

  get Id() {
    return this.id;
  }
  set Id(val) {
    this.id = val;
  }

  get ProgramName() {
    return this.programName;
  }
  set ProgramName(val) {
    this.programName = val;
  }

  get InternalProgramName() {
    return this.internalProgramName;
  }
  set InternalProgramName(val) {
    this.internalProgramName = val;
  }
}
