import { store } from "../../utils";
import { openErrorSnackBar } from "../../store";

export class NotificationError extends Error {
  /**
   * Initialize an error that will notify the user.
   * @param {string} message Message used to handle internal logic
   * @param {string} extMessage Message used to handle external logic. If not supplied, user will not be notified.
   *
   * This should only be used when the store has been initialized. If the store has not been initialized, the external logic will not be executed.
   */
  constructor(message?: string | undefined, extMessage?: string | undefined) {
    super(message);
    if (extMessage && store) {
      store.dispatch(openErrorSnackBar(extMessage));
    }
  }
}
