import { mdiAccountCardDetails } from "@mdi/js";
import { MenuListItem, IMenuList } from "../../../model";

export enum personMenuItemCategories {
  GeneralInfo = "General",
  ExperienceHistory = "Experience History",
  QualificationHistory = "Qualification History"
}

export class PersonMenuContainer implements IMenuList {
  // This will be the interface structure used for the component to make it generic
  public root: string;
  public data: any;
  public enabled: boolean;
  public menuItems: MenuListItem[];
  public rootUrl: string;
  public constructor() {
    this.root = "Personnel";
    this.rootUrl = "/";
    this.menuItems = this.getMenu();
  }
  public getMenu() {
    const menuList = [
      new MenuListItem(
        "General Info",
        mdiAccountCardDetails,
        "/personnel/:id",
        personMenuItemCategories.GeneralInfo
      )
      //TODO - leaving in for use in later stories for these items
      //   new MenuListItem(
      //     "Experience History",
      //     mdiAccountMultiple, // TODO - need appropiate icon
      //     "/study/:id/sites/:siteId/personnel", //TODO - need new link
      //     personMenuItemCategories.ExperienceHistory
      //   ),
      //   new MenuListItem(
      //     "Qualification History",
      //     mdiPlaylistCheck, // TODO - need appropiate icon
      //     "/study/:id/sites/:siteId/personnel", //TODO - need new link
      //     personMenuItemCategories.QualificationHistory
      //   )
    ];
    return menuList;
  }
}
