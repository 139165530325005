import { connect, ConnectedComponentClass } from "react-redux";
import { IApplicationState } from "../../../store";

export function withMddBase<T>(
  WrappedComponent: any
): ConnectedComponentClass<(props: T) => JSX.Element, any> {
  return connect((state: IApplicationState) => {
    return { loadingKeys: state.util.loadingKeys };
  })(WrappedComponent);
}
