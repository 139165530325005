import { MDDHTTPService } from "../../services";

export enum eUtilActionTypes {
  SET_LOADING = "@@util/SET_LOADING"
}

export interface IUtilState {
  loadingKeys: string[];
  mddHTTPService: MDDHTTPService;
  studyApiHTTPService: MDDHTTPService;
}
