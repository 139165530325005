import React from "react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { store } from "./utils";
import { RestoreOriginalUriFunction } from "@okta/okta-react/bundles/types/OktaContext";
import { Main } from "Main";
import { Route, Switch } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import { ConfigOptions } from "config";
import { INACTIVITY_TIMEOUT } from "../src/constants";
import { LoginCallbackError } from "components/LoginCallbackError/LoginCallbackError";

const oktaAuth = new OktaAuth({
  clientId: ConfigOptions.oktaClientId,
  issuer: `${ConfigOptions.oktaIssuer}/${ConfigOptions.oktaAuthServerId}`,
  redirectUri: ConfigOptions.oktaRedirectUri,
  postLogoutRedirectUri: ConfigOptions.oktaLoginUrl,
  scopes: ["openid", "profile", "email", "offline_access"]
});

const App = () => {
  const restoreOriginalUri: RestoreOriginalUriFunction = (
    _oktaAuth,
    originalUri
  ) => {
    window.location.replace(
      `${toRelativeUrl(originalUri || "/", window.location.origin)}`
    );
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route
          path="/login/callback"
          component={() => (
            <LoginCallback errorComponent={LoginCallbackError} />
          )}
        />
        <Main store={store} keepAlive={async () => INACTIVITY_TIMEOUT * 60} />
      </Switch>
    </Security>
  );
};
export default App;
