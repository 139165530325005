import { Study } from "../../model/classes/study";
import { IStudyState, eStudyActionTypes } from "./types";
import { IStudySitePerson } from "../../model";

const initialState: IStudyState = {
  currentStudy: new Study(),
  currentStudySitePerson: Object.assign({} as IStudySitePerson)
};

export const studyReducer: any = (
  state: IStudyState = initialState,
  action: any
) => {
  switch (action.type) {
    case eStudyActionTypes.SET_STUDY: {
      return {
        ...state,
        ...{
          currentStudy: Object.assign(
            new Study(),
            state.currentStudy,
            action.payload
          )
        }
      };
    }
    case eStudyActionTypes.SET_STUDY_SITE_PERSON: {
      return {
        ...state,
        ...{
          currentStudySitePerson: Object.assign(
            {} as IStudySitePerson,
            state.currentStudySitePerson,
            action.payload
          )
        }
      };
    }
    case eStudyActionTypes.SET_STUDY_RES_CONFIG: {
      return {
        ...state,
        ...{
          currentStudy: Object.assign(new Study(), state.currentStudy, {
            ResType: action.payload
          })
        }
      };
    }
    default: {
      return state;
    }
  }
};
