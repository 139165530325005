export const get = <T>(name: string) => {
  const objStr = window.localStorage.getItem(name);
  return objStr ? (JSON.parse(objStr) as T) : null;
};

export const set = (name: string, value?: object | null): void => {
  if (value) {
    window.localStorage.setItem(name, JSON.stringify(value));
  } else {
    window.localStorage.removeItem(name);
  }
};
