// Passthrough to the callApi method in the HTTP service since the services that need to
// call it don't have access to the state
import { eApiNames, eHTTPMethods } from "../constants";
import { store } from "./";

export const callApi = (
  method:
    | eHTTPMethods.GET
    | eHTTPMethods.POST
    | eHTTPMethods.DELETE
    | eHTTPMethods.PUT,
  path: string,
  data?: any,
  extMessage?: string,
  api?: eApiNames
) =>
  store
    .getState()
    .util[api || eApiNames.MDD_API].callApi(method, path, data, extMessage);
