import * as React from "react";
import {
  BktValidatedTextField,
  BktSaga,
  BktSelect,
  getKeysFromState,
  SagaService,
  IAction
} from "front-end-lib/core";
import { isEmpty, isEqual } from "lodash";
import Button from "@material-ui/core/Button";
import {
  Site,
  SiteDTO,
  Validations,
  IMatch,
  ISite,
  ILookup
} from "../../../model";
import { withMddLookups } from "../../../components";
import { BktBreadcrumb, MddForm } from "../../../components";
import { eLookupSaga, eSiteSaga } from "../../../sagas";
import { cloneAssign, isLoading } from "../../../utils";
import { ConfigOptions } from "../../../config";
import { MAX_TEXT_LENGTH } from "../../../constants";

interface IStateProps {
  site: ISite;
  newSite: ISite;
  statesList: ILookup[];
  loading: boolean;
  siteNameHasError: boolean;
  cityHasError: boolean;
  zipCodeHasError: boolean;
  phoneNumberHasError: boolean;
  disableState: boolean;
  helperText: string;
  isError: boolean;
}

interface IBktAddEditSiteProps {
  match: IMatch<{
    id: string;
  }>;
  countries: ILookup[];
}

class BktAddEditSiteComponent extends BktSaga<
  IBktAddEditSiteProps,
  IStateProps,
  {}
> {
  constructor(props: IBktAddEditSiteProps) {
    super(props);
    this.state = {
      site: new Site(),
      newSite: new Site(),
      statesList: [],
      loading: false,
      siteNameHasError: false,
      cityHasError: false,
      zipCodeHasError: false,
      phoneNumberHasError: false,
      disableState: false,
      helperText: "",
      isError: false
    };
    const saveSiteCallback = (action: IAction) => {
      const { type, payload } = action;
      if (type === eSiteSaga.SAVE_SITE_SUCCESS) {
        this.setState(
          payload instanceof Site
            ? { site: payload, newSite: payload }
            : { site: this.state.newSite, newSite: this.state.newSite }
        );
      }
    };

    this.configureSaga(
      new Map([
        [
          eLookupSaga.FETCH_STATES.toString(),
          {
            stateKey: getKeysFromState(this.state, ["statesList"]),
            validationType: eLookupSaga.FETCH_STATES_SUCCESS
          }
        ],
        [
          eSiteSaga.FETCH_SITE.toString(),
          { stateKey: getKeysFromState(this.state, ["site", "newSite"]) }
        ],
        [eSiteSaga.SAVE_SITE.toString(), { callback: saveSiteCallback }]
      ]),
      [{ type: eLookupSaga.FETCH_STATES.toString() }]
    );
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      SagaService.dispatchSaga({ type: eSiteSaga.FETCH_SITE, payload: id });
    }
    SagaService.dispatchSaga({ type: eLookupSaga.FETCH_STATES });
  }

  public render() {
    const { newSite, statesList } = this.state;
    const { countries } = this.props;
    let enableState = newSite.CountryValue === ConfigOptions.US_COUNTRY_CODE;
    return (
      <div className="mdd-main-div">
        <BktBreadcrumb
          rootText="All Sites"
          rootUrl="/site"
          currentLocation={this.getCurrentLocation()}
        />
        <MddForm
          keys={[
            `undefined_${eSiteSaga.SAVE_SITE}`,
            `HOCLookup_${eLookupSaga.FETCH_COUNTRIES}`,
            `_${eLookupSaga.FETCH_STATES}`
          ]}
        >
          <div className="mdd-form--body">
            <BktValidatedTextField
              validationForOnChange={[Validations.required]}
              validationForOnBlur={[Validations.required]}
              validationerror={(validationError: boolean) =>
                this.setState({ siteNameHasError: validationError })
              }
              textFieldProps={{
                textProps: {
                  id: "siteName",
                  className: "mdd-form--text",
                  label: "Site Name",
                  placeholder: "Site Name",
                  inputProps: { maxLength: MAX_TEXT_LENGTH.SITE_NAME },
                  variant: "standard",
                  required: true,
                  onChange: (event: any) =>
                    this.setState({
                      newSite: cloneAssign(this.state.newSite, {
                        SiteName: event.target.value
                      })
                    }),
                  type: "text",
                  value: newSite.SiteName || ""
                }
              }}
            />
            {newSite.SiteId && (
              <BktValidatedTextField
                textFieldProps={{
                  textProps: {
                    id: "siteId",
                    className: "mdd-form--text",
                    disabled: true,
                    label: "Site Id",
                    placeholder: "Site Id",
                    variant: "standard",
                    type: "text",
                    value: newSite.SiteNumber || ""
                  }
                }}
              />
            )}
            <BktValidatedTextField
              textFieldProps={{
                textProps: {
                  id: "siteAddress1",
                  className: "mdd-form--text",
                  label: "Address 1",
                  placeholder: "Address 1",
                  inputProps: { maxLength: MAX_TEXT_LENGTH.ADDRESS1 },
                  type: "text",
                  variant: "standard",
                  value: newSite.Address1 || "",
                  onChange: (event: any) =>
                    this.setState({
                      newSite: cloneAssign(this.state.newSite, {
                        Address1: event.target.value
                      })
                    })
                }
              }}
            />
            <BktValidatedTextField
              textFieldProps={{
                textProps: {
                  id: "siteAddress2",
                  className: "mdd-form--text",
                  label: "Address 2",
                  placeholder: "Address 2",
                  inputProps: { maxLength: MAX_TEXT_LENGTH.ADDRESS2 },
                  type: "text",
                  variant: "standard",
                  value: newSite.Address2 || "",
                  onChange: (event: any) =>
                    this.setState({
                      newSite: cloneAssign(this.state.newSite, {
                        Address2: event.target.value
                      })
                    })
                }
              }}
            />
            <BktValidatedTextField
              textFieldProps={{
                textProps: {
                  id: "city",
                  className: "mdd-form--text",
                  label: "City",
                  placeholder: "City",
                  inputProps: { maxLength: MAX_TEXT_LENGTH.CITY },
                  type: "text",
                  variant: "standard",
                  value: newSite.City || "",
                  onChange: (event: any) =>
                    this.setState({
                      newSite: cloneAssign(this.state.newSite, {
                        City: event.target.value
                      })
                    })
                }
              }}
            />
            <BktSelect
              menuProps={{}}
              textProps={{
                id: "bktSelectFieldCountry",
                label: "Country",
                placeholder: "Country",
                value: newSite.CountryValue || "",
                className: "mdd-form--text",
                onChange: event => {
                  this.setState({
                    newSite: cloneAssign(this.state.newSite, {
                      CountryValue: event.target.value,
                      StateId: ""
                    }),
                    isError: false,
                    helperText: ""
                  });
                },
                onBlur: event => {
                  if (isEmpty(event.target.value)) {
                    this.setState({
                      isError: true,
                      helperText: ConfigOptions.REQUIRED_HELPER_TEXT
                    });
                  }
                },
                required: true,
                error: this.state.isError,
                helperText: this.state.helperText
              }}
              selectData={countries}
            />
            <BktSelect
              menuProps={{}}
              textProps={{
                id: "bktSelectFieldState",
                label: "State",
                placeholder: "State",
                value: enableState ? this.state.newSite.StateId : "",
                className: "mdd-form--text",
                onChange: event =>
                  this.setState({
                    newSite: cloneAssign(this.state.newSite, {
                      StateId: event.target.value
                    })
                  }),
                disabled: !enableState
              }}
              defaultSelect={true}
              selectData={statesList}
            />
            <BktValidatedTextField
              validationForOnChange={[Validations.zipCodeAllowedCharacters]}
              validationForOnBlur={[Validations.zipCodeAllowedCharacters]}
              validationerror={(validationError: boolean) =>
                this.setState({ zipCodeHasError: validationError })
              }
              textFieldProps={{
                textProps: {
                  id: "zipCode",
                  className: "mdd-form--text",
                  label: "Zip Code",
                  placeholder: "Zip Code",
                  inputProps: { maxLength: MAX_TEXT_LENGTH.ZIP_CODE },
                  variant: "standard",
                  type: "text",
                  value: newSite.Zip || "",
                  onChange: (event: any) =>
                    this.setState({
                      newSite: cloneAssign(this.state.newSite, {
                        Zip: event.target.value
                      })
                    })
                }
              }}
            />
            <BktValidatedTextField
              validationForOnChange={[Validations.phoneNumberAllowedCharacters]}
              validationForOnBlur={[Validations.phoneNumberAllowedCharacters]}
              validationerror={(validationError: boolean) =>
                this.setState({ phoneNumberHasError: validationError })
              }
              textFieldProps={{
                textProps: {
                  id: "phoneNumber",
                  className: "mdd-form--text",
                  label: "Phone",
                  placeholder: "Phone",
                  inputProps: { maxLength: MAX_TEXT_LENGTH.PHONE_NUMBER },
                  type: "text",
                  variant: "standard",
                  value: newSite.Phone || "",
                  onChange: (event: any) =>
                    this.setState({
                      newSite: cloneAssign(this.state.newSite, {
                        Phone: event.target.value
                      })
                    })
                }
              }}
            />

            <div className="mdd-form__button--container">
              <Button
                id="saveButtonId"
                data-testid="saveButton"
                color="primary"
                disableFocusRipple={true}
                disableRipple={true}
                variant="contained"
                type="submit"
                className="mdd-form__button"
                onClick={this.handleSubmit}
                disabled={this.disableSaveButton()}
              >
                SAVE
              </Button>
            </div>
          </div>
        </MddForm>
      </div>
    );
  }

  private getCurrentLocation = () => {
    const { SiteId } = this.state.newSite;
    const { SiteName, SiteNumber } = this.state.site;
    return SiteId ? `${SiteName} (${SiteNumber})` : "Add New";
  };

  private disableSaveButton = () => {
    const {
      loading,
      site,
      newSite,
      newSite: { SiteName },
      newSite: { CountryValue },
      siteNameHasError,
      cityHasError,
      phoneNumberHasError,
      zipCodeHasError
    } = this.state;

    return (
      loading ||
      !SiteName ||
      !CountryValue ||
      siteNameHasError ||
      cityHasError ||
      phoneNumberHasError ||
      zipCodeHasError ||
      isEqual(site, newSite) ||
      isLoading(`undefined_${eSiteSaga.SAVE_SITE}`)
    );
  };

  private handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!this.disableSaveButton()) {
      const { newSite } = this.state;
      const {
        SiteId,
        SiteName,
        CountryValue,
        Country,
        State,
        Address1,
        Address2,
        City,
        Zip,
        Phone
      } = newSite;
      let StateId = newSite.StateId;
      if (StateId === ConfigOptions.EMPTY_GUID) {
        StateId = "";
      }
      const payload = new SiteDTO(
        SiteName ? SiteName.trim() : "",
        CountryValue,
        Country,
        Address1 ? Address1.trim() : "",
        Address2 ? Address2.trim() : "",
        City ? City.trim() : "",
        StateId,
        State,
        Zip ? Zip.trim() : "",
        Phone ? Phone.trim() : "",
        SiteId
      );
      SagaService.dispatchSaga({ type: eSiteSaga.SAVE_SITE, payload });
    }
  };
}

export const BktAddEditSite = withMddLookups(["countries"])(
  BktAddEditSiteComponent
);
