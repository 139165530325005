import * as React from "react";
import { IResVersionListItem } from "../../../model/interfaces/iResVersionListItem";
import { toDateFormatNoOffset } from "../../../utils";

interface IMddResViewMetadataProps {
  resMetadata: IResVersionListItem;
}

export const MddResViewMetadata = ({
  resMetadata
}: IMddResViewMetadataProps) => {
  return (
    <div className="metadata">
      <div className="item">
        <span className="header">Version:</span>
        {resMetadata.version}
      </div>
      <div className="item">
        <span className="header">Last Updated By:</span>
        {resMetadata.lastUpdateUser}
      </div>
      <div className="item">
        <span className="header">Published Date:</span>
        {toDateFormatNoOffset(resMetadata.publishedDate)}
      </div>
      <div className="item">
        <span className="header">Status:</span>
        {resMetadata.status}
      </div>
      <div className="item">
        <span className="header">Last Update Date:</span>
        {toDateFormatNoOffset(resMetadata.lastUpdateDate)}
      </div>
      <div className="item">
        <span className="header">Archived Date:</span>
        {toDateFormatNoOffset(resMetadata.archivedDate)}
      </div>
    </div>
  );
};
