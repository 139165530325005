import { Person } from "../../../model/classes";
import { IPersonDTO } from "../../../model/interfaces";

export class PersonDTO extends Person implements IPersonDTO {
  private size: number;
  private page: number;
  private studyId: string;
  private siteId: string;
  private personnelId: string;

  get Size() {
    return this.size;
  }
  set Size(val) {
    this.size = val;
  }
  get Page() {
    return this.page;
  }
  set Page(val) {
    this.page = val;
  }

  get StudyId() {
    return this.studyId;
  }
  set StudyId(val) {
    this.studyId = val;
  }

  get SiteId() {
    return this.siteId;
  }
  set SiteId(val) {
    this.siteId = val;
  }

  get PersonnelId() {
    return this.personnelId;
  }
  set PersonnelId(val) {
    this.personnelId = val;
  }
}
