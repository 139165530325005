import { IStudyQualification, ILearningPlan } from "../interfaces";
import { QUALIFICATION_TYPE } from "../../constants/applicationConstants";

export class StudyQualification implements IStudyQualification {
  private studyQualificationId: string;
  private scaleId?: string;
  private studyId: string;
  private scaleName: string;
  private qualificationName: string;
  private learningPlans: ILearningPlan[];
  private legacyLearningPlans: ILearningPlan[];
  private isActive: boolean;
  private qualificationType: string;
  private isExperienceRequired: boolean;
  private canDelete: boolean;

  constructor(
    studyQualificationId?: string,
    scaleId?: string,
    studyId?: string,
    scaleName?: string,
    qualificationName?: string,
    learningPlans?: ILearningPlan[],
    legacyLearningPlans?: ILearningPlan[],
    isActive?: boolean,
    qualificationType?: string,
    isExperienceRequired?: boolean,
    canDelete?: boolean
  ) {
    this.studyQualificationId = studyQualificationId || "";
    this.scaleId = scaleId || "";
    this.studyId = studyId || "";
    this.scaleName = scaleName || "";
    this.qualificationName = qualificationName || "";
    this.learningPlans = learningPlans || [];
    this.legacyLearningPlans = legacyLearningPlans || [];
    this.isActive = isActive || true;
    this.qualificationType = qualificationType || QUALIFICATION_TYPE.TRAINING;
    this.isExperienceRequired = isExperienceRequired || false;
    this.canDelete = canDelete || true;
  }

  get StudyQualificationId() {
    return this.studyQualificationId;
  }
  set StudyQualificationId(val: string) {
    this.studyQualificationId = val;
  }

  get StudyId() {
    return this.studyId;
  }
  set StudyId(val: string) {
    this.studyId = val;
  }

  get ScaleId() {
    return this.scaleId || "";
  }
  set ScaleId(val: string) {
    this.scaleId = val;
  }

  get ScaleName() {
    return this.scaleName;
  }
  set ScaleName(val: string) {
    this.scaleName = val;
  }

  get QualificationName() {
    return this.qualificationName;
  }
  set QualificationName(val: string) {
    this.qualificationName = val;
  }

  get LearningPlans() {
    return this.learningPlans;
  }
  set LearningPlans(plans: ILearningPlan[]) {
    this.learningPlans = plans;
  }

  get LegacyLearningPlans() {
    return this.legacyLearningPlans;
  }
  set LegacyLearningPlans(plans: ILearningPlan[]) {
    this.legacyLearningPlans = plans;
  }

  get IsActive() {
    return this.isActive;
  }
  set IsActive(val: boolean) {
    this.isActive = val;
  }

  get QualificationType() {
    return this.qualificationType;
  }
  set QualificationType(val: string) {
    this.qualificationType = val;
  }

  get IsExperienceRequired() {
    return this.isExperienceRequired;
  }
  set IsExperienceRequired(val: boolean) {
    this.isExperienceRequired = val;
  }

  get CanDelete() {
    return this.canDelete;
  }
  set CanDelete(val: boolean) {
    this.canDelete = val;
  }
}
