import { eFilter, Filter, SortType } from "front-end-lib/core";
import { classConstants } from "../../constants";
import { toDateFormat } from "../../utils/functionsHelper";

export const siteCommentsDataTableProps = {
  containerProps: {
    id: "tableWrapper",
    className: "tbl--container",
    style: { boxShadow: "0px 0px 0px 0px" }
  },
  tableProps: { id: "tableContainer" },
  tableHeadProps: { id: "tableHeader", rowProps: { id: "tableHeaderRow" } },
  tableBodyProps: { id: "tableBody", rowProps: { id: "tableBodyRow" } }
};

export const siteCommentsDatatableHeaders = (
  filterOnChange:
    | ((
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => void)
    | undefined
) => [
  {
    key: "updatedBy",
    label: "Username",
    sortProp: { sortPropName: "UpdatedBy", desc: false },
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "updatedByFilter",
        onChange: filterOnChange,
        name: "updatedBy"
      }
    }),
    tableCellProps: { id: "updatedByHeader" },
    divProps: { id: "updatedByHeader_div" }
  },
  {
    key: "dateCreated",
    label: "Date Created",
    sortProp: {
      sortPropName: "UpdatedDt",
      desc: true,
      sortType: "Date" as SortType
    },
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "dateCreatedFilter",
        onChange: filterOnChange,
        name: "dateCreated"
      }
    }),
    tableCellProps: { id: "dateCreatedHeader" },
    divProps: { id: "dateCreatedHeader_div" },
    formatProp: {
      formatPropName: "UpdatedDt",
      format: (val: string) => toDateFormat(val)
    }
  },
  {
    key: "comment",
    label: "Comment",
    tableCellProps: {
      id: "editHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "editHeader_div" }
  },
  {
    key: "editComment",
    label: "",
    tableCellProps: {
      id: "editHeaderComment",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "editHeaderComment_div" }
  }
];
