import { Auditable } from ".";
import { IClinicalData } from "../../model";

export class ClinicalData extends Auditable implements IClinicalData {
  private studyId: string;
  private therapeuticArea: [];
  private indications: [];
  private specialties: [];
  private demographics: [];

  get Demographics() {
    return this.demographics || [];
  }
  get Specialties() {
    return this.specialties || [];
  }
  get Indications() {
    return this.indications || [];
  }
  get TherapeuticArea() {
    return this.therapeuticArea || [];
  }
  get StudyId() {
    return this.studyId;
  }
}
