import React from "react";
import { ePersonnelSaga } from "../../../sagas";
import { MddExpansionPanel, MddProgress } from "../../common";
import { isLoading, runSagaAsync } from "../../../utils";
import { IPersonnelCpResExperience } from "../../../model/interfaces";
import { MddListPanelContent } from "./MddListPanelContent";
import MddResAssignmentStatus from "./MddResAssignmentStatus";
import { MddEducationPanelContent } from "./MddEducationPanelContent";
import { MddDataTablePanelContent } from "./MddDataTablePanelContent";
import {
  generalDataTableHeaders,
  getDataTableGeneral
} from "../../../config/dataTable/personnelCPExperience/generalDataTableConfig";
import {
  getDataTableIndications,
  indicationDataTableHeaders
} from "../../../config/dataTable/personnelCPExperience/indicationDataTableConfig";
import { MddScaleExperiencePanelContent } from "./MddScaleExperiencePanelContent";

const enum resSections {
  Demographics = 0,
  Education = 1,
  General = 2,
  Indications = 3,
  Scales = 4,
  Count = 5
}

interface IProps {
  studyId: string;
  personnelId: string;
  status: {
    latestAssignmentDate?: string | null;
    latestAnsweredDate?: string | null;
  };
}

export const MddCpExperience = ({ studyId, personnelId, status }: IProps) => {
  const [expanded, setExpanded] = React.useState(
    Array(resSections.Count).fill(true)
  );

  const [experience, setExperience] = React.useState<
    IPersonnelCpResExperience
  >();

  React.useEffect(() => {
    (async () => {
      const experience = await runSagaAsync<IPersonnelCpResExperience>({
        startType: ePersonnelSaga.GET_PERSONNEL_EXPERIENCE,
        successType: ePersonnelSaga.GET_PERSONNEL_EXPERIENCE_SUCCESS,
        payload: { studyId, personnelId }
      });

      setExperience(experience);
    })();
  }, [studyId, personnelId]);

  const handleExpandAll = () => {
    setExpanded(Array(resSections.Count).fill(true));
  };
  const handleCollapseAll = () => {
    setExpanded(Array(resSections.Count).fill(false));
  };

  const togglePanel = (isExpanded: boolean, panel: number) => {
    const currExpanded = [...expanded];
    currExpanded[panel] = isExpanded;
    setExpanded(currExpanded);
  };

  const showSpinner = isLoading(
    `undefined_${ePersonnelSaga.GET_PERSONNEL_EXPERIENCE}`
  );

  const isFullyCollapsed = expanded.every(s => !s);
  const isFullyExpanded = expanded.every(s => s);

  return (
    <div className="mdd-experience--container">
      <MddProgress inProgress={showSpinner} spinnerId="svgLoading" />
      <div className="mdd-experience--expand-collapse">
        <MddResAssignmentStatus
          assignmentDate={status?.latestAssignmentDate}
          completedDate={status?.latestAnsweredDate}
        />
        <div
          id="expandCollapseDiv"
          data-testid="expandCollapseDiv"
          className="mdd-experience--expand-collapse-div"
        >
          <span
            className={
              isFullyExpanded ? "mdd-experience--expand-collapse-disabled" : ""
            }
            data-testid={
              isFullyExpanded ? "expandAllDisabled" : "expandAllEnabled"
            }
            id="txtExpandAll"
            onClick={() => handleExpandAll()}
          >
            Expand All
          </span>
          <span>|</span>
          <span
            className={
              isFullyCollapsed ? "mdd-experience--expand-collapse-disabled" : ""
            }
            data-testid={
              isFullyCollapsed ? "collapseAllDisabled" : "collapseAllEnabled"
            }
            id="txtCollapseAll"
            onClick={() => handleCollapseAll()}
          >
            Collapse All
          </span>
        </div>
      </div>
      {/* flex container for the two main side by side divs */}
      <div className="mdd-experience--flex-container">
        {/* 1st of the main side by side divs */}
        <div className="mdd-experience--flex-container--child-left">
          {/* flex column container */}
          <div className="mdd-experience--flex-container--column">
            {/* flex item 1 */}
            <div
              id="panelDemographics"
              data-testid="panelDemographics"
              className="mdd-experience--panel"
            >
              <MddExpansionPanel
                expanded={expanded[resSections.Demographics]}
                id="panelDemographics"
                data-testid="panelDemographics"
                headerText={"Demographics"}
                onChange={(event, expanded) =>
                  togglePanel(expanded, resSections.Demographics)
                }
              >
                <MddListPanelContent data={experience?.general} />
              </MddExpansionPanel>
            </div>
          </div>
        </div>

        {/* 2nd of the main side by side divs */}
        <div className="mdd-experience--flex-container--child-right">
          {/* flex column container */}
          <div className="mdd-experience--flex-container--column">
            {/* flex item 1 */}
            <div className="mdd-experience--panel">
              <MddExpansionPanel
                expanded={expanded[resSections.Education]}
                id="panelEducation"
                data-testid="panelEducation"
                headerText="Education"
                onChange={(event, expanded) =>
                  togglePanel(expanded, resSections.Education)
                }
              >
                <MddEducationPanelContent
                  data={experience?.education}
                  displayQuestions={["Board certified", "Licensed"]}
                />
              </MddExpansionPanel>
            </div>
          </div>
        </div>
      </div>
      <div className="mdd-experience--right-justify-italic">
        * Currently inactive for this study
      </div>
      <div className="mdd-experience--flex-container">
        <div className="mdd-experience--flex-container--child-fullwidth mdd-experience--flex-container--child-bottom-spacing">
          <MddExpansionPanel
            expanded={expanded[resSections.General]}
            id="panelGeneral"
            data-testid="panelGeneral"
            headerText="Experience (General)"
            onChange={(event, expanded) =>
              togglePanel(expanded, resSections.General)
            }
          >
            <MddDataTablePanelContent
              data={experience?.experienceGeneral[0]}
              headers={generalDataTableHeaders}
              getDataTableAnswers={getDataTableGeneral}
            />
          </MddExpansionPanel>
        </div>

        <div className="mdd-experience--flex-container--child-fullwidth mdd-experience--flex-container--child-bottom-spacing">
          <MddExpansionPanel
            expanded={expanded[resSections.Indications]}
            id="panelIndication"
            data-testid="panelIndication"
            headerText="Experience (Indication)"
            onChange={(event, expanded) =>
              togglePanel(expanded, resSections.Indications)
            }
          >
            <MddDataTablePanelContent
              data={experience?.experienceIndications[0]}
              headers={indicationDataTableHeaders}
              getDataTableAnswers={getDataTableIndications}
            />
          </MddExpansionPanel>
        </div>

        <div className="mdd-experience--flex-container--child-fullwidth mdd-experience--flex-container--child-bottom-spacing">
          <MddExpansionPanel
            expanded={expanded[resSections.Scales]}
            id="panelScales"
            data-testid="panelScales"
            headerText="Scale Experience"
            onChange={(event, expanded) =>
              togglePanel(expanded, resSections.Scales)
            }
          >
            <MddScaleExperiencePanelContent
              studyId={studyId}
              personnelId={personnelId}
              scaleExperience={experience?.experienceScales[0]}
            />
          </MddExpansionPanel>
        </div>
      </div>
    </div>
  );
};
