import { call, put, takeLatest } from "redux-saga/effects";
import { SagaService, IAction } from "front-end-lib/core";
import { Scale } from "../model";
import { store } from "../utils";
import { eUtilSaga } from "../sagas";
import { initSaga } from "../utils/sagaHelper";
import { ScaleService } from "../services";
import { openSuccessSnackBar } from "../store";
import { history, overrideSaga } from "../utils";

export enum eScaleSaga {
  FETCH_SCALES = "@@scale/FETCH_SCALES",
  FETCH_SCALES_SUCCESS = "@@scale/FETCH_SCALES_SUCCESS",
  WATCH_SCALES = "@@scale/WATCH_SCALES",
  FETCH_SCALE = "@@scale/FETCH_SCALE",
  FETCH_SCALE_SUCCESS = "@@scale/FETCH_SCALE_SUCCESS",
  WATCH_SCALE = "@@scale/WATCH_SCALE",
  MODIFY_SCALE = "@@scale/MODIFY_SCALE",
  MODIFY_SCALE_SUCCESS = "@@scale/MODIFY_SCALE_SUCCESS",
  WATCH_MODIFY_SCALE = "@@scale/WATCH_MODIFY_SCALE",
  FETCH_MASTER_SCALES = "@@scale/FETCH_MASTER_SCALES",
  FETCH_MASTER_SCALES_SUCCESS = "@@scale/FETCH_MASTER_SCALES_SUCCESS",
  WATCH_MASTER_SCALES = "@@scale/WATCH_MASTER_SCALES"
}

initSaga(
  function* fetchScalgesSaga(action: IAction) {
    try {
      yield SagaService.dispatchSaga({
        type: eUtilSaga.LOADING,
        payload: true
      });
      const scales = yield call(ScaleService.getScales, action.payload);
      yield put({ type: eScaleSaga.FETCH_SCALES_SUCCESS, payload: scales });
    } catch (ex) {
      console.log(ex);
    } finally {
      yield SagaService.dispatchSaga({
        type: eUtilSaga.LOADING,
        payload: false
      });
    }
  },
  eScaleSaga.FETCH_SCALES,
  eScaleSaga.WATCH_SCALES
);

initSaga(
  function* fetchMasterScales(action: IAction) {
    const masterScales = yield call(
      ScaleService.fetchMasterScales,
      action.payload
    );
    yield put({
      type: eScaleSaga.FETCH_MASTER_SCALES_SUCCESS,
      payload: masterScales
    });
  },
  eScaleSaga.FETCH_MASTER_SCALES,
  eScaleSaga.WATCH_MASTER_SCALES
);

initSaga(
  function* fetchScaleSaga(action: IAction) {
    const scale = yield call(ScaleService.getScale, action.payload);
    yield put({ type: eScaleSaga.FETCH_SCALE_SUCCESS, payload: scale });
  },
  eScaleSaga.FETCH_SCALE,
  eScaleSaga.WATCH_SCALE
);

export function* modifyScaleSaga(action: IAction) {
  try {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: true });
    const scale = yield call(ScaleService.modifyScale, action.payload);
    yield put({ type: eScaleSaga.MODIFY_SCALE_SUCCESS, payload: scale });
    yield history.push(`/scale`);
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  } catch (e) {
    console.log(e);
  } finally {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: false });
  }
}

export function* watchModifyScaleSagas() {
  yield takeLatest(eScaleSaga.MODIFY_SCALE.toString(), modifyScaleSaga);
}

export const overrideScaleSagas = () => {
  overrideSaga(
    function* dispatchScales(action: IAction) {
      if (action.payload) {
        yield put({
          type: eScaleSaga.FETCH_SCALES_SUCCESS,
          payload: [
            Object.assign(new Scale(), {
              id: "1",
              name: "TestScale1",
              shortName: "TestScale1",
              masterScale: {
                id: "1",
                name: "TestMasterScale1",
                shortName: "TestMasterScale1"
              }
            }),
            Object.assign(new Scale(), {
              id: "2",
              name: "TestScale2",
              shortName: "TestScale2",
              masterScale: {
                id: "1",
                name: "TestMasterScale1",
                shortName: "TestMasterScale1"
              }
            })
          ]
        });
      }
    },
    eScaleSaga.FETCH_SCALES,
    eScaleSaga.WATCH_SCALES
  );
};
