import {
  IEditItem,
  IState,
  IServerState,
  ILocalStorageState,
  IExperienceItemWithChild
} from "../../store/resConfig/types";

export const convertToUIState = (
  {
    version,
    questionTypes: {
      general,
      education,
      demographics,
      indications,
      scales,
      experience
    }
  }: IServerState,
  currentLsState: ILocalStorageState
): IState => ({
  version,
  general: { fields: general.groups, items: [], edit: [] },
  education: { fields: education.groups, items: [], edit: [] },
  experience: { fields: experience.groups, items: [], edit: [] },
  demographics: {
    fields: demographics.groups,
    items: demographics.experience.map(
      ({ id, itemId, isActive, deletable }) =>
        ({
          id,
          parentId: itemId,
          isActive,
          softDeleted: false,
          deletable
        } as IEditItem)
    ),
    edit: currentLsState.demographics || []
  },
  indications: {
    fields: indications.groups,
    items: indications.experience.map(
      ({
        id,
        itemId,
        subItemId,
        isActive,
        deletable
      }: IExperienceItemWithChild) =>
        ({
          id,
          parentId: itemId,
          childId: subItemId,
          isActive,
          softDeleted: false,
          deletable
        } as IEditItem)
    ),
    edit: currentLsState.indications || []
  },
  scales: {
    fields: scales.groups,
    items: scales.experience.map(
      ({ id, itemId, isActive, deletable }) =>
        ({
          id,
          parentId: itemId,
          isActive,
          softDeleted: false,
          deletable
        } as IEditItem)
    ),
    edit: currentLsState.scales || []
  }
});

export const convertToServerState = (resConfig: IState) => ({
  version: resConfig.version,
  generalDemographic: resConfig.demographics.edit.map(m => ({
    ...(m.id
      ? { studyGeneralDemographicExperienceId: m.id }
      : { demographicTypeId: m.parentId }),
    isActive: m.isActive,
    softDeleted: m.softDeleted
  })),
  indicationDemographic: resConfig.indications.edit.map(m => ({
    ...(m.id
      ? { studyIndicationDemographicExperienceId: m.id }
      : { indicationId: m.parentId, demographicTypeId: m.childId }),
    isActive: m.isActive,
    softDeleted: m.softDeleted
  })),
  scale: resConfig.scales.edit.map(m => ({
    ...(m.id ? { studyScaleExperienceId: m.id } : { scaleId: m.parentId }),
    isActive: m.isActive,
    softDeleted: m.softDeleted
  }))
});
