import * as React from "react";
import { Link } from "react-router-dom";
import ChevronRight from "@material-ui/icons/ChevronRight";

interface IBreadProps {
  rootText: string;
  rootUrl: string;
  currentLocation?: string;
  currentNestedLocation?: string;
  currentNestedLocationUrl?: string;
}

export const BktBreadcrumb: React.SFC<IBreadProps> = (props: IBreadProps) => {
  const {
    rootUrl,
    rootText,
    currentLocation,
    currentNestedLocation,
    currentNestedLocationUrl
  } = props;
  return (
    <div id="breadCrumbContainer" className="mdd-breadcrumb typography">
      <Link id="breadLink" to={rootUrl}>{`${rootText}`}</Link>
      {currentLocation ? <ChevronRight /> : undefined}
      {currentNestedLocation ? (
        <Link
          id="breadLink"
          to={currentNestedLocationUrl ? currentNestedLocationUrl : ""}
        >
          {currentLocation}
        </Link>
      ) : (
        <span id="spanBreadSpacerCaret">{currentLocation}</span>
      )}
      {currentNestedLocation ? <ChevronRight /> : undefined}
      {currentNestedLocation ? (
        <span id="spanInnerBreadSpacerCaret">{currentNestedLocation}</span>
      ) : (
        undefined
      )}
    </div>
  );
};
