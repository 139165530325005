import * as React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  MddValidatedTextField,
  MddCheckbox,
  MddProgress
} from "../../../components";
import {
  showTrainingSpinner,
  getIsCorrectDisplayValue,
  isAtLeastOneAnswer
} from "./MddTrainingHelpers";
import {
  MAX_TEXT_LENGTH,
  NO_ANSWER_DISPLAY_TEXT,
  EXPERIENCE_STATUS_VALUE
} from "../../../constants";
import {
  IPersonnelTrainingScore,
  ICourseStatus,
  Validations,
  IPersonnelTrainingQuestionAnswer,
  IManualTrainingQuestionAnswer
} from "../../../model";
import { eStudySaga } from "../../../sagas";
import { SagaService, IAction } from "front-end-lib/core";
import { toDateFormatNoOffset, getFormattedUTCDateTime } from "../../../utils";
import {
  MddPanelContainer,
  MddNoDataDiv,
  MddDialog,
  MddDialogButtons
} from "../..";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface IMddStudySitePersonEditCourseScores {
  course: ICourseStatus;
  personnelId: string;
  handleCourseScoresClose: (shouldUpdateScore: boolean) => void;
}

export const MddStudySitePersonEditCourseScores = (
  props: IMddStudySitePersonEditCourseScores
) => {
  const [scores, setScores] = React.useState<IPersonnelTrainingScore | null>(
    null
  );
  const [completionDate, setCompletionDate] = React.useState<number>(
    Date.now()
  );
  const completionDateRef = React.useRef(completionDate);
  completionDateRef.current = completionDate;

  const [origCompletionDate, setOrigCompletionDate] = React.useState<number>();
  const [confirmEditCancelOpen, setConfirmEditCancelOpen] = React.useState(
    false
  );

  // Previously saved scores indicates they are updating scores but had at least one previously entered.
  const [
    isPreviouslySavedManualScores,
    setIsPreviouslySavedManualScores
  ] = React.useState(false);

  // This is always false when the page mounts even if QC was previously done.
  // If it was we'll display the QC person and date but the current person can override that
  // by checking the box again.
  const [isQCPerformed, setIsQCPerformed] = React.useState(false);

  // Using this is track if something changes when in edit mode.  This is not flawless since it'll be
  // set to true if they change it but it ends up exactly the same as it was to start.  The other option
  // is to store the original values and do a compare everytime they change something but there is a lot of
  // overhead involved in that so going with the more performant option despite the flaw since it really doesn't
  // matter if they save the same answers again.
  const [isCourseAnswersChanged, setIsCourseAnswersChanged] = React.useState(
    false
  );

  // Tracks if anything has changed over the lifetime of this modal being open. This is used when the modal is
  // closed to tell the caller if we need to reload the course data in order to update the page.  So if they
  // change something, save and then cancel out it should update, but if they end up not changing anything
  // and just cancel it should not waste the cycles and bandwidth doing an update.
  const [hasAnythingChanged, setHasAnythingChanged] = React.useState(false);

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false);

  // Array of booleans to track validation status for each question/response.
  const [answerValidationStatus, setAnswerValidationStatus] = React.useState<
    boolean[]
  >([]);
  const [
    hasAnswersValidationError,
    setHasAnswersValidationError
  ] = React.useState(false);

  React.useEffect(() => {
    const { course, personnelId } = props;

    const removeFetchTrainingCourseManualScores = SagaService.subscribeToSaga(
      eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES,
      (action: IAction) => {
        if (
          action.type === eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES_SUCCESS
        ) {
          const scoreQuestionAnswers: IPersonnelTrainingQuestionAnswer[] = action.payload.questionAnswers.map(
            (q: IManualTrainingQuestionAnswer) => {
              return {
                ...q,
                courseQuestionId: q.courseQuestionId || q.questionId
              };
            }
          );
          const hasPreviousScores = isAtLeastOneAnswer(scoreQuestionAnswers);
          setIsPreviouslySavedManualScores(hasPreviousScores);
          setScores(
            Object.assign(
              {},
              {
                ...action.payload,
                questionAnswers: scoreQuestionAnswers
              }
            )
          );

          // Initialize the array that tracks validation errors.  One boolean for each question.
          const answersValidation = action.payload.questionAnswers.map(
            (answer: IPersonnelTrainingQuestionAnswer) => {
              return false;
            }
          );
          setAnswerValidationStatus(answersValidation);

          const compDate = hasPreviousScores
            ? Number(new Date(action.payload.courseCompletionDate))
            : Date.now();
          setCompletionDate(compDate);
          setOrigCompletionDate(compDate);
        }
      }
    );

    const removeSaveTrainingCourseScores = SagaService.subscribeToSaga(
      eStudySaga.SAVE_TRAINING_COURSE_MANUAL_SCORES,
      (action: IAction) => {
        if (
          action.type === eStudySaga.SAVE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS
        ) {
          setIsCourseAnswersChanged(false);
          setOrigCompletionDate(completionDateRef.current);
          setIsQCPerformed(false);
          setIsPreviouslySavedManualScores(true);
          setHasAnythingChanged(true);

          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES,
            payload: {
              personnelId,
              courseId: course.courseId,
              isExisting: true
            }
          });
        }
      }
    );

    const removeDeleteTrainingCourseScores = SagaService.subscribeToSaga(
      eStudySaga.DELETE_TRAINING_COURSE_MANUAL_SCORES,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.DELETE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS
        ) {
          // close and update
          handleCourseScoresClose(true);
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES,
      payload: {
        personnelId,
        courseId: course.courseId,
        isExisting:
          course.courseStatus &&
          course.courseStatus.value !== EXPERIENCE_STATUS_VALUE.INCOMPLETE
      }
    });

    return () => {
      removeFetchTrainingCourseManualScores();
      removeSaveTrainingCourseScores();
      removeDeleteTrainingCourseScores();
    };
    // eslint-disable-next-line
  }, []);

  const handleEditAnswer = (index: number, value: string) => {
    const newScores: IPersonnelTrainingScore = {
      ...(scores as IPersonnelTrainingScore)
    };
    newScores.questionAnswers![index].userResponse = value.trim();
    setScores(newScores);
    setIsCourseAnswersChanged(true);
  };

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date === null) {
      // don't let them blank out the date
      setCompletionDate(Date.now());
    } else {
      setCompletionDate(Number(date));
    }
    setIsCourseAnswersChanged(true);
  };

  const handleToggleIsQCPerformed = () => {
    setIsQCPerformed(!isQCPerformed);
  };

  const updateAnswerValidations = (isError: boolean, index: number) => {
    // Update the answer validation array
    const newAnswerValidations = answerValidationStatus.map(
      (status: boolean, arrayIndex: number) => {
        return arrayIndex === index
          ? isError
          : answerValidationStatus[arrayIndex];
      }
    );
    setAnswerValidationStatus(newAnswerValidations);
    setHasAnswersValidationError(
      isError ||
        newAnswerValidations.find(
          (valStatus: boolean) => valStatus === true
        ) !== undefined
    );
  };

  const isSaveEnabled = () => {
    // Short circuit logic here to make the other logic easier.
    // If no scores or question/answers, or all answers are empty or is an invalid one then the button should not be enabled.
    if (
      !scores ||
      !scores.questionAnswers ||
      !isAtLeastOneAnswer(scores.questionAnswers) ||
      hasAnswersValidationError
    ) {
      return false;
    }

    // Is enabled if they have entered at least one answer (handled in the short circuit logic above) AND
    // 1) any questions have changed and there is nothing that has failed validation
    // 2) OR course completion date changed or QC is checked
    if (
      isCourseAnswersChanged ||
      isQCPerformed ||
      origCompletionDate !== completionDate
    ) {
      return true;
    }

    return false;
  };

  const renderQuestions = () => {
    return (
      <div className="mdd-training--course-scores--panel--table">
        <Table stickyHeader={true}>
          <TableHead
            id="scores_questions_table_hdr"
            data-testid="scores_questions_table_hdr"
          >
            <TableRow>
              <TableCell
                align="left"
                id="hdrQuestion"
                data-testid="hdrQuestion"
              >
                Question
              </TableCell>
              <TableCell
                align="left"
                id="hdrResponse"
                data-testid="hdrResponse"
              >
                Response
              </TableCell>
              <TableCell align="left" id="hdrCorrect" data-testid="hdrCorrect">
                Correct
              </TableCell>
              <TableCell
                align="left"
                id="hdrAcceptableResponse"
                data-testid="hdrAcceptableResponse"
              >
                Acceptable Response
              </TableCell>
            </TableRow>
          </TableHead>
          {scores && scores.questionAnswers.length > 0 && (
            <TableBody>
              {scores.questionAnswers.map((question, index) => {
                return (
                  <TableRow
                    key={`question_${index}`}
                    id={`question_${index}_row`}
                    data-testid={`question_${index}_row`}
                    hover
                  >
                    <TableCell
                      id={`question_${index}_questionText`}
                      data-testid={`question_${index}_questionText`}
                    >
                      {question.courseQuestionText}
                    </TableCell>
                    <TableCell
                      id={`question_${index}_response`}
                      data-testid={`question_${index}_response`}
                    >
                      <span
                        className="mdd-edit-course-scores--questions--textbox"
                        id={`spanAnswer_${index}`}
                        data-testid={`spanAnswer_${index}`}
                      >
                        <MddValidatedTextField
                          id={`txtAnswer_${index}`}
                          textFieldProps={{
                            debounceInterval: 0,
                            textProps: {
                              onBlur: (
                                event: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleEditAnswer(index, event.target.value);
                              },
                              value: question.userResponse || "",
                              id: `txtAnswer${index}_value`,
                              inputProps: {
                                maxLength: MAX_TEXT_LENGTH.COURSE_ANSWER
                              }
                            }
                          }}
                          validationForOnBlur={[
                            Validations.validAnswerForTrainingCourse
                          ]}
                          validationerror={(validationError: boolean) =>
                            updateAnswerValidations(validationError, index)
                          }
                        />
                      </span>
                    </TableCell>
                    <TableCell
                      id={`question_${index}_correct`}
                      data-testid={`question_${index}_correct`}
                    >
                      {getIsCorrectDisplayValue(question.isResponseCorrect)}
                    </TableCell>
                    <TableCell
                      id={`question_${index}_acceptableResponse`}
                      data-testid={`question_${index}_acceptableResponse`}
                    >
                      {question.acceptableResponse}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        {(!scores || !scores.questionAnswers.length) && <MddNoDataDiv />}
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <React.Fragment>
        <div className="mdd-edit-course-scores--details--padding">
          <div
            className="mdd-edit-course-scores--details--label"
            data-testid="metadata_modifiedBy_label"
          >
            Modified By
          </div>
          <div
            className="mdd-edit-course-scores--details--value"
            data-testid="metadata_modifiedBy_value"
          >
            {(scores && scores.updatedBy) || NO_ANSWER_DISPLAY_TEXT}
          </div>
          <div
            className="mdd-edit-course-scores--details--label"
            data-testid="metadata_modifiedDate_label"
          >
            Modified Date
          </div>
          <div
            className="mdd-edit-course-scores--details--value"
            data-testid="metadata_modifiedDate_value"
          >
            {(scores &&
              scores.updatedDt &&
              toDateFormatNoOffset(scores.updatedDt)) ||
              NO_ANSWER_DISPLAY_TEXT}
          </div>
          <div
            className="mdd-edit-course-scores--details--label"
            data-testid="metadata_statusDate_label"
          >
            Course Completion Date
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mdd-override-dialog--datePicker"
              margin="normal"
              data-testid="date-picker-dialog"
              id="date-picker-dialog"
              format="dd-MMM-yyyy"
              disableFuture={true}
              value={completionDate}
              onChange={handleDateChange}
              InputProps={{ disabled: true }}
            />
          </MuiPickersUtilsProvider>
          <div className="mdd-edit-course-scores--details--QC">
            <MddCheckbox
              lblText="QC Performed"
              lblProps={{
                className: isPreviouslySavedManualScores
                  ? ""
                  : "mdd-edit-course-scores--disabled-text"
              }}
              chkBoxProps={{
                id: "chkQCPerformed",
                checked: isQCPerformed,
                value: "chkQCPerformed",
                disabled: !isPreviouslySavedManualScores,
                onClick: () => handleToggleIsQCPerformed()
              }}
            />
          </div>
          {isPreviouslySavedManualScores && (
            <React.Fragment>
              <div
                className="mdd-edit-course-scores--details--label"
                data-testid="metadata_qcUser_label"
              >
                QC User
              </div>
              <div
                className="mdd-edit-course-scores--details--value"
                data-testid="metadata_qcUser_value"
              >
                {(scores && scores.qcUser) || NO_ANSWER_DISPLAY_TEXT}
              </div>
              <div
                className="mdd-edit-course-scores--details--label"
                data-testid="metadata_qcDate_label"
              >
                QC Date
              </div>
              <div
                className="mdd-edit-course-scores--details--value"
                data-testid="metadata_qcDate_value"
              >
                {(scores &&
                  scores.qcDate &&
                  toDateFormatNoOffset(scores.qcDate)) ||
                  NO_ANSWER_DISPLAY_TEXT}
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        <MddPanelContainer
          className="mdd-training--course-scores--panel--container"
          panels={[
            {
              title: "Course Questions",
              size: "half",
              content: renderQuestions(),
              className: "mdd-training--course-scores--panel"
            },
            {
              title: "Course Details",
              size: "half",
              content: renderDetails(),
              className: "mdd-training--course-scores--panel"
            }
          ]}
        />
        {confirmEditCancelOpen && (
          <MddDialog
            showCloseButton={true}
            onCloseClick={() =>
              isSaveEnabled()
                ? setConfirmEditCancelOpen(false)
                : handleCancelConfirmed()
            }
            dialogProps={{
              id: "divConfirmCancelDialog",
              open: confirmEditCancelOpen,
              keepMounted: false,
              maxWidth: "xs"
            }}
            dialogActionProps={{
              id: "divConfirmCancelDialogActions",
              className: "mdd-study-scores--lock-button"
            }}
            dialogContentProps={{
              id: "divConfirmCancelDialogContent"
            }}
            dialogTitleProps={{
              id: "divConfirmCancelDialogTitle",
              title: "Confirm Cancel"
            }}
            dialogContent={
              <span>
                <div>Are you sure you wish to cancel?</div>
                <div>All changes will be lost.</div>
              </span>
            }
            dialogActions={
              <MddDialogButtons
                saveButtonText="Yes"
                closeButtonText="No"
                saveButtonProps={{
                  id: "btnConfirmYes",
                  onClick: () => handleCancelConfirmed()
                }}
                closeButtonProps={{
                  id: "btnConfirmNo",
                  onClick: () => setConfirmEditCancelOpen(false)
                }}
              />
            }
          />
        )}
      </React.Fragment>
    );
  };

  const handleSaveCourseScores = () => {
    const { personnelId } = props;

    // filter out ones without answers and then put the ones left into the right format
    const saveAnswers = scores!.questionAnswers
      .filter(
        (question: IPersonnelTrainingQuestionAnswer) => question.userResponse
      )
      .map((answer: IPersonnelTrainingQuestionAnswer) => {
        return {
          questionId: answer.courseQuestionId,
          response: answer.userResponse
        };
      });

    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_TRAINING_COURSE_MANUAL_SCORES,
      payload: {
        courseId: course.courseId,
        personnelId,
        questionAnswers: saveAnswers,
        courseCompletionDate: getFormattedUTCDateTime(
          completionDateRef.current
        ),
        isQCPerformed,
        isExisting: isPreviouslySavedManualScores
      }
    });
  };

  const handleCancelConfirmed = () => {
    setConfirmEditCancelOpen(false);
    handleCourseScoresClose(hasAnythingChanged);
  };

  const handleDeleteOkClick = () => {
    const { personnelId } = props;

    // Don't bother calling the API if the isPreviouslySavedManualScores flag is false
    // since there won't be anything to do.
    if (isPreviouslySavedManualScores) {
      setIsDeleteConfirmOpen(false);
      SagaService.dispatchSaga({
        type: eStudySaga.DELETE_TRAINING_COURSE_MANUAL_SCORES,
        payload: {
          courseId: course.courseId,
          personnelId
        }
      });
    } else {
      // nothing to do so just close with no update
      setIsDeleteConfirmOpen(false);
      handleCourseScoresClose(false);
    }
  };

  const { handleCourseScoresClose, course } = props;

  const hasAnswers =
    scores &&
    scores.questionAnswers &&
    isAtLeastOneAnswer(scores.questionAnswers);

  return (
    <React.Fragment>
      <MddProgress inProgress={showTrainingSpinner()} spinnerId="svgLoading" />
      <MddDialog
        showCloseButton={true}
        onCloseClick={() =>
          isSaveEnabled()
            ? setConfirmEditCancelOpen(true)
            : handleCourseScoresClose(hasAnythingChanged)
        }
        dialogProps={{
          className: "mdd-training--course-scores--dialog",
          id: "divCourseScoresDialog",
          open: true,
          maxWidth: "xl",
          fullWidth: true
        }}
        dialogActionProps={{
          id: "divCourseScoresDialogActions",
          className: "mdd-training--course-scores--dialog--actions"
        }}
        dialogContentProps={{
          id: "divCourseScoresDialogContent",
          className: "mdd-training--course-scores--dialog--content"
        }}
        dialogTitleProps={{
          id: "divCourseScoresDialogTitle",
          title: `Edit ${(course && course.courseName) || ""}`
        }}
        dialogContent={renderContent()}
        dialogActions={
          <React.Fragment>
            <Button
              className={
                scores && scores.questionAnswers && hasAnswers
                  ? "mdd-edit-course-scores--delete"
                  : "mdd-edit-course-scores--delete--disabled"
              }
              id="btnDeleteAll"
              data-testid="btnDeleteAll"
              key="btnDeleteAll"
              color="primary"
              variant="outlined"
              disableFocusRipple={true}
              disableRipple={true}
              disabled={!hasAnswers}
              onClick={() => setIsDeleteConfirmOpen(true)}
            >
              Delete All Scores
            </Button>
            <MddDialogButtons
              saveButtonText={"Save"}
              closeButtonText="Cancel"
              saveButtonProps={{
                id: "btnCourseScoresClose",
                disabled: !isSaveEnabled(),
                className: "mdd-training--course-scores--dialog--button-close",
                onClick: () => {
                  handleSaveCourseScores();
                }
              }}
              closeButtonProps={{
                className: "mdd-training--course-scores--dialog--button-close",
                id: "btnCourseScoresHiddenButton",
                onClick: () => {
                  isSaveEnabled()
                    ? setConfirmEditCancelOpen(true)
                    : handleCourseScoresClose(hasAnythingChanged);
                }
              }}
            />
          </React.Fragment>
        }
      />
      {isDeleteConfirmOpen && (
        <MddDialog
          showCloseButton={true}
          onCloseClick={() => setIsDeleteConfirmOpen(false)}
          dialogProps={{
            id: "divDeleteConfirmDialog",
            open: isDeleteConfirmOpen,
            maxWidth: "sm"
          }}
          dialogActionProps={{
            id: "divDeleteConfirmDialogActions",
            className: "mdd-training--course-scores--dialog--confirm-button"
          }}
          dialogContentProps={{
            id: "divDeleteConfirmDialogContent"
          }}
          dialogTitleProps={{
            id: "divDeleteConfirmTitle",
            title: "Confirm Deletion"
          }}
          dialogContent={
            <span>
              Are you sure you want to delete all responses? Once deleted, the
              course status will be reverted to Incomplete and all linked
              learning plans will be updated.
            </span>
          }
          dialogActions={
            <MddDialogButtons
              saveButtonText="Yes"
              closeButtonText="No"
              saveButtonProps={{
                id: "btnDeleteYes",
                onClick: () => handleDeleteOkClick()
              }}
              closeButtonProps={{
                id: "btnDeleteNo",
                onClick: () => setIsDeleteConfirmOpen(false)
              }}
            />
          }
        />
      )}
    </React.Fragment>
  );
};
