import { AssignmentStatus } from "../../../model/interfaces/iCpResAssignmentStatus";
import React from "react";

interface IProps {
  status: AssignmentStatus;
}

const textMap = {
  [AssignmentStatus.NotAssigned]:
    "User is not assigned to the Rater Experience Survey",
  [AssignmentStatus.Unassigned]:
    "User is not assigned to the Rater Experience Survey",
  [AssignmentStatus.ResNotPublished]:
    "Rater Experience Survey is not published for this Study"
};

export const MddNoCpExperience = ({ status }: IProps) => {
  const text = textMap[status];
  return (
    <div className="mdd-experience--no-experience">
      {text && <span className="text">{text}</span>}
    </div>
  );
};
