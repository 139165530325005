import { isArray, isString } from "lodash";
import { ConfigOptions } from "../config";
import { NotificationError } from "../model";
import { eHTTPMethods, eHTTPCodes } from "../constants";
import { HTTPError } from "../model";
import { defaultApiHeaders, HTTP_RESPONSE_MESSAGES } from "../config";
import { store } from "../utils/store";

export class MDDHTTPService {
  private baseUrl: string;

  constructor(apiUrl: string) {
    this.baseUrl = apiUrl;
  }

  /**
   * Make a call the to API configured by the service.
   * @param {eHTTPMethods.GET | eHTTPMethods.POST | eHTTPMethods.DELETE | eHTTPMethods.PUT} method HTTP method to be used for the call.
   * @param {string} path The path to be appended to the baseUrl.
   * @param {any | undefined} body The body of the request
   * @param {Headers | string[][] | Record<string, string> | undefined} headers Headers to be sent with the request. Default: defaultApiHeaders.
   * @param {boolean} authorized Determines whether the request is 'Authorized' or not. Default: true
   * @returns {Promise<any>} The body of the response.
   * @throws {HTTPError} An error that includes a public and internal message.
   */
  doCallApi = async (
    method:
      | eHTTPMethods.GET
      | eHTTPMethods.POST
      | eHTTPMethods.DELETE
      | eHTTPMethods.PUT
      | eHTTPMethods.PATCH,
    path: string,
    body?: any,
    headers?: Headers | string[][] | Record<string, string> | undefined,
    authorized: boolean = true
  ): Promise<any> => {
    let response: Response | undefined = undefined;
    let responseBody: any = {};

    const token = store.getState().user.Token;
    // If they didn't give us headers add the Authorization header to the default ones
    const currHeaders: {} = headers || {
      ...defaultApiHeaders,
      Authorization: `Bearer ${token}`
    };
    try {
      const init = {
        method,
        headers: currHeaders,
        body: body ? JSON.stringify(body) : undefined
      } as RequestInit;

      if (authorized) {
        init.credentials = "include";
      }

      response = await fetch(this.baseUrl + path, init);

      // If unauthorized, go to the login.  Since Okta should automatically refresh
      // the token we shouldn't hit this in the normal flow.
      if (
        authorized &&
        response &&
        response.status === eHTTPCodes.UNAUTHORIZED
      ) {
        window.location.replace(ConfigOptions.oktaLoginUrl!);
      }

      if (!response) {
        throw new Error("Invalid response");
      }

      // Parse response
      try {
        // Try to parse the body as json
        // eHTTPCodes.NO_CONTENT (204) indicates success but no body.
        responseBody =
          response.status !== eHTTPCodes.NO_CONTENT
            ? await response.json()
            : {};

        if (response.ok) {
          return responseBody;
        }

        responseBody = JSON.parse(responseBody);
      } catch {
        throw new Error(
          `Error parsing body for: ${this.baseUrl}${path}, ${response.status}`
        );
      }

      throw new Error("Response is not ok.");
    } catch (e) {
      let responseMsg = undefined;
      let publicMsg = HTTP_RESPONSE_MESSAGES.API_FAILURE_ERROR;

      try {
        if (responseBody && isArray(responseBody)) {
          publicMsg = "";
          responseBody.map((x: any) => (publicMsg += x.Message + `, `));
          publicMsg = publicMsg.slice(0, publicMsg.lastIndexOf(","));
        } else if (responseBody?.detail) {
          publicMsg = responseBody.detail;
        } else {
          publicMsg =
            method === eHTTPMethods.GET
              ? HTTP_RESPONSE_MESSAGES.API_GET_METHOD_FAIL_ERROR
              : method === eHTTPMethods.PUT ||
                method === eHTTPMethods.PATCH ||
                method === eHTTPMethods.POST ||
                method === eHTTPMethods.DELETE
              ? HTTP_RESPONSE_MESSAGES.API_POST_METHOD_FAIL_ERROR
              : HTTP_RESPONSE_MESSAGES.API_FAILURE_ERROR;
        }

        if (isString(responseBody)) {
          responseMsg = responseBody;
        }

        throw new HTTPError((e as Error).message, publicMsg, responseMsg);
      } catch (ex) {
        throw new HTTPError(
          (ex as HTTPError).message,
          (ex as HTTPError).extMessage,
          (ex as HTTPError).responseMessage
        );
      }
    }
  };

  callApi = async (
    method:
      | eHTTPMethods.GET
      | eHTTPMethods.POST
      | eHTTPMethods.DELETE
      | eHTTPMethods.PUT
      | eHTTPMethods.PATCH,
    path: string,
    data?: any,
    extMessage?: string
  ) => {
    try {
      return await this.doCallApi(method, path, data);
    } catch (e) {
      const error = e as HTTPError;
      throw new NotificationError(
        error.message,
        extMessage || error.extMessage
      );
    }
  };
}
