import * as React from "react";
import { Button } from "@material-ui/core";
import { ePersonnelSaga } from "../../../sagas";
import { MddProgress } from "../../common";
import { withMddBase, MddStudySitePersonComments } from "../../../components";
import { IStudySitePerson } from "../../../model";
import { isLoading, runSagaAsync } from "../../../utils";
import {
  ICpResAssignmentStatus,
  AssignmentStatus
} from "../../../model/interfaces";
import { MddCpExperience } from "./MddCpExperience";
import { MddNoCpExperience } from "./MddNoCpExperience";
import { UnassignDialog } from "./UnassignDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

interface IMddResDisplay {
  person: IStudySitePerson;
  id: string;
  siteId: string;
}

interface IContentProps {
  studyId: string;
  personnelId: string;
  status: ICpResAssignmentStatus;
}

const Content = ({ status, ...rest }: IContentProps) =>
  [AssignmentStatus.Assigned, AssignmentStatus.Completed].includes(
    status.status
  ) ? (
    <MddCpExperience {...rest} status={status} />
  ) : (
    <MddNoCpExperience status={status.status} />
  );

const MddResDisplayComponent = (props: IMddResDisplay) => {
  const { id, siteId, person } = props;

  const [status, setStatus] = React.useState<ICpResAssignmentStatus>();
  const [unassignResModalOpen, setUnassignResModalOpen] = React.useState<
    boolean
  >(false);

  const getAssignmentStatus = async () => {
    const statusOnServer = await runSagaAsync<ICpResAssignmentStatus>({
      startType: ePersonnelSaga.GET_PERSONNEL_RES_STATUS,
      successType: ePersonnelSaga.GET_PERSONNEL_RES_STATUS_SUCCESS,
      payload: { studyId: id, personnelId: person.personnelId }
    });
    setStatus(statusOnServer);
  };

  React.useEffect(() => {
    getAssignmentStatus();

    // eslint-disable-next-line
  }, []);

  const showSpinner = isLoading(
    `undefined_${ePersonnelSaga.GET_PERSONNEL_RES_STATUS}`,
    `undefined_${ePersonnelSaga.SAVE_PERSONNEL_RES_ASSIGNMENT}`
  );

  const assignRes = async (): Promise<void> => {
    await runSagaAsync({
      startType: ePersonnelSaga.SAVE_PERSONNEL_RES_ASSIGNMENT,
      successType: ePersonnelSaga.SAVE_PERSONNEL_RES_ASSIGNMENT_SUCCESS,
      payload: { studyId: id, personnelId: person.personnelId }
    });
    await getAssignmentStatus();
  };

  const unassignRes = async () => {
    await runSagaAsync({
      startType: ePersonnelSaga.SAVE_PERSONNEL_RES_UNASSIGNMENT,
      successType: ePersonnelSaga.SAVE_PERSONNEL_RES_UNASSIGNMENT_SUCCESS,
      payload: { studyId: id, personnelId: person.personnelId }
    });
    await getAssignmentStatus();
  };

  let btnText, btnDisabled, btnOnClick;
  if (status?.status === AssignmentStatus.Assigned) {
    btnText = "Unassign RES";
    btnDisabled = false;
    btnOnClick = () => setUnassignResModalOpen(true);
  } else {
    btnText = "Assign RES";
    btnDisabled = !(
      (status?.status === AssignmentStatus.NotAssigned ||
        status?.status === AssignmentStatus.Unassigned ||
        status?.status === AssignmentStatus.Completed) &&
      (person?.isActive || person?.participatingSites.find(s => s.isActive))
    );
    btnOnClick = assignRes;
  }

  return (
    <React.Fragment>
      <MddProgress inProgress={showSpinner} spinnerId="svgLoading" />
      <div
        className="mdd-experience--page-header"
        id="pageHeader"
        data-testid="pageHeader"
      >
        <h2>Experience</h2>
        <div>
          {!status || status.hasCPAccess || (
            <span className="no-cp-access" data-testid="noCpAccessSpan">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              User does not have proper Clinical Profile access
            </span>
          )}
          <Button
            className="mdd-experience--assign-button"
            data-testid="btnAssign"
            id="btnAssign"
            size="small"
            variant="contained"
            color="primary"
            disabled={btnDisabled}
            onClick={btnOnClick}
          >
            {btnText}
          </Button>
        </div>
      </div>
      {status && (
        <Content
          studyId={id}
          personnelId={person.personnelId}
          status={status}
        />
      )}
      <MddStudySitePersonComments
        studyId={id}
        siteId={siteId}
        personnelId={person.personnelId}
      />
      <UnassignDialog
        open={unassignResModalOpen}
        onClose={() => setUnassignResModalOpen(false)}
        unassignRes={unassignRes}
      />
    </React.Fragment>
  );
};

export const MddResDisplay = withMddBase(MddResDisplayComponent);
