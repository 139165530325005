import * as React from "react";
import { map, pick, trim } from "lodash";
import {
  BktDataTable,
  SagaService,
  IBktDataTableData,
  IAction,
  Filter,
  eFilter
} from "front-end-lib/core";
import {
  MddDataContainer,
  withMddLookups,
  withMddBase
} from "../../../components";
import { defaultDataTableConfig } from "../../../config";
import { classConstants } from "../../../constants";
import { ISite, ILookup } from "../../../model";
import { history, isLoading } from "../../../utils";
import { eSiteSaga } from "../../../sagas";

interface IBktDataTableSite extends ISite {
  key: string;
}

interface IBktShowSitesProps {
  countries: ILookup[];
}

const _BktShowSites = (props: IBktShowSitesProps) => {
  const [sites, setSites] = React.useState<IBktDataTableSite[] | undefined>(
    undefined
  );
  let filterVals = React.useRef(new Map());
  const getFormattedSites = (rawSites: ISite[]): IBktDataTableSite[] =>
    map(
      rawSites,
      (s: ISite) =>
        Object.assign(pick(s, ["SiteName", "SiteNumber", "Country"]), {
          key: s.SiteId
        }) as IBktDataTableSite
    );
  const handleChangeFilter = (event: any) => {
    const { name } = event.target;
    const value = event.target.value ? trim(event.target.value) : "";
    const searchSite = {};

    if (
      filterVals.current.has(name) &&
      filterVals.current.get(name) === value
    ) {
      return;
    }

    value
      ? filterVals.current.set(name, value)
      : filterVals.current.delete(name);

    if (filterVals.current.size === 0) {
      setSites(undefined);
      return;
    }

    filterVals.current.forEach((value, key) => {
      Object.assign(searchSite, { [key]: value });
    });

    SagaService.dispatchSaga({
      type: eSiteSaga.FETCH_SITES,
      payload: searchSite
    });
  };

  React.useEffect(() => {
    const removeFetchSitesSub = SagaService.subscribeToSaga(
      eSiteSaga.FETCH_SITES,
      (action: IAction) => {
        if (action.type === eSiteSaga.FETCH_SITES_SUCCESS) {
          setSites(getFormattedSites(action.payload));
        }
      }
    );
    return () => removeFetchSitesSub();
    // eslint-disable-next-line
  }, []);

  return (
    <MddDataContainer
      id="divSites"
      addAction={{
        display: "Add New Site",
        action: () => history.push("/site/new")
      }}
      title="All Sites"
      classes={{
        grid: "mdd-grid"
      }}
      wrapInForm={false}
    >
      <BktDataTable
        data={sites}
        defaultSortProp="SiteName"
        loading={isLoading(`undefined_${eSiteSaga.FETCH_SITES}`)}
        columnProps={[
          {
            label: "Site Name",
            filter: new Filter(eFilter.Text, {
              textProps: {
                className: classConstants.TEXT_FILTER_CLASS,
                id: "sitNameFilter",
                onChange: handleChangeFilter,
                name: "siteName"
              }
            }),
            sortProp: { sortPropName: "SiteName", desc: true },
            tableCellProps: { id: "siteNameHeader" },
            divProps: { id: "siteNameHeader_div" }
          },
          {
            label: "Site Id",
            filter: new Filter(eFilter.Text, {
              textProps: {
                disabled: true, // not implementing now so disable it
                className: classConstants.TEXT_FILTER_CLASS,
                id: "siteIdFilter",
                onChange: handleChangeFilter,
                name: "siteId"
              }
            }),
            sortProp: { sortPropName: "SiteNumber", desc: true },
            tableCellProps: { id: "siteIdHeader" },
            divProps: { id: "siteIdHeader_div" }
          },
          {
            label: "Country",
            filter: new Filter(eFilter.Select, {
              textProps: {
                id: "countryValue",
                className: "tbl--filter-select",
                onChange: handleChangeFilter,
                InputLabelProps: {
                  shrink: filterVals.current.has("countryValue")
                },
                value: filterVals.current.get("countryValue") || ""
              },
              selectMenuProps: { MenuProps: { className: "bracket-Select" } },
              menuProps: {},
              selectData: props.countries,
              defaultSelect: true
            }),
            tableCellProps: {
              id: "countryHeader",
              className: classConstants.TABLE_CELL_CLASS
            },
            divProps: { id: "countryHeader_div" }
          }
        ]}
        rowActions={[
          {
            isPrimary: true,
            action: (
              data: IBktDataTableData,
              event: React.MouseEvent<HTMLDivElement>
            ) => history.push("/site/" + data.key)
          }
        ]}
        {...defaultDataTableConfig}
      />
    </MddDataContainer>
  );
};

export const BktShowSites = withMddLookups(["countries"])(
  withMddBase(_BktShowSites)
);
