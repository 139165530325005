import React from "react";
import { DialogContent, DialogContentText } from "@material-ui/core";
import { MddDialog, MddDialogButtons } from "../..";

type Title = "Demographic" | "Indication" | "Scale" | "RES Draft";

interface IProps {
  itemName: string;
  open: boolean;
  title: Title;
  onClose: (isSave?: boolean) => void;
}

export const DeleteDialog = ({ itemName, open, title, onClose }: IProps) => {
  return (
    <MddDialog
      dialogProps={{
        open: open,
        maxWidth: "xs"
      }}
      dialogContentProps={{ className: "mdd-res-draft--dialog" }}
      dialogTitleProps={{ title: `Delete ${title}` }}
      dialogContent={
        <DialogContent className="mdd-res-draft--dialog-content">
          <DialogContentText>
            Are you sure you want to delete the {itemName} from the Rater
            Experience Survey?
          </DialogContentText>
        </DialogContent>
      }
      onCloseClick={() => onClose()}
      dialogActions={
        <MddDialogButtons
          saveButtonText="Delete"
          saveButtonProps={{
            onClick: () => onClose(true)
          }}
          closeButtonProps={{ onClick: () => onClose() }}
        />
      }
      showCloseButton
    />
  );
};
