import * as React from "react";
import { Provider } from "react-redux";
import { Store, AnyAction } from "redux";
import { IApplicationState } from "../../../store";
import { store as _store } from "../../../utils";

export function withProvider(
  children: React.ReactNode,
  store?: Store<IApplicationState, AnyAction>
): JSX.Element {
  return <Provider store={store ? store : _store}>{children}</Provider>;
}
