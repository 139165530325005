import * as React from "react";
import {
  BktSelect,
  BktValidatedTextField,
  ISelectData
} from "front-end-lib/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { MddDialog, MddDialogButtons } from "../..";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { Validations } from "../../../model";
import {
  MAX_TEXT_LENGTH,
  QUALIFICATION_STATUS_VALUE,
  QUALIFICATION_STATUS_MAP,
  EXPERIENCE_STATUS_VALUE,
  EXPERIENCE_STATUS_MAP,
  EXPERIENCE_STATUS_DISPLAY_VALUE
} from "../../../constants";
import { toDateFormatNoOffset } from "../../../utils";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface IMddOverrideStatusDialogProps {
  /** Flag for if dialog is open */
  open: boolean;
  /** Text for header of dialog */
  headerText: string;
  /** Text for label for current definition type (Scale, Qualification, etc. ) */
  defTypeText: string;
  /** Text value of the current definition */
  defValueText: string;
  /** Text of current status */
  currStatusText: string;
  /** Flag for if comment is required */
  isCommentRequired: boolean;
  /** Previous (existing) status date */
  prevStatusDate: string | null;
  /** Array of valid statuses */
  statuses: ISelectData[];
  /** Append current status to array of statuses passed in? (default=false) */
  appendCurrentStatus?: boolean;
  /** Flag for if save is in progress */
  saveInProgress?: boolean;
  /** Callback function for when dialog is closed */
  onClose: (e: React.MouseEvent) => void;
  /** Callback function for save */
  onSave: (status: string, commentText: string, statusDate: string) => void;
  /** Date format the caller wants back, defaults to "yyyy-MM-dd" */
  dateFormat?: string;
  /** Callback function for when status is changed */
  onStatusChange?: (status: string) => void;
  /** Is it a qualification override?  If false, it's scale experience. (defaults to false) */
  isQualOverride?: boolean;
}

export const MddOverrideStatusDialog = (
  props: IMddOverrideStatusDialogProps
) => {
  const [newStatus, setNewStatus] = React.useState("");
  const [origStatus, setOrigStatus] = React.useState("");
  const [displayStatuses, setDisplayStatuses] = React.useState<ISelectData[]>(
    []
  );
  const [comment, setComment] = React.useState("");
  // NOTE: dates are maintainted as numbers internally within this component
  // (a date is just the number of milliseconds since 1/1/1970).  This will make
  // it easier to manipulate things as we need to in the future.  In the save handler
  // the date is converted to a string to send back to the caller.
  const [statusDate, setStatusDate] = React.useState<number>(Date.now());

  const findStatusByLabel = (label: string, qualOverride: boolean) =>
    (qualOverride ? QUALIFICATION_STATUS_MAP : EXPERIENCE_STATUS_MAP).find(
      x => x.label === label
    );

  React.useEffect(() => {
    const {
      statuses,
      currStatusText,
      appendCurrentStatus = false,
      isQualOverride = false
    } = props;
    if (statuses.length > 0) {
      if (
        appendCurrentStatus &&
        currStatusText !== EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
      ) {
        // We need to add the current status to the list of statuses.
        // Note that if the current status is clinical review we will
        // never add that to the list since they are not allowed to
        // update just the date for one in clinical review.
        const foundStatus = findStatusByLabel(currStatusText, isQualOverride);
        if (foundStatus) {
          const newStatuses: ISelectData[] = [
            foundStatus,
            ...statuses.filter(m => m.value !== foundStatus.value)
          ];
          setDisplayStatuses(newStatuses);
        }
      } else {
        // just use what was passed in
        setDisplayStatuses(statuses);
      }
    }
    // eslint-disable-next-line
  }, [props.statuses]);

  React.useEffect(() => {
    const { currStatusText, isQualOverride = false } = props;

    const foundStatus = findStatusByLabel(currStatusText, isQualOverride);
    if (foundStatus) {
      setNewStatus(foundStatus.value);
      setOrigStatus(foundStatus.value); // save away the original status so we can compare later
    }

    // eslint-disable-next-line
  }, [props.currStatusText]);

  const {
    open,
    headerText,
    isCommentRequired,
    defTypeText,
    defValueText,
    currStatusText,
    saveInProgress = false,
    onClose,
    onSave,
    onStatusChange
  } = props;

  const isSaveDisabled = () => {
    // enable if status changed, date changed from the previous date passed in
    // and save not in progress plus comment logic
    const { isQualOverride, prevStatusDate } = props;
    let prevDate = prevStatusDate;
    if (isQualOverride) {
      prevDate = prevStatusDate ? toDateFormatNoOffset(prevStatusDate) : null;
    }
    return (
      ((!newStatus || newStatus === origStatus) &&
        format(statusDate, "dd-MMM-yyyy").toUpperCase() === prevDate &&
        newStatus !== QUALIFICATION_STATUS_VALUE.INCOMPLETE) ||
      saveInProgress ||
      (isCommentRequired && comment.trim().length < 2)
    );
  };

  const handleOnSaveClick = (e: React.MouseEvent) => {
    const { dateFormat = "yyyy-MM-dd" } = props;
    onSave(newStatus, comment, format(statusDate, dateFormat));
  };

  const handleStatusChange = (newStatus: string) => {
    setNewStatus(newStatus);
    if (onStatusChange) {
      onStatusChange(newStatus);
    }
  };

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date === null) {
      // don't let them blank out the date
      setStatusDate(Date.now());
    } else {
      setStatusDate(Number(date));
    }
  };

  const getDialogContent = () => {
    const { isQualOverride } = props;

    // For a qualification override we won't show the date picker if they are changing the
    // new status to incomplete.  For a scale experience override we won't show the picker
    // if the PREVIOUS status was clinical review or incomplete since it's an initial override.
    // They actually cannot change a status of incomplete at this point but leaving that in in case
    // we do something different in the future.
    const showDatePicker =
      (isQualOverride && newStatus !== QUALIFICATION_STATUS_VALUE.INCOMPLETE) ||
      (!isQualOverride &&
        origStatus !== EXPERIENCE_STATUS_VALUE.CLINICAL_REVIEW &&
        origStatus !== EXPERIENCE_STATUS_VALUE.INCOMPLETE);

    const dateLabel = isQualOverride
      ? "Study Qualification Status Date *"
      : "Scale Experience Status Date *";

    return (
      <div className="mdd-override-dialog--container">
        <div className="mdd-override-dialog--left-panel">
          <div
            className="mdd-override-dialog--label"
            id="lblDefType"
            data-testid="lblDefType"
          >
            {defTypeText}
          </div>
          <div
            className="mdd-override-dialog--text"
            id="txtDefValue"
            data-testid="txtDefValue"
          >
            {defValueText || ""}
          </div>
          <div
            className="mdd-override-dialog--label"
            id="lblStatus"
            data-testid="lblStatus"
          >
            Current Status
          </div>
          <div
            className="mdd-override-dialog--text"
            id="txtStatus"
            data-testid="txtStatus"
          >
            {currStatusText || ""}
          </div>
        </div>
        <div className="mdd-override-dialog--right-panel">
          {showDatePicker && (
            <React.Fragment>
              <div
                className="mdd-override-dialog--label--no-margin"
                id="lblStatusDate"
                data-testid="lblStatusDate"
              >
                {dateLabel}
                <span
                  className="mdd-edit-course-scores--info-icon"
                  id="responseHelperText"
                  data-testid="responseHelperText"
                  title={`Use to document date of sponsor's ${String.fromCharCode(
                    13
                  )}decision to override, external training ${String.fromCharCode(
                    13
                  )}status date, etc. (which maybe be ${String.fromCharCode(
                    13
                  )}different from today's date)`}
                >
                  <InfoIcon
                    fontSize="small"
                    id="iconInfoStatusDate"
                    data-testid="iconInfoStatusDate"
                  />
                </span>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="mdd-override-dialog--datePicker"
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd-MMM-yyyy"
                  disableFuture={true}
                  value={statusDate}
                  onChange={handleDateChange}
                  InputProps={{ disabled: true }}
                />
              </MuiPickersUtilsProvider>
            </React.Fragment>
          )}
          <div
            className="mdd-override-dialog--label--no-margin"
            id="lblNewStatus"
            data-testid="lblNewStatus"
          >
            {`New Status *`}
          </div>
          <BktSelect
            menuProps={{}}
            textProps={{
              id: "selectStatus",
              label: "",
              value: newStatus,
              className: "mdd-override-dialog--select",
              onChange: event => handleStatusChange(event.target.value)
            }}
            selectData={displayStatuses}
            selectMenuProps={{
              className: "mdd-override-dialog--select--div"
            }}
          />
          <div
            className="mdd-override-dialog--label--no-margin"
            id="lblComment"
            data-testid="lblComment"
          >
            {`Comment${isCommentRequired ? " *" : ""}`}
          </div>
          <span data-testid="spanComment">
            <BktValidatedTextField
              validationForOnBlur={
                isCommentRequired ? [Validations.required] : [Validations.valid]
              }
              textFieldProps={{
                textProps: {
                  multiline: true,
                  id: "commentTextField",
                  placeholder: "Enter comment",
                  variant: "outlined",
                  required: isCommentRequired,
                  type: "text",
                  label: "",
                  className: "mdd-override-dialog--edit-comment--input",
                  disabled: false,
                  inputProps: {
                    id: "txtComment",
                    title: "comment",
                    maxLength: MAX_TEXT_LENGTH.COMMENT
                  },
                  rows: 3,
                  rowsMax: 3,
                  value: comment,
                  onChange: e => setComment(e.target.value)
                },
                debounceInterval: 0
              }}
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <MddDialog
      showCloseButton={true}
      onCloseClick={onClose}
      draggable
      dialogProps={{
        id: "divOverrideDialog",
        open,
        maxWidth: "lg"
      }}
      dialogActionProps={{ id: "divOverrideDialogActions" }}
      dialogContentProps={{
        className: "mdd-override-dialog--content",
        id: "divOverrideDialogContent"
      }}
      dialogTitleProps={{
        id: "divOverrideDialogTitle",
        title: headerText
      }}
      dialogContent={getDialogContent()}
      dialogActions={
        <MddDialogButtons
          saveButtonProps={{
            disabled: isSaveDisabled(),
            onClick: handleOnSaveClick
          }}
          closeButtonProps={{
            onClick: e => onClose(e),
            disabled: saveInProgress
          }}
        />
      }
    />
  );
};
