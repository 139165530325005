import { Auditable } from "./auditable";
import {
  IStudy,
  IStudyScale,
  IStudySite,
  IStudyQualification
} from "../../model";

export class Study extends Auditable implements IStudy {
  private demographic: string;
  private indication: string;
  private internalStudyName: string;
  private protocol: string;
  private scale: any;
  private sponsorId: string;
  private sponsorName: string;
  private sponsorStudyId: string;
  private studyId: string;
  private studyName: string;
  private therapeuticArea: string;
  private isActive: boolean;
  private projectNumber: string;
  private scales: IStudyScale[];
  private sites: IStudySite[];
  private qualifications: IStudyQualification[];
  private resType: string;

  get Demographic() {
    return this.demographic;
  }
  set Demographic(val) {
    this.demographic = val;
  }
  get Indication() {
    return this.indication;
  }
  set Indication(val) {
    this.indication = val;
  }
  get InternalStudyName() {
    return this.internalStudyName;
  }
  set InternalStudyName(val) {
    this.internalStudyName = val;
  }
  get Protocol() {
    return this.protocol;
  }
  set Protocol(val) {
    this.protocol = val;
  }
  get Scale() {
    return this.scale;
  }
  set Scale(val) {
    this.scale = val;
  }
  get SponsorId() {
    return this.sponsorId;
  }
  set SponsorId(val) {
    this.sponsorId = val;
  }
  get SponsorName() {
    return this.sponsorName;
  }
  set SponsorName(val) {
    this.sponsorName = val;
  }
  get SponsorStudyId() {
    return this.sponsorStudyId;
  }
  set SponsorStudyId(val) {
    this.sponsorStudyId = val;
  }
  get StudyId() {
    return this.studyId;
  }
  set StudyId(val) {
    this.studyId = val;
  }
  get StudyName() {
    return this.studyName;
  }
  set StudyName(val) {
    this.studyName = val;
  }
  get TherapeuticArea() {
    return this.therapeuticArea;
  }
  set TherapeuticArea(val) {
    this.therapeuticArea = val;
  }
  get IsActive() {
    return this.isActive;
  }
  set IsActive(val) {
    this.isActive = val;
  }
  get ProjectNumber() {
    return this.projectNumber;
  }
  set ProjectNumber(val) {
    this.projectNumber = val;
  }

  get Scales() {
    return this.scales;
  }
  set Scales(val) {
    this.scales = val;
  }

  get Sites() {
    return this.sites;
  }
  set Sites(val) {
    this.sites = val;
  }

  get Qualifications() {
    return this.qualifications;
  }
  set Qualifications(val) {
    this.qualifications = val;
  }

  get ResType() {
    return this.resType;
  }
  set ResType(val) {
    this.resType = val;
  }
}
