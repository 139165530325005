import React, { useState } from "react";
import { BktDataTable } from "front-end-lib";
import { useParams } from "react-router-dom";
import { eResConfig } from "../../../store/resConfig";
import { isLoading } from "../../../utils";
import {
  withMddStudy,
  BktBreadcrumb,
  MddDataContainer,
  MddProgress
} from "../../../components";
import { IMddStudyProps } from "../../../model";
import { history, runSagaAsync, useSaga } from "../../../utils";
import { MddResConfigDialog } from "./MddResConfigDialog";
import {
  getConfig,
  headers
} from "../../../config/dataTable/raterExperienceSurvey/versionsDataTableConfig";
import { IResVersionListItem } from "../../../model/interfaces/iResVersionListItem";
import { ResStatus } from "../../../constants/resConfigStatus";
import { DeleteDialog } from "../MddResDraft/DeleteDialog";
import { MddResConfigPublish } from "./MddResConfigPublish";
import { MddResConfigView } from "../MddResView/MddResConfigView";

interface IMddResConfigProps extends IMddStudyProps {}

const _MddResConfig = (props: IMddResConfigProps) => {
  const { study } = props;
  const { id } = useParams<{ id?: string }>();

  const [deleteResDraft, setDeleteResDraft] = useState<IResVersionListItem>();
  const [viewResItem, setViewResItem] = useState<IResVersionListItem>();
  const [publishResDraftId, setPublishResDraftId] = useState<string>();
  const [publishingModalOpen, setPublishingModalOpen] = useState(false);
  const { data, reload } = useSaga<IResVersionListItem[]>({
    startType: eResConfig.GET_RES_CONFIG_LIST,
    successType: eResConfig.GET_RES_CONFIG_LIST_SUCCESS,
    payload: { id }
  });

  const addAction =
    !data || data.some(m => m.status === ResStatus.Draft)
      ? undefined
      : {
          display: "Add RES",
          action: async () => {
            const result = await runSagaAsync<{ id: string }>({
              startType: eResConfig.CREATE_RES_CONFIG,
              successType: eResConfig.CREATE_RES_CONFIG_SUCCESS,
              payload: { id }
            });

            history.push(
              `/study/${study.StudyId}/resconfig/${result.id}/demographics`
            );
          }
        };

  const handleEdit = (id: string) => {
    history.push(`/study/${study.StudyId}/resconfig/${id}/demographics`);
  };

  const handleView = (id: string) => {
    const resView = data?.find(i => i.id === id);
    setViewResItem(resView);
  };

  const handleDelete = (id: string) => {
    const resDraft = data?.find(i => i.id === id);
    setDeleteResDraft(resDraft);
  };

  const handleCloseDeleteModal = async (isSave?: boolean) => {
    setDeleteResDraft(undefined);

    if (isSave && deleteResDraft) {
      await runSagaAsync<{ studyId: string; studyResId: string }>({
        startType: eResConfig.DELETE_RES_DRAFT,
        successType: eResConfig.DELETE_RES_DRAFT_SUCCESS,
        payload: { studyId: id, studyResId: deleteResDraft.id }
      });

      reload();
    }
  };

  const handleClosePublishModal = async (isSave?: boolean) => {
    setPublishResDraftId(undefined);

    if (isSave && publishResDraftId) {
      await runSagaAsync<{ study: string; studyResId: string }>({
        startType: eResConfig.PUBLISH_RES_DRAFT,
        successType: eResConfig.PUBLISH_RES_DRAFT_SUCCESS,
        payload: { studyId: id, studyResId: publishResDraftId }
      });

      reload();
    }
  };

  const inProgress = isLoading(
    `undefined_${eResConfig.DELETE_RES_DRAFT}`,
    `undefined_${eResConfig.CREATE_RES_CONFIG}`,
    `undefined_${eResConfig.PUBLISH_RES_DRAFT}`
  );
  const tableLoadInProgress = isLoading(
    `undefined_${eResConfig.GET_RES_CONFIG_LIST}`
  );

  return (
    <React.Fragment>
      <BktBreadcrumb
        rootText="All Studies"
        rootUrl="/study"
        currentLocation={study.StudyName}
      />
      <div
        className="mdd-res-config-instructions"
        data-testid="mdd-res-config-instructions"
      >
        <h2>Rater Experience Survey Configuration</h2>
        <p className="mdd-res-config--paragraph">
          Configure the Study Rater Experience Survey (RES). Manage the fields,
          indications and scales included in the Study RES for raters in
          Clinical Profile. The Study RES Request will not be available until
          published.
        </p>
        <p className="mdd-res-config--paragraph">
          Select <i>Add RES</i> to begin a new draft. Once configured for all
          fields, indications and scales, select <i>Publish</i> to make live in
          Clinical Profile.
        </p>
        <p className="mdd-res-config--paragraph">
          To update fields, scales and/or indications by adding/removing after
          publishing, select <i>Add RES</i>. Once the updated configuration is
          complete, select <i>Publish</i> on the new version to make live in
          Clinical Profile.
        </p>
        <p className="mdd-res-config--paragraph">
          <span
            className="mdd-res-config-instructions--link"
            id="txtPublishingLink"
            data-testid="txtPublishingLink"
            onClick={() => {
              setPublishingModalOpen(true);
            }}
          >
            Click here
          </span>{" "}
          to view more information on Publishing actions.
        </p>
      </div>
      <MddDataContainer
        id="resConfigContainer"
        title="Rater Experience Survey"
        classes={{
          container: "mdd-res-config-tableContainer"
        }}
        addAction={addAction}
      >
        <BktDataTable
          noRecordsMessage="Please add a Rater Experience Survey"
          data={getConfig(data || [])}
          loading={tableLoadInProgress}
          defaultSortProp="version"
          infiniteScrollProps={{
            id: "resInfiniteScroll",
            maxItems: 99999
          }}
          tableHeadProps={{
            className: "tbl--header tableHead",
            id: "resConfigTableHeader"
          }}
          tableBodyProps={{
            rowProps: { className: "tbl--body-row mdd-tbl--body-row" }
          }}
          columnProps={headers(
            handleEdit,
            handleDelete,
            setPublishResDraftId,
            handleView
          )}
        />
      </MddDataContainer>
      <MddResConfigDialog
        open={publishingModalOpen}
        onClose={() => {
          setPublishingModalOpen(false);
        }}
      />
      <DeleteDialog
        title={"RES Draft"}
        itemName={`RES Draft version ${deleteResDraft?.version}`}
        open={!!deleteResDraft}
        onClose={handleCloseDeleteModal}
      />
      <MddResConfigPublish
        open={!!publishResDraftId}
        onClose={handleClosePublishModal}
      />
      {viewResItem && (
        <MddResConfigView
          studyId={id}
          resMetadata={viewResItem}
          open={!!viewResItem}
          onClose={() => setViewResItem(undefined)}
        />
      )}
      <MddProgress inProgress={inProgress} spinnerId="deleteRes" />
    </React.Fragment>
  );
};

export const MddResConfig = withMddStudy()(_MddResConfig);
