import * as React from "react";
import { SagaService, IAction } from "front-end-lib/core";

import { MddCommentArea } from "../../common";
import { BktBreadcrumb, MddForm } from "../../../components";
import { eStudySaga, eSiteSaga, eCommentSaga } from "../../../sagas";
import { Site, Study } from "../../../model";
import { ConfigOptions } from "../../../config";

interface IMddAddEditStudySiteCommentsProps {
  match: {
    params: {
      id: string;
      siteId: string;
    };
  };
}

export const MddAddEditStudySiteComments = (
  props: IMddAddEditStudySiteCommentsProps
) => {
  const [site, setSite] = React.useState(new Site());
  const [study, setStudy] = React.useState(new Study());

  React.useEffect(() => {
    const removeFetchSiteSub = SagaService.subscribeToSaga(
      eSiteSaga.FETCH_SITE,
      (action: IAction) => {
        if (action.type === eSiteSaga.FETCH_SITE_SUCCESS) {
          setSite(action.payload);
        }
      }
    );
    const removeFetchStudySub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_STUDY_SUCCESS) {
          setStudy(action.payload);
        }
      }
    );

    const { id, siteId } = props.match.params;
    SagaService.dispatchSaga({ type: eStudySaga.FETCH_STUDY, payload: id });
    SagaService.dispatchSaga({ type: eSiteSaga.FETCH_SITE, payload: siteId });

    return () => {
      removeFetchSiteSub();
      removeFetchStudySub();
    };
  }, [props.match]);

  const handleCommentSave = () => {
    // TODO - for future use when comment table is coded.
    // In here we will reload the comments to refresh the table.
  };

  const { id, siteId } = props.match.params;

  return (
    <div className="mdd-form--fullheight">
      <BktBreadcrumb
        rootText="All Studies"
        rootUrl="/study"
        currentLocation={study.StudyId ? study.StudyName : ""}
        currentNestedLocationUrl={`/study/${study.StudyId}/sites`}
        currentNestedLocation={
          site.SiteId ? `${site.SiteName} (${site.SiteNumber}) ` : ""
        }
      />
      <MddForm
        keys={[
          `undefined_${eSiteSaga.FETCH_SITE}`,
          `undefined_${eStudySaga.FETCH_STUDY}`,
          `undefined_${eCommentSaga.SAVE_COMMENT}`
        ]}
      >
        <MddCommentArea
          minLength={2}
          onSave={handleCommentSave}
          saveUrl={`${ConfigOptions.studyApiPath}/${id}/Site/${siteId}/comment`}
        />
      </MddForm>
    </div>
  );
};
