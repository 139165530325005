import { IUser } from "../../model";
import { User } from "../../model/classes/user";
import { UserActionTypes } from "./types";

const initialState: IUser = new User("", "", "", "", "", "", true, "", [], "");

export const userReducer: any = (state: IUser = initialState, action: any) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_USER: {
      return Object.assign(
        new User("", "", "", "", "", "", true, "", [], ""),
        state,
        action.payload
      );
    }
    default: {
      return state;
    }
  }
};
