import { Auditable } from "./auditable";
import {
  IPerson,
  IRole,
  IStudyProtocol,
  IPersonKey,
  IPersonnelContactInfo,
  IPersonStudyHistory
} from "../../model";

export class Person extends Auditable implements IPerson {
  private id: string;
  private userName: string;
  private firstName: string;
  private lastName: string;
  private email: string;
  private roles: IRole[];
  private protocols: IStudyProtocol[];
  private isActive?: boolean;
  private type?: string;
  private key?: IPersonKey;
  private raterId: string;
  private personnelContactInfo: IPersonnelContactInfo;
  private studyHistory: IPersonStudyHistory[];

  get Id() {
    return this.id;
  }
  set Id(val) {
    this.id = val;
  }

  get FirstName() {
    return this.firstName;
  }
  set FirstName(val) {
    this.firstName = val;
  }

  get LastName() {
    return this.lastName;
  }
  set LastName(val) {
    this.lastName = val;
  }

  get Email() {
    return this.email;
  }
  set Email(val) {
    this.email = val;
  }

  get Roles() {
    return this.roles || [];
  }
  set Roles(val) {
    this.roles = val;
  }

  get Protocols() {
    return this.protocols || [];
  }
  set Protocols(val) {
    this.protocols = val;
  }

  get IsActive() {
    return this.isActive;
  }
  set IsActive(val) {
    this.isActive = val;
  }

  get Type() {
    return this.type || (this.key ? this.key.type : undefined);
  }
  set Type(val) {
    this.type = val;
  }

  get UserName() {
    return this.userName || (this.key ? this.key.userName : "");
  }
  set UserName(val) {
    this.userName = val;
  }

  get Key() {
    return `${this.UserName}_${this.Type}`;
  }

  get RaterId() {
    return this.raterId;
  }
  set RaterId(val) {
    this.raterId = val;
  }

  get PersonnelContactInfo() {
    return this.personnelContactInfo;
  }
  set PersonnelContactInfo(val) {
    this.personnelContactInfo = val;
  }

  get StudyHistory() {
    return this.studyHistory;
  }
}
