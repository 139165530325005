import { IStudySite, IStudySitePiHistory, IStudyPersonnelProtocol } from "..";
import { IBktDataTablePerson } from "../../components";
import { trim } from "lodash";

export class StudySite implements IStudySite {
  private studyId: string;
  private siteId: string;
  private sponsorSiteId: string;
  private siteName: string;
  private studySiteId: string;
  private primaryInvestigator?: IBktDataTablePerson;
  private isActive: boolean;
  private primaryInvestigatorHistory: IStudySitePiHistory[];
  private protocols: IStudyPersonnelProtocol[];

  get StudyId() {
    return this.studyId;
  }
  set StudyId(val) {
    this.studyId = val;
  }
  get SiteId() {
    return this.siteId;
  }
  set SiteId(val) {
    this.siteId = val;
  }
  get SiteName() {
    return this.siteName;
  }
  set SiteName(val) {
    this.siteName = val;
  }
  get SponsorSiteId() {
    return this.sponsorSiteId;
  }
  set SponsorSiteId(val) {
    this.sponsorSiteId = val;
  }
  get StudySiteId() {
    return this.studySiteId;
  }
  set StudySiteId(val) {
    this.studySiteId = val;
  }
  get PrimaryInvestigator() {
    return this.primaryInvestigator;
  }
  set PrimaryInvestigator(val) {
    this.primaryInvestigator = val;
  }
  get IsActive() {
    return this.isActive;
  }
  set IsActive(val) {
    this.isActive = val;
  }
  set PrimaryInvestigatorHistory(val) {
    this.primaryInvestigatorHistory = val;
  }
  get PrimaryInvestigatorHistory() {
    return this.primaryInvestigatorHistory;
  }
  set Protocols(val) {
    this.protocols = val;
  }
  get Protocols() {
    return this.protocols;
  }

  constructor(
    studyId?: string,
    siteId?: string,
    siteName?: string,
    primaryInvestigator?: IBktDataTablePerson,
    sponsorSiteId?: string,
    isActive?: boolean
  ) {
    this.studyId = studyId || "";
    this.siteId = siteId || "";
    this.siteName = siteName || "";
    this.sponsorSiteId = trim(sponsorSiteId || "");
    this.primaryInvestigator = primaryInvestigator;
    this.isActive = isActive || false;
    this.PrimaryInvestigatorHistory = [];
  }
}
