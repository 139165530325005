import { IMenuList } from "model/interfaces/IMenuList";
import { eIconTypes } from "../../../constants/applicationConstants";
import { MenuListItem } from "../MenuItem";
import {
  faBook,
  faGraduationCap,
  faImagePortrait,
  faMicroscope,
  faScaleBalanced
} from "@fortawesome/free-solid-svg-icons";

export enum resMenuItemCategories {
  Demographics = "Demographics",
  Education = "Education",
  ExperienceGeneral = "ExperienceGeneral",
  ExperienceIndication = "ExperienceIndication",
  ExperienceScales = "ExperienceScales"
}

export class ResMenuContainer implements IMenuList {
  root: string;
  data: any;
  selectedSponsor?: string | undefined;
  selectedSite?: string | undefined;
  enabled: boolean;
  menuItems: MenuListItem[];
  rootUrl: string;

  public constructor() {
    this.root = "All Studies";
    this.rootUrl = "/";
    this.menuItems = this.getMenu();
  }

  public getMenu() {
    const menuList = [
      new MenuListItem(
        "Demographics",
        faImagePortrait,
        "/study/:id/resconfig/:resId/demographics",
        resMenuItemCategories.Demographics,
        eIconTypes.FONTAWESOME
      ),
      new MenuListItem(
        "Education",
        faGraduationCap,
        "/study/:id/resconfig/:resId/education",
        resMenuItemCategories.Education,
        eIconTypes.FONTAWESOME
      ),
      new MenuListItem(
        "Experience (General)",
        faBook,
        "/study/:id/resconfig/:resId/experienceGeneral",
        resMenuItemCategories.ExperienceGeneral,
        eIconTypes.FONTAWESOME
      ),
      new MenuListItem(
        "Experience (Indication)",
        faMicroscope,
        "/study/:id/resconfig/:resId/experienceIndication",
        resMenuItemCategories.ExperienceIndication,
        eIconTypes.FONTAWESOME
      ),
      new MenuListItem(
        "Experience (Scales)",
        faScaleBalanced,
        "/study/:id/resconfig/:resId/experienceScales",
        resMenuItemCategories.ExperienceScales,
        eIconTypes.FONTAWESOME
      )
    ];

    return menuList;
  }
}
