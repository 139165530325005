// @ts-nocheck
import { combineReducers, Dispatch, Action, AnyAction } from "redux";
import { all, fork } from "redux-saga/effects";

import notificationSaga from "./notification/sagas";
import { INotificationState, notificationReducer } from "./notification";
import { IUser } from "../model";
import { userReducer } from "./user";
import { ISponsorState, sponsorReducer } from "./sponsor";
import { ISiteState, siteReducer } from "./site";
import { IStudyState, studyReducer } from "./study";
import { ILookupState, lookupReducer } from "./lookup";
import { IUtilState, utilReducer } from "./util";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import {
  IState as IResConfigState,
  reducer as resConfigReducer
} from "./resConfig";

/**
 * Top level state object
 */
export interface IApplicationState {
  notification: INotificationState;
  user: IUser;
  router: any;
  sponsor: ISponsorState;
  site: ISiteState;
  study: IStudyState;
  lookup: ILookupState;
  util: IUtilState;
  resConfig: IResConfigState;
}

/**
 * Additional props for connected React components. This prop is passed by default with 'connect()'
 */
export interface IConnectedReduxProp<A extends Action = AnyAction> {
  dispatch?: Dispatch<A>;
}

export const rootReducer = (history: History) =>
  combineReducers<IApplicationState>({
    notification: notificationReducer,
    user: userReducer,
    router: connectRouter(history),
    sponsor: sponsorReducer,
    site: siteReducer,
    study: studyReducer,
    lookup: lookupReducer,
    util: utilReducer,
    resConfig: resConfigReducer
  });

/**
 * using redux-saga to trigger  actions asynchronously
 */
export function* rootSaga() {
  yield all([fork(notificationSaga)]);
}

export * from "./notification";
export * from "./user";
export * from "./sponsor";
export * from "./site";
export * from "./study";
export * from "./lookup";
export * from "./util";
