import { Auditable } from "./auditable";
import { IScale, IMasterScale } from "../../model";

export class Scale extends Auditable implements IScale {
  private id: string;
  private name: string;
  private shortName: string;
  private masterScale: IMasterScale;

  get Id() {
    return this.id;
  }
  set Id(val) {
    this.id = val;
  }
  get Name() {
    return this.name;
  }
  set Name(val) {
    this.name = val;
  }
  get ShortName() {
    return this.shortName;
  }
  set ShortName(val) {
    this.shortName = val;
  }
  get MasterScale() {
    return this.masterScale;
  }
  set MasterScale(val) {
    this.masterScale = val;
  }
}
