import { mdiAccountCardDetails, mdiCommentMultiple } from "@mdi/js";
import { MenuListItem, IMenuList } from "../../../model";

export enum siteMenuItemCategories {
  General = "General",
  Comments = "Comments"
}

/**  Class created to define the site menu container implementing the IMenuList interface */
export class SiteMenuContainer implements IMenuList {
  // This will be the interface structure used for the component to make it generic
  public root: string;
  public data: any;
  public selectedSite: string;
  public enabled: boolean;
  public menuItems: MenuListItem[];
  public rootUrl: string;

  public constructor() {
    this.root = "All Sites";
    this.rootUrl = "/site/";
    this.selectedSite = this.enabled ? this.data.siteName : "Add New";
    this.menuItems = this.getMenu();
  }

  public getMenu() {
    const menuList = [
      new MenuListItem(
        "General Info",
        mdiAccountCardDetails,
        "/site/" + this.enabled ? "/site/:id" : "/site/new",
        siteMenuItemCategories.General
      ),
      new MenuListItem(
        "Comments",
        mdiCommentMultiple,
        "/site/:id/comment",
        siteMenuItemCategories.Comments
      )
    ];

    return menuList;
  }
}
