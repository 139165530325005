import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";

interface IProps {
  fields: string[];
}

const MddResGeneralElement = ({ fields }: IProps) => (
  <div className="mdd-res-draft--tab-content">
    <h3>Demographics Info</h3>
    <p className="mdd-res-draft--tab-content--paragraph">
      Demographics information fields included in Clinical Profile for the Rater
      Experience Survey (note: edits are not permitted).
    </p>
    <h4>Demographics Fields</h4>
    <ul
      className="mdd-res-draft--tab-content--fields"
      data-testid="mdd-res-draft-static-fields"
    >
      {fields.map(field => (
        <li key={field}>{field}</li>
      ))}
    </ul>
  </div>
);

export const MddResGeneral = connect(
  ({ resConfig: { general } }: IApplicationState) => ({
    fields: general.fields
  })
)(MddResGeneralElement);
