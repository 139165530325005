import { Auditable } from "./auditable";
import { ISponsor } from "../../model";

export class Sponsor extends Auditable implements ISponsor {
  private sponsorId: string;
  private sponsorName: string;
  private hasHistory: boolean;

  get SponsorId() {
    return this.sponsorId;
  }
  set SponsorId(val) {
    this.sponsorId = val;
  }
  get SponsorName() {
    return this.sponsorName;
  }
  set SponsorName(val) {
    this.sponsorName = val;
  }
  get HasHistory() {
    return this.hasHistory;
  }
  set HasHistory(val) {
    this.hasHistory = val;
  }
}
