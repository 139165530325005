import * as React from "react";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  CircularProgress
} from "@material-ui/core";
import { uniqBy } from "lodash";
import {
  MddDialog,
  MddDialogButtons,
  MddPanelContainer,
  MddProgress,
  MddValidatedTextField
} from "../../../components";
import { isLoading, sortObjectArray } from "../../../utils";
import { eStudySaga, eLookupSaga, ePersonnelSaga } from "../../../sagas";
import {
  IPersonnelAtSite,
  IPersonnelTableData,
  ILookup,
  IStudyPersonnelRole,
  ISelectChangeEvent
} from "../../../model";
import {
  SagaService,
  IAction,
  BktValidatedSelectChipField
} from "front-end-lib/core";

interface IMddAddStudySitePersonnelDialog {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  studyId: string;
  siteId: string;
}

export const MddAddStudySitePersonnelDialog = (
  props: IMddAddStudySitePersonnelDialog
) => {
  interface IFilterState {
    firstName: string;
    lastName: string;
    email: string;
  }

  // list that contains both the previous personnel retrieved from the API call which
  // is used for both the assigned and unassigned
  const [previousPersonnel, setPreviousPersonnel] = React.useState<
    IPersonnelTableData[]
  >([]);
  // personnel found from any search
  const [personnelFromSearch, setPersonnelFromSearch] = React.useState<
    IPersonnelTableData[]
  >([]);
  // personnel assigned from the results of a search
  const [newPersonnel, setNewPersonnel] = React.useState<IPersonnelTableData[]>(
    []
  );

  const [roles, setRoles] = React.useState<ILookup[]>([]);
  const [filterState, setFilterState] = React.useState<IFilterState>({
    firstName: "",
    lastName: "",
    email: ""
  });

  React.useEffect(() => {
    const { studyId, siteId } = props;

    const removeFetchPersonnelInSite = SagaService.subscribeToSaga(
      eStudySaga.FETCH_PERSONNEL_IN_SITE,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_PERSONNEL_IN_SITE_SUCCESS) {
          const persons: IPersonnelAtSite[] = action.payload;
          setPreviousPersonnel(
            sortByLastName(
              persons.map(person => {
                return Object.assign(
                  ({} as IPersonnelTableData,
                  {
                    ...person,
                    isSelected: false,
                    isAssigned: false,
                    roles: []
                  })
                );
              })
            )
          );
        }
      }
    );

    const removeFetchRoles = SagaService.subscribeToSaga(
      eLookupSaga.FETCH_ROLES,
      (action: IAction) => {
        if (action.type === eLookupSaga.FETCH_ROLES_SUCCESS) {
          // filter out the PI role first
          const rolesList: IStudyPersonnelRole[] = (action.payload as IStudyPersonnelRole[]).filter(
            role => !role.isSitePi
          );
          const roles: ILookup[] = rolesList.map(
            (role: IStudyPersonnelRole) => {
              return Object.assign(
                {},
                { value: role.roleId, label: role.name, key: role.roleId }
              );
            }
          );
          setRoles(roles);
        }
      }
    );

    const removeSavePersonnel = SagaService.subscribeToSaga(
      eStudySaga.SAVE_STUDY_SITE_PERSONNEL,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_STUDY_SITE_PERSONNEL_SUCCESS) {
          onClose();
          onSave();
        }
      }
    );

    const removeFetchPersonnelSearch = SagaService.subscribeToSaga(
      ePersonnelSaga.FETCH_MANAGED_PERSONNEL,
      (action: IAction) => {
        if (action.type === ePersonnelSaga.FETCH_MANAGED_PERSONNEL_SUCCESS) {
          const persons: IPersonnelAtSite[] = action.payload;
          setPersonnelFromSearch(
            sortByLastName(
              persons.map(person => {
                return Object.assign(
                  ({} as IPersonnelTableData,
                  {
                    ...person,
                    isSelected: false,
                    isAssigned: false,
                    roles: []
                  })
                );
              })
            )
          );
        }
      }
    );

    setNewPersonnel([]);
    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_PERSONNEL_IN_SITE,
      payload: { studyId, siteId }
    });
    SagaService.dispatchSaga({ type: eLookupSaga.FETCH_ROLES });

    return () => {
      removeFetchPersonnelInSite();
      removeFetchRoles();
      removeFetchPersonnelSearch();
      removeSavePersonnel();
    };
    // eslint-disable-next-line
  }, []);

  const showSpinner = () => {
    return isLoading(
      `undefined_${eStudySaga.FETCH_PERSONNEL_IN_SITE}`,
      `${eLookupSaga.FETCH_ROLES}`,
      `undefined_${eStudySaga.SAVE_STUDY_SITE_PERSONNEL}`
    );
  };

  const { open, onClose, onSave } = props;

  const sortByLastName = (persons: IPersonnelTableData[]) => {
    return sortObjectArray(persons, "lastName");
  };

  const toggleCheckbox = (
    currPerson: IPersonnelTableData,
    personsList: "previous" | "search" | "new"
  ) => {
    // find the person in the collection and toggle the selected flag
    const persons =
      personsList === "previous"
        ? previousPersonnel
        : personsList === "search"
        ? personnelFromSearch
        : newPersonnel;

    const newPeople = persons.map(person => {
      return Object.assign({} as IPersonnelTableData, {
        ...person,
        isSelected:
          (personsList === "previous" &&
            person.personnelId === currPerson.personnelId) ||
          (personsList !== "previous" &&
            person.userName === currPerson.userName)
            ? !person.isSelected
            : person.isSelected
      });
    });

    switch (personsList) {
      case "previous": {
        setPreviousPersonnel(newPeople);
        break;
      }
      case "search": {
        setPersonnelFromSearch(newPeople);
        break;
      }
      case "new": {
        setNewPersonnel(newPeople);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleRoleChange = (
    event: React.ChangeEvent<ISelectChangeEvent>,
    person: IPersonnelTableData,
    personsList: "previous" | "all"
  ) => {
    const personsData =
      personsList === "previous" ? previousPersonnel : newPersonnel;

    // Cast as any is necessary because the value is actually a string array
    const newRoles = (event.target.value as any).map((r: string) => {
      const existingRole = roles.find(role => {
        return role.value === r;
      });
      if (existingRole) {
        return Object.assign({} as ILookup, { ...existingRole });
      }
      return null;
    });

    // update the data for this person
    const newUsers = personsData.map(p => {
      // If we have a personnel ID use it since it's unique.  If not we have no choice
      // but to use username although everything in the previously in site should have the
      // personnel ID and anything in the search area should have a unique username so it
      // should all be good.
      const personFromKey = p.personnelId ? p.personnelId : p.userName;
      const personToKey = person.personnelId
        ? person.personnelId
        : person.userName;
      return Object.assign({} as IPersonnelTableData, {
        ...p,
        roles: personFromKey === personToKey ? newRoles : p.roles
      });
    });

    // update state
    if (personsList === "all") {
      setNewPersonnel(newUsers);
    } else {
      setPreviousPersonnel(newUsers);
    }
  };

  const getRoles = (roles: ILookup[]) => {
    // takes the roles list in ILookup format and converts to
    // the array of just id's that the chipped input needs
    return roles.map(role => {
      return role.value;
    });
  };

  const personCanBeAssigned = (person: IPersonnelTableData) => {
    if (
      person.existsInCurrentStudySite === undefined ||
      person.existsInCurrentStudySite === true
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isUnassignedPersonSelected = (list: IPersonnelTableData[]) => {
    return list
      ? list.find(person => {
          return !person.isAssigned && person.isSelected;
        }) !== undefined
      : false;
  };

  const isAssignedPersonSelected = (list: IPersonnelTableData[]) => {
    return list
      ? list.find(person => {
          return person.isAssigned && person.isSelected;
        }) !== undefined
      : false;
  };

  const isSomethingAssigned = (list: IPersonnelTableData[]) => {
    return list.find(person => {
      return person.isAssigned;
    }) !== undefined
      ? true
      : false;
  };

  const isSomethingUnassigned = (list: IPersonnelTableData[]) => {
    return list.find(person => {
      return !person.isAssigned && !person.existsInCurrentStudySite;
    }) !== undefined
      ? true
      : false;
  };

  const assignSelected = (personsList: "previous" | "all") => {
    if (personsList === "previous") {
      const newPersons = sortByLastName(
        previousPersonnel.map(person => {
          return Object.assign({} as IPersonnelTableData, {
            ...person,
            isAssigned:
              !person.isAssigned && person.isSelected
                ? true
                : person.isAssigned,
            isSelected:
              !person.isAssigned && person.isSelected
                ? false
                : person.isSelected
          });
        })
      );
      setPreviousPersonnel(newPersons);
    } else {
      // assigning from the search - move it from the search list to the all list
      //
      // First get an array of persons filtered by selected - this goes into the new people list.
      // Note that we also need to unselect all of them and assign them.
      const newAssigned = personnelFromSearch
        .filter(person => person.isSelected)
        .map(person =>
          Object.assign({} as IPersonnelTableData, {
            ...person,
            isSelected: false,
            isAssigned: true
          })
        );
      // Finally, merge this list into any existing users and sort by last name.
      const newPersons = sortByLastName(
        uniqBy([...newPersonnel, ...newAssigned], person =>
          person.userName.toUpperCase()
        )
      );

      setNewPersonnel(newPersons);

      // Now filter out all the selected people from the search list
      const newSearchList = personnelFromSearch.filter(
        person => !person.isSelected
      );
      setPersonnelFromSearch(newSearchList);
    }
  };

  const unassignSelected = (personsList: "previous" | "all") => {
    if (personsList === "previous") {
      // Move just the selected assigned items to the unassigned list
      const newPersons = sortByLastName(
        previousPersonnel.map(person => {
          return Object.assign({} as IPersonnelTableData, {
            ...person,
            isAssigned:
              person.isAssigned && person.isSelected
                ? false
                : person.isAssigned,
            isSelected:
              person.isAssigned && person.isSelected ? false : person.isSelected
          });
        })
      );
      setPreviousPersonnel(newPersons);
    } else {
      // Unassigning from new personnel which just go to the bit bucket.  We'll filter out the
      // selected persons from the list and be done with it.
      setNewPersonnel(newPersonnel.filter(person => !person.isSelected));
    }
  };

  const assignAllPrevious = () => {
    // Move all unassigned persons in the list to assigned

    const newPersons = previousPersonnel.map(person => {
      return {
        ...person,
        isAssigned: personCanBeAssigned(person) ? true : false,
        isSelected: false
      };
    });
    setPreviousPersonnel(newPersons);
  };

  const assignAllNew = () => {
    // Move all unassigned persons in the list of people found from search to assigned new persons list

    // combine the two arrays, remove duplicates and sort by last name
    const newPersons = sortByLastName(
      uniqBy([...personnelFromSearch, ...newPersonnel], person =>
        person.userName.toUpperCase()
      )
    );

    // clear out the search list and the filters
    setPersonnelFromSearch([]);
    setFilterState({ firstName: "", lastName: "", email: "" });

    // set all in the list to assigned
    setNewPersonnel(
      sortByLastName(
        newPersons.map(person => {
          return Object.assign({} as IPersonnelTableData, {
            ...person,
            isAssigned: true
          });
        })
      )
    );
  };

  const assignAll = (personList: "previous" | "all") => {
    if (personList === "previous") {
      assignAllPrevious();
    } else {
      assignAllNew();
    }
  };

  const unassignAll = (personList: "previous" | "all") => {
    if (personList === "previous") {
      // Move all assigned persons in the given list to unassigned
      const newPersons = previousPersonnel.map(person => {
        return { ...person, isAssigned: false, isSelected: false };
      });
      setPreviousPersonnel(newPersons);
    } else {
      // Unassigning all new persons - just send them to the bitbucket
      setNewPersonnel([]);
    }
  };

  const handleFilterChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    field: string
  ) => {
    // update the filter vals in state
    const newState = Object.assign({} as IFilterState, {
      ...filterState,
      [field]: e.target.value
    });
    setFilterState(newState);

    // do the search with the new filters (if any)
    if (newState.firstName || newState.lastName || newState.email) {
      const payload = {
        dto: {
          FirstName: newState.firstName,
          LastName: newState.lastName,
          Email: newState.email
        }
      };
      setPersonnelFromSearch([]);
      SagaService.dispatchSaga({
        type: ePersonnelSaga.FETCH_MANAGED_PERSONNEL,
        payload
      });
    } else {
      // all filters cleared so clear out search results
      setPersonnelFromSearch([]);
    }
  };

  const handleSave = () => {
    const { studyId, siteId } = props;
    // Combine the two lists removing duplicates.  We need to only take
    // assigned persons from the previousPersonnel list.
    const payload = {
      studyId,
      siteId,
      persons: sortByLastName(
        uniqBy(
          [
            ...newPersonnel,
            ...previousPersonnel.filter(person => person.isAssigned)
          ],
          person => person.userName.toUpperCase()
        )
      )
    };

    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_STUDY_SITE_PERSONNEL,
      payload
    });
  };

  const isRoleMissing = () => {
    // check both the previous and new personnel lists to see if any assigned people are missing a role
    const missingRolesInPrevious = previousPersonnel.length
      ? previousPersonnel.find(
          person => person.isAssigned && person.roles.length === 0
        )
      : undefined;
    if (!missingRolesInPrevious) {
      if (newPersonnel.length) {
        return newPersonnel.find(person => person.roles.length === 0)
          ? true
          : false;
      }
      return false;
    }
    return true;
  };

  const isAddDisabled =
    (!isSomethingAssigned(previousPersonnel) &&
      !isSomethingAssigned(newPersonnel)) ||
    isRoleMissing();

  const showNoData =
    !isLoading(`undefined_${ePersonnelSaga.FETCH_MANAGED_PERSONNEL}`) &&
    personnelFromSearch.length === 0 &&
    (filterState.email !== "" ||
      filterState.firstName !== "" ||
      filterState.lastName !== "");

  const renderButtonPanel = (personList: "previous" | "all") => {
    return (
      <React.Fragment>
        <div>
          <Button
            id={`btnAssignAll_${personList}`}
            data-testid={`btnAssignAll_${personList}`}
            color="primary"
            variant="contained"
            disabled={
              !isSomethingUnassigned(
                personList === "all" ? personnelFromSearch : previousPersonnel
              )
            }
            onClick={() => assignAll(personList)}
          >
            {">>"}
          </Button>
          <Button
            id={`btnAssignSelected_${personList}`}
            data-testid={`btnAssignSelected_${personList}`}
            color="primary"
            variant="contained"
            disabled={
              !isUnassignedPersonSelected(
                personList === "all" ? personnelFromSearch : previousPersonnel
              )
            }
            onClick={() => assignSelected(personList)}
          >
            {">"}
          </Button>
          <Button
            id={`btnUnassignSelected_${personList}`}
            data-testid={`btnUnassignSelected_${personList}`}
            color="primary"
            variant="contained"
            disabled={
              !isAssignedPersonSelected(
                personList === "all" ? newPersonnel : previousPersonnel
              )
            }
            onClick={() => unassignSelected(personList)}
          >
            {"<"}
          </Button>
          <Button
            id={`btnUnassignAll_${personList}`}
            data-testid={`btnUnassignAll_${personList}`}
            color="primary"
            variant="contained"
            disabled={
              !isSomethingAssigned(
                personList === "all" ? newPersonnel : previousPersonnel
              )
            }
            onClick={() => unassignAll(personList)}
          >
            {"<<"}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderUnassignedPersons = (
    personsData: IPersonnelTableData[],
    personsList: "previous" | "all"
  ) => {
    return (
      <React.Fragment>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left">First Name</TableCell>
              <TableCell align="left">Last Name</TableCell>
              <TableCell align="left">Email</TableCell>
            </TableRow>
            {personsList === "all" && (
              // search boxes
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">
                  <MddValidatedTextField
                    id="searchFirstName"
                    textFieldProps={{
                      textProps: {
                        placeholder: "Enter first name",
                        value: filterState.firstName,
                        onChange: e => handleFilterChange(e, "firstName")
                      }
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <MddValidatedTextField
                    id="searchLastName"
                    textFieldProps={{
                      textProps: {
                        placeholder: "Enter last name",
                        value: filterState.lastName,
                        onChange: e => handleFilterChange(e, "lastName")
                      }
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <MddValidatedTextField
                    id="searchEmail"
                    textFieldProps={{
                      textProps: {
                        placeholder: "Enter email address",
                        value: filterState.email,
                        onChange: e => handleFilterChange(e, "email")
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            <React.Fragment>
              {personsData.map(person => {
                const isDisabled = person.existsInCurrentStudySite;

                return !person.isAssigned ? (
                  <TableRow key={person.personnelId || person.userName} hover>
                    <TableCell padding="checkbox">
                      <Checkbox
                        id={`checkbox_${person.userName}`}
                        data-testid={`checkbox_${person.userName}`}
                        checked={person.isSelected}
                        onChange={() =>
                          toggleCheckbox(
                            person,
                            personsList === "previous" ? personsList : "search"
                          )
                        }
                        disabled={isDisabled}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <span
                        className={
                          isDisabled
                            ? "mdd-add-study-site-personnel-dialog--disabled-text"
                            : ""
                        }
                      >
                        {person.firstName}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      <span
                        className={
                          isDisabled
                            ? "mdd-add-study-site-personnel-dialog--disabled-text"
                            : ""
                        }
                      >
                        {person.lastName}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      <span
                        className={
                          isDisabled
                            ? "mdd-add-study-site-personnel-dialog--disabled-text"
                            : ""
                        }
                      >
                        {person.email}
                      </span>
                    </TableCell>
                  </TableRow>
                ) : null;
              })}
            </React.Fragment>
          </TableBody>
        </Table>
        {personsList === "all" &&
          isLoading(`undefined_${ePersonnelSaga.FETCH_MANAGED_PERSONNEL}`) && (
            <div className="mdd-add-study-site-personnel-dialog--searching--container">
              <div className="mdd-add-study-site-personnel-dialog--searching--spinner">
                <CircularProgress />
              </div>
            </div>
          )}
        {personsList === "all" && showNoData && (
          <div className="mdd-study-site--no-data--container">
            <div className="mdd-study-site--no-data--text">
              No results found
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderAssignedPersons = (
    personsData: IPersonnelTableData[],
    personsList: "previous" | "all"
  ) => {
    return (
      <Table
        className="mdd-add-study-site-personnel-dialog--assigned-table"
        stickyHeader={true}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">First Name</TableCell>
            <TableCell align="left">Last Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {personsData.map(person => {
            return person.isAssigned ? (
              <TableRow key={person.personnelId || person.userName} hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    id={`checkbox_${person.userName}`}
                    data-testid={`checkbox_${person.userName}`}
                    checked={person.isSelected}
                    onChange={() =>
                      toggleCheckbox(
                        person,
                        personsList === "previous" ? personsList : "new"
                      )
                    }
                  />
                </TableCell>
                <TableCell align="left">{person.firstName}</TableCell>
                <TableCell align="left">{person.lastName}</TableCell>
                <TableCell align="left">{person.email}</TableCell>
                <TableCell align="left">
                  {" "}
                  <BktValidatedSelectChipField
                    key={`fldRole_${person.userName}`}
                    id={`fldRole_${person.userName}`}
                    data-testid={`fldRole_${person.userName}`}
                    name={"Role"}
                    data={roles}
                    value={getRoles(person.roles)}
                    onChange={(event: React.ChangeEvent<ISelectChangeEvent>) =>
                      handleRoleChange(event, person, personsList)
                    }
                    frmControlProps={{
                      required: false,
                      "aria-labelledby": "role"
                    }}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          })}
        </TableBody>
      </Table>
    );
  };

  const renderDialogContent = () => {
    return (
      <React.Fragment>
        <MddPanelContainer
          className="mdd-add-study-site-personnel-dialog--panel-container"
          panels={[
            {
              size: "custom",
              customSize: "46",
              border: false,
              content: (
                <span
                  id="previousPersonnelLabel"
                  data-testid="previousPersonnelLabel"
                >
                  Personnel Previously at Site
                </span>
              ),
              className: "mdd-add-study-site-personnel-dialog--panel--header"
            },
            {
              size: "custom",
              customSize: "9",
              border: false,
              content: null,
              className: "mdd-add-study-site-personnel-dialog--panel--header"
            },
            {
              size: "custom",
              customSize: "46",
              border: false,
              content: (
                <span
                  id="selectedPersonnelLabel"
                  data-testid="selectedPersonnelLabel"
                >
                  Selected Personnel
                </span>
              ),
              className: "mdd-add-study-site-personnel-dialog--panel--header"
            }
          ]}
        />
        <MddPanelContainer
          className="mdd-add-study-site-personnel-dialog--panel-container"
          panels={[
            {
              size: "custom",
              customSize: "50",
              content: renderUnassignedPersons(previousPersonnel, "previous"),
              className: "mdd-add-study-site-personnel-dialog--panel",
              id: "panel_unassignedPrevious"
            },
            {
              size: "custom",
              customSize: "5",
              border: false,
              content: renderButtonPanel("previous"),
              className: "mdd-add-study-site-personnel-dialog--panel--buttons",
              id: "panel_buttonsPrevious"
            },
            {
              size: "custom",
              customSize: "50",
              content: renderAssignedPersons(previousPersonnel, "previous"),
              className: "mdd-add-study-site-personnel-dialog--panel",
              id: "panel_assignedPrevious"
            }
          ]}
        />
        <hr color="#e1e1e1" />
        <MddPanelContainer
          className="mdd-add-study-site-personnel-dialog--panel-container"
          panels={[
            {
              size: "custom",
              customSize: "46",
              border: false,
              content: (
                <span id="allUsersLabel" data-testid="allUsersLabel">
                  All Users
                </span>
              ),
              className: "mdd-add-study-site-personnel-dialog--panel--header"
            },
            {
              size: "custom",
              customSize: "9",
              border: false,
              content: null,
              className: "mdd-add-study-site-personnel-dialog--panel--header"
            },
            {
              size: "custom",
              customSize: "46",
              border: false,
              content: (
                <span
                  id="allUsersSelectedLabel"
                  data-testid="allUsersSelectedLabel"
                >
                  Selected Personnel
                </span>
              ),
              className: "mdd-add-study-site-personnel-dialog--panel--header"
            }
          ]}
        />
        <MddPanelContainer
          className="mdd-add-study-site-personnel-dialog--panel-container"
          panels={[
            {
              size: "custom",
              customSize: "50",
              content: renderUnassignedPersons(personnelFromSearch, "all"),
              className: "mdd-add-study-site-personnel-dialog--panel",
              id: "panel_unassignedAll"
            },
            {
              size: "custom",
              customSize: "5",
              border: false,
              content: renderButtonPanel("all"),
              className: "mdd-add-study-site-personnel-dialog--panel--buttons",
              id: "panel_buttonsAll"
            },
            {
              size: "custom",
              customSize: "50",
              content: renderAssignedPersons(newPersonnel, "all"),
              className: "mdd-add-study-site-personnel-dialog--panel",
              id: "panel_assignedAll"
            }
          ]}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <MddProgress inProgress={showSpinner()} spinnerId="svgLoading" />
      <MddDialog
        showCloseButton={true}
        onCloseClick={onClose}
        dialogProps={{
          id: "divAddNewPersonnelDialog",
          open: open,
          maxWidth: "xl",
          fullWidth: true
        }}
        dialogActionProps={{ id: "divAddNewPersonnelAction" }}
        dialogContentProps={{
          id: "divAddNewPersonnelContent",
          className: "mdd-dialog--content-100percent"
        }}
        dialogTitleProps={{
          id: "divAddNewPersonnelTitle",
          title: "Add New Personnel"
        }}
        dialogContent={renderDialogContent()}
        dialogActions={
          <MddDialogButtons
            saveButtonText="Add"
            saveButtonProps={{
              className: "mdd-add-study-site-personnel-dialog--action-button",
              disabled: isAddDisabled,
              onClick: handleSave
            }}
            closeButtonProps={{
              className: "mdd-add-study-site-personnel-dialog--action-button",
              onClick: onClose
            }}
          />
        }
      />
    </React.Fragment>
  );
};
