import * as React from "react";
import InactiveIcon from "@material-ui/icons/Warning";

interface IMddStatusProps {
  isActive: boolean;
}

export const MddStatus = (props: IMddStatusProps) => {
  const { isActive } = props;

  return isActive ? (
    <span>Active</span>
  ) : (
    <span className="mdd-icon-span--text">
      Inactive
      <span className="mdd-icon-span--icon">
        <InactiveIcon fontSize="small" data-testid="inactiveIcon" />
      </span>
    </span>
  );
};
