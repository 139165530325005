import { mdiStarCircle, mdiAccountBox, mdiInformation } from "@mdi/js";
import { MenuListItem, IMenuList } from "../../../model";

export enum studySitePersonnelMenuItemCategories {
  GeneralInfo = "General",
  Experience = "Experience",
  Training = "Training"
}

export class StudySitePersonnelMenuContainer implements IMenuList {
  // This will be the interface structure used for the component to make it generic
  public root: string;
  public data: any;
  public enabled: boolean;
  public menuItems: MenuListItem[];
  public rootUrl: string;
  public constructor() {
    this.root = "All Studies";
    this.rootUrl = "/";
    this.menuItems = this.getMenu();
  }
  public getMenu() {
    const menuList = [
      new MenuListItem(
        "General Info",
        mdiAccountBox,
        "/study/:id/sites/:siteId/personnel/:personnelId",
        studySitePersonnelMenuItemCategories.GeneralInfo
      ),
      new MenuListItem(
        "Experience",
        mdiStarCircle,
        "/study/:id/sites/:siteId/personnel/:personnelId/experience",
        studySitePersonnelMenuItemCategories.Experience
      ),
      new MenuListItem(
        "Training",
        mdiInformation,
        "/study/:id/sites/:siteId/personnel/:personnelId/training",
        studySitePersonnelMenuItemCategories.Training
      )
    ];
    return menuList;
  }
}
