import {
  IStudySitePersonnelDTO,
  IPerson,
  IRole,
  IStudyProtocol
} from "../../../model";
import { Person } from "../person";

export class StudySitePersonnelDTO implements IStudySitePersonnelDTO {
  private studyId: string;
  private siteId: string;
  private person: IPerson;
  private roles: IRole[];
  private protocols: IStudyProtocol[];

  get StudyId() {
    return this.studyId;
  }
  set StudyId(val) {
    this.studyId = val;
  }

  get SiteId() {
    return this.siteId;
  }
  set SiteId(val) {
    this.siteId = val;
  }

  get Person() {
    return this.person;
  }
  set Person(val) {
    this.person = val;
  }

  get Roles() {
    return this.roles;
  }
  set Roles(val) {
    this.roles = val;
  }

  get Protocols() {
    return this.protocols;
  }
  set Protocols(val) {
    this.protocols = val;
  }

  constructor(
    studyId: string = "",
    siteId: string = "",
    person?: IPerson,
    roles?: IRole[],
    protocols?: IStudyProtocol[]
  ) {
    this.studyId = studyId;
    this.siteId = siteId;
    this.Person = person || new Person();
    this.Roles = roles || [];
    this.Protocols = protocols || [];
  }
}
