export enum eUtilSaga {
  LOADING = "@@util/LOADING",
  DEPENDENT_LOADING = "@@util/DEPENDENT_LOADING"
}

export function* dispatchLoadingSaga(args: any[]) {
  yield console.log(
    "placeholder for dispatching loading to state. This should be dispatched. Not run.",
    args
  );
}

export function* dispatchDependentLoadingSaga(args: any[]) {
  yield console.log(
    "placeholder for dispatching loading to state. This should be dispatched. Not run.",
    args
  );
}
