export enum AssignmentStatus {
  NotAvailable = "NotAvailable",
  ResNotPublished = "ResNotPublished",
  NotAssigned = "NotAssigned",
  Unassigned = "Unassigned",
  Assigned = "Assigned",
  Completed = "Completed"
}

export interface ICpResAssignmentStatus {
  status: AssignmentStatus;
  hasCPAccess: boolean;
  latestAssignmentDate?: string;
  latestAnsweredDate?: string;
}
