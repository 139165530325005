import * as React from "react";
import { isEqual } from "lodash";
import Button from "@material-ui/core/Button";
import {
  SagaService,
  IAction,
  BktValidatedTextField
} from "front-end-lib/core";
import { Sponsor, Validations, IMddSponsorProps } from "../../../model";
import { BktBreadcrumb, withMddSponsor, MddForm } from "../../../components";
import { eSponsorSaga } from "../../../sagas";
import { MAX_TEXT_LENGTH } from "../../../constants";

const _BktAddEditSponsor = (props: IMddSponsorProps) => {
  const [newSponsor, setNewSponsor] = React.useState(props.sponsor);
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const { sponsor, loadingKeys } = props;
  const { id } = props.match.params;

  const sponsorNameId = "sponsorNameId";

  React.useEffect(() => {
    const removeFetchSub = SagaService.subscribeToSaga(
      eSponsorSaga.FETCH_SPONSOR,
      (action: IAction) => {
        if (action.type === eSponsorSaga.FETCH_SPONSOR_SUCCESS) {
          setNewSponsor(action.payload);
        }
      }
    );
    return () => {
      removeFetchSub();
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (id) {
      setNewSponsor(sponsor);
    }
  }, [sponsor, id]);

  const submissionDisabled = () => {
    const loading = loadingKeys.some(
      lk =>
        lk === `undefined_${eSponsorSaga.FETCH_SPONSOR}` ||
        lk === `undefined_${eSponsorSaga.MODIFY_SPONSOR}`
    );
    return (
      loading ||
      validationErrors.length > 0 ||
      !newSponsor.SponsorName ||
      isEqual(sponsor, newSponsor)
    );
  };

  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    SagaService.dispatchSaga({
      type: eSponsorSaga.MODIFY_SPONSOR,
      payload: {
        sponsorId: newSponsor.SponsorId,
        sponsorName: newSponsor.SponsorName.trim()
      }
    });
  };

  return (
    <div className="mdd-form--fullheight">
      <BktBreadcrumb
        rootText="All Sponsors"
        rootUrl="/sponsor"
        currentLocation={
          loadingKeys.some(
            lk => lk === `undefined_${eSponsorSaga.FETCH_SPONSOR}`
          )
            ? ""
            : sponsor.SponsorId
            ? sponsor.SponsorName
            : "Add Sponsor"
        }
      />
      <MddForm
        keys={[
          `undefined_${eSponsorSaga.FETCH_SPONSOR}`,
          `undefined_${eSponsorSaga.MODIFY_SPONSOR}`
        ]}
      >
        <div className="mdd-form--body">
          <BktValidatedTextField
            validationForOnChange={[Validations.sponsorNameAllowedCharacters]}
            validationForOnBlur={[
              Validations.required,
              Validations.sponsorNameAllowedCharacters
            ]}
            validationerror={(validationError: boolean) =>
              setValidationErrors(
                validationError
                  ? [...validationErrors, sponsorNameId]
                  : validationErrors.filter(v => v !== sponsorNameId)
              )
            }
            textFieldProps={{
              debounceInterval: 0,
              textProps: {
                id: sponsorNameId,
                className: "mdd-form--text",
                label: "Sponsor Name",
                placeholder: "Sponsor Name",
                inputProps: { maxLength: MAX_TEXT_LENGTH.SPONSOR_NAME },
                variant: "standard",
                required: true,
                onChange: (event: any) =>
                  setNewSponsor(
                    Object.assign(new Sponsor(), newSponsor, {
                      SponsorName: event.target.value
                    })
                  ),
                value: newSponsor.SponsorName || ""
              }
            }}
          />
          <div className="mdd-form__button--container">
            <Button
              id="saveButtonId"
              data-testid="btnSave"
              color="primary"
              disableFocusRipple={true}
              disableRipple={true}
              disabled={submissionDisabled()}
              variant="contained"
              type="submit"
              onClick={handleSave}
              className="mdd-form__button"
            >
              SAVE
            </Button>
          </div>
        </div>
      </MddForm>
    </div>
  );
};

export const BktAddEditSponsor = withMddSponsor()(_BktAddEditSponsor);
