export const defaultDataTableConfig = {
  containerProps: {
    id: "tableWrapper",
    className: "tbl--container",
    style: { boxShadow: "0px 0px 0px 0px" }
  },
  tableProps: { id: "tableContainer" },
  tableHeadProps: { id: "tableHeader", rowProps: { id: "tableHeaderRow" } },
  tableBodyProps: {
    id: "tableBody",
    rowProps: {
      id: "tableBodyRow",
      className: "tbl--body-row mdd-tbl--body-row"
    }
  }
};

export * from "./studyDataTableConfig";
export * from "./sponsorProgramDataTableConfig";
export * from "./studyProtocolDataTableConfig";
export * from "./scaleDataTableConfig";
export * from "./siteCommentsDataTableConfig";
export * from "./personnelDataTableConfig";
export * from "./studySitePersonnelDataTableConfig";
export * from "./showStudySitesConfig";
export * from "./raterExperienceSurvey/demographicsGeneralDataTableConfig";
