import * as React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tab,
  Tabs
} from "@material-ui/core";
import { MddProgress } from "../../../components";
import {
  showTrainingSpinner,
  getIsCorrectDisplayValue
} from "./MddTrainingHelpers";
import { NO_ANSWER_DISPLAY_TEXT } from "../../../constants";
import { IPersonnelTrainingScoreDetail, ICourseStatus } from "../../../model";
import { eStudySaga } from "../../../sagas";
import { SagaService, IAction } from "front-end-lib/core";
import { toDateFormatNoOffset } from "../../../utils";
import {
  MddPanelContainer,
  MddNoDataDiv,
  MddDialog,
  MddDialogButtons
} from "../..";
import { metricsFields } from "./MddTrainingHelpers";

interface IMddStudySitePersonViewCourseScores {
  course: ICourseStatus;
  studyId: string;
  personnelId: string;
  handleCourseScoresClose: () => void;
}

export const MddStudySitePersonViewCourseScores = (
  props: IMddStudySitePersonViewCourseScores
) => {
  const TAB_METRICS = 0;
  const TAB_METADATA = 1;

  const [
    scores,
    setScores
  ] = React.useState<IPersonnelTrainingScoreDetail | null>(null);

  const [tabIndex, setTabIndex] = React.useState(TAB_METRICS);

  React.useEffect(() => {
    const { course, studyId, personnelId } = props;

    const removeFetchScores = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES_SUCCESS
        ) {
          const data: IPersonnelTrainingScoreDetail = action.payload;
          // Massage the data so that the course status value is at the top level to
          // make things easier to display.  Also format the date.
          setScores(
            Object.assign(
              {},
              {
                ...data,
                courseStatusValue: data.courseStatus.name,
                statusDate: toDateFormatNoOffset(data.statusDate)
              }
            )
          );
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES,
      payload: {
        studyId,
        personnelId,
        courseId: course.courseId,
        studyComponentId: course.studyComponentId
      }
    });

    return () => {
      removeFetchScores();
    };
    // eslint-disable-next-line
  }, []);

  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue);
  };

  const renderQuestions = () => {
    return (
      <div className="mdd-training--course-scores--panel--table">
        <Table stickyHeader={true}>
          <TableHead
            id="scores_questions_table_hdr"
            data-testid="scores_questions_table_hdr"
          >
            <TableRow>
              <TableCell
                align="left"
                id="hdrQuestion"
                data-testid="hdrQuestion"
              >
                Question
              </TableCell>
              <TableCell
                align="left"
                id="hdrResponse"
                data-testid="hdrResponse"
              >
                Response
              </TableCell>
              <TableCell align="left" id="hdrCorrect" data-testid="hdrCorrect">
                Correct
              </TableCell>
              <TableCell
                align="left"
                id="hdrAcceptableResponse"
                data-testid="hdrAcceptableResponse"
              >
                Acceptable Response
              </TableCell>
            </TableRow>
          </TableHead>
          {scores && scores.questionAnswers.length > 0 && (
            <TableBody>
              {scores.questionAnswers.map((question, index) => {
                return (
                  <TableRow
                    key={`question_${index}`}
                    id={`question_${index}_row`}
                    data-testid={`question_${index}_row`}
                    hover
                  >
                    <TableCell
                      id={`question_${index}_questionText`}
                      data-testid={`question_${index}_questionText`}
                    >
                      {question.courseQuestionText}
                    </TableCell>
                    <TableCell
                      id={`question_${index}_response`}
                      data-testid={`question_${index}_response`}
                    >
                      <span>{question.userResponse}</span>
                    </TableCell>
                    <TableCell
                      id={`question_${index}_correct`}
                      data-testid={`question_${index}_correct`}
                    >
                      {getIsCorrectDisplayValue(question.isResponseCorrect)}
                    </TableCell>
                    <TableCell
                      id={`question_${index}_acceptableResponse`}
                      data-testid={`question_${index}_acceptableResponse`}
                    >
                      {question.acceptableResponse}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        {(!scores || !scores.questionAnswers.length) && <MddNoDataDiv />}
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className="mdd-training--tabs">
        <Tabs value={tabIndex}>
          <Tab
            className="mdd-training--tab"
            id="scores_metrics_tab"
            data-testid="scores_metrics_tab"
            value={TAB_METRICS}
            label="Metrics"
            onClick={() => handleTabChange(TAB_METRICS)}
          ></Tab>
          <Tab
            className="mdd-training--tab"
            id="scores_metadata_tab"
            data-testid="scores_metadata_tab"
            value={TAB_METADATA}
            label="Metadata"
            onClick={() => handleTabChange(TAB_METADATA)}
          ></Tab>
        </Tabs>
      </div>
    );
  };
  const renderMetrics = () => {
    return (
      <React.Fragment>
        {renderTabs()}
        <div className="mdd-training--course-scores--panel--table mdd-edit-course-scores--details--padding">
          <Table stickyHeader={true}>
            <TableHead
              id="scores_metrics_table_hdr"
              data-testid="scores_metrics_table_hdr"
            >
              <TableRow>
                <TableCell align="left" id="hdrItem" data-testid="hdrItem">
                  Item
                </TableCell>
                <TableCell align="left" id="hdrValue" data-testid="hdrValue">
                  Value
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metricsFields.map(field => {
                return (
                  <TableRow
                    key={`${field.label}`}
                    id={`metrics_${field.field}_row`}
                    data-testid={`metrics_${field.field}_row`}
                    hover
                  >
                    <TableCell
                      id={`metrics_${field.field}_label`}
                      data-testid={`metrics_${field.field}_label`}
                    >
                      {field.label}
                    </TableCell>
                    <TableCell
                      id={`metrics_${field.field}_value`}
                      data-testid={`metrics_${field.field}_value`}
                    >
                      {scores ? getScoreDisplayValue(scores[field.field]) : ""}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </React.Fragment>
    );
  };

  const renderDetails = () => {
    return (
      <React.Fragment>
        <div>{renderTabs()}</div>
        <div className="mdd-edit-course-scores--details--padding">
          <div
            className="mdd-edit-course-scores--details--label"
            data-testid="metadata_modifiedBy_label"
          >
            Modified By
          </div>
          <div
            className="mdd-edit-course-scores--details--value"
            data-testid="metadata_modifiedBy_value"
          >
            {(scores && scores.modifiedBy) || NO_ANSWER_DISPLAY_TEXT}
          </div>
          <div
            className="mdd-edit-course-scores--details--label"
            data-testid="metadata_modifiedDate_label"
          >
            Modified Date
          </div>
          <div
            className="mdd-edit-course-scores--details--value"
            data-testid="metadata_modifiedDate_value"
          >
            {(scores &&
              scores.modifiedDate &&
              toDateFormatNoOffset(scores.modifiedDate)) ||
              NO_ANSWER_DISPLAY_TEXT}
          </div>
          <div
            className="mdd-edit-course-scores--details--label"
            data-testid="metadata_statusDate_label"
          >
            Course Completion Date
          </div>
          <div
            className="mdd-edit-course-scores--details--value"
            data-testid="metadata_statusDate_value"
          >
            {(scores && scores.statusDate) || NO_ANSWER_DISPLAY_TEXT}
          </div>
          <React.Fragment>
            <div
              className="mdd-edit-course-scores--details--label"
              data-testid="metadata_qcUser_label"
            >
              QC User
            </div>
            <div
              className="mdd-edit-course-scores--details--value"
              data-testid="metadata_qcUser_value"
            >
              {(scores && scores.qcUser) || NO_ANSWER_DISPLAY_TEXT}
            </div>
            <div
              className="mdd-edit-course-scores--details--label"
              data-testid="metadata_qcDate_label"
            >
              QC Date
            </div>
            <div
              className="mdd-edit-course-scores--details--value"
              data-testid="metadata_qcDate_value"
            >
              {(scores &&
                scores.qcDate &&
                toDateFormatNoOffset(scores.qcDate)) ||
                NO_ANSWER_DISPLAY_TEXT}
            </div>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  };

  const getScoreDisplayValue = (score: string | number) => {
    if (typeof score === "string") {
      return score || "";
    } else {
      return score === null || score === undefined ? "" : score;
    }
  };

  const renderContent = () => {
    return (
      <MddPanelContainer
        className="mdd-training--course-scores--panel--container"
        panels={[
          {
            title: "Course Questions",
            size: "half",
            content: renderQuestions(),
            className: "mdd-training--course-scores--panel"
          },
          {
            title: "",
            size: "half",
            content:
              tabIndex === TAB_METADATA ? renderDetails() : renderMetrics(),
            className: "mdd-training--course-scores--panel"
          }
        ]}
      />
    );
  };

  const { handleCourseScoresClose, course } = props;

  return (
    <React.Fragment>
      <MddProgress inProgress={showTrainingSpinner()} spinnerId="svgLoading" />
      <MddDialog
        showCloseButton={true}
        onCloseClick={() => handleCourseScoresClose()}
        dialogProps={{
          className: "mdd-training--course-scores--dialog",
          id: "divCourseScoresDialog",
          open: true,
          maxWidth: "xl",
          fullWidth: true
        }}
        dialogActionProps={{
          id: "divCourseScoresDialogActions",
          className: "mdd-training--course-scores--dialog--actions"
        }}
        dialogContentProps={{
          id: "divCourseScoresDialogContent",
          className: "mdd-training--course-scores--dialog--content"
        }}
        dialogTitleProps={{
          id: "divCourseScoresDialogTitle",
          title: (course && course.courseName) || ""
        }}
        dialogContent={renderContent()}
        dialogActions={
          <React.Fragment>
            <MddDialogButtons
              saveButtonText={"Close"}
              //   closeButtonText="Cancel"
              saveButtonProps={{
                id: "btnCourseScoresClose",
                disabled: false,
                className: "mdd-training--course-scores--dialog--button-close",
                onClick: () => {
                  handleCourseScoresClose();
                }
              }}
              closeButtonProps={{
                className: "mdd-training--course-scores--button-hidden",
                id: "btnCourseScoresHiddenButton",
                onClick: () => {
                  handleCourseScoresClose();
                }
              }}
            />
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};
