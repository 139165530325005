import { DialogContent, DialogContentText } from "@material-ui/core";
import { MddDialog, MddDialogButtons } from "../../../components/common";
import React from "react";

interface IProps {
  open: boolean;
  onClose: (confirmed?: boolean) => void;
}

export const MddResDraftClose = ({ open, onClose }: IProps) => {
  return (
    <MddDialog
      dialogProps={{ maxWidth: "sm", open: open }}
      dialogContentProps={{ className: "mdd-res-draft--dialog" }}
      dialogTitleProps={{ title: "Unsaved Changes" }}
      dialogContent={
        <DialogContent className="mdd-res-draft--dialog-content">
          <DialogContentText>
            You have unsaved changes on the Rater Experience Survey draft. Do
            you want to close and not save?
          </DialogContentText>
        </DialogContent>
      }
      dialogActions={
        <MddDialogButtons
          saveButtonText="Exit Without Saving"
          saveButtonProps={{ onClick: () => onClose(true) }}
          closeButtonText="Cancel"
          closeButtonProps={{ onClick: () => onClose() }}
        />
      }
      onCloseClick={() => onClose()}
      showCloseButton
    />
  );
};
