import * as React from "react";
import { Button, Checkbox } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import {
  SagaService,
  IAction,
  BktSelect,
  ISelectData,
  BktDataTable,
  IBktDataTableData,
  Filter,
  eFilter
} from "front-end-lib/core";
import { classConstants } from "../../../constants";
import { BktBreadcrumb, withMddStudy, MddProgress } from "../../../components";
import { filterDataForDataTable, isLoading } from "../../../utils";
import {
  IMddStudyProps,
  ILearningPlan,
  IFilter,
  IBulkLPAssignment
} from "../../../model";
import { eStudySaga } from "../../../sagas";

interface IBktAddEditStudyClinicalData extends IMddStudyProps {}

interface ISelectionInfo {
  isSelected: boolean;
  isAssigned: boolean;
  personnelId: string;
}
interface IDataTableLP extends IBktDataTableData {
  selectionInfo: ISelectionInfo;
  countryName: string;
  sponsorSiteId: string;
  personnelName: string;
  roles: { name: string; roleId: string };
  isAssigned: boolean;
}

const _MddBulkLPAssign = (props: IBktAddEditStudyClinicalData) => {
  const { study } = props;

  const [learningPlans, setLearningPlans] = React.useState<ISelectData[]>([]);
  const [currLearningPlan, setCurrLearningPlan] = React.useState("");
  const [origTableData, setOrigTableData] = React.useState<
    IDataTableLP[] | undefined
  >(undefined);
  const [currTableData, setCurrTableData] = React.useState<
    IDataTableLP[] | undefined
  >(undefined);
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);

  const currTableDataRef = React.useRef(currTableData);
  currTableDataRef.current = currTableData;
  const origTableDataRef = React.useRef(origTableData);
  origTableDataRef.current = origTableData;

  const filterVals = React.useRef(new Map<string, IFilter>());

  React.useEffect(() => {
    const removeFetchLPs = SagaService.subscribeToSaga(
      eStudySaga.FETCH_LEARNING_PLANS_BULK_ASSIGN,
      (action: IAction) => {
        if (
          action.type === eStudySaga.FETCH_LEARNING_PLANS_BULK_ASSIGN_SUCCESS
        ) {
          const lps: ILearningPlan[] = action.payload;
          setLearningPlans(
            lps.map(lp => {
              return Object.assign(
                {},
                { value: lp.id, label: lp.name, key: lp.id }
              );
            })
          );
        }
      }
    );

    const removeFetchBulkLPData = SagaService.subscribeToSaga(
      eStudySaga.FETCH_BULK_LP_DATA,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_BULK_LP_DATA_SUCCESS) {
          const tableData = (action.payload as IBulkLPAssignment[]).map(
            (item: IBulkLPAssignment) =>
              Object.assign({} as IDataTableLP, {
                selectionInfo: {
                  isSelected: false,
                  isAssigned: item.isAssigned,
                  personnelId: item.personnelId
                },
                countryName: item.countryName,
                sponsorSiteId: item.sponsorSiteId,
                personnelName: item.personnelName,
                roles: item.roles.map((r: any) => r.name).join(", "),
                // This is in the object twice because we need it for the checkbox column (to disable it) and also
                // for the assigned one to show the icon.  Since neither column knows about the other we have to do
                // it this way.
                isAssigned: item.isAssigned,
                key: item.personnelId
              })
          );
          filterVals.current.clear();
          setCurrTableData(tableData);
          setOrigTableData(tableData);
        }
      }
    );

    const removeSaveLPAssignment = SagaService.subscribeToSaga(
      eStudySaga.SAVE_BULK_LP_ASSIGNMENT,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_BULK_LP_ASSIGNMENT_SUCCESS) {
          // Save successful.  Mark the ones they selected as assigned and unselect them.
          const newData = currTableDataRef!.current!.map(item =>
            Object.assign({} as IDataTableLP, {
              ...item,
              isAssigned: item.selectionInfo.isSelected
                ? true
                : item.isAssigned,
              selectionInfo: {
                personnelId: item.selectionInfo.personnelId,
                isSelected: false,
                isAssigned: item.selectionInfo.isSelected
                  ? true
                  : item.isAssigned
              }
            })
          );

          // We also need to do the assignment in the master data (what we got from the API) since we'll
          // revert to that data if they change filters.
          const newOrigData = origTableDataRef!.current!.map(item => {
            // Is the item in the current table data?
            const foundItem = newData.find(row => row.key === item.key);
            return foundItem ? { ...foundItem } : { ...item };
          });

          setSelectAllChecked(false);
          setCurrTableData(newData);
          setOrigTableData(newOrigData);
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_LEARNING_PLANS_BULK_ASSIGN.toString(),
      payload: props.match.params.id
    });

    return () => {
      removeFetchLPs();
      removeFetchBulkLPData();
      removeSaveLPAssignment();
    };
    // eslint-disable-next-line
  }, []);

  const showSpinner = () => {
    return isLoading(
      `undefined_${eStudySaga.FETCH_LEARNING_PLANS_BULK_ASSIGN}`,
      `undefined_${eStudySaga.SAVE_BULK_LP_ASSIGNMENT}`,
      `undefined_${eStudySaga.FETCH_BULK_LP_DATA}`
    );
  };

  const isSomethingSelected = () => {
    return (
      currTableData &&
      currTableData.find(row => row.selectionInfo.isSelected === true) !==
        undefined
    );
  };

  const disableAssignButton = () => {
    return !currLearningPlan || !isSomethingSelected();
  };

  const handleLPChange = (lpId: string) => {
    setCurrLearningPlan(lpId);
    if (lpId) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_BULK_LP_DATA.toString(),
        payload: {
          studyId: props.match.params.id,
          learningPlanId: lpId
        }
      });
      // set focus to the first filter to make it easier for them
      document.getElementById("countryFilter")!.focus();
    } else {
      // removed the selection so blank out the table data
      setCurrTableData(undefined);
    }
  };

  const handleHeaderCheckboxClick = () => {
    if (!currTableData || !currTableData.length) {
      // nothing to do if no data
      return;
    }

    const newCheckboxVal = !selectAllChecked;

    const newTableData = currTableData!.map(item =>
      item.isAssigned
        ? { ...item }
        : Object.assign({} as IDataTableLP, {
            ...item,
            selectionInfo: Object.assign({} as ISelectionInfo, {
              ...item.selectionInfo,
              isSelected: newCheckboxVal
            })
          })
    );
    setSelectAllChecked(newCheckboxVal);
    setCurrTableData(newTableData);
  };

  const handleRowCheckboxClick = (info: ISelectionInfo) => {
    if (info.isAssigned) {
      // can't select an assigned one!
      return;
    }

    const newTableData = currTableData!.map(item =>
      item.key === info.personnelId
        ? Object.assign({} as IDataTableLP, {
            ...item,
            selectionInfo: {
              personnelId: item.key,
              isSelected: !item.selectionInfo.isSelected,
              isAssigned: item.isAssigned
            }
          })
        : { ...item }
    );

    // If they unchecked the row then the header select all should be unselected since it'd be deceiving to show
    // they are all selected when not.
    if (info.isSelected) {
      // It was selected which means it's now not so clear the header checkbox.
      setSelectAllChecked(false);
    }
    setCurrTableData(newTableData);
  };

  const getSelectedPersonnel = () => {
    // returns an array of all selected personnel ids
    return currTableData!
      .filter(row => row.selectionInfo.isSelected)
      .map(item => item.key);
  };

  const handleAssignClick = () => {
    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_BULK_LP_ASSIGNMENT.toString(),
      payload: {
        studyId: props.match.params.id,
        lpId: currLearningPlan,
        personnelIds: getSelectedPersonnel()
      }
    });
  };

  const handleFilterChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const value = e.target.value ? e.target.value.trim() : "";
    const key = e.target.name;
    if (origTableData && origTableData.length) {
      const filteredData = filterDataForDataTable(
        { key, value: { value } },
        filterVals.current,
        origTableData
      );
      setCurrTableData(filteredData!);
    } else {
      setCurrTableData(currTableData);
    }
  };

  const getFilterVal = (value: string) => {
    if (!filterVals || !filterVals.current || !filterVals.current.get(value)) {
      return "";
    }
    const filterObj = filterVals.current.get(value);
    return (filterObj as { value: string }).value;
  };

  const renderNoDataDiv = () => {
    return (
      <div className="mdd-assign-lp--no-data--container">
        <div
          className="mdd-assign-lp--no-data--text"
          id="divNoData"
          data-testid="divNoData"
        >
          {currTableData && currTableData.length === 0
            ? "No matching records found"
            : "Select a learning plan to view eligible users"}
        </div>
      </div>
    );
  };

  const areAllAssigned = () => {
    return currTableData &&
      currTableData.find(item => !item.isAssigned) === undefined
      ? true
      : false;
  };

  const isSelectAllDisabled =
    !currTableData || currTableData.length === 0 || areAllAssigned();

  const filterKeyDown = (e: React.KeyboardEvent) => {
    // disallow typing in a filter if no LP is selected
    if (!currLearningPlan) {
      e.preventDefault();
    }
  };

  return (
    <div className="mdd-assign-lp--main">
      <MddProgress inProgress={showSpinner()} spinnerId="svgLoading" />
      <BktBreadcrumb
        rootText="All Studies"
        rootUrl="/study"
        currentLocation={!!study.StudyId ? study.StudyName : "Loading Study"}
      />
      <div className="mdd-assign-lp--container">
        <React.Fragment>
          <div className="mdd-assign-lp--label">
            <span id="txtLP" data-testid="txtLP">
              Learning Plan
            </span>
          </div>
          <div
            id="divLearningPlan"
            data-testid="divLearningPlan"
            className="mdd-assign-lp--divSelect"
          >
            <BktSelect
              defaultSelect={true}
              menuProps={{}}
              textProps={{
                variant: "outlined",
                id: "learningPlan",
                label: "",
                value: currLearningPlan,
                className: "mdd-assign-lp--select",
                placeholder: "Select a LP",
                onChange: event => handleLPChange(event.target.value)
              }}
              selectData={learningPlans}
              selectMenuProps={{
                className: "mdd-assign-lp--select--div"
              }}
            />
          </div>
          <Button
            variant="contained"
            size="small"
            color="primary"
            id="btnAssign"
            data-testid="btnAssign"
            className="mdd-assign-lp--button"
            disabled={disableAssignButton()}
            onClick={handleAssignClick}
          >
            Assign
          </Button>
          <BktDataTable
            data={currTableData}
            defaultSortProp="personnelName"
            columnProps={[
              {
                label: (
                  <span
                    id={`chkSelectAll${isSelectAllDisabled ? "_disabled" : ""}`}
                    data-testid={`chkSelectAll${
                      isSelectAllDisabled ? "_disabled" : ""
                    }`}
                  >
                    <Checkbox
                      checked={selectAllChecked}
                      onClick={() => handleHeaderCheckboxClick()}
                      disabled={isSelectAllDisabled}
                    />
                  </span>
                ),
                formatProp: {
                  formatPropName: "selectionInfo",
                  format: (vals: ISelectionInfo) => (
                    <React.Fragment>
                      <span
                        id={`chkSelect_${vals.personnelId}${
                          vals.isSelected ? "_checked" : ""
                        }${vals.isAssigned ? "_disabled" : ""}`}
                        data-testid={`chkSelect_${vals.personnelId}${
                          vals.isSelected ? "_checked" : ""
                        }${vals.isAssigned ? "_disabled" : ""}`}
                      >
                        <Checkbox
                          checked={vals.isSelected}
                          disabled={vals.isAssigned}
                          onClick={() => handleRowCheckboxClick(vals)}
                        />
                      </span>
                    </React.Fragment>
                  )
                }
              },
              {
                label: "Country",
                filter: new Filter(eFilter.Text, {
                  debounceInterval: 0,
                  textProps: {
                    className: classConstants.TEXT_FILTER_CLASS,
                    id: "countryFilter",
                    value: getFilterVal("countryName"),
                    onChange: handleFilterChange,
                    onKeyDown: e => filterKeyDown(e),
                    name: "countryName",
                    placeholder: "Enter Country"
                  }
                }),
                tableCellProps: {
                  id: "countryHeader"
                },
                divProps: { id: "countryHeader_div" },
                sortProp: { sortPropName: "countryName", desc: true }
              },
              {
                label: "Sponsor Site Id",
                filter: new Filter(eFilter.Text, {
                  debounceInterval: 0,
                  textProps: {
                    className: classConstants.TEXT_FILTER_CLASS,
                    id: "sponsorSiteIdFilter",
                    value: getFilterVal("sponsorSiteId"),
                    onChange: handleFilterChange,
                    onKeyDown: e => filterKeyDown(e),
                    name: "sponsorSiteId",
                    placeholder: "Enter Sponsor Site Id"
                  }
                }),
                tableCellProps: {
                  id: "sponsorSiteIdHeader"
                },
                divProps: { id: "sponsorSiteIdHeader_div" },
                sortProp: { sortPropName: "sponsorSiteId", desc: true }
              },
              {
                label: "Name",
                filter: new Filter(eFilter.Text, {
                  debounceInterval: 0,
                  textProps: {
                    className: classConstants.TEXT_FILTER_CLASS,
                    id: "personnelNameFilter",
                    value: getFilterVal("personnelName"),
                    onChange: handleFilterChange,
                    onKeyDown: e => filterKeyDown(e),
                    name: "personnelName",
                    placeholder: "Enter Name"
                  }
                }),
                tableCellProps: {
                  id: "personnelNameHeader"
                },
                divProps: { id: "personnelName_div" },
                sortProp: { sortPropName: "personnelName", desc: true }
              },
              {
                label: "Roles",
                filter: new Filter(eFilter.Text, {
                  debounceInterval: 0,
                  textProps: {
                    className: classConstants.TEXT_FILTER_CLASS,
                    id: "roleFilter",
                    value: getFilterVal("roles"),
                    onChange: handleFilterChange,
                    onKeyDown: e => filterKeyDown(e),
                    name: "roles",
                    placeholder: "Enter Role"
                  }
                }),
                tableCellProps: {
                  id: "rolesHeader"
                },
                divProps: { id: "roles_div" },
                sortProp: { sortPropName: "roles", desc: true }
              },
              {
                label: "Assigned",
                formatProp: {
                  formatPropName: "isAssigned",
                  format: (isAssigned: boolean) =>
                    isAssigned ? (
                      <span id="iconAssigned" data-testid="iconAssigned">
                        <CheckCircle fontSize="small" />
                      </span>
                    ) : null
                }
              }
            ]}
            tableBodyProps={{ id: "learningPlans_tablebody" }}
            tableFooterProps={{ className: "displayNone" }}
            infiniteScrollProps={{
              id: "learningPlansInfiniteScroll",
              containerProps: {
                className: "mdd-assign-lp--infiniteScroll-container"
              }
            }}
            // allow clicking on a row to check/uncheck the checkbox
            rowActions={[
              {
                isPrimary: true,
                action: (
                  data: IBktDataTableData,
                  event: React.MouseEvent<HTMLDivElement>
                ) =>
                  handleRowCheckboxClick((data as IDataTableLP).selectionInfo)
              }
            ]}
          />
          {(!currTableData || currTableData.length === 0) && renderNoDataDiv()}
        </React.Fragment>
      </div>
    </div>
  );
};

export const MddBulkLPAssign = withMddStudy()(_MddBulkLPAssign);
