import { map, toArray } from "lodash";
import {
  ILookup,
  IFilter,
  IRole,
  IScaleExperience,
  IStudySitePerson,
  ITrainingExperience,
  ILearningPlanStatus
} from "../model";
import { ISelectData } from "front-end-lib/core";
import {
  EXPERIENCE_STATUS_DISPLAY_VALUE,
  SH_SESSION_COOKIE_MAX_AGE,
  SH_SESSION_COOKIE_NAME
} from "../constants";
import { isLocal } from "../utils/configHelper";

export const mapLookupValuesToSet = (
  x: [],
  y: ILookup[],
  getLabel: boolean
) => {
  const temp: any = [];
  map(x, (t: string) => {
    const slicedValue = getLabel
      ? toArray(y.find(s => s.value === t)).slice(1)[0]
      : toArray(y.find(s => s.label === t)).slice(0)[0];
    temp.push(slicedValue);
  });
  return temp;
};

export const cloneAssign = <T extends U & object, U>(
  instance: T,
  withArg: U
): T => Object.assign(Object.create(instance), withArg);

export const toQueryParams = (obj: object) => {
  const params = Object.keys(obj)
    .map(k => {
      const val = obj[k];
      return val ? `${k.toLocaleLowerCase()}=${val.toString()}` : "";
    })
    .join("&");
  return params ? `?${params}` : "";
};

export const toEncodedQueryParams = (obj: object) => {
  const params = Object.keys(obj)
    .map(k => {
      const val = obj[k];
      return val
        ? `${encodeURIComponent(k.toLocaleLowerCase())}=${encodeURIComponent(
            val.toString()
          )}`
        : "";
    })
    .join("&");
  return params ? `?${params}` : "";
};

export const toDateFormat = (dt: string) =>
  (new Date(dt) as any).toStandardFormat();

export const toDateFormatNoOffset = (dt: string | null) => {
  if (!dt) {
    return "";
  }

  // Going with the brute force method here since Javascript loves to
  // make the date one day earlier than reality.  TODO - revisit this when time allows.

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];

  // Converts to Signant format ignoring the UTC DateTime offset.
  const dateSplit = dt.split("T")[0].split("-");
  return `${dateSplit[2]}-${months[parseInt(dateSplit[1], 10) - 1]}-${
    dateSplit[0]
  }`;
};

// front-end-lib prototype function on Date gives us the standard format
export const toTimeFormat = (dt: string) =>
  (new Date(dt) as any).toStandardTimeFormat();

// Returns the UTC date/time for the given Date with a zero offset
export const getFormattedUTCDateTime = (dt: number) => {
  const myDate = new Date(dt);

  const formattedDateTime = myDate.toISOString().split("Z")[0] + "+00:00";

  return formattedDateTime;
};

export const getDefaultValueForSelect = (
  x: ISelectData[],
  matchValue: string
) => {
  if (x && x.length > 0) {
    const obj = x.find(s => s.value === matchValue);
    return obj ? obj.label : "";
  } else {
    return "";
  }
};

export function filterDataForDataTable<T>(
  filter: { key: string; value: IFilter },
  filterVals: Map<string, IFilter>,
  initialData: T[],
  exactMatch = false
) {
  const { key, value } = filter;

  const match = filterVals.get(key);
  const matchValue =
    typeof match !== "string" && !!match ? match!.value : match;

  if (filterVals.has(key) && matchValue === value) {
    return;
  }

  value ? filterVals.set(key, value) : filterVals.delete(key);

  if (filterVals.size === 0) {
    return initialData;
  }

  let newData = [...initialData];
  filterVals.forEach((v, k) => {
    const compareValue = typeof v !== "string" && !!v ? v!.value : v;
    newData = exactMatch
      ? newData.filter((x: T) =>
          x[k] !== undefined && x[k] != null
            ? x[k].toString() === compareValue
            : false
        )
      : newData.filter((x: T) =>
          x[k] !== undefined && x[k] != null
            ? x[k]
                .toString()
                .toLowerCase()
                .indexOf(compareValue.toLowerCase().trim()) > -1
            : false
        );
  });

  return newData;
}

export function rolesIncludePI(roles: IRole[], piGuid: string | undefined) {
  return roles.find((r: IRole) => {
    return r.RoleId === piGuid;
  })
    ? true
    : false;
}

export function sortStatus(a: any, b: any) {
  // This is the custom sort for the status column in
  // various grids.  We want Active (true) at the top when
  // sorted in ascending order.  The front end lib will take
  // care of reversing the order if it's a descending sort.
  return a.IsActive > b.IsActive ? -1 : a.IsActive === b.IsActive ? 0 : 1;
}

export const sanitizeData = (data: string) => {
  return data;
  // return data.replace(/[&^%;{}()<>'\\"+$]/g, "");
};

export const sortObjectArray = <T>(
  itemArray: T[],
  fieldName: string,
  useUpperCase: boolean = true,
  useTrimming: boolean = true,
  ascending: boolean = true
): T[] => {
  const workArray = [...itemArray].sort((item1: T, item2: T) => {
    let item1Value = useUpperCase
      ? item1[fieldName].toUpperCase()
      : item1[fieldName];

    let item2Value = useUpperCase
      ? item2[fieldName].toUpperCase()
      : item2[fieldName];

    item1Value = useTrimming ? item1Value.trim() : item1Value;
    item2Value = useTrimming ? item2Value.trim() : item2Value;

    if (item1Value > item2Value) return ascending ? 1 : -1;
    if (item2Value > item1Value) return ascending ? -1 : 1;
    return 0;
  });
  return workArray;
};

export const formatScaleExperienceResponse = (item: IScaleExperience) => {
  return item.status === EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE
    ? ""
    : `${item.experienceYear} year${item.experienceYear !== "1" ? "s" : ""} ${
        item.experienceMonth
      } month${item.experienceMonth !== "1" ? "s" : ""}`;
};

export const formatTrainingExperienceResponse = (
  quals: ITrainingExperience[],
  oldQuals: ITrainingExperience[],
  updatingScores: boolean
) => {
  const isQualExpanded = (oldQuals: ITrainingExperience[], qualId: string) => {
    const qual = oldQuals!.find((q: ITrainingExperience) => {
      return q.studyQualificationId === qualId;
    });
    if (qual) {
      return qual.expanded;
    }
    return false;
  };

  const isLPExpanded = (
    oldQuals: ITrainingExperience[],
    qualId: string,
    learningPlanId: number | null,
    legacyLearningPlanId: string | null
  ) => {
    // Find the qualification then the LP within it to get the expanded state
    const qual = oldQuals!.find((q: ITrainingExperience) => {
      return q.studyQualificationId === qualId;
    });
    if (qual) {
      const lp = qual.userLearningPlanStatuses.find(
        (learningPlan: ILearningPlanStatus) => {
          return learningPlan.learningPlanId
            ? learningPlan.learningPlanId === learningPlanId
            : learningPlan.legacyLearningPlanId === legacyLearningPlanId;
        }
      );

      if (lp) {
        return lp.expanded;
      }
    }

    return false;
  };

  return (
    quals
      .map((qual: ITrainingExperience) => {
        const learningPlans = qual.userLearningPlanStatuses
          .map((lp: ILearningPlanStatus) => {
            // sort courses within the LP alphabetically
            const courses = sortObjectArray(
              lp.userCourseStatuses,
              "courseName"
            );
            return Object.assign(
              {},
              { ...lp },
              {
                // add qualification and LP ids to each course so we have a link back
                userCourseStatuses: courses.map(course => {
                  return Object.assign(
                    {},
                    { ...course },
                    {
                      learningPlanId: lp.learningPlanId,
                      legacyLearningPlanId: lp.legacyLearningPlanId,
                      studyQualificationId: qual.studyQualificationId
                    }
                  );
                }),
                expanded: updatingScores
                  ? isLPExpanded(
                      oldQuals,
                      qual.studyQualificationId,
                      lp.learningPlanId,
                      lp.legacyLearningPlanId
                    )
                  : false
              }
            );
          }) // sort LPs alphabetically
          .sort((lp1: ILearningPlanStatus, lp2: ILearningPlanStatus) => {
            return lp1.learningPlanName.toUpperCase() >
              lp2.learningPlanName.toUpperCase()
              ? 1
              : -1;
          });
        return Object.assign(
          {},
          { ...qual, userLearningPlanStatuses: learningPlans },
          {
            expanded: updatingScores
              ? isQualExpanded(oldQuals, qual.studyQualificationId)
              : false
          }
        );
      })
      // sort qualifications alphabetically
      .sort((qual1: ITrainingExperience, qual2: ITrainingExperience) => {
        return qual1.studyQualificationName.toUpperCase() >
          qual2.studyQualificationName.toUpperCase()
          ? 1
          : -1;
      })
  );
};

export const formatPersonName = (person: IStudySitePerson) => {
  return `${person && person.firstName ? person.firstName : "Loading..."} ${
    person && person.lastName ? person.lastName : ""
  }`;
};

export const isNumber = (value: string) => {
  const isNumeric = /[0-9]\b$/.test(value);
  if (isNumeric) {
    // check for "+" sign since that's a valid number
    if (value.includes("+")) {
      return false;
    }
    // need to check to make sure they didn't put in a decimal (that's still a valid number)
    const numberVal = parseInt(value, 10);
    if (numberVal === +value) {
      return true;
    }
  }
  return false;
};

// until we move to the newest Javascript version which has
// a replaceAll function this will remove all spaces from a string
export const removeAllSpaces = (value: string) => {
  return value.replace(/ /g, "");
};

//https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript
/**
 * @description - Gets the value of a cookie by name.
 * @returns {string} the cookie value or an empty string if the cookie doesn't exist
 */
export const getCookieByName = (name: string) => {
  var b = window.document.cookie.match(
    "(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  return b ? b.pop() : "";
};

/**
 * @returns {Object} the cookie value as a JSON object or null if the cookie doesn't exist
 */
export const getSHCookieJson = () => {
  const cookie = getCookieByName(SH_SESSION_COOKIE_NAME);
  return cookie ? JSON.parse(cookie) : null;
};

/**
 * @description Sets the SH-Session cookie with the user's email address and the timestamp of the last activity
 * @param user - the user's email address
 * @param lastActivityTimestamp - the timestamp of the last activity
 */
export const setSHCookie = (user: string, lastActivityTimestamp: number) => {
  const content = JSON.stringify({
    user,
    lastActivityTimestamp
  });

  // if we are running on localhost just use localhost, otherwise get it from the current location
  const domain = isLocal
    ? "localhost"
    : document!.location!.hostname!.match(
        /^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/
      )[1];

  document.cookie = `SH-Session=${content}; domain=.${domain}; max-age=${SH_SESSION_COOKIE_MAX_AGE}; Secure; SameSite=Lax; Path=/`;
};

/**
 * @description Deletes the SH-Session cookie
 */
export const deleteSHCookie = () => {
  // if we are running on localhost just use localhost, otherwise get it from the current location
  const domain = isLocal
    ? "localhost"
    : document!.location!.hostname!.match(
        /^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/
      )[1];
  document.cookie = `SH-Session=; domain=.${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/`;
};

/**
 *
 * @returns {number} the timestamp of the last activity or null if the cookie doesn't exist
 */
export const getLastActivityTimeFromSHCookie = () => {
  const cookie = getSHCookieJson();
  return cookie ? cookie.lastActivityTimestamp : null;
};

/**
 *  @description Sets the timestamp of the last activity in the SH-Session cookie
 * @param lastActivityTimestamp - the timestamp of the last activity
 */
export const setLastActivityTimeInSHCookie = (
  lastActivityTimestamp: number
) => {
  const cookie = getSHCookieJson();
  if (cookie) {
    setSHCookie(cookie.user, lastActivityTimestamp);
  }
};
