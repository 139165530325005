import * as React from "react";
import Drawer from "@material-ui/core/Drawer";
// import FilterIcon from "@material-ui/icons/FilterList";  //TODO - for filter story
import { BktValidatedTextField } from "front-end-lib/core";
import { MddComment } from "./MddComment";
import { MddDialogButtons } from "../../common";
import { MAX_TEXT_LENGTH } from "../../../constants";
import { IComment } from "../../../model";
import { isLoading } from "../../../utils";
import { eStudySaga } from "../../../sagas";

interface IMddCommentDrawerProps {
  comments: IComment[];
  open: boolean;
  onClose?: () => void;
  onSubmit: (commentText: string) => void;
  onEditSave: (commentText: string, commentId: string) => void;
}

export const MddCommentDrawer = (props: IMddCommentDrawerProps) => {
  const { comments, open, onClose } = props;

  const [comment, setComment] = React.useState("");

  const commentsEndRef: any = React.createRef();

  React.useEffect(() => {
    // comments have changed indicating one was added after a save, clear out the comment box
    setComment("");
    // scroll to bottom so they see the latest comment added
    scrollToBottom();

    // eslint-disable-next-line
  }, [props.comments]);

  React.useEffect(() => {
    if (open) {
      // since the drawer hasn't been rendered yet at this point we need to wait a bit before
      // doing the scroll
      setTimeout(() => {
        scrollToBottom();
      }, 250);
    }
    // eslint-disable-next-line
  }, [props.open]);

  const scrollToBottom = () => {
    if (commentsEndRef && commentsEndRef.current) {
      commentsEndRef.current.scrollTop = commentsEndRef.current.scrollHeight;
    }
  };

  const isSaveDisabled = () => {
    return (
      comment.trim().length < 2 ||
      isLoading(`undefined_${eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT}`)
    );
  };

  const handleSaveComment = () => {
    const { onSubmit } = props;
    // it's required so should always be here but just a sanity check
    if (onSubmit) {
      onSubmit(comment);
    }
  };

  const handleEditSave = (commentText: string, commentId: string) => {
    const { onEditSave } = props;
    if (onEditSave) {
      onEditSave(commentText, commentId);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} keepMounted={true}>
      <div
        id="commentsContainer"
        data-testid={open ? "divComments" : "divCommentsHidden"}
        className="mdd-comments--container"
      >
        {/* header */}
        <div className="mdd-comments--header">
          Comments
          {/* //TODO - for upcoming filter story */}
          {/* <span
            id="commentsFilterIcon"
            className="mdd-comments--header--filter"
          >
            <FilterIcon />
          </span> */}
        </div>

        {/* list */}
        <div
          className="mdd-comments--list "
          id="commentsList"
          ref={commentsEndRef}
        >
          {comments.map((comment, index) => (
            <MddComment
              comment={comment}
              key={comment.studySitePersonnelCommentId}
              onEditSave={handleEditSave}
            />
          ))}
          {comments.length === 0 && (
            <div
              id="noCommentsContainer"
              className="mdd-comments--no-comments--container"
            >
              <div
                id="noCommentsText"
                className="mdd-comments--no-comments--text"
              >
                No comments to display
              </div>
            </div>
          )}
        </div>

        {/* section to add comment */}
        <div className="mdd-comments--add-comment" id="commentsAddContainer">
          <BktValidatedTextField
            textFieldProps={{
              textProps: {
                multiline: true,
                id: "commentTextField",
                variant: "outlined",
                required: false,
                type: "text",
                label: "",
                placeholder: "Enter your comment",
                className: "mdd-form--text mdd-comments--add-comment--input",
                disabled: false,
                inputProps: {
                  id: "txtComment",
                  maxLength: MAX_TEXT_LENGTH.COMMENT
                },
                rows: 3,
                rowsMax: 3,
                value: comment,
                onChange: e => setComment(e.target.value)
              },
              debounceInterval: 0
            }}
          />
          <MddDialogButtons
            saveButtonProps={{
              id: "btnCommentSave",
              disabled: isSaveDisabled(),
              onClick: handleSaveComment
            }}
            closeButtonProps={{
              className: "notVisible"
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};
