import { ConfigOptions } from "../config";
import {
  Sponsor,
  ISponsor,
  ISponsorDto,
  ISponsorHistoryDTO,
  ISponsorProgram,
  ISponsorProgramDTO
} from "../model";
import { SponsorProgram } from "../model/classes/sponsorProgram";
import { eSponsorActionTypes } from "../store";
import { store, callApi } from "../utils";
import { eHTTPMethods } from "../constants";
import { toDateFormat } from "../utils/functionsHelper";

export abstract class SponsorService {
  public static async getSponsors(payload: any) {
    return (await callApi(
      eHTTPMethods.POST,
      ConfigOptions.searchSponsorApiPath,
      payload,
      "Request failed"
    )) as ISponsor[];
  }

  public static async getSponsor(payload: string): Promise<ISponsor> {
    const sponsor = Object.assign(
      new Sponsor(),
      await callApi(
        eHTTPMethods.GET,
        `${ConfigOptions.sponsorApiPath}/${payload}`,
        null,
        "Request failed"
      )
    );
    store.dispatch({ type: eSponsorActionTypes.SET_SPONSOR, payload: sponsor });
    return sponsor;
  }

  public static async saveSponsor(
    payload: ISponsorDto
  ): Promise<ISponsor | boolean> {
    const { currentSponsor } = store.getState().sponsor;
    const { sponsorId } = payload;
    const retVal = await callApi(
      sponsorId ? eHTTPMethods.PUT : eHTTPMethods.POST,
      `${ConfigOptions.sponsorApiPath}${sponsorId ? `/${sponsorId}` : ""}`,
      sponsorId ? payload : { ...{ sponsorId }, ...payload }
    );
    store.dispatch({
      type: eSponsorActionTypes.SET_SPONSOR,
      payload: Object.assign(
        new Sponsor(),
        currentSponsor,
        sponsorId ? payload : retVal
      )
    });
    return retVal;
  }

  public static async saveSponsorProgram(
    payload: ISponsorProgramDTO
  ): Promise<ISponsorProgram | boolean> {
    const { id } = payload;
    const val = await callApi(
      id ? eHTTPMethods.PUT : eHTTPMethods.POST,
      ConfigOptions.sponsorProgramApiPath,
      id ? payload : { ...{ id }, ...payload }
    );
    return Object.assign(new SponsorProgram(), val) as ISponsorProgram;
  }

  public static async getSponsorHistory(
    sponsorId: string
  ): Promise<ISponsorHistoryDTO[]> {
    const result = (await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.sponsorApiPath}/GetHistory/${sponsorId}`
    )) as ISponsorHistoryDTO[];
    if (result) {
      result.map(sh => {
        // date format for display day-month-year;  dd-MMM-yyyy
        sh.updatedDt = toDateFormat(sh.updatedDt);
        return sh;
      });
    }
    return result;
  }

  public static async getSponsorPrograms(payload: string) {
    return ((await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.getSponsorProgramsApiPath}/${payload}`
    )) as ISponsorProgram[]).map((sp: ISponsorProgram) =>
      Object.assign(new SponsorProgram(), sp)
    );
  }
}
