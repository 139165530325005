export enum NotificationActionTypes {
  OPEN_SUCCESS_SNACKBAR = "@@notification/OPEN_SUCCESS_SNACKBAR",
  OPEN_ERROR_SNACKBAR = "@@notification/OPEN_ERROR_SNACKBAR",
  OPEN_INFO_SNACKBAR = "@@notification/OPEN_INFO_SNACKBAR",
  OPEN_WARNING_SNACKBAR = "@@notification/OPEN_WARNING_SNACKBAR",
  OPEN_SNACKBAR_REQUEST = "@@notification/OPEN_SNACKBAR_REQUEST",
  CLOSE_SNACKBAR = "@@notification/CLOSE_SNACKBAR",
  CLOSE_SNACKBAR_REQUEST = "@@notification/CLOSE_SNACKBAR_REQUEST"
}

export interface INotificationState {
  message: string;
}
