import { IStudyProtocol } from "../../model";

export class StudyProtocol implements IStudyProtocol {
  private protocolId: string;
  private studyId: string;
  private programId: string;
  private programName: string;
  private protocolName: string;

  get ProtocolId() {
    return this.protocolId;
  }
  set ProtocolId(val) {
    this.protocolId = val;
  }

  get StudyId() {
    return this.studyId;
  }
  set StudyId(val) {
    this.studyId = val;
  }

  get ProgramId() {
    return this.programId;
  }
  set ProgramId(val) {
    this.programId = val;
  }

  get ProgramName() {
    return this.programName;
  }
  set ProgramName(val) {
    this.programName = val;
  }

  get ProtocolName() {
    return this.protocolName;
  }
  set ProtocolName(val) {
    this.protocolName = val;
  }
}
