import { eFilter, Filter } from "front-end-lib/core";
import { classConstants } from "../../constants";

export const personnelDataTableProps = {
  containerProps: {
    id: "tableWrapper",
    className: "tbl--container",
    style: { boxShadow: "0px 0px 0px 0px" }
  },
  tableProps: { id: "tableContainer" },
  tableHeadProps: { id: "tableHeader", rowProps: { id: "tableHeaderRow" } },
  tableBodyProps: {
    id: "tableBody",
    rowProps: {
      id: "tableBodyRow",
      className: "tbl--body-row mdd-tbl--body-row"
    }
  },
  tableFooterProps: { className: "mdd-personnel-tbl--footer" }
};

export const personnelDataTableHeaders = (
  filterOnChange:
    | ((
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => void)
    | undefined
) => [
  {
    key: "FirstName",
    label: "First Name",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "firstNameFilter",
        onChange: filterOnChange,
        name: "FirstName"
      }
    }),
    tableCellProps: { id: "firstNameHeader" },
    divProps: { id: "firstNameHeader_div" }
  },
  {
    key: "LastName",
    label: "Last Name",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "lastNameFilter",
        onChange: filterOnChange,
        name: "LastName"
      }
    }),
    tableCellProps: {
      id: "lastNameHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "lastNameHeader_div" },
    sortProp: { sortPropName: "LastName", desc: false }
  },
  {
    key: "UserName",
    label: "Username",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "userNameFilter",
        onChange: filterOnChange,
        name: "UserName"
      }
    }),
    tableCellProps: {
      id: "userNameHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "userNameHeader_div" }
  },
  {
    key: "Email",
    label: "Email",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "emailFilter",
        onChange: filterOnChange,
        name: "Email"
      }
    }),
    tableCellProps: {
      id: "emailHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "emailHeader_div" }
  }
];
