import { IBktDataTableColumnProps } from "front-end-lib";
import React from "react";
import { MddCheckbox } from "../../../components";
import { IIdNamePair } from "../../../model/interfaces/iIdNamePair";
import { IEditItem } from "store/resConfig";

export interface IScaleBktDataTable extends Omit<IIdNamePair, "name"> {
  item: IAddScaleEditItem;
  key: string;
  name: React.ReactNode;
}

export const tableStyles = {
  containerProps: { className: "mdd-res-draft--add-table" },
  tableHeadProps: {
    className: "tbl--header tableHead",
    id: "resDraftTableHeader"
  },
  tableFooterProps: {
    className: "mdd-res-draft--tableFooter",
    id: "resDraftTableFooter"
  }
};

export const headers: IBktDataTableColumnProps[] = [
  {
    label: "",
    tableCellProps: { id: "checkedHeader" },
    formatProp: {
      formatPropName: "item",
      format: (item: IEditItem) => (
        <MddCheckbox
          lblText=""
          chkBoxProps={{
            id: `activeCheckbox_${item.name}`,
            checked: item.checked,
            className: "activeCheckbox",
            disabled: item.disabledOnModal
          }}
        />
      )
    }
  },
  {
    label: "Scale",
    tableCellProps: { id: "scaleHeader" }
  },
  {
    label: "Full Name",
    tableCellProps: { id: "fullNameHeader" }
  },
  {
    label: "Master Scale Name",
    tableCellProps: { id: "masterScaleHeader" }
  }
];

interface IAddScaleEditItem {
  id?: string;
  name: string;
  fullName: string;
  masterScaleName: string;
  checked: boolean;
  disabled: boolean;
}

export const getConfig = (
  scales: IAddScaleEditItem[]
): Omit<IScaleBktDataTable, "id">[] =>
  scales.map(item => ({
    key: item.name,
    item,
    name: item.name,
    fullName: item.fullName,
    masterScaleName: item.masterScaleName
  }));
