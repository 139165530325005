import { BktDataTable, SagaService } from "front-end-lib";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { IEditItem, eResConfig } from "../../../store/resConfig/types";
import { MddDataContainer } from "../../../components";
import {
  getConfig,
  headers,
  tableStyles
} from "../../../config/dataTable/raterExperienceSurvey/scaleDataTableConfig";
import {
  getScalesState,
  handleActiveChange
} from "../../../store/resConfig/selectors";
import { MddResScaleDialog } from "./MddResScaleDialog";
import { DeleteDialog } from "./DeleteDialog";

interface IProps {
  fields: string[];
  items: IEditItem[] | null;
  edit: IEditItem[];
}

const MddResScaleComponent = ({ fields, items, edit }: IProps) => {
  const { id } = useParams<{ id?: string }>();

  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState<IEditItem>();

  useEffect(() => {
    SagaService.dispatchSaga({
      type: eResConfig.GET_SCALES,
      payload: { studyId: id }
    });
  }, [id]);

  const handleClose = (edits?: IEditItem[]) => {
    if (edits) {
      SagaService.dispatchSaga({
        type: eResConfig.EDIT_ITEMS,
        payload: { section: "scales", edits }
      });
    }

    setOpen(false);
  };

  const handleOpenDeleteDialog = (parentId: string) => {
    const item = items?.find(i => i.parentId === parentId);
    setOpenDeleteDialog(true);
    setDeleteItem({
      ...item!,
      softDeleted: true
    });
  };

  const handleCloseDeleteDialog = (isSave?: boolean) => {
    if (isSave) {
      SagaService.dispatchSaga({
        type: eResConfig.DELETE_ITEMS,
        payload: {
          edits: edit,
          items,
          deleteItem: deleteItem,
          section: "scales"
        }
      });
    }

    setOpenDeleteDialog(false);
  };

  const handleIsActiveChange = (parentId: string) => {
    const item = items?.find(i => i.parentId === parentId);
    SagaService.dispatchSaga({
      type: eResConfig.EDIT_ITEMS,
      payload: {
        section: "scales",
        edits: handleActiveChange(item!, edit, items!)
      }
    });
  };

  return (
    <div
      className="mdd-res-draft--tab-content"
      data-testid="mdd-res-draft-scale-tab"
    >
      <h3>Experience (Scales)</h3>
      <p className="mdd-res-draft--tab-content--paragraph">
        Add or remove scale(s). Once published, any scale selected cannot be
        deleted from the configuration. However, the status can be updated,
        which will no longer display the fields in the RES within Clinical
        Profile.
      </p>
      <h4>Scales Fields</h4>
      <ul
        className="mdd-res-draft--tab-content--fields"
        data-testid="mdd-res-draft-static-fields"
      >
        {fields.map(field => (
          <li key={field}>{field}</li>
        ))}
      </ul>
      <MddDataContainer
        id="resScalesContainer"
        title="Scales Included"
        classes={{
          container: "mdd-res-draft--tableContainer"
        }}
        addAction={
          items
            ? {
                display: "Add Scale(s)",
                action: () => setOpen(true)
              }
            : undefined
        }
      >
        <BktDataTable
          noRecordsMessage="Please add a Scale(s)"
          loading={!items}
          data={getConfig((items as any[]) || [], handleIsActiveChange)}
          columnProps={headers(handleOpenDeleteDialog)}
          {...tableStyles}
        />
      </MddDataContainer>
      <MddResScaleDialog isOpen={open} onClose={handleClose} />
      <DeleteDialog
        title="Scale"
        itemName={`${deleteItem?.name!} Scale`}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </div>
  );
};

export const MddResScale = connect(getScalesState)(MddResScaleComponent);
