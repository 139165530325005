import { IStudy } from "../../model/interfaces/iStudy";
import { IStudySitePerson } from "../../model/interfaces/iStudySitePerson";

export enum eStudyActionTypes {
  SET_STUDY = "@@study/SET_STUDY",
  SET_STUDY_SITE_PERSON = "@@study/SET_STUDY_SITE_PERSON",
  SET_SITE = "@@study/SET_SITE",
  SET_STUDY_RES_CONFIG = "@@study/SET_STUDY_RES_CONFIG"
}

export interface IStudyState {
  currentStudy: IStudy;
  currentStudySitePerson: IStudySitePerson;
}
