import * as React from "react";
import { Paper, PaperProps } from "@material-ui/core";
import Draggable from "react-draggable";

export const MddDraggablePaper = (props: PaperProps) => (
  <Draggable
    handle=".mdd-dialog--title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
);
