import { eFilter, Filter } from "front-end-lib/core";
import { classConstants } from "../../constants";

export const studyProtocolDataTableProps = {
  containerProps: {
    id: "tableWrapper",
    className: "tbl--container",
    style: { boxShadow: "0px 0px 0px 0px" }
  },
  tableProps: { id: "tableContainer" },
  tableHeadProps: { id: "tableHeader", rowProps: { id: "tableHeaderRow" } },
  tableBodyProps: { id: "tableBody", rowProps: { id: "tableBodyRow" } }
};

export const studyProtocolDatatableHeaders = (
  filterOnChange:
    | ((
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => void)
    | undefined
) => [
  {
    key: "protocolName",
    label: "Protocol Name",
    filter: new Filter(eFilter.Text, {
      debounceInterval: 0,
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "protocolNameFilter",
        onChange: filterOnChange,
        name: "protocolName"
      }
    }),
    sortProp: { sortPropName: "ProtocolName", desc: true },
    tableCellProps: { id: "protocolNameHeader" },
    divProps: { id: "protocolNameHeader_div" }
  },
  {
    key: "programName",
    label: "Program Name",
    filter: new Filter(eFilter.Text, {
      debounceInterval: 0,
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "programNameFilter",
        onChange: filterOnChange,
        name: "programName"
      }
    }),
    tableCellProps: { id: "programNameHeader" },
    divProps: { id: "programNameHeader_div" }
  },
  {
    key: "editProtocol",
    label: "",
    tableCellProps: {
      id: "editHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "editHeader_div" }
  }
];
