import { SagaService, ISagaServiceSaga } from "front-end-lib/core";

import {
  eStudySaga,
  fetchstudyLookups,
  watchFetchstudyLookups as watchFetchStudyLookups
} from "./studySaga";
import {
  eSiteSaga,
  fetchSiteCommentsSaga,
  watchSiteCommentsSagas,
  modifySiteCommentSaga,
  watchSaveSiteCommentSagas
} from "./siteSaga";
import {
  eScaleSaga,
  modifyScaleSaga,
  watchModifyScaleSagas
} from "./scaleSaga";
import {
  ePersonnelSaga,
  fetchPersonnelSaga,
  watchPersonnelSagas
} from "./personnelSaga";
import {
  eUtilSaga,
  dispatchLoadingSaga,
  dispatchDependentLoadingSaga
} from "./utilSaga";

export const rootStorelessSagas = () => {
  // Initialize sagas by adding them to the services saga map.
  SagaService.setSagas([
    // Lookups
    { saga: dispatchLoadingSaga, type: eUtilSaga.LOADING },
    { saga: dispatchDependentLoadingSaga, type: eUtilSaga.DEPENDENT_LOADING },
    { saga: fetchstudyLookups, type: eStudySaga.FETCH_STUDY_LOOKUPS },
    { saga: watchFetchStudyLookups, type: eStudySaga.WATCH_STUDY_LOOKUPS },
    { saga: fetchSiteCommentsSaga, type: eSiteSaga.FETCH_SITE_COMMENTS },
    { saga: watchSiteCommentsSagas, type: eSiteSaga.WATCH_SITE_COMMENTS },
    { saga: modifySiteCommentSaga, type: eSiteSaga.MODIFY_SITE_COMMENT },
    {
      saga: watchSaveSiteCommentSagas,
      type: eSiteSaga.WATCH_SITE_COMMENT_SAVE
    },

    // Scale Sagas
    { saga: modifyScaleSaga, type: eScaleSaga.MODIFY_SCALE },
    { saga: watchModifyScaleSagas, type: eScaleSaga.WATCH_MODIFY_SCALE },
    // Personnel Sagas
    { saga: fetchPersonnelSaga, type: ePersonnelSaga.FETCH_PERSONNEL },
    { saga: watchPersonnelSagas, type: ePersonnelSaga.WATCH_PERSONNEL }
  ]);

  // Run the saga watchers, these must be initialized prior it running.
  SagaService.runSagas([
    {
      type: eStudySaga.WATCH_STUDY_LOOKUPS,
      sagaio: (SagaService.sagas.get(
        eStudySaga.FETCH_STUDY_LOOKUPS
      ) as ISagaServiceSaga).sagaIO
    },
    {
      type: eSiteSaga.WATCH_SITE_COMMENTS,
      sagaio: (SagaService.sagas.get(
        eSiteSaga.FETCH_SITE_COMMENTS
      ) as ISagaServiceSaga).sagaIO
    },
    {
      type: eSiteSaga.WATCH_SITE_COMMENT_SAVE,
      sagaio: (SagaService.sagas.get(
        eSiteSaga.MODIFY_SITE_COMMENT
      ) as ISagaServiceSaga).sagaIO
    },

    // Scale Sagas
    {
      type: eScaleSaga.WATCH_SCALES,
      sagaio: (SagaService.sagas.get(
        eScaleSaga.FETCH_SCALES
      ) as ISagaServiceSaga).sagaIO
    },
    {
      type: eScaleSaga.WATCH_MASTER_SCALES,
      sagaio: (SagaService.sagas.get(
        eScaleSaga.FETCH_MASTER_SCALES
      ) as ISagaServiceSaga).sagaIO
    },
    {
      type: eScaleSaga.WATCH_SCALE,
      sagaio: (SagaService.sagas.get(
        eScaleSaga.FETCH_SCALE
      ) as ISagaServiceSaga).sagaIO
    },
    {
      type: eScaleSaga.WATCH_MODIFY_SCALE,
      sagaio: (SagaService.sagas.get(
        eScaleSaga.MODIFY_SCALE
      ) as ISagaServiceSaga).sagaIO
    },
    // Personnel Sagas
    {
      type: ePersonnelSaga.WATCH_PERSONNEL,
      sagaio: (SagaService.sagas.get(
        ePersonnelSaga.FETCH_PERSONNEL
      ) as ISagaServiceSaga).sagaIO
    }
  ]);
};

export * from "./lookupSaga";
export * from "./personnelSaga";
export * from "./sponsorSaga";
export * from "./studySaga";
export * from "./utilSaga";
export * from "./siteSaga";
export * from "./scaleSaga";
export * from "./commentSaga";
