import { ICommentPayload } from "../model";
import { eHTTPMethods } from "../constants";
import { callApi } from "../utils";

export abstract class CommentService {
  public static async saveComment(commentPayload: ICommentPayload) {
    const { url, comment } = commentPayload;
    return await callApi(eHTTPMethods.POST, url, {
      commentText: comment
    });
  }
}
