import * as React from "react";
import { withMddBase } from "../../higher-order/WithMddBase/WithMddBase";
import { isLoading } from "../../../utils";
import { MddProgress } from "../../common";

export interface IMddFormProps {
  id?: string;
  keys?: string[];
  children?: React.ReactNode;
  formClass?: string;
  containerClass?: string;
}

const _MddForm = (props: IMddFormProps) => {
  const { id, keys, children, formClass, containerClass } = props;

  return (
    <div id={`${!id ? "frmDiv" : id}`} className={formClass || "mdd-form"}>
      <MddProgress
        inProgress={keys && isLoading(...keys)}
        spinnerId={`${!id ? "svgLoading" : `${id}_loading`}`}
      />
      <div
        id={`${!id ? "frmDivContent" : `${id}_content`}`}
        className={containerClass ? containerClass : "mdd-form--container"}
      >
        {children}
      </div>
    </div>
  );
};

export const MddForm = withMddBase<IMddFormProps>(_MddForm);
