import { ConfigOptions } from "../config";
import {
  Site,
  SiteComment,
  ISite,
  ISiteDTO,
  ISiteComment,
  ISiteCommentDTO
} from "../model";
import { eHTTPMethods } from "../constants";
import { omit, partialRight } from "lodash";
import * as _ from "lodash";
import { callApi } from "../utils";

export abstract class SiteService {
  public static async getSites(payload: any): Promise<ISite[]> {
    const result = (await callApi(
      eHTTPMethods.POST,
      ConfigOptions.searchSiteApiPath,
      payload
    )) as ISite[];
    return result.map((s: ISite) => Object.assign(new Site(), s) as ISite);
  }

  public static async getSite(payload: string) {
    const result = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.siteApiPath}/${payload}`
    );
    return Object.assign(new Site(), result) as ISite;
  }

  public static async saveSite(payload: ISiteDTO): Promise<ISite | boolean> {
    const { siteId } = payload;
    const callSiteApi = partialRight(callApi, _, _, _, "Save failed");
    const val = siteId
      ? await callSiteApi(
          eHTTPMethods.PUT,
          `${ConfigOptions.siteApiPath}/${siteId}`,
          payload
        )
      : await callSiteApi(
          eHTTPMethods.POST,
          ConfigOptions.siteApiPath,
          omit(payload, "siteId")
        );
    return Object.assign(new Site(), val) as ISite;
  }

  public static async getSiteComments(payload: string) {
    const result = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.getSiteCommentsApiPath}/${payload}`
    );
    return result;
  }

  public static async saveSiteComment(
    payload: ISiteCommentDTO
  ): Promise<ISiteComment | boolean> {
    const { id } = payload;
    const val = await callApi(
      id ? eHTTPMethods.PUT : eHTTPMethods.POST,
      ConfigOptions.siteCommentApiPath,
      id ? payload : { ...{ id }, ...payload }
    );
    return Object.assign(new SiteComment(), val) as ISiteComment;
  }
}
