import * as React from "react";
import { isEqual, map } from "lodash";
import {
  SagaService,
  getKeysFromState,
  BktTypeaheadSelect,
  BktValidatedTextField,
  BktSaga
} from "front-end-lib/core";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import { withMddStudy } from "../../higher-order/WithMddStudy/WithMddStudy";
import {
  Validations,
  Study,
  StudyDTO,
  IStudy,
  ILookup,
  IMddStudyProps
} from "../../../model";
import { BktBreadcrumb, MddForm } from "../../../components";
import { eLookupSaga, eStudySaga } from "../../../sagas";
import { getDefaultValueForSelect, isLoading } from "../../../utils";
import { MAX_TEXT_LENGTH } from "../../../constants";

interface IStateProps {
  newStudy: IStudy;
  sponsors: ILookup[];
  internalStudyNameHasError: boolean;
  projectNumberHasError: boolean;
  studyNameHasError: boolean;
  sponsorNameHasError: boolean;
  studyNameErrorMessage: string;
  placeholder: string;
  [key: string]: any; // Needed for dropdown selectionOption to map key value pair
}

class BktAddEditStudyComponent extends BktSaga<
  IMddStudyProps,
  IStateProps,
  {}
> {
  private get isLoadingSave() {
    return isLoading(`undefined_${eStudySaga.MODIFY_STUDY}`);
  }
  constructor(props: IMddStudyProps) {
    super(props);
    this.state = {
      newStudy: Object.assign(new Study(), props.study),
      studyNameErrorMessage: "",
      internalStudyNameHasError: false,
      projectNumberHasError: false,
      studyNameHasError: false,
      sponsorNameHasError: false,
      sponsors: [],
      placeholder: "Type in to search for Sponsor"
    };

    this.configureSaga(
      new Map([
        [
          eLookupSaga.FETCH_SPONSORS,
          {
            stateKey: getKeysFromState(this.state, ["sponsors"]),
            validationType: eLookupSaga.FETCH_SPONSORS_SUCCESS
          }
        ]
      ])
    );
  }

  public componentDidMount() {
    SagaService.dispatchSaga({
      type: eLookupSaga.FETCH_SPONSORS
    });
  }

  public componentDidUpdate(prevProps: IMddStudyProps) {
    if (!isEqual(prevProps.study, this.props.study)) {
      this.setState({ newStudy: this.props.study });
    }
  }

  public render() {
    const { newStudy, sponsors, sponsorNameHasError } = this.state;
    const { study } = this.props;
    const loadingSave = this.isLoadingSave;
    // PLAT-2232: Fix Sponsor name search with "-"
    const data = map(sponsors, (option: ILookup) => ({
      value: option.value,
      label: option.label
    }));

    return (
      <div style={{ height: "100%" }}>
        <BktBreadcrumb
          rootText="All Studies"
          rootUrl="/study"
          currentLocation={this.getCurrentLocation()}
        />
        <MddForm
          keys={[
            `undefined_${eStudySaga.FETCH_STUDY}`,
            `_${eLookupSaga.FETCH_SPONSORS}`,
            `undefined_${eStudySaga.MODIFY_STUDY}`
          ]}
        >
          <div className="mdd-form--body mdd-add-edit-study--form-body">
            <div>
              <label
                id="sponsorNameLabel"
                data-testid="sponsorNameLabel"
                className={
                  sponsorNameHasError
                    ? "mdd-form--typeahead-label-error"
                    : "mdd-form--typeahead-label"
                }
                data-shrink="true"
              >
                Sponsor Name *
              </label>
              <BktTypeaheadSelect
                selectData={data}
                textProps={{
                  validationForOnBlur: [Validations.required],
                  validationerror: (validationError: boolean) =>
                    this.setState({ sponsorNameHasError: validationError }),
                  textFieldProps: {
                    textProps: {
                      id: "txtSponsorId",
                      placeholder: "Select a sponsor",
                      className: "tbl--filter-select",
                      disabled:
                        !!study.SponsorId ||
                        isLoading(`undefined_${eLookupSaga.FETCH_SPONSORS}`),
                      InputLabelProps: {
                        shrink: newStudy.SponsorId !== undefined
                      },
                      value: getDefaultValueForSelect(sponsors, study.SponsorId)
                    }
                  }
                }}
                itemProps={{ name: "sponsorId" }}
                onSubmit={this.handleSponsorChange}
              />
            </div>
            <BktValidatedTextField
              validationForOnChange={[Validations.studyNameAllowedCharacters]}
              validationForOnBlur={[
                Validations.required,
                Validations.studyNameAllowedCharacters
              ]}
              validationerror={this.setStudyNameValidationError}
              textFieldProps={{
                textProps: {
                  id: "studyId",
                  className: "mdd-form--text",
                  label: "Study Name",
                  placeholder: "Study Name",
                  inputProps: { maxLength: MAX_TEXT_LENGTH.STUDY_NAME },
                  variant: "standard",
                  required: true,
                  disabled: loadingSave,
                  onChange: (event: any) =>
                    this.setState({
                      newStudy: Object.assign(new Study(), newStudy, {
                        StudyName: event.target.value
                      })
                    }),
                  type: "text",
                  value: newStudy.StudyName || ""
                }
              }}
            />
            <span
              id="spanInternalName"
              data-testid="spanInternalName"
              className="mdd-add-edit-study--internal-name"
            >
              <BktValidatedTextField
                validationForOnBlur={[
                  Validations.required,
                  Validations.internalStudyNameAllowedCharacters
                ]}
                validationForOnChange={[
                  Validations.internalStudyNameAllowedCharacters
                ]}
                validationerror={this.setInternalStudyNameValidationError}
                textFieldProps={{
                  textProps: {
                    id: "internalStudyId",
                    className: "mdd-form--text",
                    label: "Internal Study Name",
                    placeholder: "Internal Study Name",
                    inputProps: {
                      maxLength: MAX_TEXT_LENGTH.INTERNAL_STUDY_NAME
                    },
                    type: "text",
                    variant: "standard",
                    required: true,
                    disabled: loadingSave || study.StudyId !== undefined,
                    onChange: (event: any) =>
                      this.setState({
                        newStudy: Object.assign(new Study(), newStudy, {
                          InternalStudyName: event.target.value
                        })
                      }),
                    value: newStudy.InternalStudyName || ""
                  }
                }}
              />
              <span
                className="mdd-add-edit-study--info-icon"
                id="iconInfo"
                data-testid="iconInfo"
                title="Internal study name cannot be edited after initial creation"
              >
                <InfoIcon fontSize="small" />
              </span>
            </span>
            <BktValidatedTextField
              validationForOnBlur={[
                Validations.required,
                Validations.studyCodeAllowedCharacters
              ]}
              validationForOnChange={[Validations.studyCodeAllowedCharacters]}
              validationerror={this.setInternalStudyCodeValidationError}
              textFieldProps={{
                textProps: {
                  id: "internalStudyCodeId",
                  className: "mdd-form--text",
                  label: "Internal Study Code",
                  placeholder: "Internal Study Code",
                  inputProps: {
                    maxLength: MAX_TEXT_LENGTH.INTERNAL_STUDY_CODE
                  },
                  type: "text",
                  variant: "standard",
                  required: true,
                  disabled: loadingSave,
                  onChange: (event: any) =>
                    this.setState({
                      newStudy: Object.assign(new Study(), newStudy, {
                        ProjectNumber: event.target.value
                      })
                    }),
                  value: newStudy.ProjectNumber || ""
                }
              }}
            />
            <div className="mdd-form__button--container">
              <Button
                id="saveButtonId"
                color="primary"
                disableFocusRipple={true}
                disableRipple={true}
                disabled={this.disableSubmitButton()}
                variant="contained"
                type="submit"
                className="mdd-form__button"
                onClick={this.handleSubmit}
              >
                SAVE
              </Button>
            </div>
          </div>
        </MddForm>
      </div>
    );
  }

  private formHasError = () => {
    const {
      newStudy: { SponsorId },
      internalStudyNameHasError,
      studyNameHasError: StudyNameHasError,
      projectNumberHasError: internalStudyCodeHasError
    } = this.state;
    return (
      !SponsorId ||
      internalStudyNameHasError ||
      StudyNameHasError ||
      internalStudyCodeHasError
    );
  };

  private disableSubmitButton = () => {
    const { newStudy } = this.state;
    return (
      this.formHasError() ||
      this.isLoadingSave ||
      !newStudy.SponsorId ||
      !newStudy.StudyName ||
      !newStudy.InternalStudyName ||
      !newStudy.ProjectNumber ||
      isEqual(newStudy, this.props.study)
    );
  };

  private setStudyNameValidationError = (validationError: boolean) =>
    this.setState({ studyNameHasError: validationError, formIsDirty: true });
  private setInternalStudyNameValidationError = (validationError: boolean) =>
    this.setState({
      internalStudyNameHasError: validationError,
      formIsDirty: true
    });
  private setInternalStudyCodeValidationError = (validationError: boolean) =>
    this.setState({
      projectNumberHasError: validationError,
      formIsDirty: true
    });

  private getCurrentLocation = () => {
    const { StudyId, StudyName } = this.props.study;
    return this.state.loading ? "" : StudyId ? StudyName : "Add New";
  };

  private handleSponsorChange = (selectedOption: any) => {
    const { value } = selectedOption || { value: undefined };
    this.setState({
      newStudy: Object.assign(new Study(), this.state.newStudy, {
        SponsorId: value
      })
    });
  };

  // This is from built in library code 'react-select', the selectedOption is out-box property to retain selectedValue
  private handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!this.disableSubmitButton()) {
      const { newStudy } = this.state;
      const {
        StudyId,
        SponsorId,
        StudyName,
        InternalStudyName,
        ProjectNumber
      } = newStudy;
      const payload = new StudyDTO(
        StudyName,
        InternalStudyName,
        ProjectNumber,
        SponsorId,
        StudyId
      );
      SagaService.dispatchSaga({ type: eStudySaga.MODIFY_STUDY, payload });
    }
  };
}

export const BktAddEditStudy = withMddStudy()(BktAddEditStudyComponent);
