import * as React from "react";
import { useState, useEffect } from "react";
import { ISelectData } from "front-end-lib";
import { ePersonnelSaga, eStudySaga } from "../../../sagas";
import {
  EXPERIENCE_STATUS_DISPLAY_VALUE,
  EXPERIENCE_STATUS_VALUE
} from "../../../constants";
import { isLoading, runSagaAsync, toDateFormatNoOffset } from "../../../utils";
import { MddDataTablePanelContent } from "./MddDataTablePanelContent";
import { MddOverrideStatusDialog, MddProgress } from "../../common";
import { IPersonnelResScaleExperience, IQuestionGroup } from "../../../model";
import {
  getDataTableScales,
  scaleDataTableHeaders
} from "../../../config/dataTable/personnelCPExperience/scaleDataTableConfig";

interface IScaleExperienceStatusUpdateResult {
  userCourseStatusId: string;
  statusDate: string;
  courseStatus: {
    name: string;
    value: string;
  };
  comment: {
    commentId: string;
    commentText: string;
  } | null;
}

interface IProps {
  studyId: string;
  personnelId: string;
  scaleExperience: IQuestionGroup | undefined;
}
export const MddScaleExperiencePanelContent = ({
  studyId,
  personnelId,
  scaleExperience
}: IProps) => {
  const [currentScale, setCurrentScale] = useState<
    | (IPersonnelResScaleExperience["items"][0] & { scaleName: string })
    | undefined
  >(undefined);
  const [availableStatuses, setAvailableStatuses] = useState<ISelectData[]>([]);
  const [isCommentRequired, setIsCommentRequired] = useState(true);
  const [scaleExperienceStatuses, setScaleExperienceStatuses] = useState<
    IPersonnelResScaleExperience
  >();

  useEffect(() => {
    (async () => {
      const loadedScaleExperienceStatuses = await runSagaAsync<
        IPersonnelResScaleExperience
      >({
        startType: ePersonnelSaga.GET_PERSONNEL_SCALE_EXPERIENCE,
        successType: ePersonnelSaga.GET_PERSONNEL_SCALE_EXPERIENCE_SUCCESS,
        payload: { studyId, personnelId }
      });

      setScaleExperienceStatuses(loadedScaleExperienceStatuses);
    })();
  }, [studyId, personnelId]);

  const open = async (
    status: IPersonnelResScaleExperience["items"][0] & { scaleName: string }
  ) => {
    setCurrentScale(status);

    const loadedAvailableStatuses = await runSagaAsync<
      { name: string; value: string }[]
    >({
      startType: eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES,
      successType:
        eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES_SUCCESS,
      payload: {
        studyId,
        personnelId,
        courseId: status.userCourseStatusId
      }
    });

    setAvailableStatuses(
      loadedAvailableStatuses
        .filter(m => m.name !== status.status)
        .map(m => ({ value: m.value, label: m.name }))
    );
  };

  const handleCloseDialog = () => {
    setCurrentScale(undefined);
    setAvailableStatuses([]);
  };

  const handleSaveStatus = async (
    status: string,
    commentText: string,
    statusDate?: string
  ) => {
    const result = await runSagaAsync<IScaleExperienceStatusUpdateResult>({
      startType: eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS,
      successType: eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS_SUCCESS,
      payload: {
        studyId,
        personnelId,
        courseId: currentScale!.userCourseStatusId,
        status,
        commentText: encodeURIComponent(commentText.trim()),
        statusDate: [
          EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE,
          EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
        ].includes(currentScale!.status)
          ? null
          : statusDate
      }
    });
    var item = scaleExperienceStatuses?.items.find(
      m => m.scaleId === currentScale!.scaleId
    )!;
    setScaleExperienceStatuses({
      items: scaleExperienceStatuses!.items
        .filter(m => m.scaleId !== currentScale!.scaleId)
        .concat({
          ...item,
          status: result.courseStatus.name,
          statusDate: result.statusDate,
          comment: result.comment && {
            text: result.comment.commentText,
            type: "Override"
          }
        })
    });
    handleCloseDialog();
    setIsCommentRequired(true);
  };

  const showSpinner = isLoading(
    `undefined_${eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS}`
  );

  return (
    <>
      <MddProgress inProgress={showSpinner} spinnerId="svgLoading" />
      <MddDataTablePanelContent
        data={scaleExperience}
        headers={scaleDataTableHeaders}
        getDataTableAnswers={getDataTableScales(scaleExperienceStatuses, open)}
      />
      {currentScale && (
        <MddOverrideStatusDialog
          open
          isQualOverride={false}
          appendCurrentStatus={
            currentScale.status !==
            EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
          }
          headerText={`${
            currentScale.status !==
            EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
              ? "Override"
              : "Edit"
          } Scale Experience`}
          defTypeText="Scale"
          defValueText={currentScale.scaleName}
          currStatusText={currentScale.status ? currentScale.status : ""}
          prevStatusDate={toDateFormatNoOffset(currentScale.statusDate)}
          isCommentRequired={isCommentRequired}
          onStatusChange={(newStatus: string) =>
            setIsCommentRequired(
              !(
                currentScale.status ===
                  EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW &&
                newStatus === EXPERIENCE_STATUS_VALUE.ELIGIBLE
              )
            )
          }
          saveInProgress={showSpinner}
          onClose={handleCloseDialog}
          onSave={handleSaveStatus}
          statuses={availableStatuses}
        />
      )}
    </>
  );
};
