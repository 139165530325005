import * as React from "react";
import {
  BktBreadcrumb,
  MddForm,
  withMddSponsor,
  MddDialog,
  MddDialogButtons
} from "../../../components";
import { pick, isEqual, some, filter } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddCircle from "@material-ui/icons/AddCircle";
import {
  BktDataTable,
  SagaService,
  IAction,
  BktValidatedTextField,
  Filter,
  eFilter
} from "front-end-lib/core";
import { sponsorProgramDataTableProps } from "../../../config";
import { classConstants } from "../../../constants";
import { eSponsorSaga } from "../../../sagas";
import {
  SponsorProgram,
  Validations,
  ISponsorProgram,
  IMddSponsorProps
} from "../../../model";
import { MAX_TEXT_LENGTH } from "../../../constants";
import { isLoading } from "../../../utils";

export interface IBktDataTableSponsorProgram extends ISponsorProgram {
  key: string;
}

interface IBktShowSponsorProgramsState {
  programs: IBktDataTableSponsorProgram[] | undefined;
  filteredPrograms: IBktDataTableSponsorProgram[] | undefined;
  newProgram: ISponsorProgram;
  validationErrors: string[];
  mode: "View" | "Add" | "Edit";
}

const _BktShowSponsorPrograms = (props: IMddSponsorProps) => {
  const [state, setState] = React.useState<IBktShowSponsorProgramsState>({
    programs: undefined,
    filteredPrograms: undefined,
    newProgram: new SponsorProgram(),
    validationErrors: [],
    mode: "View"
  });
  const {
    programs,
    filteredPrograms,
    newProgram,
    validationErrors,
    mode
  } = state;
  const {
    match: {
      params: { id }
    },
    sponsor,
    loadingKeys
  } = props;
  const txtProgramId = "txtProgramName";
  let program = React.useRef(new SponsorProgram());
  const assignState = (newState: Partial<IBktShowSponsorProgramsState>) => {
    setState(Object.assign({}, state, newState));
  };

  React.useEffect(() => {
    const removeFetchPrograms = SagaService.subscribeToSaga(
      eSponsorSaga.FETCH_SPONSOR_PROGRAMS,
      (action: IAction) => {
        const { type, payload } = action;
        if (type === eSponsorSaga.FETCH_SPONSOR_PROGRAMS_SUCCESS) {
          assignState({ programs: payload, filteredPrograms: payload });
        }
      }
    );

    const removeModifySponsorProgram = SagaService.subscribeToSaga(
      eSponsorSaga.MODIFY_SPONSOR_PROGRAM,
      (action: IAction) => {
        if (action.type === eSponsorSaga.MODIFY_SPONSOR_PROGRAM_SUCCESS) {
          assignState({ mode: "View" });
        }
      }
    );

    SagaService.dispatchSaga({
      type: eSponsorSaga.FETCH_SPONSOR_PROGRAMS,
      payload: id
    });

    return () => {
      removeFetchPrograms();
      removeModifySponsorProgram();
    };
    // eslint-disable-next-line
  }, []);

  const getFormattedPrograms = () => {
    return filteredPrograms
      ? (filteredPrograms || []).map((s: IBktDataTableSponsorProgram) => {
          return Object.assign(
            pick(s, ["ProgramName", ""]) as IBktDataTableSponsorProgram,
            {
              key: s.Id,
              EditProgram: (
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  fixedWidth
                  fontSize={"1.25rem"}
                  id="squareEditIcon"
                  data-testid={`${s.Id}_EditIcon`}
                  title="Edit"
                  onClick={() => {
                    const sp = Object.assign(new SponsorProgram(), s);
                    program.current = sp;
                    assignState({ newProgram: sp, mode: "Edit" });
                  }}
                />
              )
            }
          );
        })
      : [];
  };

  const disableSaveButton = () => {
    const loading = loadingKeys.some(
      lk => lk === `undefined_${eSponsorSaga.MODIFY_SPONSOR_PROGRAM}`
    );
    return (
      loading ||
      !newProgram.ProgramName ||
      validationErrors.length > 0 ||
      isEqual(newProgram, program.current)
    );
  };

  const handleChangeFilter = (event: any) => {
    const { value } = event.target;
    const newFilteredPrograms = !value
      ? programs
      : filter(
          programs,
          (x: any) =>
            x.programName.toLowerCase().indexOf(value.toLowerCase().trim()) > -1
        );
    setState(
      Object.assign({}, state, { filteredPrograms: newFilteredPrograms })
    );
  };

  const handleDialogClose = () => {
    assignState({ mode: "View" });
  };

  const handleDialogSave = () => {
    const payload: any = {
      id: newProgram.Id,
      sponsorId: sponsor.SponsorId,
      programName: newProgram.ProgramName.trim(),
      internalProgramName: newProgram.ProgramName.trim() // Pass in program Name for now as this field is not null in DB.
    };
    SagaService.dispatchSaga({
      type: eSponsorSaga.MODIFY_SPONSOR_PROGRAM,
      payload
    });
  };

  const renderDialogContentChildren = () => {
    return [
      <BktValidatedTextField
        key="txtProgramName"
        validationForOnChange={[Validations.programAllowedCharacters]}
        validationForOnBlur={[
          Validations.required,
          Validations.programAllowedCharacters
        ]}
        validationerror={(validationError: boolean) =>
          assignState({
            validationErrors: validationError
              ? [...validationErrors, txtProgramId]
              : validationErrors.filter(v => v !== txtProgramId)
          })
        }
        textFieldProps={{
          // Setting the debounce itnerval to 1 millisecond is required for assignstate to be called on the validationerror call as well as the textProps.onChange
          debounceInterval: 1,
          textChangeDependencies: [state.newProgram],
          textProps: {
            id: txtProgramId,
            className: "mdd-form--text",
            label: "Program Name",
            placeholder: "Program Name",
            inputProps: { maxLength: MAX_TEXT_LENGTH.PROGRAM_NAME },
            required: true,
            type: "text",
            onChange: (event: any) =>
              assignState({
                newProgram: Object.assign(new SponsorProgram(), newProgram, {
                  ProgramName: event.target.value
                })
              }),
            value: newProgram.ProgramName || ""
          }
        }}
      />
    ];
  };

  return (
    <div className="mdd-sponsorPrograms-main">
      <div>
        <BktBreadcrumb
          rootText="All Sponsors"
          rootUrl="/sponsor"
          currentLocation={sponsor.SponsorName}
        />
      </div>
      <MddForm>
        <div id="container" className="mdd-grid--with-menu mdd-grid">
          <Paper id="headerContainer">
            <Typography id="headerTitle">Programs</Typography>
            <Button
              id="addProgramButton"
              variant="contained"
              size={"small"}
              onClick={() => {
                program.current = new SponsorProgram();
                assignState({ newProgram: new SponsorProgram(), mode: "Add" });
              }}
            >
              <AddCircle id="addCircleIcon" />
              Add New Program
            </Button>
          </Paper>
          <Paper id="bodyContainer">
            <div>
              <BktDataTable
                data={getFormattedPrograms()}
                defaultSortProp="ProgramName"
                loading={loadingKeys.some(
                  lk =>
                    lk === `undefined_${eSponsorSaga.FETCH_SPONSOR_PROGRAMS}`
                )}
                columnProps={[
                  {
                    label: "Program Name",
                    filter: new Filter(eFilter.Text, {
                      debounceInterval: 0,
                      textProps: {
                        className: classConstants.TEXT_FILTER_CLASS,
                        id: "programNameFilter",
                        onChange: handleChangeFilter,
                        name: "programName"
                      }
                    }),
                    sortProp: { sortPropName: "ProgramName", desc: true },
                    tableCellProps: { id: "programNameHeader" },
                    divProps: { id: "studyNameHeader_div" }
                  },
                  {
                    label: "",
                    tableCellProps: {
                      id: "editHeader",
                      className: classConstants.TABLE_CELL_CLASS
                    },
                    divProps: { id: "editHeader_div" }
                  }
                ]}
                noRecordsMessage={
                  some(programs) && !some(filteredPrograms)
                    ? "No records found"
                    : "No programs exist for this sponsor"
                }
                {...sponsorProgramDataTableProps}
              />
            </div>
          </Paper>
        </div>
      </MddForm>
      {mode !== "View" && (
        <MddDialog
          submitting={isLoading(
            `undefined_${eSponsorSaga.MODIFY_SPONSOR_PROGRAM}`
          )}
          dialogProps={{
            id: "BktFormDialogAddEditProgram",
            "aria-labelledby": "form-dialog-title",
            open: true
          }}
          dialogActionProps={{ id: "divAddEditProgramDialogActions" }}
          dialogContentProps={{
            id: "divAddEditProgramDialogContent"
          }}
          dialogTitleProps={{
            id: "form-dialog-title",
            title: newProgram.Id ? "Edit Program" : "Add New Program"
          }}
          dialogContent={renderDialogContentChildren()}
          dialogActions={
            <MddDialogButtons
              closeButtonId="btnCancel"
              saveButtonId="btnSave"
              saveButtonProps={{
                disabled: disableSaveButton(),
                onClick: handleDialogSave
              }}
              closeButtonProps={{
                onClick: () => handleDialogClose()
              }}
            />
          }
        />
      )}
    </div>
  );
};

export const BktShowSponsorPrograms = withMddSponsor()(_BktShowSponsorPrograms);
