import * as React from "react";
import { Button } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {
  BktDataTable,
  IBktDataTableData,
  eFilter,
  Filter,
  SagaService,
  IAction
} from "front-end-lib/core";
import { eStudySaga } from "../../../../sagas";
import {
  IMddStudyProps,
  IMatch,
  ICourseThresholds,
  ICountryScore,
  ICourseScoresDetails
} from "../../../../model";
import { withMddStudy } from "../../../higher-order";
import {
  MddBreadcrumb,
  MddProgress,
  MddEditThresholdsDialog,
  MddDialog,
  MddDialogButtons,
  MddNoDataDiv
} from "../../../../components";
import { history, isLoading } from "../../../../utils";

interface IMddShowStudyCourseScoresProps extends IMddStudyProps {
  match: IMatch<{
    id: string;
    sponsorId: string;
    siteId: string;
    courseId: string;
    studyComponentId: string;
  }>;
}

interface IDataTableCourse extends IBktDataTableData {
  countryName: string;
  passRate?: string;
  totalSubmitted?: number | null;
}

const _MddShowStudyCourseScores = (props: IMddShowStudyCourseScoresProps) => {
  const { study } = props;

  const [
    countryScoresDetails,
    setCountryScoresDetails
  ] = React.useState<ICourseScoresDetails | null>(null);
  const [scoresTableData, setScoresTableData] = React.useState<
    IDataTableCourse[]
  >([]);
  const [origScoresTableData, setOrigScoresTableData] = React.useState<
    IDataTableCourse[]
  >([]);
  const [
    courseThresholds,
    setCourseThresholds
  ] = React.useState<ICourseThresholds | null>(null);
  const [modifyThresholdsOpen, setModifyThresholdsOpen] = React.useState(false);
  const [lockThresholdsOpen, setLockThresholdsOpen] = React.useState(false);
  const [lockMasterOpen, setLockMasterOpen] = React.useState(false);
  const [masterIsLocked, setMasterIsLocked] = React.useState(false);
  const [isLegacy, setIsLegacy] = React.useState(false);

  const origScoresTableDataRef = React.useRef(origScoresTableData);
  origScoresTableDataRef.current = origScoresTableData;

  const courseThresholdsRef = React.useRef(courseThresholds);
  courseThresholdsRef.current = courseThresholds;

  React.useEffect(() => {
    const { id, courseId, studyComponentId } = props.match.params;

    const isLegacy = !!studyComponentId;
    setIsLegacy(isLegacy);

    const processCourseDetails = (details: ICourseScoresDetails) => {
      setCountryScoresDetails(details);
      const formattedCountries = formatTableData(
        details.countryAcceptableScores
      );
      setScoresTableData(formattedCountries);
      setOrigScoresTableData(formattedCountries);
    };

    const removeFetchScoringCourseDetails = SagaService.subscribeToSaga(
      eStudySaga.FETCH_SCORING_COURSE_DETAILS,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_SCORING_COURSE_DETAILS_SUCCESS) {
          processCourseDetails(action.payload);
        }
      }
    );
    const removeFetchScoringCourseDetailsLegacy = SagaService.subscribeToSaga(
      eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY,
      (action: IAction) => {
        if (
          action.type === eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY_SUCCESS
        ) {
          processCourseDetails(action.payload);
        }
      }
    );

    const removeFetchThresholds = SagaService.subscribeToSaga(
      eStudySaga.FETCH_COURSE_THRESHOLDS,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_COURSE_THRESHOLDS_SUCCESS) {
          setCourseThresholds(action.payload);
        }
      }
    );
    const removeFetchThresholdsLegacy = SagaService.subscribeToSaga(
      eStudySaga.FETCH_COURSE_THRESHOLDS_LEGACY,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_COURSE_THRESHOLDS_LEGACY_SUCCESS) {
          setCourseThresholds(action.payload);
        }
      }
    );
    const removeLockThresholds = SagaService.subscribeToSaga(
      eStudySaga.SAVE_COURSE_THRESHOLDS,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_COURSE_THRESHOLDS_SUCCESS) {
          handleThresholdsUpdate(
            courseThresholdsRef.current!.passingThresholdPercentage.toString(),
            courseThresholdsRef.current!.remediationThresholdPercentage.toString(),
            true
          );
        }
      }
    );

    const removeLockMaster = SagaService.subscribeToSaga(
      eStudySaga.LOCK_COURSE_MASTER_QUESTIONS,
      (action: IAction) => {
        if (action.type === eStudySaga.LOCK_COURSE_MASTER_QUESTIONS_SUCCESS) {
          setMasterIsLocked(true);
          setLockMasterOpen(false);
        }
      }
    );

    SagaService.dispatchSaga({
      type: isLegacy
        ? eStudySaga.FETCH_COURSE_THRESHOLDS_LEGACY
        : eStudySaga.FETCH_COURSE_THRESHOLDS,
      payload: isLegacy ? studyComponentId : courseId
    });
    SagaService.dispatchSaga({
      type: isLegacy
        ? eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY
        : eStudySaga.FETCH_SCORING_COURSE_DETAILS,
      payload: { studyId: id, courseId, studyComponentId }
    });

    return () => {
      removeFetchScoringCourseDetails();
      removeFetchScoringCourseDetailsLegacy();
      removeFetchThresholds();
      removeFetchThresholdsLegacy();
      removeLockThresholds();
      removeLockMaster();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // reset the master locked flag when the score details change
    if (countryScoresDetails) {
      setMasterIsLocked(countryScoresDetails.masterAcceptableScore.isLocked);
    }
  }, [countryScoresDetails]);

  const formatTableData = (scores: ICountryScore[]) => {
    return scores.map((score: ICountryScore) => {
      return Object.assign(
        {},
        {
          countryName: score.countryName,
          key: `${score.countryId}_${encodeURIComponent(score.countryName)}` // key is combo of id and name for country so we can pass what we need when a country is clicked
        }
      ) as IDataTableCourse;
    });
  };

  const hasCountrySpecificScoresheet = (countryName: string) => {
    // Find the country in the main list so we can check the flag to see if it has its own scoresheet
    const foundCountry = countryScoresDetails
      ? countryScoresDetails.countryAcceptableScores.find(
          (item: ICountryScore) => {
            return item.countryName === countryName;
          }
        )
      : null;
    if (foundCountry && foundCountry.hasCountrySpecificScoreSheet) {
      return true;
    } else {
      return false;
    }
  };

  const getCountryAcceptableScoreId = (countryName: string) => {
    // Find the country in the main list so we can get the id
    const foundCountry = countryScoresDetails
      ? countryScoresDetails.countryAcceptableScores.find(
          (item: ICountryScore) => {
            return item.countryName === countryName;
          }
        )
      : null;
    if (foundCountry) {
      return foundCountry.countryAcceptableScoreId;
    } else {
      return null;
    }
  };

  const handleFilterChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const value = e.target.value ? e.target.value.trim().toLowerCase() : "";
    if (origScoresTableDataRef.current.length) {
      const filteredData = origScoresTableDataRef.current.filter(
        (item: IDataTableCourse) => {
          return item.countryName.toLowerCase().includes(value);
        }
      );
      setScoresTableData(filteredData!);
    } else {
      setScoresTableData(origScoresTableDataRef.current);
    }
  };

  const renderCountry = (countryName: string) => {
    return (
      <span>
        <span
          className={masterIsLocked ? "mdd-study-scores--country-link" : ""}
        >
          {countryName}
        </span>
        {hasCountrySpecificScoresheet(countryName) && (
          <span
            className="mdd-study-scores--info-icon"
            title="A country specific scoresheet exists for this country."
          >
            <InfoIcon fontSize="small" id="iconInfo" data-testid="iconInfo" />
          </span>
        )}
      </span>
    );
  };

  const showSpinner = () => {
    return isLoading(
      `undefined_${eStudySaga.FETCH_COURSE_THRESHOLDS}`,
      `undefined_${eStudySaga.FETCH_SCORING_COURSE_DETAILS}`,
      `undefined_${eStudySaga.SAVE_COURSE_THRESHOLDS}`,
      `undefined_${eStudySaga.LOCK_COURSE_MASTER_QUESTIONS}`,
      `undefined_${eStudySaga.FETCH_COURSE_THRESHOLDS_LEGACY}`,
      `undefined_${eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY}`
    );
  };

  const disableModifyMaster =
    !courseThresholds || !courseThresholds.isLocked || !countryScoresDetails;

  const disableLockMaster =
    !courseThresholds ||
    !courseThresholds.isLocked ||
    !countryScoresDetails ||
    !countryScoresDetails.masterAcceptableScore.masterAcceptableScoreId ||
    masterIsLocked ||
    isLegacy;

  const hasMasterScores =
    countryScoresDetails &&
    countryScoresDetails.masterAcceptableScore.masterAcceptableScoreId;

  const handleThresholdsUpdate = (
    passingThreshold: string,
    remediationThreshold: string,
    isLocked: boolean
  ) => {
    const newThresholds = Object.assign(
      {},
      { ...courseThresholds },
      {
        passingThresholdPercentage: parseInt(passingThreshold, 10),
        remediationThresholdPercentage: parseInt(remediationThreshold, 10),
        isLocked
      }
    ) as ICourseThresholds;
    setModifyThresholdsOpen(false);
    setLockThresholdsOpen(false);
    setCourseThresholds(newThresholds);
  };

  const getMasterScoresActionButtonCaption = () => {
    let caption = "Modify";

    if (
      !countryScoresDetails ||
      !countryScoresDetails.masterAcceptableScore.masterAcceptableScoreId
    ) {
      caption = "Create";
    } else if (masterIsLocked) {
      caption = "View";
    }
    return caption;
  };

  const handleLockThresholds = () => {
    const { courseId } = props.match.params;

    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_COURSE_THRESHOLDS,
      payload: {
        courseId,
        passingThreshold: courseThresholds!.passingThresholdPercentage,
        remediationThreshold: courseThresholds!.remediationThresholdPercentage,
        isLocked: true
      }
    });
  };

  const handleLockMaster = () => {
    SagaService.dispatchSaga({
      type: eStudySaga.LOCK_COURSE_MASTER_QUESTIONS,
      payload: courseId
    });
  };

  const { id, courseId, studyComponentId } = props.match.params;

  return (
    <React.Fragment>
      <MddProgress inProgress={showSpinner()} spinnerId="svgLoading" />
      <MddBreadcrumb
        breadcrumbs={[
          { display: "All Studies", route: "/study" },
          {
            display: study.StudyName,
            route: `/study/${study.StudyId}`
          },
          {
            display: "Courses",
            route: `/study/${study.StudyId}/scores`
          },
          {
            display:
              countryScoresDetails && countryScoresDetails.courseName
                ? countryScoresDetails.courseName
                : "Loading..."
          }
        ]}
      />
      <div
        className="mdd-study-scores--flex-container"
        id="divContainer"
        data-testid="divContainer"
      >
        <div
          className="mdd-study-scores--flex-container--child-fullwidth"
          id="divTopPane"
          data-testid="divTopPane"
        >
          Thresholds
          <span className="mdd-study-scores--button-container">
            <Button
              variant="contained"
              size="small"
              color="primary"
              id="btnModifyThreshold"
              data-testid="btnModifyThreshold"
              disabled={
                !courseThresholds || courseThresholds.isLocked || isLegacy
              }
              onClick={() => setModifyThresholdsOpen(true)}
            >
              Modify
            </Button>
            <Button
              variant="contained"
              size="small"
              className="mdd-study-scores--button-secondary"
              id="btnLockThreshold"
              data-testid="btnLockThreshold"
              disabled={
                !courseThresholds || courseThresholds.isLocked || isLegacy
              }
              onClick={() => setLockThresholdsOpen(true)}
            >
              Lock
            </Button>
          </span>
          <div className="mdd-study-scores--section--container">
            <div className="mdd-study-scores--section--column mdd-study-scores--section--header">
              Passing Threshold
            </div>
            <div className="mdd-study-scores--section--column mdd-study-scores--section--header">
              Remediation Threshold
            </div>
            <div
              className="mdd-study-scores--section--column"
              id="hdrPlaceholder"
            ></div>
            <div className="mdd-study-scores--section--column--icon mdd-study-scores--section--header" />
            <div className="mdd-study-scores--section--column">
              {courseThresholds &&
              courseThresholds.passingThresholdPercentage !== null &&
              courseThresholds.passingThresholdPercentage !== undefined
                ? `${courseThresholds.passingThresholdPercentage}%`
                : "--"}
            </div>
            <div className="mdd-study-scores--section--column">
              {courseThresholds &&
              courseThresholds.remediationThresholdPercentage !== null &&
              courseThresholds.remediationThresholdPercentage !== undefined
                ? `${courseThresholds.remediationThresholdPercentage}%`
                : "--"}
            </div>
            <div
              className="mdd-study-scores--section--column"
              id="colPlaceholder"
            ></div>
            <div className="mdd-study-scores--section--column--icon">
              {courseThresholds && courseThresholds.isLocked && (
                <span
                  title="The threshold is locked for editing"
                  id="iconThresholdsLocked"
                  data-testid="iconThresholdsLocked"
                >
                  <LockIcon />
                </span>
              )}
            </div>
          </div>
          <hr color="#DBDBDB" className="mdd-study-scores--separator" />
        </div>
        <div
          className="mdd-study-scores--flex-container--child-fullwidth"
          id="divMiddlePane"
          data-testid="divMiddlePane"
        >
          Acceptable Scores
          <span className="mdd-study-scores--button-container">
            <Button
              variant="contained"
              size="small"
              color="primary"
              id="btnModifyMaster"
              data-testid="btnModifyMaster"
              disabled={disableModifyMaster}
              onClick={() =>
                history.push({
                  pathname: courseId
                    ? `/study/${id}/scores/${courseId}/editscores/master`
                    : `/study/${id}/scores/${studyComponentId}/editscores/master/legacy`,
                  state: {
                    courseName: countryScoresDetails!.courseName,
                    isLocked: masterIsLocked
                  }
                })
              }
            >
              <span>{getMasterScoresActionButtonCaption()}</span>
            </Button>
            <Button
              variant="contained"
              size="small"
              className="mdd-study-scores--button-secondary"
              id="btnLockMaster"
              data-testid="btnLockMaster"
              disabled={disableLockMaster}
              onClick={() => setLockMasterOpen(true)}
            >
              Lock
            </Button>
          </span>
          <div className="mdd-study-scores--section--container">
            {hasMasterScores && (
              <React.Fragment>
                <div className="mdd-study-scores--section--column mdd-study-scores--section--header"></div>
                <div className="mdd-study-scores--section--column mdd-study-scores--section--header">
                  {/* Pass Rate - Placeholder for later implementation*/}
                </div>
                <div className="mdd-study-scores--section--column mdd-study-scores--section--header">
                  {/* Total Submitted - Placeholder for later implementation */}
                </div>
                <div className="mdd-study-scores--section--column">Master</div>
                <div className="mdd-study-scores--section--column"></div>
                <div className="mdd-study-scores--section--column"></div>
                <div className="mdd-study-scores--section--column--icon">
                  {countryScoresDetails && masterIsLocked && (
                    <span
                      id="iconMasterLocked"
                      data-testid="iconMasterLocked"
                      title="The master acceptable scores are locked"
                    >
                      <LockIcon />
                    </span>
                  )}
                </div>
              </React.Fragment>
            )}
            {!hasMasterScores && <MddNoDataDiv loading={showSpinner()} />}
          </div>
          {/* want to display the separator line when we do have master score info */}
          {hasMasterScores && (
            <hr color="#DBDBDB" className="mdd-study-scores--separator" />
          )}
        </div>
        <div
          className="mdd-study-scores--flex-container--child-fullwidth"
          id="divBottomPane"
          data-testid="divBottomPane"
        >
          Acceptable Scores by Country
          {origScoresTableData.length ? (
            <BktDataTable
              data={scoresTableData}
              defaultSortProp="countryName"
              infiniteScrollProps={{
                id: "countriesInfiniteScroll",
                containerProps: {
                  className: "mdd-study-scores--divCountriesInfiniteScroll"
                }
              }}
              tableFooterProps={{ className: "displayNone" }}
              columnProps={[
                {
                  label: "Country",
                  sortProp: {
                    sortPropName: "countryName",
                    desc: true
                  },
                  filter: new Filter(eFilter.Text, {
                    textProps: {
                      className: "tbl--filter-text",
                      id: "countryNameFilter",
                      onChange: handleFilterChange,
                      name: "countryName",
                      placeholder: "Enter country"
                    }
                  }),
                  tableCellProps: {
                    id: "countryHeader",
                    className: "tbl--header-cell"
                  },
                  divProps: { id: "countryHeader_div" },
                  formatProp: {
                    formatPropName: "countryName",
                    format: (val: any) => (val ? renderCountry(val) : null)
                  }
                }
                //TODO - these are for later (post MVP)
                // {
                //   label: "Pass Rate",
                //   sortProp: { sortPropName: "passRate", desc: true },
                //   tableCellProps: {
                //     id: "passRateHeader",
                //     className: "tbl--header-cell"
                //   },
                //   divProps: { id: "passRateHeader_div" },
                //   formatProp: {
                //     formatPropName: "passRate",
                //     format: (val: any) =>
                //       val ? <span>{`${val}%`}</span> : "--"
                //   }
                // },
                // {
                //   label: "Total Submitted",
                //   sortProp: { sortPropName: "totalSubmitted", desc: true },
                //   tableCellProps: {
                //     id: "totalSubmittedHeader",
                //     className: "tbl--header-cell"
                //   },
                //   divProps: { id: "totalSubmittedHeader_div" },
                //   formatProp: {
                //     formatPropName: "totalSubmitted",
                //     format: (val: any) => (val ? <span>{`${val}`}</span> : "--")
                //   }
                // }
              ]}
              rowActions={[
                {
                  isPrimary: true,
                  action: (
                    data: IBktDataTableData,
                    event: React.MouseEvent<HTMLDivElement>
                  ) =>
                    masterIsLocked
                      ? history.push({
                          pathname: `/study/${id}/scores/${
                            isLegacy ? studyComponentId : courseId
                          }/editscores/country/${data.key.split("_")[0]}${
                            isLegacy ? "/legacy" : ""
                          }`,
                          state: {
                            courseName: countryScoresDetails!.courseName,
                            countryName: decodeURIComponent(
                              data.key.split("_")[1]
                            ),
                            countryAcceptableScoreId: getCountryAcceptableScoreId(
                              decodeURIComponent(data.key.split("_")[1])
                            )
                          }
                        })
                      : null
                }
              ]}
            />
          ) : (
            <MddNoDataDiv loading={showSpinner()} />
          )}
        </div>
      </div>
      {modifyThresholdsOpen && (
        <MddEditThresholdsDialog
          open={modifyThresholdsOpen}
          courseId={courseId}
          passingThreshold={
            courseThresholds
              ? courseThresholds.passingThresholdPercentage.toString()
              : ""
          }
          remediationThreshold={
            courseThresholds
              ? courseThresholds.remediationThresholdPercentage.toString()
              : ""
          }
          onClose={() => setModifyThresholdsOpen(false)}
          onSave={handleThresholdsUpdate}
        />
      )}
      {lockThresholdsOpen && (
        <MddDialog
          showCloseButton={true}
          onCloseClick={() => setLockThresholdsOpen(false)}
          dialogProps={{
            id: "divLockThresholdsDialog",
            open: lockThresholdsOpen,
            maxWidth: "xs"
          }}
          dialogActionProps={{
            id: "divLockThresholdsDialogActions",
            className: "mdd-study-scores--lock-button"
          }}
          dialogContentProps={{
            id: "divLockThresholdsDialogContent"
          }}
          dialogTitleProps={{
            id: "divLockThresholdsDialogTitle",
            title: "Lock Thresholds"
          }}
          dialogContent={
            <span>Are you sure you want to lock the selected thresholds?</span>
          }
          dialogActions={
            <MddDialogButtons
              saveButtonText="Yes"
              closeButtonText="No"
              saveButtonProps={{
                id: "btnLockThresholds",
                onClick: () => handleLockThresholds()
              }}
              closeButtonProps={{
                id: "btnLockThresholdsCancel",
                onClick: () => setLockThresholdsOpen(false)
              }}
            />
          }
        />
      )}
      {lockMasterOpen && (
        <MddDialog
          showCloseButton={true}
          onCloseClick={() => setLockMasterOpen(false)}
          dialogProps={{
            id: "divLockMastersDialog",
            open: lockMasterOpen,
            maxWidth: "sm"
          }}
          dialogActionProps={{
            id: "divLockMasterDialogActions",
            className: "mdd-study-scores--lock-button"
          }}
          dialogContentProps={{
            id: "divLockMastersDialogContent"
          }}
          dialogTitleProps={{
            id: "divLockMasterDialogTitle",
            title: "Lock Master"
          }}
          dialogContent={
            <span>
              Are you sure you want to lock the master acceptable scores?
            </span>
          }
          dialogActions={
            <MddDialogButtons
              saveButtonText="Yes"
              closeButtonText="No"
              saveButtonProps={{
                id: "btnLockMaster",
                onClick: () => handleLockMaster()
              }}
              closeButtonProps={{
                id: "btnLockMasterCancel",
                onClick: () => setLockMasterOpen(false)
              }}
            />
          }
        />
      )}
    </React.Fragment>
  );
};

export const MddShowStudyCourseScores = withMddStudy()(
  _MddShowStudyCourseScores
);
