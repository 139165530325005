import React from "react";
import { ConfigOptions } from "../../config";

interface Props {
  error: Error;
}

export const LoginCallbackError = ({ error }: Props) => {
  return (
    <div className="error-modal" data-testid="errorModal">
      <div className="error-modal--heading">
        An error occurred while logging into endSight
      </div>
      <div className="error-modal--message">{error.message}</div>
      <div className="error-modal--link">
        <a href={`${ConfigOptions.oktaLoginUrl}`}>Return to ID Portal Login</a>
      </div>
    </div>
  );
};
