import { IIdNamePair } from "model";
import { IApplicationState } from "../";
import { IDemographicFields, IEditItem } from "./types";

interface ICalculateState {
  items: IEditItem[];
  edits: IEditItem[];
  editState?: IEditItem[];
  parents: IIdNamePair[];
  childs: IIdNamePair[];
}

export const calculateItems = ({
  items,
  edits,
  editState,
  parents,
  childs
}: ICalculateState): IEditItem[] => {
  const findExisting = (
    parentId: string,
    childId?: string
  ): IEditItem | undefined =>
    edits.find(
      m =>
        m.parentId === parentId &&
        ((!childId && !m.childId) || m.childId === childId)
    ) ||
    items.find(
      v =>
        v.parentId === parentId &&
        ((!childId && !v.childId) || v.childId === childId)
    );

  const checkDisabledOnModal = (item?: IEditItem, parentId?: string) => {
    const existing =
      editState?.find(
        m =>
          m.parentId === item?.parentId &&
          ((!item?.childId && !m.childId) || m.childId === item.childId)
      ) ||
      items.find(
        v =>
          v.parentId === item?.parentId &&
          ((!item?.childId && !v.childId) || v.childId === item?.childId)
      );

    const parent =
      editState?.find(i => i.parentId === parentId && !i.childId) ||
      items.find(i => i.parentId === parentId && !i.childId);

    if (!existing && parent) {
      return !parent.isActive;
    }

    if (existing?.softDeleted && !parent?.isActive) {
      return true;
    }

    return existing ? !existing.softDeleted : false;
  };

  const checkDisabledOnGrid = (item?: IEditItem) => {
    if (item?.childId && !item.deletable) {
      const parent = findExisting(item.parentId);

      return !parent?.isActive;
    }

    return item?.deletable;
  };

  const checkChecked = (item?: IEditItem) =>
    item ? !(item as IEditItem).softDeleted : false;

  const initState = [];
  for (let i = 0; i < parents.length; i += 1) {
    const { id, ...parent } = parents[i];
    const existing = findExisting(id);
    const isChecked = checkChecked(existing);
    initState.push({
      parentId: id,
      ...parent,
      softDeleted: existing?.softDeleted ?? false,
      isActive: existing?.isActive ?? true,
      ...existing,
      checked: isChecked,
      disabledOnModal: checkDisabledOnModal(existing),
      disabledOnGrid: checkDisabledOnGrid(existing),
      deletable: existing?.deletable ?? true
    });

    if (isChecked) {
      for (let j = 0; j < childs.length; j += 1) {
        const existing = findExisting(id, childs[j].id);
        initState.push({
          parentId: id,
          childId: childs[j].id,
          nested: true,
          name: `${childs[j].name} ${parent.name}`,
          softDeleted: existing?.deletable ?? false,
          isActive: existing?.isActive ?? true,
          ...existing,
          checked: checkChecked(existing),
          disabledOnModal: checkDisabledOnModal(existing, parents[i].id),
          disabledOnGrid: checkDisabledOnGrid(existing),
          deletable: existing?.deletable ?? true
        });
      }
    }
  }

  return initState;
};

const findExisting = (item: IEditItem, edits: IEditItem[]) =>
  edits.find(
    m =>
      m.parentId === item.parentId &&
      ((!item.childId && !m.childId) || m.childId === item.childId)
  );

export const handleCheckedChange = (item: IEditItem, edits: IEditItem[]) => {
  const existing = findExisting(item, edits);
  return existing
    ? edits.filter(m =>
        existing?.childId ? m !== existing : m.parentId !== existing?.parentId
      )
    : [...edits, { ...item }];
};

export const handleActiveChange = (
  item: IEditItem,
  edits: IEditItem[],
  items: IEditItem[]
) => {
  const isParent = (parent: IEditItem) => !parent.childId;

  const isActiveParent = (parent: IEditItem) =>
    isParent(parent) && parent.isActive;

  const isSameParent = (item1: IEditItem, item2: IEditItem) =>
    item1.parentId === item2.parentId;

  const isSameChild = (item1: IEditItem, item2: IEditItem) =>
    item1.childId === item2.childId;

  const editsHasSameItem = (item: IEditItem) =>
    edits.some(i => isSameParent(i, item) && isSameChild(i, item));

  const existing = findExisting(item, edits);
  if (existing) {
    const filterEdits = isActiveParent(existing)
      ? (editItem: IEditItem, existing: IEditItem) =>
          !isSameParent(editItem, existing)
      : (editItem: IEditItem, existing: IEditItem) => editItem !== existing;

    return edits.filter(e => filterEdits(e, existing));
  }

  const filterItems = (i: IEditItem) =>
    !editsHasSameItem(i) &&
    isSameParent(i, item) &&
    ((isActiveParent(item) && i.isActive) || isSameChild(i, item));

  const itemsToChange = items.filter(i => !i.deletable && filterItems(i));

  const itemsToDelete = items.filter(i => i.deletable && filterItems(i));

  const editsByParentId = edits.filter(i => isSameParent(i, item));

  const hasEditsToChange =
    isActiveParent(item) && editsByParentId.some(i => i.isActive);

  const filteredEditsByParent = edits.filter(i =>
    editsByParentId.some(s => !isSameParent(i, s))
  );

  const editsToChange = hasEditsToChange
    ? [
        ...editsByParentId.filter(i => !i.isActive || i.softDeleted),
        ...filteredEditsByParent
      ]
    : edits;

  return [
    ...editsToChange,
    ...itemsToChange.map(i => ({ ...i, isActive: !i.isActive })),
    ...itemsToDelete.map(i => ({ ...i, softDeleted: !i.softDeleted }))
  ];
};

export const getIndictionsState = ({
  resConfig: { indications, demographics }
}: IApplicationState) => ({
  fields: indications?.fields,
  edit: indications?.edit,
  items:
    indications?.list && demographics?.list
      ? calculateItems({
          items: indications.items!,
          childs: demographics.list,
          parents: indications.list,
          edits: indications.edit
        }).filter(m => m.checked)
      : null
});

export const getDemographicsState = ({
  resConfig: { demographics, experience }
}: IApplicationState) => {
  const { items, edit, list, fields: demographicFields } = demographics;
  const fields: IDemographicFields = {
    general: experience.fields,
    demographic: demographicFields
  };
  return {
    fields,
    edit,
    items: list
      ? calculateItems({
          items: items!,
          parents: list!,
          childs: [],
          edits: edit
        }).filter(m => m.checked)
      : null
  };
};

export const getScalesState = ({
  resConfig: {
    scales: { items, edit, list, fields }
  }
}: IApplicationState) => ({
  fields,
  edit,
  items: list
    ? calculateItems({
        items: items!,
        parents: list!,
        childs: [],
        edits: edit
      }).filter(m => m.checked)
    : null
});
