import { IStudySitePersonCommentDTO } from "../../../model";

export class StudySitePersonCommentDTO implements IStudySitePersonCommentDTO {
  public studyId: string;
  public personnelId: string;
  public commentText: string;
  public commentId?: string;

  constructor(
    studyId: string,
    personnelId: string,
    commentText: string,
    commentId?: string
  ) {
    this.studyId = studyId.trim() || "";
    this.personnelId = personnelId.trim() || "";
    this.commentId = commentId ? commentId.trim() || "" : "";
    this.commentText = commentText.trim() || "";
  }
}
