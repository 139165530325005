import * as React from "react";
import {
  BktDataTable,
  IBktDataTableData,
  eFilter,
  Filter,
  SagaService,
  IAction
} from "front-end-lib/core";
import { eStudySaga } from "../../../../sagas";
import { IMddStudyProps, IFilter, IStudyCourse } from "../../../../model";
import { withMddStudy } from "../../../higher-order";
import { MddDataContainer, MddProgress } from "../../../../components";
import { filterDataForDataTable, history, isLoading } from "../../../../utils";

interface IMddShowStudyScoresProps extends IMddStudyProps {}

interface IDataTableCourse extends IBktDataTableData {
  isLegacy: number;
  courseName: string;
  learningPlanName: string;
  qualificationName: string;
}

const _MddShowStudyScores = (props: IMddShowStudyScoresProps) => {
  const { study } = props;

  // full data set
  const [origCourseData, setOrigCourseData] = React.useState<
    IDataTableCourse[]
  >([]);
  // display data set (may be filtered)
  const [courseData, setCourseData] = React.useState<IDataTableCourse[]>([]);

  React.useEffect(() => {
    const { id } = props.match.params;

    const removeFetchCourses = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_COURSES,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_STUDY_COURSES_SUCCESS) {
          const courseData = formatTableData(action.payload);

          setCourseData(courseData);
          setOrigCourseData(courseData);
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_COURSES,
      payload: id
    });

    return () => {
      removeFetchCourses();
    };
    // eslint-disable-next-line
  }, []);

  const filterVals = React.useRef(new Map<string, IFilter>());
  const origCourseDataRef = React.useRef(origCourseData);
  origCourseDataRef.current = origCourseData;
  const courseDataRef = React.useRef(courseData);
  courseDataRef.current = courseData;

  const formatTableData = (courses: IStudyCourse[]) => {
    return courses.map((course: IStudyCourse) => {
      const isLegacy = !!course.studyComponentId;
      const id = isLegacy
        ? course.studyComponentId
        : course.courseId!.toString();
      return Object.assign(
        {},
        {
          isLegacy: isLegacy ? 1 : 0,
          courseName: course.courseName,
          learningPlanName: course.learningPlanName,
          qualificationName: course.studyQualificationName,
          key: `${id}_${course.learningPlanName}_${course.studyQualificationName}`
        }
      ) as IDataTableCourse;
    });
  };

  const handleFilterChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const value = e.target.value ? e.target.value.trim() : "";
    const key = e.target.name;
    if (origCourseDataRef.current.length) {
      const filteredData = filterDataForDataTable(
        { key, value: { value } },
        filterVals.current,
        origCourseDataRef.current
      );
      setCourseData(filteredData!);
    } else {
      setCourseData(origCourseDataRef.current);
    }
  };

  const extractCourseIdFromKey = (key: string) => {
    // In order to be unique the key for the table row is the <course id>_<LPName>_<qualification name>.
    // We need to just pull out the course id (which may actually be a study component Id)
    const pieces = key.split("_");
    return pieces[0];
  };

  const showSpinner = () => {
    return isLoading(`undefined_${eStudySaga.FETCH_STUDY_COURSES}`);
  };

  return (
    <React.Fragment>
      <MddProgress inProgress={showSpinner()} spinnerId="svgLoading" />
      <MddDataContainer
        id="StudyScores"
        title="Acceptable Scores"
        classes={{
          container: "mdd-form--fullheight mdd-study-scores--container"
        }}
        breadcrumbs={[
          { display: "All Studies", route: "/study" },
          { display: study.StudyName }
        ]}
      >
        <BktDataTable
          data={courseData}
          defaultSortProp="courseName"
          infiniteScrollProps={{
            id: "scoresInfiniteScroll",
            containerProps: {
              className: "mdd-study-scores--divScoresInfiniteScroll"
            },
            maxItems: 99999
          }}
          columnProps={[
            {
              label: "isLegacy",
              tableCellProps: {
                id: "isLegacyHeader",
                className:
                  "tbl--header-cell mdd-study-scores--hidden-column-header"
              }
            },
            {
              label: "Course",
              sortProp: { sortPropName: "courseName", desc: true },
              filter: new Filter(eFilter.Text, {
                textProps: {
                  className: "tbl--filter-text",
                  id: "courseNameFilter",
                  onChange: handleFilterChange,
                  name: "courseName",
                  placeholder: "Enter course name"
                }
              }),
              tableCellProps: {
                id: "courseHeader",
                className: "tbl--header-cell"
              },
              divProps: { id: "courseHeader_div" }
            },
            {
              label: "Learning Plan",
              sortProp: { sortPropName: "learningPlanName", desc: true },
              filter: new Filter(eFilter.Text, {
                textProps: {
                  className: "tbl--filter-text",
                  id: "lpFilter",
                  onChange: handleFilterChange,
                  name: "learningPlanName",
                  placeholder: "Enter learning plan name"
                }
              }),
              tableCellProps: {
                id: "learningPlanHeader",
                className: "tbl--header-cell"
              },
              divProps: { id: "learningPlanHeader_div" }
            },
            {
              label: "Study Qualification",
              sortProp: { sortPropName: "qualificationName", desc: true },
              filter: new Filter(eFilter.Text, {
                textProps: {
                  className: "tbl--filter-text",
                  id: "qualificationFilter",
                  onChange: handleFilterChange,
                  name: "qualificationName",
                  placeholder: "Enter study qualification name"
                }
              }),
              tableCellProps: {
                id: "studyQualificationHeader",
                className: "tbl--header-cell"
              },
              divProps: { id: "studyQualificationHeader_div" }
            }
          ]}
          rowActions={[
            {
              isPrimary: true,
              action: (
                data: IBktDataTableData,
                event: React.MouseEvent<HTMLDivElement>
              ) =>
                history.push(
                  `/study/${study.StudyId}/scores/${extractCourseIdFromKey(
                    data.key
                  )}${(data as IDataTableCourse).isLegacy ? "/legacy" : ""}`
                )
            }
          ]}
        />
      </MddDataContainer>
    </React.Fragment>
  );
};

export const MddShowStudyScores = withMddStudy()(_MddShowStudyScores);
