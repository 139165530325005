export class HTTPError extends Error {
  public responseMessage: string | undefined;
  public extMessage: string | undefined;

  /**
   * Initialize an error for HTTP requests.
   * @param {string} message Message used to handle internal logic
   * @param {string} extMessage Message used to handle external logic.
   */
  constructor(
    message?: string | undefined,
    extMessage?: string | undefined,
    responseMessage?: string
  ) {
    super(message);
    this.extMessage = extMessage;
    this.responseMessage = responseMessage;
  }
}
