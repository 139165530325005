import {
  TextField,
  InputAdornment,
  Paper,
  Typography
} from "@material-ui/core";
import { BktDataTable } from "front-end-lib";
import SearchIcon from "@material-ui/icons/Search";

import React, { useMemo, useState } from "react";
import { IEditItem } from "../../../store/resConfig/types";
import {
  headers,
  getConfig,
  tableStyles
} from "../../../config/dataTable/raterExperienceSurvey/addScaleDataTableConfig";

interface IProps {
  items: IEditItem[];
  handleChange: (item: IEditItem) => void;
}

interface IScaleListItem {
  id: string;
  name: string;
  fullName: string;
  masterScaleName: string;
}

export const MddResScaleDialogContent = ({ items, handleChange }: IProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const filteredData = useMemo<IEditItem[]>(() => {
    const q = searchQuery.toLocaleLowerCase();
    return searchQuery !== ""
      ? (items as any[]).filter(
          (i: IScaleListItem) =>
            i.name.toLocaleLowerCase().includes(q) ||
            i.fullName.toLocaleLowerCase().includes(q) ||
            i.masterScaleName.toLocaleLowerCase().includes(q)
        )
      : items;
  }, [searchQuery, items]);

  return (
    <>
      <div className="mdd-res-draft--dialog-search">
        <Typography variant="body2" component="p">
          Scales must be added to Study Scales prior to adding to RES.
        </Typography>
        <TextField
          label="Search"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          variant="outlined"
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
      <Paper elevation={2}>
        <BktDataTable
          data={getConfig((filteredData as any[]) || [])}
          columnProps={headers}
          rowActions={[
            {
              isPrimary: true,
              action: (data: any) =>
                data.item.disabled || handleChange(data.item)
            }
          ]}
          {...tableStyles}
        />
      </Paper>
    </>
  );
};
