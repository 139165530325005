import * as React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { MddDialog } from "../../../components/common/MddDialog/MddDialog";
import { MddDialogButtons } from "../../../components/common/MddDialogButtons/MddDialogButtons";

export interface UnassignDialogProps {
  open: boolean;
  onClose: () => void;
  unassignRes: () => void;
}

export const UnassignDialog = ({
  open,
  onClose,
  unassignRes
}: UnassignDialogProps) => (
  <MddDialog
    onCloseClick={onClose}
    dialogProps={{
      open,
      maxWidth: "xs"
    }}
    dialogContent={
      <DialogContent className="mdd-experience--unassign-dialog-content">
        <DialogContentText>
          Are you sure you want to unassign the Study RES from user in Clinical
          Profile?
        </DialogContentText>
      </DialogContent>
    }
    dialogContentProps={{ className: "mdd-experience--unassign-dialog" }}
    dialogTitleProps={{
      id: "divUnassignTitle",
      title: "Unassign RES"
    }}
    dialogActions={
      <MddDialogButtons
        saveButtonText="Unassign"
        saveButtonProps={{
          onClick: () => {
            unassignRes();
            onClose();
          }
        }}
        saveButtonId="btnUnassignDialogSave"
        closeButtonId="btnUnassignDialogClose"
        closeButtonProps={{ onClick: () => onClose() }}
      />
    }
    showCloseButton
  />
);
