import * as React from "react";
import { Fab } from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";

interface IMddCommentIconProps {
  onClick: () => void;
  fabId?: string;
  iconId?: string;
  classes?: string;
}

export const MddCommentIcon = (props: IMddCommentIconProps) => {
  const {
    onClick,
    fabId = "commentsFab",
    iconId = "commentIcon",
    classes
  } = props;

  return (
    <span className="mdd-comments--icon-wrapper">
      <Fab
        data-testid={fabId}
        id={fabId}
        onClick={onClick}
        color="secondary"
        className={classes ? classes : "mdd-comments--icon-fab"}
      >
        <CommentIcon id={iconId} data-testid={iconId} />
      </Fab>
    </span>
  );
};
