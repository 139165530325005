import * as React from "react";
import { MddProgress, MddDraggablePaper } from "../../common";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions, {
  DialogActionsProps
} from "@material-ui/core/DialogActions";
import DialogContent, {
  DialogContentProps
} from "@material-ui/core/DialogContent";
import DialogTitle, { DialogTitleProps } from "@material-ui/core/DialogTitle";
import { IconButton, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IMddDialogProps {
  dialogProps: DialogProps;
  dialogContent?: React.ReactNode;
  dialogActions?: React.ReactNode;
  dialogActionProps?: DialogActionsProps;
  dialogContentProps?: DialogContentProps;
  dialogTitleProps?: DialogTitleProps;
  submitting?: boolean;
  showCloseButton?: boolean;
  onCloseClick?: (e: React.MouseEvent) => void;
  draggable?: boolean;
}

export const MddDialog = (props: IMddDialogProps) => {
  const {
    dialogContent,
    dialogActions,
    dialogProps,
    dialogActionProps,
    dialogContentProps,
    dialogTitleProps,
    submitting = false,
    showCloseButton = false,
    onCloseClick = undefined,
    draggable = false
  } = props;
  const getId = (id: string, props?: any) =>
    props && props.id ? props.id : id;
  return (
    <Dialog
      maxWidth="lg"
      {...dialogProps}
      id={getId("mddDialog", dialogProps)}
      data-testid={getId("mddDialog", dialogProps)}
      className={`mdd-dialog ${dialogProps.className ||
        "mdd-dialog--no-scroll"}`}
      PaperComponent={draggable ? MddDraggablePaper : Paper}
    >
      <MddProgress
        inProgress={submitting}
        spinnerId={`${getId("mddDialog", dialogProps)}_loading`}
      />
      {!dialogTitleProps || !dialogTitleProps.title ? (
        undefined
      ) : (
        <DialogTitle
          {...dialogTitleProps}
          id={getId("mddDialogTitle", dialogTitleProps)}
          data-testid={getId("mddDialogTitle", dialogTitleProps)}
          className={`mdd-dialog--title ${dialogTitleProps.className ||
            "form-dialog-title"}`}
        >
          {dialogTitleProps.title}
          {showCloseButton && (
            <IconButton
              aria-label="close"
              className="mdd-dialog--close-button"
              id="dialogCloseIcon"
              data-testid="dialogCloseIcon"
              onClick={onCloseClick ? e => onCloseClick!(e) : undefined}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {!dialogContent ? (
        undefined
      ) : (
        <DialogContent
          {...dialogContentProps}
          id={getId("mddDialogContent", dialogContentProps)}
          data-testid={getId("mddDialogContent", dialogContentProps)}
          className={`mdd-dialog--content ${
            dialogContentProps
              ? dialogContentProps.className || "mdd-dialog--content-small"
              : ""
          }`}
        >
          {dialogContent}
        </DialogContent>
      )}
      {!dialogActions ? (
        undefined
      ) : (
        <DialogActions
          {...dialogActionProps}
          id={getId("mddDialogActions", dialogActionProps)}
          data-testid={getId("mddDialogActions", dialogActionProps)}
          className={`mdd-dialog--actions ${
            dialogActionProps
              ? dialogActionProps.className || "mdd-form-dialog-action"
              : ""
          }`}
        >
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  );
};
