/**
 * Service used to for common user functionality
 */
import { IUserService } from "../model";
import { OktaAuth } from "@okta/okta-auth-js";
import { ConfigOptions } from "../config";
import { deleteSHCookie } from "../utils";

export class UserService implements IUserService {
  private oktaAuth: OktaAuth;

  /**
   * Initialize the user service.
   * @param {OktaAuth} oktaAuth The Okta auth object
   */
  constructor(oktaAuth: OktaAuth) {
    this.oktaAuth = oktaAuth;
  }

  /**
   * Signs the user in (goes to ID Portal signin if not logged in) then redirects to the defined app callback
   * @returns {Promise<void>}
   */
  signInWithRedirect = async () => {
    await this.oktaAuth.signInWithRedirect();
  };

  /**
   * Gets the user information
   * @returns {Promise<IUser>}
   */
  getUser = async () => {
    return await this.oktaAuth.getUser();
  };

  /**
   * Gets the user's access token
   * @returns {string | undefined}
   */
  getAccessToken = () => {
    return this.oktaAuth ? this.oktaAuth.getAccessToken() : undefined;
  };

  /**
   * Signs out the user
   */
  signOut = async () => {
    this.oktaAuth.tokenManager.clear();
    // delete the SH-Session token
    deleteSHCookie();

    await this.oktaAuth.signOut({
      postLogoutRedirectUri: ConfigOptions.oktaLoginUrl
    });
  };
}
