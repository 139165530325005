import React, { useState } from "react";
import { ITabData } from "../../../store/resConfig/types";
import { MddExpansionPanel } from "../../common/MddExpansionPanel/MddExpansionPanel";

interface IMddResViewEducationProps {
  education: ITabData;
}

export const MddResViewEducation = ({
  education
}: IMddResViewEducationProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <MddExpansionPanel
      expanded={isExpanded}
      id="panelEducation"
      data-testid="panelEducation"
      panelClass="mdd-expansion-panel--panel mdd-res-config-view--panel-nonscales"
      headerText={<h3>Education</h3>}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <div
        id="viewRes-education"
        data-testid="viewRes-education"
        className="mdd-res-config-view"
      >
        <h4 className="mdd-res-config-view--panel-description">
          Education Fields:
        </h4>
        {education?.fields?.map((field: string) => (
          <div key={field} className="value">
            {field}
          </div>
        ))}
      </div>
    </MddExpansionPanel>
  );
};
