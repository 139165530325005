import * as React from "react";
import { CircularProgress } from "front-end-lib/core";

interface IMddProgressProps {
  id?: string;
  dataTestId?: string;
  spinnerId?: string;
  inProgress?: boolean;
  spinnerSize?: number;
}

export const MddProgress = (props: IMddProgressProps) => {
  const {
    id = "progressDiv",
    dataTestId = "progressDivTest",
    spinnerId = "progressSpinner",
    inProgress = false,
    spinnerSize = 40
  } = props;

  return (
    <React.Fragment>
      {inProgress && (
        <React.Fragment>
          <div id={id} data-testid={dataTestId} className="mdd-progress--div" />
          <div className="mdd-progress--spinner">
            <CircularProgress id={spinnerId} size={spinnerSize} />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
