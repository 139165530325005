import { trim } from "lodash";
import { ISiteDTO } from "../../../model";

export class SiteDTO implements ISiteDTO {
  public siteId?: string;
  public siteName: string;
  public address1?: string;
  public address2?: string;
  public city?: string;
  public countryValue: string;
  public country?: string;
  public stateId?: string;
  public state?: string;
  public zipCode?: string;
  public phone?: string;

  constructor(
    siteName: string,
    countryValue: string,
    country?: string,
    address1?: string,
    address2?: string,
    city?: string,
    stateId?: string,
    state?: string,
    zipcode?: string,
    phone?: string,
    siteId?: string
  ) {
    this.siteId = siteId || "";
    this.siteName = trim(siteName);
    this.address1 = trim(address1);
    this.address2 = trim(address2);
    this.city = trim(city);
    this.countryValue = trim(countryValue);
    this.country = trim(country);
    this.stateId = trim(stateId);
    this.state = trim(state);
    this.zipCode = trim(zipcode);
    this.phone = trim(phone);
  }
}
