import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { MddDialog, MddDialogButtons } from "../../../components";
import { IEditItem } from "../../../store/resConfig/types";
import {
  calculateItems,
  handleCheckedChange
} from "../../../store/resConfig/selectors";
import { IIdNamePair } from "../../../model";
import { IApplicationState } from "../../../store";
import { IndicationDialogContent } from "./IndicationDialogContent";

interface IProps {
  open: boolean;
  items: IEditItem[];
  edit: IEditItem[];
  indications: IIdNamePair[];
  demographics: IIdNamePair[];
  onClose: (edits?: IEditItem[]) => void;
}

export const MddResExperienceIndicationDialogComponent = ({
  open,
  onClose,
  edit: editsProps,
  items,
  ...rest
}: IProps) => {
  const [edits, setEdits] = useState<IEditItem[]>([]);
  const state = useMemo<IEditItem[]>(
    () =>
      open
        ? calculateItems({
            edits,
            items,
            parents: rest.indications,
            childs: rest.demographics,
            editState: editsProps
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [edits, items, open]
  );

  useEffect(() => {
    if (open) {
      setEdits([...editsProps.map(m => ({ ...m }))]);
    }
  }, [editsProps, open]);

  const handleChange = (item: IEditItem) => {
    setEdits(handleCheckedChange(item, edits));
  };

  return (
    <MddDialog
      dialogProps={{ open, maxWidth: "sm", fullWidth: true }}
      dialogTitleProps={{
        title: "Add Indication(s)"
      }}
      dialogContentProps={{ className: "mdd-res-draft--dialog" }}
      dialogContent={
        <IndicationDialogContent items={state} handleChange={handleChange} />
      }
      showCloseButton={true}
      onCloseClick={() => {
        onClose();
      }}
      dialogActions={
        <MddDialogButtons
          saveButtonText="Add Indication"
          saveButtonProps={{
            onClick: () => onClose(edits),
            disabled: !state.some(i => i.checked && !i.disabledOnModal)
          }}
          closeButtonProps={{ onClick: () => onClose() }}
        />
      }
    />
  );
};

export const MddResExperienceIndicationDialog = connect(
  ({ resConfig }: IApplicationState) => ({
    items: resConfig.indications.items!,
    indications: resConfig.indications.list!,
    demographics: resConfig.demographics.list!,
    edit: resConfig.indications.edit
  })
)(MddResExperienceIndicationDialogComponent);
