import { trim } from "lodash";
import { IStudySiteDTO, IStudyPersonnelProtocol } from "../../../model";

export class StudySiteDTO implements IStudySiteDTO {
  public StudyId: string;
  public SiteId: string;
  public SponsorSiteId: string;
  public StudySiteId: string;
  public PrimaryInvestigatorHistory: [];
  public Protocols: IStudyPersonnelProtocol[];

  constructor(
    studyId: string,
    siteId: string,
    sponsorSiteId: string,
    existingStudySiteValue: boolean
  ) {
    this.StudyId = studyId || "";
    this.SiteId = siteId || "";
    this.SponsorSiteId = trim(sponsorSiteId);
  }
}
