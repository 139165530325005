import { IScaleDTO } from "../..";
import { trim } from "lodash";

export class ScaleDTO implements IScaleDTO {
  public id: string;
  public name: string;
  public shortName: string;
  public masterScaleId: string;

  constructor(
    id: string,
    name: string,
    shortName: string,
    masterScaleId: string
  ) {
    this.id = id || "";
    this.name = trim(name);
    this.shortName = trim(shortName);
    this.masterScaleId = masterScaleId;
  }
}
