import {
  ICourseStatus,
  ITrainingExperience,
  ILearningPlanStatus,
  ILearningPlanAssignment,
  IPersonnelTrainingQuestionAnswer,
  IUpdateCourseStatusPayload
} from "../../../model";
import {
  LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE,
  EXPERIENCE_STATUS_VALUE
} from "../../../constants";
import { isLoading } from "../../../utils";
import { eStudySaga } from "../../../sagas";

export const getIsCorrectDisplayValue = (isCorrect: boolean | null) => {
  // isCorrect is a nullable boolean in the backend.  A value
  // of true should display Y, false should display N, but null should display nothing
  return isCorrect ? "Y" : isCorrect === false ? "N" : "";
};

export const isAtLeastOneAnswer = (
  answers: IPersonnelTrainingQuestionAnswer[]
) => {
  if (answers) {
    return answers.find(answer => answer.userResponse) !== undefined;
  }
  return false;
};

export const isLpSelected = (
  qual: ITrainingExperience,
  plan: ILearningPlanStatus,
  selectedLps: ILearningPlanAssignment[]
) => {
  // find the matching qual in the selected LP list
  const foundQual = selectedLps.find(item => {
    return item.studyQualificationId === qual.studyQualificationId;
  });
  if (foundQual) {
    // is our LP selected?
    const foundLp = foundQual.learningPlanIds.find(lpId => {
      return lpId === plan.learningPlanId;
    });
    if (foundLp) {
      return true;
    }
  }

  return false;
};

export const disableLPCheckbox = (plan: ILearningPlanStatus) => {
  // disable the checkbox if the plan is not assignable
  // OR it is already assigned or pending assignment
  // OR it doesn't have any courses
  // OR is a legacy LP (learningPlanId 0 or null)
  return (
    !plan.isAssignable ||
    (plan.learningPlanAssignmentStatus &&
      plan.learningPlanAssignmentStatus.value ===
        (LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE.ASSIGNED ||
          plan.learningPlanAssignmentStatus.value ===
            LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE.PENDING_ASSIGNMENT)) ||
    plan.userCourseStatuses.length === 0 ||
    !plan.learningPlanId
  );
};

export const isQualLpAssigned = (
  qualId: string,
  lpId: number,
  trainingExperience: ITrainingExperience[] | undefined
) => {
  // find the qualification in our current training list
  const qual = trainingExperience
    ? trainingExperience.find(qual => {
        return qual.studyQualificationId === qualId;
      })
    : undefined;

  if (qual) {
    // find the LP in this qual
    const lp = qual.userLearningPlanStatuses.find(lp => {
      return lp.learningPlanId === lpId;
    });
    // Assignment status is null if not assigned but also can be pending assignment.
    // We don't want pending to count as assigned.
    if (
      lp &&
      lp.learningPlanAssignmentStatus &&
      lp.learningPlanAssignmentStatus.value ===
        LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE.ASSIGNED
    ) {
      return true;
    }
  }

  return false;
};

export const canExemptCourse = (
  course: ICourseStatus,
  trainingExperience: ITrainingExperience[] | undefined
) => {
  // a course can be exempted only if it's in an incomplete status and
  // the LP is assigned.  Also cannot be exempted if the course is in a legacy LP.
  return (
    course &&
    isQualLpAssigned(
      course.studyQualificationId!,
      course.learningPlanId!,
      trainingExperience
    ) &&
    (!course.courseStatus ||
      course.courseStatus.value === EXPERIENCE_STATUS_VALUE.INCOMPLETE) &&
    course.learningPlanId
  );
};

export const showTrainingSpinner = () => {
  return isLoading(
    `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING}`,
    `undefined_${eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT}`,
    `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS}`,
    `undefined_${eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT}`,
    `undefined_${eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT}`,
    `undefined_${eStudySaga.SAVE_COURSE_STATUS}`,
    `undefined_${eStudySaga.UNASSIGN_LP}`,
    `undefined_${eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES}`,
    `undefined_${eStudySaga.FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES}`,
    `undefined_${eStudySaga.SAVE_QUALIFICATION_STATUS}`,
    `undefined_${eStudySaga.FETCH_COURSE_MASTER_QUESTIONS}`,
    `undefined_${eStudySaga.SAVE_TRAINING_COURSE_MANUAL_SCORES}`,
    `undefined_${eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES}`,
    `undefined_${eStudySaga.DELETE_TRAINING_COURSE_MANUAL_SCORES}`
  );
};

export const isFullyExpanded = (
  trainingExperience: ITrainingExperience[] | undefined
) => {
  if (trainingExperience) {
    const firstCollapsedQual = trainingExperience.find(item => {
      return !item.expanded;
    });
    // if we found a collapsed qualification panel then just return false, otherwise we
    // need to look at each LP.
    if (firstCollapsedQual) {
      return false;
    }
    for (const qual of trainingExperience) {
      const firstCollapsedLP = qual.userLearningPlanStatuses.find(lp => {
        return !lp.expanded;
      });
      // if a collapsed one in this qualification then we're done
      if (firstCollapsedLP) {
        return false;
      }
    }
    return true;
  } else {
    return true; // if no training then return true so option is disabled
  }
};

export const isFullyCollapsed = (
  trainingExperience: ITrainingExperience[] | undefined
) => {
  if (trainingExperience) {
    const firstUncollapsed = trainingExperience.find(item => {
      return item.expanded;
    });
    // if we found a uncollapsed qualification panel then just return false, otherwise we
    // need to look at each LP.
    if (firstUncollapsed) {
      return false;
    }
    for (const qual of trainingExperience) {
      const firstUncollapsedLP = qual.userLearningPlanStatuses.find(lp => {
        return lp.expanded;
      });
      // if a uncollapsed one in this qualification then we're done
      if (firstUncollapsedLP) {
        return false;
      }
    }
    return true;
  } else {
    return true; // if no training then return true so option is disabled
  }
};

export const enableUnassign = (plan: ILearningPlanStatus) => {
  if (plan.userCourseStatuses) {
    const foundIncompleteCourse = plan.userCourseStatuses.find(course => {
      return (
        !course.courseStatus ||
        course.courseStatus.value === EXPERIENCE_STATUS_VALUE.INCOMPLETE
      );
    });
    if (foundIncompleteCourse) {
      return true;
    }
  }
  return false;
};

export const getUpdatedLpWithNewStatusAndDate = (
  payload: IUpdateCourseStatusPayload,
  lp: ILearningPlanStatus
) => {
  const foundLP = payload.learningPlanStatuses
    ? payload.learningPlanStatuses.find(
        plan => plan.learningPlanId === lp.learningPlanId
      )
    : undefined;
  return Object.assign(
    {},
    {
      ...lp,
      learningPlanStatus: foundLP
        ? foundLP.learningPlanStatus
        : lp.learningPlanStatus,
      learningPlanStatusDate: foundLP
        ? foundLP.learningPlanStatusDate
        : lp.learningPlanStatusDate
    }
  );
};

export const getUpdatedQualWithNewStatusAndDate = (
  payload: IUpdateCourseStatusPayload,
  studyQual: ITrainingExperience,
  learningPlans: ILearningPlanStatus[]
) => {
  const foundQual = payload.studyQualificationStatuses
    ? payload.studyQualificationStatuses.find(
        qual => qual.studyQualificationId === studyQual.studyQualificationId
      )
    : undefined;
  return Object.assign(
    {},
    {
      ...studyQual,
      userLearningPlanStatuses: learningPlans,
      studyQualificationStatus: foundQual
        ? foundQual.studyQualificationStatus
        : studyQual.studyQualificationStatus,
      studyQualificationStatusDate: foundQual
        ? foundQual.studyQualificationStatusDate
        : studyQual.studyQualificationStatusDate
    }
  );
};

export const showSpinner = () => {
  return isLoading(
    `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING}`,
    `undefined_${eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT}`,
    `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS}`,
    `undefined_${eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT}`,
    `undefined_${eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT}`,
    `undefined_${eStudySaga.SAVE_COURSE_STATUS}`,
    `undefined_${eStudySaga.UNASSIGN_LP}`,
    `undefined_${eStudySaga.FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES}`,
    `undefined_${eStudySaga.SAVE_QUALIFICATION_STATUS}`
  );
};

export const metricsFields = [
  { label: "Status", field: "courseStatusValue" },
  { label: "Status Date", field: "statusDate" },
  { label: "Percentage", field: "overallPercentage" },
  { label: "Items Deviation", field: "itemsDeviation" },
  { label: "Absolute Items Deviation", field: "absoluteItemsDeviation" },
  { label: "Total Score", field: "totalScore" }
];

export const metadataFields = [
  {
    label: "Modified By",
    field: "modifiedBy"
  },
  {
    label: "Modified Date",
    field: "modifiedDate"
  },
  {
    label: "Course Completion Date",
    field: "statusDate"
  },
  {
    label: "QC User",
    field: "qcUser"
  },
  {
    label: "QC Date",
    field: "qcDate"
  }
];
