import { EXPERIENCE_STATUS_DISPLAY_VALUE } from "../constants";

export const isEditDisabled = (scale: { status: string } | undefined | null) =>
  !scale || scale.status === EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE;

export const getButtonText = (scale: { status: string } | undefined | null) =>
  !scale ||
  scale.status === EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE ||
  scale.status === EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
    ? "Edit"
    : "Override";
