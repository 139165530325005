import * as React from "react";
import { ConnectedRouter } from "connected-react-router";
import { withProvider } from "../WithProvider/WithProvider";
import { history } from "../../../utils";

export function withRouter(children: React.ReactNode): JSX.Element {
  return withProvider(
    <ConnectedRouter history={history}>{children}</ConnectedRouter>
  );
}
