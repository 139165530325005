import * as React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { renderQualificationPanel } from "./MddStudySitePersonTrainingRenderHelper";

import { IApplicationState } from "../../../store";
import { SagaService, IAction, ISelectData } from "front-end-lib/core";
import {
  showTrainingSpinner,
  isFullyCollapsed,
  isFullyExpanded,
  getUpdatedLpWithNewStatusAndDate,
  getUpdatedQualWithNewStatusAndDate
} from "./MddTrainingHelpers";
import { MddStudySitePersonEditCourseScores } from "./MddStudySitePersonEditCourseScores";
import { MddStudySitePersonViewCourseScores } from "./MddStudySitePersonViewCourseScores";

import {
  MddBreadcrumb,
  withMddBase,
  MddProgress,
  MddStudySitePersonComments,
  MddDialog,
  MddDialogButtons,
  MddOverrideStatusDialog,
  MddNoDataDiv
} from "../..";
import {
  IStudy,
  ISite,
  IStudySitePerson,
  ITrainingExperience,
  ILearningPlanStatus,
  ICourseStatus,
  ILearningPlanAssignment,
  IUpdateCourseStatusPayload,
  ISaveQualificationStatusResponse,
  ISaveQualificationStatusPayload,
  INameValuePair
} from "../../../model";
import { eStudySaga, eSiteSaga } from "../../../sagas";
import {
  formatPersonName,
  formatTrainingExperienceResponse,
  isLoading
} from "../../../utils";
import {
  LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE,
  LEARNING_PLAN_ASSIGNMENT_STATUS_NAME,
  EXPERIENCE_STATUS_VALUE,
  QUALIFICATION_STATUS_DISPLAY_VALUE,
  TRAINING_COURSE_TYPE,
  QUALIFICATION_STATUS_VALUE
} from "../../../constants";

interface IMddStudySitePersonTrainingProps {
  match: {
    params: {
      id: string;
      siteId: string;
      personnelId: string;
    };
  };
  study: IStudy;
  person: IStudySitePerson;
  site: ISite;
}

const MddStudySitePersonTrainingComponent = (
  props: IMddStudySitePersonTrainingProps
) => {
  const [trainingExperience, setTrainingExperience] = React.useState<
    ITrainingExperience[] | undefined
  >(undefined);
  const [selectedLps, setSelectedLps] = React.useState<
    ILearningPlanAssignment[]
  >([]);
  const [isUnassignOpen, setisUnassignOpen] = React.useState(false);
  const [currUnassignLpId, setCurrUnassignLpId] = React.useState<number | null>(
    null
  );
  const [isOverrideDialogOpen, setIsOverrideDialogOpen] = React.useState(false);
  const [
    isCourseScoresDialogOpen,
    setIsCourseScoresDialogOpen
  ] = React.useState(false);
  const [isCourseEditMode, setIsCourseEditMode] = React.useState(false);

  const [currentQual, setCurrentQual] = React.useState<
    ITrainingExperience | undefined
  >(undefined);
  const [statuses, setStatuses] = React.useState<ISelectData[]>([]);
  const [currCourse, setCurrCourse] = React.useState<ICourseStatus | null>(
    null
  );
  const [updatingScores, setUpdatingScores] = React.useState(false);

  // need refs for these since they are accessed within a closure in the useEffect
  const trainingExperienceRef = React.useRef(trainingExperience);
  trainingExperienceRef.current = trainingExperience;

  const currUnassignLpIdRef = React.useRef(currUnassignLpId);
  currUnassignLpIdRef.current = currUnassignLpId;

  const selectedLpsRef = React.useRef(selectedLps);
  selectedLpsRef.current = selectedLps;

  const currCourseRef = React.useRef(currCourse);
  currCourseRef.current = currCourse;

  const updatingScoresRef = React.useRef(updatingScores);
  updatingScoresRef.current = updatingScores;

  React.useEffect(() => {
    const { id, siteId, personnelId } = props.match.params;

    const removeFetchTrainingSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING,
      (action: IAction) => {
        if (
          action.type === eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING_SUCCESS
        ) {
          setTrainingExperience(
            formatTrainingExperienceResponse(
              action.payload.userStudyQualificationStatuses,
              trainingExperienceRef!.current!,
              updatingScoresRef.current
            )
          );
          setUpdatingScores(false);
        }
      }
    );

    const removeSaveLpAssignmentSub = SagaService.subscribeToSaga(
      eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT_SUCCESS
        ) {
          // Go through the list of selected LPs and set them all to an assigned status.  This
          // prevents us from having to reload again which would end up also collapsing all panels
          // which is not desirable and not easy to get around.
          assignLPsAfterSave();
          // Clear all the selected LPs (which will uncheck the checkboxes)
          setSelectedLps([]);
        }
      }
    );

    const removeSaveCourseStatusSub = SagaService.subscribeToSaga(
      eStudySaga.SAVE_COURSE_STATUS,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_COURSE_STATUS_SUCCESS) {
          updateCourseStatus(action.payload);
        }
      }
    );

    const removeUnassignLPSub = SagaService.subscribeToSaga(
      eStudySaga.UNASSIGN_LP,
      (action: IAction) => {
        if (action.type === eStudySaga.UNASSIGN_LP_SUCCESS) {
          const newTraining = trainingExperienceRef!.current!.map(qual => {
            return Object.assign(
              {},
              {
                ...qual,
                userLearningPlanStatuses: qual.userLearningPlanStatuses.map(
                  lp => {
                    return Object.assign(
                      {},
                      {
                        ...lp,
                        learningPlanAssignmentStatus:
                          lp.learningPlanId === currUnassignLpIdRef.current
                            ? null
                            : lp.learningPlanAssignmentStatus,
                        isAssignable:
                          // lp.learningPlanId === action.payload.learningPlanId
                          lp.learningPlanId === currUnassignLpIdRef.current
                            ? true
                            : lp.isAssignable
                      }
                    );
                  }
                )
              }
            );
          });
          setTrainingExperience(newTraining);
          handleCloseUnassignDialog();
        }
      }
    );

    const removeFetchNextStatuses = SagaService.subscribeToSaga(
      eStudySaga.FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES_SUCCESS
        ) {
          setStatuses(
            action.payload.map((status: INameValuePair) => {
              return { value: status.value, label: status.name };
            })
          );
        }
      }
    );

    const removeSaveQualStatus = SagaService.subscribeToSaga(
      eStudySaga.SAVE_QUALIFICATION_STATUS,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_QUALIFICATION_STATUS_SUCCESS) {
          setIsOverrideDialogOpen(false);
          const data: ISaveQualificationStatusResponse = action.payload;
          setTrainingExperience(
            trainingExperienceRef!.current!.map(exp => {
              return exp.studyQualificationName === data.studyQualificationName
                ? Object.assign({} as ITrainingExperience, {
                    ...exp,
                    studyQualificationStatus: data.studyQualificationStatus,
                    studyQualificationStatusDate:
                      data.studyQualificationStatusDate,
                    comment: data.comment,
                    userStudyQualificationStatusId:
                      data.userStudyQualificationStatusId
                  })
                : { ...exp };
            })
          );
        }
      }
    );

    const removeGetCourseStatusSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES_SUCCESS
        ) {
          // Update the course status date and status in place.
          // Loop through all qualifications, then all LPs under
          // that qual, and finally the courses in each LP.  Update
          // any that match our course ID since a course can be in
          // multiple LPs, and a LP can be in multiple quals.
          const newExp = trainingExperienceRef.current!.map(
            (qual: ITrainingExperience) => {
              const lps = qual.userLearningPlanStatuses.map(
                (lp: ILearningPlanStatus) => {
                  const courses = lp.userCourseStatuses.map(
                    (course: ICourseStatus) => {
                      return Object.assign(
                        {},
                        { ...course },
                        {
                          courseStatus:
                            course.courseId === currCourseRef.current!.courseId
                              ? action.payload.courseStatus
                              : course.courseStatus,
                          courseStatusDate:
                            course.courseId === currCourseRef.current!.courseId
                              ? action.payload.statusDate
                              : course.courseStatusDate,
                          sourceEntry:
                            course.courseId === currCourseRef.current!.courseId
                              ? action.payload.sourceEntry
                              : course.sourceEntry
                        }
                      );
                    }
                  );
                  return Object.assign(
                    {},
                    {
                      ...lp,
                      userCourseStatuses: courses
                    }
                  );
                }
              );
              return Object.assign(
                {},
                {
                  ...qual,
                  userLearningPlanStatuses: lps
                }
              );
            }
          );
          setTrainingExperience(newExp);
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING,
      payload: {
        studyId: id,
        siteId,
        personnelId
      }
    });

    // if not in the store already (i.e. they went directly to this page from a link),
    // then load the study, site and person.  These are only used on this page for the
    // breadcrumb so they are intentionally left to last so the important stuff can load first.
    if (!study.StudyId) {
      SagaService.dispatchSaga({ type: eStudySaga.FETCH_STUDY, payload: id });
    }
    if (!currentSite.SiteId) {
      SagaService.dispatchSaga({ type: eSiteSaga.FETCH_SITE, payload: siteId });
    }
    if (!person.personnelId) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_SITE_PERSON.toString(),
        payload: {
          dto: { StudyId: id, SiteId: siteId, PersonnelId: personnelId }
        }
      });
    }

    return () => {
      removeFetchTrainingSub();
      removeSaveLpAssignmentSub();
      removeSaveCourseStatusSub();
      removeUnassignLPSub();
      removeFetchNextStatuses();
      removeSaveQualStatus();
      removeGetCourseStatusSub();
    };
    // eslint-disable-next-line
  }, []);

  const assignLPsAfterSave = () => {
    // Go through all the LPs just assigned and set their status to pending assignment.  This prevents us from having to
    // reload which not only prevents another API call but also from having to jump through major hoops to keep
    // things collapsed/expanded as they were previously.  Note that, for now, they will have to refresh the page
    // in order to see if it was successfully assigned.

    // To make things easier, put together a list of all assigned LPs separate from their qualifications.  If an LP
    // is in multiple quals assigning it in one assigns it in all so just create an array of all the assigned
    // LP id's - it doesn't matter if there are duplicates in the list since we're just checking to see if a given one
    // is in the list.
    const lpList: number[] = [];
    selectedLpsRef.current.forEach(qual => {
      qual.learningPlanIds.forEach(lpId => {
        lpList.push(lpId);
      });
    });

    const wasLpAssigned = (id: number) => {
      // If the lp is in the list to assign for any qualification it was assigned.
      return lpList.find(lpId => {
        return lpId === id;
      });
    };

    setTrainingExperience(
      trainingExperienceRef!.current!.map((qual: ITrainingExperience) => {
        const newLps = qual.userLearningPlanStatuses.map(
          (lp: ILearningPlanStatus) => {
            return Object.assign(
              {},
              {
                ...lp,
                isAssignable: wasLpAssigned(lp.learningPlanId)
                  ? false
                  : lp.isAssignable,
                learningPlanAssignmentStatus: wasLpAssigned(lp.learningPlanId)
                  ? {
                      name:
                        LEARNING_PLAN_ASSIGNMENT_STATUS_NAME.PENDING_ASSIGNMENT,
                      value:
                        LEARNING_PLAN_ASSIGNMENT_STATUS_VALUE.PENDING_ASSIGNMENT
                    }
                  : lp.learningPlanAssignmentStatus
              }
            );
          }
        );
        return Object.assign({}, { ...qual, userLearningPlanStatuses: newLps });
      })
    );
  };

  const updateCourseStatus = (payload: IUpdateCourseStatusPayload) => {
    const newExp = trainingExperienceRef.current!.map(
      (qual: ITrainingExperience) => {
        const learningPlans = qual.userLearningPlanStatuses.map(
          (lp: ILearningPlanStatus) => {
            const courses = lp.userCourseStatuses.map(
              (course: ICourseStatus) => {
                return Object.assign(
                  {},
                  { ...course },
                  {
                    courseStatus:
                      payload.courseId === course.courseId
                        ? payload.courseStatus
                        : course.courseStatus,
                    courseStatusDate:
                      payload.courseId === course.courseId
                        ? payload.courseStatusDate
                        : course.courseStatusDate
                  }
                );
              }
            );
            return Object.assign(
              {},
              {
                ...getUpdatedLpWithNewStatusAndDate(payload, lp)
              },
              {
                userCourseStatuses: courses
              }
            );
          }
        );
        return Object.assign(
          {},
          {
            ...getUpdatedQualWithNewStatusAndDate(payload, qual, learningPlans)
          }
        );
      }
    );

    setTrainingExperience(newExp);
  };

  const toggleQualPanel = (expanded: boolean, index: number) => {
    const currTraining = [...(trainingExperience as ITrainingExperience[])];
    currTraining[index] = Object.assign(
      {},
      { ...trainingExperience![index] },
      { expanded }
    );
    setTrainingExperience(currTraining);
  };

  const toggleLpPanel = (
    expanded: boolean,
    index: number,
    qual: ITrainingExperience
  ) => {
    const newLps = [...qual.userLearningPlanStatuses];
    newLps[index].expanded = expanded;
    // find the qualification that matches this so we can update it
    const currQualIndex = trainingExperience!.findIndex(
      (item: ITrainingExperience) => {
        return item.studyQualificationId === qual.studyQualificationId;
      }
    );
    if (currQualIndex !== -1) {
      const currTraining = [...(trainingExperience as ITrainingExperience[])];
      currTraining[currQualIndex].userLearningPlanStatuses = newLps;
      setTrainingExperience(currTraining);
    }
  };

  const handleExpandAll = () => {
    if (trainingExperience) {
      setTrainingExperience(
        trainingExperience.map((qual: ITrainingExperience) => {
          const newLps = qual.userLearningPlanStatuses.map(
            (lp: ILearningPlanStatus) => {
              return Object.assign({}, { ...lp, expanded: true });
            }
          );
          return Object.assign(
            {},
            { ...qual, userLearningPlanStatuses: newLps, expanded: true }
          );
        })
      );
    }
  };
  const handleCollapseAll = () => {
    if (trainingExperience) {
      setTrainingExperience(
        trainingExperience.map((qual: ITrainingExperience) => {
          const newLps = qual.userLearningPlanStatuses.map(
            (lp: ILearningPlanStatus) => {
              return Object.assign({}, { ...lp, expanded: false });
            }
          );
          return Object.assign(
            {},
            { ...qual, userLearningPlanStatuses: newLps, expanded: false }
          );
        })
      );
    }
  };

  const handleLPClick = (
    e: React.MouseEvent,
    qual: ITrainingExperience,
    plan: ILearningPlanStatus
  ) => {
    e.stopPropagation(); // prevent collapse of panel

    // see if it's already in the list of ones to assign, if so take it out (unchecked the box), otherwise
    // put it in (checked the box).
    // Since a given LP can be associated with multiple qualifications and the API needs the qualification ID
    // and an array of LP IDs to assign underneath that qual we'll mimic that stucture here.

    // First see if the current qual ID exists in our selected LP list.
    const foundQual = selectedLps.find((item: ILearningPlanAssignment) => {
      return item.studyQualificationId === qual.studyQualificationId;
    });
    if (foundQual) {
      // qual is already there, if the LP is already there remove it from the LP array, otherwise add it
      const foundLp = foundQual.learningPlanIds.find(lp => {
        return lp === plan.learningPlanId;
      });
      if (foundLp) {
        // already there, remove it
        const newLps = foundQual.learningPlanIds.filter(lpId => {
          return lpId !== plan.learningPlanId;
        });
        const newQual = { ...foundQual, learningPlanIds: newLps };
        // remove the old qual from the list and put this new one in
        const newSelectedLps = selectedLps.filter(item => {
          return item.studyQualificationId !== newQual.studyQualificationId;
        });
        // add it in but only if at least one LP is selected
        if (newQual.learningPlanIds.length) {
          newSelectedLps.push(newQual);
        }
        setSelectedLps(newSelectedLps);
      } else {
        // LP not found in this qual so add it
        const newLps = [...foundQual.learningPlanIds];
        newLps.push(plan.learningPlanId);
        const newQual = { ...foundQual, learningPlanIds: newLps };
        // remove the old qual from the list and put this new one in
        const newSelectedLps = selectedLps.filter(item => {
          return item.studyQualificationId !== newQual.studyQualificationId;
        });
        newSelectedLps.push(newQual);
        setSelectedLps(newSelectedLps);
      }
    } else {
      // qual is not there, just add it and the LP
      const newSelectedLps = [...selectedLps];
      newSelectedLps.push({
        studyQualificationId: qual.studyQualificationId,
        learningPlanIds: [plan.learningPlanId]
      });
      setSelectedLps(newSelectedLps);
    }
  };

  const handleAssignLps = () => {
    const { id, personnelId } = props.match.params;
    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT,
      payload: {
        studyId: id,
        personnelId,
        trainingLearningPlanAssignments: selectedLps
      }
    });
  };

  const handleUnassignClick = (e: React.MouseEvent, lpId: number) => {
    e.stopPropagation();
    setCurrUnassignLpId(lpId);
    setisUnassignOpen(true);
  };

  const handleUnassignLp = () => {
    SagaService.dispatchSaga({
      type: eStudySaga.UNASSIGN_LP,
      payload: {
        studyId: id,
        personnelId,
        learningPlanId: currUnassignLpId
      }
    });
  };

  const handleUpdateCourseStatus = (
    e: React.MouseEvent,
    course: ICourseStatus,
    status: string
  ) => {
    const { id, personnelId } = props.match.params;
    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_COURSE_STATUS,
      payload: {
        studyId: id,
        personnelId,
        courseId: course.courseId,
        status,
        courseStatusId: course.userCourseStatusId
      }
    });
  };

  const handleCloseUnassignDialog = () => {
    setCurrUnassignLpId(null);
    setisUnassignOpen(false);
  };

  const handleOverrideClick = (
    e: React.MouseEvent,
    qual: ITrainingExperience
  ) => {
    e.stopPropagation(); // prevent open or collapse of panel
    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES,
      payload: {
        studyId: id,
        personnelId,
        userStudyQualificationStatusId: qual.userStudyQualificationStatusId
      } as ISaveQualificationStatusPayload
    });

    setCurrentQual(qual);
    setIsOverrideDialogOpen(true);
  };

  const handleSaveOverride = (
    status: string,
    commentText: string,
    statusDate?: string
  ) => {
    const { id, personnelId } = props.match.params;
    // If the status hasn't changed then use the old endpoint, otherwise use the new one (isStatusChanged flag used to tell the API what to do)
    let isStatusChanged = false;
    // If no previous status (studyQualificationStatus is null) and our current status is not incomplete or null, it changed.
    // If we do have a previous status then it changed if our current status is different than the previous.  Otherwise it hasn't.
    const havePreviousStatus = !!currentQual!.studyQualificationStatus;
    if (
      (!havePreviousStatus &&
        status !== QUALIFICATION_STATUS_VALUE.INCOMPLETE &&
        status != null) ||
      (havePreviousStatus &&
        status !== currentQual!.studyQualificationStatus!.value)
    ) {
      isStatusChanged = true;
    }
    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_QUALIFICATION_STATUS,
      payload: {
        studyId: id,
        personnelId,
        userStudyQualificationStatusId: currentQual!
          .userStudyQualificationStatusId,
        studyQualificationId: currentQual!.studyQualificationId,
        status,
        isStatusChanged,
        commentText: encodeURIComponent(commentText),
        statusDate:
          status === QUALIFICATION_STATUS_VALUE.INCOMPLETE ? null : statusDate
      }
    });
  };

  const handleCourseClick = (course: ICourseStatus) => {
    if (
      course.courseStatus &&
      course.courseStatus.value !== EXPERIENCE_STATUS_VALUE.INCOMPLETE &&
      course.trainingCourseType === TRAINING_COURSE_TYPE.SCORING
    ) {
      setCurrCourse(course);
      setIsCourseScoresDialogOpen(true);
    }
  };

  const handleCourseScoresClose = () => {
    setIsCourseScoresDialogOpen(false);
    setIsCourseEditMode(false);
  };

  const handleCourseScoresEditClose = (shouldUpdateScore: boolean) => {
    setIsCourseScoresDialogOpen(false);
    setIsCourseEditMode(false);
    // reload the training data if they told us to update
    if (shouldUpdateScore) {
      setUpdatingScores(true);
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING,
        payload: {
          studyId: id,
          siteId,
          personnelId
        }
      });
    }
  };

  const handleEditClick = (course: ICourseStatus) => {
    setCurrCourse(course);
    setIsCourseEditMode(true);
    setIsCourseScoresDialogOpen(true);
  };

  const isExpanded = isFullyExpanded(trainingExperience);
  const isCollapsed = isFullyCollapsed(trainingExperience);

  const { study, site: currentSite, person } = props;
  const { id, siteId, personnelId } = props.match.params;

  return (
    <React.Fragment>
      <MddProgress inProgress={showTrainingSpinner()} spinnerId="svgLoading" />
      <MddBreadcrumb
        breadcrumbs={[
          { display: "All Studies", route: "/study" },
          {
            display: study && study.StudyName ? study.StudyName : "Loading...",
            route: `/study/${id}`
          },
          {
            display:
              currentSite && currentSite.SiteName
                ? currentSite.SiteName
                : "Loading...",
            route: `/study/${id}/sites/${siteId}/personnel`
          },
          { display: formatPersonName(person) }
        ]}
      />
      <div className="mdd-training--container" data-testid="divTraining">
        <div
          className="mdd-training--header-div"
          id="divTrainingHeader"
          data-testid="divTrainingHeader"
        >
          <div className="mdd-training--header-div--button">
            <Button
              className="button-primary"
              size="small"
              variant="contained"
              color="primary"
              disabled={selectedLps.length === 0}
              data-testid={`btnAssign`}
              id={`btnAssign`}
              onClick={handleAssignLps}
            >
              Assign Selected LPs
            </Button>
          </div>
          <div
            className="mdd-training--header-div--placeholder"
            id="hdrPlaceholder"
            data-testid="hdrPlaceholder"
          />
          <div className="mdd-training--header-div--expand-collapse">
            <span
              className={
                isExpanded
                  ? "mdd-training--header-div--expand-collapse--disabled"
                  : ""
              }
              data-testid={
                isExpanded ? "expandAllDisabled" : "expandAllEnabled"
              }
              id="txtExpandAll"
              onClick={() => handleExpandAll()}
            >
              Expand All
            </span>
            <span>|</span>
            <span
              className={
                isCollapsed
                  ? "mdd-training--header-div--expand-collapse--disabled"
                  : ""
              }
              data-testid={
                isCollapsed ? "collapseAllDisabled" : "collapseAllEnabled"
              }
              id="txtCollapseAll"
              onClick={() => handleCollapseAll()}
            >
              Collapse All
            </span>
          </div>
        </div>
        {trainingExperience ? (
          trainingExperience.map((item: ITrainingExperience, index: number) => {
            return renderQualificationPanel(
              item,
              index,
              selectedLps,
              trainingExperience,
              handleLPClick,
              handleOverrideClick,
              handleUnassignClick,
              toggleLpPanel,
              handleCourseClick,
              handleUpdateCourseStatus,
              handleEditClick,
              toggleQualPanel
            );
          })
        ) : (
          <MddNoDataDiv loading={showTrainingSpinner()} />
        )}
      </div>
      <MddStudySitePersonComments
        studyId={id}
        siteId={siteId}
        personnelId={personnelId}
      />
      {isUnassignOpen && (
        <MddDialog
          showCloseButton={true}
          onCloseClick={() => handleCloseUnassignDialog()}
          dialogProps={{
            id: "divUnassignDialog",
            open: isUnassignOpen,
            maxWidth: "xs"
          }}
          dialogActionProps={{
            id: "divUnassignDialogActions",
            className: "mdd-study-scores--lock-button"
          }}
          dialogContentProps={{
            id: "divUnassignDialogContent"
          }}
          dialogTitleProps={{
            id: "divUnassignTitle",
            title: "Unassign LP"
          }}
          dialogContent={
            <span>Are you sure you want to unassign the selected LP?</span>
          }
          dialogActions={
            <MddDialogButtons
              saveButtonText="Yes"
              closeButtonText="No"
              saveButtonProps={{
                id: "btnUnassignYes",
                onClick: () => handleUnassignLp()
              }}
              closeButtonProps={{
                id: "btnUnassignNo",
                onClick: () => handleCloseUnassignDialog()
              }}
            />
          }
        />
      )}
      {isOverrideDialogOpen && (
        <MddOverrideStatusDialog
          open={isOverrideDialogOpen}
          statuses={statuses}
          appendCurrentStatus={true}
          isQualOverride={true}
          headerText={`Override ${
            currentQual ? currentQual.studyQualificationName : ""
          }`}
          isCommentRequired={true}
          defTypeText="Study Qualification"
          defValueText={`${
            currentQual ? currentQual.studyQualificationName : ""
          }`}
          currStatusText={
            currentQual && currentQual.studyQualificationStatus
              ? currentQual.studyQualificationStatus.name
              : QUALIFICATION_STATUS_DISPLAY_VALUE.INCOMPLETE
          }
          prevStatusDate={currentQual!.studyQualificationStatusDate}
          saveInProgress={isLoading(
            `undefined_${eStudySaga.SAVE_QUALIFICATION_STATUS}`
          )}
          onSave={(status: string, commentText: string, statusDate: string) =>
            handleSaveOverride(status, commentText, statusDate)
          }
          onClose={() => setIsOverrideDialogOpen(false)}
        />
      )}
      {isCourseScoresDialogOpen && isCourseEditMode && (
        <MddStudySitePersonEditCourseScores
          course={currCourse!}
          personnelId={personnelId}
          handleCourseScoresClose={handleCourseScoresEditClose}
        />
      )}
      {isCourseScoresDialogOpen && !isCourseEditMode && (
        <MddStudySitePersonViewCourseScores
          course={currCourse!}
          studyId={id}
          personnelId={personnelId}
          handleCourseScoresClose={handleCourseScoresClose}
        />
      )}
    </React.Fragment>
  );
};

export const MddStudySitePersonTraining = connect(
  (state: IApplicationState) => ({
    study: state.study.currentStudy,
    person: state.study.currentStudySitePerson,
    site: state.site.currentSite
  })
)(withMddBase(MddStudySitePersonTrainingComponent));
