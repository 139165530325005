import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { unregister } from "./registerServiceWorker";

import { rootStorelessSagas } from "./sagas";
import "./sass/main.scss";
// import * as dotenv from "dotenv";
import App from "App";

// dotenv.config();
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
rootStorelessSagas();
unregister();
