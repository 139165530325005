import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IBktDataTableColumnProps } from "front-end-lib";
import React from "react";
import { MddCheckbox } from "../../../components";
import { IIdNamePair } from "../../../model/interfaces/iIdNamePair";

export interface IScaleBktDataTable extends Omit<IIdNamePair, "name"> {
  key: string;
  name: React.ReactNode;
  active: React.ReactNode;
  delete: React.ReactNode;
}

export const tableStyles = {
  tableHeadProps: {
    className: "tbl--header tableHead",
    id: "resDraftTableHeader"
  },
  tableBodyProps: {
    rowProps: { className: "tbl--body-row mdd-tbl--body-row" }
  },
  tableFooterProps: {
    className: "mdd-res-draft--tableFooter",
    id: "resDraftTableFooter"
  }
};

export const headers = (
  openDeleteDialog: (id: string) => void
): IBktDataTableColumnProps[] => [
  {
    label: "Scale",
    tableCellProps: { id: "scaleHeader" }
  },
  {
    label: "Full Name",
    tableCellProps: { id: "fullNameHeader" }
  },
  {
    label: "Master Scale Name",
    tableCellProps: { id: "masterScaleHeader" }
  },
  {
    label: "Active",
    tableCellProps: { id: "activeHeader" }
  },
  {
    label: "Delete",
    tableCellProps: { id: "deleteHeader" },
    formatProp: {
      formatPropName: "delete",
      format: ({ id, deletable }) => (
        <div className="tbl--body-row-actions">
          <FontAwesomeIcon
            className={deletable ? "" : "icon-disabled"}
            data-testid={`deleteIcon_${id}`}
            onClick={() => openDeleteDialog(id)}
            icon={faTimesCircle}
            fixedWidth
            id="closeIcon"
            size="xl"
          />
        </div>
      )
    }
  }
];

export const getConfig = (
  scales: {
    parentId?: string;
    name: string;
    fullName: string;
    masterScaleName: string;
    isActive: boolean;
    deletable: boolean;
    disabledOnGrid: boolean;
  }[],
  handle: (parentId: string) => void
) =>
  scales.map(
    ({
      parentId,
      name,
      isActive,
      fullName,
      masterScaleName,
      deletable,
      disabledOnGrid
    }) => ({
      key: name,
      name,
      fullName,
      masterScaleName,
      active: (
        <MddCheckbox
          lblText=""
          chkBoxProps={{
            id: `activeCheckbox_${name}`,
            checked: isActive,
            disabled: disabledOnGrid,
            className: "activeCheckbox",
            onClick: () => handle(parentId!)
          }}
        />
      ),
      delete: { id: parentId, deletable }
    })
  );
