import { IAuditable } from "../../model";

export abstract class Auditable implements IAuditable {
  private createdBy: string;
  private createdDt: Date;
  private modifiedBy: string;
  private modifiedDt: Date;
  private softDeleted: boolean;

  get CreatedBy() {
    return this.createdBy;
  }
  set CreatedBy(val) {
    this.createdBy = val;
  }
  get CreatedDt() {
    return this.createdDt;
  }
  set CreatedDt(val) {
    this.createdDt = val;
  }
  get ModifiedBy() {
    return this.modifiedBy;
  }
  set ModifiedBy(val) {
    this.modifiedBy = val;
  }
  get ModifiedDt() {
    return this.modifiedDt;
  }
  set ModifiedDt(val) {
    this.modifiedDt = val;
  }
  get SoftDeleted() {
    return this.softDeleted;
  }
  set SoftDeleted(val) {
    this.softDeleted = val;
  }
}
