import { connect } from "react-redux";
import { SagaService } from "front-end-lib/core";
import { eLookupSaga } from "../../../sagas/lookupSaga";
import { IApplicationState } from "../../../store";
import * as React from "react";

type Lookups =
  | "countries"
  | "demographics"
  | "indications"
  | "specialties"
  | "therapeuticAreas"
  | "qualificationTypes";
const lookupMap = new Map<Lookups, eLookupSaga>([
  ["demographics", eLookupSaga.FETCH_DEMOGRAPHICS],
  ["indications", eLookupSaga.FETCH_INDICATIONS],
  ["specialties", eLookupSaga.FETCH_SPECIALITIES],
  ["therapeuticAreas", eLookupSaga.FETCH_THERAPEUTIC_AREAS],
  ["countries", eLookupSaga.FETCH_COUNTRIES],
  ["qualificationTypes", eLookupSaga.FETCH_QUALIFICATION_TYPES]
]);

// BUG: If the dispatch call errors out, it continues to make each call.
export const withMddLookups = (lookups: Lookups[]) => (Component: any) => {
  const WrappedComponent = ({ loadingKeys, ...props }: any) => {
    React.useEffect(() => {
      for (const l of lookups) {
        const lk = lookupMap.get(l);
        if (lk) {
          const lkup = props[l];
          const loading = loadingKeys.some(
            (lKey: string) => lKey === `HOCLookup_${lk}`
          );
          if (!loading && lkup !== undefined && lkup.length === 0) {
            SagaService.dispatchSaga({
              type: lk,
              payload: { loadingKey: "HOCLookup" }
            });
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
  };

  return connect((state: IApplicationState) =>
    lookups.reduce(
      (prev, next) => {
        const lk = lookupMap.get(next);
        if (lk) {
          prev[next] = state.lookup[next];
        }
        return prev;
      },
      { loadingKeys: state.util.loadingKeys }
    )
  )(WrappedComponent);
};
