import { eFilter, Filter } from "front-end-lib/core";
import { classConstants } from "../../constants";

const THERAPEUTIC_AREA_ID = "therapeuticAreaId";
const DEMOGRAPHICS_ID = "demographicTypeId";
const INDICATIONS_ID = "indicationId";
const SELECT_FILTER_CLASS = "tbl--filter-select";

export const studyDataTableProps = {
  containerProps: {
    id: "tableWrapper",
    className: "tbl--container",
    style: { boxShadow: "0px 0px 0px 0px" }
  },
  tableProps: { id: "tableContainer" },
  tableHeadProps: { id: "tableHeader", rowProps: { id: "tableHeaderRow" } },
  tableBodyProps: { id: "tableBody", rowProps: { id: "tableBodyRow" } }
};

export const studyDatatableHeaders = (
  filterOnChange:
    | ((
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => void)
    | undefined,
  filterVals: Map<string, any>,
  therapeuticAreaData: any,
  indicationsData: any,
  demographicsData: any
) => [
  {
    key: "studyName",
    label: "Study Name",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "studyNameFilter",
        onChange: filterOnChange,
        name: "studyName"
      }
    }),
    sortProp: { sortPropName: "StudyName", desc: true },
    tableCellProps: { id: "studyNameHeader" },
    divProps: { id: "studyNameHeader_div" }
  },
  {
    key: "sponsor",
    label: "Sponsor",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "sponsorNameFilter",
        onChange: filterOnChange,
        name: "sponsorName"
      }
    }),
    tableCellProps: {
      id: "sponsorNameHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "sponsorNameHeader_div" }
  },
  {
    key: "internalStudyName",
    label: "Internal Study Name",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "internalStudyNameFilter",
        onChange: filterOnChange,
        name: "internalStudyName"
      }
    }),
    tableCellProps: {
      id: "internalStudyNameHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "internalStudyNameHeader_div" }
  },
  {
    key: "protocol",
    label: "Protocol",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "protocolFilter",
        onChange: filterOnChange,
        name: "protocol"
      }
    }),
    tableCellProps: {
      id: "protocolNameHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "protocolNameHeader_div" }
  },
  {
    key: "therapeuticArea",
    label: "Therapeutic Area",
    filter: new Filter(eFilter.Select, {
      textProps: {
        id: THERAPEUTIC_AREA_ID,
        className: SELECT_FILTER_CLASS,
        onChange: filterOnChange,
        disabled: !therapeuticAreaData || therapeuticAreaData.length === 0,
        InputLabelProps: { shrink: filterVals.has(THERAPEUTIC_AREA_ID) },
        value: filterVals.get(THERAPEUTIC_AREA_ID) || ""
      },
      selectMenuProps: { MenuProps: { className: "bracket-Select" } },
      menuProps: {},
      selectData: therapeuticAreaData,
      defaultSelect: true
    }),
    tableCellProps: {
      id: "therapeuticAreaHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "therapeuticAreaHeader_div" }
  },
  {
    key: "indication",
    label: "Indication",
    filter: new Filter(eFilter.Select, {
      textProps: {
        id: INDICATIONS_ID,
        className: SELECT_FILTER_CLASS,
        onChange: filterOnChange,
        disabled: !indicationsData || indicationsData.length === 0,
        InputLabelProps: { shrink: filterVals.has(INDICATIONS_ID) },
        value: filterVals.get(INDICATIONS_ID) || ""
      },
      menuProps: {},
      selectMenuProps: { MenuProps: { className: "bracket-Select" } },
      selectData: indicationsData,
      defaultSelect: true
    }),
    tableCellProps: {
      id: "indicationHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "indicationHeader_div" }
  },
  {
    key: "demographic",
    label: "Demographic",
    filter: new Filter(eFilter.Select, {
      textProps: {
        id: DEMOGRAPHICS_ID,
        className: SELECT_FILTER_CLASS,
        InputLabelProps: { shrink: filterVals.has(DEMOGRAPHICS_ID) },
        disabled: !demographicsData || demographicsData.length === 0,
        onChange: filterOnChange,
        value: filterVals.get(DEMOGRAPHICS_ID) || ""
      },
      selectMenuProps: { MenuProps: { className: "bracket-Select" } },
      menuProps: {},
      selectData: demographicsData,
      defaultSelect: true
    }),
    tableCellProps: {
      id: "demographicHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "demographicHeader_div" }
  }
];
