import * as React from "react";
import { IQuestionGroup } from "../../../model/interfaces/IPersonnelCPResExperience";
import { QnA, getAnswersWithSeparator, getQuestionsAndAnswers } from "./Utils";

interface IProps {
  data: IQuestionGroup;
  displayQuestions: string[];
}

export const EducationContent = ({ data, displayQuestions }: IProps) => {
  displayQuestions = data.questions.filter(q => displayQuestions.includes(q));

  const qnA = getQuestionsAndAnswers(data);
  const answerList = qnA
    .map(arr => arr.filter(item => item.answer !== null))
    .reduce((acc, curr) => acc.concat(curr), []);

  const getAnswerList = (data: QnA[]) =>
    data.length > 0
      ? data.map(item => (
          <div
            key={`${item.question}_${item.answer}`}
            data-testid={`${item.question}_${item.answer}_testid`}
          >
            {displayQuestions.includes(item.question) && (
              <strong>{`${item.question}: `}</strong>
            )}
            {item.answer}
          </div>
        ))
      : displayQuestions.map((question, index) => (
          <div key={`${question}_${index}`}>
            <div className="mdd-experience--panel--content--text--bold">{`${question}:-`}</div>
          </div>
        ));

  return (
    <>
      <div
        className="mdd-experience--panel--content--text"
        id="divItemText"
        data-testid="divItemText"
      >
        {displayQuestions.length > 0
          ? getAnswerList(answerList)
          : getAnswersWithSeparator(data.answers)}
      </div>
    </>
  );
};
