import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { assign, map, pick, trim } from "lodash";
import { Button, Paper } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import History from "@material-ui/icons/History";
import {
  BktDataTable,
  Filter,
  eFilter,
  IBktDataTableData,
  IAction,
  SagaService,
  BktToolTip,
  CircularProgress
} from "front-end-lib/core";
import { eSponsorSaga } from "../../../sagas";
import { eSponsorActionTypes } from "../../../store";
import { withMddBase } from "../../../components";
import { Sponsor, ISponsor, ISponsorHistoryDTO } from "../../../model";
import { history, store } from "../../../utils";

interface IBktDataTableSponsor extends IBktDataTableData {
  SponsorName: string;
  SponsorHistory: JSX.Element;
}

interface IStateProps {
  sponsors?: ISponsor[];
  sponsorHistory?: ISponsorHistoryDTO[];
}

class BktSponsorShowComponent extends React.Component<any, IStateProps> {
  private filterVals = new Map();

  constructor(props: any) {
    super(props);

    this.state = {
      sponsors: undefined
    };
    this.handleChangeFilter = this.handleChangeFilter.bind(this);

    const setSponsorsToState = (action: IAction) => {
      if (action.type === eSponsorSaga.FETCH_SPONSORS_SUCCESS) {
        // map data into `Sponsor` type expected by `render()`
        const sponsors = map(action.payload, s => assign(new Sponsor(), s));
        this.setState({ sponsors });
      }
    };

    const setSponsorHistory = (action: IAction) => {
      if (action.type === eSponsorSaga.FETCH_SPONSOR_HISTORY_SUCCESS) {
        this.setState({ sponsorHistory: action.payload });
      }
    };

    SagaService.mapSagaToState(
      this,
      new Map([
        [
          eSponsorSaga.FETCH_SPONSORS.toString(),
          { callback: setSponsorsToState }
        ],
        [
          eSponsorSaga.FETCH_SPONSOR_HISTORY.toString(),
          { callback: setSponsorHistory }
        ]
      ])
    );
  }

  public async handleChangeFilter(event: any) {
    const { name } = event.target;
    const value = event.target.value ? trim(event.target.value) : "";
    const searchSponsor = {};

    if (this.filterVals.has(name) && this.filterVals.get(name) === value) {
      return;
    }

    value ? this.filterVals.set(name, value) : this.filterVals.delete(name);

    if (this.filterVals.size < 1) {
      this.setState({ sponsors: undefined });
      return;
    }

    this.filterVals.forEach((value, key) => {
      Object.assign(searchSponsor, { [key]: value });
    });

    SagaService.dispatchSaga({
      type: eSponsorSaga.FETCH_SPONSORS,
      payload: searchSponsor
    });
  }

  public render() {
    const sponsorRows: IBktDataTableSponsor[] | undefined = this.state.sponsors
      ? map(this.state.sponsors || [], s => {
          // render sponsor history cell
          const sponorHistoryContainerProps = s.HasHistory
            ? {
                style: { backgroundColor: "transparent" },
                onMouseEnter: (e: any) => this.getSponsorHistory(s),
                onMouseLeave: (e: any) => {
                  this.setState({ sponsorHistory: undefined });
                }
              }
            : {};

          const SponsorHistory = (
            <BktToolTip
              containerProps={sponorHistoryContainerProps}
              tooltip={this.renderSponsorHistory(s)}
              position="bottom-end"
            >
              <History
                id="historyIcon"
                color={s.HasHistory ? "action" : "disabled"}
              />
              <label>{s.HasHistory}</label>
            </BktToolTip>
          );

          return {
            key: s.SponsorId,
            SponsorName: s.SponsorName,
            SponsorHistory
          };
        })
      : undefined;

    return (
      <div id="container" className="mdd-grid">
        <Paper id="headerContainer">
          <Typography id="headerTitle"> All Sponsors</Typography>
          <Button
            id="addSponsorButton"
            data-testid="btnAddSponsor"
            variant="contained"
            size={"small"}
            onClick={() => {
              store.dispatch({
                type: eSponsorActionTypes.SET_SPONSOR,
                payload: new Sponsor()
              });
              history.push("/sponsor/new");
            }}
          >
            <AddCircle id="addCircleIcon" />
            Add New Sponsor
          </Button>
        </Paper>
        <Paper id="bodyContainer">
          <div>
            <BktDataTable
              data={sponsorRows}
              defaultSortProp="SponsorName"
              loading={this.props.loadingKeys.some(
                (lk: string) =>
                  lk === `undefined_${eSponsorSaga.FETCH_SPONSORS}`
              )}
              columnProps={[
                {
                  label: "Sponsor",
                  filter: new Filter(eFilter.Text, {
                    textProps: {
                      className: "tbl--filter-text",
                      id: "sponsorNameFilter",
                      onChange: this.handleChangeFilter,
                      name: "sponsorName"
                    }
                  }),
                  sortProp: { sortPropName: "SponsorName", desc: true },
                  tableCellProps: { id: "sponsorNameHeader" },
                  divProps: { id: "sponsorNameHeader_div" },
                  formatProp: {
                    formatPropName: "SponsorName",
                    format: (s: any) => <span title={s}>{s}</span>
                  }
                },
                {
                  label: "Sponsor Name Change History",
                  tableCellProps: { id: "sponsorNameHistoryHeader" },
                  divProps: { id: "sponsorNameHistoryHeader_div" }
                }
              ]}
              rowActions={[
                {
                  isPrimary: true,
                  action: (
                    data: IBktDataTableData,
                    event: React.MouseEvent<HTMLDivElement>
                  ) => history.push("/sponsor/" + data.key)
                }
              ]}
              containerProps={{
                id: "tableWrapper",
                className: "tbl--container",
                style: { boxShadow: "0px 0px 0px 0px" }
              }}
              tableProps={{ id: "tableContainer" }}
              tableHeadProps={{
                id: "tableHeader",
                rowProps: {
                  id: "tableHeaderRow",
                  className: "mdd-sponsor-tbl--header-row tbl--header-row"
                }
              }}
              tableBodyProps={{
                id: "tableBody",
                rowProps: {
                  id: "tableBodyRow",
                  className: "mdd-sponsor-tbl--body-row tbl--body-row"
                },
                cellProps: { className: "mdd-sponsor-tbl--body-cell" }
              }}
            />
          </div>
        </Paper>
      </div>
    );
  }

  private renderSponsorHistory(sponsor: ISponsor): JSX.Element | undefined {
    const { sponsorHistory } = this.state;

    if (sponsor.HasHistory) {
      if (!sponsorHistory) {
        return (
          <span className="mdd-sponsor-tbl-history">
            <CircularProgress className="mdd-sponsor-tbl-history--progress" />
          </span>
        );
      }

      const data = sponsorHistory.map((sh: ISponsorHistoryDTO, i: number) => {
        return Object.assign(
          pick(sh, [
            "sponsorName",
            "action",
            "updatedDt",
            "updatedBy"
          ]) as ISponsorHistoryDTO,
          { key: `sh.sponsorId_${i}` }
        );
      });

      return (
        <BktDataTable
          data={data}
          columnProps={[
            {
              label: "Sponsor Name",
              tableCellProps: { id: "sponsorHistoryNameHeader" },
              divProps: { id: "sponsorHistoryNameHeader_div" }
            },
            {
              label: "Action",
              tableCellProps: { id: "sponsorHistoryActionHeader" },
              divProps: { id: "sponsorHistoryActionHeader_div" }
            },
            {
              label: "Date",
              tableCellProps: { id: "sponsorHistoryDateHeader" },
              divProps: { id: "sponsorHistoryDateHeader_div" }
            },
            {
              label: "User",
              tableCellProps: { id: "sponsorHistoryUserHeader" },
              divProps: { id: "sponsorHistoryUserHeader_div" }
            }
          ]}
          containerProps={{ id: "tableWrapper" }}
          tableProps={{
            id: "tableContainer",
            className: "mdd-sponsor-tbl-history"
          }}
          tableHeadProps={{
            id: "tableHeader",
            rowProps: {
              id: "tableHeaderRow",
              className: "mdd-sponsor-tbl-history--header-row"
            }
          }}
          tableBodyProps={{
            id: "tableBody",
            rowProps: {
              id: "tableBodyRow",
              className: "mdd-sponsor-tbl-history--body-row"
            }
          }}
        />
      );
    }

    return undefined;
  }

  private getSponsorHistory(sponsor: ISponsor) {
    SagaService.dispatchSaga({
      type: eSponsorSaga.FETCH_SPONSOR_HISTORY,
      payload: sponsor.SponsorId
    });
  }
}
export const BktShowSponsors = withMddBase(BktSponsorShowComponent);
