import { TextField } from "@material-ui/core";
import { MddCheckbox } from "../../../components";
import React, { useMemo, useState } from "react";
import { IEditItem } from "../../../store/resConfig/types";
import { FixedSizeList as List } from "react-window";

interface IProps {
  items: IEditItem[];
  handleChange: (item: IEditItem) => void;
}

export const IndicationDialogContent = ({ items, handleChange }: IProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const filteredData = useMemo<IEditItem[]>(
    () =>
      searchQuery !== ""
        ? items.reduce((prev: IEditItem[], next) => {
            if (
              (!next.childId &&
                next
                  .name!.toLocaleLowerCase()
                  .includes(searchQuery.toLocaleLowerCase())) ||
              prev.find(m => m.parentId === next.parentId)
            ) {
              prev.push(next);
            }

            return prev;
          }, [])
        : items,
    [searchQuery, items]
  );

  const Row = ({ index, style }: any) => {
    const item = filteredData[index];
    return (
      <div style={style}>
        <div
          style={{ paddingLeft: item.nested ? "16px" : "0px" }}
          title={
            item.disabledOnModal && !item.checked
              ? `You can't add ${item.name} demographic because the Indication isn't active`
              : ""
          }
        >
          <MddCheckbox
            lblText={item.name!}
            chkBoxProps={{
              id: `activeCheckbox_${item.name}`,
              checked: item.checked,
              className: "activeCheckbox",
              disabled: item.disabledOnModal,
              onChange: () => handleChange(item)
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="mdd-res-draft--dialog-search">
        <TextField
          data-testid="search-field"
          label="Search"
          fullWidth
          variant="outlined"
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
      <List
        width={"auto"}
        height={400}
        itemSize={35}
        itemCount={filteredData.length}
      >
        {Row}
      </List>
    </div>
  );
};
