import { Sponsor } from "../../model/classes/sponsor";
import { ISponsor } from "../../model";

const initialState: ISponsorState = {
  currentSponsor: new Sponsor()
};

export interface ISponsorState {
  currentSponsor: ISponsor;
}

export enum eSponsorActionTypes {
  SET_SPONSOR = "@@sponsor/SET_SPONSOR"
}

export const setSponsor = (study: ISponsor) => ({
  type: eSponsorActionTypes.SET_SPONSOR,
  payload: study
});

export const sponsorReducer: any = (
  state: ISponsorState = initialState,
  action: any
) => {
  switch (action.type) {
    case eSponsorActionTypes.SET_SPONSOR: {
      return { ...state, ...{ currentSponsor: action.payload } };
    }
    default: {
      return state;
    }
  }
};
