import * as React from "react";
import { SagaService, IAction } from "front-end-lib/core";
import { eStudySaga } from "../../../sagas";
import { MddCommentDrawer, MddCommentIcon } from "../../../components";
import { StudySitePersonCommentDTO, IComment } from "../../../model";

interface IMddStudySitePersonCommentsProps {
  studyId: string;
  siteId: string;
  personnelId: string;
}

export const MddStudySitePersonComments = (
  props: IMddStudySitePersonCommentsProps
) => {
  const [comments, setComments] = React.useState<IComment[]>([]);
  const [commentsOpen, setCommentsOpen] = React.useState(false);

  const commentsRef = React.useRef(comments);
  commentsRef.current = comments;

  React.useEffect(() => {
    const removeSavePersonCommentSub = SagaService.subscribeToSaga(
      eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT_SUCCESS) {
          // Add the returned comment from the save to the bottom of the list.
          // Also add the isEditable flag and set it to true since the current
          // user just added this comment and should be able to edit it.
          const newComments = [...commentsRef.current];
          newComments.push(
            Object.assign({}, action.payload, { isEditable: true })
          );
          setComments(newComments);
        }
      }
    );

    const removeUpdatePersonCommentSub = SagaService.subscribeToSaga(
      eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT,
      (action: IAction) => {
        if (
          action.type === eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT_SUCCESS
        ) {
          // Update the comment in place so we don't need to reload (which would scroll them to the bottom)
          const currComment = commentsRef.current.find(
            c =>
              c.studySitePersonnelCommentId ===
              action.payload.studySitePersonnelCommentId
          );
          if (currComment) {
            Object.assign(currComment, {
              commentText: action.payload.commentText,
              commentUpdatedDate: action.payload.commentUpdatedDate
            });
          }
        }
      }
    );

    return () => {
      removeSavePersonCommentSub();
      removeUpdatePersonCommentSub();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // comments opened, if we haven't loaded the comments do it now
    if (!commentsOpen) {
      return;
    }

    const { studyId, siteId, personnelId } = props;

    const removeFetchComments = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS,
      commentsFetchCallback
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS.toString(),
      payload: {
        studyId,
        siteId,
        personnelId
      }
    });

    return () => {
      removeFetchComments();
    };
    // eslint-disable-next-line
  }, [commentsOpen]);

  const commentsFetchCallback = (action: IAction) => {
    const { type, payload } = action;
    if (type === eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS_SUCCESS) {
      setComments(payload);
    }
  };

  const toggleComments = (val: boolean) => {
    setCommentsOpen(val);
  };

  const handleCommentSave = (commentText: string) => {
    const { studyId, personnelId } = props;
    const payload = new StudySitePersonCommentDTO(
      studyId,
      personnelId,
      encodeURI(commentText.trim())
    );
    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT,
      payload
    });
  };

  const handleEditSave = (commentText: string, commentId: string) => {
    const { studyId, personnelId } = props;
    const payload = new StudySitePersonCommentDTO(
      studyId,
      personnelId,
      encodeURI(commentText.trim()),
      commentId
    );
    SagaService.dispatchSaga({
      type: eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT,
      payload
    });
  };

  return (
    <React.Fragment>
      <MddCommentIcon onClick={() => toggleComments(true)} />
      <MddCommentDrawer
        open={commentsOpen}
        onClose={() => toggleComments(false)}
        onSubmit={handleCommentSave}
        onEditSave={handleEditSave}
        comments={comments}
      />
    </React.Fragment>
  );
};
