import * as React from "react";
import { MddDialog, MddDialogButtons } from "../../../components";
import { MddResConfigDialogContent } from "./MddResConfigDialogContent";

interface IMddResConfigDialogProps {
  open: boolean;
  onClose: () => void;
}

export const MddResConfigDialog = (props: IMddResConfigDialogProps) => {
  const { open, onClose } = props;

  return (
    <MddDialog
      showCloseButton={true}
      onCloseClick={onClose}
      dialogProps={{
        id: "divPublishingModal",
        open,
        maxWidth: "md",
        fullWidth: true
      }}
      dialogActionProps={{ id: "divPublishingModalAction" }}
      dialogContentProps={{
        id: "divPublishingModalContent",
        dividers: true,
        className: "mdd-dialog--content--medium"
      }}
      dialogTitleProps={{
        id: "divPublishingModalTitle",
        title: "Publishing"
      }}
      dialogContent={<MddResConfigDialogContent />}
      dialogActions={
        <MddDialogButtons
          saveButtonText="Close"
          saveButtonProps={{
            onClick: onClose
          }}
          saveButtonId="=btnDialogClose"
          closeButtonProps={{
            className: "notVisible"
          }}
        />
      }
    />
  );
};
