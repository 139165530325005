import * as React from "react";
import { NO_DATA_AVAILABLE_TEXT } from "../../../constants";

interface IMddNoDataDivProps {
  loading?: boolean;
  noDataText?: string;
}

export const MddNoDataDiv = (props: IMddNoDataDivProps) => {
  const { loading = false, noDataText = NO_DATA_AVAILABLE_TEXT } = props;

  return (
    <div className="mdd-no-data-div--container">
      <div
        className="mdd-no-data-div--text"
        id="divNoData"
        data-testid="divNoData"
      >
        {loading ? "Loading..." : noDataText}
      </div>
    </div>
  );
};
