import { ISiteComment } from "../../model";
/** Class used to define the sitecomment object and implements the ISiteComment interface */
export class SiteComment implements ISiteComment {
  private id: string;
  private siteId: string;
  private siteName: string;
  private commentText: string;
  private updatedBy: string;
  private updatedDt: string;

  /** Gets or sets the Id associated to a Site Comment */
  get Id() {
    return this.id;
  }
  set Id(val) {
    this.id = val;
  }

  /** Gets or sets the SiteId associated to a Site Comment */
  get SiteId() {
    return this.siteId;
  }
  set SiteId(val) {
    this.siteId = val;
  }

  /** Gets or sets the Site Name associated to a Site Comment */
  get SiteName() {
    return this.siteName;
  }
  set SiteName(val) {
    this.siteName = val;
  }

  /** Gets or sets the Comment Text associated to a Site Comment */
  get CommentText() {
    return this.commentText;
  }
  set CommentText(val) {
    this.commentText = val;
  }

  /** Gets or sets the Updated By associated to a Site Comment */
  get UpdatedBy() {
    return this.updatedBy;
  }
  set UpdatedBy(val) {
    this.updatedBy = val;
  }

  /** Gets or sets the Updated Date associated to a Site Comment */

  get UpdatedDt() {
    return this.updatedDt;
  }
  set UpdatedDt(val) {
    this.updatedDt = val;
  }
}
