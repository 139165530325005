import * as React from "react";
import { SagaService, IAction } from "front-end-lib/core";
import { MddForm, BktBreadcrumb } from "../../../components";
import { IPerson, Person, IPersonnelContactInfo } from "../../../model";
import { ePersonnelSaga } from "../../../sagas";
import { MddPersonDetails } from "../../common";

interface IMddEditPersonnelProps {
  match: {
    params: {
      id: string;
    };
  };
}

export const MddEditPersonnel = (props: IMddEditPersonnelProps) => {
  const personFetchCallback = (action: IAction) => {
    const { type, payload } = action;
    if (type === ePersonnelSaga.FETCH_PERSON_SUCCESS) {
      setPerson(payload);
    }
  };
  React.useEffect(() => {
    const { id } = props.match.params;

    const removeFetchPerson = SagaService.subscribeToSaga(
      ePersonnelSaga.FETCH_PERSON,
      personFetchCallback
    );

    SagaService.dispatchSaga({
      type: ePersonnelSaga.FETCH_PERSON.toString(),
      payload: id
    });
    return () => removeFetchPerson();
    // eslint-disable-next-line
  }, []);

  const [person, setPerson] = React.useState<IPerson>(new Person());
  const personRef = React.useRef(person);
  personRef.current = person;

  const updateContactInfo = (
    contactInfo: IPersonnelContactInfo | undefined
  ) => {
    setPerson(
      Object.assign(new Person(), personRef.current, {
        PersonnelContactInfo: contactInfo
      })
    );
  };

  return (
    <React.Fragment>
      <BktBreadcrumb
        rootText="All Personnel"
        rootUrl="/personnel"
        currentLocation={
          person && person.FirstName
            ? `${person.FirstName} ${person.LastName}`
            : ""
        }
      />
      <MddForm
        containerClass="mdd-personnel-form--container"
        keys={[
          `undefined_${ePersonnelSaga.FETCH_PERSON}`,
          `undefined_${ePersonnelSaga.SAVE_PERSON}`
        ]}
      >
        <div className="mdd-form--body">
          <div className="mdd-personnel-div--title">Personnel Details</div>
          <MddPersonDetails person={person} onCommit={updateContactInfo} />
        </div>
      </MddForm>
    </React.Fragment>
  );
};
