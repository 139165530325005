import { call, put, takeLatest } from "redux-saga/effects";
import { SagaService, IAction } from "front-end-lib/core";
import { eUtilSaga } from "../sagas";
import { PersonnelService } from "../services";
import { initSaga } from "../utils/sagaHelper";
import { store, overrideSaga, callApi } from "../utils";
import { openErrorSnackBar, openSuccessSnackBar } from "../store";
import { mockSearchUserData } from "../config";
import { eApiNames, eHTTPMethods } from "../constants";
import {
  IPersonnelCpResExperience,
  ICpResAssignmentStatus,
  IPersonnelResScaleExperience
} from "../model/interfaces";

export enum ePersonnelSaga {
  FETCH_PERSONNEL = "@@personnel/FETCH_PERSONNEL",
  FETCH_PERSONNEL_SUCCESS = "@@personnel/FETCH_PERSONNEL_SUCCESS",
  WATCH_PERSONNEL = "@@personnel/WATCH_PERSONNEL",
  FETCH_MANAGED_PERSONNEL = "@@personnel/FETCH_MANAGED_PERSONNEL",
  FETCH_MANAGED_PERSONNEL_SUCCESS = "@@personnel/FETCH_MANAGED_PERSONNEL_SUCCESS",
  WATCH_MANAGED_PERSONNEL = "@@personnel/WATCH_MANAGED_PERSONNEL",
  FETCH_PERSON = "@@personnel/FETCH_PERSON",
  FETCH_PERSON_SUCCESS = "@@personnel/FETCH_PERSON_SUCCESS",
  WATCH_PERSON = "@@personnel/WATCH_PERSON",
  SAVE_PERSON = "@@personnel/SAVE_PERSON",
  WATCH_SAVE_PERSON = "@@personnel/WATCH_SAVE_PERSON",
  SAVE_PERSON_SUCCESS = "@@personnel/SAVE_PERSON_SUCCESS",

  GET_PERSONNEL_RES_STATUS = "@@personnel/GET_PERSONNEL_RES_STATUS",
  GET_PERSONNEL_RES_STATUS_SUCCESS = "@@personnel/GET_PERSONNEL_RES_STATUS_SUCCESS",
  GET_PERSONNEL_RES_STATUS_WATCH = "@@personnel/GET_PERSONNEL_RES_STATUS_WATCH",

  SAVE_PERSONNEL_RES_ASSIGNMENT = "@@personnel/SAVE_PERSONNEL_RES_ASSIGNMENT",
  SAVE_PERSONNEL_RES_ASSIGNMENT_SUCCESS = "@@personnel/SAVE_PERSONNEL_RES_ASSIGNMENT_SUCCESS",
  SAVE_PERSONNEL_RES_ASSIGNMENT_WATCH = "@@personnel/SAVE_PERSONNEL_RES_ASSIGNMENT_WATCH",

  SAVE_PERSONNEL_RES_UNASSIGNMENT = "@@personnel/SAVE_PERSONNEL_RES_UNASSIGNMENT",
  SAVE_PERSONNEL_RES_UNASSIGNMENT_SUCCESS = "@@personnel/SAVE_PERSONNEL_RES_UNASSIGNMENT_SUCCESS",
  SAVE_PERSONNEL_RES_UNASSIGNMENT_WATCH = "@@personnel/SAVE_PERSONNEL_RES_UNASSIGNMENT_WATCH",

  GET_PERSONNEL_EXPERIENCE = "@@personnel/GET_PERSONNEL_EXPERIENCE",
  GET_PERSONNEL_EXPERIENCE_SUCCESS = "@@personnel/GET_PERSONNEL_EXPERIENCE_SUCCESS",
  GET_PERSONNEL_EXPERIENCE_WATCH = "@@personnel/GET_PERSONNEL_EXPERIENCE_WATCH",

  GET_PERSONNEL_SCALE_EXPERIENCE = "@@personnel/GET_PERSONNEL_SCALE_EXPERIENCE",
  GET_PERSONNEL_SCALE_EXPERIENCE_SUCCESS = "@@personnel/GET_PERSONNEL_SCALE_EXPERIENCE_SUCCESS",
  GET_PERSONNEL_SCALE_EXPERIENCE_WATCH = "@@personnel/GET_PERSONNEL_SCALE_EXPERIENCE_WATCH"
}

export function* fetchPersonnelSaga(action: IAction) {
  try {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: true });
    const personnel = yield call(PersonnelService.getPersonnel, action.payload);
    yield put({
      type: ePersonnelSaga.FETCH_PERSONNEL_SUCCESS,
      payload: personnel
    });
  } catch (e) {
    console.log(e);
  } finally {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: false });
  }
}

export function* watchPersonnelSagas() {
  yield takeLatest(
    ePersonnelSaga.FETCH_PERSONNEL.toString(),
    fetchPersonnelSaga
  );
}

initSaga(
  function* fetchManagedPersonnelSaga(action: IAction) {
    const personnel = yield call(
      PersonnelService.getManagedPersonnel,
      action.payload
    );
    yield put({
      type: ePersonnelSaga.FETCH_MANAGED_PERSONNEL_SUCCESS,
      payload: personnel
    });
  },
  ePersonnelSaga.FETCH_MANAGED_PERSONNEL,
  ePersonnelSaga.WATCH_MANAGED_PERSONNEL
);

initSaga(
  function* fetchPersonSaga(action: IAction) {
    const personnel = yield call(PersonnelService.getPerson, action.payload);
    yield put({
      type: ePersonnelSaga.FETCH_PERSON_SUCCESS,
      payload: personnel
    });
  },
  ePersonnelSaga.FETCH_PERSON,
  ePersonnelSaga.WATCH_PERSON
);

initSaga(
  function* savePersonSaga(action: IAction) {
    const person = yield call(PersonnelService.savePerson, action.payload);
    yield put({ type: ePersonnelSaga.SAVE_PERSON_SUCCESS, payload: person });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  ePersonnelSaga.SAVE_PERSON,
  ePersonnelSaga.WATCH_SAVE_PERSON
);

export const overridePersonnelSagas = () => {
  overrideSaga(
    function* savePersonSaga(action: IAction) {
      if (action.payload) {
        yield put({
          type: ePersonnelSaga.SAVE_PERSON_SUCCESS,
          payload: {}
        });
      }
    },
    ePersonnelSaga.SAVE_PERSON_SUCCESS,
    ePersonnelSaga.WATCH_SAVE_PERSON
  );

  overrideSaga(
    function* fetchManagedPersonnelSaga(action: IAction) {
      yield put({
        type: ePersonnelSaga.FETCH_MANAGED_PERSONNEL_SUCCESS,
        payload: mockSearchUserData
      });
    },
    ePersonnelSaga.FETCH_MANAGED_PERSONNEL,
    ePersonnelSaga.WATCH_MANAGED_PERSONNEL
  );
};

initSaga(
  function* loadPersonnelExperience({
    payload: { studyId, personnelId }
  }: IAction) {
    try {
      const result: IPersonnelCpResExperience = yield call(() =>
        callApi(
          eHTTPMethods.GET,
          `/studies/${studyId}/personnel/${personnelId}/experience`,
          undefined,
          undefined,
          eApiNames.STUDY_API
        )
      );

      yield put({
        type: ePersonnelSaga.GET_PERSONNEL_EXPERIENCE_SUCCESS,
        payload: result
      });
    } catch (e) {
      yield store.dispatch(openErrorSnackBar(e.message));
    }
  },
  ePersonnelSaga.GET_PERSONNEL_EXPERIENCE,
  ePersonnelSaga.GET_PERSONNEL_EXPERIENCE_WATCH
);

initSaga(
  function* loadPersonnelResStatus({
    payload: { studyId, personnelId }
  }: IAction) {
    try {
      const result: ICpResAssignmentStatus = yield call(() =>
        callApi(
          eHTTPMethods.GET,
          `/studies/${studyId}/personnel/${personnelId}/res-assignment`,
          undefined,
          undefined,
          eApiNames.STUDY_API
        )
      );

      yield put({
        type: ePersonnelSaga.GET_PERSONNEL_RES_STATUS_SUCCESS,
        payload: result
      });
    } catch (e) {
      yield store.dispatch(openErrorSnackBar(e.message));
    }
  },
  ePersonnelSaga.GET_PERSONNEL_RES_STATUS,
  ePersonnelSaga.GET_PERSONNEL_RES_STATUS_WATCH
);

initSaga(
  function* savePersonResAssignment({
    payload: { studyId, personnelId }
  }: IAction) {
    yield call(() =>
      callApi(
        eHTTPMethods.POST,
        `/studies/${studyId}/personnel/${personnelId}/res-assignment`,
        undefined,
        "Assignment failed",
        eApiNames.STUDY_API
      )
    );
    yield put({
      type: ePersonnelSaga.SAVE_PERSONNEL_RES_ASSIGNMENT_SUCCESS,
      payload: null
    });
    yield store.dispatch(openSuccessSnackBar("Assignment successful"));
  },
  ePersonnelSaga.SAVE_PERSONNEL_RES_ASSIGNMENT,
  ePersonnelSaga.SAVE_PERSONNEL_RES_ASSIGNMENT_WATCH
);

initSaga(
  function* loadPersonnelResScaleExperience({
    payload: { studyId, personnelId }
  }: IAction) {
    try {
      const result: IPersonnelResScaleExperience = yield call(() =>
        callApi(
          eHTTPMethods.GET,
          `/studies/${studyId}/personnel/${personnelId}/scale-experience-statuses`,
          undefined,
          undefined,
          eApiNames.STUDY_API
        )
      );

      yield put({
        type: ePersonnelSaga.GET_PERSONNEL_SCALE_EXPERIENCE_SUCCESS,
        payload: result
      });
    } catch (e) {
      yield store.dispatch(openErrorSnackBar(e.message));
    }
  },
  ePersonnelSaga.GET_PERSONNEL_SCALE_EXPERIENCE,
  ePersonnelSaga.GET_PERSONNEL_SCALE_EXPERIENCE_WATCH
);

initSaga(
  function* savePersonResUnassignment({
    payload: { studyId, personnelId }
  }: IAction) {
    yield call(() =>
      callApi(
        eHTTPMethods.DELETE,
        `/studies/${studyId}/personnel/${personnelId}/res-assignment`,
        undefined,
        "Unassignment failed",
        eApiNames.STUDY_API
      )
    );
    yield put({
      type: ePersonnelSaga.SAVE_PERSONNEL_RES_UNASSIGNMENT_SUCCESS,
      payload: null
    });
    yield store.dispatch(openSuccessSnackBar("Unassignment successful"));
  },
  ePersonnelSaga.SAVE_PERSONNEL_RES_UNASSIGNMENT,
  ePersonnelSaga.SAVE_PERSONNEL_RES_UNASSIGNMENT_WATCH
);
