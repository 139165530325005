import { MenuListItem, IMenuList } from "../../../model";

import {
  mdiAccountCardDetails,
  mdiHospitalBuilding,
  mdiDatabase,
  mdiFormatListChecks,
  mdiScaleBalance,
  mdiPlaylistCheck,
  mdiBallotOutline,
  mdiSettings
  // mdiClipboardTextOutline
} from "@mdi/js";

export enum studyMenuItemCategories {
  General = "General",
  Sites = "Sites",
  ClinicalData = "ClinicalData",
  Protocols = "Protocols",
  Scales = "Scales",
  Qualifications = "Qualifications",
  AcceptableScores = "AcceptableScores",
  RESConfig = "RESConfig"
  // AssignLearningPlans = "Assign Learning Plans"
}

export class StudyMenuContainer implements IMenuList {
  // This will be the interface structure used for the component to make it generic
  public root: string;
  public data: any;
  public selectedSponsor: string;
  public enabled: boolean;
  public menuItems: MenuListItem[];
  public rootUrl: string;
  public constructor() {
    this.root = "All Studies";
    this.rootUrl = "/";
    this.selectedSponsor = this.enabled ? this.data.sponsorName : "Add New";
    this.menuItems = this.getMenu();
  }
  public getMenu() {
    const menuList = [
      new MenuListItem(
        "General Info",
        mdiAccountCardDetails,
        "/sponsor/" + this.enabled ? ":id" : "new",
        studyMenuItemCategories.General
      ),
      new MenuListItem(
        "Clinical Data",
        mdiDatabase,
        "/study/:id/clinicaldata",
        studyMenuItemCategories.ClinicalData
      ),
      new MenuListItem(
        "Protocols",
        mdiFormatListChecks,
        "/study/:id/protocol",
        studyMenuItemCategories.Protocols
      ),
      new MenuListItem(
        "Sites",
        mdiHospitalBuilding,
        "/study/:id/sites",
        studyMenuItemCategories.Sites
      ),
      new MenuListItem(
        "Scales",
        mdiScaleBalance,
        "/study/:id/scales",
        studyMenuItemCategories.Scales
      ),
      new MenuListItem(
        "Qualifications",
        mdiPlaylistCheck,
        "/study/:id/qualifications",
        studyMenuItemCategories.Qualifications
      ),
      new MenuListItem(
        "Acceptable Scores",
        mdiBallotOutline,
        "/study/:id/scores",
        studyMenuItemCategories.AcceptableScores
      ),
      new MenuListItem(
        "RES Configuration",
        mdiSettings,
        "/study/:id/resconfig",
        studyMenuItemCategories.RESConfig
      )
      // new MenuListItem(
      //   "Assign Learning Plan",
      //   mdiClipboardTextOutline,
      //   "/study/:id/assignLPs",
      //   studyMenuItemCategories.AssignLearningPlans
      // )
    ];

    return menuList;
  }
}
