import * as React from "react";
import { withMddStudy } from "../../../higher-order";
import { isLoading } from "../../../../utils";
import { eStudySaga } from "../../../../sagas";
import { SagaService, IAction } from "front-end-lib/core";
import {
  IMddStudyProps,
  IMatch,
  ICourseAcceptableScore,
  ICourseScoresDetails
} from "../../../../model";
import { MddEditCourseScoresRender, MddProgress } from "../../..";

interface IMddEditCourseCountryScoresProps extends IMddStudyProps {
  match: IMatch<{
    id: string;
    sponsorId: string;
    siteId: string;
    courseId: string | null;
    studyComponentId: string | null;
    countryId: string;
  }>;
  location: {
    state?: {
      courseName: string;
      countryName: string;
      countryAcceptableScoreId?: string;
    };
  };
}

const _MddEditCourseCountryScores = (
  props: IMddEditCourseCountryScoresProps
) => {
  const { study, location } = props;
  const { id, courseId, studyComponentId, countryId } = props.match.params;

  const [courseName, setCourseName] = React.useState("");
  const [countryName, setCountryName] = React.useState("");

  const [questions, setQuestions] = React.useState<ICourseAcceptableScore[]>(
    []
  );

  // Flag for if this is a new country specific scoresheet (uses master scores as default).
  // We need to pass this into the save so it knows if we should do a POST or PUT.
  const [isNewCountry, setIsNewCountry] = React.useState(false);

  React.useEffect(() => {
    const removeFetchMasterQuestions = SagaService.subscribeToSaga(
      eStudySaga.FETCH_COURSE_MASTER_QUESTIONS,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_SUCCESS) {
          setQuestions(action.payload);
        }
      }
    );
    const removeFetchMasterQuestionsLegacy = SagaService.subscribeToSaga(
      eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY_SUCCESS
        ) {
          setQuestions(action.payload);
        }
      }
    );

    const removeFetchCountryQuestions = SagaService.subscribeToSaga(
      eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_SUCCESS) {
          setQuestions(action.payload);
        }
      }
    );
    const removeFetchCountryQuestionsLegacy = SagaService.subscribeToSaga(
      eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY_SUCCESS
        ) {
          setQuestions(action.payload);
        }
      }
    );

    const processFetchCourseDetails = (
      courseScores: ICourseScoresDetails,
      isLegacy: boolean
    ) => {
      setCourseName(courseScores.courseName);

      // find our country in the country scores array so we can see if scores exist
      const foundCountry = courseScores.countryAcceptableScores.find(
        country => country.countryId === countryId
      );
      if (foundCountry && foundCountry.hasCountrySpecificScoreSheet) {
        // Has country scores so get them
        setIsNewCountry(false);
        SagaService.dispatchSaga({
          type: isLegacy
            ? eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY
            : eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS,
          payload: { courseId, studyComponentId, countryId }
        });
      } else {
        // No country scores so get the master scores and treat them as the initial country ones.
        setIsNewCountry(true);
        SagaService.dispatchSaga({
          type: isLegacy
            ? eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY
            : eStudySaga.FETCH_COURSE_MASTER_QUESTIONS,
          payload: isLegacy ? studyComponentId : courseId
        });
      }
      setCountryName(foundCountry ? foundCountry.countryName : "");
    };

    const removeFetchScoringCourseDetails = SagaService.subscribeToSaga(
      eStudySaga.FETCH_SCORING_COURSE_DETAILS,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_SCORING_COURSE_DETAILS_SUCCESS) {
          processFetchCourseDetails(action.payload, false);
        }
      }
    );
    const removeFetchScoringCourseDetailsLegacy = SagaService.subscribeToSaga(
      eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY,
      (action: IAction) => {
        if (
          action.type === eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY_SUCCESS
        ) {
          processFetchCourseDetails(action.payload, true);
        }
      }
    );

    // If we have state passed in (they came from the main scores page) then use what we know to
    // determine what we still need to load.
    if (location.state) {
      setCourseName(location.state.courseName);
      setCountryName(location.state.countryName);

      if (location.state.countryAcceptableScoreId) {
        // Has country specific scores so get them
        setIsNewCountry(false);
        if (courseId) {
          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS,
            payload: {
              courseId,
              countryId
            }
          });
        } else {
          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY,
            payload: {
              studyComponentId,
              countryId
            }
          });
        }
      } else {
        // No country specific scores so retrieve and use master scores.
        // Fire and forget, the callback will populate the question values from the master but
        // we will treat them as new scores for the country.
        setIsNewCountry(true);
        if (courseId) {
          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_COURSE_MASTER_QUESTIONS,
            payload: courseId
          });
        } else {
          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY,
            payload: studyComponentId
          });
        }
      }
    } else {
      // No state passed in (went right to this page) so always need to fetch the scoring details.  This will just
      // value the courseName and countryName plus determine what do to with the country based on if
      // country scores exist for the country.
      if (courseId) {
        SagaService.dispatchSaga({
          type: eStudySaga.FETCH_SCORING_COURSE_DETAILS,
          payload: { studyId: id, courseId }
        });
      } else {
        SagaService.dispatchSaga({
          type: eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY,
          payload: { studyId: id, studyComponentId }
        });
      }
    }

    return () => {
      removeFetchMasterQuestions();
      removeFetchMasterQuestionsLegacy();
      removeFetchCountryQuestions();
      removeFetchCountryQuestionsLegacy();
      removeFetchScoringCourseDetails();
      removeFetchScoringCourseDetailsLegacy();
    };
    // eslint-disable-next-line
  }, []);

  const showSpinner = () => {
    return isLoading(
      `undefined_${eStudySaga.FETCH_COURSE_MASTER_QUESTIONS}`,
      `undefined_${eStudySaga.SAVE_COURSE_MASTER_QUESTIONS}`,
      `undefined_${eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS}`,
      `undefined_${eStudySaga.SAVE_COURSE_COUNTRY_QUESTIONS}`,
      `undefined_${eStudySaga.FETCH_SCORING_COURSE_DETAILS}`,
      `undefined_${eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY}`,
      `undefined_${eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY}`,
      `undefined_${eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY}`
    );
  };

  return (
    <React.Fragment>
      <MddProgress inProgress={showSpinner()} spinnerId="svgLoading" />
      <MddEditCourseScoresRender
        studyId={study.StudyId}
        studyName={study.StudyName}
        courseId={courseId}
        studyComponentId={studyComponentId}
        courseName={courseName}
        countryName={countryName}
        countryId={countryId}
        mode={studyComponentId ? "view" : "edit"}
        isNewCountry={isNewCountry}
        isMasterScores={false}
        questions={questions}
      />
    </React.Fragment>
  );
};

export const MddEditCourseCountryScores = withMddStudy()(
  _MddEditCourseCountryScores
);
