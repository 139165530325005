import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Error from "@material-ui/icons/Error";
import Info from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";
import Warning from "@material-ui/icons/Warning";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import withStyles from "@material-ui/core/styles/withStyles";
import * as PropTypes from "prop-types";
import classNames from "classnames";

import * as NotificationActionTypes from "../../../store";

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info
};

const styles1 = (theme: any) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: red[600]
  },
  info: {
    backgroundColor: blue[600]
  },
  warning: {
    backgroundColor: orange[600]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  width: {
    maxWidth: "100%"
  }
});

const MySnackbarContent: React.SFC<any> = (props: any) => {
  const { classes, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], classes.width)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <Close className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = (theme: any) => ({
  margin: {
    margin: theme.spacing.unit
  }
});

class BktNotificationComponent extends React.Component<any, any> {
  public static propTypes = {
    classes: PropTypes.object.isRequired
  };

  constructor(props: any) {
    super(props);

    this.state = {
      open: this.props.open,
      variant: "info",
      message: ""
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  public componentWillReceiveProps(nextProps: any) {
    if (nextProps.open !== this.props.open) {
      this.setState({ open: nextProps.open });
    }
  }

  public handleClick = () => {
    this.setState({ open: true });
  };

  public handleClose = (event: any, reason: string) => {
    if (reason !== "clickaway") {
      this.setState({
        open: false
      });
      this.props.closeSnackBar();
    }
  };

  public render() {
    return (
      <div>
        <Snackbar
          id="snackbar"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.open}
          autoHideDuration={4000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={this.props.variant}
            message={this.props.message}
          />
        </Snackbar>
      </div>
    );
  }
}

BktNotificationComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state: any) => {
  return {
    variant: NotificationActionTypes.notificationVariantSelector(state),
    message: NotificationActionTypes.notificationMessageSelector(state),
    open: NotificationActionTypes.notificationOpenSelector(state)
  };
};

// const mapDispatchToProps = (dispatch: Dispatch) => ({
//   closeSnackBar: () => dispatch(closeSnackBar())
// })

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeSnackBar: () =>
    dispatch({
      type:
        NotificationActionTypes.NotificationActionTypes.CLOSE_SNACKBAR_REQUEST
    })
});

export const BktNotification = withStyles(styles2)(
  connect(mapStateToProps, mapDispatchToProps)(BktNotificationComponent)
);
