import * as React from "react";
import { IBktDataTableColumnProps } from "front-end-lib";
import { QnA } from "../../../components/BktStudy/MddResDisplay/Utils";

const getIsActiveParent = (parentId: string, indications: QnA[][]): boolean => {
  const parent = indications.find(item => {
    const indicationId = item.find(i => i.question === "indicationId");
    return indicationId?.answer === parentId;
  });
  const isActive = parent?.find(item => item.question === "isActive")?.answer;
  return (isActive as unknown) as boolean;
};

export const indicationDataTableHeaders = (
  questions: string[]
): IBktDataTableColumnProps[] =>
  questions.map((question: string) => {
    return {
      label: question,
      tableCellProps: { id: `${question}_indication_header` }
    };
  });

export const getDataTableIndications = (indications: QnA[][], meta: string[]) =>
  indications.map(indication => {
    const [name, isActive, indicationId, demographicId] = [
      "Indication",
      "isActive",
      "indicationId",
      "demographicId"
    ].map(
      question => indication.find(item => item.question === question)?.answer
    );

    const isActiveParent = getIsActiveParent(indicationId!, indications);
    const isParent = indicationId && !demographicId;
    const indicationName =
      (isParent && !isActive) || (isActiveParent && !isParent && !isActive)
        ? `*${name}`
        : name;

    const childClassName = isParent ? "" : "mdd-experience--indication-child";
    const inactiveClassName = isActive ? "" : "mdd-experience--inactive-item";

    const result = indication
      .filter(item => !meta?.includes(item.question))
      .map(item => {
        const answer =
          item.question === "Indication" ? indicationName : item.answer;
        const className =
          item.question === "Indication"
            ? `${childClassName} ${inactiveClassName}`
            : inactiveClassName;

        return {
          [item.question]: (
            <div
              className={`mdd-experience--tbl-cell ${className}`}
              data-testid={`${name}_${item.question}_indication_testid`}
              title={answer ?? "-"}
            >
              {answer ?? "-"}
            </div>
          )
        };
      });

    return Object.assign({ key: name }, ...result);
  });
