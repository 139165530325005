import * as React from "react";
import { pick, trim } from "lodash";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddCircle from "@material-ui/icons/AddCircle";
import {
  SagaService,
  IAction,
  BktDataTable,
  IBktDataTableData
} from "front-end-lib/core";

import { studyDatatableHeaders, studyDataTableProps } from "../../../config";
import {
  IBktDataTableStudy,
  withMddStudy,
  withMddLookups
} from "../../../components";
import { IMddStudyProps, ILookup, Study } from "../../../model";
import { eStudySaga } from "../../../sagas";
import { eStudyActionTypes } from "../../../store";
import { history, store } from "../../../utils";

interface IBktShowStudiesProps extends IMddStudyProps {
  demographics: ILookup[];
  indications: ILookup[];
  therapeuticAreas: ILookup[];
}

export const BktShowStudiesComponent = (props: IBktShowStudiesProps) => {
  const [studies, setStudies] = React.useState(undefined);
  const {
    demographics = [],
    indications = [],
    therapeuticAreas = [],
    loadingKeys = []
  } = props;
  let filterVals = React.useRef(new Map());

  React.useEffect(() => {
    const removeFetchStudiesSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDIES,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_STUDIES_SUCCESS) {
          setStudies(action.payload);
        }
      }
    );
    return () => removeFetchStudiesSub();
    // eslint-disable-next-line
  }, []);

  const handleChangeFilter = async (event: any) => {
    const { name } = event.target;
    const value = event.target.value ? trim(event.target.value) : "";
    const searchStudy = { loadingKey: "BktShowStudiesComponent" };

    if (
      filterVals.current.has(name) &&
      filterVals.current.get(name) === value
    ) {
      return;
    }

    value
      ? filterVals.current.set(name, value)
      : filterVals.current.delete(name);

    if (filterVals.current.size < 1) {
      setStudies(undefined);
      return;
    }

    filterVals.current.forEach((value, key) => {
      Object.assign(searchStudy, { [key]: value });
    });

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDIES,
      payload: searchStudy
    });
  };

  const getFormattedStudyData = () => {
    const dataProps = [
      "StudyName",
      "SponsorName",
      "InternalStudyName",
      "Protocol",
      "TherapeuticArea",
      "Indication",
      "Demographic"
    ];
    return studies
      ? (studies || []).map((st: IBktDataTableStudy) => {
          return Object.assign(pick(st, dataProps) as IBktDataTableStudy, {
            key: st.StudyId
          });
        })
      : undefined;
  };

  return (
    <div id="container" className="mdd-grid">
      <Paper id="headerContainer">
        <Typography id="headerTitle"> All Studies</Typography>
        <Button
          id="addStudyButton"
          variant="contained"
          size={"small"}
          onClick={() => {
            store.dispatch({
              type: eStudyActionTypes.SET_STUDY,
              payload: new Study()
            });
            history.push("/study/new");
          }}
        >
          <AddCircle id="addCircleIcon" />
          Add New Study
        </Button>
      </Paper>
      <Paper id="bodyContainer">
        <BktDataTable
          data={getFormattedStudyData()}
          defaultSortProp="StudyName"
          loading={loadingKeys.some(
            lk => lk === "BktShowStudiesComponent_@@study/FETCH_STUDIES"
          )}
          columnProps={studyDatatableHeaders(
            handleChangeFilter,
            filterVals.current as Map<string, any>,
            therapeuticAreas,
            indications,
            demographics
          )}
          rowActions={[
            {
              isPrimary: true,
              action: (
                data: IBktDataTableData,
                event: React.MouseEvent<HTMLDivElement>
              ) => history.push("/study/" + data.key)
            }
          ]}
          {...studyDataTableProps}
        />
      </Paper>
    </div>
  );
};

export const BktShowStudies = withMddLookups([
  "demographics",
  "indications",
  "therapeuticAreas"
])(withMddStudy()(BktShowStudiesComponent));
