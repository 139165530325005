import * as React from "react";
import { connect } from "react-redux";
import { SagaService } from "front-end-lib";
import { eStudySaga, eSiteSaga } from "../../../sagas";
import { IApplicationState, eStudyActionTypes } from "../../../store";
import { MddProgress } from "../../common";
import { MddBreadcrumb, withMddBase } from "../../../components";
import { MddStudySitePersonExperience } from "../MddEditStudySitePersonnel/MddStudySitePersonExperience";
import { MddResDisplay } from "./MddResDisplay";
import {
  IStudy,
  ISite,
  IStudySitePerson,
  AssignmentStatus
} from "../../../model";
import { formatPersonName, isLoading, store } from "../../../utils";
import { eResTypes } from "../../../constants/applicationConstants";
import { MddNoCpExperience } from "./MddNoCpExperience";

interface IMddResDisplayWrapper {
  match: {
    params: {
      id: string;
      siteId: string;
      personnelId: string;
    };
  };
  study: IStudy;
  person: IStudySitePerson;
  site: ISite;
}

const experiencePageMap = {
  [eResTypes.CLINICALPROFILE]: MddResDisplay,
  [eResTypes.DOCEBO]: MddStudySitePersonExperience,
  [eResTypes.LEGACYEXPERIENCE]: MddStudySitePersonExperience
};

const MddResDisplayWrapperComponent = (props: IMddResDisplayWrapper) => {
  const { study, person, site } = props;
  const { id, siteId, personnelId } = props.match.params;

  React.useEffect(() => {
    // If not in the store already (i.e. they went directly to this page from a link),
    // then load the study, site and person.
    if (!study.StudyId) {
      SagaService.dispatchSaga({ type: eStudySaga.FETCH_STUDY, payload: id });
    }
    if (!site.SiteId) {
      SagaService.dispatchSaga({ type: eSiteSaga.FETCH_SITE, payload: siteId });
    }
    if (!person.personnelId) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_SITE_PERSON,
        payload: {
          dto: { StudyId: id, SiteId: siteId, PersonnelId: personnelId }
        }
      });
    }

    if (!study.ResType) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_RES_CONFIG,
        payload: id
      });
    }

    return () => {
      store.dispatch({
        type: eStudyActionTypes.SET_STUDY_RES_CONFIG,
        payload: undefined
      });
    };
    // eslint-disable-next-line
  }, []);

  const showSpinner = isLoading(
    `undefined_${eStudySaga.FETCH_STUDY}`,
    `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON}`,
    `undefined_${eStudySaga.FETCH_STUDY_RES_CONFIG}`,
    `undefined_${eSiteSaga.FETCH_SITE}`
  );

  const Experience = experiencePageMap[study.ResType];

  return (
    <React.Fragment>
      <MddProgress inProgress={showSpinner} spinnerId="svgLoading" />
      <MddBreadcrumb
        breadcrumbs={[
          { display: "All Studies", route: "/study" },
          {
            display: study?.StudyName || "Loading...",
            route: `/study/${id}`
          },
          {
            display: site?.SiteName || "Loading...",
            route: `/study/${id}/sites/${siteId}/personnel`
          },
          { display: formatPersonName(person) }
        ]}
      />
      {!showSpinner &&
        (Experience ? (
          <Experience
            id={id}
            siteId={siteId}
            personnelId={personnelId}
            person={person}
          />
        ) : (
          <MddNoCpExperience status={AssignmentStatus.ResNotPublished} />
        ))}
    </React.Fragment>
  );
};

export const MddResDisplayWrapper = connect((state: IApplicationState) => ({
  study: state.study.currentStudy,
  person: state.study.currentStudySitePerson,
  site: state.site.currentSite
}))(withMddBase(MddResDisplayWrapperComponent));
