import * as React from "react";
import { map, pick, trim, filter, some } from "lodash";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddCircle from "@material-ui/icons/AddCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faPenToSquare
} from "@fortawesome/free-regular-svg-icons";
import {
  SagaService,
  getKeysFromState,
  BktDataTable,
  IBktDataTableData,
  IAction,
  BktTypeaheadSelect,
  eFilter,
  Filter,
  SortType
} from "front-end-lib/core";
import { eStudySaga, eScaleSaga } from "../../..//sagas";
import {
  Scale,
  StudyScale,
  Validations,
  IMddStudyProps,
  ILookup,
  IScale,
  IStudyScale
} from "../../..//model";
import {
  BktBreadcrumb,
  withMddStudy,
  MddForm,
  MddDialog,
  MddDialogButtons,
  MddCheckbox
} from "../../..//components";
import { scaleDataTableProps } from "../../..//config";
import { classConstants, activeStatuses } from "../../../constants";
import {
  getDefaultValueForSelect,
  isLoading,
  sortStatus
} from "../../../utils";
import { MddStatus } from "../../common";

export interface IBktDataTableStudyScale extends IStudyScale {
  key: string;
}

export interface IBktShowStudyScaleDataTableState {
  scales?: IScale[] | undefined;
  studyScales?: IBktDataTableStudyScale[] | undefined;
  newStudyScales?: IBktDataTableStudyScale[] | undefined;
  studyScale?: IStudyScale;
  mode: "View" | "Add" | "Edit" | "Delete";
  scaleNameHasError: boolean;
  origActive: boolean;
}

export class _BktShowStudyScales extends React.Component<
  IMddStudyProps,
  IBktShowStudyScaleDataTableState
> {
  private filterVals = new Map();

  constructor(props: IMddStudyProps) {
    super(props);

    this.state = {
      studyScales: [],
      newStudyScales: [],
      studyScale: new StudyScale(),
      scales: [],
      mode: "View",
      scaleNameHasError: false,
      origActive: false
    };

    const resetStudyScaleState = () => {
      const { study } = this.props;
      this.setState({ mode: "View", studyScale: undefined }, () => {
        SagaService.dispatchSaga({
          type: eStudySaga.FETCH_STUDY_SCALES,
          payload: study.StudyId
        });
      });
    };

    const studyScaleCallback = (action: IAction) => {
      if (
        action.type === eStudySaga.SAVE_STUDY_SCALE_SUCCESS ||
        action.type === eStudySaga.DELETE_STUDY_SCALE_SUCCESS
      ) {
        resetStudyScaleState();
      }
    };

    SagaService.mapSagaToState(
      this,
      new Map([
        [
          eScaleSaga.FETCH_SCALES.toString(),
          {
            stateKey: getKeysFromState(this.state, ["scales"]),
            validationType: eScaleSaga.FETCH_SCALES_SUCCESS
          }
        ],
        [
          eStudySaga.FETCH_STUDY_SCALES.toString(),
          {
            stateKey: getKeysFromState(this.state, [
              "studyScales",
              "newStudyScales"
            ]),
            validationType: eStudySaga.FETCH_STUDY_SCALES_SUCCESS
          }
        ],
        [
          eStudySaga.SAVE_STUDY_SCALE.toString(),
          { callback: studyScaleCallback }
        ],
        [
          eStudySaga.DELETE_STUDY_SCALE.toString(),
          { callback: studyScaleCallback }
        ]
      ])
    );
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    SagaService.dispatchSaga({ type: eScaleSaga.FETCH_SCALES, payload: {} });
    if (id) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_SCALES,
        payload: id
      });
    }
  }

  public render() {
    const {
      studyScales,
      studyScale,
      newStudyScales,
      mode,
      origActive
    } = this.state;
    const { study } = this.props;
    const dataProps = ["Name", "ShortName", "IsActive"];

    const data = newStudyScales
      ? map(newStudyScales, (sc: IBktDataTableStudyScale) => {
          sc = Object.assign(new StudyScale(), sc) as IBktDataTableStudyScale;
          return Object.assign(pick(sc, dataProps) as IBktDataTableStudyScale, {
            key: sc.ScaleId
          });
        })
      : undefined;

    const loading =
      isLoading(`undefined_${eStudySaga.SAVE_STUDY_SCALE}`) ||
      isLoading(`undefined_${eScaleSaga.FETCH_SCALES}`);

    const saving = isLoading(`undefined_${eStudySaga.SAVE_STUDY_SCALE}`);

    return (
      <div className="mdd-form--fullheight">
        <div>
          <BktBreadcrumb
            rootText="All Studies"
            rootUrl="/study"
            currentLocation={study.StudyName}
          />
        </div>
        <MddForm>
          <div className="mdd-grid--with-menu mdd-grid">
            <Paper id="headerContainer">
              <Typography id="headerTitle">Scales</Typography>
              <Button
                id="addScaleButton"
                data-testid="btnAddScale"
                variant="contained"
                size={"small"}
                onClick={() => {
                  this.setState({ studyScale: undefined, mode: "Add" });
                }}
              >
                <AddCircle id="addCircleIcon" />
                Add New Scale
              </Button>
            </Paper>
            <Paper id="bodyContainer">
              <div>
                <BktDataTable
                  data={data}
                  defaultSortProp="Name"
                  loading={isLoading(
                    `undefined_${eStudySaga.FETCH_STUDY_SCALES}`
                  )}
                  columnProps={[
                    {
                      label: "Scale Name",
                      filter: new Filter(eFilter.Text, {
                        debounceInterval: 0,
                        textProps: {
                          className: classConstants.TEXT_FILTER_CLASS,
                          id: "scaleNameFilter",
                          onChange: this.handleChangeFilter,
                          name: "Name"
                        }
                      }),
                      tableCellProps: { id: "scaleNameHeader" },
                      divProps: { id: "scaleNameHeader_div" },
                      sortProp: { sortPropName: "Name", desc: true }
                    },
                    {
                      label: "Short Name",
                      filter: new Filter(eFilter.Text, {
                        debounceInterval: 0,
                        textProps: {
                          className: classConstants.TEXT_FILTER_CLASS,
                          id: "scaleShortNameFilter",
                          onChange: this.handleChangeFilter,
                          name: "ShortName"
                        }
                      }),
                      tableCellProps: {
                        id: "scaleShortNameHeader",
                        className: classConstants.TABLE_CELL_CLASS
                      },
                      divProps: { id: "scaleShortNameHeader_div" }
                    },
                    {
                      label: "Status",
                      sortProp: {
                        sortPropName: "IsActive",
                        desc: false,
                        sortType: "Custom" as SortType,
                        alg: sortStatus
                      },
                      filter: new Filter(eFilter.Select, {
                        debounceInterval: 0,
                        textProps: {
                          id: "scaleStatusFilterText",
                          name: "IsActive",
                          className: "tbl--filter-select",
                          onChange: this.handleChangeFilter,
                          InputLabelProps: {
                            shrink: this.filterVals.has("IsActive")
                          }
                        },
                        selectMenuProps: {
                          MenuProps: { className: "bracket-Select" }
                        },
                        menuProps: {},
                        selectData: activeStatuses,
                        defaultSelect: true
                      }),
                      tableCellProps: { id: "ScaleStatusHeader" },
                      divProps: { id: "scaleStatus_div" },
                      formatProp: {
                        formatPropName: "IsActive",
                        format: (val: boolean) => <MddStatus isActive={val} />
                      }
                    }
                  ]}
                  rowActions={[
                    {
                      isPrimary: false,
                      display: (
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          fixedWidth
                          id="editIcon"
                          data-testid="svgEditIcon"
                          className="svgEditIconPointer"
                          title="Edit"
                        />
                      ),
                      action: (data: IBktDataTableData) => {
                        const currScale = this.getStudyScale(data);
                        this.setState({
                          studyScale: currScale,
                          mode: "Edit",
                          origActive: currScale!.IsActive
                        });
                      }
                    },
                    {
                      action: (data: IBktDataTableData) =>
                        this.setState({
                          studyScale: this.getStudyScale(data),
                          mode: "Delete"
                        }),
                      display: (
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          fixedWidth
                          id="deleteCircleIcon"
                          data-testid="svgDeleteIcon"
                          className="svgEditIconPointer"
                          title="Delete"
                        />
                      )
                    }
                  ]}
                  noRecordsMessage={
                    some(studyScales) && !some(data)
                      ? "No records found"
                      : "No scales exist for this study"
                  }
                  {...scaleDataTableProps}
                />
              </div>
              {mode === "Add" && (
                <MddDialog
                  submitting={loading}
                  dialogProps={{
                    id: "BktFormDialogAddEditStudyScale",
                    onClose: () =>
                      this.setState({ scaleNameHasError: false, mode: "View" }),
                    "aria-labelledby": "form-dialog-title",
                    open: true
                  }}
                  dialogContentProps={{
                    id: "form-dialog-content"
                  }}
                  dialogTitleProps={{
                    id: "form-dialog-title",
                    title: "Add New Scale"
                  }}
                  dialogContent={this.renderDialogContentChildren()}
                  dialogActions={
                    <MddDialogButtons
                      saveButtonProps={{
                        disabled:
                          !this.state.studyScale ||
                          !this.state.studyScale.ScaleId,
                        onClick: this.handleStudyScaleSave
                      }}
                      closeButtonProps={{
                        onClick: () =>
                          this.setState({
                            scaleNameHasError: false,
                            mode: "View"
                          })
                      }}
                    />
                  }
                />
              )}
              {mode === "Delete" && (
                <MddDialog
                  submitting={isLoading(
                    `undefined_${eStudySaga.DELETE_STUDY_SCALE}`
                  )}
                  dialogProps={{
                    id: "BktFormDialogDeleteStudyScale",
                    onClose: () => this.setState({ mode: "View" }),
                    "aria-labelledby": "form-dialog-title",
                    open: true
                  }}
                  dialogContentProps={{
                    id: "form-dialog-content",
                    className: "mdd-dialog--content-medium"
                  }}
                  dialogTitleProps={{
                    id: "form-dialog-title",
                    title: "Remove Study Scale"
                  }}
                  dialogContent={this.renderDeleteDialogContentChildren()}
                  dialogActions={
                    <MddDialogButtons
                      saveButtonText={"DELETE"}
                      saveButtonProps={{
                        disabled: isLoading(
                          `undefined_${eStudySaga.DELETE_STUDY_SCALE}`
                        ),
                        onClick: this.handleDelete,
                        id: "btnDelete"
                      }}
                      closeButtonProps={{
                        onClick: () => this.setState({ mode: "View" })
                      }}
                    />
                  }
                />
              )}
              {mode === "Edit" && (
                <MddDialog
                  submitting={saving}
                  dialogProps={{
                    id: "divEditStudyScaleDialog",
                    open: true,
                    maxWidth: "md",
                    fullWidth: true
                  }}
                  dialogActionProps={{ id: "divStudyEditScaleDialogActions" }}
                  dialogContentProps={{
                    id: "divEditStudyScaleDialogContent",
                    className: "mdd-dialog--content-medium"
                  }}
                  dialogTitleProps={{
                    id: "divEditStudyScaleTitle",
                    title: "Edit Scale"
                  }}
                  dialogContent={this.getEditDialogContent()}
                  dialogActions={
                    <MddDialogButtons
                      saveButtonProps={{
                        disabled: saving || origActive === studyScale!.IsActive,
                        id: "btnSave",
                        onClick: this.handleStudyScaleSave
                      }}
                      closeButtonProps={{
                        id: "btnCancel",
                        disabled: saving,
                        onClick: () => this.setState({ mode: "View" })
                      }}
                    />
                  }
                />
              )}
            </Paper>
          </div>
        </MddForm>
      </div>
    );
  }

  private getEditDialogContent = () => {
    const { studyScale } = this.state;
    return [
      <div className="mdd-study-scale-div--container">
        <div
          className="mdd-study-scale-div--caption"
          data-testid="scaleNameCaption"
        >
          Scale Name
        </div>
        <div className="mdd-study-scale-div--value" data-testid="scaleName">
          {studyScale ? studyScale.Name : ""}
        </div>
      </div>,
      <div className="mdd-study-scale-div--container">
        <div className="mdd-study-scale-div--caption" data-testid="shortName">
          Short Name
        </div>
        <div
          className="mdd-study-scale-div--value"
          data-testid="scaleShortName"
        >
          {studyScale ? studyScale.ShortName : ""}
        </div>
      </div>,
      <MddCheckbox
        lblText="Active"
        chkBoxProps={{
          id: "chkStudyScaleActive",
          checked: studyScale!.IsActive,
          value: studyScale!.IsActive,
          onChange: (e: any) =>
            this.setState({
              studyScale: Object.assign(new StudyScale(), studyScale, {
                IsActive: !studyScale!.IsActive
              })
            })
        }}
      />
    ];
  };

  private renderDeleteDialogContentChildren = () => {
    const { studyScale } = this.state;
    const { study } = this.props;
    return [
      <div id="divTitle" key="textKey" className="displayRelative">
        <label id="lblDeleteScale" key="lblDeleteScale">
          {`Are you sure you want to delete "${
            studyScale ? studyScale.Name : ""
          }" from the "${
            study.StudyName
          }" study? All associated data will be deleted.`}
        </label>
      </div>
    ];
  };

  private getStudyScale = (data: IBktDataTableData) => {
    const { newStudyScales } = this.state;
    return newStudyScales
      ? newStudyScales.find((x: any) => x.id === data.key)
      : undefined;
  };

  private handleDelete = () => {
    const { studyScale } = this.state;
    const { study } = this.props;
    SagaService.dispatchSaga({
      type: eStudySaga.DELETE_STUDY_SCALE,
      payload: new StudyScale(
        study.StudyId,
        studyScale ? studyScale.ScaleId : ""
      )
    });
  };

  private handleChangeFilter = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const inputValue = event.target.value ? trim(event.target.value) : "";
    const inputName = event.target.name;
    const { studyScales } = this.state;

    if (
      this.filterVals.has(inputName) &&
      this.filterVals.get(inputName) === inputValue
    ) {
      return;
    }

    let newStudyScales = studyScales;
    inputValue
      ? this.filterVals.set(inputName, inputValue)
      : this.filterVals.delete(inputName);

    if (this.filterVals.size > 0) {
      this.filterVals.forEach((value, key) => {
        newStudyScales = filter(
          newStudyScales,
          (sc: IBktDataTableStudyScale) =>
            sc[key]
              .toString()
              .toLocaleLowerCase()
              .indexOf(value.toString().toLocaleLowerCase()) > -1
        );
      });
      this.setState({ newStudyScales });
    } else {
      this.setState({ newStudyScales: studyScales });
    }
  };

  private renderDialogContentChildren = () => {
    let { scales, studyScale, studyScales, scaleNameHasError } = this.state;
    const scaleIds = studyScales ? studyScales.map(sts => sts.ScaleId) : [];
    scales = (map(scales, (ms: IScale) =>
      Object.assign(new Scale(), ms)
    ) as IScale[]).filter(sc => !scaleIds.some(id => id === sc.Id));

    const scalesDropdownData = map(scales, (option: IScale) => ({
      value: option.Id,
      label: option.Name
    }));

    return [
      <span className={scaleNameHasError ? "" : "mdd-form--text"}>
        <label
          key="scaleDiv"
          data-testid="lblScale"
          className={
            scaleNameHasError
              ? "mdd-form--typeahead-label-error"
              : "mdd-typeahead-container"
          }
        >
          Scale *
        </label>
      </span>,
      <BktTypeaheadSelect
        key="bktTypeaheadSelect"
        selectData={scalesDropdownData}
        textProps={{
          validationForOnBlur: [Validations.required],
          validationerror: (validationError: boolean) =>
            this.setState({ scaleNameHasError: validationError }),
          textFieldProps: {
            textProps: {
              id: "scalesDropdown",
              placeholder: "Start typing to filter scales",
              className: "tbl--filter-select",
              InputLabelProps: {
                shrink:
                  studyScale !== undefined && studyScale.ScaleId !== undefined
              },
              disabled: scalesDropdownData.length === 0,
              value: getDefaultValueForSelect(
                scalesDropdownData,
                studyScale ? studyScale.ScaleId : ""
              )
            }
          }
        }}
        itemProps={{ name: "scaleId" }}
        onSubmit={(selectedOption: ILookup) => {
          const { label, value } = selectedOption || {
            value: undefined,
            label: undefined
          };
          this.setState({
            studyScale: Object.assign(new StudyScale(), this.state.studyScale, {
              id: value,
              name: label
            }) as IStudyScale
          });
        }}
      />
    ];
  };

  private handleStudyScaleSave = () => {
    const { studyScale } = this.state;
    const { study } = this.props;
    if (studyScale) {
      SagaService.dispatchSaga({
        type: eStudySaga.SAVE_STUDY_SCALE,
        payload: {
          newStudyScale: Object.assign(new StudyScale(), studyScale, {
            studyId: study.StudyId
          }),
          existingStudyScale: studyScale.StudyId
        }
      });
    }
  };
}

export const BktShowStudyScales = withMddStudy()(_BktShowStudyScales);
