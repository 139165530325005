import * as React from "react";
import { IQuestionGroup } from "../../../model/interfaces/IPersonnelCPResExperience";
import { MddListPanelContent } from "./MddListPanelContent";
import { EducationContent } from "./MddEducationContent";

interface IMddEducationPanelConent {
  data?: IQuestionGroup[];
  displayQuestions: string[];
}

export const MddEducationPanelContent = ({
  data,
  displayQuestions
}: IMddEducationPanelConent) => {
  const educationContent = (questionGroup: IQuestionGroup) => (
    <EducationContent
      data={questionGroup}
      displayQuestions={displayQuestions}
    />
  );

  return <MddListPanelContent data={data} content={educationContent} />;
};
