import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationActionTypes } from "./types";
import { closeSnackBar, openSuccessSnackBar } from "./actions";
import * as selector from "./selector";

export function* handleOpenSuccessSnackBar(action: any) {
  yield put(openSuccessSnackBar(action.payload));
}

export function* handleCloseSnackBar() {
  yield put(closeSnackBar());
}

export function* getNotificationVariantSelector() {
  yield select(selector.notificationVariantSelector);
}

/**
 * Watcher function to watch Redux for a specific action type
 */

function* watchNotificationOpenRequest() {
  yield takeEvery(
    NotificationActionTypes.OPEN_SNACKBAR_REQUEST,
    handleOpenSuccessSnackBar
  );
}

function* watchNotificationCloseRequest() {
  yield takeEvery(
    NotificationActionTypes.CLOSE_SNACKBAR_REQUEST,
    handleCloseSnackBar
  );
}

function* notificationSaga() {
  yield all([
    fork(watchNotificationOpenRequest),
    fork(watchNotificationCloseRequest)
  ]);
}

export default notificationSaga;

// function* notificationSaga()
// {
//     yield takeLatest(NotificationActionTypes.CLOSE_SNACKBAR_REQUEST, handleCloseSnackBar)
// }

// export default notificationSaga
