import * as React from "react";
import {
  Typography,
  Paper,
  getKeysFromState,
  SagaService,
  BktDataTable,
  IAction,
  BktSaga
} from "front-end-lib/core";
import { map, pick, trim, filter, assign, some } from "lodash";
import { Button } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import {
  BktBreadcrumb,
  IBktDataTablePerson,
  withMddStudy,
  MddForm,
  MddDialog,
  MddDialogButtons,
  MddAddStudySitePersonnelDialog
} from "../../../components";
import {
  studySitePersonnelDataTableProps,
  studySitePersonnelDataTableHeaders
} from "../../../config";
import {
  StudySiteDTO,
  PersonDTO,
  Role,
  IMddStudyProps,
  ILookup,
  IStudySite,
  IPerson,
  IRole,
  ISite,
  Site
} from "../../../model";
import { eStudySaga, eLookupSaga, eSiteSaga } from "../../../sagas";
import {
  getDefaultValueForSelect,
  isLoading,
  rolesIncludePI,
  history
} from "../../../utils";

export interface IBktDataTableStudySitePersonnel extends IPerson {
  key: string;
}

export interface IBktShowStudySitePersonnelState {
  studySitePersonnel?: IBktDataTableStudySitePersonnel[] | undefined;
  newStudySitePersonnel?: IBktDataTableStudySitePersonnel[] | undefined;
  site: ISite;
  roles?: ILookup[];
  filteredRoles?: ILookup[];
  person?: IBktDataTablePerson;
  personPIKey?: string;
  mode: "View" | "Add" | "Edit" | "Delete";
  piGuid: string | undefined;
}

class _BktShowStudySitePersonnel extends BktSaga<
  IMddStudyProps,
  IBktShowStudySitePersonnelState,
  {}
> {
  private filterVals = new Map();
  private get loadingDelete() {
    return isLoading(`undefined_${eStudySaga.DELETE_STUDY_SITE_PERSONNEL}`);
  }

  private get personDTO() {
    const personDTO = Object.assign(new PersonDTO());
    this.filterVals.forEach((value, key) => {
      Object.assign(personDTO, { [key]: value });
    });
    return personDTO;
  }
  get searchPersonnel() {
    const searchPersonnel = {};
    this.filterVals.forEach((value, key) => {
      Object.assign(searchPersonnel, { [key]: value });
    });
    return searchPersonnel;
  }

  constructor(props: IMddStudyProps) {
    super(props);
    this.state = {
      studySitePersonnel: [],
      newStudySitePersonnel: [],
      site: new Site(),
      roles: undefined,
      filteredRoles: undefined, // roles with PI filtered out - to use for assigning role for new person
      person: undefined,
      personPIKey: undefined,
      mode: "View",
      piGuid: undefined
    };

    const setFilteredRolesInState = (payload: any[]) => {
      // Filter out the site PI role for dropdown purposes.
      // Also, save away the GUID for the PI role so we can use
      // it for comparison purposes later.
      const filteredRoles: ILookup[] = payload
        .filter((r: IRole) => {
          const role = assign(new Role(), r);
          if (role.IsSitePi) {
            this.setState({ piGuid: role.RoleId });
            return false;
          }
          return true;
        })
        .map((r: IRole) => {
          const role = assign(new Role(), r);
          return {
            value: role.RoleId,
            label: role.Name
          } as ILookup;
        });
      this.setState({ filteredRoles });
    };

    const setRolesInState = (action: IAction) => {
      if (action.type === eLookupSaga.FETCH_ROLES_SUCCESS) {
        this.setState(
          {
            roles: action.payload.map((r: IRole) => {
              const role = assign(new Role(), r);
              return {
                value: role.RoleId,
                label: role.Name
              } as ILookup;
            })
          },
          () => {
            const { roles } = this.state;
            if (roles && roles.length) {
              setFilteredRolesInState(action.payload);
            }
          }
        );
      }
    };

    const setPersonsInState = (action: IAction) => {
      if (
        action.type === eStudySaga.FETCH_STUDY_SITE_PERSONNEL_SUCCESS &&
        action.payload.length
      ) {
        const { piGuid } = this.state;
        this.setState(
          {
            studySitePersonnel: action.payload,
            newStudySitePersonnel: action.payload
          },
          () => {
            // See if we have a PI.  If so, save that person away for use showing/hiding the Active checkbox.
            const person = this.state.newStudySitePersonnel!.find(
              (p: IBktDataTableStudySitePersonnel) => {
                return rolesIncludePI(p.Roles, piGuid);
              }
            );
            if (person) {
              this.setState({ personPIKey: person.key });
            }
          }
        );
      }
    };

    const studySiteCallback = (action: IAction) => {
      if (action.type === eStudySaga.FETCH_STUDY_SITE_SUCCESS) {
        const site = action.payload as IStudySite;
        SagaService.dispatchSaga({
          type: eSiteSaga.FETCH_SITE,
          payload: site.SiteId
        });
      }
    };

    const studySitePersonnelCallback = (action: IAction) => {
      if (action.type === eStudySaga.DELETE_STUDY_SITE_PERSONNEL_SUCCESS) {
        const { id, siteId } = this.props.match.params;
        this.setState({ mode: "View" });
        SagaService.dispatchSaga({
          type: eStudySaga.FETCH_STUDY_SITE_PERSONNEL,
          payload: {
            dto: Object.assign(this.personDTO, { studyId: id, siteId })
          }
        });
      }
    };

    this.configureSaga(
      new Map([
        [
          eStudySaga.FETCH_STUDY_SITE.toString(),
          { callback: studySiteCallback }
        ],
        [
          eSiteSaga.FETCH_SITE.toString(),
          {
            stateKey: getKeysFromState(this.state, ["site"]),
            validationType: eSiteSaga.FETCH_SITE_SUCCESS
          }
        ],
        [eLookupSaga.FETCH_ROLES.toString(), { callback: setRolesInState }],
        [
          eStudySaga.FETCH_STUDY_SITE_PERSONNEL.toString(),
          { callback: setPersonsInState }
        ],
        [
          eStudySaga.DELETE_STUDY_SITE_PERSONNEL.toString(),
          { callback: studySitePersonnelCallback }
        ],
        [
          eStudySaga.SAVE_STUDY_SITE_PERSONNEL.toString(),
          { callback: studySitePersonnelCallback }
        ]
      ])
    );
  }

  public componentDidMount() {
    const { id, siteId } = this.props.match.params;
    if (id && siteId) {
      const payload = new StudySiteDTO(id, siteId, "", true);
      SagaService.dispatchSaga({ type: eStudySaga.FETCH_STUDY_SITE, payload });
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_SITE_PERSONNEL,
        payload: { dto: Object.assign(this.personDTO, { studyId: id, siteId }) }
      });
    }
    SagaService.dispatchSaga({ type: eLookupSaga.FETCH_ROLES, payload: id });
  }

  public handleSave = () => {
    const { id, siteId } = this.props.match.params;
    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SITE_PERSONNEL,
      payload: {
        dto: Object.assign(this.personDTO, { studyId: id, siteId })
      }
    });
  };

  public render() {
    const { site, studySitePersonnel, roles, mode } = this.state;
    const { study } = this.props;
    const { id, siteId } = this.props.match.params;

    let { newStudySitePersonnel } = this.state;
    const data = newStudySitePersonnel
      ? map(newStudySitePersonnel, (p: IBktDataTableStudySitePersonnel) => {
          return Object.assign(
            pick(p, [
              "FirstName",
              "LastName",
              "UserName",
              "Email"
            ]) as IBktDataTableStudySitePersonnel,
            {
              key: p.Id,
              Roles: p.Roles.map((r: any) => r.Name).join(", "),
              IsActive: p.IsActive,
              person: p
            }
          );
        })
      : undefined;

    return (
      <div className="mdd-form--fullheight">
        <BktBreadcrumb
          rootText="All Studies"
          rootUrl="/study"
          currentLocation={study.StudyId ? study.StudyName : ""}
          currentNestedLocation={
            site.SiteId ? `${site.SiteName} (${site.SiteNumber}) ` : ""
          }
          currentNestedLocationUrl={`/study/${study.StudyId}/sites`}
        />
        <MddForm>
          <div className="mdd-grid--with-menu mdd-grid">
            <Paper id="headerContainer">
              <Typography id="headerTitle">Personnel</Typography>
              <Button
                id="addPersonButton"
                variant="contained"
                size={"small"}
                onClick={() =>
                  this.setState({ person: undefined }, () =>
                    this.setState({ mode: "Add" })
                  )
                }
              >
                <AddCircle id="addCircleIcon" />
                Add New Person
              </Button>
            </Paper>
            <Paper id="bodyContainer">
              <BktDataTable
                data={data}
                defaultSortProp={{ sortPropName: "LastName", desc: false }}
                loading={isLoading(
                  `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSONNEL}`
                )}
                columnProps={studySitePersonnelDataTableHeaders(
                  this.handleChangeFilter,
                  roles,
                  this.state.piGuid,
                  this.filterVals,
                  this.handleActionClick
                )}
                noRecordsMessage={
                  some(studySitePersonnel) && !some(newStudySitePersonnel)
                    ? "No records found"
                    : "No personnel exist for this study"
                }
                {...studySitePersonnelDataTableProps}
              />
            </Paper>
          </div>
        </MddForm>
        {mode === "Add" && (
          <MddAddStudySitePersonnelDialog
            open={true}
            onClose={() => this.setState({ mode: "View" })}
            onSave={this.handleSave}
            studyId={id}
            siteId={siteId}
          />
        )}

        {mode === "Delete" && (
          <MddDialog
            submitting={this.loadingDelete}
            dialogProps={{
              id: "divDeletePersonDialog",
              onClose: () => this.setState({ mode: "View" }),
              "aria-labelledby": "form-dialog-title",
              open: true
            }}
            dialogActionProps={{
              id: "divDeletePersonDialogActions"
            }}
            dialogContentProps={{
              id: "divDeletePersonDialogContent",
              className: "mdd-dialog--content-medium"
            }}
            dialogTitleProps={{
              id: "divDeletePersonDialogTitle",
              title: "Remove person from site"
            }}
            dialogContent={this.renderDeleteDialogContentChildren()}
            dialogActions={
              <MddDialogButtons
                saveButtonText="DELETE"
                saveButtonProps={{
                  disabled:
                    this.loadingDelete || this.state.person === undefined,
                  onClick: this.deletePersonnel
                }}
                closeButtonProps={{
                  disabled: this.loadingDelete,
                  onClick: () => this.setState({ mode: "View" })
                }}
              />
            }
          />
        )}
      </div>
    );
  }

  private renderDeleteDialogContentChildren = () => {
    const { person, site } = this.state;
    return [
      <div
        id="divTitle"
        key="textKey"
        className="mdd-form-delete-dialog-content"
      >
        <label id="lblDeletePerson" key="lblDeletePerson">
          {person
            ? `Are you sure you want to remove "${person.FirstName} ${person.LastName} - ${person.Email}" from "${site.SiteName}"?`
            : "Cannot find person"}
        </label>
      </div>
    ];
  };

  private handleActionClick = (
    person: IBktDataTablePerson,
    mode: "View" | "Add" | "Edit" | "Delete"
  ) => {
    if (mode === "Edit") {
      const { id, siteId } = this.props.match.params;
      history.push(`/study/${id}/sites/${siteId}/personnel/${person.Id}`);
      return;
    } else {
      this.setState({ person, mode });
    }
  };

  private handleChangeFilter = async (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name } = event.target;
    const value = event.target.value ? trim(event.target.value) : "";
    const { studySitePersonnel, roles } = this.state;
    let newStudySitePersonnel = studySitePersonnel;

    if (this.filterVals.has(name) && this.filterVals.get(name) === value) {
      return;
    }

    value ? this.filterVals.set(name, value) : this.filterVals.delete(name);

    if (this.filterVals.size > 0) {
      this.filterVals.forEach((value, key) => {
        if (key === "Role") {
          value = getDefaultValueForSelect(roles || [], value);
          newStudySitePersonnel = filter(
            newStudySitePersonnel,
            (sc: IBktDataTableStudySitePersonnel) =>
              sc.Roles.some((r: IRole) => r.Name === value)
          );
        } else if (key === "IsActive") {
          newStudySitePersonnel = filter(
            newStudySitePersonnel,
            (sc: IBktDataTableStudySitePersonnel) =>
              sc[key].toString() === value
          );
        } else {
          newStudySitePersonnel = filter(
            newStudySitePersonnel,
            (sc: IBktDataTableStudySitePersonnel) =>
              sc[key]
                ? sc[key]
                    .toString()
                    .toLocaleLowerCase()
                    .indexOf(value.toString().toLocaleLowerCase()) > -1
                : false
          );
        }
      });
      this.setState({ newStudySitePersonnel });
    } else {
      this.setState({ newStudySitePersonnel: studySitePersonnel });
    }
  };

  private deletePersonnel = () => {
    const { person } = this.state;
    const { id, siteId } = this.props.match.params;
    this.setState(() =>
      SagaService.dispatchSaga({
        type: eStudySaga.DELETE_STUDY_SITE_PERSONNEL,
        payload: {
          dto: {
            StudyId: id,
            SiteId: siteId,
            PersonnelId: person ? person.Id : ""
          }
        }
      })
    );
  };
}

export const BktShowStudySitePersonnel = withMddStudy()(
  _BktShowStudySitePersonnel
);
