import { ValidationItem } from "./ValidationItem";
import { REGEX_VALUES } from "../../constants";
import { removeAllSpaces } from "../../utils";

export class Validations {
  public static required = new ValidationItem(
    (eventValue: string = "") => !!eventValue,
    "This field is required"
  );
  public static valid = new ValidationItem(() => true, "");

  public static sponsorNameAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        // eslint-disable-next-line
        RegExp("^[a-zA-Z0-9 &\\-]+$").test(eventValue) ||
        eventValue.length === 0
      );
    },
    "Enter a valid sponsor name"
  );
  public static studyNameAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        // eslint-disable-next-line
        RegExp("^[a-zA-Z0-9 &.//_\\-]+$").test(eventValue) ||
        eventValue.length === 0
      );
    },
    "Enter a valid study name"
  );
  public static internalStudyNameAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        // eslint-disable-next-line
        RegExp("^[a-zA-Z0-9 &.//_\\-]+$").test(eventValue) ||
        eventValue.length === 0
      );
    },
    "Enter a valid internal study name"
  );
  public static studyCodeAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        RegExp("^[a-zA-Z0-9 ]+$").test(eventValue) || eventValue.length === 0
      );
    },
    "Enter a valid study code"
  );
  public static programAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        RegExp("^[a-zA-Z0-9 ]+$").test(eventValue) || eventValue.length === 0
      );
    },
    "Enter a valid program name"
  );
  public static protocolAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        RegExp("^[a-zA-Z0-9 //-]+$").test(eventValue) || eventValue.length === 0
      );
    },
    "Enter a valid protocol"
  );
  public static cityAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        RegExp("^[a-zA-Z0-9 ]+$").test(eventValue) || eventValue.length === 0
      );
    },
    "Enter a valid city name"
  );
  public static zipCodeAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        RegExp("^[a-zA-Z0-9 -]+$").test(eventValue) || eventValue.length === 0
      );
    },
    "Enter a valid zip code"
  );
  // Allowing for the full alphanumeric set to avoid confusion in differing examples and not trying to guess at a full set of possible values
  public static phoneNumberAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        RegExp("^[0-9a-zA-Z \\-()\\–#,.:;?@\\+\\<\\>\\/]+$").test(eventValue) ||
        eventValue.length === 0
      );
    },
    "Enter a valid phone number"
  );
  public static sponsorSiteAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        RegExp("^[a-zA-Z0-9 -]+$").test(eventValue) || eventValue.length === 0
      );
    },
    "Enter a valid sponsor site id"
  );
  public static validEmail = new ValidationItem((eventValue: string = "") => {
    return (
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(eventValue) ||
      eventValue.length === 0
    );
  }, "Enter a valid email address");

  public static validThresholdValue = new ValidationItem(
    (eventValue: string = "") => {
      // Threshold value must be a number between 0-100 (whole numbers only)

      const isNumber = /[0-9]\b$/.test(eventValue);
      if (isNumber) {
        // need to check to make sure they didn't put in a decimal (that's still a valid number)
        const numberVal = parseInt(eventValue, 10);
        if (numberVal === +eventValue) {
          // between 0 and 100?
          if (numberVal >= 0 && numberVal <= 100) {
            return true;
          }
        }
      }
      return false;
    },
    "Value must be a whole number between 0 and 100"
  );

  // Applies to answer for a training course (one value, must be numeric with at most one decimal place)
  public static validAnswerForTrainingCourse = new ValidationItem(
    (eventValue: string = "") => {
      const value = eventValue.trim();

      // If empty it's OK (required validation will catch it if caller wants that)
      if (!value) {
        return true;
      }

      // dashes and commas not allowed
      if (value.includes(",") || value.includes("-") || value.includes(" ")) {
        return false;
      }

      return Validations.isNumberAndNoMultipleDecimals(value);
    },
    "Invalid value"
  );

  // Applies to course score answers, both for the acceptable response
  // and the answer set.
  public static validAnswersForCourseScore = new ValidationItem(
    (eventValue: string = "") => {
      // Only numbers, commas and dashes are allowed so we'll parse the pieces out by the comma and dashes and
      // validate each piece one at a time.

      const value = removeAllSpaces(eventValue);

      // If empty it's OK (required validation will catch it if caller wants that)
      if (!value) {
        return true;
      }

      return Validations.isCourseScoreAnswersValid(value);
    },
    "Invalid value"
  );

  public static validAnswerSet = new ValidationItem(
    (eventValue: string = "") => {
      const value = removeAllSpaces(eventValue);

      // If empty it's OK (validation for required will catch it if caller wants that)
      if (!value) {
        return true;
      }

      const isRange = value.includes("-");
      // Make sure it doesn't contain values and a range
      if (isRange && value.includes(",")) {
        return false;
      }

      let regexToUse;

      if (isRange) {
        // decimal places not allow in a range
        if (value.includes(".")) {
          return false;
        }
        // check for trailing "-"
        if (value.charAt(value.length - 1) === "-") {
          // no trailing - allowed
          return false;
        }
        // check for invalid range (e.g. 7-5)
        const dashParts = value.split("-");
        if (
          parseInt(dashParts[1], 10) < parseInt(dashParts[0], 10) ||
          !dashParts[0]
        ) {
          return false;
        }
        regexToUse = REGEX_VALUES.ANSWER_SET_RANGE;
      } else {
        // Check for multiple decimal places (not allowed)
        // Why'll we're at it we'll check for a valid number too
        // since that's what the regex does.
        const commaParts = value.split(",");
        for (const commaPart of commaParts) {
          if (
            !commaPart ||
            !Validations.isNumberAndNoMultipleDecimals(commaPart)
          ) {
            return false;
          }
        }
        regexToUse = REGEX_VALUES.ANSWER_SET_VALUES;
      }

      // made it through the pre-checks so use the regex to test it
      return RegExp(regexToUse).test(value);
    },
    "Invalid value"
  );

  private static isCourseScoreAnswersValid(value: string) {
    // Get the easy one out of the way first.  If no commas or dashes then just check to see if it's a number.
    if (!value.includes(",") && !value.includes("-")) {
      if (Validations.isNumberAndNoMultipleDecimals(value)) {
        return true;
      } else {
        return false;
      }
    }

    // parse out by comma first
    const commaParts = value.split(",");
    for (const commaPart of commaParts) {
      // if it contains a dash and a decimal point it's invalid (only integer ranges allowed)
      if (commaPart.includes("-") && commaPart.includes(".")) {
        return false;
      }
      // parse by dash for each one
      const dashParts = commaPart.split("-");
      for (const dashPart of dashParts) {
        if (!dashPart || !Validations.isNumberAndNoMultipleDecimals(dashPart)) {
          return false;
        }
      }
      // check for an invalid range (e.g. 5-3 or including decimals)
      for (let partNum = 0; partNum < dashParts.length - 1; partNum++) {
        if (
          parseInt(dashParts[partNum + 1], 10) <
          parseInt(dashParts[partNum], 10)
        ) {
          return false;
        }
      }
    }

    return true;
  }

  private static isNumberAndNoMultipleDecimals(value: string) {
    return RegExp(REGEX_VALUES.NUMBER_AND_NO_MULTIPLE_DECIMALS).test(value);
  }
}
