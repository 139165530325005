import * as React from "react";
import { connect } from "react-redux";
import PersonIcon from "@material-ui/icons/AccountCircle";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import { Divider } from "@material-ui/core";
import { IComment, IUser } from "../../../model";
import { toDateFormat, toTimeFormat } from "../../../utils/functionsHelper";
import { IApplicationState } from "../../../store";
import { MAX_TEXT_LENGTH } from "../../../constants";
import { MddExpandableMultiline } from "../../../components";
import { BktValidatedTextField } from "front-end-lib/core";
import { isLoading } from "../../../utils";
import { eStudySaga } from "../../../sagas";

interface IMddCommentProps {
  comment: IComment;
  user: IUser;
  onEditSave: (commentText: string, id: string) => void;
  // Controls if edit open/close state is maintained by the parent control.
  // If true, onEditOpen and onEditClose functions must be defined, and the
  // parent must pass down an "open" value. This functionality allows for
  // coordination of a group of these comment components.
  // If false (default), opening and closing of edit is controlled within this component.
  parentEditControl?: boolean;
  parentEditOpen?: boolean;
  onEditOpen?: (id: string | undefined) => void;
  onEditClose?: (id: string | undefined) => void;
}

export const MddCommentComponent = (props: IMddCommentProps) => {
  const [editOpen, setEditOpen] = React.useState(false);
  const [editedComment, setEditedComment] = React.useState("");

  const { comment, parentEditControl = false, parentEditOpen = false } = props;
  const displayUsername = comment.userName.split("@")[0];

  React.useEffect(() => {
    // Comment text changed so either we're just getting it for the first time after load
    // or they edited it and saved successfully.  The latter case is the main reason for
    // this - it's the only way to know it was saved OK and we should close the edit.
    // It's fine to do this in the first scenario also since it wouldn't be in edit mode anyway.
    setEditedComment("");
    toggleEdit(false);
    // eslint-disable-next-line
  }, [props.comment.commentText]);

  const toggleEdit = (val: boolean) => {
    const {
      comment,
      parentEditControl = false,
      onEditOpen,
      onEditClose
    } = props;

    if (!parentEditControl) {
      setEditOpen(val);
      return;
    }

    // parent controls open/close of edit so do the callback
    if (val && onEditOpen) {
      onEditOpen(comment.studySitePersonnelCommentId);
    }
    if (!val && onEditClose) {
      onEditClose(comment.studySitePersonnelCommentId);
    }
  };

  const isEditSaveDisabled = () => {
    const { comment } = props;
    return (
      decodeURIComponent(comment.commentText) === editedComment ||
      editedComment.trim().length < 2 ||
      isLoading(`undefined_${eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT}`)
    );
  };

  const handleEditSave = () => {
    const { onEditSave, comment } = props;
    if (onEditSave) {
      onEditSave(editedComment, comment.studySitePersonnelCommentId!);
    }
  };

  const isEditOpen =
    (parentEditControl && parentEditOpen) || (!parentEditControl && editOpen);

  const isEdited =
    new Date(comment.commentDate).getTime() !==
    new Date(comment.commentUpdatedDate).getTime();

  return (
    <React.Fragment>
      <div
        id="commentDiv"
        data-testid="commentDiv"
        className="mdd-comments--comment"
      >
        <div
          id="commentPersonDiv"
          className="mdd-comments--comment--person-div"
        >
          <div
            id="commentPersonIconDiv"
            className="mdd-comments--comment--person-div--icon"
            data-testid="personIcon"
          >
            <PersonIcon fontSize="small" />
          </div>
          <div
            className="mdd-comments--comment--person-div--name"
            id="commentNameDiv"
            data-testid="commentNameDiv"
          >
            <span id="personName">{displayUsername}</span>
          </div>
          <div
            id="commentEditIconDiv"
            className={
              isEditOpen || !comment.isEditable
                ? "displayNone"
                : "mdd-comments--comment--person-div--editicon"
            }
          >
            {comment.isEditable && (
              <EditIcon
                id="commentEditIcon"
                fontSize="small"
                data-testid={isEditOpen ? "" : "editIcon"}
                onClick={() => {
                  setEditedComment(decodeURIComponent(comment.commentText));
                  toggleEdit(true);
                }}
              />
            )}
          </div>
          <div
            id="commentEditingIconsDiv"
            className={
              isEditOpen
                ? "mdd-comments--comment--person-div--editing-icons"
                : "displayNone"
            }
          >
            <span
              className={
                isEditSaveDisabled()
                  ? "mdd-comments--comment--person-div--editing-icons--done-disabled"
                  : "mdd-comments--comment--person-div--editing-icons--done"
              }
            >
              <CheckIcon
                id="saveEditIcon"
                data-testid={isEditOpen ? "saveEditIcon" : ""}
                onClick={() => {
                  if (!isEditSaveDisabled()) {
                    handleEditSave();
                  }
                }}
              />
            </span>
            <span className="mdd-comments--comment--person-div--editing-icons--cancel">
              <CancelIcon
                id="cancelEditIcon"
                data-testid={isEditOpen ? "cancelEditIcon" : ""}
                onClick={() => {
                  setEditedComment("");
                  toggleEdit(false);
                }}
              />
            </span>
          </div>
        </div>
        <div
          id="commentInfoTextDiv"
          className="mdd-comments--comment--info-div"
        >
          <div
            id="commentInfoDiv"
            className="mdd-comments--comment--info-div--text"
            data-testid="commentInfoDiv"
          >
            {`${comment.commentType.label} | ${toDateFormat(
              comment.commentDate
            )} | ${toTimeFormat(comment.commentDate)}`}
            <span
              id="divEdited"
              className={
                isEdited
                  ? "mdd-comments--comment--info-div--edited"
                  : "displayNone"
              }
            >
              (edited)
            </span>
          </div>

          <span className={isEditOpen ? "displayNone" : ""}>
            <MddExpandableMultiline
              displayText={decodeURIComponent(comment.commentText)}
            />
          </span>
          <span className={isEditOpen ? "" : "displayNone"}>
            <BktValidatedTextField
              textFieldProps={{
                textProps: {
                  multiline: true,
                  id: "commentTextField",
                  placeholder: isEditOpen ? "Enter your comment" : "",
                  variant: "outlined",
                  required: false,
                  type: "text",
                  label: "",
                  className: isEditOpen
                    ? "mdd-form--text mdd-comments--edit-comment--input"
                    : "displayNone",
                  disabled: false,
                  inputProps: {
                    id: "txtEditComment",
                    maxLength: MAX_TEXT_LENGTH.COMMENT
                  },
                  rows: 5,
                  rowsMax: 5,
                  value: editedComment,
                  onChange: e => setEditedComment(e.target.value)
                },
                debounceInterval: 0
              }}
            />
          </span>
        </div>
      </div>
      <Divider />
    </React.Fragment>
  );
};

export const MddComment = connect((state: IApplicationState) => ({
  user: state.user
}))(MddCommentComponent);
