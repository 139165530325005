import * as React from "react";
import { IBktDataTableColumnProps } from "front-end-lib";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { QnA } from "../../../components/BktStudy/MddResDisplay/Utils";
import { IPersonnelResScaleExperience } from "../../../model/interfaces";
import {
  getButtonText,
  isEditDisabled,
  toDateFormatNoOffset
} from "../../../utils";

export const scaleDataTableHeaders = (
  questions: string[]
): IBktDataTableColumnProps[] =>
  [...questions, "Status", "Status Date", "Action"].map((question: string) => {
    return {
      label: question,
      tableCellProps: { id: `${question}_scale_header` }
    };
  });

export const getDataTableScales = (
  { items: scalesExperience }: IPersonnelResScaleExperience = { items: [] },
  open: (
    item: IPersonnelResScaleExperience["items"][0] & { scaleName: string }
  ) => void
) => (scales: QnA[][], meta: string[]) =>
  scales.map(scale => {
    const [name, isActive, scaleId] = ["Scale", "isActive", "scaleId"].map(
      question => scale.find(item => item.question === question)?.answer ?? "-"
    );

    const inactiveClassName = isActive ? "" : "mdd-experience--inactive-item";
    const scaleName = isActive ? name : `*${name}`;

    const scaleExperience = scale
      .filter(item => !meta?.includes(item.question))
      .reduce(
        (prev, next) => {
          const answer = next.question === "Scale" ? scaleName : next.answer;
          prev[next.question] = (
            <div
              className={`mdd-experience--tbl-cell ${inactiveClassName}`}
              data-testid={`${name}_${next.question}_scale_testid`}
              title={answer ?? "-"}
            >
              {answer ?? "-"}
            </div>
          );

          return prev;
        },
        { key: name }
      );

    const scaleExperienceStatus =
      scalesExperience.find(m => m.scaleId === scaleId) ||
      ({ status: "Incomplete" } as IPersonnelResScaleExperience["items"][0]);
    const additionalFields = {
      status: (
        <div
          className={`mdd-experience--tbl-cell ${inactiveClassName}`}
          data-testid={`${name}_Status_scale_testid`}
        >
          {scaleExperienceStatus.status}
          {scaleExperienceStatus.comment && (
            <span
              title={decodeURIComponent(scaleExperienceStatus.comment.text)}
              className="mdd-experience--override--cp-comment-icon"
            >
              <FontAwesomeIcon
                icon={faCommentDots}
                data-testid={`${name}_iconComment`}
              />
            </span>
          )}
        </div>
      ),
      statusDate: (
        <div
          className={`mdd-experience--tbl-cell ${inactiveClassName}`}
          data-testid={`${name}_Status Date_scale_testid`}
        >
          {(scaleExperienceStatus.statusDate &&
            toDateFormatNoOffset(scaleExperienceStatus.statusDate)) ||
            "-"}
        </div>
      ),
      action: (
        <Button
          id="btnOverride"
          disabled={isEditDisabled(scaleExperienceStatus)}
          data-testid={`btnOverride_${scaleId}`}
          className="mdd-experience--button"
          color="primary"
          variant="contained"
          onClick={() => {
            open({ ...scaleExperienceStatus, scaleName: name });
          }}
        >
          {getButtonText(scaleExperienceStatus)}
        </Button>
      )
    };

    return { ...scaleExperience, ...additionalFields };
  });
