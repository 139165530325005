import { action } from "typesafe-actions";
import { NotificationActionTypes } from "./types";

export const openSuccessSnackBar = (message: string) =>
  action(NotificationActionTypes.OPEN_SUCCESS_SNACKBAR, message);
export const openErrorSnackBar = (message: string) =>
  action(NotificationActionTypes.OPEN_ERROR_SNACKBAR, message);
export const openInfoSnackBar = (message: string) =>
  action(NotificationActionTypes.OPEN_INFO_SNACKBAR, message);
export const openWarningSnackBar = (message: string) =>
  action(NotificationActionTypes.OPEN_WARNING_SNACKBAR, message);
export const closeSnackBar = () =>
  action(NotificationActionTypes.CLOSE_SNACKBAR);
