import { eFilter, Filter } from "front-end-lib/core";
import { classConstants } from "../../constants";

export const scaleDataTableProps = {
  containerProps: {
    id: "tableWrapper",
    className: "tbl--container",
    style: { boxShadow: "0px 0px 0px 0px" }
  },
  tableProps: { id: "tableContainer" },
  tableHeadProps: { id: "tableHeader", rowProps: { id: "tableHeaderRow" } },
  tableBodyProps: {
    id: "tableBody",
    rowProps: {
      id: "tableBodyRow",
      className: "tbl--body-row mdd-tbl--body-row"
    }
  }
};

export const scaleDataTableHeaders = (
  filterOnChange:
    | ((
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => void)
    | undefined
) => [
  {
    key: "scaleName",
    label: "Scale Name",
    filter: new Filter(eFilter.Text, {
      debounceInterval: 0,
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "scaleNameFilter",
        onChange: filterOnChange,
        name: "Name"
      }
    }),
    tableCellProps: { id: "scaleNameHeader" },
    divProps: { id: "scaleNameHeader_div" },
    sortProp: { sortPropName: "Name", desc: true }
  },
  {
    key: "scaleShortName",
    label: "Short Name",
    filter: new Filter(eFilter.Text, {
      debounceInterval: 0,
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "scaleShortNameFilter",
        onChange: filterOnChange,
        name: "ShortName"
      }
    }),
    tableCellProps: {
      id: "scaleShortNameHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "scaleShortNameHeader_div" }
  },
  {
    key: "actions",
    label: ""
  }
];
