import * as React from "react";
import { eFilter, Filter, SortType } from "front-end-lib/core";
import { classConstants, activeStatuses } from "../../constants";
import { ILookup, IFilter } from "../../model";
import { MddStatus } from "../../components";
import { sortStatus } from "../../utils";

export const studySitesDataTableHeaders = (
  filterOnChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    exactMatch?: boolean
  ) => void,
  countries: ILookup[],
  filterVals: Map<string, IFilter>
) => [
  {
    key: "Site",
    label: "Site",
    sortProp: { sortPropName: "siteName", desc: true },
    filter: new Filter(eFilter.Text, {
      debounceInterval: 0,
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "siteNameFilter",
        onChange: e => filterOnChange(e),
        name: "SiteName"
      }
    }),
    tableCellProps: { id: "siteNameHeader" },
    divProps: { id: "siteNameHeader_div" }
  },
  {
    key: "PI",
    label: "PI",
    filter: new Filter(eFilter.Text, {
      debounceInterval: 0,
      textProps: {
        disabled: false,
        className: classConstants.TEXT_FILTER_CLASS,
        id: "principleInvestigatorFilter",
        onChange: e => filterOnChange(e),
        name: "pi"
      }
    }),
    tableCellProps: { id: "principleInvestigatorHeader" },
    divProps: { id: "principleInvestigatorHeader_div" }
  },
  {
    key: "SponsorSiteID",
    label: "Sponsor Site ID",
    tableCellProps: {
      id: "sponsorSiteIdHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    filter: new Filter(eFilter.Text, {
      debounceInterval: 0,
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "sponsorSiteIdFilter",
        onChange: e => filterOnChange(e),
        name: "SponsorSiteId"
      }
    }),
    divProps: { id: "sponsorSiteHeader_div" }
  },
  {
    key: "Country",
    label: "Country",
    filter: new Filter(eFilter.Select, {
      textProps: {
        debounceInterval: 0,
        id: "countryFilterText",
        name: "countryValue",
        className: "tbl--filter-select",
        onChange: e => filterOnChange(e, true),
        InputLabelProps: { shrink: filterVals.has("countryValue") }
      },
      selectMenuProps: { MenuProps: { className: "bracket-Select" } },
      menuProps: {},
      selectData: countries,
      defaultSelect: true
    }),
    tableCellProps: { id: "countryHeader", className: "tbl--header-cell" },
    divProps: { id: "countryHeader_div" }
  },
  {
    key: "Status",
    label: "Status",
    sortProp: {
      sortPropName: "IsActive",
      desc: false,
      sortType: "Custom" as SortType,
      alg: sortStatus
    },
    filter: new Filter(eFilter.Select, {
      textProps: {
        id: "siteStatusFilterText",
        name: "IsActive",
        className: "tbl--filter-select",
        onChange: e => filterOnChange(e, true),
        InputLabelProps: { shrink: filterVals.has("IsActive") }
      },
      selectMenuProps: { MenuProps: { className: "bracket-Select" } },
      menuProps: {},
      selectData: activeStatuses,
      defaultSelect: true
    }),
    tableCellProps: { id: "siteStatusHeader" },
    divProps: { id: "siteStatus_div" },
    formatProp: {
      formatPropName: "IsActive",
      format: (val: boolean) => <MddStatus isActive={val} />
    }
  }
];
