import throttle from "lodash/throttle";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  getLastActivityTimeFromSHCookie,
  setLastActivityTimeInSHCookie
} from "../../../utils";

interface ISettings {
  /** Number of seconds remaining at which to display a modal 
        warning the user that they will be logged out in n seconds. */
  gracePeriodSeconds: number;

  /** A function which will be called if user don't want to extend timeout.
        it will be called automatically after time is over
     */
  cancel: () => void;

  /** Async function that pings the server to extend the users' session. 
        Must return the configured timeout duration in seconds.
        This function does not need error handling, as the Timeout component will handle errors. */
  keepAlive: () => Promise<number>;

  /** The events which will make the timer update.
        default: ['mousedown', 'keydown'] */
  events?: (keyof DocumentEventMap)[];
}

interface IState {
  /* The seconds left before time is over and 'onCancel' is called 
       Has a value only a 'gracePeriodSeconds' seconds before the time is over. */
  timer: number | null;

  /** A function which should be called if user don't want to extend timeout.
        It will be called automatically after the time is over.
        Can be called to finish countdown anytime (make timer = 0) */
  cancel: () => void;

  /** A function which should be called if user want to extend timeout.
        It will call 'keepAlive' internally.
        Can be called to start the timer over independently of its value  */
  extend: () => void;
}

type TimeoutHook = (settings: ISettings) => IState;
const defaultEvents = ["mousedown", "keydown", "mousemove"];

export const useSlidingExpiration: TimeoutHook = ({
  gracePeriodSeconds,
  keepAlive: keepAliveProps,
  cancel: cancelProps,
  events = defaultEvents
}) => {
  const [timeoutDurationSeconds, setTimeoutDurationSeconds] = useState(0);

  // useRef to avoid unnecessary re-renders util timer becomes visible.
  const secondsRemaining = useRef(0);
  const setSecondsRemaining = useCallback(
    (newValue: number) => {
      secondsRemaining.current = newValue;
    },
    [secondsRemaining]
  );

  // null - timer is active, it will get a value when enters 'gracePeriodSeconds' range,
  // 0 - finished, needs resetting to null
  const [timer, setTimer] = useState<number | null>(null);

  const setTimeRemaining = useCallback(
    (time: number) => {
      setLastActivityTimeInSHCookie(time ? Date.now() : 0);
      setSecondsRemaining(time);
    },
    [setSecondsRemaining]
  );

  const cancel = useCallback(() => {
    setTimeRemaining(0);
    setTimer(0);
    cancelProps();
  }, [cancelProps, setTimeRemaining]);

  const keepAlive = useCallback(async () => {
    try {
      const duration = await keepAliveProps();
      setTimeoutDurationSeconds(duration);
      setTimeRemaining(duration);
      setTimer(null);
    } catch (error) {
      cancel();
    }
  }, [cancel, keepAliveProps, setTimeRemaining]);

  const userActive = useMemo(
    () => throttle(keepAlive, 5000, { trailing: false }),
    [keepAlive]
  );

  // init
  useEffect(() => {
    (async () => {
      await keepAlive();
    })();
  }, [keepAlive]);

  // add event listeners only when timer is null
  useEffect(() => {
    const eventsAttached = [];

    if (timer === null) {
      for (let i = 0; i < events.length; i += 1) {
        document.addEventListener(events[i], userActive);
        eventsAttached.push(events[i]);
      }
    }

    return () => {
      for (let i = 0; i < eventsAttached.length; i += 1) {
        document.removeEventListener(events[i], userActive);
      }
    };
  }, [events, timer, userActive]);

  // attach countdown interval
  useEffect(() => {
    if (timeoutDurationSeconds === 0) return undefined;

    const intervalCallback = () => {
      const newSecondsRemaining =
        timeoutDurationSeconds -
        Math.round(
          (Date.now() - Number(getLastActivityTimeFromSHCookie())) / 1000
        );

      const timerIsInGracePeriodRange =
        newSecondsRemaining <= gracePeriodSeconds && newSecondsRemaining >= 0;
      setTimer(timerIsInGracePeriodRange ? newSecondsRemaining : null);
      setSecondsRemaining(newSecondsRemaining);

      if (newSecondsRemaining <= 0) {
        cancel();
      }
    };

    const sub = timer !== 0 ? setInterval(intervalCallback, 1000) : null;

    return () => {
      if (sub) {
        clearInterval(sub);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutDurationSeconds, !timer, gracePeriodSeconds, cancel]);

  return {
    timer: timer && timer >= 0 ? timer : null,
    cancel,
    extend: keepAlive
  };
};
