import * as React from "react";
import { eFilter, Filter, ISelectData, SortType } from "front-end-lib/core";
import { IBktDataTablePerson } from "../../components";
import { classConstants } from "../../constants";
import InactiveIcon from "@material-ui/icons/Warning";
import { rolesIncludePI, sortStatus } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

const personnelStatuses: ISelectData[] = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" }
];

const getFormattedStatus = (val: boolean) => {
  return val ? (
    "Active"
  ) : (
    <span className="mdd-icon-span--text">
      Inactive
      <span className="mdd-icon-span--icon">
        <InactiveIcon fontSize="small" />
      </span>
    </span>
  );
};

const getActions = (
  person: IBktDataTablePerson,
  onActionClick: (person: IBktDataTablePerson, mode: "Edit" | "Delete") => void,
  piGuid: string | undefined
) => {
  const isPI: boolean = rolesIncludePI(person.Roles, piGuid);
  return (
    <div className="tbl--body-row-actions">
      <span onClick={() => onActionClick(person, "Edit")}>
        <FontAwesomeIcon
          icon={faPenToSquare}
          fixedWidth
          fontSize={"1.1rem"}
          id="squareEditIcon"
          data-testid="squareEditIcon"
          title="Edit"
        />
      </span>
      <span
        onClick={() => onActionClick(person, "Delete")}
        className={isPI ? "notVisible" : ""}
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          fixedWidth
          fontSize={"1.1rem"}
          id="deleteCircleIcon"
          data-testid="deleteCircleIcon"
          title="Delete"
        />
      </span>
    </div>
  );
};

export const studySitePersonnelDataTableProps = {
  containerProps: {
    id: "tableWrapper",
    className: "tbl--container",
    style: { boxShadow: "0px 0px 0px 0px" }
  },
  tableProps: { id: "tableContainer" },
  tableHeadProps: { id: "tableHeader", rowProps: { id: "tableHeaderRow" } },
  tableBodyProps: {
    id: "tableBody",
    rowProps: {
      id: "tableBodyRow",
      className: "tbl--body-row mdd-tbl--body-row"
    }
  }
};

export const studySitePersonnelDataTableHeaders = (
  filterOnChange:
    | ((
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => void)
    | undefined,
  roleData: any,
  piGuid: string | undefined,
  filterVals: Map<string, string>,
  onActionClick: (person: IBktDataTablePerson, mode: "Edit" | "Delete") => void
) => [
  {
    key: "FirstName",
    label: "First Name",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "firstNameFilter",
        onChange: filterOnChange,
        name: "FirstName"
      }
    }),
    tableCellProps: { id: "firstNameHeader" },
    divProps: { id: "firstNameHeader_div" },
    sortProp: { sortPropName: "FirstName", desc: true }
  },
  {
    key: "LastName",
    label: "Last Name",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "lastNameFilter",
        onChange: filterOnChange,
        name: "LastName"
      }
    }),
    tableCellProps: { id: "lastNameHeader" },
    divProps: { id: "lastNameHeader_div" },
    sortProp: { sortPropName: "LastName", desc: false }
  },
  {
    key: "UserName",
    label: "Username",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "userNameFilter",
        onChange: filterOnChange,
        name: "UserName"
      }
    }),
    tableCellProps: {
      id: "userNameHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "userNameHeader_div" }
  },
  {
    key: "Email",
    label: "Email",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "emailFilter",
        onChange: filterOnChange,
        name: "Email"
      }
    }),
    tableCellProps: {
      id: "emailHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "emailHeader_div" }
  },
  {
    key: "Role",
    label: "Role",
    filter: new Filter(eFilter.Select, {
      textProps: {
        id: "Role",
        className: "tbl--filter-select",
        disabled: roleData === undefined,
        InputLabelProps: { shrink: filterVals.has("Role") },
        onChange: filterOnChange
      },
      selectMenuProps: { MenuProps: { className: "bracket-Select" } },
      menuProps: {},
      selectData: roleData || [],
      defaultSelect: true
    }),
    tableCellProps: { id: "roleHeader" },
    divProps: { id: "roleHeader_div" },
    sortProp: { sortPropName: "Roles", desc: true }
  },
  {
    key: "Status",
    label: "Status",
    sortProp: {
      sortPropName: "IsActive",
      desc: false,
      sortType: "Custom" as SortType,
      alg: sortStatus
    },
    filter: new Filter(eFilter.Select, {
      textProps: {
        id: "personnelStatus",
        name: "IsActive",
        className: "tbl--filter-select",
        onChange: filterOnChange,
        InputLabelProps: { shrink: filterVals.has("IsActive") }
      },
      selectMenuProps: { MenuProps: { className: "bracket-Select" } },
      menuProps: {},
      selectData: personnelStatuses,
      defaultSelect: true
    }),
    tableCellProps: { id: "personnelStatusHeader" },
    divProps: { id: "personnelStatus_div" },
    formatProp: {
      formatPropName: "IsActive",
      format: (val: boolean) => getFormattedStatus(val)
    }
  },
  {
    key: "Actions",
    label: "Actions",
    tableCellProps: { id: "actions" },
    divProps: { id: "actions_div" },
    formatProp: {
      formatPropName: "person",
      format: (person: IBktDataTablePerson) =>
        getActions(person, onActionClick, piGuid)
    }
  }
];
