import { ILookup } from "../../model";

export enum eLookupActionTypes {
  SET_LOOKUP = "@@lookup/SET_LOOKUP"
}

export interface ILookupState {
  countries: ILookup[];
  demographics: ILookup[];
  indications: ILookup[];
  specialties: ILookup[];
  therapeuticAreas: ILookup[];
  qualificationTypes: ILookup[];
}
