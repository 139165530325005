import { ILookupState, eLookupActionTypes } from "./types";

const initialState: ILookupState = {
  countries: [],
  demographics: [],
  indications: [],
  specialties: [],
  therapeuticAreas: [],
  qualificationTypes: []
};

export const lookupReducer: any = (
  state: ILookupState = initialState,
  action: any
) => {
  switch (action.type) {
    case eLookupActionTypes.SET_LOOKUP: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};
