import { trim } from "lodash";
import { IStudyDTO } from "../../../model";

export class StudyDTO implements IStudyDTO {
  public studyId?: string;
  public studyName: string;
  public internalStudyName: string;
  public projectNumber: string;
  public sponsorId: string;

  constructor(
    studyName: string,
    internalStudyName: string,
    projectNumber: string,
    sponsorId: string,
    studyId?: string
  ) {
    this.studyId = trim(studyId || "");
    this.studyName = trim(studyName);
    this.internalStudyName = trim(internalStudyName);
    this.projectNumber = trim(projectNumber);
    this.sponsorId = trim(sponsorId);
  }
}
