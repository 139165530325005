// @ts-nocheck
import { history } from "../utils";

/**
 * Implement function to initialize Redux store, bootstrap middlewares and  combine with reducers
 */
import { Store, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

/**
 * Connect react router provides redux middleware which connects to react-router instance
 */
import { routerMiddleware } from "connected-react-router";

/**
 * Useful with Redux Dev Tools so we can pass middleware along with it
 */
import { composeWithDevTools } from "redux-devtools-extension";

/**
 * pass in history in react-router
 */
import { History } from "history";

/**
 * Import the state interface and combined reducers/sagas
 */
import { IApplicationState, rootReducer, rootSaga } from "../store";

/**
 *
 */
export default function configureStore(
  history: History,
  initialState: IApplicationState
): Store<IApplicationState> {
  /** create composing function for middlewares */
  const composeEnhancers = composeWithDevTools({});
  /** create redux-saga middleware */
  const sagaMiddleware = createSagaMiddleware();
  // const loggerMiddleware = createLogger();

  /** create store with combined reducers/sagas and initial redux state that is passed from entry point */
  const store = createStore(
    rootReducer(history),
    initialState as any, // had to add to get this to compile.  Do we even need/use initialReduxState?
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);
  return store;
}

export const isLoading = (...args: string[]) => {
  const { loadingKeys } = store.getState().util;
  return args.filter((k: string) => loadingKeys.indexOf(k) > -1).length > 0;
};

const initialState = window.initialReduxState;
export const store = configureStore(history, initialState);
