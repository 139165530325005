import { Site } from "../../model/classes/site";
import { ISite } from "../../model";

const initialState: ISiteState = {
  currentSite: new Site()
};

export interface ISiteState {
  currentSite: ISite;
}

export enum eSiteActionTypes {
  SET_SITE = "@@site/SET_SITE"
}

export const setSite = (site: ISite) => ({
  type: eSiteActionTypes.SET_SITE,
  payload: site
});

export const siteReducer: any = (
  state: ISiteState = initialState,
  action: any
) => {
  switch (action.type) {
    case eSiteActionTypes.SET_SITE: {
      return { ...state, ...{ currentSite: action.payload } };
    }
    default: {
      return state;
    }
  }
};
