import * as React from "react";
import {
  IBktValidatedTextFieldProps,
  BktValidatedTextField,
  BktTextFieldProps
} from "front-end-lib/core";

interface IMddValidatedTextFieldProps extends IBktValidatedTextFieldProps {
  id: string;
  labelProps?: {
    labelText?: string | React.ReactNode;
    className?: string;
  };
}

export const MddValidatedTextField = (props: IMddValidatedTextFieldProps) => {
  const { id, labelProps, textFieldProps, ...other } = props;

  const defaultTextProps = {
    id: `${id}_value`,
    type: "text",
    variant: "outlined",
    placeholder: ""
  };

  // include our default props but allow the caller to override if desired
  const mergedTextFieldProps = {
    ...textFieldProps,
    textProps:
      textFieldProps && textFieldProps.textProps
        ? {
            ...defaultTextProps,
            ...textFieldProps.textProps
          }
        : { ...defaultTextProps }
  } as BktTextFieldProps;

  return (
    <div className="mdd-validated-text-field-div--container" key={`${id}_key`}>
      {labelProps && labelProps.labelText && (
        <div
          className={
            labelProps && labelProps.className ? labelProps.className : ""
          }
          id={`${id}_label`}
          data-testid={`${id}_label`}
        >
          {labelProps.labelText}
        </div>
      )}
      <BktValidatedTextField
        key={`${id}_txtField`}
        {...other}
        textFieldProps={{ ...mergedTextFieldProps }}
      />
    </div>
  );
};
