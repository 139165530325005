import * as React from "react";
import { ITabData, eResConfig } from "../../../store/resConfig/types";
import { isLoading } from "../../../utils/store";
import { MddPanelContainer, MddProgress } from "../../common";
import { MddResViewDemographics } from "./MddResViewDemographics";
import { MddResViewGeneral } from "./MddResViewGeneral";
import { MddResViewScales } from "./MddResViewScales";
import { MddResViewEducation } from "./MddResViewEducation";
import { MddResViewIndications } from "./MddResViewIndications";

interface IMddResViewDetailsProps {
  demographics: ITabData;
  education: ITabData;
  general: ITabData;
  indications: ITabData;
  scales: ITabData;
  studyId: string;
}

export const MddResViewDetails = ({
  demographics,
  education,
  general,
  indications,
  scales,
  studyId
}: IMddResViewDetailsProps) => {
  const inProgress = isLoading(
    `undefined_${eResConfig.GET_RES_CONFIG}`,
    `_${eResConfig.GET_DEMOGRAPHICS}`,
    `_${eResConfig.GET_INDICATIONS}`
  );

  return (
    <React.Fragment>
      <MddProgress inProgress={inProgress} spinnerId="loadRes" />
      <MddPanelContainer
        className="mdd-res-config-view--panel mdd-res-config-view--panel-format-left"
        panels={[
          {
            size: "twothirds",
            border: false,
            className: "mdd-res-config-view--panel-format",
            id: "viewRes-leftpanels",
            content: (
              <MddPanelContainer
                className="mdd-res-config-view--panel"
                id="viewRes-leftpanelContainer"
                data-testid="viewRes-leftpanelContainer"
                panels={[
                  {
                    size: "half",
                    className: "mdd-res-config-view--panel-format",
                    content: (
                      <MddResViewDemographics demographicFields={general} />
                    )
                  },

                  {
                    size: "half",
                    className: "mdd-res-config-view--panel-format",
                    content: <MddResViewEducation education={education} />
                  },
                  {
                    size: "half",
                    className: "mdd-res-config-view--panel-format",
                    content: <MddResViewGeneral demographics={demographics} />
                  },
                  {
                    size: "half",
                    className: "mdd-res-config-view--panel-format",
                    content: (
                      <MddResViewIndications
                        indications={indications}
                        demographics={demographics}
                      />
                    )
                  }
                ]}
              ></MddPanelContainer>
            )
          },
          {
            size: "onethird",
            className: "mdd-res-config-view--panel-format-right",
            content: <MddResViewScales scales={scales} id={studyId} />
          }
        ]}
      ></MddPanelContainer>
    </React.Fragment>
  );
};
