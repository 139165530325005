import * as React from "react";
import { IQuestionGroup } from "../../../model/interfaces/IPersonnelCpResExperience";

export interface QnA {
  question: string;
  answer: string;
}

export const getQuestionsAndAnswers = (data: IQuestionGroup) => {
  const questions = [...data.questions, ...data.meta];
  return data.answers.map(answerGroup =>
    answerGroup.map((answer, index) => ({
      question: questions[index],
      answer
    }))
  );
};

export const getAnswersWithSeparator = (data: string[][]) =>
  data.length > 0
    ? data
        .map(answer => answer.join(", "))
        .map((item, index) => (
          <div
            key={`key_${item}_${index}`}
            className="mdd-experience--panel--content--text"
            data-testid={`${item}_${index}_testid`}
          >
            {item}
          </div>
        ))
    : "-";
