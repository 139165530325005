import { omit, pick, map } from "lodash";
import { ConfigOptions } from "../config";
import { Scale, MasterScale, IScale, IScaleDTO } from "../model";
import { toQueryParams, callApi } from "../utils";
import { eHTTPMethods } from "../constants";

export abstract class ScaleService {
  public static async getScales(scale: IScale) {
    const { Name, ShortName } = scale;
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.scaleApiPath}/${
        Name || ShortName
          ? toQueryParams(pick(scale, ["Name", "ShortName"]))
          : ""
      }`
    );
  }

  public static async getScale(scaleId: string): Promise<IScale> {
    const val = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.scaleApiPath}/${scaleId}`
    );
    return Object.assign(new Scale(), val) as IScale;
  }

  public static async modifyScale(
    payload: IScaleDTO
  ): Promise<IScale | boolean> {
    const { id } = payload;
    const val = await callApi(
      id ? eHTTPMethods.PUT : eHTTPMethods.POST,
      `${ConfigOptions.scaleApiPath}${id ? `/${id}` : ""}`,
      omit(payload, "id")
    );
    return Object.assign(new Scale(), val) as IScale;
  }

  public static async fetchMasterScales(): Promise<MasterScale[]> {
    const val = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.scaleApiPath}/${ConfigOptions.MASTER_SCALES}`
    );
    return map(val, (ms: MasterScale) =>
      Object.assign(new MasterScale(), ms)
    ) as MasterScale[];
  }
}
