import * as React from "react";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {
  MddDialog,
  MddDialogButtons,
  MddValidatedTextField
} from "../../../../components";
import {
  Validations,
  IScoreResponse,
  ICourseAcceptableScore
} from "../../../../model";
import { MAX_TEXT_LENGTH } from "../../../../constants";

interface IMddEditScoresDialog {
  open: boolean;
  currentScore: ICourseAcceptableScore | null;
  isMasterScores: boolean;
  onClose: () => void;
  onUpdate: (
    isScoreChanged: boolean,
    updatedScore: IScoreResponse,
    isAnswerSetChanged: boolean,
    updatedAnswerSet: IScoreResponse
  ) => void;
}

export const MddEditScoresDialog = (props: IMddEditScoresDialog) => {
  const { open, onClose, onUpdate, currentScore, isMasterScores } = props;

  const [hasResponseValError, setHasResponseValError] = React.useState(false);
  const [hasAnswerSetValError, setHasAnswerSetValError] = React.useState(false);
  const [response, setResponse] = React.useState(
    currentScore && currentScore.acceptableResponse
      ? currentScore.acceptableResponse
      : ""
  );
  const [answerSet, setAnswerSet] = React.useState(
    currentScore && currentScore.answerSet ? currentScore.answerSet : ""
  );

  const handleClose = () => {
    setResponse("");
    setAnswerSet("");
    onClose();
  };

  const isSomethingChanged = () => {
    return isAnswerSetChanged() || isResponseChanged();
  };

  const isAnswerSetChanged = () => {
    return answerSet !== currentScore!.answerSet;
  };
  const isResponseChanged = () => {
    return response !== currentScore!.acceptableResponse;
  };

  const renderUpdateDialogContent = () => {
    return (
      <React.Fragment>
        <div id="divUpdatedResponse" data-testid="divUpdatedResponse">
          <MddValidatedTextField
            id="txtUpdatedResponse"
            data-testid="txtUpdatedResponse"
            labelProps={{
              labelText: (
                <span id="updatedResponseHdr" data-testid="updatedResponseHdr">
                  Updated Response
                  <span
                    className="mdd-edit-course-scores--info-icon"
                    id="responseHelperText"
                    data-testid="responseHelperText"
                    title={`Please enter individual responses with ${String.fromCharCode(
                      13
                    )}a comma in between (e.g. 1,4,6) or a ${String.fromCharCode(
                      13
                    )}range of responses (e.g. 2-4)`}
                  >
                    <InfoIcon
                      fontSize="small"
                      id="iconInfoPassing"
                      data-testid="iconInfoPassing"
                    />
                  </span>
                </span>
              )
            }}
            textFieldProps={{
              debounceInterval: 0,
              textProps: {
                className: "mdd-edit-course-scores--updatedResponse",
                autoFocus: true,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  setResponse(event.target.value);
                },
                value: response,
                id: "txtUpdatedResponse_value",
                inputProps: {
                  maxLength: MAX_TEXT_LENGTH.COURSE_ACCEPTABLE_RESPONSE
                }
              }
            }}
            validationForOnBlur={[
              Validations.required,
              Validations.validAnswersForCourseScore
            ]}
            validationerror={(validationError: boolean) =>
              setHasResponseValError(validationError)
            }
          />
        </div>
        {isMasterScores && (
          <div id="divUpdatedAnswerSet" data-testid="divUpdatedAnswerSet">
            <MddValidatedTextField
              id="txtUpdatedAnswerSet"
              data-testid="txtUpdatedAnswerSet"
              labelProps={{
                labelText: (
                  <div
                    className="mdd-edit-course-scores--updatedAnswerSet"
                    id="updatedAnswerSetHdr"
                    data-testid="updatedAnswerSetHdr"
                  >
                    Updated Answer Set
                  </div>
                )
              }}
              textFieldProps={{
                debounceInterval: 0,
                textProps: {
                  className: "mdd-edit-course-scores--updatedResponse",
                  autoFocus: false,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setAnswerSet(event.target.value);
                  },
                  value: answerSet,
                  id: "txtUpdatedAnswerSet_value",
                  inputProps: {
                    maxLength: MAX_TEXT_LENGTH.COURSE_ANSWER_SET
                  }
                }
              }}
              validationForOnBlur={[
                Validations.required,
                Validations.validAnswerSet
              ]}
              validationerror={(validationError: boolean) =>
                setHasAnswerSetValError(validationError)
              }
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <MddDialog
      showCloseButton={true}
      onCloseClick={handleClose}
      dialogProps={{
        id: "divUpdateScoreDialog",
        open,
        maxWidth: "xs",
        fullWidth: true
      }}
      dialogActionProps={{ id: "divUpdateScoresAction" }}
      dialogContentProps={{
        id: "divUpdateScoresContent"
      }}
      dialogTitleProps={{
        id: "divUpdateScoresTitle",
        title: "Update Scores"
      }}
      dialogContent={renderUpdateDialogContent()}
      dialogActions={
        <MddDialogButtons
          saveButtonText="Update"
          saveButtonProps={{
            disabled:
              hasResponseValError ||
              hasAnswerSetValError ||
              !response ||
              !answerSet ||
              !isSomethingChanged(),
            onClick: () => {
              onUpdate(
                isResponseChanged(),
                {
                  value: response,
                  questionId: currentScore!.courseQuestionId
                },
                isAnswerSetChanged(),
                { value: answerSet, questionId: currentScore!.courseQuestionId }
              );
              handleClose();
            }
          }}
          closeButtonProps={{
            onClick: handleClose
          }}
        />
      }
    />
  );
};
