import { omit, assign, pick } from "lodash";
import { SagaService, IAction } from "front-end-lib/core";
import { ConfigOptions, mockBulkLPAssignData } from "../config";
import {
  Study,
  ClinicalData,
  StudyProtocol,
  Site,
  StudySite,
  StudyScale,
  StudySitePersonnelDTO,
  Person,
  StudyQualification,
  IStudy,
  IStudyDTO,
  IClinicalData,
  IStudyProtocol,
  IStudyProtocolDTO,
  ISite,
  IStudySite,
  IScale,
  IStudyScale,
  IPersonnelPayload,
  IStudySitePersonnelDTO,
  IStudySiteDTO,
  Role,
  IStudyQualification,
  IStudyPersonPayload,
  ILearningPlan,
  IUpdateStudySitePersonDTO,
  IStudySitePersonCommentDTO,
  IStudySitePersonExperienceDTO,
  IStudyPersonnelCoursePayload,
  IComment,
  IScaleExperiencePayload,
  IAssignLpPayload,
  ICourseStatusPayload,
  IStudyCourse,
  IStudyCountryScorePayload,
  ICourseScoresDetails,
  ICourseThresholdPayload,
  IAcceptableScoresPayload,
  IPersonnelAtSitePayload,
  IPersonnelAtSite,
  ISaveStudySitePersonnelPayload,
  ILearningPlanUnassignPayload,
  ISaveQualificationStatusResponse,
  ISaveQualificationStatusPayload,
  IBulkLpAssignPayload,
  ICountryQuestionsPayload,
  IGetTrainingCourseScoresPayload,
  ISaveTrainingCourseAnswersPayload,
  IManualTrainingQuestionAnswer,
  IDeleteManualScoresPayload
} from "../model";
import { eStudySaga } from "../sagas";
import { eStudyActionTypes } from "../store";
import { store, callApi } from "../utils";
import { eApiNames, eHTTPMethods } from "../constants";

interface ISaveStudySitePayload {
  newStudySite: IStudySite;
  existingStudySite: boolean;
}

interface ISaveStudyScalePayload {
  newStudyScale: StudyScale;
  existingStudyScale: boolean;
}

export abstract class StudyService {
  public static async getStudies(payload: any) {
    return (
      await callApi(
        eHTTPMethods.POST,
        ConfigOptions.searchStudyApiPath,
        payload
      )
    ).map((st: any) => Object.assign(new Study(), st));
  }

  public static async getStudy(payload: string): Promise<IStudy> {
    const study = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${payload}`
    );

    store.dispatch({ type: eStudyActionTypes.SET_STUDY, payload: study });
    return study;
  }

  public static async getStudyResConfiguration(
    payload: string
  ): Promise<{
    configurationType: string;
    isPublishedForClinicalProfile: boolean;
  }> {
    const resConfig = await callApi(
      eHTTPMethods.GET,
      `/studies/${payload}/configuration/res`,
      undefined,
      undefined,
      eApiNames.STUDY_API
    );

    store.dispatch({
      type: eStudyActionTypes.SET_STUDY_RES_CONFIG,
      payload: resConfig.configurationType
    });
    return resConfig;
  }

  public static async getStudyCourses(
    studyId: string
  ): Promise<IStudyCourse[]> {
    return (await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${studyId}/scores`
    )) as IStudyCourse[];
  }

  public static async getScoringCourseDetails(
    payload: IStudyCountryScorePayload
  ) {
    const { studyId, courseId } = payload;
    return (await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.scoreApiPath}/course/${courseId}/study/${studyId}`
    )) as ICourseScoresDetails[];
  }
  public static async getScoringCourseDetailsLegacy(
    payload: IStudyCountryScorePayload
  ) {
    const { studyId, studyComponentId } = payload;
    return (await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.scoreApiPath}/studycomponent/${studyComponentId}/study/${studyId}`
    )) as ICourseScoresDetails[];
  }

  public static async modifyStudy(
    payload: IStudyDTO
  ): Promise<IStudy | boolean> {
    const { studyId } = payload;
    const retVal = await callApi(
      studyId ? eHTTPMethods.PUT : eHTTPMethods.POST,
      `${ConfigOptions.studyApiPath}${studyId ? `/${studyId}` : ""}`,
      studyId ? payload : omit(payload, "studyId")
    );
    // This needs to be moved. We should not be dispatching an action when modifying a study.
    store.dispatch({
      type: eStudyActionTypes.SET_STUDY,
      payload: Object.assign(new Study(), studyId ? payload : retVal)
    });
    return retVal;
  }

  public static async fetchClinicalStudyData(
    payload: string
  ): Promise<IClinicalData> {
    const val = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.clinicalDataStudyApiPath}/${payload}`
    );
    return Object.assign(new ClinicalData(), val) as IClinicalData;
  }

  public static async saveClinicalStudyData(
    payload: IClinicalData
  ): Promise<IClinicalData> {
    const val = await callApi(
      eHTTPMethods.PUT,
      ConfigOptions.clinicalDataStudyApiPath,
      payload,
      "Save failed"
    );
    return Object.assign(new ClinicalData(), val) as IClinicalData;
  }

  public static async fetchStudyProtocolsData(
    payload: string
  ): Promise<IStudyProtocol[]> {
    return ((await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.getStudyProtocolsApiPath}/${payload}`
    )) as IStudyProtocol[]).map((st: IStudyProtocol) =>
      Object.assign(new StudyProtocol(), st)
    );
  }

  public static async saveStudyProtocol(
    payload: IStudyProtocolDTO
  ): Promise<IStudyProtocol | boolean> {
    const { id } = payload;
    const val = await callApi(
      id ? eHTTPMethods.PUT : eHTTPMethods.POST,
      id
        ? ConfigOptions.putStudyProtocolApiPath
        : ConfigOptions.poststudyProtocolApiPath,
      id ? payload : { ...{ id }, ...payload }
    );
    return Object.assign(new StudyProtocol(), val) as IStudyProtocol;
  }

  public static deleteStudySite(payload: IStudySite): Promise<IStudySite> {
    return callApi(
      eHTTPMethods.DELETE,
      `/studies/${payload.StudyId}/sites/${payload.SiteId}`,
      undefined,
      undefined,
      eApiNames.STUDY_API
    );
  }

  public static deleteStudyScale(payload: IStudyScale): Promise<IStudyScale> {
    return callApi(
      eHTTPMethods.DELETE,
      `/studies/${payload.StudyId}/scales/${payload.ScaleId}`,
      undefined,
      undefined,
      eApiNames.STUDY_API
    );
  }

  public static async getStudySites(payload: string) {
    const resultValue = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.searchStudySitesApiPath}/${payload}`
    );
    let formattedSites: ISite[];
    formattedSites = resultValue.map((element: any) => {
      return assign(new Site(), element);
    });
    return formattedSites;
  }

  public static async saveStudySite(payload: ISaveStudySitePayload) {
    const {
      existingStudySite,
      newStudySite: {
        StudyId,
        SiteId,
        SponsorSiteId,
        PrimaryInvestigator,
        IsActive,
        Protocols
      }
    } = payload;
    const dto = {
      ...(existingStudySite
        ? { primaryInvestigatorUsername: PrimaryInvestigator?.UserName }
        : {
            studyId: StudyId,
            siteId: SiteId,
            primaryInvestigator: {
              key: pick(PrimaryInvestigator, ["UserName", "Type"])
            }
          }),
      sponsorSiteId: SponsorSiteId,
      isActive: IsActive,
      protocols: Protocols.map(prot => prot.id)
    };

    const result = existingStudySite
      ? await callApi(
          eHTTPMethods.PUT,
          `/studies/${StudyId}/sites/${SiteId}`,
          dto,
          undefined,
          eApiNames.STUDY_API
        )
      : await callApi(
          eHTTPMethods.POST,
          `${ConfigOptions.studyApiPath}/${StudyId}/Site`,
          dto
        );

    return Object.assign(new StudySite(), result) as IStudySite;
  }

  public static async saveStudyScale(payload: ISaveStudyScalePayload) {
    const { existingStudyScale, newStudyScale } = payload;

    const result = existingStudyScale
      ? await callApi(
          eHTTPMethods.PUT,
          `/studies/${newStudyScale.StudyId}/scales/${newStudyScale.ScaleId}`,
          { isActive: newStudyScale.IsActive },
          undefined,
          eApiNames.STUDY_API
        )
      : await callApi(
          eHTTPMethods.POST,
          `/studies/${newStudyScale.StudyId}/scales`,
          { scaleId: newStudyScale.ScaleId },
          undefined,
          eApiNames.STUDY_API
        );

    return Object.assign(new StudyScale(), result) as IStudyScale;
  }

  public static async getStudySite(payload: IStudySiteDTO) {
    const result = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${payload.StudyId}/Site/${payload.SiteId}`
    );
    return Object.assign(new StudySite(), result) as IStudySite;
  }

  public static async getStudyScales(studyId: string): Promise<IScale[]> {
    const scales = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${studyId}/scale`
    );
    return scales.map(
      (sc: IStudyScale) => Object.assign(new StudyScale(), sc) as IStudyScale
    );
  }

  // Personnel
  public static async getStudySitePersonnel(payload: IPersonnelPayload) {
    const { dto } = payload;
    const res = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${dto.StudyId}/Site/${dto.SiteId}/personnel`
    );
    return res.map((x: any) =>
      Object.assign(new Person(), omit(x.person, "personnelId"), {
        roles: (x.roles || []).map((r: any) => Object.assign(new Role(), r)),
        protocols: (x.protocols || []).map((p: any) =>
          Object.assign(new StudyProtocol(), p)
        ),
        id: x.person.personnelId,
        isActive: x.isActive
      })
    );
  }

  public static async fetchStudySitePerson(payload: IPersonnelPayload) {
    const { dto } = payload;
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${dto.StudyId}/Site/${dto.SiteId}/personnel/${dto.PersonnelId}`
    );
  }

  public static async fetchStudySitePersonByKey(payload: IStudyPersonPayload) {
    const body = payload.key;
    const path = `${ConfigOptions.studyApiPath}/${payload.studyId}/personnel`;
    return await callApi(eHTTPMethods.POST, path, body);
  }

  public static async saveStudySitePersonComment(
    payload: IStudySitePersonCommentDTO
  ) {
    const { studyId, personnelId, commentText } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/Personnel/${personnelId}/comment`;
    const body = { commentText: commentText };
    const result: IComment = await callApi(eHTTPMethods.POST, path, body);
    return result;
  }
  public static async updateStudySitePersonComment(
    payload: IStudySitePersonCommentDTO
  ) {
    const { studyId, personnelId, commentId, commentText } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/Personnel/${personnelId}/comment/${commentId}`;
    const body = { commentText };
    const result: IComment = await callApi(eHTTPMethods.PUT, path, body);
    return result;
  }
  public static async getStudySitePersonComments(
    payload: IStudySitePersonCommentDTO
  ) {
    const { studyId, personnelId } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/Personnel/${personnelId}/comment`;
    return await callApi(eHTTPMethods.GET, path);
  }

  public static async getStudySitePersonExperience(
    payload: IStudySitePersonExperienceDTO
  ) {
    const { studyId, siteId, personnelId } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/Site/${siteId}/Personnel/${personnelId}/experience`;
    return await callApi(eHTTPMethods.GET, path);
  }

  public static async getStudySitePersonScaleExperience(
    payload: IStudySitePersonExperienceDTO
  ) {
    const { studyId, siteId, personnelId } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/Site/${siteId}/Personnel/${personnelId}/scaleexperience`;
    return await callApi(eHTTPMethods.GET, path);
  }

  public static getScaleExperienceNextAvailableStatuses({
    studyId,
    personnelId,
    courseId
  }: IStudyPersonnelCoursePayload) {
    const uri = `/studies/${studyId}/personnel/${personnelId}/scale-experience-statuses/${courseId}/next-available`;
    return callApi(
      eHTTPMethods.GET,
      uri,
      undefined,
      undefined,
      eApiNames.STUDY_API
    );
  }

  public static async getQualificationNextAvailableStatuses(
    payload: ISaveQualificationStatusPayload
  ) {
    const { studyId, personnelId, userStudyQualificationStatusId } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/personnel/${personnelId}/userstudyqualificationstatus/${userStudyQualificationStatusId}/NextAvailableStatusesForTraining`;
    return await callApi(eHTTPMethods.GET, path);
  }

  public static async saveQualificationStatus(
    payload: ISaveQualificationStatusPayload
  ) {
    const {
      studyId,
      personnelId,
      userStudyQualificationStatusId,
      studyQualificationId,
      status,
      isStatusChanged,
      commentText,
      statusDate = null
    } = payload;
    const path = `${
      ConfigOptions.studyApiPath
    }/${studyId}/personnel/${personnelId}/training/userstudyqualificationstatus/${userStudyQualificationStatusId}${
      !isStatusChanged ? "/overridedate" : ""
    }`;
    const body = {
      studyQualificationStatus: status,
      commentText,
      studyQualificationId,
      overrideStatusDate: statusDate
    };
    return (await callApi(
      eHTTPMethods.PUT,
      path,
      body
    )) as ISaveQualificationStatusResponse;
  }

  public static async saveExperienceScaleStatus({
    studyId,
    personnelId,
    courseId,
    status,
    commentText,
    statusDate = null
  }: IScaleExperiencePayload) {
    return callApi(
      eHTTPMethods.PUT,
      `/studies/${studyId}/personnel/${personnelId}/scale-experience-statuses/${courseId}`,
      {
        courseStatus: status,
        commentText,
        overrideStatusDate: statusDate
      },
      undefined,
      eApiNames.STUDY_API
    );
  }

  public static async saveCourseStatus(payload: ICourseStatusPayload) {
    const { studyId, personnelId, courseId, status } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/personnel/${personnelId}/training/course/${courseId}`;
    const body = { courseStatus: status };
    const result = await callApi(eHTTPMethods.PUT, path, body);
    return result;
  }

  public static async saveTrainingCourseScores(
    payload: ISaveTrainingCourseAnswersPayload
  ) {
    const path = `${ConfigOptions.scoreApiPath}/personnel/${payload.personnelId}/course/${payload.courseId}/manualentrytrainingresponses/`;
    const body = {
      ManualEntryResponses: payload.questionAnswers,
      completionDate: payload.courseCompletionDate,
      isQCPerformed: payload.isQCPerformed
    };
    const result = await callApi(
      payload.isExisting ? eHTTPMethods.PUT : eHTTPMethods.POST,
      path,
      body
    );
    return result;
  }

  public static async getTrainingCourseScores(
    payload: IGetTrainingCourseScoresPayload
  ) {
    const masterUrl = `${ConfigOptions.scoreApiPath}/course/${payload.courseId}/MasterAcceptableScoreQuestions`;
    const manualUrl = `${ConfigOptions.scoreApiPath}/personnel/${payload.personnelId}/course/${payload.courseId}/manualentrytrainingresponses`;

    let masterResult: IManualTrainingQuestionAnswer[];
    let manualResult;
    let returnValue;

    // If it's not existing then we just want to get the master scores, otherwise we need to get both the
    // master and the manually entered ones and merge in the acceptable responses.
    if (payload.isExisting) {
      [masterResult, manualResult] = await Promise.all([
        callApi(eHTTPMethods.GET, masterUrl),
        callApi(eHTTPMethods.GET, manualUrl)
      ]);

      const mergedQuestions = manualResult.questionAnswers.map(
        (q: IManualTrainingQuestionAnswer) => {
          // Find the matching master question
          const matchingMaster = masterResult.find(
            (qa: IManualTrainingQuestionAnswer) =>
              qa.courseQuestionId === q.questionId
          );
          if (matchingMaster) {
            return Object.assign(
              {},
              {
                ...q,
                acceptableResponse: matchingMaster.acceptableResponse,
                answerSet: matchingMaster.answerSet
              }
            );
          } else {
            return { ...q };
          }
        }
      );
      returnValue = { ...manualResult, questionAnswers: mergedQuestions };
    } else {
      masterResult = await callApi(eHTTPMethods.GET, masterUrl);
      returnValue = { questionAnswers: masterResult };
    }

    return returnValue;
  }

  public static async deleteTrainingCourseScores(
    payload: IDeleteManualScoresPayload
  ) {
    const path = `${ConfigOptions.scoreApiPath}/personnel/${payload.personnelId}/course/${payload.courseId}/manualentrytrainingresponses/`;
    const result = await callApi(eHTTPMethods.DELETE, path);

    return result;
  }

  public static async getCourseMasterQuestions(courseId: string) {
    const path = `${ConfigOptions.scoreApiPath}/course/${courseId}/MasterAcceptableScoreQuestions`;
    const result = await callApi(eHTTPMethods.GET, path);
    return result;
  }
  public static async getCourseMasterQuestionsLegacy(studyComponentId: string) {
    const path = `${ConfigOptions.scoreApiPath}/studycomponent/${studyComponentId}/MasterAcceptableScoreQuestions`;
    const result = await callApi(eHTTPMethods.GET, path);
    return result;
  }

  public static async getCourseCountryQuestions(
    payload: ICountryQuestionsPayload
  ) {
    const path = `${ConfigOptions.scoreApiPath}/course/${payload.courseId}/country/${payload.countryId}/AcceptableScoreQuestions`;
    const result = await callApi(eHTTPMethods.GET, path);
    return result;
  }
  public static async getCourseCountryQuestionsLegacy(
    payload: ICountryQuestionsPayload
  ) {
    const path = `${ConfigOptions.scoreApiPath}/studycomponent/${payload.studyComponentId}/country/${payload.countryId}/AcceptableScoreQuestions`;
    const result = await callApi(eHTTPMethods.GET, path);
    return result;
  }

  public static async updateCourseMasterQuestions(
    payload: IAcceptableScoresPayload
  ) {
    const path = `${ConfigOptions.scoreApiPath}/course/${payload.courseId}/MasterAcceptableScoreQuestions`;
    const result = await callApi(
      payload.mode === "add" ? eHTTPMethods.POST : eHTTPMethods.PUT,
      path,
      payload.body
    );
    return result;
  }

  public static async updateCourseCountryQuestions(
    payload: IAcceptableScoresPayload
  ) {
    const path = `${ConfigOptions.scoreApiPath}/course/${payload.courseId}/country/${payload.countryId}/AcceptableScoreQuestions`;
    const result = await callApi(
      payload.mode === "add" || payload.isNewCountry
        ? eHTTPMethods.POST
        : eHTTPMethods.PUT,
      path,
      payload.body
    );
    return result;
  }

  public static async lockCourseMasterQuestions(courseId: string) {
    const path = `${ConfigOptions.scoreApiPath}/course/${courseId}/MasterAcceptableScore/lock`;
    await callApi(eHTTPMethods.PUT, path);
  }

  public static async getStudySitePersonTraining(
    payload: IStudySitePersonExperienceDTO
  ) {
    const { studyId, siteId, personnelId } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/Site/${siteId}/Personnel/${personnelId}/training`;
    return await callApi(eHTTPMethods.GET, path);
  }

  public static async getCourseThresholds(courseId: string) {
    // We will first do a GET to retrieve the thresholds.  If they have not
    // yet been set it will return an empty object (HTTP 204 - no content).
    // If that happens we need to turn around and do a POST to create the
    // default thresholds.  That one will then return data.

    const path = `${ConfigOptions.scoreApiPath}/course/${courseId}/standardthreshold`;
    const getResponse = await callApi(eHTTPMethods.GET, path);
    if (Object.keys(getResponse).length === 0) {
      // It's empty so we need to do the POST to tell the backend to create the thresholds.
      // It'll return the default thresholds.
      return await callApi(eHTTPMethods.POST, path);
    } else {
      return getResponse;
    }
  }
  public static async getCourseThresholdsLegacy(studyComponentId: string) {
    const path = `${ConfigOptions.scoreApiPath}/studycomponent/${studyComponentId}/standardthreshold`;
    return await callApi(eHTTPMethods.GET, path);
  }

  public static async saveCourseThresholds(payload: ICourseThresholdPayload) {
    const {
      courseId,
      passingThreshold,
      remediationThreshold,
      isLocked
    } = payload;
    const path = `${ConfigOptions.scoreApiPath}/course/${courseId}/standardthreshold`;
    const body = {
      passingThresholdPercentage: passingThreshold,
      remediationThresholdPercentage: remediationThreshold,
      isLocked
    };
    const result = await callApi(eHTTPMethods.PUT, path, body);
    return result;
  }

  public static async getPersonnelTrainingScores(
    payload: IStudyPersonnelCoursePayload
  ) {
    const { studyId, personnelId, courseId, studyComponentId } = payload;
    const path = `${
      ConfigOptions.scoreApiPath
    }/study/${studyId}/personnel/${personnelId}/${
      courseId ? "course" : "studycomponent"
    }/${courseId ? courseId : studyComponentId}`;
    return callApi(eHTTPMethods.GET, path);
  }

  public static async saveLpAssignment(payload: IAssignLpPayload) {
    const { studyId, personnelId, trainingLearningPlanAssignments } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/personnel/${personnelId}/training/learningplans`;
    const body = trainingLearningPlanAssignments;
    const result = await callApi(eHTTPMethods.POST, path, body);
    return result;
  }

  public static async unassignLP(payload: ILearningPlanUnassignPayload) {
    const { studyId, personnelId, learningPlanId } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/personnel/${personnelId}/training/learningplans/unassign`;
    const body = { learningPlanId };
    const result = await callApi(
      eHTTPMethods.PUT,
      path,
      body,
      "Unassign Failed"
    );
    return result;
  }

  public static async saveStudySitePersonnel(
    payload: ISaveStudySitePersonnelPayload
  ) {
    const { studyId, siteId, persons } = payload;
    const path = `/studies/${studyId}/sites/${siteId}/personnel`;
    const body = persons.map((person: IPersonnelAtSite) => ({
      roles: person.roles.map(role => role.value),
      username: person.userName
    }));
    return await callApi(
      eHTTPMethods.POST,
      path,
      body,
      undefined,
      eApiNames.STUDY_API
    );
  }

  public static async updateStudySitePerson(
    payload: IUpdateStudySitePersonDTO
  ) {
    const { id, siteId, person } = payload;
    const { roles, isActive } = person;
    const path = `/studies/${id}/sites/${siteId}/personnel/${person.personnelId}`;
    const body = Object.assign({
      roles: roles.map(r => r.RoleId),
      isActive
    });

    const result = await callApi(
      eHTTPMethods.PUT,
      path,
      body,
      undefined,
      eApiNames.STUDY_API
    );

    return Object.assign(
      new StudySitePersonnelDTO(),
      result
    ) as IStudySitePersonnelDTO;
  }

  public static deleteStudySitePersonnel({ dto }: IPersonnelPayload) {
    return callApi(
      eHTTPMethods.DELETE,
      `/studies/${dto.StudyId}/sites/${dto.SiteId}/personnel/${dto.PersonnelId}`,
      undefined,
      "Delete Failed",
      eApiNames.STUDY_API
    );
  }

  public static async fetchPersonnelInSite(payload: IPersonnelAtSitePayload) {
    const { studyId, siteId } = payload;
    const path = `${ConfigOptions.studyApiPath}/${studyId}/site/${siteId}/PersonnelInSite`;
    return await callApi(eHTTPMethods.GET, path);
  }

  // Qualifications
  public static async getQualifications(
    studyId: string
  ): Promise<IStudyQualification[]> {
    const result = (await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyQualificationsApiPath(studyId)}`
    )) as IStudyQualification[];
    return result.map((sq: IStudyQualification) =>
      Object.assign(new StudyQualification(), sq, { studyId })
    );
  }

  public static async saveQualification(
    studyQual: IStudyQualification
  ): Promise<IStudyQualification> {
    const httpAction = !studyQual.StudyQualificationId
      ? eHTTPMethods.POST
      : eHTTPMethods.PUT;
    let body;
    if (httpAction === eHTTPMethods.POST) {
      body = {
        scaleId: studyQual.ScaleId,
        qualificationName: studyQual.QualificationName.trim(),
        learningPlans: studyQual.LearningPlans
          ? studyQual.LearningPlans.map(lp => {
              return parseInt(lp.id, 10);
            })
          : [],
        isActive: studyQual.IsActive,
        isExperienceRequired: studyQual.IsExperienceRequired,
        qualificationType: studyQual.QualificationType
      };
    } else {
      // no qualification type for update
      body = {
        scaleId: studyQual.ScaleId,
        qualificationName: studyQual.QualificationName.trim(),
        learningPlans: studyQual.LearningPlans
          ? studyQual.LearningPlans.map(lp => {
              return parseInt(lp.id, 10);
            })
          : [],
        legacyLearningPlans: studyQual.LegacyLearningPlans
          ? [...studyQual.LegacyLearningPlans]
          : [],
        isActive: studyQual.IsActive,
        isExperienceRequired: studyQual.IsExperienceRequired
      };
    }
    const result = await callApi(
      httpAction,
      `${ConfigOptions.studyQualificationsApiPath(studyQual.StudyId)}${
        studyQual.StudyQualificationId
          ? `/${studyQual.StudyQualificationId}`
          : ""
      }`,
      body
    );
    return Object.assign(new StudyQualification(), result);
  }

  public static async deleteQualification(studyQual: IStudyQualification) {
    return await callApi(
      eHTTPMethods.DELETE,
      `${ConfigOptions.studyApiPath}/${studyQual.StudyId}/qualification/${studyQual.StudyQualificationId}`,
      undefined,
      "Delete Failed"
    );
  }

  // Learning Plans
  public static async getLearningPlans(
    studyId: string
  ): Promise<ILearningPlan[]> {
    return (await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${studyId}/learningplan`
    )) as ILearningPlan[];
  }

  public static async getLearningPlansForBulkAssign(studyId: string) {
    //TODO - new URL, remove this one
    return (await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${studyId}/learningplan`
    )) as ILearningPlan[];
  }

  public static async fetchBulkLPData(payload: {
    studyId: string;
    learningPlanId: string;
  }) {
    //TODO
    //TODO - any
    //TODO - API call
    return mockBulkLPAssignData;
    // return (await callApi(
    //   eHTTPMethods.GET,
    //   `${ConfigOptions.studyApiUrl}${payload.studyId}/learningplan/${payload.learningPlanId}/users`,
    //   "",
    //   undefined
    // )) as IBulkLPAssignment[];
  }

  public static async saveBulkLPAssignment(payload: IBulkLpAssignPayload) {
    //TODO - API call
    return {};
    // return await callApi(
    //   eHTTPMethods.POST,
    //   `${ConfigOptions.studyApiUrl}${
    //     payload.studyId
    //   }/learningplan/${payload.lpId.toString()}/bulkAssign`,
    //   "",
    //   payload.personnelIds,
    //   "Assign Failed"
    // );
  }

  public static async getStudyQualification(
    studyQual: IStudyQualification
  ): Promise<IStudyQualification[]> {
    const result = (await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.studyApiPath}/${studyQual.StudyId}/qualification/${studyQual.StudyQualificationId}`
    )) as IStudyQualification[];
    return result;
  }

  public static async setNewStudyInState(studyId: string) {
    let { currentStudy } = store.getState().study;
    const subscribeToSaga = (
      sagaType: eStudySaga,
      successValidator: eStudySaga,
      dispatcher: eStudyActionTypes
    ) => {
      const subCanceler = SagaService.subscribeToSaga(
        sagaType,
        (action: IAction) => {
          let subCancelled = false;
          const { type, payload } = action;
          if (type === successValidator) {
            store.dispatch({ type: dispatcher, payload });
            subCanceler();
            subCancelled = true;
          }
          setTimeout(() => {
            if (!subCancelled) {
              subCanceler();
            }
          }, 60000);
        }
      );
    };

    if (currentStudy.StudyId !== studyId) {
      currentStudy = Object.assign(new Study(), { studyId });
      // Reset the study
      store.dispatch({
        type: eStudyActionTypes.SET_STUDY,
        payload: currentStudy
      });
      // Get the new study information
      subscribeToSaga(
        eStudySaga.FETCH_STUDY,
        eStudySaga.FETCH_STUDY_SUCCESS,
        eStudyActionTypes.SET_STUDY
      );
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY,
        payload: studyId
      });
    }
  }
}
