import { call, put } from "redux-saga/effects";
import { SagaService, IAction } from "front-end-lib/core";
import { Sponsor, SponsorProgram } from "../model";
import { store } from "../utils";
import { SponsorService } from "../services";
import { eSponsorActionTypes, openSuccessSnackBar } from "../store";
import { history, overrideSaga } from "../utils";
import { initSaga } from "../utils/sagaHelper";

export enum eSponsorSaga {
  FETCH_SPONSORS = "@@sponsor/FETCH_SPONSORS",
  FETCH_SPONSORS_SUCCESS = "@@sponsor/FETCH_SPONSORS_SUCCESS",
  WATCH_FETCH_SPONSORS = "@@sponsor/WATCH_FETCH_SPONSORS",
  FETCH_SPONSOR_PROGRAMS = "@@sponsor/FETCH_SPONSOR_PROGRAMS",
  FETCH_SPONSOR_PROGRAMS_SUCCESS = "@@sponsor/FETCH_SPONSOR_PROGRAMS_SUCCESS",
  FETCH_SPONSOR = "@@sponsor/FETCH_SPONSOR",
  FETCH_SPONSOR_SUCCESS = "@@sponsor/FETCH_SPONSOR_SUCCESS",
  WATCH_FETCH_SPONSOR = "@@sponsor/WATCH_FETCH_SPONSOR",
  FETCH_SPONSOR_HISTORY = "@@sponsor/FETCH_SPONSOR_HISTORY",
  FETCH_SPONSOR_HISTORY_SUCCESS = "@@sponsor/FETCH_SPONSOR_HISTORY_SUCCESS",
  MODIFY_SPONSOR = "@@sponsor/MODIFY_SPONSOR",
  MODIFY_SPONSOR_SUCCESS = "@@sponsor/MODIFY_SPONSOR_SUCCESS",
  WATCH_MODIFY_SPONSOR = "@@sponsor/WATCH_MODIFY_SPONSOR",
  MODIFY_SPONSOR_PROGRAM = "@@sponsor/MODIFY_SPONSOR_PROGRAM",
  MODIFY_SPONSOR_PROGRAM_SUCCESS = "@@sponsor/MODIFY_SPONSOR_PROGRAM_SUCCESS",
  WATCH_SPONSOR_PROGRAMS = "@@sponsor/WATCH_SPONSOR_PROGRAMS",
  WATCH_SPONSOR_PROGRAM_SAVE = "@@sponsor/WATCH_SPONSOR_PROGRAM_SAVE",
  WATCH_SPONSOR_HISTORY = "@@sponsor/WATCH_SPONSOR_HISTORY"
}

initSaga(
  function*(action: IAction) {
    const sponsors = yield call(SponsorService.getSponsors, action.payload);
    yield put({ type: eSponsorSaga.FETCH_SPONSORS_SUCCESS, payload: sponsors });
  },
  eSponsorSaga.FETCH_SPONSORS,
  eSponsorSaga.WATCH_FETCH_SPONSORS
);

initSaga(
  function*(action: IAction) {
    const programs = yield call(
      SponsorService.getSponsorPrograms,
      action.payload
    );
    yield put({
      type: eSponsorSaga.FETCH_SPONSOR_PROGRAMS_SUCCESS,
      payload: programs
    });
  },
  eSponsorSaga.FETCH_SPONSOR_PROGRAMS,
  eSponsorSaga.WATCH_SPONSOR_PROGRAMS
);

initSaga(
  function*(action: IAction) {
    const sponsor = yield call(SponsorService.getSponsor, action.payload);
    yield put({ type: eSponsorSaga.FETCH_SPONSOR_SUCCESS, payload: sponsor });
  },
  eSponsorSaga.FETCH_SPONSOR,
  eSponsorSaga.WATCH_FETCH_SPONSOR
);

initSaga(
  function*(action: IAction) {
    const sponsor = yield call(SponsorService.saveSponsor, action.payload);
    yield put({ type: eSponsorSaga.MODIFY_SPONSOR_SUCCESS, payload: sponsor });
    yield history.push(
      `/sponsor/${sponsor.sponsorId || action.payload.sponsorId}`
    );
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eSponsorSaga.MODIFY_SPONSOR,
  eSponsorSaga.MODIFY_SPONSOR_SUCCESS
);

initSaga(
  function*(action: IAction) {
    const sponsorProgram = yield call(
      SponsorService.saveSponsorProgram,
      action.payload
    );
    yield put({
      type: eSponsorSaga.MODIFY_SPONSOR_PROGRAM_SUCCESS,
      payload: sponsorProgram
    });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
    SagaService.dispatchSaga({
      type: eSponsorSaga.FETCH_SPONSOR_PROGRAMS,
      payload: sponsorProgram.sponsorId
    });
  },
  eSponsorSaga.MODIFY_SPONSOR_PROGRAM,
  eSponsorSaga.WATCH_SPONSOR_PROGRAM_SAVE
);

initSaga(
  function* fetchSponsorHistory(action: IAction) {
    const history = yield call(
      SponsorService.getSponsorHistory,
      action.payload
    );
    yield put({
      type: eSponsorSaga.FETCH_SPONSOR_HISTORY_SUCCESS,
      payload: history
    });
  },
  eSponsorSaga.FETCH_SPONSOR_HISTORY,
  eSponsorSaga.WATCH_SPONSOR_HISTORY
);

export const overrideSponsorSagas = () => {
  overrideSaga(
    function* dispatchSponsor() {
      const payload = Object.assign(new Sponsor(), {
        sponsorId: "1",
        sponsorName: "TestSponsor",
        hasHistory: true
      });
      store.dispatch({ type: eSponsorActionTypes.SET_SPONSOR, payload });
      yield put({ type: eSponsorSaga.FETCH_SPONSOR_SUCCESS, payload });
    },
    eSponsorSaga.FETCH_SPONSOR,
    eSponsorSaga.WATCH_FETCH_SPONSOR
  );

  overrideSaga(
    function* dispatchSponsors() {
      yield put({
        type: eSponsorSaga.FETCH_SPONSORS_SUCCESS,
        payload: [
          Object.assign(new Sponsor(), {
            sponsorId: "1",
            sponsorName: "TestSponsor",
            hasHistory: true
          }),
          Object.assign(new Sponsor(), {
            sponsorId: "2",
            sponsorName: "TestSponsor1",
            hasHistory: false
          })
        ]
      });
    },
    eSponsorSaga.FETCH_SPONSORS,
    eSponsorSaga.WATCH_FETCH_SPONSORS
  );

  overrideSaga(
    function* saveSponsor(action: IAction) {
      const payload = Object.assign(
        new Sponsor(),
        store.getState().sponsor.currentSponsor,
        action.payload,
        { sponsorId: "1" }
      );
      store.dispatch({ type: eSponsorActionTypes.SET_SPONSOR, payload });
      yield put({ type: eSponsorSaga.MODIFY_SPONSOR_SUCCESS, payload });
    },
    eSponsorSaga.MODIFY_SPONSOR,
    eSponsorSaga.WATCH_MODIFY_SPONSOR
  );

  overrideSaga(
    function* dispatchSponsorPrograms(action: IAction) {
      if (action.payload) {
        yield put({
          type: eSponsorSaga.FETCH_SPONSOR_PROGRAMS_SUCCESS,
          payload: [
            Object.assign(new SponsorProgram(), {
              id: "1",
              sponsorId: "1",
              programName: "TestProgram1",
              internalProgramName: "TestProgram1"
            }),
            Object.assign(new SponsorProgram(), {
              id: "2",
              sponsorId: "1",
              programName: "TestProgram2",
              internalProgramName: "TestProgram2"
            })
          ]
        });
      }
    },
    eSponsorSaga.FETCH_SPONSOR_PROGRAMS,
    eSponsorSaga.WATCH_SPONSOR_PROGRAMS
  );
};
