import {
  mdiAccountCardDetails,
  mdiFileDocumentBox,
  mdiBookOpenPageVariant
} from "@mdi/js";
import { MenuListItem, IMenuList } from "../../../model";

export enum sponsorMenuItemCategories {
  General = "General",
  Studies = "Studies",
  Programs = "Programs"
}

export class SponsorMenuContainer implements IMenuList {
  // This will be the interface structure used for the component to make it generic
  public root: string;
  public data: any;
  public selectedSponsor: string;
  public enabled: boolean;
  public menuItems: MenuListItem[];
  public rootUrl: string;
  public constructor() {
    this.root = "All Sponsors";
    this.rootUrl = "/sponsor/";
    this.menuItems = this.getMenu();
  }

  public getMenu() {
    const menuList = [
      new MenuListItem(
        "General Info",
        mdiAccountCardDetails,
        "/sponsor/" + this.enabled ? "/sponsor/:id" : "/sponsor/new",
        sponsorMenuItemCategories.General
      ),
      new MenuListItem(
        "Studies",
        mdiFileDocumentBox,
        "/sponsor/" + this.enabled ? "/sponsor/:id/study" : "/sponsor/new",
        sponsorMenuItemCategories.Studies
      ),
      new MenuListItem(
        "Programs",
        mdiBookOpenPageVariant,
        "/sponsor/" + this.enabled ? "/sponsor/:id/program" : "/sponsor/new",
        sponsorMenuItemCategories.Programs
      )
    ];

    return menuList;
  }
}
