import * as React from "react";
import { Button } from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import {
  SagaService,
  IAction,
  BktDataTable,
  ISelectData
} from "front-end-lib/core";
import { eStudySaga } from "../../../sagas";
import { MddExpansionPanel, MddProgress } from "../../common";
import {
  withMddBase,
  MddOverrideStatusDialog,
  MddStudySitePersonComments,
  MddNoDataDiv
} from "../../../components";
import {
  ICourseQuestion,
  ICourse,
  IScaleExperience,
  IScaleDataTable,
  INameValuePair
} from "../../../model";

import {
  EXPERIENCE_TAG,
  classConstants,
  NO_ANSWER_DISPLAY_TEXT,
  EXPERIENCE_STATUS_DISPLAY_VALUE,
  EXPERIENCE_STATUS_VALUE
} from "../../../constants";
import {
  isLoading,
  toDateFormatNoOffset,
  getButtonText,
  isEditDisabled,
  formatScaleExperienceResponse
} from "../../../utils";

interface IMddStudySitePersonExperienceProps {
  id: string;
  siteId: string;
  personnelId: string;
}

const enum resSections {
  General = 0,
  ClinicalData = 1,
  Additional = 2,
  Scales = 3,
  Count = 4
}

const MddStudySitePersonExperienceComponent = (
  props: IMddStudySitePersonExperienceProps
) => {
  const [expanded, setExpanded] = React.useState(
    Array(resSections.Count).fill(true)
  );
  const [generalExperience, setGeneralExperience] = React.useState<
    ICourseQuestion[]
  >([]);
  const [demographicExperience, setDemographicExperience] = React.useState<
    ICourseQuestion[]
  >([]);
  const [additionalExperience, setAdditionalExperience] = React.useState<
    ICourseQuestion[]
  >([]);
  const [scaleExperience, setScaleExperience] = React.useState<
    IScaleDataTable[]
  >([]);
  const [overrideOpen, setOverrideOpen] = React.useState(false);
  const [currentScale, setCurrentScale] = React.useState<
    IScaleDataTable | undefined
  >(undefined);
  const [availableStatuses, setAvailableStatuses] = React.useState<
    ISelectData[]
  >([]);
  const [
    isOverrideCommentRequired,
    setIsOverrideCommentRequired
  ] = React.useState(true);

  React.useEffect(() => {
    const { id, siteId, personnelId } = props;

    const removeFetchExperienceSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITE_PERSON_EXPERIENCE,
      (action: IAction) => {
        if (
          action.type === eStudySaga.FETCH_STUDY_SITE_PERSON_EXPERIENCE_SUCCESS
        ) {
          formatExperience(action.payload);
        }
      }
    );
    const removeFetchScaleExperienceSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE_SUCCESS
        ) {
          if (action.payload) {
            const ret = action.payload.map((item: IScaleExperience) => {
              return Object.assign(
                {},
                {
                  scaleName: item.scaleName,
                  response: formatScaleExperienceResponse(item),
                  scaleUsage: item.scaleUsage,
                  status: item.status || "Incomplete",
                  override:
                    item.comment && item.comment.commentText
                      ? decodeURIComponent(item.comment.commentText)
                      : null,
                  statusDate: item.statusDate,
                  id: item.userCourseStatusId,
                  key: item.userCourseStatusId
                }
              );
            });
            setScaleExperience(ret);
          }
        }
      }
    );
    const removeFetchScaleStatusesSub = SagaService.subscribeToSaga(
      eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES,
      (action: IAction) => {
        if (
          action.type ===
          eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES_SUCCESS
        ) {
          setAvailableStatuses(formatStatuses(action.payload));
        }
      }
    );
    const removeSaveScaleStatusSub = SagaService.subscribeToSaga(
      eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS_SUCCESS) {
          // update the scale in place so we don't need another API call
          const {
            userCourseStatusId: courseId,
            courseStatus,
            statusDate,
            comment
          } = action.payload;
          const newScales = scalesRef.current.map((scale: IScaleDataTable) => {
            return scale.id === courseId
              ? Object.assign({}, scale, {
                  status: courseStatus.name,
                  statusDate: statusDate,
                  override:
                    comment && comment.commentText
                      ? decodeURIComponent(comment.commentText)
                      : null
                })
              : scale;
          });
          setScaleExperience(newScales);
          setOverrideOpen(false);
          // Fix for PLAT-19619 - once a save is done there is no way that a comment isn't required since
          // it's only not required for Clinical Review to Eligible and there is no way to get back to Clinical Review.
          setIsOverrideCommentRequired(true);
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_EXPERIENCE,
      payload: {
        studyId: id,
        siteId,
        personnelId
      }
    });
    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE,
      payload: {
        studyId: id,
        siteId,
        personnelId
      }
    });

    return () => {
      removeFetchExperienceSub();
      removeFetchScaleExperienceSub();
      removeFetchScaleStatusesSub();
      removeSaveScaleStatusSub();
    };
    // eslint-disable-next-line
  }, []);

  const scalesRef = React.useRef(scaleExperience);
  scalesRef.current = scaleExperience;
  const currScaleRef = React.useRef(currentScale);
  currScaleRef.current = currentScale;

  const { id, siteId, personnelId } = props;

  const formatExperience = (payload: ICourse[]) => {
    const formatCategory = (data: ICourse[], tag: string) => {
      // This maps the data into an array of questions for the given tag.
      // If multiple courses have the same tag there will be multiple entries in the array.
      const questions = data
        .filter((course: ICourse) => {
          return course.experienceTag === tag;
        })
        .map((course: ICourse) => {
          return course.questions;
        });

      // Now merge the questions array items into one array for this category.
      let categoryArray: ICourseQuestion[] = [];
      questions.forEach((q: ICourseQuestion[]) => {
        categoryArray = categoryArray.concat(q);
      });

      return categoryArray;
    };

    setGeneralExperience(formatCategory(payload, EXPERIENCE_TAG.GENERAL));
    setDemographicExperience(formatCategory(payload, EXPERIENCE_TAG.CLINICAL));
    setAdditionalExperience(formatCategory(payload, EXPERIENCE_TAG.ADDITIONAL));
  };

  const formatStatuses = (payload: INameValuePair[]) => {
    // filter out the current status since they shouldn't be able to pick it
    return payload
      .map((status: INameValuePair) => {
        return Object.assign({}, { value: status.value, label: status.name });
      })
      .filter(status => status.label !== currScaleRef.current!.status);
  };

  const handleEditOverrideClick = (e: React.MouseEvent, id: string) => {
    const { id: studyId, personnelId } = props;
    setOverrideOpen(true);

    const scale: IScaleDataTable | undefined = getScaleById(id);
    if (scale) {
      setCurrentScale(scale);
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES,
        payload: {
          studyId,
          personnelId,
          courseId: scale.key
        }
      });
    }
  };

  const handleExpandAll = () => {
    setExpanded(Array(resSections.Count).fill(true));
  };
  const handleCollapseAll = () => {
    setExpanded(Array(resSections.Count).fill(false));
  };

  const togglePanel = (isExpanded: boolean, panel: number) => {
    const currExpanded = [...expanded];
    currExpanded[panel] = isExpanded;
    setExpanded(currExpanded);
  };

  const isFullyCollapsed = expanded.every(s => !s);
  const isFullyExpanded = expanded.every(s => s);

  const formatAnswer = (response: string[]) => {
    // If no answer (typically "" as the first answer) return the no answer text
    if (!response || !response[0] || !response[0].length) {
      return NO_ANSWER_DISPLAY_TEXT;
    }
    // Otherwise create a comma separated string of the answers
    return response.join(", ");
  };

  const formatQuestionAnswer = (item: ICourseQuestion, index: number) => {
    return (
      <React.Fragment key={`${item.description}_${index}`}>
        <div
          className="mdd-experience--panel--content--label"
          id="divItemLabel"
          data-testid="divItemLabel"
        >
          {item.description}
        </div>
        <div
          className="mdd-experience--panel--content--text"
          id="divItemText"
          data-testid="divItemText"
        >
          {formatAnswer(item.response)}
        </div>
      </React.Fragment>
    );
  };

  const getPanelContent = (data: ICourseQuestion[]) => {
    // Input is an array of questions and answers (description/response).
    // Format that for display.
    if (!data.length) {
      return <MddNoDataDiv />;
    }
    return (
      <div className="mdd-experience--panel--content">
        {data.map((item: ICourseQuestion, index: number) => {
          return formatQuestionAnswer(item, index);
        })}
      </div>
    );
  };

  const showSpinner = () => {
    return isLoading(
      `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON_EXPERIENCE}`,
      `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE}`,
      `undefined_${eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES}`,
      `undefined_${eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS}`,
      `undefined_${eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS}`,
      `undefined_${eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT}`,
      `undefined_${eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT}`
    );
  };

  const formatAdditionalPanel = () => {
    return (
      <div className="mdd-experience--bottom-panel">
        <MddExpansionPanel
          expanded={expanded[resSections.Additional]}
          id="panelAdditional"
          data-testid="panelAdditional"
          headerText="Additional Experience"
          onChange={(event, expanded) =>
            togglePanel(expanded, resSections.Additional)
          }
        >
          {getPanelContent(additionalExperience)}
        </MddExpansionPanel>
      </div>
    );
  };

  // Determines if the 3rd panel (additional experience) should be on the left (underneath general experience) or
  // on the right (under indication/demographic). Move it to the right if the general panel is, by an educated guess,
  // longer than the indication one by enough to make the screen more balanced by moving it.
  const additionalPanelOnLeft =
    generalExperience.length < demographicExperience.length + 3;

  const getScaleById = (id: string) =>
    scaleExperience.find(item => item.id === id);

  const handleCloseDialog = (e: React.MouseEvent) => {
    setOverrideOpen(false);
    setCurrentScale(undefined);
  };

  const handleSaveStatus = (
    status: string,
    commentText: string,
    statusDate?: string
  ) => {
    const { id, personnelId } = props;
    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS,
      payload: {
        studyId: id,
        personnelId,
        courseId: currentScale!.id,
        status,
        commentText: encodeURIComponent(commentText.trim()),
        statusDate: [
          EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE,
          EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
        ].includes(currentScale!.status)
          ? null
          : statusDate
      }
    });
  };

  return (
    <React.Fragment>
      <MddProgress inProgress={showSpinner()} spinnerId="svgLoading" />
      <div className="mdd-experience--container">
        <div className="mdd-experience--expand-collapse">
          <div
            id="expandCollapseDiv"
            data-testid="expandCollapseDiv"
            className="mdd-experience--expand-collapse-div mdd-experience--fullwidth"
          >
            <span
              className={
                isFullyExpanded
                  ? "mdd-experience--expand-collapse-disabled"
                  : ""
              }
              data-testid={
                isFullyExpanded ? "expandAllDisabled" : "expandAllEnabled"
              }
              id="txtExpandAll"
              onClick={() => handleExpandAll()}
            >
              Expand All
            </span>
            <span>|</span>
            <span
              className={
                isFullyCollapsed
                  ? "mdd-experience--expand-collapse-disabled"
                  : ""
              }
              data-testid={
                isFullyCollapsed ? "collapseAllDisabled" : "collapseAllEnabled"
              }
              id="txtCollapseAll"
              onClick={() => handleCollapseAll()}
            >
              Collapse All
            </span>
          </div>
        </div>
        {/* flex container for the two main side by side divs */}
        <div className="mdd-experience--flex-container">
          {/* 1st of the main side by side divs */}
          <div className="mdd-experience--flex-container--child-left">
            {/* flex column container */}
            <div className="mdd-experience--flex-container--column">
              {/* flex item 1 */}
              <div
                id="panelExperience"
                data-testid="panelExperience"
                className="mdd-experience--panel"
              >
                <MddExpansionPanel
                  expanded={expanded[resSections.General]}
                  id="panelGeneral"
                  data-testid="panelGeneral"
                  headerText={"General Experience"}
                  onChange={(event, expanded) =>
                    togglePanel(expanded, resSections.General)
                  }
                >
                  {getPanelContent(generalExperience)}
                </MddExpansionPanel>
              </div>
              {/* flex item 2 */}
              {additionalPanelOnLeft && formatAdditionalPanel()}
            </div>
          </div>

          {/* 2nd of the main side by side divs */}
          <div className="mdd-experience--flex-container--child-right">
            {/* flex column container */}
            <div className="mdd-experience--flex-container--column">
              {/* flex item 1 */}
              <div className="mdd-experience--panel">
                <MddExpansionPanel
                  expanded={expanded[resSections.ClinicalData]}
                  id="panelClinicalData"
                  data-testid="panelClinicalData"
                  headerText="Indication and Demographic Experience"
                  onChange={(event, expanded) =>
                    togglePanel(expanded, resSections.ClinicalData)
                  }
                >
                  {getPanelContent(demographicExperience)}
                </MddExpansionPanel>
              </div>
              {/* flex item 2 */}
              {!additionalPanelOnLeft && formatAdditionalPanel()}
            </div>
          </div>
          <div className="mdd-experience--flex-container--child-fullwidth">
            <div className="mdd-experience--panel--scales">
              <MddExpansionPanel
                expanded={expanded[resSections.Scales]}
                id="panelScaleExperience"
                data-testid="panelScaleExperience"
                headerText="Scale Experience"
                onChange={(event, expanded) =>
                  togglePanel(expanded, resSections.Scales)
                }
              >
                {!scaleExperience.length && <MddNoDataDiv />}

                {scaleExperience.length > 0 && (
                  <BktDataTable
                    data={scaleExperience || []}
                    columnProps={[
                      {
                        label: "Scale",
                        tableCellProps: {
                          id: "scaleHeader"
                        },
                        divProps: { id: "scaleHeader_div" }
                      },
                      {
                        label: "Response",
                        tableCellProps: {
                          id: "responseHeader"
                        },
                        divProps: { id: "responseHeader_div" }
                      },
                      {
                        label: "Scale Usage (per year)",
                        tableCellProps: {
                          id: "usageHeader"
                        },
                        divProps: { id: "usageHeader_div" }
                      },
                      {
                        label: "Experience Status",
                        tableCellProps: {
                          id: "experienceHeader",
                          className: classConstants.TABLE_CELL_CLASS
                        },
                        divProps: { id: "experienceHeader_div" }
                      },
                      {
                        label: "Comment",
                        tableCellProps: {
                          // TODO - intentionally leaving this id and others below as "override" for now since we are going to revisit this column
                          id: "overrideHeader",
                          className: classConstants.TABLE_CELL_CLASS
                        },
                        divProps: { id: "overrideHeader_div" },
                        formatProp: {
                          formatPropName: "override",
                          format: (val: string) =>
                            val ? (
                              <span className="mdd-experience--override--comment-icon">
                                <CommentIcon
                                  fontSize={"small"}
                                  data-testid="iconComment"
                                />
                              </span>
                            ) : null
                        }
                      },
                      {
                        label: "Status Date",
                        tableCellProps: {
                          id: "statusDateHeader",
                          className: classConstants.TABLE_CELL_CLASS
                        },
                        divProps: { id: "statusDateHeader_div" },
                        formatProp: {
                          formatPropName: "statusDate",
                          format: (val: string) =>
                            val ? toDateFormatNoOffset(val) : ""
                        }
                      },
                      {
                        label: "",
                        tableCellProps: {
                          id: "editHeader",
                          className: classConstants.TABLE_CELL_CLASS
                        },
                        divProps: { id: "editHeader_div" },
                        formatProp: {
                          formatPropName: "id",
                          format: val => (
                            <Button
                              id="btnEdit"
                              data-testid="btnEdit"
                              className="mdd-experience--button"
                              color="primary"
                              variant="contained"
                              disabled={isEditDisabled(getScaleById(val))}
                              onClick={(e: React.MouseEvent) =>
                                handleEditOverrideClick(e, val)
                              }
                            >
                              {getButtonText(getScaleById(val))}
                            </Button>
                          )
                        }
                      }
                    ]}
                    tableBodyProps={{ id: "scaleExperience_tablebody" }}
                    infiniteScrollProps={{
                      id: "scaleExperienceInfiniteScroll",
                      maxItems: 999
                    }}
                    noRecordsMessage={"No matching scale experience available"}
                  />
                )}
              </MddExpansionPanel>
            </div>
          </div>
        </div>
        {/* end main flex container */}
      </div>
      {overrideOpen && (
        <MddOverrideStatusDialog
          open={overrideOpen}
          isQualOverride={false}
          appendCurrentStatus={
            currentScale &&
            currentScale.status !==
              EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
          }
          headerText={`${
            currentScale &&
            currentScale.status !==
              EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW
              ? "Override"
              : "Edit"
          } Scale Experience`}
          defTypeText="Scale"
          defValueText={(currentScale && currentScale.scaleName) || ""}
          currStatusText={
            currentScale && currentScale.status ? currentScale.status : ""
          }
          prevStatusDate={toDateFormatNoOffset(currentScale!.statusDate)}
          isCommentRequired={isOverrideCommentRequired}
          onStatusChange={(newStatus: string) =>
            setIsOverrideCommentRequired(
              !(
                currentScale &&
                currentScale.status ===
                  EXPERIENCE_STATUS_DISPLAY_VALUE.CLINICAL_REVIEW &&
                newStatus === EXPERIENCE_STATUS_VALUE.ELIGIBLE
              )
            )
          }
          saveInProgress={isLoading(
            `undefined_${eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS}`
          )}
          onClose={handleCloseDialog}
          onSave={handleSaveStatus}
          statuses={availableStatuses}
        />
      )}
      <MddStudySitePersonComments
        studyId={id}
        siteId={siteId}
        personnelId={personnelId}
      />
    </React.Fragment>
  );
};

export const MddStudySitePersonExperience = withMddBase(
  MddStudySitePersonExperienceComponent
);
