import React, { useState, useCallback } from "react";
import { IEditItem, ITabData } from "../../../store/resConfig/types";
import { MddExpansionPanel } from "../../common/MddExpansionPanel/MddExpansionPanel";
import { IIdNamePair } from "model";

interface IMddResViewGeneralProps {
  demographics: ITabData;
}

export const MddResViewGeneral = ({
  demographics
}: IMddResViewGeneralProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const findDemographic = useCallback(
    (id: string) => {
      return `${
        demographics.list?.find((item: IIdNamePair) => item.id === id)?.name
      } Experience`;
    },
    [demographics]
  );

  return (
    <MddExpansionPanel
      expanded={isExpanded}
      id="panelGeneral"
      data-testid="panelGeneral"
      panelClass="mdd-expansion-panel--panel mdd-res-config-view--panel-nonscales"
      headerText={<h3>Experience (General)</h3>}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <div
        id="viewRes-general"
        data-testid="viewRes-general"
        className="mdd-res-config-view"
      >
        <h4 className="mdd-res-config-view--panel-description">
          General Experience Fields:
        </h4>
        {demographics?.fields?.map((field: string) => (
          <div key={field} className="value">
            {field}
          </div>
        ))}
        <div className="mdd-res-config-view--panel-description mdd-res-config-view--panel-description-flex mdd-res-config-view--panel-description-padding">
          <h4>Demographic Clinical Experience:</h4>
          <div className="mdd-res-config-view--panel-description-inactive">
            *Inactive
          </div>
        </div>

        {demographics?.items?.map((item: IEditItem) => {
          const demographic = findDemographic(item.parentId);
          const value = item.isActive ? demographic : `*${demographic}`;
          const className = item.isActive ? "" : "inactive-item";
          return (
            <div key={item.id} className={`value ${className}`}>
              {value}
            </div>
          );
        })}
      </div>
    </MddExpansionPanel>
  );
};
