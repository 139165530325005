import React, { useState } from "react";
import { ITabData } from "../../../store/resConfig/types";
import { MddExpansionPanel } from "../../common/MddExpansionPanel/MddExpansionPanel";

interface IMddResViewDemographicsProps {
  demographicFields: ITabData;
}

export const MddResViewDemographics = ({
  demographicFields
}: IMddResViewDemographicsProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <MddExpansionPanel
      expanded={isExpanded}
      id="panelDemographics"
      data-testid="panelDemographics"
      panelClass="mdd-expansion-panel--panel mdd-res-config-view--panel-nonscales"
      headerText={<h3>Demographics</h3>}
      onChange={(event, expanded) => setIsExpanded(!isExpanded)}
    >
      <div
        id="viewRes-demographics"
        data-testid="viewRes-demographics"
        className="mdd-res-config-view"
      >
        <h4 className="mdd-res-config-view--panel-description">
          Demographic Fields:
        </h4>
        {demographicFields?.fields?.map((field: string) => (
          <div key={field} className="value">
            {field}
          </div>
        ))}
      </div>
    </MddExpansionPanel>
  );
};
