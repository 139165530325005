import { Grid } from "@material-ui/core/";
import { ListItemText } from "@material-ui/core/";
import { IconButton } from "@material-ui/core/";
import { MenuList } from "@material-ui/core/";
import { MenuItem } from "@material-ui/core/";
import Icon from "@mdi/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import * as React from "react";
import IMenuProps from "./types";
import { mdiLock } from "@mdi/js";
import { history } from "../../../utils";

import { eIconTypes } from "../../../constants/applicationConstants";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faLock } from "@fortawesome/free-solid-svg-icons";

class BktGenericMenuComponent extends React.Component<IMenuProps, any> {
  public state = {
    open: false,
    selected: this.props.selected
  };

  public handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  public handleDrawerClose = () => {
    this.setState({ open: false });
  };

  public isMenuDisabled = (index: number) => {
    return !this.props.enabled && index !== 0;
  };

  public componentDidUpdate() {
    if (this.state.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
  }

  public setMenuIconOrChevron = () => {
    const { open } = this.state;
    return !open ? (
      <div
        className="mdd-generic-menu--divMenuOpenDrawerIcon"
        id="divMenuOpenDrawerIcon"
      >
        <IconButton
          id="iconOpenDrawerBtn"
          className="mdd-generic-menu--menuDrawerIcon"
          color="inherit"
          aria-label="Open drawer"
          onClick={this.handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
      </div>
    ) : (
      <div
        className="mdd-generic-menu--divMenuCloseDrawerIcon"
        id="divMenuCloseDrawerIcon"
      >
        <IconButton
          id="iconCloseDrawerBtn"
          className="mdd-generic-menu--menuDrawerIcon"
          onClick={this.handleDrawerClose}
          aria-label="Close drawer"
        >
          <ChevronLeftIcon />
        </IconButton>
      </div>
    );
  };

  public getCustomMenuList = (
    id: string,
    siteId: string,
    personnelId: string,
    resId: string
  ) => {
    const { menu } = this.props;
    const { selected, open } = this.state;
    return (
      <MenuList id="navList">
        {menu.menuItems.map((menuItem, index) => (
          <MenuItem
            button={true}
            id={menuItem.anchor}
            key={index}
            className="mdd-generic-menu--menuItem"
            selected={selected === menuItem.anchor}
            disabled={this.isMenuDisabled(index)}
            title={menuItem.menuText}
            onClick={() => {
              this.setState({ selected: menuItem.anchor });
              history.push(
                menuItem.linkTo
                  .replace(":id", id)
                  .replace(":siteId", siteId)
                  .replace(":personnelId", personnelId)
                  .replace(":resId", resId)
              );
            }}
          >
            <div
              id={`${menuItem.anchor}icon`}
              className="mdd-generic-menu--menuItemIconWrapper"
            >
              {menuItem.iconType === eIconTypes.MDI && (
                <React.Fragment>
                  <Icon
                    path={menuItem.icon as string}
                    color={this.getMenuItemColor(index)}
                    className="mdd-generic-menu--menuItemIcon"
                  />
                  <Icon
                    path={mdiLock}
                    className={
                      this.isMenuDisabled(index)
                        ? "mdd-generic-menu--disabledButtonLockIcon"
                        : "mdd-generic-menu--displayNone"
                    }
                  />
                </React.Fragment>
              )}
              {menuItem.iconType === eIconTypes.FONTAWESOME && (
                <React.Fragment>
                  <FontAwesomeIcon
                    className="mdd-generic-menu--faIcon"
                    icon={menuItem.icon as IconDefinition}
                    fixedWidth
                    fontSize={"1.25rem"}
                  />
                  <FontAwesomeIcon
                    className={
                      this.isMenuDisabled(index)
                        ? "mdd-generic-menu--faIcon mdd-generic-menu--disabledButtonLockIconFa"
                        : "mdd-generic-menu--displayNone"
                    }
                    icon={faLock}
                    fixedWidth
                    fontSize={"1.25rem"}
                  />
                </React.Fragment>
              )}
            </div>
            <ListItemText
              disableTypography
              id={`${menuItem.anchor}MenuText`}
              className={
                open
                  ? "mdd-generic-menu--menuItem--text"
                  : "mdd-generic-menu--displayNone"
              }
              primary={menuItem.menuText}
            />
          </MenuItem>
        ))}
      </MenuList>
    );
  };

  public getMenuItemColor = (index: number) => {
    return this.isMenuDisabled(index) ? "rgb(202, 202, 202)" : "#646464";
  };

  public render() {
    const { children } = this.props;
    const { open } = this.state;
    const { id, siteId, personnelId, resId } = this.props.match.params;

    return (
      <div id="menuWrapper">
        <Grid
          id="gridContainer"
          container={true}
          spacing={0}
          className="mdd-generic-menu--grid"
        >
          <Grid
            id="menuContainer"
            item={true}
            className="mdd-generic-menu--container"
          >
            {this.setMenuIconOrChevron()}
            <div
              id="listbodyContainer"
              className={
                open
                  ? "mdd-generic-menu--drawerOpen"
                  : "mdd-generic-menu--drawerClose"
              }
            >
              {this.getCustomMenuList(id, siteId, personnelId, resId)}
            </div>
          </Grid>

          <Grid
            id="showComponentContainer"
            item={true}
            className={
              open
                ? "mdd-generic-menu--appBarOpen"
                : "mdd-generic-menu--appBarClose"
            }
          >
            <div
              id="childComponentContainer"
              className="mdd-generic-menu--childComponentContainer"
            >
              {children}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export const BktGenericMenu = BktGenericMenuComponent;
