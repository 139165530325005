import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddCircle from "@material-ui/icons/AddCircle";
import { MddBreadcrumb } from "../MddBreadcrumb/MddBreadcrumb";
import { MddForm, IMddFormProps } from "../MddForm/MddForm";
import { IBreadcrumb } from "../../../model";

interface IMddDataContainerClasses {
  container?: string;
  grid?: string;
  paper?: string;
  typography?: string;
  button?: string;
  addCircle?: string;
  body?: string;
}

interface IMddDataContainerProps {
  /** Data that should be displayed in the container. */
  children?: React.ReactNode;
  /** Unique identifier for data container elements. */
  id?: string;
  /** Viewable history for user navigation. */
  breadcrumbs?: IBreadcrumb[];
  /** Button used to add data to the data container */
  addAction?: {
    /** The message to be displayed on the button. */
    display: string;
    /** The action to be executed. */
    action: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };
  /** Data containers title. */
  title?: string;
  /** Wrap the children in a form? */
  wrapInForm?: boolean;
  /** Props to be passed to the MddForm. Will be ignored if withMddForm is passed in as false */
  mddFormProps?: IMddFormProps;
  /** Classes used to override any default classes */
  classes?: IMddDataContainerClasses;
}

const defaultClasses: IMddDataContainerClasses = {
  container: "mdd-form--fullheight",
  grid: "mdd-grid mdd-grid--with-menu",
  paper: "",
  typography: "",
  button: "",
  addCircle: "",
  body: ""
};

export const MddDataContainer = (props: IMddDataContainerProps) => {
  const {
    breadcrumbs,
    id = "dataContainer",
    addAction,
    title,
    wrapInForm = true,
    mddFormProps,
    classes: {
      container = defaultClasses.container,
      grid = defaultClasses.grid,
      paper = defaultClasses.paper,
      typography = defaultClasses.typography,
      button = defaultClasses.button,
      addCircle = defaultClasses.addCircle,
      body = defaultClasses.body
    } = defaultClasses,
    children
  } = props;

  const getContent = () => (
    <div id={`div${id}Grid`} data-testid={`div${id}Grid`} className={grid}>
      {!addAction && !title ? (
        <span />
      ) : (
        <Paper id={`div${id}Header`} className={paper}>
          <Typography id={`p${id}HeaderTitle`} className={typography}>
            {title}
          </Typography>
          {!addAction ? (
            undefined
          ) : (
            <Button
              id={`btn${id}Add`}
              className={button}
              variant="contained"
              size={"small"}
              onClick={addAction.action}
            >
              <AddCircle id="addCircleIcon" className={addCircle} />
              {addAction.display}
            </Button>
          )}
        </Paper>
      )}
      <Paper id={`div${id}Body`} className={body}>
        {children}
      </Paper>
    </div>
  );

  return (
    <div id={`div${id}`} data-testid={`div${id}`} className={container}>
      {breadcrumbs && breadcrumbs.length > 0 ? (
        <MddBreadcrumb breadcrumbs={breadcrumbs} />
      ) : (
        undefined
      )}
      {!wrapInForm ? (
        getContent()
      ) : (
        <MddForm {...mddFormProps}>{getContent()}</MddForm>
      )}
    </div>
  );
};
