import * as React from "react";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { eStudySaga } from "../../../../sagas";
import { SagaService, IAction } from "front-end-lib/core";
import {
  MddDialog,
  MddDialogButtons,
  MddValidatedTextField
} from "../../../../components";
import { MAX_TEXT_LENGTH } from "../../../../constants";
import { Validations } from "../../../../model";

interface IMddEditThresholdsDialog {
  courseId: string;
  open: boolean;
  passingThreshold: string;
  remediationThreshold: string;
  onClose: () => void;
  onSave: (
    passingThreshold: string,
    remediationThreshold: string,
    isLocked: boolean
  ) => void;
}

export const MddEditThresholdsDialog = (props: IMddEditThresholdsDialog) => {
  const {
    open,
    passingThreshold,
    remediationThreshold,
    onClose,
    onSave
  } = props;

  const [passingThresholdVal, setPassingThresholdVal] = React.useState(
    passingThreshold
  );
  const [remediationThresholdVal, setRemediationThresholdVal] = React.useState(
    remediationThreshold
  );
  const [
    hasPassingValidationError,
    setHasPassingValidationError
  ] = React.useState(false);
  // This one is used for the front-end-lib's validation
  const [
    hasRemediationValidationError,
    setHasRemediationValidationError
  ] = React.useState(false);
  // This one is used for our own validation (remediation < passing)
  const [hasRemediationError, setHasRemediationError] = React.useState(false);

  const passingThresholdRef = React.useRef(passingThresholdVal);
  passingThresholdRef.current = passingThresholdVal;
  const remediationThresholdRef = React.useRef(remediationThresholdVal);
  remediationThresholdRef.current = remediationThresholdVal;

  React.useEffect(() => {
    const removeSaveThresholds = SagaService.subscribeToSaga(
      eStudySaga.SAVE_COURSE_THRESHOLDS,
      (action: IAction) => {
        if (action.type === eStudySaga.SAVE_COURSE_THRESHOLDS_SUCCESS) {
          onSave(
            passingThresholdRef.current,
            remediationThresholdRef.current,
            false
          );
        }
      }
    );

    return () => {
      removeSaveThresholds();
    };

    // eslint-disable-next-line
  }, []);

  const enableSave = () => {
    const { passingThreshold, remediationThreshold } = props;

    if (
      (passingThreshold !== passingThresholdVal.trim() ||
        remediationThreshold !== remediationThresholdVal.trim()) &&
      !hasPassingValidationError &&
      !hasRemediationValidationError &&
      !hasRemediationError &&
      parseInt(passingThresholdVal.trim(), 10) >=
        parseInt(remediationThresholdVal.trim(), 10)
    ) {
      return true;
    }
    return false;
  };

  const validateRemediationThreshold = (e: React.FocusEvent) => {
    // The built-in validation from the front-end-lib control isn't smart enough to
    // be able to look at other values to do a validation, only the current field's value.
    // Therefore, we need to do our own validation to make sure the remediation threshold
    // is less than or equal to the passing threshold.

    // First, get the current remediation value.  We cannot be sure that state has been
    // updated yet from any change they made since updates are asynchronous, so we'll
    // get the actual value from the field.
    const dataVal = (e.target as HTMLInputElement).value;
    setHasRemediationError(
      parseInt(passingThresholdVal, 10) < parseInt(dataVal, 10)
    );
  };

  const validatePassingThreshold = (e: React.FocusEvent) => {
    // The built-in validation from the front-end-lib control isn't smart enough to
    // be able to look at other values to do a validation, only the current field's value.
    // Therefore, we need to do our own validation to make sure the remediation threshold
    // is less than or equal to the passing threshold.  Note that this one is called on blur
    // of the passing threshold but will display the error on the remediation one.  We need
    // to do this because otherwise they can change this one last and the save button will
    // still be enabled.

    // First, get the current passing value.  We cannot be sure that state has been
    // updated yet from any change they made since updates are asynchronous, so we'll
    // get the actual value from the field.
    const dataVal = (e.target as HTMLInputElement).value.trim();
    setHasRemediationError(
      parseInt(dataVal, 10) < parseInt(remediationThresholdVal, 10)
    );
  };

  const handleSaveThresholdsClick = () => {
    const { courseId } = props;

    SagaService.dispatchSaga({
      type: eStudySaga.SAVE_COURSE_THRESHOLDS,
      payload: {
        courseId,
        passingThreshold: parseInt(passingThresholdVal.trim(), 10),
        remediationThreshold: parseInt(remediationThresholdVal.trim(), 10),
        isLocked: false
      }
    });
  };

  const renderThresholdsModalContent = () => {
    return (
      <React.Fragment>
        <MddValidatedTextField
          id="txtPassingThreshold"
          data-testid="txtPassingThreshold"
          labelProps={{
            labelText: (
              <span>
                Passing Threshold (%)
                <span
                  className="mdd-study-scores--info-icon"
                  title="Enter the minimum passing score."
                >
                  <InfoIcon
                    fontSize="small"
                    id="iconInfoPassing"
                    data-testid="iconInfoPassing"
                  />
                </span>
              </span>
            )
          }}
          textFieldProps={{
            debounceInterval: 0,
            textProps: {
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                setPassingThresholdVal(event.target.value),
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                validatePassingThreshold(e);
              },
              value: passingThresholdVal,
              id: `txtPassingThreshold_value`,
              className: "mdd-study-scores--threshold-width",
              inputProps: {
                maxLength: MAX_TEXT_LENGTH.THRESHOLD_VALUE
              }
            }
          }}
          validationForOnBlur={[
            Validations.required,
            Validations.validThresholdValue
          ]}
          validationerror={(validationError: boolean) =>
            setHasPassingValidationError(validationError)
          }
        />
        <MddValidatedTextField
          id="txtRemediationThreshold"
          data-testid="txtRemediationThreshold"
          labelProps={{
            labelText: (
              <span>
                Remediation Threshold (%)
                <span
                  className="mdd-study-scores--info-icon"
                  title="Enter the minimum remediation score. Scores below must be reviewed by the sponsor prior to remediation."
                >
                  <InfoIcon
                    fontSize="small"
                    id="iconInfoRemediation"
                    data-testid="iconInfoRemediation"
                  />
                </span>
              </span>
            ),
            className: "mdd-validated-text-field--label-padded"
          }}
          textFieldProps={{
            debounceInterval: 0,
            textProps: {
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                setRemediationThresholdVal(event.target.value.trim()),
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                validateRemediationThreshold(e);
              },
              value: remediationThresholdVal,
              id: `txtRemediationThreshold_value`,
              className: "mdd-study-scores--threshold-width",
              inputProps: {
                maxLength: MAX_TEXT_LENGTH.THRESHOLD_VALUE
              }
            }
          }}
          validationForOnBlur={[
            Validations.required,
            Validations.validThresholdValue
          ]}
          validationerror={(validationError: boolean) =>
            setHasRemediationValidationError(validationError)
          }
        />
        {hasRemediationError && (
          <div className="mdd-study-scores--validation-error">
            Remediation threshold must be less than or equal to passing
            threshold
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <MddDialog
      showCloseButton={true}
      onCloseClick={() => onClose()}
      dialogProps={{
        id: "divThresholdsDialog",
        open: open,
        maxWidth: "xs"
      }}
      dialogActionProps={{ id: "divThresholdsDialogActions" }}
      dialogContentProps={{
        id: "divThresholdsDialogContent"
      }}
      dialogTitleProps={{
        id: "divThresholdsDialogTitle",
        title: "Modify Thresholds"
      }}
      dialogContent={renderThresholdsModalContent()}
      dialogActions={
        <MddDialogButtons
          saveButtonText="Modify"
          closeButtonText="Cancel"
          saveButtonProps={{
            id: "btnThresholdsModify",
            disabled: !enableSave(),
            onClick: () => handleSaveThresholdsClick()
          }}
          closeButtonProps={{
            id: "btnThresholdsCancel",
            onClick: () => onClose(),
            disabled: false
          }}
        />
      }
    />
  );
};
