import { ISite, IPerson } from "../../model";
import { Auditable } from "./auditable";
import { Person } from "./person";

export class Site extends Auditable implements ISite {
  private primaryInvestigator: IPerson;
  private siteMonitor: string;
  private siteMonitorId: string;
  private protocolId: string;
  private siteId?: string;
  private siteName: string;
  private siteNumber: string;
  private siteAddressId: string;
  private sponsorSiteId: string;
  private countryValue: string;
  private address1: string;
  private address2: string;
  private city: string;
  private stateId: string;
  private state: string;
  private countryName: string;
  private zipCode: string;
  private phone: string;
  private protocol: string;
  private isActive: boolean;

  get PrimaryInvestigator() {
    return Object.assign(new Person(), this.primaryInvestigator);
  }
  set PrimaryInvestigator(val) {
    this.primaryInvestigator = val;
  }
  get SiteMonitor() {
    return this.siteMonitor;
  }
  set SiteMonitor(val) {
    this.siteMonitor = val;
  }
  get SiteMonitorId() {
    return this.siteMonitorId;
  }
  set SiteMonitorId(val) {
    this.siteMonitorId = val;
  }
  get ProtocolId() {
    return this.protocolId;
  }
  set ProtocolId(val) {
    this.protocolId = val;
  }
  get SiteNameWithNumber() {
    return `${this.SiteName} (${this.SiteNumber})`;
  }
  get SponsorSiteId() {
    return this.sponsorSiteId;
  }
  set SponsorSiteId(val) {
    this.sponsorSiteId = val;
  }
  get SiteNumber() {
    return this.siteNumber;
  }
  set SiteNumber(val) {
    this.siteNumber = val;
  }
  get Protocol() {
    return this.protocol;
  }
  set Protocol(val) {
    this.protocol = val;
  }
  get SiteId() {
    return this.siteId;
  }
  set SiteId(val) {
    this.siteId = val;
  }
  get SiteName() {
    return this.siteName;
  }

  set SiteName(val) {
    this.siteName = val;
  }
  get Address1() {
    return this.address1;
  }
  set Address1(val) {
    this.address1 = val;
  }
  get Address2() {
    return this.address2;
  }
  set Address2(val) {
    this.address2 = val;
  }
  get City() {
    return this.city;
  }
  set City(val) {
    this.city = val;
  }
  get CountryValue() {
    return this.countryValue;
  }
  set CountryValue(val) {
    this.countryValue = val;
  }
  get StateId() {
    return this.stateId;
  }
  set StateId(val) {
    this.stateId = val;
  }
  get State() {
    return this.state;
  }
  set State(val) {
    this.state = val;
  }
  get Phone() {
    return this.phone;
  }
  set Phone(val) {
    this.phone = val;
  }
  get SiteAddressId() {
    return this.siteAddressId;
  }
  set SiteAddressId(val) {
    this.siteAddressId = val;
  }
  get Country() {
    return this.countryName;
  }
  set Country(val) {
    this.countryName = val;
  }
  get Zip() {
    return this.zipCode;
  }
  set Zip(val) {
    this.zipCode = val;
  }
  get IsActive() {
    return this.isActive;
  }
  set IsActive(val) {
    this.isActive = val;
  }
}
