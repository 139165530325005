import { eFilter, Filter } from "front-end-lib/core";
import { classConstants } from "../../constants";

export const sponsorProgramDataTableProps = {
  containerProps: {
    id: "tableWrapper",
    className: "tbl--container",
    style: { boxShadow: "0px 0px 0px 0px" }
  },
  tableProps: { id: "tableContainer" },
  tableHeadProps: {
    id: "tableHeader",
    rowProps: {
      id: "tableHeaderRow",
      className: "tbl--header-row mdd-sponsorPrograms-tbl--header-row"
    }
  },
  tableBodyProps: {
    id: "tableBody",
    rowProps: {
      id: "tableBodyRow",
      className: "tbl--body-row mdd-sponsorPrograms-tbl--body-row"
    },
    cellProps: { style: { overflow: "visible" } }
  }
};

export const sponsorProgramDatatableHeaders = (
  filterOnChange:
    | ((
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => void)
    | undefined
) => [
  {
    key: "programName",
    label: "Program Name",
    filter: new Filter(eFilter.Text, {
      textProps: {
        className: classConstants.TEXT_FILTER_CLASS,
        id: "programNameFilter",
        onChange: filterOnChange,
        name: "programName"
      }
    }),
    sortProp: { sortPropName: "ProgramName", desc: true },
    tableCellProps: { id: "programNameHeader" },
    divProps: { id: "studyNameHeader_div" }
  },
  {
    key: "editProgram",
    label: "",
    tableCellProps: {
      id: "editHeader",
      className: classConstants.TABLE_CELL_CLASS
    },
    divProps: { id: "editHeader_div" }
  }
];
