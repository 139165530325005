import React from "react";
import { IBktDataTableColumnProps } from "front-end-lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { MddCheckbox } from "../../../components";
import { IEditItem } from "../../../store/resConfig/types";

interface IDeleteProps {
  id: string;
  deletable: boolean;
}

export const demographicTableStyles = {
  tableHeadProps: {
    className: "tbl--header tableHead",
    id: "resDraftTableHeader"
  },
  tableBodyProps: {
    rowProps: { className: "tbl--body-row mdd-tbl--body-row" }
  },
  tableFooterProps: {
    className: "mdd-res-draft--tableFooter",
    id: "resDraftTableFooter"
  }
};

export const demographicDataTableHeaders = (
  openDeleteDialog: (parentId: string) => void
): IBktDataTableColumnProps[] => [
  {
    label: "Experience",
    tableCellProps: {
      id: "experienceHeader"
    }
  },
  {
    label: "Active",
    tableCellProps: {
      id: "activeHeader"
    }
  },
  {
    label: "Delete",
    tableCellProps: {
      id: "deleteHeader"
    },
    formatProp: {
      formatPropName: "delete",
      format: ({ id, deletable }: IDeleteProps) => (
        <div className="tbl--body-row-actions">
          <FontAwesomeIcon
            className={deletable ? "" : "icon-disabled"}
            data-testid={`deleteIcon_${id}`}
            onClick={() => openDeleteDialog(id)}
            icon={faTimesCircle}
            fixedWidth
            id="closeIcon"
            size="xl"
          />
        </div>
      )
    }
  }
];

export const getDataTableDemographics = (
  demographics: IEditItem[],
  handle: (parentId: string) => void
) => {
  return demographics.map(
    ({ parentId, name, isActive, deletable, disabledOnGrid }) => ({
      name,
      key: parentId,
      active: (
        <MddCheckbox
          lblText=""
          chkBoxProps={{
            id: `activeCheckbox_${parentId}`,
            checked: isActive,
            className: "activeCheckbox",
            disabled: disabledOnGrid,
            onClick: () => handle(parentId)
          }}
        />
      ),
      delete: { id: parentId, deletable }
    })
  );
};
