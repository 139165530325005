import * as React from "react";
import { map, pick, trim, some, find, isEmpty, isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import {
  SagaService,
  getKeysFromState,
  IAction,
  BktDataTable,
  BktValidatedTextField,
  BktSelect,
  ISelectData
} from "front-end-lib/core";
import { eStudySaga, eSponsorSaga } from "../../../sagas";
import {
  StudyProtocol,
  IStudyProtocol,
  IMddStudyProps,
  Validations
} from "../../../model";
import {
  IBktDataTableSponsorProgram,
  MddDataContainer,
  MddDialog,
  MddDialogButtons,
  withMddStudy
} from "../../../components";
import {
  studyProtocolDataTableProps,
  studyProtocolDatatableHeaders
} from "../../../config";
import { isLoading, filterDataForDataTable } from "../../../utils";
import { MAX_TEXT_LENGTH } from "../../../constants";

export interface IBktDataTableStudyProtocol extends IStudyProtocol {
  key: string;
  value: string;
}

export interface IBktStudyProtocolDataTableState {
  protocols?: IBktDataTableStudyProtocol[] | undefined;
  newProtocols?: IBktDataTableStudyProtocol[] | undefined;
  protocol: IStudyProtocol;
  newProtocol: IStudyProtocol;
  sponsorPrograms?: IBktDataTableSponsorProgram[];
  mode: "View" | "Add" | "Edit";
  protocolNameHasError: boolean;
}
export class _BktShowStudyProtocols extends React.Component<
  IMddStudyProps,
  IBktStudyProtocolDataTableState
> {
  private filterVals = new Map();
  constructor(props: IMddStudyProps) {
    super(props);
    this.state = {
      protocols: [],
      newProtocols: [],
      sponsorPrograms: [],
      protocol: new StudyProtocol(),
      newProtocol: new StudyProtocol(),
      mode: "View",
      protocolNameHasError: false
    };
  }

  public componentDidMount() {
    const {
      match: {
        params: { id: routeStudyId }
      },
      study: { StudyId, SponsorId }
    } = this.props;

    const fetchStudyProtocols = (action: IAction) => {
      const { type, payload } = action;
      if (type === eStudySaga.FETCH_STUDY_PROTOCOLS_SUCCESS) {
        this.setState({
          protocols: payload,
          newProtocols: this.getFormattedProtocols(payload)
        });
      }
    };

    const setStudyProtocolAfterSave = (action: IAction) => {
      const { type, payload } = action;
      if (type === eStudySaga.MODIFY_STUDY_PROTOCOL_SUCCESS) {
        this.setState({ mode: "View" }, () => {
          SagaService.dispatchSaga({
            type: eStudySaga.FETCH_STUDY_PROTOCOLS,
            payload: payload.studyId
          });
        });
      }
    };

    SagaService.mapSagaToState(
      this,
      new Map([
        [
          eStudySaga.FETCH_STUDY_PROTOCOLS.toString(),
          { callback: fetchStudyProtocols }
        ],
        [
          eStudySaga.MODIFY_STUDY_PROTOCOL.toString(),
          { callback: setStudyProtocolAfterSave }
        ],
        [
          eSponsorSaga.FETCH_SPONSOR_PROGRAMS.toString(),
          {
            stateKey: getKeysFromState(this.state, ["sponsorPrograms"]),
            validationType: eSponsorSaga.FETCH_SPONSOR_PROGRAMS_SUCCESS
          }
        ]
      ])
    );

    if (routeStudyId) {
      SagaService.dispatchSaga({
        type: eStudySaga.FETCH_STUDY_PROTOCOLS,
        payload: routeStudyId
      });
    }

    if (StudyId && StudyId === routeStudyId) {
      SagaService.dispatchSaga({
        type: eSponsorSaga.FETCH_SPONSOR_PROGRAMS,
        payload: SponsorId
      });
    }
  }

  componentDidUpdate(prevProps: IMddStudyProps) {
    const { study } = this.props;

    if (prevProps.study.StudyId !== study.StudyId) {
      SagaService.dispatchSaga({
        type: eSponsorSaga.FETCH_SPONSOR_PROGRAMS,
        payload: study.SponsorId
      });
    }
  }

  public render() {
    const { protocols, newProtocols, mode } = this.state;
    const { study } = this.props;

    return (
      <MddDataContainer
        id="divStudyProtocols"
        breadcrumbs={[
          { display: "All Studies", route: "/study" },
          { display: study.StudyName }
        ]}
        addAction={{
          display: "Add New Protocol",
          action: (event: any) => this.handleDialogOpen("Add")
        }}
        title="Protocols"
      >
        <BktDataTable
          data={newProtocols}
          defaultSortProp="ProtocolName"
          loading={isLoading(`undefined_${eStudySaga.FETCH_STUDY_PROTOCOLS}`)}
          columnProps={studyProtocolDatatableHeaders(
            this.handleChangeFilter
          ).filter(dth => ["protocols", ""].indexOf(dth.key) < 0)}
          noRecordsMessage={
            some(protocols) && !some(newProtocols)
              ? "No records found"
              : "No protocols exist for this study"
          }
          {...studyProtocolDataTableProps}
        />
        {mode !== "View" && (
          <MddDialog
            submitting={isLoading(
              `undefined_${eStudySaga.MODIFY_STUDY_PROTOCOL}`
            )}
            dialogProps={{
              id: "divStudyProtocolDialog",
              open: true
            }}
            dialogActionProps={{ id: "divStudyProtocolDialogActions" }}
            dialogContentProps={{
              id: "divStudyProtocolDialogContent"
            }}
            dialogTitleProps={{
              id: "divStudyProtocolDialofTitle",
              title: mode === "Edit" ? "Edit Protocol" : "Add New Protocol"
            }}
            dialogContent={this.getDialogContent()}
            dialogActions={
              <MddDialogButtons
                saveButtonProps={{
                  disabled: this.disableSaveButton(),
                  onClick: this.handleDialogSave
                }}
                closeButtonProps={{
                  onClick: () => this.setState({ mode: "View" })
                }}
              />
            }
          />
        )}
      </MddDataContainer>
    );
  }

  private getDialogContent = () => {
    const { newProtocol, protocol, sponsorPrograms } = this.state;
    const data = !sponsorPrograms
      ? []
      : (sponsorPrograms.map((option: IBktDataTableSponsorProgram) => {
          return {
            value: option.Id,
            label: option.ProgramName
          } as ISelectData;
        }) as ISelectData[]);

    return [
      <BktValidatedTextField
        key="txtProtocolName"
        validationForOnChange={[Validations.protocolAllowedCharacters]}
        validationForOnBlur={[
          Validations.required,
          Validations.protocolAllowedCharacters
        ]}
        validationerror={(validationError: boolean) =>
          this.setState({ protocolNameHasError: validationError })
        }
        textFieldProps={{
          debounceInterval: 0,
          textProps: {
            id: "txtProtocolName",
            className: "mdd-form--text",
            label: "Protocol Name",
            placeholder: "Protocol Name",
            variant: "standard",
            required: true,
            type: "text",
            value: newProtocol.ProtocolName,
            inputProps: { maxLength: MAX_TEXT_LENGTH.PROTOCOL_NAME },
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({
                newProtocol: Object.assign(
                  new StudyProtocol(),
                  this.state.newProtocol,
                  { protocolName: e.target.value }
                )
              });
            }
          }
        }}
      />,
      <span data-testid="selectProgram">
        <BktSelect
          key="selectProgramName"
          menuProps={{}}
          textProps={{
            id: "selectProgramName",
            className: "mdd-form--text",
            label: "Program",
            variant: "standard",
            required: false,
            value: newProtocol.ProgramId,
            disabled: !isEmpty(protocol.ProgramId),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({
                newProtocol: Object.assign(
                  new StudyProtocol(),
                  this.state.newProtocol,
                  { programId: e.target.value }
                )
              });
            }
          }}
          /* 
          The API call for sponsor programs returns a program with an empty display for the default. 
          This API is incorrect and needs to be adjusted prior to setting this to true.
        */
          // defaultSelect={true}
          selectData={data}
        />
      </span>
    ];
  };

  private getFormattedProtocols = (
    protocols?: IBktDataTableStudyProtocol[]
  ) => {
    return protocols
      ? map(protocols, (st: IBktDataTableStudyProtocol) => {
          const editIconId = `${st.ProtocolId}_EditIcon`;
          return Object.assign(
            pick(st, [
              "ProtocolName",
              "ProgramName",
              ""
            ]) as IBktDataTableStudyProtocol,
            {
              key: st.ProtocolId,
              EditProtocol: (
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  fixedWidth
                  fontSize={"1.25rem"}
                  id="editIconId"
                  data-testid={editIconId}
                  title="Edit"
                  onClick={() => this.handleDialogOpen("Edit", st.ProtocolId)}
                />
              )
            }
          );
        })
      : undefined;
  };

  private handleChangeFilter = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const value = event.target.value ? trim(event.target.value) : "";
    const key = event.target.name;
    const { protocols } = this.state;
    if (protocols) {
      const filteredData = filterDataForDataTable(
        { key, value },
        this.filterVals,
        protocols
      );
      if (filteredData) {
        this.setState({
          newProtocols: this.getFormattedProtocols(filteredData)
        });
      }
    }
  };

  private handleDialogOpen = (
    mode: "View" | "Add" | "Edit",
    protocolId?: string
  ) => {
    const { protocols, sponsorPrograms } = this.state;
    let newProtocol: IStudyProtocol | undefined;

    if (protocolId) {
      newProtocol = find(
        protocols,
        (x: IStudyProtocol) => x.ProtocolId === protocolId
      );
      if (newProtocol) {
        if (newProtocol.ProgramName && sponsorPrograms) {
          const sponsorProgram = sponsorPrograms.find(
            (sp: IBktDataTableSponsorProgram) =>
              sp.ProgramName === newProtocol!.ProgramName
          );
          newProtocol.ProgramId = sponsorProgram ? sponsorProgram.Id : "";
        }
        this.setState({ protocol: newProtocol, newProtocol, mode });
        return;
      }
    }

    this.setState({
      protocol: new StudyProtocol(),
      newProtocol: new StudyProtocol(),
      mode
    });
  };

  private disableSaveButton = () => {
    const { protocolNameHasError, protocol, newProtocol } = this.state;
    return (
      isLoading(`undefined_${eStudySaga.MODIFY_STUDY_PROTOCOL}`) ||
      !newProtocol.ProtocolName ||
      protocolNameHasError ||
      isEqual(protocol, newProtocol)
    );
  };

  private handleDialogSave = () => {
    const { newProtocol } = this.state;
    const { study } = this.props;
    const payload: any = {
      id: newProtocol.ProtocolId,
      studyId: study.StudyId,
      programId: newProtocol.ProgramId,
      protocolName: newProtocol.ProtocolName.trim()
    };

    SagaService.dispatchSaga({
      type: eStudySaga.MODIFY_STUDY_PROTOCOL,
      payload
    });
  };
}

export const BktShowStudyProtocols = withMddStudy()(_BktShowStudyProtocols);
