import * as React from "react";
import { trim, isArray, pick, map } from "lodash";
import { ButtonProps } from "@material-ui/core/Button";
import {
  BktDataTable,
  Filter,
  eFilter,
  BktInfiniteScroll,
  SagaService,
  IAction,
  IBktDataTableData
} from "front-end-lib/core";
import { MddDialog, MddDialogButtons } from "../../../components";
import { Person, PersonDTO, IPerson } from "../../../model";
import { ePersonnelSaga } from "../../../sagas";
import { isLoading } from "../../../utils";

export interface IBktDataTablePerson extends IPerson {
  key: string;
}

interface IBktSelectPersonnelProps {
  displayDialog: boolean;
  onPersonChange?: (person?: IBktDataTablePerson, e?: React.MouseEvent) => void;
  onCancel?: () => void;
  onSelect?: (person?: IBktDataTablePerson) => void;
  person?: IBktDataTablePerson;
  additionalContent?: React.ReactNode;
  disabled?: boolean;
  btnSelectProps?: ButtonProps;
  submitting?: boolean;
}

export interface IBktSelectPersonnelState {
  person?: IBktDataTablePerson;
  personnel?: IBktDataTablePerson[];
  maxItems?: number;
}

export class BktSelectPersonnel extends React.PureComponent<
  IBktSelectPersonnelProps,
  IBktSelectPersonnelState,
  {}
> {
  private filterVals = new Map();
  private readonly itemIncrementer = 5;
  private scrollMax = 10;
  private scrollPosition = 0;
  private pageNumber = 0;
  private readonly pageSize = 200;
  private get scrollEl() {
    return document.getElementById("personInfiniteScroll") as HTMLDivElement;
  }
  private get personDTO() {
    const personDTO = Object.assign(new PersonDTO(), { Size: this.pageSize });
    this.filterVals.forEach((value, key) => {
      Object.assign(personDTO, { [key]: value });
    });
    return personDTO;
  }

  constructor(props: any) {
    super(props);
    this.state = {
      person: undefined,
      personnel: undefined,
      maxItems: 10
    };
    this.filterVals = new Map();

    SagaService.mapSagaToState(
      this,
      new Map([
        [
          ePersonnelSaga.FETCH_MANAGED_PERSONNEL.toString(),
          { callback: this.loadPersonnel }
        ]
      ])
    );
  }

  public render() {
    const {
      props: { displayDialog },
      dialogContentRenderChildren
    } = this;
    const { person } = this.state;
    const { btnSelectProps, submitting } = this.props;

    return (
      <MddDialog
        submitting={submitting}
        dialogProps={{
          id: "divPIDialog",
          onClose: () => {
            if (this.props.onCancel) {
              this.props.onCancel();
            }
          },
          "aria-labelledby": "form-dialog-title",
          open: displayDialog,
          maxWidth: "xl",
          fullWidth: true
        }}
        dialogActionProps={{
          id: "divPIDialogActions"
        }}
        dialogContentProps={{
          id: "divPIDialogContent",
          className: "mdd-dialog--content-100percent"
        }}
        dialogTitleProps={{
          id: "divPIDialogTitle",
          title: "Search People"
        }}
        dialogContent={dialogContentRenderChildren()}
        dialogActions={
          <MddDialogButtons
            saveButtonText="Select"
            saveButtonProps={{
              id: "btnSelectPerson",
              disabled:
                (btnSelectProps && btnSelectProps.disabled) ||
                this.state.person === undefined,
              onClick: () => {
                if (this.props.onSelect) {
                  this.props.onSelect(person);
                }
              }
            }}
            closeButtonProps={{
              id: "btnCancelSelectPerson",
              onClick: () => {
                if (this.props.onCancel) {
                  this.props.onCancel();
                }
              }
            }}
          />
        }
      />
    );
  }

  private dialogContentRenderChildren = () => {
    const { person, personnel, maxItems } = this.state;
    const { onPersonChange, additionalContent } = this.props;
    let { disabled } = this.props;
    const dataProps = ["FirstName", "LastName", "Email"];
    const data = personnel
      ? map(personnel, (p: IBktDataTablePerson) =>
          Object.assign(pick(p, dataProps) as IBktDataTablePerson, {
            key: p.Key
          })
        )
      : undefined;
    disabled = disabled !== undefined ? disabled : false;

    return [
      <BktDataTable
        key="piDataTable"
        data={data}
        loading={isLoading(
          `undefined_${ePersonnelSaga.FETCH_MANAGED_PERSONNEL}`
        )}
        columnProps={[
          {
            label: "First Name",
            filter: new Filter(eFilter.Text, {
              textProps: {
                disabled,
                className: "tbl--filter-text",
                id: "piFirstNameFilter",
                onChange: this.handleChangeFilter,
                name: "FirstName",
                value: this.filterVals.get("FirstName")
              }
            }),
            tableCellProps: {
              id: "piFirstNameHeader",
              className: "tbl--header-cell"
            },
            divProps: { id: "piFirstNameHeader_div" }
          },
          {
            label: "Last Name",
            filter: new Filter(eFilter.Text, {
              textProps: {
                disabled,
                className: "tbl--filter-text",
                id: "piLastNameFilter",
                onChange: this.handleChangeFilter,
                name: "LastName",
                value: this.filterVals.get("LastName")
              }
            }),
            tableCellProps: {
              id: "piLastNameHeader",
              className: "tbl--header-cell"
            },
            divProps: { id: "piLastNameHeader_div" }
          },
          {
            label: "Email",
            filter: new Filter(eFilter.Text, {
              textProps: {
                disabled,
                className: "tbl--filter-text",
                id: "piEmailFilter",
                onChange: this.handleChangeFilter,
                name: "Email",
                value: this.filterVals.get("Email")
              }
            }),
            tableCellProps: {
              id: "piEmailHeader",
              className: "tbl--header-cell"
            },
            divProps: { id: "piEmailHeader_div" }
          }
        ]}
        rowActions={
          disabled
            ? undefined
            : [
                {
                  isPrimary: true,
                  // Set data type to personnel once DTO/Class has been defined.
                  action: (data: IBktDataTableData, e: React.MouseEvent) => {
                    this.setState(
                      {
                        person: personnel
                          ? personnel.find(x => x.Key === data.key)
                          : undefined
                      },
                      () => {
                        if (onPersonChange) {
                          onPersonChange(this.state.person, e);
                        }
                      }
                    );
                  }
                }
              ]
        }
        selectedData={
          {
            ...person,
            ...{ key: person ? (person as IBktDataTablePerson).Key : "" }
          } as IBktDataTablePerson
        }
        infiniteScrollProps={{
          id: "personInfiniteScroll",
          maxItems,
          itemIncrementer: this.itemIncrementer,
          defaultScrollPosition: this.scrollPosition,
          onIncrement: async (component: BktInfiniteScroll) => {
            /* 
                            Guard to ensure that the amount of items allowed in the infinite scroll is greater than the amount of data and 
                            that there are more pages to retrieve.
                        */
            this.scrollMax = component.state.maxItems;
            if (
              this.scrollMax >= (personnel || []).length &&
              !(
                (personnel || []).length <
                (this.pageNumber + 1) * this.pageSize
              )
            ) {
              // Get the current scroll position prior to the component re-rendering
              const { scrollTop, offsetHeight } = this.scrollEl;
              this.scrollPosition = Math.round(
                scrollTop || 0 + offsetHeight || 0
              );
              this.pageNumber += 1;
              SagaService.dispatchSaga({
                type: ePersonnelSaga.FETCH_MANAGED_PERSONNEL,
                payload: {
                  dto: Object.assign(this.personDTO, { Page: this.pageNumber })
                }
              });
            }
          }
        }}
        selectedDataProps={{ className: "mdd-personnel-tbl--selected" }}
        tableBodyProps={{ rowProps: { id: "personDataTableRow" } }}
        containerProps={{ className: "mdd-personnel-tbl--container" }}
        tableFooterProps={{ className: "mdd-personnel-tbl--footer" }}
      />,
      <label key="spanPIDetailsDisplay">
        {person
          ? `${person.FirstName} ${person.LastName} - ${person.Email}`
          : ""}
      </label>,
      additionalContent
    ];
  };

  private handleChangeFilter = async (event: any) => {
    const { name } = event.target;
    const value = event.target.value ? trim(event.target.value) : "";

    if (this.filterVals.has(name) && this.filterVals.get(name) === value) {
      return;
    }

    value ? this.filterVals.set(name, value) : this.filterVals.delete(name);

    Object.assign(this, { pageNumber: 0, scrollPosition: 0, scrollMax: 10 });

    if (!(this.filterVals.size > 0)) {
      this.setState({ personnel: undefined });
    } else {
      SagaService.dispatchSaga({
        type: ePersonnelSaga.FETCH_MANAGED_PERSONNEL,
        payload: {
          dto: Object.assign(this.personDTO, { Page: this.pageNumber })
        }
      });
    }
  };

  private loadPersonnel = (action: IAction) => {
    if (isArray(action.payload) && action.payload.length > -1) {
      this.setState({
        personnel: [
          ...(this.pageNumber > 0 ? this.state.personnel || [] : []),
          ...action.payload.map(p => Object.assign(new Person(), p))
        ],
        maxItems: this.scrollMax + this.itemIncrementer
      });
    }
  };
}
