import { takeLatest } from "redux-saga/effects";
import { IAction, SagaService, ISagaServiceSaga } from "front-end-lib/core";
import { eUtilActionTypes } from "../store";
import { store } from "../utils";

export const initSaga = (
  saga: (action: IAction) => void,
  type: string,
  watchType?: string,
  handleLoadingState: boolean = true
) => {
  const sagaWrapper = function*(wrapperAction: IAction) {
    const loadingKey = `${
      wrapperAction.payload ? wrapperAction.payload.loadingKey : ""
    }_${wrapperAction.type}`;
    try {
      if (handleLoadingState) {
        yield store.dispatch({
          type: eUtilActionTypes.SET_LOADING,
          payload: { key: loadingKey, loading: true }
        });
      }
      yield saga(wrapperAction);
    } catch (e) {
      console.log(e);
    } finally {
      if (handleLoadingState) {
        yield store.dispatch({
          type: eUtilActionTypes.SET_LOADING,
          payload: { key: loadingKey, loading: false }
        });
      }
    }
  };

  SagaService.setSaga({ saga: sagaWrapper, type });

  if (watchType) {
    const sagaWrapperWatcher = function*() {
      yield takeLatest(type, sagaWrapper);
    };

    SagaService.setSaga({ saga: sagaWrapperWatcher, type: watchType });
    SagaService.runSaga({
      type: watchType,
      sagaio: (SagaService.sagas.get(type) as ISagaServiceSaga).sagaIO
    });
  }

  return sagaWrapper;
};

// This is used for tests so that we do not have to mock the api calls.
export const overrideSaga = (
  newSaga: (...args: any[]) => any,
  type: string,
  watchType: string = ""
) => {
  const saga = SagaService.sagas.get(type);
  const watcher = SagaService.sagas.get(watchType);

  if (saga) {
    SagaService.sagas.delete(type);
  }
  if (watcher) {
    SagaService.sagas.delete(watchType);
  }

  initSaga(newSaga, type, watchType);

  return newSaga;
};

interface IRunSagaAsyncProps {
  startType: string;
  successType: string;
  payload?: any;
}

export function runSagaAsync<T>({
  startType,
  successType,
  payload
}: IRunSagaAsyncProps): Promise<T> {
  return new Promise(resolve => {
    const disposeSub = SagaService.subscribeToSaga(
      startType,
      (action: IAction) => {
        if (action.type === successType) {
          disposeSub();
          resolve(action.payload);
        }
      }
    );

    SagaService.dispatchSaga({ type: startType, payload });
  });
}
